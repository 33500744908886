import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  BsFillCalendarCheckFill, BsFillExclamationTriangleFill, BsSegmentedNav, BsStack,
} from 'react-icons/bs';
import { useSelector } from 'react-redux';

import { selectGasContainers } from '../../../store/slices/gas-containers-slice';
import { selectLiquidContainers } from '../../../store/slices/liquid-containers-slice';
import Callout from '../../UI/molecules/Callout/callout';

function WorkOrdersMetricsCallout(props) {
  let workOrders = props.workOrders ?? [];
  if (props.workOrders) {
    workOrders = props.workOrders;
  }

  const liquidContainers = useSelector(selectLiquidContainers);
  const gasContainers = useSelector(selectGasContainers);

  const isLindeOwned = (value) => {
    // need a section to check if the customerId is null. if so, get the asset on the WO and
    // check the owner customer against the necessary logic
    if (value[1].original.assetTypeId === 2 && value[1].original.customerId === null) {
      const container = liquidContainers[value[1].original.assetSerialNumber];
      if (container?.ownerCustomer === 91000000) {
        return true;
      }
    }
    if (value[1].original.assetTypeId === 1 && value[1].original.customerId === null) {
      const container = gasContainers[value[1].original.assetSerialNumber];
      if (container?.ownerCustomer === 98000008) {
        return true;
      }
    }
    if (value[1].original.assetTypeId === 2 && value[1].original.customerId === 91000000
    ) {
      return true;
    }
    if (value[1].original.assetTypeId === 1 && (value[1].original.customerId === 98000008
      || value[1].original.leasedToCustomer === 98000008)
    ) {
      return true;
    }
    if (value[1].original.assetTypeId === 4) {
      return true;
    }
    return false;
  };

  const totalWorkOrders = Object.entries(workOrders)
    .filter(([key, value]) => value.original.workOrderStatusId >= 1
     && value.original.workOrderStatusId <= 3);
  const totalOpenWorkOrders = Object.entries(workOrders)
    .filter(([key, value]) => value.original.workOrderStatusId === 1);
  const totalInProcessWorkOrders = Object.entries(workOrders)
    .filter(([key, value]) => value.original.workOrderStatusId === 3);
  const totalScheduledWorkOrders = Object.entries(workOrders)
    .filter(([key, value]) => value.original.workOrderStatusId === 2);

  const totalLindeOwnedWorkOrders = Object.entries(totalWorkOrders)
    .filter(([key, value]) => isLindeOwned(value));
  const totalLindeOwnedOpenWorkOrders = Object.entries(totalOpenWorkOrders)
    .filter(([key, value]) => isLindeOwned(value));
  const totalLindeOwnedInProcessWorkOrders = Object.entries(totalInProcessWorkOrders)
    .filter(([key, value]) => isLindeOwned(value));
  const totalLindeOwnedScheduledWorkOrders = Object.entries(totalScheduledWorkOrders)
    .filter(([key, value]) => isLindeOwned(value));

  const totalCalloutContent = (
    <span>
      <span className="info-box-text text-sm dg-draggable">
        <b> Total Work Orders: </b>
        {' '}
        {Array.from(totalWorkOrders, (x) => x[1]).length}
      </span>
      <span className="text-sm text-blue">
        {' '}
        <b> Linde Owned: </b>
        {Array.from(totalLindeOwnedWorkOrders, (x) => x[1]).length}
      </span>
      <span className="info-box-text text-sm">
        {' '}
        <b> Customer Owned: </b>
        {(Array.from(totalWorkOrders, (x) => x[1]).length)
        - (Array.from(totalLindeOwnedWorkOrders, (x) => x[1]).length)}
      </span>
    </span>
  );

  const openCalloutContent = (
    <>
      <span className="info-box-text text-sm dg-draggable">
        <b> Total Open Work Orders: </b>
        {' '}
        {Array.from(totalOpenWorkOrders, (x) => x[1]).length}
      </span>
      <span className="text-sm text-blue">
        {' '}
        <b> Linde Owned: </b>
        {Array.from(totalLindeOwnedOpenWorkOrders, (x) => x[1]).length}
      </span>
      <span className="info-box-text text-sm">
        {' '}
        <b> Customer Owned: </b>
        {(Array.from(totalOpenWorkOrders, (x) => x[1]).length)
        - (Array.from(totalLindeOwnedOpenWorkOrders, (x) => x[1]).length)}
      </span>
    </>
  );
  const inProcessCalloutContent = (
    <>
      <span className="info-box-text text-sm dg-draggable">
        <b> Total In-Progress Work Orders: </b>
        {' '}
        {Array.from(totalInProcessWorkOrders, (x) => x[1]).length}
      </span>
      <span className="text-sm text-blue">
        {' '}
        <b> Linde Owned: </b>
        {Array.from(totalLindeOwnedInProcessWorkOrders, (x) => x[1]).length}
      </span>
      <span className="text-sm">
        {' '}
        <b> Customer Owned: </b>
        {(Array.from(totalInProcessWorkOrders, (x) => x[1]).length)
        - (Array.from(totalLindeOwnedInProcessWorkOrders, (x) => x[1]).length)}
      </span>
    </>
  );
  const scheduledCalloutContent = (
    <>
      <span className="info-box-text text-sm dg-draggable">
        <b> Total Scheduled Work Orders: </b>
        {' '}
        {Array.from(totalScheduledWorkOrders, (x) => x[1]).length}
      </span>
      <span className="text-sm text-blue">
        {' '}
        <b> Linde Owned: </b>
        {Array.from(totalLindeOwnedScheduledWorkOrders, (x) => x[1]).length}
      </span>
      <span>
        {' '}
        <b> Customer Owned: </b>
        {(Array.from(totalScheduledWorkOrders, (x) => x[1]).length)
        - (Array.from(totalLindeOwnedScheduledWorkOrders, (x) => x[1]).length)}
      </span>
    </>
  );
  return (
    <div className="card-tools">
      <Container className="pl-0 pr-1" fluid>
        <Row className="d-flex">
          <Col>
            <Callout icon={<BsStack />} content={totalCalloutContent} />
          </Col>
          <Col>
            <Callout icon={<BsFillExclamationTriangleFill />} content={openCalloutContent} />
          </Col>
          <Col>
            <Callout icon={<BsSegmentedNav />} content={inProcessCalloutContent} />
          </Col>
          <Col>
            <Callout icon={<BsFillCalendarCheckFill />} content={scheduledCalloutContent} />
          </Col>
        </Row>
        <br />
      </Container>
    </div>
  );
}

export default WorkOrdersMetricsCallout;
