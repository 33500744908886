import React from "react";
import { NavLink, OverlayTrigger, Popover } from "react-bootstrap";

export const SidebarHoverButton = (props) => {
  let icon = null;
  if (props.icon) {
    icon = (<i className={`bi-${props.icon} nav-icon fas ml-1`} />);
  }
  return (
    <OverlayTrigger
      /*       trigger="click" */
      placement={'right'}
      overlay={

        <Popover id={`popover-positioned-right`} data-bs-theme="dark">
          <div data-bs-theme="dark">
            <Popover.Header as="h3" data-bs-theme="dark">{`Popover right`}</Popover.Header>
            <Popover.Body data-bs-theme="dark">
              <strong>Holy guacamole!</strong> Check this info.
            </Popover.Body>
          </div>
        </Popover>


      }
    >
      <NavLink
        end
        to={props.to}
        onClick={props.onClick}
        // @ts-ignore
        className={({ isActive }) => `nav-link pt-2 pb-2 pr-3 pl-3 ${isActive && props.to !== '#' ? 'active' : 'notactive'}`}
      >
        {icon}
        {' '}
        <p className="text-nowrap">
          {props.text}
          {props.hasSubMenu && <i className="bi-caret-left fas right" />}
        </p>
      </NavLink>
    </OverlayTrigger>
  );
}