import React from 'react';
import {
  Button, Col, Container, Form, Modal,
  Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  selectPackagedProductLocations,
} from '../../../store/slices/legacy-slice';
import { createPackagedProduct, fetchPackagedProductBySerialNumber } from '../../../store/slices/packaged-products-slice';
import BasicInput from '../../UI/molecules/BasicInput/basic-input';
import DateInput from '../../UI/molecules/DateInput/date-input';
import { packagedProductManufacturers } from './packaged-product-values';

function CreatePackagedProductForm({ onHide, packagedProductStatusIsLoading }) {
  const defaultPackagedProductFormValues = {
    serialNumber: '',
    tareWeight: null,
    currentVolume: null,
    owningLocationId: null,
    packagedProductType: null,
    manufacturer: null,
    isCustomerOwned: null,
    manufacturedDate: null,
    comments: '',
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
    trigger,
  } = useForm({
    defaultValues: defaultPackagedProductFormValues,
  });

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const dispatch = useDispatch();

  const onSubmit = (packagedProduct) => {
    Object.keys(packagedProduct).forEach((key) => {
      if (key.toLowerCase().includes('date') && packagedProduct[key]) {
        packagedProduct[key] = convertDateToUTCDay(packagedProduct[key]);
      }
    });
    dispatch(createPackagedProduct({ ...packagedProduct, assetType: 'PackagedProduct' }));
  };

  const basicInputProps = {
    setValue,
    watch,
    getFieldState,
    register,
    trigger,
  };

  const packagedProductLocations = useSelector(selectPackagedProductLocations);
  const sortedLocations = Object.values(packagedProductLocations)
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim()));
  const packagedProductTypes = useSelector(
    (state) => state.legacy?.dropdownValues?.packagedProductTypes,
  );

  const validateUnitNumber = async (input) => {
    const packagedProduct = getValues();
    if (!errors?.serialNumber && packagedProduct.serialNumber !== '') {
      const { payload } = await dispatch(fetchPackagedProductBySerialNumber(packagedProduct.serialNumber));
      if (payload?.assetDetails?.id) {
        setError('uniqueSerialNumber', { type: 'custom', message: 'The serial number already exists' });
      } else {
        clearErrors();
      }
    }
  };
  return (
    <>
      <Modal.Body>
        <Form className="bg-white h-100 overflow-auto">
          <Spinner
            animation="border"
            variant="primary"
            className={`create-spinner${packagedProductStatusIsLoading ? ' visible' : ' invisible'}`}
          />
          <Container
            fluid
            className={`${packagedProductStatusIsLoading ? ' creation-loading' : ''}`}
          >
            <Row>
              <Col>

                <Container>
                  <Form.Group className="mb-1">
                    <Form.Label>
                      Unit Number
                    </Form.Label>
                    <Form.Control
                      size="sm"
                      isInvalid={!!errors.serialNumber || !!errors.uniqueSerialNumber}
                      {...register('serialNumber', { required: true, maxLength: { message: 'Unit ID cannot exceed 20 characters', value: 20 } })}
                      onBlur={(input) => validateUnitNumber(input)}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.serialNumber && errors.serialNumber.message}
                      {errors.uniqueSerialNumber && errors.uniqueSerialNumber.message}
                    </Form.Control.Feedback>

                  </Form.Group>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>
                          Product Type
                        </Form.Label>
                        <Form.Select
                          size="sm"
                          isInvalid={!!errors.packagedProductType}
                          {...register('packagedProductType', { required: true })}
                        >
                          {(packagedProductTypes ?? []).map((type) => (
                            <option value={type.id} key={type.id}>{type.ProductNumber}</option>
                          ))}

                        </Form.Select>
                      </Form.Group>
                    </Col>
                    <Col className="d-flex flex-column-reverse">
                      <Form.Group className="mb-1">
                        <Form.Check
                          {...register('isCustomerOwned')}
                          type="switch"
                          inline
                          id="isCustomerOwned-switch"
                          label="Customer Owned"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Form.Group className="mb-1">
                    <Form.Label>
                      Owning Location
                    </Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('owningLocationId')}
                    >
                      {(sortedLocations ?? []).map((location) => (
                        <option
                          value={location.LocationID}
                          key={location.LocationID}
                        >
                          {location.LocationName}

                        </option>
                      ))}

                    </Form.Select>
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>
                      Tare Weight
                    </Form.Label>
                    <BasicInput
                      name="tareWeight"
                      defaultUnit="lbs"
                      options={['lbs', 'kgs']}
                      isInvalid={!!errors.tareWeight}
                      createMode
                      {...basicInputProps}
                      registerOptions={{ required: 'Please enter a valid Tare Weight', min: { value: 0, message: 'must be non-negative' } }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.tareWeight && (
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-block"
                      >
                        {errors.tareWeight.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Form.Group className="mb-1">
                    <Form.Label>
                      Current Volume
                    </Form.Label>
                    <BasicInput
                      name="currentVolume"
                      defaultUnit="Cubic Feet"
                      options={['Cubic Feet', 'Cubic Meters', 'Liters']}
                      isInvalid={!!errors.currentVolume}
                      createMode
                      {...basicInputProps}
                      registerOptions={{ required: 'Please enter a Current Volume', min: { value: 0, message: 'must be non-negative' } }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.currentVolume && (
                      <Form.Control.Feedback
                        type="invalid"
                        className="d-block"
                      >
                        {errors.currentVolume.message}
                      </Form.Control.Feedback>
                    )}
                  </Form.Group>

                  <Row>
                    <Col>
                      <Form.Label>
                        Manufacturer
                      </Form.Label>
                      <Form.Select
                        size="sm"
                        {...register('manufacturer')}
                      >
                        {(packagedProductManufacturers ?? []).map((manufacturer) => (
                          <option value={manufacturer} key={manufacturer}>{manufacturer}</option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Manufactured Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="manufacturedDate"
                          rules={{ required: 'Please enter a Manufactured Daye' }}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group>
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      rows={3}
                      {...register('comments')}
                      style={{ resize: 'none' }}
                    />
                  </Form.Group>
                </Container>
              </Col>

            </Row>

          </Container>

        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          onClick={onHide}
          variant="secondary"
        >
          Close
        </Button>
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
          disabled={packagedProductStatusIsLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </>
  );
}

export default CreatePackagedProductForm;
