import React from 'react';

function Callout(props) {
  return (
    <div className="info-box box-shadow mt-1 mb-0 p-0 ">
      <span className="info-box-icon bg-primary">{props.icon}</span>
      <div className="info-box-content">
        {props.content}
      </div>
    </div>
  );
}

export default Callout;
