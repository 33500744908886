import moment from "moment";
import { formatCurrency } from "../../../../services/format";

import { FormItemType } from "../../../UI/view/component-map";
import { DataSource } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../../UI/view/list-view";
import { createConnectedView } from "../../../UI/view/view";
import { BillingDewarRentalListView } from "./dewarrental-invoice-list";
import { months } from "../../../../services/date-helpers";
import { billingPermissions } from "../billing-datasource";
import { InsertBilling, bypassAll, getDewarRental, getDewarRentalsUnbilled, insertAllPending } from "../../../../services/requests/billing/dewar-rental-billing-requests";

export const dewarrentalordersDS = {
  request: getDewarRentalsUnbilled,
  pk: 'pk',
  name: 'dewarrentalbill',
  updateRequest: InsertBilling,
  skipLocalUpdate: true,
  entityRequest: getDewarRental,
  /*   serverSideEvents: true */
} as DataSource;

///create function that takes the first 1000 items in an array and returns them

export const dewarRentalOrdersList = {
  autoCheckAll: true,
  disableSelection: true,
  filters: [
    {
      type: FilterType.multiselect,
      fieldName: 'recordType',
      filterValue: { 'MTD': true, 'Unbilled': true },
      options: () => ['MTD', 'Unbilled']
    }
  ],
  toolbar: [
    {
      type: FormItemType.button,
      label: 'Move All Unbilled to Pending',
      handler: async ({ getValues, confirm }) => {
        const proceed = await confirm('Are you sure you want to move all Unbilled Rentals to pending?');
        if (proceed) {
          const myVals = getValues();
          console.log(myVals)
          try {
            await insertAllPending({
              billingList: myVals.dewarRentalBillingList?.map(b => ({
                customerId: b.dewarCustomerId,
                year: b.invoiceYear,
                month: b.invoiceMonth,
                orderId: b.orderNumber,
              }))
            });
            await confirm('Rentals moved to pending Successfully', true);
            location.reload();
          } catch {
            await confirm('There was an error moving rentals to pending', true)
          }

        }
      }
    },
    {
      type: FormItemType.button,
      label: 'Bypass All Unbilled',
      handler: async ({ getValues, confirm }) => {
        const myVals = getValues();
        console.log(myVals)
        /*      const { billingId, emailAddress } = getValues(); */
        const proceed = await confirm('Are you sure you want to bypass all Unbilled Rentals?');
        if (proceed) {
          try {
            await bypassAll({
              billingList: myVals.dewarRentalBillingList?.map(b => ({
                customerId: b.dewarCustomerId,
                year: b.invoiceYear,
                month: b.invoiceMonth,
                orderId: b.orderNumber,
              }))
            });
            await confirm('Bypassed Successfully', true);
            location.reload();
          } catch {
            await confirm('There was an error bypassing rentals', true)
          }

        }
      }
    }
  ],
  columns: [
    {
      type: ListViewColumnType.icon,
      id: 'trafficStatus',
      accessor: ({ invoiceYear, invoiceMonth }) => moment().year() == invoiceYear && moment().month() + 1 == invoiceMonth ? 'clock-history' : 'currency-dollar',
      tooltip: ({ invoiceYear, invoiceMonth }) => moment().year() == invoiceYear && moment().month() + 1 == invoiceMonth ? 'MTD' : 'Unbilled',
    },
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/billing/dewarrentalinvoice/' + row.original.pk
    },
    {
      Header: 'CST',
      accessor: 'dewarCustomerId'
    },
    {
      Header: 'Year',
      accessor: 'invoiceYear'
    },
    {
      Header: 'Month',
      id: 'invoiceMonth',
      accessor: ({invoiceMonth}) => months[invoiceMonth]
    },
    {
      Header: 'Dewars Unbilled For Month',
      accessor: 'count'
    },
    {
      Header: 'Dollars ($)',
      accessor: 'totalDollars',
      Cell: ({ row: { original: { totalDollars } } }) => formatCurrency(totalDollars),
    },
    {
      Header: 'Order Number',
      accessor: 'orderNumber',
      hidden: true
    },
    {
      Header: 'Priority',
      id: 'priority',
      defaultSort: true,
      defaultSortDirection: 'desc',
      accessor: ({ invoiceYear, invoiceMonth }) => {
        console.log(moment().year())
        console.log(moment().month())
        return moment().year() == invoiceYear && moment().month() + 1 == invoiceMonth ? 2 : 1;
      },
      hidden: true 
    },
    {
      Header: 'RecordType',
      id: 'recordType',
      accessor: ({ invoiceYear, invoiceMonth }) => moment().year() == invoiceYear && moment().month() + 1 == invoiceMonth ? 'MTD' : 'Unbilled',
      hidden: true
    },
    {
      Header: 'Free Days',
      accessor: 'freeDays',
      hidden: true
    },
  ]
} as ListView;

export const DewarRentalsListView = createConnectedListView(dewarRentalOrdersList, dewarrentalordersDS);

export const DewarRentalsListPage = createConnectedView({
  form: {
    pageHeader: 'Dewar Rental Billing',
    permissions: billingPermissions,
    breadcrumbs: [
      {
        name: 'Dewar Rental Billing',
        icon: 'currency-dollar',
        to: '/billing/dewar',
      },
    ],
    items: [
      {
        type: FormItemType.tab,
        items: [
          {
            label: 'Monthly Rentals',
            mountOnEnter: false,
            badgeCounter: (state) => {
              return state.dewarrentalbill?.data?.length;
            },
            items: [
              {
                items: [
                  {
                    type: FormItemType.customComponent,
                    component: DewarRentalsListView,
                    name: 'dewarRentalBillingList'
                  }
                ]
              }
            ]
          },
          {
            label: 'Invoices',
            mountOnEnter: false,
            badgeCounter: (state) => {
              let count = state.dewarRB?.data?.filter(x => [0, 9].includes(x.status) && !x.jdebypassed).length;
              if(count == 100) count = `${count}+`;
              return count;
            },
            items: [
              {
                items: [
                  {
                    type: FormItemType.customComponent,
                    component: BillingDewarRentalListView,
                    name: 'dewarRentalBillingList'
                  }
                ]
              }
            ]
          },
        ]
      }
    ]
  } as FormView, dataSource: dewarrentalordersDS
});
