/* eslint-disable indent */
import 'chartjs-adapter-date-fns';

import React, { useMemo, useRef } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

// eslint-disable-next-line unused-imports/no-unused-imports
import { formatDate } from '../../../services/format';
import { convertToPreferred, getPreferredUnit } from '../../../services/user-preferences';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectCurrentGasContainer, selectCurrentGasReadings } from '../../../store/slices/gas-containers-slice';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

export function SummaryTable() {
  const tableInstance = useRef(null);
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);
  const currentGasContainer = useSelector(selectCurrentGasContainer);
  const {
    myTemp,
    myBattV,
    myLoc,
    myReadings,
    pr,
  } = useSelector(selectCurrentGasReadings);
  const tempAbbr = getPreferredUnit('Fahrenheit', measurementPreference).substr(0, 1);

  const mySummaryRecord = {
    location: myLoc?.Location,
    batteryLevel: myBattV?.ReadingValue,
    ambientTemperature: myTemp?.ReadingValue,
    Latitude: myLoc?.Latitude,
    Longitude: myLoc?.Longitude,
    ReadingDate: myLoc?.ReadingDate,
    pressure: pr?.ReadingValue,
  };

  const summaryColumns = useMemo(
    () => [
      {
        Header: 'Location',
        accessor: 'location',
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.ReadingDate, datePreference)}`}>{value}</span>,
      },
      {
        Header: 'Battery Voltage',
        accessor: 'batteryLevel',
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.ReadingDate, datePreference)}`}>{value}</span>,
      },
      {
        Header: `Ambient Temperature (${tempAbbr})`,
        id: 'ambientTemperature',
        accessor: ({ ambientTemperature }) => {
          const converted = convertToPreferred('Fahrenheit', measurementPreference, ambientTemperature);
          return Math.floor(converted);
        },
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.ReadingDate, datePreference)}`}>{value}</span>,
      },
      {
        Header: 'Pressure',
        accessor: 'pressure',
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.ReadingDate, datePreference)}`}>{value ?? 'N/A'}</span>,
      },
      {
        Header: 'GPS',
        accessor: 'gps',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${row.original.Latitude},${row.original.Longitude}`} rel="noreferrer">
            <FaMapMarkerAlt />
          </a>
        ),
      },
    ],
    [mySummaryRecord],
  );

  return (
    <PaginatedSelectTable
      columns={summaryColumns}
      data={mySummaryRecord ? [mySummaryRecord] : []}
      ref={tableInstance}
      rowProps={(row) => ({
        onClick: () => {
          // handleRowClick(row.original.serialNumber);
        },
      })}
    />
  );
}
