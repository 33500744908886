import './balloons.scss';

import React from 'react';

function Balloons(props) {
  return (
    <div className={`balloon-group brand-image ${props.className}`} style={{ color: 'f1f5f7' }}>
      <div className="balloon-ring" />
      <i className="bi bi-balloon-fill balloon" />
      <i className="bi bi-balloon-fill balloon" />
      <i className="bi bi-balloon-fill balloon" />
    </div>
  );
}

export default Balloons;
