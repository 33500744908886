/* eslint-disable spaced-comment */
/* eslint-disable no-return-assign */
import Polyline from '@arcgis/core/geometry/Polyline';
import * as geodesicUtils from '@arcgis/core/geometry/support/geodesicUtils';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import Recenter from './recenter';
import { intersects } from "@arcgis/core/geometry/geometryEngine";
import { LocationMap } from './location-map';

export function LocationMapWrapper(props) {
  const {entity, assetType} = props;
/*   const asd = assetType="chassis" */
/*   readings={{
    summary: myLoc,
    breadcrum//bs: myReadings,
  }} */
  return <LocationMap assetType={assetType} readings={{summary: entity?.myLoc, breadcrumbs: entity?.myReadings}} />
}
