import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { selectCurentUserRoles } from './store/slices/auth-slice';


const permittedRoutes = [
  '/globaldemand',
  '/unauthorized',
  '/profile'
];

export const usePageAccessChecker = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const currRoles = useSelector(selectCurentUserRoles);
  
  useEffect(() => {
    if(!currRoles) return;
    let hasPermission = true;
    const isAffiliate = currRoles.find(r => r.roleId == 23);
    if(isAffiliate){
      if (!permittedRoutes.includes(location.pathname.toLowerCase())) {
        //not authorized
        hasPermission = false;
      }
    }
    if (!hasPermission) {
      navigate('/unauthorized');
    }
  }, [location, navigate, currRoles]);
};