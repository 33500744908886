import { createListView, FilterType, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { gasCustomerStore } from "../../../store/store";
import { customerGasCreate } from "./customer-gas-create";
import { customerPermissions } from "./customer-data-source";

export const gasCustomerList: ListView = {
  create: customerGasCreate,
  enableExport: true,
  permissions: customerPermissions,
  filters: [
    {
      type: FilterType.combo,
      label: 'State',
      fieldName: 'state',
    },
    {
      type: FilterType.simple,
      label: 'Active',
      fieldName: 'active',
      filterValue: true
    },
  ],
  columns: [
    {
      Header: 'Customer ID',
      accessor: 'customerId',
      type: ListViewColumnType.linkEdit,
      linkPath: ({value, row}) => `/customers/edit/gas/${value}`
    },
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      defaultSort: true,
    },
    {
      Header: 'Region',
      accessor: 'region',
    },
    {
      Header: 'Address',
      accessor: 'address',
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'State',
      accessor: 'state',
      filter: 'equals'
    },
    {
      Header: 'Status',
      accessor: 'status',
    },
    {
      Header: 'Active',
      accessor: 'active',
      hidden: true

    },
  ] as ListViewColumn[],
/*   pageHeader: 'Dewar Customers', */
  breadcrumbs: [
    {
      name: 'Customers',
      icon: 'people',
      to: '/customers',
    },
  ],

};

export const GasCustomerList = createListView(gasCustomerList, gasCustomerStore as any);

export const GasCustomerSelectList = createListView({
  ...gasCustomerList,
  noGlobalFilterPadding: true,
  toolbar: undefined,
  enableExport: false,
  create: undefined,
  viewOptions: undefined,
  selectionMode: 'single',
  columns: [
    {
      type: ListViewColumnType.radio,
      accessor: 'checked',
      excludeGlobalFilter: true,
    },
    ...gasCustomerList.columns
  ]
}, gasCustomerStore as any);