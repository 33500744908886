import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { getFormFieldCollection, guid } from "../../../../services/form-helpers";
import { Accordion, Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { useTable, useRowSelect } from 'react-table';
import { ListViewColumnType } from "../list-view";
import { BsPencilFill, BsPlusLg, BsXLg } from "react-icons/bs";
import { MultiSelectFilter } from "../../atoms/Filters/MultiSelectFilter";
import "./EditTable.scss";
import RowSelectTable from "./row-select-table";
/* import RowSelectTable from "./row-select-table"; */
/* import { FrameWorkView } from "../view"; */
import { EditTableRow } from "./EditTableRow";
import { FormItemType } from "../component-map";
import { useSelector } from "react-redux";
import { selectCurentUserRoles } from "../../../../store/slices/auth-slice";
import _ from "lodash";

export interface EditTablePartitionProps {
  label: string,
  filter: (record: any) => boolean,
  emptyText?: string,
  id?: string
}

export interface EditTableProps {
  formState, renderItem, fieldErrors, entity, field, formView, pk
}

export const EditTable = ({ formView, formState: methods, renderItem: renderFormField, fieldErrors, entity, field }: EditTableProps) => {
  const { addTemplate, emptyText, name: tableName, columns } = field;
  const [data, setData] = useState([]);
  const [refreshKey, setRefreshKey] = useState(guid());
  const fields = useMemo(() => columns.filter(c => c.formItemProps).map(c => c.formItemProps), [columns]);
  const defaults = useMemo(() => getFormFieldCollection(fields, 'defaultValue'), [fields]);
  const [activeKey, setActiveKey] = useState('1');
  const [selection, setSelection] = useState([]);
  const tableInstance = useRef(null);
  const tableRef = tableInstance.current;
  useEffect(() => {
    if (entity) {
      setData(_.get(entity,tableName)|| []);
    }
  }, [entity])

  const { permissions } = formView;
  const currRoles = useSelector(selectCurentUserRoles);
  const writeAccess = useMemo(() => {
    if (!permissions || !permissions.write || !currRoles) return true;
    const readRoles = currRoles.find(r => permissions.write.includes(r.roleId));
    return readRoles;
  }, [currRoles, permissions]);



  const onRowSelect = (sel) => {
    setSelection(sel)
  }

  const addRow = function () {
    let l = data.length;
    const current = methods.getValues(tableName) || [];
    const d = [
      ...current,
      {
        ...(addTemplate ? addTemplate({ getValues: methods.getValues }) : defaults),
      }
    ];

    setData(d);
    methods.setValue(tableName, d);
    setActiveKey('1');
  };

  const removeRow = function (idx) {
    const current = methods.getValues(tableName) || [];
    let l = current.filter((d, rowIdx) => rowIdx != idx)
    setData(l);
    methods.setValue(tableName, l);
    setRefreshKey(guid());
  };

  const getPartitions = (partitionConfig) => {
    let collections = [] as any[];
    for (let partition of partitionConfig) {
      let filtered = data.filter(record => partition.filter(record));
      collections.push(filtered)
    }
    return collections
  }

  const partitions = useMemo(() => {
    const p = field.partitionView ? getPartitions(field.partitionView) : null;
    return p;
  }, [data, field?.partitionView]);

  const subRenderers = field.subRenderers;

  const renderAccordian = () => {
    if (field.partitionView) {
      return (
        <Accordion className="edit-accordion" activeKey={activeKey} onSelect={(newKey: any) => setActiveKey(newKey)}>
          {
            field.partitionView?.map((partition, idx) => {
              return (
                <Accordion.Item key={idx} eventKey={idx.toString()}>
                  <Accordion.Header><Form.Label style={{ paddingLeft: 10, paddingTop: 6 }}>{partition.label}</Form.Label></Accordion.Header>
                  <Accordion.Body  >
                    {renderEditTable(partition, partitions, idx)}
                  </Accordion.Body>
                </Accordion.Item>
              );
            })
          }
        </Accordion>
      )
    }
  }

  const renderEditTable = (partition?: EditTablePartitionProps, partitions?: any[], idx?: number) => {
    const filtered = partitions ? partitions[idx] : data;
    let startIdx = 0;
    for (let i = 0; i < idx; i++) {
      startIdx += partitions[i].length;
    }
    const emptyTextVal = partition?.emptyText ?? emptyText ?? 'There are currently no records';

    const editTableProps = {
      columns,
      data,
      ref: tableInstance,
      formItemRenderer: renderFormField,
      tableName: tableName,
      onRowSelect: onRowSelect
    } as any;
    return !filtered?.length ? <Form.Label>{emptyTextVal}</Form.Label> : field.selectable ?
      <RowSelectTable
        {...editTableProps}
      /> :
      <Table1
        writeAccess={writeAccess}
        field={field}
        selectable={field.selectable}
        tableName={tableName}
        ref={methods.register}
        columns={columns}
        data={filtered}
        methods={methods}
        renderFormField={renderFormField}
        removeRow={removeRow}
        pk={field.pk}
        partition={partition}
        partitions={partitions}
        startIdx={startIdx}
        fullCollection={data}
      />;
  }

  const renderEditComponent = () => {
    if (field.partitionView) {
      return renderAccordian();
    }
    return renderEditTable()
  }
  const renderController = () => {
    return (
      <Controller
        key={refreshKey}
        name={tableName}
        control={methods.control}
        render={({
          field: {
            onChange, value, ref,
          },
          fieldState: {
            isTouched, isDirty, error,
          },
          formState,
        }) => {
          return (
            renderEditComponent()
          );
        }}
      />
    );
  }


/*   const hideAddButton = useMemo(() => {
    if (field.hideAddButton != null && typeof field.hideAddButton == 'function') {
      const val = field.hideAddButton({ getValues: methods.getValues });
    }
  }, []); */


  return (
    <>
      <Row style={{ marginBottom: 6 }}>
        {!field.hideAddButton && <Col className="col-md-auto">
          <Button
            size="sm"
            variant="primary"
            onClick={addRow}
            disabled={!writeAccess}
          >
            Add
          </Button>
        </Col>}
        <Col>
          {
            field.toolbar?.map(tb => renderFormField({ ...tb, style: { marginLeft: 6 } }, null, { data, setData, partitions, setActiveKey }))
          }
        </Col>
      </Row>
      <Row>
        <Col>
          {renderController()}
        </Col>
      </Row>
    </>
  )
}


export const Table1 = React.forwardRef(({ writeAccess, field, editTableProps, subRenderers, singleSelectMode, selectable, columns, data, fullCollection, methods, tableName, renderFormField, removeRow, pk, partitions, partition, startIdx }: any, ref) => {
  // Use the state and functions returned from useTable to build your UI
  let columnsAdapted = columns;
  if (selectable) {
    columnsAdapted = [
      {
        id: 'selection',
        Header: !singleSelectMode ? ({ toggleRowSelected, isAllPageRowsSelected, page, rows }) => {
          const modifiedOnChange = (event) => {
            rows.forEach((row) => {
              // check each row if it is not disabled
              if (!row.original.disabled) {
                toggleRowSelected(row.id, event.currentTarget.checked);
              }
            });
          };
          // Count number of selectable and selected rows in the current page
          // to determine the state of select all checkbox
          let selectableRowsInCurrentPage = 0;
          let selectedRowsInCurrentPage = 0;
          rows.forEach((row) => {
            if (row.isSelected) { selectedRowsInCurrentPage += 1; }
            if (!row.original.disabled) { selectableRowsInCurrentPage += 1; }
          });
          // If there are no selectable rows in the current page
          // select all checkbox will be disabled -> see page 2
          const disabled = selectableRowsInCurrentPage === 0;
          const checked = (isAllPageRowsSelected
            || selectableRowsInCurrentPage === selectedRowsInCurrentPage)
            && !disabled;
          return (
            <div>
              <IndeterminateCheckbox
                onChange={modifiedOnChange}
                checked={checked}
                disabled={disabled}
              />
            </div>
          );
        } : () => <div />,

        Cell: ({ row }) => (
          <div>
            <IndeterminateCheckbox
              disabled={row.original.disabled}
              {...row.getToggleRowSelectedProps()}
            />
          </div>
        ),
      },
      ...columns
    ]
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable({
    columns: columnsAdapted,
    data,
    initialState: {
      hiddenColumns: columnsAdapted.filter(x => x.hidden).map(c => c.accessor),
    },

  }
  );

  const [checked, setChecked] = useState(false);
  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, disabled, ...rest }: any, ref1) => {
      const defaultRef = React.useRef();
      const resolvedRef = (ref1 || defaultRef) as any;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      if (disabled) {
        return (
          <div>
            <input type="checkbox" ref={resolvedRef} checked={checked} disabled {...rest} />
          </div>
        );
      }
      return (
        <input type="checkbox" ref={resolvedRef} checked={checked} {...rest} />
      );
    },
  );

  const getSubRenderer = (rowIdx) => {
    if (!subRenderers) return null;
    const fVals = methods.getValues(tableName)
    const subR = subRenderers?.find(r => r.renderCondition({ row: fVals[rowIdx] }));
    return subR;
  }

  const getRowEntity = useCallback((rowIdx) => {
    const fVals = methods.getValues(tableName);
    return { ...fVals[rowIdx] };
  }, [methods]);


  // Render the UI for your table
  return (
    <table {...getTableProps()} className={`he3-edittable ${field.variant == 'tabular' ? 'he3-edit-tabular' : ''}`} style={{ position: 'relative', width: '100%' }}>
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <th {...column.getHeaderProps()} style={{
                width: column.fixedWidth,
                paddingLeft: column.formItemProps?.type == FormItemType.checkbox ? 6 : undefined,
                position: 'sticky',
                top: 0,
                background: 'white',
                boxShadow: 'inset 0 -2px 0 #000000',
                paddingBottom: '0.3rem'
              }}>{column.render("Header")}</th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, idx) => {
          const i = fullCollection.indexOf(row.original);
          prepareRow(row);
          return (
            <>
              <EditTableRow
                writeAccess={writeAccess}
                columnsAdapted={columnsAdapted}
                row={row}
                removeRow={removeRow}
                renderFormField={renderFormField}
                tableName={tableName}
                getRowEntity={getRowEntity}
                editTableProps={editTableProps}
                i={i}
                methods={methods}
                partitions={partitions}
                partition={partition}
                pk={pk}
              />
              <tr className="m-0 p-0">
                <td className="m-0 p-0 border-0" colSpan={row.cells.length}>
                  <div style={{ marginLeft: 20 }}>
                    {
                      getSubRenderer(i)?.form?.items?.map(item => {
                        return renderFormField({
                          ...item,
                          name: `${tableName}[${i}][subRow][${item.name}]`,
                        }, i, { ...editTableProps, rowEntity: getRowEntity(i), rowIdentity: i })
                      })
                    }
                  </div>
                </td>
              </tr>
            </>
          );
        })}
      </tbody>
    </table>
  );
});