import { FormItem, FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";

export const gasPurity = [
  {
    type: FormItemType.row,
    items: [
      { name: 'Neon Max', label: 'Neon Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.neonMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Carbon Dioxide Max', label: 'Carbon Dioxide Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.carbonDioxideMax',  defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Carbon Monoxide Max', label: 'Carbon Monoxide Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.carbonMonoxideMax',  defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Methane Max', label: 'Methane Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.methaneMax',  defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'THC Max', label: 'THC Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.thcmax',  defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Oxygen Max', label: 'Oxygen Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.oxygenMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Nitrogen Max', label: 'Nitrogen Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.nitrogenMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Moisture Max', label: 'Moisture Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.moistureMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Hydrogen Max', label: 'Hydrogen Max', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.hydrogenMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Odor', label: 'Odor', type: FormItemType.label, width: 180, },
      { name: 'gasPurity.odor', defaultValue: true, type: FormItemType.checkboxField },
    ]
  },

] as FormItem[];
