import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsXCircleFill } from 'react-icons/bs';

function InactiveIcon() {
  const renderTooltip = (props) => (
    <Tooltip id="tooltip-top" {...props}>
      Inactive
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="left"
      overlay={renderTooltip}
    >
      <div>
        <BsXCircleFill className="text-danger" />
      </div>
    </OverlayTrigger>
  );
}

export default InactiveIcon;
