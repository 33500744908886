import React, { useEffect } from "react";
import { Form } from "react-bootstrap";

export const CustomField = ({ field, watch, setValue, editMode, editTableProps, rowIdentity, fieldProps, register, required, fieldErrors, unregister }) => {
  const CustomComponent = field.getComponent ? field.getComponent() : field.component;
  const { onChange: onCustomFieldChange } = field;
  const hide = field.hideIf ? field.hideIf({ editTableProps, rowIdentity, watch }) : false;

  useEffect(() => {
    if (hide) {
      unregister(field.name)
    } else {
      register(field.name, {
        required: required && 'Required',
        min: typeof field.min != 'undefined' ? { value: field.min, message: 'must be greater than 0' } : undefined,
        maxLength: field.maxLength,
        minLength: field.minLength
      })
    }

  }, [register, hide]);

  return hide ? null : (
    <Form.Group className="mb-1" style={field.style}>
      {field.label && <Form.Label>{field.label}</Form.Label>}
      {
        fieldErrors && fieldErrors.type === "required" && <Form.Control.Feedback type="invalid" style={{ display: 'block' }}>
          {'this field is required'}
        </Form.Control.Feedback>
      }
      <CustomComponent
        fieldProps={fieldProps}
        onChange={(newValue) =>  {
          if(onCustomFieldChange)
            onCustomFieldChange({ watch, setValue, newValue, editMode })
        }}
      />

    </Form.Group>
  )
}