import React from 'react';
import { Typeahead as TypeAheadInternal } from 'react-bootstrap-typeahead';

export const Typeahead = React.forwardRef((props, ref) => {
  const { autoSort, options } = props;
  const customSort = autoSort !== false && typeof props.labelKey !== 'function';
  // eslint-disable-next-line no-nested-ternary
  const ordered = React.useMemo(() => (customSort === false ? options
    : props.labelKey ? options?.sort((a, b) => a[props.labelKey].trim().localeCompare(b[props.labelKey].trim()))
    // eslint-disable-next-line no-undef
      : options?.sort((a, b) => a.trim().localeCompare(b.trim()))), [props.options]);

  return (
    <TypeAheadInternal
      {...props}
      ref={ref}
      options={ordered}
    />
  );
});
