import { GetVendorList, UpdateVendor } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";
import { CreateVendor } from "../../../services/requests";

export const vendorDataSource = {
  name: 'vendors',
  pk: 'vendorId',
  request: GetVendorList,
  createRequest: CreateVendor,
  updateRequest: UpdateVendor
} as DataSource