/* eslint-disable max-len */
import './navbar.scss';

import axios from 'axios';
import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import {
  Button,
  Nav, Navbar,
} from 'react-bootstrap';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { BsXLg } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { splitStringOnUppercase } from '../../../../services/format';
import { selectMaintenanceRequestDropdownObjects } from '../../../../store/slices/maintenance-requests-slice';
import ActiveLocationNavItem from '../../molecules/ActiveLocationNavItem/active-location-nav-item';

function RNNavbar(props) {
  const PER_PAGE = 50;
  const navigate = useNavigate();
  const customerTypes = ['Liquid Customer', 'Gas Customer', 'Packaged Customer'];
  const breadCrumbPaths = ['assets', 'admin', 'packaged-product', 'liquid-container', 'gas-container', 'chassis'];
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const [query, setQuery] = useState('');
  const [options, setOptions] = useState([]);
  const [assetApiFailed, setAssetApiFailed] = useState(false);
  const assetNavigateRoute = {
    0: 'gas-container', 1: 'liquid-container', 2: 'packaged-product', 3: 'chassis',
  };
  const searchRef = useRef(null);

  const maintenanceDropdownValues = useSelector(selectMaintenanceRequestDropdownObjects);
  const initialSearchedAsset = {
    serialNumber: '',
    isActive: '',
    assetType: '',
    id: '',
  };
  const [searchedAsset, setSearchedAsset] = useState(initialSearchedAsset);
  let CACHE = {};

  let assetSearchPlaceholder;
  if (assetApiFailed) {
    assetSearchPlaceholder = 'Error loading results';
  } else if (isLoading) {
    assetSearchPlaceholder = 'Loading...';
  } else {
    assetSearchPlaceholder = 'Search...';
  }
  const getCustomerType = (record) => {
    if (record.assetType === 'Liquid Customer') {
      return 'liquid';
    }
    if (record.assetType === 'Gas Customer') {
      return 'gas';
    }
    return 'dewars';
  };
  const getEntityType = (record) => {
    if (customerTypes.includes(record.assetType)) return 'customer';
    return 'asset';
  };
  const getLink = (option) => {
    const theType = getEntityType(option);
    if (theType === 'asset') {
      let routingId = '';
      if (option.assetType === 2) {
        routingId = option.id;
      } else {
        routingId = option.serialNumber;
      }
      return `/assets/${assetNavigateRoute[option.assetType]}/${routingId}`;
    }

    return `/customers/edit/${getCustomerType(option)}/${option.serialNumber}`;
  };
  const getLinkText = (option) => {
    const theType = getEntityType(option);
    if (theType === 'asset') {
      return option && option.assetType + 1 in maintenanceDropdownValues.assetTypes ? splitStringOnUppercase(maintenanceDropdownValues.assetTypes[option.assetType + 1].name) : 'N/A';
    }
    return option.assetType;
  };
  const handleInputChange = (q) => {
    setQuery(q);
  };

  function makeAndHandleAssetRequest(searchValue, page = 1) {
    return axios.get(`/AssetManagement/Assets?searchValue=${searchValue}`)
      .then((resp) => resp.data);
  }
  function makeAndHandleCustomerRequest(searchValue, page = 1) {
    return axios.get(`/Customer/SearchCustomer/${searchValue}`)
      .then((resp) => resp.data);
  }

  const makeAndHandleRequest = async (searchValue, page = 1) => {
    const [Promise1Result, Promise2Result] = await Promise.allSettled([makeAndHandleAssetRequest(searchValue, page), makeAndHandleCustomerRequest(searchValue, page)]);
    return [...Promise1Result.value, ...Promise2Result.value];
  };

  useEffect(() => {
    searchRef.current.clear();
  }, [location.pathname]);

  const handleSearch = useCallback((q) => {
    if (CACHE[q]) {
      setOptions(CACHE[q].assetSearch.sort((a, b) => a.serialNumber.trim().localeCompare(b.serialNumber.trim())));
      return;
    }

    setIsLoading(true);
    makeAndHandleRequest(q, '').then((resp) => {
      CACHE[q] = { assetSearch: resp };
      setIsLoading(false);
      setOptions(resp.sort((a, b) => a.serialNumber.trim().localeCompare(b.serialNumber.trim())));
      setAssetApiFailed(false);
    }).catch((err) => {
      setAssetApiFailed(true);
    });
  }, [searchedAsset]);

  const resetSearch = () => {
    if (query in CACHE) {
      CACHE = {};
    }
    setSearchedAsset(initialSearchedAsset);
    setQuery('');
    setOptions([]);
    setAssetApiFailed(false);
    setIsLoading(false);
  };

  return (
    <Navbar className="main-header navbar navbar-expand navbar-primary navbar-dark custom-navbar">
      <Nav>
        <Nav.Item className="mx-3"><Button onClick={props.toggleClick} variant="outline-none"><span className="navbar-toggler-icon" /></Button></Nav.Item>
      </Nav>
      <AsyncTypeahead
        clearButton
        ref={searchRef}
        className="w-25 global-search-nav-input"
        id="async-pagination-example"
        isLoading={isLoading}
        filterBy={() => true}
        labelKey="serialNumber"
        maxResults={PER_PAGE - 1}
        minLength={4}
        onInputChange={handleInputChange}
        onSearch={handleSearch}
        options={options}
        onKeyDown={(e) => {
          if (e.key === 'Escape') {
            searchRef.current.clear();
            setQuery('');
          }
        }}
        paginate
        delay={500}
        useCache={false}
        placeholder={assetSearchPlaceholder}
        onChange={(e) => {
          try {
            if (e[0]) {
              setSearchedAsset(e[0]);
            }
          } catch (err) {
            console.error(err);
          }
        }}
        renderMenuItemChildren={(option) => {
          let routingId = '';
          if (option.assetType === 2) {
            routingId = option.id;
          } else {
            routingId = option.serialNumber;
          }
          return (
            <NavLink
              onClick={() => resetSearch()}
              to={getLink(option)}
            >
              <div key={option.serialNumber}>
                <span className="d-inline-flex w-100 h-100 justify-content-between">
                  {option.serialNumber}
                  {option.subHeading ? ` - ${option.subHeading}` : ''}
                </span>
                <div className="d-flex flex-row justify-content-between">
                  <div className="flex-md-column">
                    <small>
                      {getLinkText(option)}
                    </small>
                  </div>
                </div>
              </div>
            </NavLink>
          );
        }}
      >
        {({ onClear }) => (
          <div className="rbt-aux">
            {(!isLoading && query !== '') && <BsXLg onClick={onClear} />}
          </div>
        )}
      </AsyncTypeahead>

      <Nav className="ml-auto">
        <ActiveLocationNavItem />
      </Nav>
    </Navbar>
  );
}
// eslint-disable-next-line max-len, no-lone-blocks
{ /* <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown2" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        Help
      </a> */ }
// eslint-disable-next-line no-lone-blocks
{ /* <div class="dropdown-menu" aria-labelledby="navbarDropdown2">
        <a class="dropdown-item" href="#">FAQ</a>
        <a class="dropdown-item" href="#">Support</a>
        <div class="dropdown-divider"></div>
        <a class="dropdown-item" href="#">Contact</a>
      </div>
    </li> */ }

export default RNNavbar;
