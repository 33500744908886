import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createConnectedView } from "../../UI/view/view";
import { groupingsDataSource } from "./groupings-ds";

export const groupingsCreate = {
  editMode: false,
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Save Liquid Customer Grouping',
      items: [
        {
          label: 'Customer Name',
          name: 'customerName',
          required: true,
        },
        {
          label: 'Segment',
          name: 'segment',
          type: FormItemType.select,
          defaultValue: 'Americas',
          options: [
            {
              text: 'Americas',
              id: 'Americas'
            },
            {
              text: 'APAC',
              id: 'APAC'
            },
            {
              text: 'EMEA',
              id: 'EMEA'
            },
          ],
          required: true,
        },
        {
          label: 'Grouping',
          name: 'grouping',
          required: true,
        },
        {
          label: 'Sales Manager',
          name: 'salesManager',
          required: true,
        },
      ]
    }
  ]
} as FormView;

export const groupingsEdit = {
  ...groupingsCreate,
  editMode: true
}

export const groupingsCreateForm = createConnectedView({form: groupingsCreate,dataSource: groupingsDataSource})