import { createAsyncThunk, createSlice, current } from '@reduxjs/toolkit';
import { FilterDefinition, ListViewColumn } from './list-view';
import { capitalize } from '../../../services/format';
import { uniqueList } from '../../../services/form-helpers';

export const convertFilterStateToQuery = (filterState: any, filters: FilterDefinition[], columns: ListViewColumn[], globalText: string) => {

}

export const convertFilterDefinitionToString = (filterState: any) => {

}
export enum SortDirection {
  Asc,
  Desc
}
export interface SortDefinition {
  direction: SortDirection,
  path: string
}

export enum LogicalOperator {
  Equal,
  NotEqual,
  Like
}
export enum LogicalConnector {
  And,
  Or
}
export interface QueryFilter {
  accessor?: string,
  value?: any,
  logicalOperator?: LogicalOperator,
  logicalConnector?: LogicalConnector,
  filters?: QueryFilter[],
  stringExpression?: boolean
}

export interface QueryDefinition {
  filters?: QueryFilter[],
  page: number,
  limit: number,
  sortDefinition?: SortDefinition,
  pKs?: any[],
  primaryKeyColumn?: string
}

export type DataSourceSelector = (state: any) => any;

export interface DataSourceRequest {
  name: string;
  request: (parameters?: any) => Promise<any>;
  appendData?: boolean;
}

export interface DataSource {
  name: string,
  pk: string,
  additionalSelector?: DataSourceSelector,
  requestArgs?: (state, getValues?: any, entity?: any) => any,
  request?(parameters?: any): Promise<any>,
  entityRequest?(parameters?: any): Promise<any>,
  entityRequestArgs?: ({getValues, additionaSelector}) => any,
  createRequest?(parameters?: any): Promise<any>,
  updateRequest?(parameters?: any): Promise<any>,
  skipLocalUpdate?: boolean,
  deleteRequest?(parameters?: any): Promise<any>,
  additionalRequests?: DataSourceRequest[],
  selector?: DataSourceSelector,
  serverSideEvents?: boolean,

  //only used for attachments currently.
  entityName?: string,
  entityTypeId?: any,

  //useful when the model in the backend needs to be converted to a different structure for the front-end
  //Note convertToViewModel will execute on the current entity.
  //Note convertToDataModel will execute right before form submission for insert and update requests
  convertToViewModel?: (dataModel: any) => any,
  convertToDataModel?: (viewModel: any) => any,
}

export interface Repository {
  fetchData: any,
  createAction: any,
  updateAction: any,
  deleteAction: any,
  reducer: any,
  dataSource: DataSource,
  actions: any,
  additionalActions: any
}

export const createReduxStore = (dataSource: DataSource) => {
  const { name: key, request, selector, createRequest, updateRequest, deleteRequest, pk, serverSideEvents, additionalRequests, skipLocalUpdate } = dataSource;

  const reqs = additionalRequests?.reduce((obj, current) => {
    obj[current.name] = {
      status: 'idle',
      error: null,
    }
    return obj;
  }, {});

  let initialState = {
    status: 'idle',
    error: null,
    data: [],
    total: 0,
    current: {
      data: null,
      status: 'idle',
      error: null,
    },
    createAction: {
      status: 'idle',
      error: null,
      createdEntity: null
    },
    updateAction: {
      status: 'idle',
      error: null,
    },
    deleteAction: {
      status: 'idle',
      error: null,
    },
    ...reqs
  } as any;
  initialState[key] = [];

  const additionalThunks = additionalRequests?.reduce((obj, current) => {
    obj[current.name] = createAsyncThunk(key + current.name, async (...args: any[]) => {
      const response = await current.request(...args);
      return response;
    });
    return obj;
  }, {});

  const fetchData = createAsyncThunk(key, async (...args: any[]) => {
    if (request) {
      let argument = [];
      const p = args && args?.length ? args[0] : null;
      if (p && p instanceof Array && p.length) {
        argument = p;
      } else if (p && p instanceof Object) {
        argument = [p];
      } else if(p != null){
        argument = [p];
      }
      const response = await request(...argument);
      return response;
    } else {
      //no request provided. load data from selector
      return selector;
    }

  });

  const createAction = createAsyncThunk(key + '1', async (...args1: any[]) => {
    const response = await createRequest(...args1);
    return response;
  });

  const updateAction = createAsyncThunk(key + '2', async (...args2: any[]) => {
    const response = await updateRequest(...args2);
    return response;
  });

  const deleteAction = createAsyncThunk(key + '3', async (...args3: any[]) => {
    const response = await deleteRequest(...args3);
    return response;
  });

  const statusActions = additionalRequests?.reduce((obj, current) => {
    obj['set' + capitalize(current.name) + 'Status'] = (state, action) => {
      state[current.name].status = action.payload;
    };
    return obj;
  }, {});

  const dataSlice = createSlice({
    name: key,
    initialState,
    reducers: {
      setData: (state, action) => {
        state[key] = action.payload;
      },
      setCurrent: (state, action) => {
        state.current.data = action.payload;
      },
      setCreateStatus: (state, action) => {
        state.createAction.status = action.payload;
      },
      setUpdateStatus: (state, action) => {
        state.updateAction.status = action.payload;
      },
      ...statusActions
    },
    extraReducers(builder) {
      builder
        .addCase(fetchData.pending, (state, action) => {
          state.status = 'loading';
        })
        .addCase(fetchData.fulfilled, (state, action) => {
          if (!(action.payload instanceof Function)) {
            state.status = 'succeeded';
            if (serverSideEvents) {
              state.data = action.payload.result;
              state.total = action.payload.total;
            } else {
              state.data = action.payload; //conversion here
            }
          } else {
            //our payload is a selector! useful when computing a data source from other data sources
            state.status = 'succeeded';
            state.data = action.payload(state);
          }
        })
        .addCase(fetchData.rejected, (state: any, action: any) => {
          state.status = 'failed';
          state.error = action.error.message;
        });

      builder
        .addCase(createAction.pending, (state, action) => {
          state.createAction.status = 'loading';
        })
        .addCase(createAction.fulfilled, (state, action) => {
          state.createAction.status = 'succeeded';
          state.data = [
            ...state.data,
            action.payload,
          ];
          state.createAction.createdEntity = action.payload;
          state.createAction.error = null;
        })
        .addCase(createAction.rejected, (state: any, action: any) => {
          state.createAction.status = 'failed';
          state.createAction.error = action.error.message;
        });

      builder
        .addCase(updateAction.pending, (state, action) => {
          state.updateAction.status = 'loading';
        })
        .addCase(updateAction.fulfilled, (state, action) => {
          state.updateAction.status = 'succeeded';
          if (!skipLocalUpdate) {
            state.data = [
              ...state.data.filter(d => d[pk] != action.payload[pk]),
              action.payload,
            ];
            state.current.data = action.payload;
          }
          state.updateAction.error = null;
        })
        .addCase(updateAction.rejected, (state: any, action: any) => {
          state.updateAction.status = 'failed';
          state.updateAction.error = action.error.message;
        });

      builder
        .addCase(deleteAction.pending, (state, action) => {
          state.deleteAction.status = 'loading';
        })
        .addCase(deleteAction.fulfilled, (state, action) => {
          state.deleteAction.status = 'succeeded';
          state.data = [
            ...state.data.filter(d => d[pk] != action.payload[pk]),
            action.payload,
          ];
          state.deleteAction.error = null;
        })
        .addCase(deleteAction.rejected, (state: any, action: any) => {
          state.deleteAction.status = 'failed';
          state.deleteAction.error = action.error.message;
        });

      additionalRequests?.forEach(additionalRequest => {
        const additionalAction = additionalThunks[additionalRequest.name];
        const name = additionalRequest.name;
        const appendData = additionalRequest.appendData;
        builder
          .addCase(additionalAction.pending, (state, action) => {
            state[name].status = 'loading';
          })
          .addCase(additionalAction.fulfilled, (state, action) => {
            state[name].status = 'succeeded';
            state[name].data = action.payload;
            state[name].error = null;
          })
          .addCase(additionalAction.rejected, (state: any, action: any) => {
            state[name].status = 'failed';
            state[name].error = action.error.message;
          });

      })
    },
  });

  const {
    setCurrent, setCreateStatus, setUpdateStatus, ...additionalActions
  } = dataSlice.actions;

  return { additionalActions: { ...additionalActions, ...additionalThunks } as any, fetchData, createAction, deleteAction, updateAction, reducer: dataSlice.reducer, dataSource, actions: { setCurrent, setCreateStatus, setUpdateStatus } };
}

