import React from 'react';
import { NavLink } from 'react-router-dom';

import ErrorPageTemplate from '../../templates/Errors/error-template';

const content = (
  <p>
    We could not find the page you were looking for. You may
    {' '}
    <NavLink to="/">return to the homescreen</NavLink>
    {' '}
    or wherever you&apos;d like to go.
  </p>
);

function NotFound(props) {
  return (
    <ErrorPageTemplate
      errorCode={404}
      pageTitle="'! Not Found'"
      title="Page Not Found."
      content={content}
    />
  );
}

export default NotFound;
