import { useCallback, useState } from 'react';

function useTablePageRef() {
  const [page, setPage] = useState(null);
  const [table, setTable] = useState(null);
  const ref = useCallback((node) => {
    if (node !== null) {
      setPage(node.page);
      setTable(node);
    }
  }, []);
  return [page, table, ref];
}

export { useTablePageRef };
