import React, { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { fetchAdminAuditLogs } from '../../../store/slices/audit-slice';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import AuditLogTable from './audit-log-table';

function AuditLog(props) {
  const dispatch = useDispatch();

  const breadcrumbs = useMemo(() => [
    {
      name: 'Audit Log',
      icon: 'file-text',
      to: '/audit-log',

    },
  ], []);

  useEffect(() => {
    // add condition to only call if in idel or non success state?
    dispatch(fetchAdminAuditLogs());
  }, []);

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column">
      <PageHeader header="Audit Log" breadcrumbs={breadcrumbs} />
      <AuditLogTable />
    </Container>
  );
}

export default AuditLog;
