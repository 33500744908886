import React, { useEffect, useMemo, useRef } from 'react';
import {
  Card,
  Col, Container, Row, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatDate } from '../../../../services/format';
import {
  fetchAssetOrderHistoryLogs, selectChassisOrderHistoryLogs,
} from '../../../../store/slices/asset-order-history-slice';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import PaginatedSelectTable from '../Table/paginated-select-table';

function ChassisHistory({ assetType, serialNumber }) {
  const assetOrderHistoryLogsFetchStatus = useSelector(
    (state) => state.assetOrderHistory.assetOrderHistoryLogsFetch.status,
  );

  const chassisOrderHistoryLogs = useSelector(
    selectChassisOrderHistoryLogs,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssetOrderHistoryLogs({ assetType, serialNumber }));
  }, []);

  const orderHistoryIsLoading = assetOrderHistoryLogsFetchStatus === 'loading';

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const orderHistoryColumns = useMemo(() => [
    {
      Header: 'Trk #',
      accessor: 'trackingNumberId',
    },
    {
      Header: 'Liquid Container',
      accessor: 'containerId',
      Cell: ({ value }) => (<NavLink to={`/assets/liquid-container/${value}`}>{value}</NavLink>),
    },
    {
      Header: 'Location',
      accessor: 'shippingLocationName',
    },
    {
      Header: 'Arrival',
      accessor: 'inventoryDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'Ship',
      accessor: 'shipDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'Fill Customer',
      accessor: 'fillCustomerName',
    },
  ], [orderHistoryIsLoading]);

  const orderHistortyTableInstance = useRef(null);

  const orderHistoryData = useMemo(() => {
    if (chassisOrderHistoryLogs) {
      return chassisOrderHistoryLogs;
    }
    return [];
  }, [chassisOrderHistoryLogs]);

  return (
    <div className="h-100 d-flex">
      <Spinner animation="border" variant="primary" className={`create-spinner${orderHistoryIsLoading ? ' visible' : ' invisible'}`} />
      <Container fluid className="h-100 overflow-auto py-1">
        <Row className="h-100">
          <Col className="h-100">
            <Card className="card-secondary card-outline flex-grow-1 p-0 m-0 overflow-auto h-100">
              <Card.Header>
                <div className="d-flex justify-content-between mb-1">
                  <h3 className="card-title">History</h3>
                </div>
              </Card.Header>
              <Card.Body className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
                <div className="h-100">
                  <div className={`d-flex flexbox flex-column overflow-auto flex-grow-1 h-100${orderHistoryIsLoading ? ' creation-loading' : ''}`}>
                    <PaginatedSelectTable
                      columns={orderHistoryColumns}
                      data={orderHistoryData}
                      ref={orderHistortyTableInstance}
                      rowProps={() => ({})}
                    />
                  </div>
                </div>
              </Card.Body>
            </Card>

          </Col>
        </Row>

      </Container>

    </div>
  );
}

export default ChassisHistory;
