/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  getPurchaseOrderPartsPurchased,
  getPurchaseOrders,
  getPurchaseOrderSingle,
  getShipmentsByPurchaseOrder,
  postLogShipment,
  postPurchaseOrder,
  putPurchaseOrder,
  putPurchaseOrderStatus,
} from '../../services/requests';

const initialState = {
  purchaseOrders: {},
  purchaseOrdersFetch: {
    status: 'idle',
    error: null,
  },
  shipmentsByPurchaseOrder: {},
  shipmentsByPurchaseOrderFetch: {
    status: 'idle',
    error: null,
  },
  purchaseOrdersFetchSingle: {
    status: 'idle',
    error: null,
  },
  currentPurchaseOrderId: null,
  purchaseOrderCreate: {
    status: 'idle',
    error: null,
  },
  purchaseOrderUpdate: {
    status: 'idle',
    error: null,
  },
  partsPurchased: {

  },
  purchaseOrderPartsPurchasedFetch: {
    status: 'idle',
    error: null,
  },
  logShipment: {
    status: 'idle',
    error: null,
  },

};

export const fetchPurchaseOrders = createAsyncThunk('purchaseOrders/fetchPurchaseOrders', async () => {
  const response = await getPurchaseOrders();
  return response;
});

export const fetchPurchaseOrderSingle = createAsyncThunk('purchaseOrders/fetchPurchaseOrderSingle', async (purchaseOrderId) => {
  const response = await getPurchaseOrderSingle(purchaseOrderId);
  return response;
});

export const fetchPurchaseOrderPartsPurchased = createAsyncThunk('purchaseOrders/fetchPurchaseOrderPartsPurchased', async (purchaseOrderId) => {
  const response = await getPurchaseOrderPartsPurchased(purchaseOrderId);
  return response;
});

export const logShipmentRequest = createAsyncThunk('purchaseOrders/logShipmentRequest', async (
  { purchaseOrderId, logShipmentRequestBody },
) => {
  const response = await postLogShipment(purchaseOrderId, logShipmentRequestBody);
  return response;
});

export const createPurchaseOrder = createAsyncThunk('purchaseOrders/createPurchaseOrder', async (purchaseOrder) => {
  const response = await postPurchaseOrder(purchaseOrder);
  return response;
});

export const updatePurchaseOrder = createAsyncThunk('purchaseOrders/updatePurchaseOrder', async (purchaseOrder) => {
  const response = await putPurchaseOrder(purchaseOrder);
  return response;
});

export const updatePurchaseOrderStatus = createAsyncThunk('purchaseOrders/updatePurchaseOrderStatus', async ({ purchaseOrderId, purchaseOrderStatusId }) => {
  const response = await putPurchaseOrderStatus(purchaseOrderId, purchaseOrderStatusId);
  return response;
});

export const fetchShipmentsByPurchaseOrder = createAsyncThunk('purchaseOrders/fetchShipmentsByPurchaseOrders', async (purchaseOrderId) => {
  const response = await getShipmentsByPurchaseOrder(purchaseOrderId);
  return response;
});

export const purchaseOrdersSlice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: {
    setFetchPurchaseOrdersStatus: (state, action) => {
      state.purchaseOrdersFetch.status = action.payload;
    },
    setFetchPurchaseOrderSingleStatus: (state, action) => {
      state.purchaseOrdersFetchSingle.status = action.payload;
    },
    setCurrentPurchaseOrderId: (state, action) => {
      state.currentPurchaseOrderId = action.payload;
    },
    setCreatePurchaseOrderStatus: (state, action) => {
      state.purchaseOrderCreate.status = action.payload;
    },
    setUpdatePurchaseOrderStatus: (state, action) => {
      state.purchaseOrderUpdate.status = action.payload;
    },
    setLogShipmentStatus: (state, action) => {
      state.logShipment.status = action.payload;
    },
    setPurchaseOrderPartsPurchasedFetchStatus: (state, action) => {
      state.purchaseOrderPartsPurchasedFetch.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPurchaseOrderSingle.pending, (state, action) => {
        state.purchaseOrdersFetchSingle.status = 'loading';
      })
      .addCase(fetchPurchaseOrderSingle.fulfilled, (state, action) => {
        state.purchaseOrdersFetchSingle.status = 'succeeded';
        state.purchaseOrders = {
          ...state.purchaseOrders,
          [action.payload.purchaseOrderId]: action.payload,
        };
        state.purchaseOrdersFetchSingle.error = null;
      })
      .addCase(fetchPurchaseOrderSingle.rejected, (state, action) => {
        state.purchaseOrdersFetchSingle.status = 'failed';
        state.purchaseOrdersFetchSingle.error = action.error.message;
      });

    builder
      .addCase(fetchPurchaseOrders.pending, (state, action) => {
        state.purchaseOrdersFetch.status = 'loading';
      })
      .addCase(fetchPurchaseOrders.fulfilled, (state, action) => {
        state.purchaseOrdersFetch.status = 'succeeded';
        state.purchaseOrders = action.payload.reduce((current, p) => ({
          ...current,
          [p.purchaseOrderId]: p,
        }), {});
        state.purchaseOrdersFetch.error = null;
      })
      .addCase(fetchPurchaseOrders.rejected, (state, action) => {
        state.purchaseOrdersFetch.status = 'failed';
        state.purchaseOrdersFetch.error = action.error.message;
      });

    builder
      .addCase(createPurchaseOrder.pending, (state, action) => {
        state.purchaseOrderCreate.status = 'loading';
      })
      .addCase(createPurchaseOrder.fulfilled, (state, action) => {
        state.purchaseOrderCreate.status = 'succeeded';
        state.purchaseOrders = {
          ...state.purchaseOrders,
          [action.payload.purchaseOrderId]: action.payload,
        };
        state.purchaseOrderCreate.error = null;
      })
      .addCase(createPurchaseOrder.rejected, (state, action) => {
        state.purchaseOrderCreate.status = 'failed';
        state.purchaseOrderCreate.error = action.error.message;
      });

    builder
      .addCase(updatePurchaseOrder.pending, (state, action) => {
        state.purchaseOrderUpdate.status = 'loading';
      })
      .addCase(updatePurchaseOrder.fulfilled, (state, action) => {
        state.purchaseOrderUpdate.status = 'succeeded';
        state.purchaseOrders = {
          ...state.purchaseOrders,
          [action.payload.purchaseOrderId]: action.payload,
        };
        state.purchaseOrderUpdate.error = null;
      })
      .addCase(updatePurchaseOrder.rejected, (state, action) => {
        state.purchaseOrderUpdate.status = 'failed';
        state.purchaseOrderUpdate.error = action.error.message;
      });

    builder
      .addCase(updatePurchaseOrderStatus.pending, (state, action) => {
        state.purchaseOrderUpdate.status = 'loading';
      })
      .addCase(updatePurchaseOrderStatus.fulfilled, (state, action) => {
        state.purchaseOrderUpdate.status = 'succeeded';
        state.purchaseOrders = {
          ...state.purchaseOrders,
          [action.payload.purchaseOrderId]: action.payload,
        };
        state.purchaseOrderUpdate.error = null;
      })
      .addCase(updatePurchaseOrderStatus.rejected, (state, action) => {
        state.purchaseOrderUpdate.status = 'failed';
        state.purchaseOrderUpdate.error = action.error.message;
      });

    builder
      .addCase(fetchPurchaseOrderPartsPurchased.pending, (state, action) => {
        state.purchaseOrderPartsPurchasedFetch.status = 'loading';
      })
      .addCase(fetchPurchaseOrderPartsPurchased.fulfilled, (state, action) => {
        state.purchaseOrderPartsPurchasedFetch.status = 'succeeded';
        state.partsPurchased = {
          ...state.partsPurchased,
          [action.meta.arg]: action.payload,
        };
        state.purchaseOrderPartsPurchasedFetch.error = null;
      })
      .addCase(fetchPurchaseOrderPartsPurchased.rejected, (state, action) => {
        state.purchaseOrderPartsPurchasedFetch.status = 'failed';
        state.purchaseOrderPartsPurchasedFetch.error = action.error.message;
      });

    builder
      .addCase(logShipmentRequest.pending, (state, action) => {
        state.logShipment.status = 'loading';
      })
      .addCase(logShipmentRequest.fulfilled, (state, action) => {
        state.logShipment.status = 'succeeded';
        state.logShipment.error = null;
        state.purchaseOrders[action.payload.purchaseOrderId].shipments = [
          ...state.purchaseOrders[action.payload.purchaseOrderId].shipments,
          action.payload];
      })
      .addCase(logShipmentRequest.rejected, (state, action) => {
        state.logShipment.status = 'failed';
        state.logShipment.error = action.error.message;
      });

    builder
      .addCase(fetchShipmentsByPurchaseOrder.pending, (state, action) => {
        state.shipmentsByPurchaseOrderFetch.status = 'loading';
      })
      .addCase(fetchShipmentsByPurchaseOrder.fulfilled, (state, action) => {
        state.shipmentsByPurchaseOrderFetch.status = 'succeeded';
        state.shipmentsByPurchaseOrder = action.payload.reduce((current, p) => ({
          ...current,
          [p.shipmentId]: p,
        }), {});
        state.shipmentsByPurchaseOrderFetch.error = null;
      })
      .addCase(fetchShipmentsByPurchaseOrder.rejected, (state, action) => {
        state.shipmentsByPurchaseOrderFetch.status = 'failed';
        state.shipmentsByPurchaseOrderFetch.error = action.error.message;
      });
  },
});

export const {
  setFetchPurchaseOrdersStatus,
  setCreatePurchaseOrderStatus,
  setUpdatePurchaseOrderStatus,
  setFetchPurchaseOrderSingleStatus,
  setCurrentPurchaseOrderId,
  setLogShipmentStatus,
  setPurchaseOrderPartsPurchasedFetchStatus,
} = purchaseOrdersSlice.actions;

export const selectPurchaseOrders = (state) => state.purchaseOrder.purchaseOrders;

export const selectCurrentPurchaseOrderId = (state) => state.purchaseOrder.currentPurchaseOrderId;

export const selectPartsPurchased = (state) => state.purchaseOrder.partsPurchased;

export const selectShipmentsByPurchaseOrder = (state) => state.purchaseOrder.shipmentsByPurchaseOrder;

export const selectCurrentPurchaseOrder = createSelector([
  selectPurchaseOrders,
  selectCurrentPurchaseOrderId], (purchaseOrders, currentPurchaseOrderId) => {
  if (currentPurchaseOrderId == null) {
    return {};
  }
  return purchaseOrders[currentPurchaseOrderId];
});

export const selectShipmentsByCurrentPurchaseOrder = createSelector([
  selectCurrentPurchaseOrder], (purchaseOrder) => {
  if (purchaseOrder == null) {
    return {};
  }
  return purchaseOrder.shipments;
});

export const selectCurrentPurchaseOrderPartsPurchased = createSelector([
  selectPartsPurchased,
  selectCurrentPurchaseOrderId], (partsPurchased, currentPurchaseOrderId) => {
  if (currentPurchaseOrderId == null) {
    return {};
  }
  return partsPurchased[currentPurchaseOrderId] ?? {};
});

export const selectCurrentPurchaseOrderPartsPurchasedByBOE = createSelector([
  selectPartsPurchased,
  selectCurrentPurchaseOrderId], (partsPurchased, currentPurchaseOrderId) => {
  if (currentPurchaseOrderId == null) {
    return {};
  }
  const partsPurchasedList = Object.values(partsPurchased[currentPurchaseOrderId] ?? {});

  return partsPurchasedList.reduce((current, partPurchased) => {
    const currentBOE = partPurchased?.billOfEntry ?? 'local';
    if (currentBOE in current) {
      const currentBOEPurchaseRequests = current[currentBOE]?.purchaseRequests ?? {};
      current[currentBOE] = {
        ...current[currentBOE],
        purchaseRequests: {
          ...currentBOEPurchaseRequests,
          [partPurchased.purchaseRequestId]: {
            count: (currentBOEPurchaseRequests[partPurchased.purchaseRequestId]?.count ?? 0) + 1,
          },
        },
      };
    } else {
      current[currentBOE] = {
        purchaseRequests: {
          [partPurchased.purchaseRequestId]: {
            count: 1,
          },
        },
      };
    }
    return current;
  }, { local: {} });
});

export const selectCurrentPurchaseOrderPartsPurchasedByPurchaseRequestId = createSelector([
  selectPartsPurchased,
  selectCurrentPurchaseOrderId], (partsPurchased, currentPurchaseOrderId) => {
  if (currentPurchaseOrderId == null) {
    return {};
  }
  const partsPurchasedList = Object.values(partsPurchased[currentPurchaseOrderId] ?? {});

  return partsPurchasedList.reduce((current, partPurchased) => {
    const currentRequestId = partPurchased?.purchaseRequestId;
    if (currentRequestId in current) {
      current = {
        ...current,
        [currentRequestId]: {
          ...current[currentRequestId],
          count: current[currentRequestId].count + 1,
        },
      };
    } else {
      current[currentRequestId] = {
        count: 1,
      };
    }
    return current;
  }, { });
});

export const selectCurrentPurchaseOrderPartDeliveriesByPurchaseRequestId = createSelector([
  selectCurrentPurchaseOrder], (currentPurchaseOrder) => {
  if (currentPurchaseOrder == null || !currentPurchaseOrder?.shipments) {
    return {};
  }
  const { shipments } = currentPurchaseOrder;

  const result = [];
  const results = {};
  // eslint-disable-next-line no-plusplus
  return shipments.map((s) => s.partDeliveries).flat().reduce((current, delivery) => {
    current[delivery.purchaseRequestId] = current[delivery.purchaseRequestId] || [];
    current[delivery.purchaseRequestId].push(delivery);
    return current;
  }, {});
  // for (let i = 0; i < shipments?.length; i += 1) {
  //   const { partDeliveries } = shipments[i];
  //   if (!(partDeliveries[0]?.purchaseRequestId in results)) {
  //     results[partDeliveries[0]?.purchaseRequestId] = [];
  //   }
  //   Array.prototype.push.apply(results[partDeliveries[0]?.purchaseRequestId], partDeliveries);
  // }
  // return results;
});

export default purchaseOrdersSlice.reducer;
