import { formatCurrency } from "../../../services/format";
import { getBillingItemTypes } from "../../../services/requests/billing/misc-billing-requests";
import { FormItemType } from "../../UI/view/component-map";
import { ListViewColumnType } from "../../UI/view/list-view";

const getCustomerType = (getValues) => {
  const { productType, dewarCustomerID, gasCustomerId, liquidCustomerId } = getValues();
  return liquidCustomerId ? 'liquid' : gasCustomerId ? 'gas' : 'dewar';
}

export const invoiceContentsTable = (collectionName) => ({
  name: 'miscBillingDetails',
  type: FormItemType.editTable,
  pk: 'id',
  variant: 'tabular',
  addTemplate: ({ getValues }) => {
    const vals = getValues();
    const miscBillingDetails = getValues(collectionName);
    const { syncPO } = vals;
    return {
      ponumber: syncPO && miscBillingDetails?.length ? miscBillingDetails[0].ponumber : null,
      item: null,
      itemDescription: null,
      quantity: null,
      unitPrice: null,
      value: null
    };
  },
  toolbar: [
    {
      type: FormItemType.checkboxField,
      inline: true,
      label: 'Sync PO Number',
      name: 'syncPO',
      width: 160,
      fitToContent: true,
    }
  ],
  columns: [
    {
      type: ListViewColumnType.delete,
      accessor: 'delete',
    },
    {
      accessor: 'ponumber',
      Header: 'PO Number',
      formItemProps: {
        onChange: (v, getValues, setValue, editTableProps) => {
          const vals = getValues();
          const miscBillingDetails = getValues(collectionName);
          const { syncPO } = vals;
          if (!syncPO) return;
          const { rowIdentity, rowEntity } = editTableProps;
          for (var i = 0; i < miscBillingDetails.length; i++) {
            if (i != rowIdentity) {
              setValue(`${collectionName}[${i}].ponumber`, v);
            }
          }
        },
        name: 'ponumber',
        defaultValue: '',
        maxLength: 50,
      }
    },
    {
      accessor: 'item',
      Header: 'Item',
      formItemProps: {
        name: 'item',
        type: FormItemType.select,
        defaultValue: null,
        required: true,
        options: async (data, getValues, editTableProps) => {
          let list = await getBillingItemTypes();
          const customerType = getCustomerType(getValues);
          const bitFlag = customerType == 'liquid' ? 'isLiquid' : customerType == 'gas' ? 'isGas' : 'isDewar';
          list = list.filter(x => x[bitFlag] );
          return [{ id: null, text: '' }, ...list.filter(x => x.billingType == 'MISC').map(t => ({ id: t.itemKey, text: `${t.description} - ${t.itemKey}` }))]
        },
        onChange: async ({ getValues, editTableProps, evt, setValue }) => {
          const list = await getBillingItemTypes();
          const element = list.find(x => x.billingType == 'MISC' && x.itemKey == evt.target.value);
          const { rowIdentity, rowEntity } = editTableProps;
          if (element) {
            setValue(`${collectionName}[${rowIdentity}].itemDescription`, element.description);
          }
        },
        maxLength: 50
      }
    },
    {
      accessor: 'itemDescription',
      Header: 'Description',
      formItemProps: {
        name: 'itemDescription',
        defaultValue: null,
        maxLength: 30,
        required: true
      }
    },
    {
      accessor: 'quantity',
      Header: 'Quantity',
      formItemProps: {
        onChange: (v, getValues, setValue, editTableProps) => {
          const { rowIdentity } = editTableProps;
          const miscBillingDetails = getValues(collectionName);
          const rowEntity = miscBillingDetails[rowIdentity];
          const { unitPrice } = rowEntity;
          const quantity = Number(v ?? 0);
          const unitPriceNum = Number(unitPrice ?? 0);
          setValue(`${collectionName}[${rowIdentity}].value`, (quantity ?? 0) * (unitPriceNum ?? 0))
        },
        number: true,
        name: 'quantity',
        defaultValue: null,
        maxLength: 50,
        required: true
      }
    },
    {
      accessor: 'unitPrice',
      Header: 'Price Per Item',
      formItemProps: {
        onChange: (v, getValues, setValue, editTableProps) => {
          const { rowIdentity } = editTableProps;
          const miscBillingDetails = getValues(collectionName);
          const rowEntity = miscBillingDetails[rowIdentity];
          const { quantity } = rowEntity;
          const value = Number(v ?? 0);
          const quantityNum = Number(quantity ?? 0);
          setValue(`${collectionName}[${rowIdentity}].value`, (quantityNum ?? 0) * (value ?? 0))
        },
        name: 'unitPrice',
        number: true,
        defaultValue: null,
        maxLength: 50,
        required: true
      }
    },
    {
      accessor: 'value',
      Header: 'Total',
      formItemProps: {
        name: 'value',
        defaultValue: null,
        maxLength: 50,
        number: true,
     /*    format: 'currency', */
        computedValue: (getValues, editTableProps) => {
          const { rowIdentity, rowEntity } = editTableProps;
          const { quantity, unitPrice } = rowEntity;
          return (quantity ?? 0) * (unitPrice ?? 0);
        },
        disabled: (watch, rowIdentity) => {
          return true;
        }
      }
    },
  ]
});
