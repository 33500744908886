import { useEffect, useMemo, useState } from 'react';
import { getRowError } from '../../services/form-helpers';

const prefix = '³He';

const useValidation = ({field, validation, getValues, data, rowIdentity, isUnique, errors,  }) => {
  const customValidation = useMemo(() => {
    let c = Object.keys(validation || []).reduce((obj, current, idx) => {
      obj[current] = (val) => {
        return validation[current].handler(val, getValues, data, rowIdentity);
      };
      return obj;
    }, {});
    if (field.unique) {
      c['unique'] = isUnique;
    }
    return c;
  }, [validation]);
  const customValidatorNames = useMemo(() => {
    if (validation) return Object.keys(validation);
  }, [validation]);

  const rowError = getRowError(errors, field.name, rowIdentity);

  return [customValidation, customValidatorNames, rowError];
};

export { useValidation };
