//@ts-nocheck
import axios from 'axios';
import React, { useCallback, useState } from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { useSelector } from 'react-redux';

let CACHE = {};
const PER_PAGE = 50;

export interface UserSearchTypeaheadProps {
  onChange: (newValue: any) => void;
}
export const UserSearchTypeahead = (props: UserSearchTypeaheadProps) => {
  const { onChange } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [adApiFailed, setAdApiFailed] = useState(false);
  const [options, setOptions] = useState([]);
  const [query, setQuery] = useState('');
  const initialSelectedAdUser = {
    displayName: '',
    id: '',
    mail: '',
    userPrincipalName: '',
  };
  const [selectedAdUser, setSelectedAdUser] = useState(initialSelectedAdUser);

  const currentUserAdIds = useSelector(
    (state: any) => {
      return new Set(Object.values(state.users.data).map((u: any) => u.activeDirectoryId));
    },
  );

  const handleInputChange = (q) => {
    setQuery(q);
  };

  function makeAndHandleRequest(searchValue, token, page = 1) {
    return axios.get(`/Administration/AdUsers?searchValue=${searchValue}&skipToken=${token}`)
      .then((resp) => resp.data)
      .then(({ adUsers, skipToken }) => {
        return {
          adUsers: adUsers.map(
            (adu) => ({ ...adu, disabled: currentUserAdIds.has(adu.id) }),
          ),
          skipToken,
        };
      });
  }

  const handleSearch = useCallback((q) => {
    if (CACHE[q]) {
      setOptions(CACHE[q].adUsers);
      return;
    }

    setIsLoading(true);
    makeAndHandleRequest(q, '').then((resp) => {
      CACHE[q] = { adUsers: resp.adUsers, skipToken: resp.skipToken };

      setIsLoading(false);
      setOptions(resp.adUsers);
      setAdApiFailed(false);
    }).catch((err) => {
      setAdApiFailed(true);
    });
  }, [currentUserAdIds]);

  let adUsersSearchPlaceholder;
  if (adApiFailed) {
    adUsersSearchPlaceholder = 'Error loading users';
  } else if (isLoading) {
    adUsersSearchPlaceholder = 'Loading users...';
  } else {
    adUsersSearchPlaceholder = 'Begin typing to select a user';
  }


  return (
    <AsyncTypeahead
      id="async-pagination-example"
      isLoading={isLoading}
      filterBy={() => true}
      labelKey="displayName"
      maxResults={PER_PAGE - 1}
      minLength={3}
      paginate
      placeholder={adUsersSearchPlaceholder}
      onSearch={handleSearch}
      options={options}
      delay={500}
      useCache={false}
      onChange={(e) => {
        try {
          if (e[0]) {
            // @ts-ignore
            setSelectedAdUser(e[0]);
            if (onChange) onChange(e[0]);
          }
        } catch (err) {
          console.error(err);
        }
      }}
      renderMenuItemChildren={(option) => (
// @ts-ignore
        <div key={option.id}>
          <span className="d-inline-flex w-100 justify-content-between">
            {option.displayName}
            {currentUserAdIds.has(option.id) && <b>User already in system</b>}
          </span>
          <br />
          <small>
            {option.mail}
          </small>
        </div>
      )}
    />
  )
}