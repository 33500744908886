import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Card,
  Col, Container, Form,
  Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
// import { FaMinus } from 'react-icons/fa';
import { useDispatch } from 'react-redux';

import {
  triggerTest, updateChassidId, updateGasContainerId, updateLiquidContainerId, updatePackageProduct,
} from '../../../services/requests';
import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';

function DevControls(props) {
  const [doctitle, setDocTitle] = usePageTitle('Dev Controls');
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState0,
    trigger,
  } = useForm();

  const breadcrumbs = useMemo(() => [
    {
      name: 'Dev Controls',
      icon: 'person',
      to: '/dev-controls',

    },
  ], []);

  const dispatch = useDispatch();

  useEffect(() => {
    // dispatch(setUserUpdateStatus('idle'));
    // dispatch(getCurrentUser);
  }, []);

  const onGenerate = () => {
    triggerTest();
  };

  const chassisUpdateNotify = (replacementName) => toast.success(`Successfully Updated ${replacementName}.`);
  const chassisUpdateNotifyFail = (replacementName) => toast.error(`Failed to Updated ${replacementName}.`);
  const replaceChassisId = async (chassis) => {
    const { oldSerialNumber, newSerialNumber } = chassis;
    if (oldSerialNumber && newSerialNumber) {
      const replacementName = 'Chassis';
      try {
        await updateChassidId(oldSerialNumber, newSerialNumber);
        chassisUpdateNotify(replacementName);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch {
        chassisUpdateNotifyFail(replacementName);
      }
    }
  };
  const replaceLiquidContainerId = async (liquidContainer) => {
    const { oldLiquidContainerId, newLiquidContainerId } = liquidContainer;
    if (oldLiquidContainerId && newLiquidContainerId) {
      const replacementName = 'Liquid Container';
      try {
        await updateLiquidContainerId(oldLiquidContainerId, newLiquidContainerId);
        chassisUpdateNotify(replacementName);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch {
        chassisUpdateNotifyFail(replacementName);
      }
    }
  };
  const replaceGasContainerId = async (gasContainer) => {
    const { oldGasContainerId, newGasContainerId } = gasContainer;
    if (oldGasContainerId && newGasContainerId) {
      const replacementName = 'Gas Container';
      try {
        await updateGasContainerId(oldGasContainerId, newGasContainerId);
        chassisUpdateNotify(replacementName);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch {
        chassisUpdateNotifyFail(replacementName);
      }
    }
  };
  const replacePackageProductSerialNumber = async (packageProduct) => {
    const { oldPackageProductSerialNumber, newPackageProductSerialNumber } = packageProduct;
    if (oldPackageProductSerialNumber && newPackageProductSerialNumber) {
      const replacementName = 'Package Product';
      try {
        await updatePackageProduct(oldPackageProductSerialNumber, newPackageProductSerialNumber);
        chassisUpdateNotify(replacementName);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } catch {
        chassisUpdateNotifyFail(replacementName);
      }
    }
  };

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column">
      <PageHeader header="Dev Controls" breadcrumbs={breadcrumbs} />
      <Row className="flex-grow-1 pb-2">
        <Col>
          <Card>
            <div>
              <Button
                onClick={() => onGenerate()}
              >
                Run Planned Maintenance Request Generation
              </Button>
            </div>
          </Card>
        </Col>
      </Row>
      { /* ---------Chassis ID --------- */}
      <Row>
        <Col className="d-flex align-items-center">
          <h3 className="m-0">Chassis ID Replacement</h3>
        </Col>
      </Row>
      <Row className="flex-grow-1 pb-2">
        <Col>
          <Form.Group className="mb-1">
            Old Chassis Serial Number
            <Form.Control
              name="oldSerialNumber"
              {...register('oldSerialNumber')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            New Chassis Serial Number
            <Form.Control
              name="newSerialNumber"
              {...register('newSerialNumber')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Button
            onClick={handleSubmit(replaceChassisId)}
            style={{ marginTop: 20 }}
          >
            Replace Chassis ID
          </Button>
        </Col>
      </Row>
      { /* ---------Liquid Container ID --------- */}
      <Row>
        <Col className="d-flex align-items-center">
          <h3 className="m-0">Liquid Container ID Replacement</h3>
        </Col>
      </Row>
      <Row className="flex-grow-1 pb-2">
        <Col>
          <Form.Group className="mb-1">
            Old Liquid Container ID
            <Form.Control
              name="oldLiquidContainerId"
              {...register('oldLiquidContainerId')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            New Liquid Container ID
            <Form.Control
              name="newLiquidContainerId"
              {...register('newLiquidContainerId')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Button
            onClick={handleSubmit(replaceLiquidContainerId)}
            style={{ marginTop: 20 }}
          >
            Replace Liquid Container ID
          </Button>
        </Col>
      </Row>
      { /* ---------Gas Container ID --------- */}
      <Row>
        <Col className="d-flex align-items-center">
          <h3 className="m-0">Gas Container ID Replacement</h3>
        </Col>
      </Row>
      <Row className="flex-grow-1 pb-2">
        <Col>
          <Form.Group className="mb-1">
            Old Gas Container ID
            <Form.Control
              name="oldGasContainerId"
              {...register('oldGasContainerId')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            New Gas Container ID
            <Form.Control
              name="newGasContainerId"
              {...register('newGasContainerId')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Button
            onClick={handleSubmit(replaceGasContainerId)}
            style={{ marginTop: 20 }}
          >
            Replace Gas Container ID
          </Button>
        </Col>
      </Row>
      { /* ---------Package Product Serial Number --------- */}
      <Row>
        <Col className="d-flex align-items-center">
          <h3 className="m-0">Package Product Serial Number Replacement</h3>
        </Col>
      </Row>
      <Row className="flex-grow-1 pb-2">
        <Col>
          <Form.Group className="mb-1">
            Old Package Product Serial Number
            <Form.Control
              name="oldPackageProductSerialNumber"
              {...register('oldPackageProductSerialNumber')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Form.Group className="mb-1">
            New Package Product Serial Number
            <Form.Control
              name="newPackageProductSerialNumber"
              {...register('newPackageProductSerialNumber')}
              required
            />
          </Form.Group>
        </Col>
        <Col>
          <Button
            onClick={handleSubmit(replacePackageProductSerialNumber)}
            style={{ marginTop: 20 }}
          >
            Replace Package Product Serial Number
          </Button>
        </Col>
      </Row>
    </Container>
  );
}

export default DevControls;
