import { create } from "lodash";
import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { billingPermissions } from "./billing-datasource";
import { BillingItemList } from "./billing-item-types";

import { createConnectedView } from "../../UI/view/view";
import { DewarProductTypeList, dewarProductTypeDS } from "./dewar-product-type";

export const billingAdmin = {
  pageHeader: 'Admin',
  
  permissions: {
    read: [1],
    write: [1],
    admin: [1],
  },
  breadcrumbs: [
    {
      name: 'Admin',
      icon: 'columns',
      to: '/admin/enum',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Dewar Product Types',
          items: [
            {
              type: FormItemType.customComponent,
              component: DewarProductTypeList
            }
          ]
        },
        {
          label: 'Billing Item Types',
          items: [
            {
              type: FormItemType.customComponent,
              component: BillingItemList
            }
          ]
        },
      ]
    }
  ]
} as FormView;

export const BillingAdminView = createConnectedView({ form: billingAdmin, dataSource: dewarProductTypeDS });