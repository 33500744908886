import './error-template.scss';

import React from 'react';
import { Container } from 'react-bootstrap';

import { usePageTitle } from '../../hooks/usePageTitle';

function ErrorPageTemplate(props) {
  const [doctitle, setDocTitle] = usePageTitle(props.pageTitle || '! Error Page');

  return (
    <Container fluid className="error-template">
      <div className="d-flex justify-content-center align-items-center">
        <h2 className="error-template-headline text-warning">
          <i className="bi bi-exclamation-triangle" />
        </h2>
      </div>
      <div className="error-template-content">
        <h3>
          { props.errorCode }
          {': '}
          {props.title}
        </h3>
        {props.content}
      </div>
    </Container>
  );
}

export default ErrorPageTemplate;
