import './maintenance-dashboard.scss';

import React, {
  useCallback,
  useEffect, useMemo, useState,
} from 'react';
import {
  Badge,
  Card, Container, Nav, NavDropdown, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { selectLoggedInUserInfo } from '../../../store/slices/auth-slice';
import {
  fetchPendingMaintenanceRequests,
  selectPendingMaintenanceRequestsCount,
  setCreateBulkMaintenanceRequestStatus,
  setCurrentMaintenanceRequestId,
} from '../../../store/slices/maintenance-requests-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import WorkOrdersTable from '../WorkOrders/work-order-table';
import AddRequestToWorkOrderModal from './add-request-to-work-order';
import ChassisRequestsTable from './chassis-requests-table';
import GasContainerRequestsTable from './gas-containers-requests-table';
import { LateReadingsListComponent } from './LateReadingsTab/late-readings-list';
import LiquidContainerRequestsTable from './liquid-containers-requests-table';
import PendingRequestsTable from './pending-requests-table';
import RequestDetailsForm from './request-details-form';

function MaintenanceDashboard(props) {
  usePageTitle('Maintenance Dashboard');
  const [createModalShow, setCreateModalShow] = useState(false);
  const [createModalAssetType, setCreateModalAssetType] = useState(null);

  const handleCreateModalClose = () => {
    setCreateModalAssetType(null);
    setCreateModalShow(false);
  };
  const handleCreateModalShow = (assetType) => {
    setCreateModalAssetType(assetType);
    setCreateModalShow(true);
  };

  const userInfo = useSelector(selectLoggedInUserInfo);

  const [createRequestModalShow, setCreateRequestModalShow] = useState(false);

  const handleCreateRequestModalClose = () => {
    setCreateRequestModalShow(false);
  };
  const handleCreateRequestModalShow = (assetType) => {
    setCreateRequestModalShow(true);
  };

  const dispatch = useDispatch();

  const [addToWorkOrderModaShow, setAddToWorkOrderModaShow] = useState(false);
  const [modalAssetSerialNumber, setModalAssetSerialNumber] = useState(null);
  const [modalAssetTypeId, setModalAssetTypeId] = useState(null);

  const handleWorkOrderModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setModalAssetSerialNumber(null);
    setModalAssetTypeId(null);
    setAddToWorkOrderModaShow(false);
  };
  const handleWorkOrderModalShow = (maintenanceRequestId, assetSerialNumber, assetTypeId) => {
    dispatch(setCurrentMaintenanceRequestId(maintenanceRequestId));
    setModalAssetSerialNumber(assetSerialNumber);
    setModalAssetTypeId(assetTypeId);
    setAddToWorkOrderModaShow(true);
  };

  const [detailsModalShow, setDetailsModalShow] = useState(false);
  const handleDetailsModalClose = () => setDetailsModalShow(false);
  const handleDetailsModalShow = () => setDetailsModalShow(true);

  const maintenanceRequestPendingFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsPendingFetch.status,
  );

  const pendingMaintenanceRequestsCount = useSelector(selectPendingMaintenanceRequestsCount);

  const loadData = useCallback(async () => {
    if (maintenanceRequestPendingFetchStatus === 'idle' || maintenanceRequestPendingFetchStatus === 'failed') {
      dispatch(fetchPendingMaintenanceRequests());
    }
  }, [
    maintenanceRequestPendingFetchStatus,
  ]);

  const bulkMaintenanceRequestCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceBulkRequestCreate.status,
  );
  const bulkMaintenanceRequestCreateError = useSelector(
    (state) => state.maintenanceRequest.maintenanceBulkRequestCreate.error,
  );

  const maintenanceRequestCreateSuccessNotify = () => toast.success('Successfully created Maintenance Request(s)');
  const maintenanceRequestCreateFailedNotify = (error) => toast.error(`Failed to create Maintenance Request\n${error}`);

  useEffect(() => {
    if (bulkMaintenanceRequestCreateStatus === 'succeeded') {
      maintenanceRequestCreateSuccessNotify();
      dispatch(setCreateBulkMaintenanceRequestStatus('idle'));
    }
    if (bulkMaintenanceRequestCreateStatus === 'failed') {
      maintenanceRequestCreateFailedNotify(bulkMaintenanceRequestCreateError);
      dispatch(setCreateBulkMaintenanceRequestStatus('idle'));
    }
  }, [bulkMaintenanceRequestCreateStatus]);

  useEffect(() => {
    loadData();
  }, []);

  // eslint-disable-next-line no-restricted-globals
  const defaultTab = decodeURIComponent(location.hash?.replace('#', ''));
  const [activeTab, setActiveTab] = useState(defaultTab || 'Liquid Container');
  const onTabChange = (key) => {
    if (key === 'notab') {
      return;
    }
    setActiveTab(key);
  };
  const breadcrumbs = useMemo(() => [
    {
      name: 'Maintenance',
      icon: 'wrench-adjustable',
      to: '/maintenance',
    },
  ], []);

  const liquidContent = (
    <LiquidContainerRequestsTable
      handleCreateModalShow={handleCreateRequestModalShow}
      handleWorkOrderModalShow={handleWorkOrderModalShow}
      handleDetailsModalShow={handleDetailsModalShow}
      createModalAssetType={2}
    />
  );
  const gasContent = (
    <GasContainerRequestsTable
      handleCreateModalShow={handleCreateRequestModalShow}
      handleWorkOrderModalShow={handleWorkOrderModalShow}
      handleDetailsModalShow={handleDetailsModalShow}
      createModalAssetType={1}
    />
  );
  const chassisContent = (
    <ChassisRequestsTable
      handleCreateModalShow={handleCreateRequestModalShow}
      handleWorkOrderModalShow={handleWorkOrderModalShow}
      handleDetailsModalShow={handleDetailsModalShow}
      createModalAssetType={4}
    />
  );

  return (
    <>
      <Container
        fluid
        className="h-100 m-0 p-0 d-flex flex-column mb-2"
      >
        <PageHeader
          header="Maintenance"
          breadcrumbs={breadcrumbs}
        />
        <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
          <TabContainer defaultActiveKey="Liquid Container" activeKey={activeTab} onSelect={(tab) => onTabChange(tab)}>
            <div className="card-header p-0 pt-1">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="Liquid Container">Liquid</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Gas Container">Gas</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Chassis">Chassis</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Work Orders">Work Orders</Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Pending Requests">
                    <div className="d-flex">
                      <span>
                        Pending Requests
                      </span>
                      {pendingMaintenanceRequestsCount > 0
                        && (
                        <Badge pill bg="danger" className="d-inline-flex align-items-center justify-content-center ml-2">
                          {pendingMaintenanceRequestsCount}
                        </Badge>
                        )}
                    </div>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="Late Readings">Late Readings</Nav.Link>
                </Nav.Item>
                <NavDropdown title="Reports" id="report-nav-dropdown" className="dashboard-report-menu">
                  <NavDropdown.Item eventKey="notab" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fLiquid+Containers+Inspection+By+Location&rs:Command=Render&Loc=${userInfo.locationPreference}`} target="__blank" style={{ color: '#212529', textAlign: 'left', width: 200 }}>Daily Walkthrough Report</NavDropdown.Item>
                  <NavDropdown.Item eventKey="notab" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fHe3_MaintenanceWorkOrderList&rs:Command=Render&LocId=${userInfo.locationPreference}`} target="__blank" style={{ color: '#212529', textAlign: 'left', width: 200 }}>Work Order List</NavDropdown.Item>
                  <NavDropdown.Item eventKey="notab" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fHe3_MaintenanceStockAudit&rs:Command=Render&LocationId=${userInfo.locationPreference}`} target="__blank" style={{ color: '#212529', textAlign: 'left', width: 200 }}>Stock Audit Report</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </div>
            <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
              <TabContent className="h-100">
                <TabPane eventKey="Liquid Container" className="h-100" mountOnEnter>
                  {liquidContent}
                </TabPane>
                <TabPane eventKey="Gas Container" className="h-100" mountOnEnter>
                  {gasContent}
                </TabPane>
                <TabPane eventKey="Chassis" className="h-100" mountOnEnter>
                  {chassisContent}
                </TabPane>
                <TabPane eventKey="Work Orders" className="h-100" mountOnEnter>
                  <WorkOrdersTable handleModalShow={handleCreateModalShow} />
                </TabPane>
                <TabPane eventKey="Pending Requests" className="h-100" mountOnEnter>
                  <PendingRequestsTable />
                </TabPane>
                <TabPane eventKey="Late Readings" className="h-100" mountOnEnter unmountOnExit>
                  <LateReadingsListComponent />
                </TabPane>
              </TabContent>
            </div>
          </TabContainer>
        </Card>
      </Container>
      <AddRequestToWorkOrderModal
        assetTypeId={modalAssetTypeId}
        assetSerialNumber={modalAssetSerialNumber}
        show={addToWorkOrderModaShow}
        onHide={handleWorkOrderModalClose}
      />
      <RequestDetailsForm
        show={detailsModalShow}
        onHide={handleDetailsModalClose}
      />
    </>

  );
}

export default MaintenanceDashboard;
