import { sum } from "../../../../services/array-helpers";
import { formatCurrency, formatDate } from "../../../../services/format";
import { FormItemType } from "../../../UI/view/component-map";
import { FormView } from "../../../UI/view/form-view";
import { ListView, ListViewColumnType, createConnectedListView, createListView } from "../../../UI/view/list-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { gasrentalordersDS } from "./gasrental-unbilled-list";

export const gasRentalsCreate = {
  editMode: true,
  pageHeader: 'Gas Rentals Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Gas Rentals Billing',
      icon: 'currency-dollar',
      to: '/billing/gas',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          type: FormItemType.infoPane,
          header: ({ gasrentalbill }) => gasrentalbill?.current?.data?.customerName,
          infoListItems: [

            {
              label: 'Customer',
              name: 'customerName'
            },
            {
              label: 'Customer Id',
              name: 'gasCustomerId'
            },
            {
              label: 'Free Days Per Trip',
              value: ({ freeDays }) => freeDays
            },
            {
              label: 'Price Charged Per Month',
              value: ({ pricePerMonth }) => formatCurrency(pricePerMonth)
            },
            {
              label: 'Price Charged Per Day',
              value: ({ pricePerDay }) => formatCurrency(pricePerDay)
            },
            {
              label: 'Billing Period',
              name: 'billingPeriod',
              value: ({ invoiceMonth, invoiceYear }) => `${invoiceMonth}/${invoiceYear}`
            },
            {
              label: 'Total',
              value: (a, watch) => {
                const vals = watch('gasRentalBillingDetails');
                if (!vals) return '';
                return formatCurrency(sum(vals.map((v) => v.value)));
              }
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Gas Rentals',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Rental Billing',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          name: 'ponumber',
                          label: 'PO Number',
                          minWidth: 200,
                          fitToContent: true,
                          defaultValue: ''
                        },
                      ]
                    },
                    {
                      name: 'invoiceNotes',
                      label: 'Invoice Notes',
                      type: FormItemType.textarea,
                      defaultValue: ''
                    },
                    {
                      name: 'gasRentalBillingDetails',
                      type: FormItemType.editTable,
                      hideAddButton: true,
                      variant: 'tabular',


                      toolbar: [
                        {
                          type: FormItemType.checkboxField,
                          inline: true,
                          label: 'PO on Each Item',
                          name: 'Postyle',
                          width: 130,
                          fitToContent: true,
                        }
                      ],
                      columns: [
                        {
                          type: ListViewColumnType.delete,
                          accessor: 'delete',
                        },
                        {
                          accessor: 'ponumber',
                          Header: 'PO Number',
                          formItemProps: {
                            disabled: ({ watch }) => !watch('Postyle'),
                            name: 'ponumber',
                            defaultValue: '',
                            maxLength: 50,
                          }
                        },
                        {
                          Header: 'Container ID',
                          accessor: 'containerId',
                          defaultSort: true,
                          formItemProps: {
                            name: 'containerId',
                            disabled: () => true,
                          }
                        },
                        {
                          Header: 'Booking ID',
                          accessor: 'bookingId',
                          formItemProps: {
                            name: 'bookingId',
                            disabled: () => true,
                          }
                        },
                        {
                          Header: 'Port Name',
                          accessor: 'terminalName',
                          formItemProps: {
                            name: 'terminalName',
                            disabled: () => true,
                          }
                        },
                        {
                          Header: 'Port Arrival Date',
                          type: ListViewColumnType.date,
                          accessor: 'startDate',
                          formItemProps: {
                            name: 'startDate',
                            type: FormItemType.date,
                            disabled: () => true,
                          }
                        },
                        {
                          Header: 'Port Release Date',
                          type: ListViewColumnType.date,
                          accessor: 'endDate',
                          formItemProps: {
                            name: 'endDate',
                            type: FormItemType.date,
                            disabled: () => true,
                          }
                        },
                        {
                          Header: 'Days Billable',
                          accessor: 'daysBilled',
                          formItemProps: {
                            name: 'daysBilled',
                            disabled: () => true,
                          }
                        },
                        {
                          Header: 'Total $',
                          accessor: 'value',
                          formItemProps: {
                            name: 'value',
                            disabled: () => true,
                          }
                        },

                      ]
                    },
                    {
                      name: 'billingType',
                      defaultValue: 'GASRENTAL',
                      hideIf: () => true
                    },
                    {
                      name: 'productType',
                      defaultValue: 'Gas',
                      hideIf: () => true
                    },
                    {
                      name: 'customerId',
                      hideIf: () => true
                    },
                    {
                      name: 'gasCustomerId',
                      computedValue: (getValues) => getValues('customerId'),
                      hideIf: () => true
                    },
                    {
                      name: 'roNumber',
                      defaultValue: '',
                      hideIf: () => true
                    },
                    {
                      name: 'bypassInvoice',
                      defaultValue: false,
                      hideIf: () => true
                    },
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.button,
                  label: 'Bypass Invoice',
                  variant: 'secondary',
                  handler: async ({ methods, handleSubmit, onSubmit, redirect, confirm }) => {
                    const { setValue } = methods;
                    setValue('bypassInvoice', true);
                    let isError = false;
                    let result = null;
                    try {
                      result = await handleSubmit(onSubmit)();
                    } catch {
                      isError = true;
                    }
                    if (isError) {
                      await confirm('There was an error bypassing this invoice.', true);
                    } else {
                      //redirect to created invoice. We need the invoice id!\
                      await confirm('Invoice Bypassed.', true);
                      redirect('/billing/gas');
                    }
                  }
                },
                {
                  type: FormItemType.submitButton,
                  label: 'Create Invoice',
                  variant: 'secondary',
                  onAfterSubmit: ({ redirect }) => {
                    alert('Invoice Successfully Created!')
                    redirect('/billing/gas');
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
} as FormView;

export const [GasRentalInvoice, GasRentalInvoiceRepo] = createConnectedView2({ form: gasRentalsCreate, dataSource: gasrentalordersDS });
