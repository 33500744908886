import { getGasAndChassisReadingsObj} from "../../../services/requests";
import { getRareGasModules, insertRareGasModule, updateRareGasModule } from "../../../services/requests/raregasRequests";
import { FormItemType } from "../../UI/view/component-map";
import { DataSource } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";


export const rareGasDs = {
  name: 'raregasmodules',
  pk: 'id',
  request: getRareGasModules,
  updateRequest: updateRareGasModule,
  createRequest: insertRareGasModule
} as DataSource;

export const rareGasAdd = (isEdit) =>  ({
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Info',
      items: [
        {
          name: 'trackerDeviceId',
          label: 'Tracking Id',
        },
        {
          name: 'serialNumber',
          label: 'Asset Id',
          required: true,
        },
        {
          name: 'assetType',
          label: 'Asset Type',
          type: FormItemType.select,
          defaultValue: null,
          required: true,
          options: [
            {
              id: null,
              text: ''
            },
            {
              id: 'MCP',
              text: 'MCP'
            },
            {
              id: 'TT',
              text: 'Trailer'
            },
            {
              id: 'module',
              text: 'Module'
            },
          ]
        },
        {
          name: 'region',
          label: 'Owner',
          type: FormItemType.select,
          defaultValue: null,
          options: [
            {
              id: null,
              text: ''
            },
            {
              id: 'Linde China',
              text: 'Linde China',
            },
            {
              id: 'He&RG',
              text: 'He&RG',
            },
            {
              id: 'Linde Gas Germany',
              text: 'Linde Gas Germany',
            },
          ]
        },
        {
          name: 'product',
          label: 'Product Type',
          type: FormItemType.select,
          defaultValue: null,
          options: [
            {
              id: null,
              text: ''
            },
            {
              id: 'Neon',
              text: 'Pure Neon'
            },
            {
              id: 'Krypton',
              text: 'Krypton'
            },
            {
              id: 'C1',
              text: 'C1'
            },
            {
              id: 'C2',
              text: 'C2'
            },
            {
              id: 'Crude Oxygen',
              text: 'Crude Oxygen'
            },
          ]
        },
        {
          name: 'supplyChainOrigin',
          label: 'Supply Chain Origin',
          type: FormItemType.select,
          defaultValue: null,
          options: [
            { id: null, text: '' },
            { id: 'East Chicago', text: 'East Chicago' },
            { id: 'Alpha', text: 'Alpha' },
            { id: 'ASU', text: 'ASU' },
            { id: 'Dakota', text: 'Dakota' },
            { id: 'Huizhou', text: 'Huizhou' },
            { id: 'Kingman', text: 'Kingman' },
            { id: 'Linde Korea', text: 'Linde Korea' },
            { id: 'Ningbo', text: 'Ningbo' },
            { id: 'SOPO', text: 'SOPO' },
            { id: 'Storage', text: 'Storage' },
            { id: 'TGC', text: 'TGC' },

          ]
        },
        {
          name: 'supplyChainDestination',
          label: 'Supply Chain Destination',
          type: FormItemType.select,
          defaultValue: null,
          options: [
            { id: null, text: '' },
            { id: 'East Chicago', text: 'East Chicago' },
            { id: 'Alpha', text: 'Alpha' },
            { id: 'ASU', text: 'ASU' },
            { id: 'Dakota', text: 'Dakota' },
            { id: 'Huizhou', text: 'Huizhou' },
            { id: 'Kingman', text: 'Kingman' },
            { id: 'Linde Korea', text: 'Linde Korea' },
            { id: 'Ningbo', text: 'Ningbo' },
            { id: 'SOPO', text: 'SOPO' },
            { id: 'Storage', text: 'Storage' },
            { id: 'TGC', text: 'TGC' },

          ]
        },
        {
          name: 'lastTestDate',
          label: 'Last Retest Date',
          type: FormItemType.date
        },
        {
          name: 'retestDueDate',
          label: 'Next Retest Date',
          type: FormItemType.date
        },
        {
          name: 'isActive',
          label: 'isActive',
          defaultValue: true,
          hideIf: () => true
        },
      ]
    },
  ]

} as FormView);


export const rareGasList = {
  /*   variant: 'card', */
  preventRedirectOnCreate: true,
  create: rareGasAdd(false),
  update: rareGasAdd(true),
  filters: [
    {
      type: FilterType.simple,
      fieldName: 'isActive',
      label: 'Active',
      filterValue: true
    },

/*     {
      id: null,
      text: ''
    },
    {
      id: 'MCP',
      text: 'MCP'
    },
    {
      id: 'TT',
      text: 'Trailer'
    },
    {
      id: 'module',
      text: 'Module'
    }, */


    {
      type: FilterType.multiselect,
      fieldName: 'assetType',
      filterValue: { ['MCP']: true, ['TT']: true, ['module']: true },
      options: () => ['MCP', 'TT', 'module'],
      labels: { ['MCP']: 'MCP', ['TT']: 'Trailer', ['module']: 'Module'},
    },
    {
      type: FilterType.combo,
      label: 'Owner',
      fieldName: 'region',
    },
    {
      type: FilterType.combo,
      label: 'Product Type',
      fieldName: 'product'
    },
  ],
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      accessor: 'trackerDeviceId',
      Header: 'Tracking Id',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/raregas/edit/' + row?.original?.serialNumber,

    },
    {
      accessor: 'serialNumber',
      Header: 'Asset Id',
      defaultSort: true,
    },
    {
      accessor: 'region',
      Header: 'Owner'
    },
    {
      accessor: 'assetType',
      Header: 'Asset Type'
    },
    {
      accessor: 'product',
      Header: 'Product Type'
    },
    {
      id: 'originaDestination',
      accessor: ({ supplyChainOrigin, supplyChainDestination }) => (supplyChainOrigin ?? '') + ' - ' + (supplyChainDestination ?? ''),
      Header: 'Supply Chain Origin - Destination'
    },

    {
      accessor: 'lastTestDate',
      Header: 'Last Retest Date',
      type: ListViewColumnType.date
    },
    {
      accessor: 'retestDueDate',
      Header: 'Next Retest Date',
      type: ListViewColumnType.date
    },

    {
      accessor: 'isActive',
      Header: 'isActive',
      hidden: true
    },
  ]
} as ListView;

export const RareGasListView = createConnectedListView(rareGasList, rareGasDs);