import { useEffect, useMemo, useState } from 'react';
import { getRowError } from '../../services/form-helpers';
import { useSelector } from 'react-redux';
import { selectCurentUserRoles } from '../../store/slices/auth-slice';


const usePermissions = (props) => {
  const currRoles = useSelector(selectCurentUserRoles);
  const [writeAccess, readAccess] = useMemo(() => {
    if(!props || !props?.write || !currRoles) return [true, true];
    const writeRules = [...currRoles, {roleId: 2}].find(r => props?.write?.includes(r.roleId));
    const readRules = [...currRoles, {roleId: 2}].find(r => props?.read?.includes(r.roleId));
    return [writeRules, readRules];
  }, [currRoles, props]);

  return [writeAccess, readAccess];
};

export { usePermissions };
