import React, { useMemo } from 'react';
import { IconContext } from 'react-icons';
import { BsCircleFill } from 'react-icons/bs';

export function RedIcon({ tooltip }) {
  const colorValue = useMemo(() => ({ color: 'red' }));
  return (
    <IconContext.Provider
      value={colorValue}
    >
      <div title={tooltip}>
        <BsCircleFill />
      </div>
    </IconContext.Provider>
  );
}

export function YelloIcon({ tooltip }) {
  const colorValue = useMemo(() => ({ color: 'yellow' }));
  return (
    <IconContext.Provider
      value={colorValue}
    >
      <div title={tooltip}>
        <BsCircleFill />
      </div>
    </IconContext.Provider>
  );
}

export function GreenIcon({ tooltip }) {
  const colorValue = useMemo(() => ({ color: 'green' }));
  return (
    <IconContext.Provider
      value={colorValue}
    >
      <div title={tooltip}>
        <BsCircleFill />
      </div>
    </IconContext.Provider>
  );
}
