import { addDaysToDate, formatCurrency, formatDate } from "../../../services/format";
import { LogicalConnector } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { billingPermissions } from "./billing-datasource";
import { DewarDamagesList } from "./billing-dewar-damages";
import { createInvoice } from "./billing-invoice-create";
import { BillingItemList } from "./billing-item-types";
import moment from "moment";
import { GetMiscBillingList, InsertBilling, UpdateBilling, getBillingHeader, uploadInvoicesJDEDTO } from "../../../services/requests/billing/misc-billing-requests";
import { MiscBillingScheduleList } from "./billing-schedule-list";



export const miscBillingDataSource = {
  name: 'billing',
  request: GetMiscBillingList,
  entityRequest: getBillingHeader,
  updateRequest: UpdateBilling,
  createRequest: InsertBilling,
  serverSideEvents: true,
  pk: 'billingId'
}

export const billingListView = ({ uploadEndpoint, collectionName }) => ({
  addButtonText: 'Create Invoice',
  create: createInvoice,
  secondarySort: '(dateJdeprocessed ?? dateInvoiced) desc',
  permissions: billingPermissions,
  toolbar: [
    {
      type: FormItemType.button,
      label: 'Upload Selected to JDE',
      permissions: {
        read: [1, 14, 15, 18],
        write: [1, 15],
        admin: [1, 15],
      },
      handler: async ({ getValues, confirm }) => {
        const vals = getValues();
        const billingList = vals[collectionName];
        if (!billingList?.length) {
          await confirm('No invoices are selected. Please select invoices from the list below then press upload.', true)
        } else {
          if (await confirm("Are you sure you want to upload the selected invoices to JDE?") == true) {
            let isError = false;
            try {
              await uploadEndpoint({ billingIds: billingList.map(b => b.billingId) });
            } catch (e) {
              isError = true;
              await confirm('There was an error uploading to JDE:' + e, true)
            }
            if (!isError) {
              await confirm('JDE Upload process is completed.', true);
              location.reload();
            }
          }
        }
      }
    }
  ],
  filters: [
    {
      type: FilterType.simple,
      fieldName: 'unbilled',
      label: 'Unbilled',
      filterValue: false,
      serverExpression: 'Status == 0 && Jdebypassed == false',
      logicalConnector: LogicalConnector.And
    },
    {
      type: FilterType.simple,
      fieldName: 'hasErrors',
      label: 'JDE Errors',
      filterValue: false,
      serverExpression: 'Status == 9 && Jdebypassed == false',
      logicalConnector: LogicalConnector.Or
    },
    {
      type: FilterType.simple,
      fieldName: 'jdeBypassed',
      label: 'JDE Bypassed',
      filterValue: false,
      serverExpression: 'Jdebypassed == true',
      logicalConnector: LogicalConnector.Or
    },
    {
      type: FilterType.simple,
      fieldName: 'jdeUploaded',
      label: 'JDE Uploaded',
      filterValue: false,
      serverExpression: 'Status == 5 and jdebatchnumber != null',
      logicalConnector: LogicalConnector.Or
    },
    {
      type: FilterType.number,
      fieldName: 'dateInvoiced',
      label: 'Show Last X Days',
      filterValue: null,
      maxValue: 180,
      minValue: 1,
      serverExpression: 'DateInvoiced >= @val',
      valExpression: (val) => {
        return '"' + formatDate(addDaysToDate(new Date(), -val)) + '"';
      },
      logicalConnector: LogicalConnector.And
    },
    {
      type: FilterType.simple,
      fieldName: 'holdDate',
      label: 'On Hold',
      filterValue: false,
      serverExpression: 'holdDate > ' + '"' + formatDate(moment(moment().startOf('day'))) + '"',
      logicalConnector: LogicalConnector.Or
    },
  ],
  columns: [
    {
      Header: '',
      accessor: 'checked',
      type: ListViewColumnType.checkbox,
      disabled: ({ status, jdebypassed, holdDate }) => moment(moment(holdDate).startOf('day')) > moment(moment().startOf('day')) || (Math.floor(status) != 0 && Math.floor(status) != 9) || jdebypassed,
      excludeGlobalFilter: true,
    },
    {
      type: ListViewColumnType.icon,
      id: 'trafficStatus',
      accessor: ({ status, jdebypassed, holdDate }) => moment(moment(holdDate).startOf('day')) > moment(moment().startOf('day')) ? 'clock-history' : (Math.floor(status) == 0 && !jdebypassed) ? 'currency-dollar' :
        (Math.floor(status) == 9 && !jdebypassed) ? 'exclamation-diamond' : jdebypassed ? 'arrow-return-left' : 'check',
      tooltip: ({ status, jdebypassed, holdDate }) => moment(moment(holdDate).startOf('day')) > moment(moment().startOf('day')) ? `On Hold until ${formatDate(moment(holdDate))}` : (Math.floor(status) == 0 && !jdebypassed) ? 'Unbilled' :
        (Math.floor(status) == 9 && !jdebypassed) ? 'JDE Error' : jdebypassed ? 'JDE Bypassed' : 'JDE Processed',//'JDE Processed',
      serverExpression: `iif(Status == 9 && jdebypassed == false, 4, iif(Status == 0 && jdebypassed == false, 3, iif(holdDate > ${'"' + formatDate(new Date()) + '"'}, 2, 1)))`,
    },
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ value, row }) => `/billing/edit/${row.original ? row.original.billingId : row.billingId}#Invoice Contents`,
      serverExpression: 'LiquidCustomer.CustomerName ?? GasCustomer.CustomerName ?? DewarCustomer.AccountName',
    },
    {
      Header: 'Invoice Id',
      accessor: 'billingId'
    },
    {
      Header: 'Date Invoiced',
      accessor: 'dateInvoiced',
      defaultSortDirection: 'desc',
      type: ListViewColumnType.date,
      excludeGlobalFilter: true,
    },
    {
      Header: 'Date Processed',
      accessor: 'dateJdeprocessed',
      type: ListViewColumnType.date,
      excludeGlobalFilter: true,
    },
    {
      Header: 'PO Number',
      accessor: 'ponumber',
    },
    {
      Header: 'Comments',
      accessor: 'invoiceComments',
      hideOverflow: true,
      maxWidth: 200,
    },
    {
      Header: 'Created By',
      id: 'userInputDisplay',
      accessor: ({ userInputDisplay, userIdinput }) => userInputDisplay || userIdinput,
      serverExpression: 'userInputDisplay ?? userIdinput',
      excludeGlobalFilter: true,
    },
    {
      Header: 'Processed By',
      id: 'userIdjdeUploaded',
      accessor: ({ userIdjdeUploaded, userJDEUploadDisplay }) => userJDEUploadDisplay || userIdjdeUploaded,
      serverExpression: 'userJDEUploadDisplay ?? userIdjdeUploaded',
      excludeGlobalFilter: true,
    },
    {
      Header: 'JDE Batch Number',
      accessor: 'jdebatchNumber',
    },
    // /JDEBatchNumber
    //UserIdjdeUploaded
    {
      Header: 'Total Amount',
      accessor: 'itemValue',
      serverExpression: 'MiscBillingDetails.Sum(Value)',
      Cell: ({ row }) => formatCurrency(row.original.itemValue)
    },
    {
      Header: 'JDE Errors',
      accessor: 'uploadIssues',
      hideOverflow: true,
      maxWidth: 200,
    },

    {
      Header: 'unbilled',
      accessor: 'unbilled',
      hidden: true,
      excludeGlobalFilter: true,
    },
    {
      Header: 'hasErrors',
      accessor: 'hasErrors',
      hidden: true,
      excludeGlobalFilter: true,
    },
    {
      Header: 'status',
      accessor: 'status',
      hidden: true,
      excludeGlobalFilter: true,
    },
    {
      Header: 'priority',
      accessor: 'priority',
      hidden: true,
      defaultSort: true,
      excludeGlobalFilter: true,
      defaultSortDirection: 'desc',
      serverExpression: `iif(holdDate > ${'"' + formatDate(new Date()) + '"'}, 2, iif(Status == 0 && Jdebypassed == false, 4, iif(Status == 9 && Jdebypassed == false, 3, 1)))`
    },
    {
      Header: 'customerId',
      accessor: 'customerId',
      hidden: true,
    },
    {
      Header: 'holdDate',
      accessor: 'holdDate',
      hidden: true,
    }
    //Comments

  ]
} as ListView);

export const BillingList = createConnectedListView(billingListView({ uploadEndpoint: uploadInvoicesJDEDTO, collectionName: 'miscBillingList' }), miscBillingDataSource);


export const billingView = {
  pageHeader: 'Misc Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Billing',
      icon: 'currency-dollar',
      to: '/billing',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Miscellaneous',
          mountOnEnter: false,
          badgeCounter: (state) => {
            return state.billing?.data?.filter(x => [0, 9].includes(x.status) && !x.jdebypassed).length;
          },
          items: [
            {
              items: [
                {
                  type: FormItemType.customComponent,
                  component: BillingList,
                  name: 'miscBillingList'
                }
              ]
            }
          ]
        },
        {
          label: 'Dewar Damages',
          mountOnEnter: false,
          badgeCounter: (state) => {
            return state.dewarDamages?.data?.length;
          },
          items: [
            {
              items: [
                {
                  type: FormItemType.customComponent,
                  component: DewarDamagesList,
                  name: 'dewarDamagesList'
                }
              ]
            }
          ]
        },
        {
          label: 'Schedules',
          items: [
            {
              type: FormItemType.customComponent,
              component: MiscBillingScheduleList,
              name: 'billingScheduleList'
            }

          ]
        }
      ]
    }
  ]
} as FormView;



export const BillingListView = createConnectedView({ form: billingView, dataSource: miscBillingDataSource });

