import React from "react"
import { Col, Container } from "react-bootstrap"
import Card from "../../molecules/Card/card"

export const FieldSet = ({ field, renderItem, watch }) => {
  const hidden = field?.hide ? field.hide({ watch }) : false;

  if (hidden) {
    return null;
  }

  const render = (
    <Col style={field.allowGrow ? {
      flexGrow: 1,
      overflow: 'hidden',
      width: '100%',
      height: '100%',
      display: 'flex',
      flexFlow: 'column'
    } : undefined} >
      <Card header={field.label} className="card-secondary card-outline h-100">
        <Container fluid className="p-0 m-0 h-100" style={{ height: '100%', overflow: field.allowScroll ? 'auto' : undefined }} >
          {field.items.map(item => renderItem(item))}
        </Container>
      </Card>
    </Col>
  )
  return field.isFluid ?
    <div className="container-fluid" style={{ padding: 0 }}>
      {render}
    </div> : render;
}