/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Button, Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { FiExternalLink } from 'react-icons/fi';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay, formatCurrency } from '../../../services/format';
import { selectAllActiveLocationObjects, selectGasCustomers, selectLiquidCustomers } from '../../../store/slices/legacy-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import { updatePurchaseOrder } from '../../../store/slices/purchase-orders-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import Card from '../../UI/molecules/Card/card';
import DateInput from '../../UI/molecules/DateInput/date-input';
import { Typeahead } from '../../UI/molecules/Typeahead';

const defaultPurchaseOrderFormValues = {
  purchaseOrderId: null,
  purchaseOrderNumber: null,
  approvedDate: null,
  approvedBy: null,
  estimatedDeliveryDate: null,
  actualDeliveryDate: null,
  vendorId: null,
  boeNumber: null,
  hsCode: null,
  comments: null,
  locationId: null,
  sourceLocationId: null,
  isStockTransferOrder: false,
  freightCost: null,
  emailReference: null,
  quoteNumberReference: null,
  deliveryTerms: null,
  shippingInstructions: null,
  paymentTerms: null,
  notes: null,
};

function PurchaseOrderForm({ currentPurchaseOrder, purchaseOrderStatusIsLoading }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultPurchaseOrderFormValues,
  });

  const updatePurchaseOrderError = useSelector(
    (state) => state.purchaseOrder.purchaseOrderUpdate.error,
  );

  const updatePurchaseOrderStatus = useSelector(
    (state) => state.purchaseOrder.purchaseOrderUpdate.status,
  );

  const [showFormErrors, setShowFormErrors] = useState(false);
  const gasCustomers = useSelector(selectGasCustomers);
  const liquidCustomers = useSelector(selectLiquidCustomers);
  const locations = useSelector(selectAllActiveLocationObjects);
  const vendors = useSelector((state) => state.vendors.data);

  const dispatch = useDispatch();
  const { assetTypes, purchaseOrderStatuses } = useSelector(selectMaintenanceRequestDropdownValues);
  const onSubmit = (purchaseOrder) => {
    Object.keys(purchaseOrder).forEach((key) => {
      if (key.toLowerCase().includes('date') && purchaseOrder[key]) {
        purchaseOrder[key] = convertDateToUTCDay(purchaseOrder[key]);
      }
    });
    dispatch(updatePurchaseOrder({ ...currentPurchaseOrder, ...purchaseOrder }));
  };

  const vendorPlaceholder = '';
  const currentVendor = '';

  // Populate form with default values
  useEffect(() => {
    if (currentPurchaseOrder) {
      Object.keys(defaultPurchaseOrderFormValues).forEach((key) => {
        if (key.toLowerCase().includes('date') && currentPurchaseOrder[key]) {
          setValue(key, new Date(currentPurchaseOrder[key].replace('Z', '')));
        } else {
          setValue(key, currentPurchaseOrder[key]);
        }
      });
    }
  }, [currentPurchaseOrder, vendors]);

  useEffect(() => {
    if (updatePurchaseOrderStatus === 'failed') {
      if (updatePurchaseOrderError.includes('duplicate')) {
        setShowFormErrors(true);
      }
    }
    if (updatePurchaseOrderStatus === 'succeeded') {
      setShowFormErrors(false);
    }
  }, [updatePurchaseOrderStatus]);

  const usersByOid = useSelector(selectUsersByOid);
  return (
    <>
      <Form className="overflow-auto pt-3 flex-grow-1">
        <Spinner animation="border" variant="primary" className={`create-spinner${purchaseOrderStatusIsLoading ? ' visible' : ' invisible'}`} />
        <Container fluid className={` ${purchaseOrderStatusIsLoading ? ' creation-loading' : ''}`}>
          {/* <Row>
            <Col>
              <BootstrapCard>
                <BootstrapCard.Body>
                  <Container fluid className="p-0 m-0">

                    <ProgressBar>
                      <ProgressBar now={100} variant="warning" />
                      <ProgressBar now={100} variant="info" />
                      <ProgressBar now={100} variant="warning" />
                      <ProgressBar now={100} />
                      <ProgressBar now={100} variant="warning" />
                      <ProgressBar now={100} variant="info" />
                    </ProgressBar>
                  </Container>
                </BootstrapCard.Body>
              </BootstrapCard>

            </Col>
          </Row> */}
          <Row>
            <Col>
              <Card header="Info" className="card-secondary card-outline">
                <Container fluid className="p-0 m-0">
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Order Type</Form.Label>
                        <Form.Control
                          size="sm"
                          disabled
                          value={watch('isStockTransferOrder') ? 'Stock Transfer Order' : 'Purchase Order'}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Purchase Order Number</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('purchaseOrderNumber', { required: true })}
                          isInvalid={!!errors.purchaseOrderNumber || showFormErrors}
                        />
                        <Form.Control.Feedback type="invalid">
                          Please provide a unique purchase order number
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Col>
                    <Col className={watch('isStockTransferOrder') ? '' : 'd-none'}>
                      <Form.Group className="mb-1">
                        <Form.Label>Source Location</Form.Label>
                        <Form.Control
                          size="sm"
                          disabled
                          value={locations[watch('sourceLocationId')]?.LocationName ?? ''}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Vendor</Form.Label>
                        <Controller
                          control={control}
                          name="vendorId"
                          defaultValue={null}
                          render={({
                            field: {
                              onChange, value, ref,
                            },
                            fieldState: {
                              isTouched, isDirty, error,
                            },
                            formState,
                          }) => (
                            <Typeahead
                              ref={ref}
                              value={value}
                              onChange={(e) => {
                                onChange(vendors?.find(
                                  (v) => v.vendorName === e[0],
                                )?.vendorId);
                              }}
                              size="sm"
                              id="vendorId"
                              placeholder={watch('isStockTransferOrder') ? 'Stock Transfer Order' : vendors.find((v) => v.vendorId === currentPurchaseOrder?.vendorId)?.vendorName}
                              disabled={!!currentPurchaseOrder?.vendorId || watch('isStockTransferOrder')}
                              options={vendors?.map((v) => v.vendorName)}
                              isInvalid={!!error}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  {currentPurchaseOrder?.approvedBy && currentPurchaseOrder?.approvedDate
                    && (
                      <Row>
                        <Col>
                          <Form.Group className="mb-1">
                            <Form.Label>Approved</Form.Label>
                            <DateInput
                              control={control}
                              disabled
                              formControlName="approvedDate"
                            />
                          </Form.Group>
                        </Col>
                        <Col>
                          <Form.Group className="mb-1">
                            <Form.Label>Approved By</Form.Label>
                            <Form.Control
                              size="sm"
                              disabled
                              value={usersByOid[watch('approvedBy')]?.displayName ?? ''}
                            />

                          </Form.Group>
                        </Col>
                      </Row>
                    )}

                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Location</Form.Label>
                        <Form.Control
                          size="sm"
                          disabled
                          value={locations[watch('locationId')]?.LocationName ?? ''}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Estimated Delivery Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="estimatedDeliveryDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Actual Delivery Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="actualDeliveryDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Quote Number Reference</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('quoteNumberReference')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Email Reference</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('emailReference')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Delivery Terms</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('deliveryTerms')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Shipping Instructions</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('shippingInstructions')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Payment Terms</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('paymentTerms')}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Notes</Form.Label>
                        <Form.Control
                          size="sm"
                          {...register('notes')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-1">
                    <Form.Label>Comments</Form.Label>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      rows={3}
                      {...register('comments')}
                      style={{ resize: 'none' }}
                    />
                  </Form.Group>
                  <Form.Group className="mb-1">
                    <Form.Label>Total</Form.Label>
                    <Form.Control
                      size="sm"
                      rows={3}
                      disabled
                      value={formatCurrency(currentPurchaseOrder?.purchaseOrderCost)}
                      style={{ resize: 'none' }}
                    />
                  </Form.Group>
                </Container>
              </Card>
            </Col>
          </Row>
          {/* <Row>
            <Col>
              <Card header="Status" className="card-secondary card-outline">

              </Card>
            </Col>
          </Row> */}

        </Container>

      </Form>

      <div className="d-flex flex-row position-sticky bottom-0 p-2 pr-1 bg-body border-top">
        <Button variant="light" target="__blank" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fHe3_MaintenancePurchaseOrder&rs:Command=Render&POid=${currentPurchaseOrder?.purchaseOrderId}`}>
          Purchase Order Report
          <FiExternalLink style={{ paddingLeft: 2, paddingBottom: 4 }} />
        </Button>
        <Button
          type="submit"
          disabled={purchaseOrderStatusIsLoading}
          onClick={handleSubmit(onSubmit)}
          style={{ marginLeft: 'auto' }}
        >
          Update
        </Button>
      </div>
    </>
  );
}

export default PurchaseOrderForm;
