import React, {
  useCallback, useEffect, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAssetWorkOrders, setFetchWorkOrderStatus } from '../../../store/slices/work-orders-slice';
import CustomModal from '../../UI/molecules/Modal/modal';
import AssetWorkOrdersTable from './asset-work-orders-table';
import CreateWorkOrder from './create-work-order';

function AssetWorkOrders(props) {
  const workOrdersFetchStatus = useSelector((state) => state.workOrder.workOrdersFetch.status);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    await dispatch(fetchAssetWorkOrders({
      assetType: props.assetTypeId,
      assetSerialNumber: props.assetSerialNumber,
    })).unwrap();
    dispatch(setFetchWorkOrderStatus('idle'));
  }, [workOrdersFetchStatus, props.assetTypeId, props.assetSerialNumber]);

  useEffect(() => {
    loadData();
  }, [props.assetTypeId, props.assetSerialNumber]);

  const [createModalShow, setCreateModalShow] = useState(false);
  const [createModalAssetType, setCreateModalAssetType] = useState(null);
  const handleCreateModalClose = () => {
    setCreateModalAssetType(null);
    setCreateModalShow(false);
  };
  const handleCreateModalShow = (assetType) => {
    setCreateModalAssetType(assetType);
    setCreateModalShow(true);
  };

  let modalContent;
  if (props.assetType === 'Liquid Container') {
    modalContent = (
      <CreateWorkOrder
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType="2"
        asset
      />
    );
  }
  if (props.assetType === 'Gas Container') {
    modalContent = (
      <CreateWorkOrder
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType="1"
        asset
      />
    );
  }
  if (props.assetType === 'Chassis') {
    modalContent = (
      <CreateWorkOrder
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType="4"
        asset
      />
    );
  }

  const tableContent = (
    <AssetWorkOrdersTable
      asset
      assetTypeId={props.assetTypeId}
      serialNumber={props.serialNumber}
      handleModalShow={handleCreateModalShow}
    />
  );

  return (
    <>
      <div className="h-100 d-flex">
        {tableContent}
      </div>
      <CustomModal
        contentClassName="h-100"
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType={createModalAssetType}
      >
        {modalContent}
      </CustomModal>
    </>
  );
}

export default AssetWorkOrders;
