import './callout.scss';

import React from 'react';

function Callout(props) {
  return (
    <div className={`callout callout-primary ${props.className}`}>
      {props.children}
    </div>
  );
}

export default Callout;
