import './table.scss';

import React, { forwardRef, useImperativeHandle } from 'react';
import { Table as BootstrapTable } from 'react-bootstrap';
import {
  useFilters, useGlobalFilter, useTable,
} from 'react-table';

const Table = forwardRef((props, ref) => {
  const { columns } = props;
  const { data } = props;
  const theadProps = props.theadProps ?? {};
  const rowProps = props.rowProps ?? function (row) { };
  const cellProps = props.cellProps ?? function (cell) { };
  const instance = useTable({
    columns, data,
  }, useFilters, useGlobalFilter);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = instance;

  useImperativeHandle(ref, () => instance);

  return (
  // apply the table props
    <div className="d-flex flex-column overflow-auto flex-grow-1 justify-content-between">
      <BootstrapTable size="sm" striped hover {...getTableProps()} className="custom-table table-layout-fixed">
        <thead {...theadProps}>
          {// Loop over the header rows
                        headerGroups.map((headerGroup) => (
                          // Apply the header row props
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                    headerGroup.headers.map((column) => (
                                      // Apply the header cell props
                                      <th {...column.getHeaderProps({
                                        style: {
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                          width: column.width,
                                        },
                                      })}
                                      >
                                        {// Render the header
                                                column.render('Header')
                                            }
                                      </th>
                                    ))
                                }
                          </tr>
                        ))
                    }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
                        rows.map((row) => {
                          // Prepare the row for display
                          prepareRow(row);
                          return (
                          // Apply the row props
                            <tr {...row.getRowProps(rowProps(row))}>
                              {// Loop over the rows cells
                                        row.cells.map((cell) => (
                                          <td {...cell.getCellProps([
                                            {
                                              className: cell.column.className,
                                              style: cell.column.style,
                                            },
                                          ])}
                                          >
                                            {// Render the cell contents
                                                    cell.render('Cell')
                                                }
                                          </td>
                                        ))
                                    }
                            </tr>
                          );
                        })
                    }
        </tbody>
      </BootstrapTable>
    </div>
  );
});

export default Table;
