import { ListView, ListViewColumnType, createConnectedListView } from "../../../UI/view/list-view";
import { gasBillingPendingDeliveryDataSource } from "./gas-billing-datasource";

const pendingDeliveryList = {
  columns: [
    {
      accessor: 'location.locationName',
      Header: 'Shipping Plant',
      defaultSort: true
    },
    {
      accessor: 'gasFilling.gasCustomer.customerName',
      Header: 'Customer Name',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/billing/gasproductcreate/' + row.original.trackingNumberId,
    },
    {
      accessor: 'gasFilling.gasCustomer.customerId',
      Header: 'PX Number'
    },
    {
      accessor: 'gasShipping.dateComplete',
      type: ListViewColumnType.date,
      Header: 'Ship Date'
    },
    {
      accessor: 'gasShipping.socpo',
      Header: 'SO Number'
    },
    {
      accessor: 'containerId',
      Header: 'Container Number'
    },
    {
      accessor: 'gasFilling.gasCustomer.city',
      Header: 'City'
    },
    {
      accessor: 'gasFilling.gasCustomer.state',
      Header: 'State'
    },
  ]
} as ListView;

export const PendingDeliveryList = createConnectedListView(pendingDeliveryList, gasBillingPendingDeliveryDataSource);