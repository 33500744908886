import { gasCustomerStore } from "../../../store/store";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createView } from "../../UI/view/view";

import { customerPermissions } from "./customer-data-source";
import { getCOCReports, getCoordinates, getDeliveryTypes } from "../../../services/requests";
import { GasHistoryListViewComponent } from "./customer-gas-history-list";

export const customerGasEdit = {
  editMode: true,
  pageHeader: 'Gas Customer',
  permissions: customerPermissions,
  breadcrumbs: [
    {
      name: 'Customers',
      icon: 'people',
      to: '/customers#Gas Customers',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              infoListItems: [
                {
                  label: 'Customer ID',
                  name: 'customerId',
                },
                {
                  label: 'Address',
                  name: 'address',
                },
                {
                  label: 'City',
                  name: 'city',
                },
                {
                  label: 'State',
                  name: 'state',
                },

              ],
              header: ({ gascustomers }) => gascustomers?.current?.data?.customerName,
            },
            {
              type: FormItemType.fileAttachments,
              permissions: customerPermissions,
              entityTypeId: 10,
              entityName: 'Gas Customers',
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'General Info',
              items: [
                {
                  type: FormItemType.row,
                  items: [
                    {
                      type: FormItemType.fieldset,
                      label: 'Details',
                      items: [
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'Name',
                              name: 'customerName',
                              required: true,
                              unique: true
                            },
                            {
                              label: 'Status',
                              name: 'status',
                              type: FormItemType.select,
                              required: true,
                              options: [{ text: "", id: null }, { text: "Active", id: "Active" }, { text: "In-Active", id: "In-Active" }]
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'Address',
                              name: 'address',
                              required: true,
                              tooltip: `If changing address please regenerate lat/long`
                            },
                            {
                              label: 'Address 2',
                              name: 'address2',
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'City',
                              name: 'city',
                              required: true,
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'State',
                              name: 'state',
                              required: true,
                            },
                            {
                              label: 'Zip',
                              name: 'zip',
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'latitude',
                              label: 'Latitude',
                              number: true,
                              disabled: ({ watch }) => !watch('overrideLatLong')
                            },
                            {
                              name: 'longitude',
                              label: 'Longitude',
                              number: true,
                              disabled: ({ watch }) => !watch('overrideLatLong')
                            },
                            {
                              type: FormItemType.checkboxField,
                              label: 'Override lat/long',
                              fitToContent: true,
                              name: 'overrideLatLong'
                            }
                          ]
                        },
                        {
                          type: FormItemType.button,
                          label: 'Generate Lat/Long',
                          style: { marginBottom: 6 },
                          handler: async ({ watch, setValue, getValues }) => {
                            const { address: address1, address2, city, state, zip } = getValues()
                            const address = `${address1 ?? ''} ${address2 ?? ''}, ${city ?? ''},${state ?? ''} ${zip ?? ''}`;
                            const coordinates = await getCoordinates(encodeURIComponent(address));
                            if (coordinates) {
                              setValue('latitude', coordinates?.latitude);
                              setValue('longitude', coordinates?.longitude);
                            }
                          }
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'billingPoNumberRequired',
                              label: 'PO# Required for Billing',
                              type: FormItemType.checkboxField
                            },
                            {
                              name: 'billingRoNumberRequired',
                              label: 'RO# Required for Billing',
                              type: FormItemType.checkboxField
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'poNumber',
                              label: 'PO Number'
                            },
                            {
                              name: 'releaseOrderNumber',
                              label: 'Release Order Number'
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'specs',
                              label: 'Specs',
                              required: true,
                            },
                            {
                              name: 'specialInstructions',
                              label: 'Special Instructions'
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'bdtcpo',
                              label: 'BDTCPO'
                            },
                            {
                              name: 'cocid',
                              label: 'COC',
                              type: FormItemType.select,
                              defaultValue: 0,
                              required: true,
                              options: async () => {
                                const res = await getCOCReports();
                                return [{ id: '', text: '-- Select --' }, ...res.map(rec => ({ id: rec.cocid, text: rec.cocName }))];
                              }
                            },
                          ]
                        },
                        {
                          name: 'coCdocumentText',
                          label: 'COC Text',
                          required: true,
                          type: FormItemType.textarea,
                          tooltip: `NOTE: If needed, please use the following input place holders (%abc%) in the COC text:
                              Date = %DATE%, Container = %CONTAINER%, Order Number = %ORDERNUMBER%,
                              Temperature = %TEMPERATURE%, Pressure = %PRESSURE%, Purity = %PURITY%`
                        },
                        {
                          name: 'driverInstruction',
                          type: FormItemType.textarea,
                          label: 'Driver Instruction'
                        },
                        {
                          name: 'logisticsOnlyNotes',
                          type: FormItemType.textarea,
                          label: 'Logistics Only Notes'
                        },
                        {
                          name: 'driverRouting',
                          type: FormItemType.textarea,
                          label: 'Driver Routing'
                        },
                        {
                          name: 'billingInstructions',
                          type: FormItemType.textarea,
                          label: 'Billing Instructions'
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'recommendedFillPlant',
                              label: 'Default Fill Plant',
                              type: FormItemType.select,
                              options: [
                                {
                                  text: '-- Select --',
                                  id: ''
                                },
                                { text: 'Algeria', id: 'Algeria' },
                                { text: 'Bushton', id: 'Bushton' },
                                { text: 'Tumbleweed', id: 'Tumbleweed' },
                                { text: 'Exxon', id: 'Exxon' },
                                { text: 'Qatar', id: 'Qatar' },
                                { text: 'Ulysses', id: 'Ulysses' },
                                { text: 'Shrewsbury', id: 'Shrewsbury' },
                                { text: 'Taft', id: 'Taft' },
                                { text: 'Keasbey', id: 'Keasbey' },
                                { text: 'Fife', id: 'Fife' },
                                { text: 'Ontario', id: 'Ontario' },
                                { text: 'North Royalton', id: 'North Royalton' },
                                { text: 'East Chicago', id: 'East Chicago' },
                                { text: 'Tampa', id: 'Tampa' },
                                { text: 'Waxahachie', id: 'Waxahachie' },
                              ]
                            },
                            {
                              name: 'defaultDeliveryTypeId',
                              label: 'Default Delivery Type',
                              type: FormItemType.select,
                              options: async () => {
                                const dt = await getDeliveryTypes(0);
                                return [{ id: '', text: '-- Select --' }, ...dt.map(rec => ({ id: rec.id, text: rec.name }))];
                              }
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'region',
                              label: 'Region',
                              required: true,
                              type: FormItemType.select,
                              options: [
                                { id: '', text: '-- Select --' },
                                { id: 'Internal', text: 'Internal' },
                                { id: 'KS Direct', text: 'KS Direct' },
                                { id: 'PDI', text: 'PDI' },
                                { id: 'Praxair Electronics', text: 'Praxair Electronics' },
                                { id: 'Praxair Mexico', text: 'Praxair Mexico' },
                                { id: 'Praxair South America', text: 'Praxair South America' },
                                { id: 'Repair Shop', text: 'Repair Shop' },
                                { id: 'US Direct', text: 'US Direct' },
                                { id: 'US Gov', text: 'US Gov' },
                              ]
                            },
                            {
                              name: 'aveNetVol',
                              label: 'Avg Net Volume',
                              required: true,
                              number: true
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'hourlyMaintenanceRate',
                              label: 'Hourly Maint. Rate',
                              number: true,
                              defaultValue: 0,
                              required: true
                            },
                          ]
                        },
                        {
                          name: 'miles',
                          label: 'Miles',
                          number: true
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'maxResidualPct',
                              label: 'Max Residual %'
                            },
                            {
                              name: 'minShipPressure',
                              label: 'Min Shipping Pressure (PSI)',
                              number: true,
                              defaultValue: 0,
                              required: true
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'bypassContaminentBilling',
                              label: 'Ignore Contaminents',
                              type: FormItemType.checkboxField,

                            },
                            {
                              name: 'crudeSource',
                              label: 'Crude Source',

                              type: FormItemType.checkboxField
                            },
                            {
                              name: 'compressorOnSite',
                              label: 'Compressor On Site',
                              fitToContent: true,
                              type: FormItemType.checkboxField
                            },
                          ]
                        },
                      ]
                    },
                    {
                      items: [
                        {
                          type: FormItemType.fieldset,
                          label: 'Consignee Details',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'consigneeName',
                                  label: 'Consignee Name'
                                },
                                {
                                  name: 'consigneeNumber',
                                  label: 'Consignee Number'
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'consigneeEmail',
                                  label: 'Consignee Email'
                                },
                                {
                                  name: 'consigneePhone',
                                  label: 'Consignee Phone'
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [

                                {
                                  name: 'consigneeFax',
                                  label: 'Consignee Fax'
                                },
                                {
                                  name: 'consigneeAddress',
                                  label: 'Consignee Address 1'
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [

                                {
                                  name: 'consigneeAddress2',
                                  label: 'Consignee Address 2'
                                },
                                {
                                  name: 'consigneeAddress3',
                                  label: 'Consignee Address 3'
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'consigneeAddress4',
                                  label: 'Consignee Address 4'
                                },
                                {
                                  name: 'consigneeAddress5',
                                  label: 'Consignee Address 5'
                                },

                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [

                                {
                                  name: 'consigneeCity',
                                  label: 'Consignee City'
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'consigneeState',
                                  label: 'Consignee State'
                                },
                                {
                                  name: 'consigneeZip',
                                  label: 'Consignee Zip'
                                },
                              ]
                            },
                          ]
                        },
                        {
                          type: FormItemType.fieldset,
                          label: 'Container Notifications',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'containerReturnEmail',
                                  label: 'Send Email When Container Returned?',
                                  type: FormItemType.yesno,
                                },
                                {
                                  name: 'containerShipEmail',
                                  label: 'Send Email When Container Shipped?',
                                  type: FormItemType.yesno,
                                },
                              ]
                            },

                            {
                              name: 'emailAddress',
                              label: 'Email Address'
                            },
                          ]
                        },
                        {
                          type: FormItemType.fieldset,
                          label: 'Receiver Bank Water Volumes',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'waterVolumeBankA',
                                  label: 'Water Vol Bank A',
                                  number: true
                                },
                                {
                                  name: 'waterVolumeBankB',
                                  label: 'Water Vol Bank B',
                                  number: true
                                },

                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'waterVolumeBankC',
                                  label: 'Water Vol Bank C',
                                  number: true
                                },
                                {
                                  name: 'waterVolumeBankD',
                                  label: 'Water Vol Bank D',
                                  number: true
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [

                                {
                                  name: 'waterVolumeBankE',
                                  label: 'Water Vol Bank E',
                                  number: true
                                },
                                {
                                  name: 'waterVolumeBankF',
                                  label: 'Water Vol Bank F',
                                  number: true
                                },
                              ]
                            },
                          ]
                        },
                      ]
                    },
                  ]
                },

              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: customerPermissions,
                }
              ]
            },
            {
              label: 'Billing',
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: customerPermissions,
                }
              ],
              items: [
                {
                  type: FormItemType.row,
                  items: [
                    {
                      type: FormItemType.fieldset,
                      label: 'Billing',
                      items: [
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'billingAddress1',
                              label: 'Billing Address 1'
                            },
                            {
                              name: 'billingAddress2',
                              label: 'Billing Address 2'
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'billingAddress3',
                              label: 'Billing Address 3'
                            },
                            {
                              name: 'billingAddress4',
                              label: 'Billing Address 4'
                            },

                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'billingAddress5',
                              label: 'Billing Address 5'
                            },
                            {
                              name: 'invoiceCustomerCode',
                              label: 'Invoice Customer Code'
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [

                            {
                              name: 'invoiceTC',
                              label: 'Invoice TC'
                            },
                            {
                              name: 'invoiceCO',
                              label: 'Invoice CO'
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'invoiceCountry',
                              label: 'Invoice Country'
                            },
                            {
                              name: 'invoiceLoc',
                              label: 'Invoice Loc'
                            },

                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [

                            {
                              name: 'invoiceTermsCode',
                              label: 'Invoice Terms Code'
                            },
                            {
                              name: 'invoiceTermsofPayment',
                              label: 'Invoice Terms of Payment'
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [

                            {
                              name: 'invoiceTermsofSale',
                              label: 'Invoice Terms of Sale'
                            },
                            {
                              name: 'invoiceRemittanceInfo',
                              label: 'Invoice Remittance Info'
                            },

                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'bypassGasBilling',
                              label: 'Bypass JDE Billing',
                              type: FormItemType.checkboxField

                            },
                          ]
                        },
                        {
                          name: 'bypassGasBillingReason',
                          label: 'Reason for Bypass',
                          type: FormItemType.textarea
                        },
                        {
                          name: 'billingComments',
                          label: 'Billing Comments',
                          type: FormItemType.textarea
                        },
                      ]
                    },
                    {
                      items: [
                        {
                          type: FormItemType.fieldset,
                          label: 'Rental',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'freeRentDays',
                                  label: 'Free Days',
                                  number: true,
                                  defaultValue: 0,
                                  required: true
                                },
                                {
                                  name: 'rentalFeePerDay',
                                  label: 'Rental $/Day',
                                  number: true,
                                  defaultValue: 0,
                                  required: true
                                },
                                {
                                  name: 'rentalFeePerMonth',
                                  label: 'Rental $/Month',
                                  number: true,
                                  defaultValue: 0,
                                  required: true
                                },
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'rentalEmail',
                                  label: 'Rental Email',
                                  maxLength: 255
                                }
                              ]
                            },
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'effectiveDate',
                                  label: 'Effective Date',
                                  type: FormItemType.date
                                },
                                {
                                  name: 'expirationDate',
                                  label: 'Expiration Date',
                                  type: FormItemType.date
                                }
                              ]
                            }
                          ]
                        },
                        {
                          type: FormItemType.fieldset,
                          label: 'Residual Penalty',
                          items: [
                            {
                              type: FormItemType.row,
                              items: [
                                {
                                  name: 'residualPenaltyPressureThreshold',
                                  label: 'Residual Pressure Threshold ',
                                  number: true,
                                  defaultValue: 0,
                                  required: true,
                                  min: 0
                                },
                                {
                                  name: 'residualPenaltyApplied',
                                  label: 'Residual Penalty Applied',
                                  fitToContent: true,
                                  type: FormItemType.checkboxField
                                },
                              ]
                            },
                          ]
                        }
                      ]
                    }
                  ]
                },
              ]
            },
            {
              label: 'Purity Requirements',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Customer Purity Requirements',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Hide Analytical Results (COC 3)',
                          type: FormItemType.checkboxField,
                          name: 'gasCustomerPurityRequirements.hideAnalyticalResults',
                          defaultValue: false
                        }
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'Neon Max',
                          name: 'Neon Max',
                          width: 140
                        },
                        {
                          name: 'gasCustomerPurityRequirements.neonMax',
                          number: true,
                          defaultValue: 2,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeNeon'),
                          width: 100,
                          min: 0,

                        },
                        {
                          name: 'gasCustomerPurityRequirements.neonEngUnits',
                          type: FormItemType.select,
                          defaultValue: 'PPM',
                          tooltip: 'Units',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' }
                          ],
                          fitToContent: true
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeNeon',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include'
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'O2 Max',
                          name: 'O2 Max',
                          width: 140
                        },
                        {
                          name: 'gasCustomerPurityRequirements.oxygenMax',
                          number: true,
                          defaultValue: 1,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeOxygen'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.oxygenEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          required: true,
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' }
                          ],
                          fitToContent: true
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeOxygen',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'N2 Max',
                          name: 'N2 Max',
                          width: 140
                        },
                        {
                          name: 'gasCustomerPurityRequirements.nitrogenMax',
                          number: true,
                          defaultValue: 5,
                          width: 100,
                          fitToContent: true,
                          min: 0,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeNitrogen'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.nitrogenEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeNitrogen',
                          defaultValue: false,
                          tooltip: 'Include',
                          type: FormItemType.checkboxField
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CO2 Max',
                          name: 'CO2 Max',
                          width: 140
                        },
                        {
                          name: 'gasCustomerPurityRequirements.carbonDioxideMax',
                          number: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeCarbonDioxide'),
                          width: 100,
                          defaultValue: 1,
                          min: 0,
                          fitToContent: true
                        },
                        {
                          name: 'gasCustomerPurityRequirements.carbonDioxideEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          required: true,
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeCarbonDioxide',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CO Max',
                          name: 'CO Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.carbonMonoxideMax',
                          number: true,
                          defaultValue: 30,
                          width: 100,
                          fitToContent: true,
                          min: 0,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeCarbonMonoxide'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.carbonMonoxideEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeCarbonMonoxide',
                          defaultValue: false,
                          tooltip: 'Include',
                          type: FormItemType.checkboxField,
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'H20 Max',
                          name: 'H20 Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.moistureMax',
                          number: true,
                          defaultValue: 1.5,
                          width: 100,
                          fitToContent: true,
                          min: 0,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeMoisture'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.moistureEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeMoisture',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'H2 Max',
                          name: 'H2 Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.hydrogenMax',
                          number: true,
                          defaultValue: 1,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeHydrogen'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.hydrogenEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeHydrogen',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'THC Max',
                          name: 'THC Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.thCmax',
                          number: true,
                          defaultValue: 1,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeThc'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.thcengUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeThc',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CH4 Max',
                          name: 'CH4 Max',
                          width: 140,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.methaneMax',
                          number: true,
                          width: 100,
                          defaultValue: 1,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeMethane'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.methaneEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                          required: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeMethane',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                          required: true,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'Assay',
                          name: 'AssayLabel',
                          width: 140,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.assay',
                          number: true,
                          defaultValue: 0,
                          min: 0,
                          width: 100,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeAssay'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.assayEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: '%',
                          options: [
                            { id: '%', text: '%---' },
                          ],
                          fitToContent: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeAssay',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'CO + CO2 Max',
                          name: 'CO + CO2 Max',
                          width: 140,

                        },
                        {
                          name: 'gasCustomerPurityRequirements.carbonMonoxideCarbonDioxideMax',
                          number: true,
                          defaultValue: 0.5,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeCarbonMonoxideCarbonDioxide'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.carbonMonoxideCarbonDioxideEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeCarbonMonoxideCarbonDioxide',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',

                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'O2 + Argon Max',
                          name: 'O2 + Argon Max',
                          width: 140,
                        },
                        {
                          name: 'gasCustomerPurityRequirements.oxygenArgonMax',
                          number: true,
                          defaultValue: null,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeOxygenArgon'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.oxygenArgonEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
    
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeOxygenArgon',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',

                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'N2 + Argon Max',
                          name: 'N2 + Argon Max',
                          width: 140,
 
                        },
                        {
                          name: 'gasCustomerPurityRequirements.nitrogenArgonMax',
                          number: true,
                          defaultValue: 5,
                          min: 0,
                          width: 100,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeNitrogenArgon'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.nitrogenArgonEngUnits',
                          type: FormItemType.select,
                          tooltip: 'Units',
                          defaultValue: 'PPM',
                          options: [
                            { id: 'PPM', text: 'PPM' },
                            { id: 'PPB', text: 'PPB' },
                          ],
                          fitToContent: true,
               
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeNitrogenArgon',
                          defaultValue: true,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.label,
                          label: 'Impurities, PPM by Vol, Max',
                          name: 'Impurities, PPM by Vol, Max',
                          width: 140,
                    
                        },
                        {
                          name: 'gasCustomerPurityRequirements.impuritiesPpmvolMax',
                          number: true,
                          defaultValue: null,
                          width: 100,
                          min: 0,
                          fitToContent: true,
                          required: ({watch})=> watch('gasCustomerPurityRequirements.includeImpuritiesPpmvol'),
                        },
                        {
                          name: 'gasCustomerPurityRequirements.includeImpuritiesPpmvol',
                          defaultValue: false,
                          type: FormItemType.checkboxField,
                          tooltip: 'Include',
                   
                        },
                      ]
                    },
                    //Impurities, PPM by Vol, Max

                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: customerPermissions,
                }
              ]
            },
            {
              label: 'Audit Logs',
              unMountOnExit: true,
              items: [
                {
                  type: FormItemType.auditLogs,
                  name: 'GasCustomers',
                }
              ]
            },
            {
              label: 'History',
              items: [
                {
                  type: FormItemType.customComponent,
                  component: GasHistoryListViewComponent,
                }
              ]
            },
          ]
        }
      ]
    }
  ]

} as FormView;

export const CustomerGasEdit = createView({ form: customerGasEdit as any, repository: gasCustomerStore as any });