import React from 'react';
import {
  Col, Container,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { formatCurrency } from '../../../services/format';
import { selectCurrentWorkOrderMaintenanceRequests, selectCurrentWorkOrderPartsConsumed } from '../../../store/slices/maintenance-requests-slice';
import Card from '../../UI/molecules/Card/card';

function WorkOrderLineItemsSummary() {
  const maintRequests = useSelector(selectCurrentWorkOrderMaintenanceRequests);
  const lineItemsObj = maintRequests.map(
    (
      { maintenanceRequestLineItems },
    ) => (maintenanceRequestLineItems !== null ? [maintenanceRequestLineItems] : []),
  );

  const lineItemsArr = lineItemsObj.length > 0 ? [].concat(...lineItemsObj.flat(1)) : [];

  const partsConsumed = useSelector(selectCurrentWorkOrderPartsConsumed);

  // filters to get hours/cost from active lineItems

  const partsCost = partsConsumed.reduce((acc, part) => {
    if (part?.unitCost) {
      return acc + part.unitCost;
    }
    return acc;
  }, 0);

  const laborHours = lineItemsArr.filter(
    (li) => li.isActive === true && li.maintenanceRequestLineItemTypeId === 2,
  ).map(({ quantity }) => [quantity]).flat(1).reduce((acc, val) => {
    return acc + val;
  }, 0);

  const vacuumHours = lineItemsArr.filter(
    (li) => li.isActive === true && li.maintenanceRequestLineItemTypeId === 3,
  ).map(({ duration }) => [duration]).flat(1).reduce((acc, val) => {
    return acc + val;
  }, 0);

  return (
    <Card header="Maintenance Requests Summary" className="card-secondary card-outline">
      <Container fluid className="p-0 m-0">
        <Col>
          <span className="info-box-text text-sm dg-draggable">
            <b>Total Parts Cost:</b>
            {' '}
            {formatCurrency(partsCost)}
          </span>
          <br />
          <span className="text-sm text-blue">
            <b>Total Labor Hours:</b>
            {' '}
            {laborHours}
          </span>
          <br />
          <span className="info-box-text text-sm">
            <b>Total Vacuum Hours:</b>
            {' '}
            {vacuumHours}
          </span>

        </Col>
      </Container>
    </Card>
  );
}

export default WorkOrderLineItemsSummary;
