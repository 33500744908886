import { FormItemType } from "../../UI/view/component-map";

const year = (y) => y.substring(2, 4);
const isPastMonth = (month) => {
  return (record, formState) => {
    const { year, snapshot } = formState.getValues();
    if(snapshot) return true;
    const currentYear = (new Date().getFullYear());
    if (year <= currentYear) {
      const currMonth = (new Date().getMonth()) + 1;
      return currMonth >= month;
    }
    return false;
  };
}
export const america = {
  type: FormItemType.spreadsheet,
  totals: [
    {
      header: 'Canada',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'Americas' && d.grouping == 'Canada' && !d.isOriginal)
    },
    {
      header: 'LG&E',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'Americas' && d.grouping == 'LG&E' && !d.isOriginal)
    },
    {
      header: 'North Latam',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'Americas' && d.grouping == 'North Latam' && !d.isOriginal)
    },
    {
      header: 'South Latam',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'Americas' && d.grouping == 'South Latam' && !d.isOriginal)
    },
    {
      header: 'US Direct',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'Americas' && d.grouping == 'US Direct' && !d.isOriginal)
    },
    {
      header: 'Adjustment (+-)',
      colspan: 4,
      selector: (data) => {
        const og = data.filter(d => d.segment == 'Americas' && d.isOriginal);
        const current = data.filter(d => d.segment == 'Americas' && !d.isOriginal);
        return current.map(curr => {
          const ogRecord = og.find(a => a.shipmentId == curr.shipmentId);
          return {
            ...curr,
            isOriginal: false,
            janCurrentYear: (curr.janCurrentYear ?? 0) - (ogRecord?.janCurrentYear ?? 0),
            febCurrentYear: (curr.febCurrentYear ?? 0) - (ogRecord?.febCurrentYear ?? 0),
            marCurrentYear: (curr.marCurrentYear ?? 0) - (ogRecord?.marCurrentYear ?? 0),
            aprCurrentYear: (curr.aprCurrentYear ?? 0) - (ogRecord?.aprCurrentYear ?? 0),
            mayCurrentYear: (curr.mayCurrentYear ?? 0) - (ogRecord?.mayCurrentYear ?? 0),
            junCurrentYear: (curr.junCurrentYear ?? 0) - (ogRecord?.junCurrentYear ?? 0),
            julyCurrentYear: (curr.julyCurrentYear ?? 0) - (ogRecord?.julyCurrentYear ?? 0),
            augCurrentYear: (curr.augCurrentYear ?? 0) - (ogRecord?.augCurrentYear ?? 0),
            septCurrentYear: (curr.septCurrentYear ?? 0) - (ogRecord?.septCurrentYear ?? 0),
            octCurrentYear: (curr.octCurrentYear ?? 0) - (ogRecord?.octCurrentYear ?? 0),
            novCurrentYear: (curr.novCurrentYear ?? 0) - (ogRecord?.novCurrentYear ?? 0),
            decCurrentYear: (curr.decCurrentYear ?? 0) - (ogRecord?.decCurrentYear ?? 0)
          }
        })
      }
    },
    {
      header: 'Americas Total',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'Americas' && !d.isOriginal),
      bold: true
    }
  ],
  name: 'liquidShipments',
  cells: [
    {
      header: 'Segment',
      name: 'segment',
      cellType: 'text',
      width: 80,
      readOnly: true,
      flexGrow: .2
    },
    {
      header: 'Grouping',
      name: 'grouping',
      cellType: 'text',
      width: 100,
      readOnly: true,
      flexGrow: .2
    },
    {
      header: 'Resp',
      name: 'salesManager',
      cellType: 'text',
      width: 80,
      readOnly: true,
      flexGrow: .2
    },
    {
      header: 'Customer',
      name: 'customerName',
      cellType: 'text',
      width: 250,
      readOnly: true,
      flexGrow: .4
    },
    {
      header: (requestArgs) => `Jan-${year(requestArgs)}`,
      name: 'janCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(1)
    },
    {
      header: (requestArgs) => `Feb-${year(requestArgs)}`,
      name: 'febCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(2)
    },
    {
      header: (requestArgs) => `Mar-${year(requestArgs)}`,
      name: 'marCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(3)
    },
    {
      header: (requestArgs) => `Apr-${year(requestArgs)}`,
      name: 'aprCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(4)
    },
    {
      header: (requestArgs) => `May-${year(requestArgs)}`,
      name: 'mayCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(5)
    },
    {
      header: (requestArgs) => `Jun-${year(requestArgs)}`,
      name: 'junCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(6)
    },
    {
      header: (requestArgs) => `Jul-${year(requestArgs)}`,
      name: 'julyCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(7)
    },
    {
      header: (requestArgs) => `Aug-${year(requestArgs)}`,
      name: 'augCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(8)
    },
    {
      header: (requestArgs) => `Sep-${year(requestArgs)}`,
      name: 'septCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(9)
    },
    {
      header: (requestArgs) => `Oct-${year(requestArgs)}`,
      name: 'octCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(10)
    },
    {
      header: (requestArgs) => `Nov-${year(requestArgs)}`,
      name: 'novCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(11)
    },
    {
      header: (requestArgs) => `Dec-${year(requestArgs)}`,
      name: 'decCurrentYear',
      cellType: 'number',
      width: 55,
      readOnly: isPastMonth(12)
    },

    {
      header: (requestArgs) => `1Q${year(requestArgs)}`,
      name: 'q1CurrentYear',
      cellType: 'number',
      width: 55,
      compute: ({ janCurrentYear, febCurrentYear, marCurrentYear }) => {
        return (janCurrentYear ?? 0) + (febCurrentYear ?? 0) + (marCurrentYear ?? 0);
      }
    },
    {
      header: (requestArgs) => `2Q${year(requestArgs)}`,
      name: 'q2CurrentYear',
      cellType: 'number',
      width: 55,
      compute: ({ aprCurrentYear, mayCurrentYear, junCurrentYear }) => {
        return (aprCurrentYear ?? 0) + (mayCurrentYear ?? 0) + (junCurrentYear ?? 0);
      }
    },
    {
      header: (requestArgs) => `3Q${year(requestArgs)}`,
      name: 'q3CurrentYear',
      cellType: 'number',
      width: 55,
      compute: ({ julyCurrentYear, augCurrentYear, septCurrentYear }) => {
        return (julyCurrentYear ?? 0) + (augCurrentYear ?? 0) + (septCurrentYear ?? 0);
      }
    },
    {
      header: (requestArgs) => `4Q${year(requestArgs)}`,
      name: 'q4CurrentYear',
      cellType: 'number',
      width: 55,
      compute: ({ octCurrentYear, novCurrentYear, decCurrentYear }) => {
        return (octCurrentYear ?? 0) + (novCurrentYear ?? 0) + (decCurrentYear ?? 0);
      }
    },
    {
      header: (requestArgs) => `${requestArgs.substr(0, 4)} Demand`,
      name: 'CurrentYearDemand',
      cellType: 'number',
      width: 100,
      compute: ({
        janCurrentYear,
        febCurrentYear,
        marCurrentYear,
        aprCurrentYear,
        mayCurrentYear,
        junCurrentYear,
        julyCurrentYear,
        augCurrentYear,
        septCurrentYear,
        octCurrentYear,
        novCurrentYear,
        decCurrentYear
      }) => {
        let q1 = (janCurrentYear ?? 0) + (febCurrentYear ?? 0) + (marCurrentYear ?? 0);
        let q2 = (aprCurrentYear ?? 0) + (mayCurrentYear ?? 0) + (junCurrentYear ?? 0);
        let q3 = (julyCurrentYear ?? 0) + (augCurrentYear ?? 0) + (septCurrentYear ?? 0);
        let q4 = (octCurrentYear ?? 0) + (novCurrentYear ?? 0) + (decCurrentYear ?? 0);
        return q1 + q2 + q3 + q4;
      }
    },
  ]
};

export const americaBase = { ...america, selector: (data) => data.filter(d => d.segment == 'Americas' && !d.isOriginal) };

export const americaTotal = {
  ...america, disabledEdit: true, selector: (data) => {
    //combine the two datasets
    //so first get each set...
    const base = data.filter(d => d.segment == 'Americas');
    const total = base.map(bRecord => {
      return {
        ...bRecord,
        janCurrentYear: (bRecord.janCurrentYear ?? 0),
        febCurrentYear: (bRecord.febCurrentYear ?? 0),
        marCurrentYear: (bRecord.marCurrentYear ?? 0),
        aprCurrentYear: (bRecord.aprCurrentYear ?? 0),
        mayCurrentYear: (bRecord.mayCurrentYear ?? 0),
        junCurrentYear: (bRecord.junCurrentYear ?? 0),
        julyCurrentYear: (bRecord.julyCurrentYear ?? 0),
        augCurrentYear: (bRecord.augCurrentYear ?? 0),
        septCurrentYear: (bRecord.septCurrentYear ?? 0),
        octCurrentYear: (bRecord.octCurrentYear ?? 0),
        novCurrentYear: (bRecord.novCurrentYear ?? 0),
        decCurrentYear: (bRecord.decCurrentYear ?? 0)
      }
    });
    return total;
  }
};