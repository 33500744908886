import React, { useMemo } from 'react';
import {
  Button, Col, Container, Form, Modal,
  Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { createChassis } from '../../../store/slices/chassis-slice';
import {
  chassisLength,
  chassisManufacturers,
  chassisResidentRegion,
  chassisTypes,
} from './chassis-values';

function CreateChassisForm({ onHide, chassisStatusIsLoading }) {
  // some of the below values must be deleted
  const defaultChassisFormValues = {
    AssetType: 'Chassis',
    serialNumber: '',
    ownerCustomer: null,
    type: null,
    residentRegion: null,
    length: null,
    isActive: true,
    comments: null,
    manufacturedYear: null,
    manufacturer: null,
  };

  const {
    register, handleSubmit, formState: { errors }, setValue, reset,
  } = useForm({
    defaultValues: defaultChassisFormValues,
  });

  const dispatch = useDispatch();

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const onSubmit = (chassis) => {
    Object.keys(chassis).forEach((key) => {
      if (key.toLowerCase().includes('date') && chassis[key]) {
        chassis[key] = convertDateToUTCDay(chassis[key]);
      }
    });
    dispatch(createChassis({ ...chassis, assetType: 'Chassis' }));
  };

  const chassisCustomers = useSelector(((state) => state.legacy?.dropdownValues?.liquidCustomers));
  const activeCustomers = useMemo(() => {
    const sortedCustomers = [...chassisCustomers].sort((a, b) => {
      return a.CustomerName.trim().localeCompare(b.CustomerName.trim());
    });
    return sortedCustomers.filter((cust) => cust.Status === 'Active');
  }, [chassisCustomers]);

  return (
    <>
      <Modal.Body>
        <Form className="bg-white h-100 overflow-auto">
          <Spinner animation="border" variant="primary" className={`create-spinner${chassisStatusIsLoading ? ' visible' : ' invisible'}`} />
          <Container fluid className={` ${chassisStatusIsLoading ? ' creation-loading' : ''}`}>
            <Container className="p-0 m-0">
              <Form.Group className="mb-1">
                <Form.Label>Unit Number</Form.Label>
                <Form.Control
                  size="sm"
                  {...register('serialNumber', { required: 'Please enter a serial number.', maxLength: { message: 'Unit ID cannot exceed 15 characters', value: 15 } })}
                  isInvalid={!!errors.serialNumber}
                />
                <Form.Control.Feedback type="invalid">
                  {errors.serialNumber && errors.serialNumber.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Owner</Form.Label>
                <Form.Select size="sm" {...register('ownerCustomer', { required: 'Please select an owner.' })} isInvalid={!!errors.ownerCustomer}>
                  {activeCustomers
                    .map((customer, index) => (
                      <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                    ))}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                  {errors.ownerCustomer && errors.ownerCustomer.message}
                </Form.Control.Feedback>
              </Form.Group>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Manufacturer</Form.Label>
                    <Form.Select size="sm" {...register('manufacturer')}>
                      {chassisManufacturers.map((manufacturer, i) => (
                        <option value={manufacturer} key={`manufacturer-${manufacturer}`}>{manufacturer}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Manufactured Year</Form.Label>
                    <Form.Control
                      type="number"
                      min="0"
                      size="sm"
                      placeholder="YYYY"
                      {...register('manufacturedYear', { pattern: { value: /^\d{4}$/, message: 'Enter a valid year YYYY' } })}
                      isInvalid={!!errors.manufacturedYear}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.manufacturedYear && errors.manufacturedYear.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Type</Form.Label>
                    <Form.Select size="sm" {...register('type')}>
                      {chassisTypes.map((type, i) => (
                        <option value={i} key={`type-${type}`}>{type}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Length</Form.Label>
                    <Form.Select size="sm" {...register('length')}>
                      {chassisLength.map((type, i) => (
                        <option value={i} key={`length-${type}`}>{type}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Resident Region</Form.Label>
                    <Form.Select size="sm" {...register('residentRegion', { required: 'Please select a resident region.' })} isInvalid={!!errors.residentRegion}>
                      {chassisResidentRegion.map((type, i) => (
                        <option value={i} key={`resident-region-${type}`}>{type}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.residentRegion && errors.residentRegion.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group>
                <Form.Label>Comments</Form.Label>
                <Form.Control size="sm" as="textarea" rows={3} {...register('comments')} style={{ resize: 'none' }} />
              </Form.Group>
            </Container>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          onClick={onHide}
          variant="secondary"
        >
          Close

        </Button>
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
          disabled={chassisStatusIsLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Submit

        </Button>
      </Modal.Footer>
    </>

  );
}

export default CreateChassisForm;
