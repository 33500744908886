import { getGasAndChassisReadings, getGasAndChassisReadingsObj, getRareGasModules } from "../../../services/requests";
import { getRareGasReadingsObj } from "../../../services/requests/raregasRequests";
import { DataSource } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";


export const rareGasPermissions = {
  read: [1, 19],
  write: [1, 19],
  admin: [1, 19],
};

export const rareGasEditDataSource = {
  name: 'raregas',
  request: getRareGasModules,
  pk: 'serialNumber'
} as DataSource;

const getDateString = (dt) => dt?.toISOString ? dt.toISOString() : dt;

//getGasAndChassisReadings = async (type, serialNumber, range)
export const rareGasSensorsDataSource = {
  name: 'raregassensors',
  entityRequest: getRareGasReadingsObj,
  entityRequestArgs: ({ getValues }, containerId) => {
    const { dateRange } = getValues();
    let range = dateRange;
/*     if (!range?.start || !range.end) {
      return 'RareGas_' + containerId + '_';
    } */
    return containerId + '_' + getDateString(range.start) + '_' + getDateString(range.end)
  },
  pk: 'serialNumber'
} as DataSource;