import { FormItemType } from "../../../UI/view/component-map";
import { Repository } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { BillingDewarListView } from "./dewar-billing-invoice-list";
import { PastVolumesList } from "./dewar-billing-pastvolumes";
import { DewarBillingPendingList, DewarBillingPendingListRepo } from "./dewar-billing-pending-list";
import { DewarPricingForm } from "./dewar-pricing";
 
export const dewarBillingMain = {
  editMode: false,
  pageHeader: 'Dewar Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Dewar Billing',
      icon: 'currency-dollar',
      to: '/billing/dewarproduct',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Pending Auto Uploads',
          items: [
            {
              type: FormItemType.customField,
              name: 'pendingDewarInvoices',
              component: DewarBillingPendingList
            }
          ]
        },
        {
          label: 'Invoices',
          items: [
            {
              type: FormItemType.customField,
              component: BillingDewarListView,
              name: 'dewarInvoices',
              badgeCounter: (state) => {
                return state.dewarinvoices?.data?.length;
              },
            }
          ]
        },
        {
          label: 'Dewar Pricing',
          items: [
            {
              type: FormItemType.customComponent,
              component: DewarPricingForm
            }
          ]
        },
        {
          label: 'Past Volumes',
          items: [
            {
              type: FormItemType.customComponent,
              component: PastVolumesList
            }
          ]
        }
      ]
    }
  ]
} as FormView;

export const[DewarBillingMain, DewarBillingMainRepo] = createConnectedView2({form: dewarBillingMain, existingRepo: DewarBillingPendingListRepo as Repository});