import moment from "moment";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { FilterType, ListView, ListViewColumnType, createConnectedListView, createConnectedListView2, createListView } from "../../UI/view/list-view";
import { createConnectedView, createView } from "../../UI/view/view";
import { rtuDataSource, rtuPermissions } from "./rtu-data-source";
import { formatDate } from "../../../services/format";
import { exportDemandForecasting } from "../../../services/requests";
import { rtuExport } from "./rtu-export";
import { CreateRTU, CreateRTURepo, addRTUDataSource, creatRTURequest } from "./rtu-add";

export const rtuListView = {
  customExport: rtuExport,
  enableExport: true,
  defaultPageSize: 100,
  permissions: rtuPermissions,
  filters: [
    {
      type: FilterType.simple,
      label: 'Active',
      fieldName: 'isActive',
      filterValue: true
    },
  ],
  additionalModals: [
    {
      form: CreateRTU,
      repository: CreateRTURepo,
      name: 'addToRTU',
      title: 'Send Gas Customer To RTU Manager',
    }
  ],
  toolbar: [
    {
      type: FormItemType.button,
      modal: 'addToRTU',
      label: 'Send Gas Customer To RTU Manager',
    }
  ],
  columns: [
    {
      accessor: 'delete',
      type: ListViewColumnType.delete,
    },
    {
      accessor: 'praxairNumber',
      Header: 'Px Number',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ row }) => `/telemetry/edit/${row.original.rtupk}`
    },
    {
      accessor: 'locationName',
      Header: 'Name',
      defaultSort: true,
    },
    {
      accessor: 'locationInfo',
      Header: 'Location Info',
    },
    {
      accessor: 'rtuSerialNumber',
      Header: 'S/N',
      hideOverflow: true,
      maxWidth: 100
    },
    {
      accessor: 'channelId',
      Header: 'Channel',
    },
    {
      accessor: 'readingTimestamp',
      Header: 'Last Reading Date',
      type: ListViewColumnType.datetime,
      validation: {
        hour24: {
          message: 'No Reading In 24 Hours',
          handler: (rtu) => {
            let LastReadingDateTime = moment(rtu.readingTimestamp);
            let TodaysDate = moment();

            //Check if the Last reading is older than 24 hours
            let myCompareReadingDate = TodaysDate.diff(LastReadingDateTime, 'hour');
            if (myCompareReadingDate >= 24) {
              return false;
            }
            return true;
          },
        }
      },
    },
    {
      accessor: 'readingValue',
      Cell: ({ row }) => `${Math.trunc(row.original.readingValue)} ${row.original.unitOfMeasureCode}`,
      Header: 'Reading',
    },
    {
      id: 'avgUsage',
      Header: 'Avg psi/day',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return 'loading...';
        return metrics?.avgUsage;
      }
    },
    {
      id: 'currentUsage',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return 'loading...';
        return metrics?.currentUsage;
      },
      Header: 'psi/day',
      validation: {
        acceleratedUse: {
          message: 'Current Usage has Accelerated by more than 50% of the average.',
          handler: (row) => {
            if (row.avgUsage > 0 && row.readingValue < 1000) {
              let myCompareUsage = (row.avgUsage * 1.5) - row.currentUsage;
              if (myCompareUsage <= 0) {
                return false;
              }
            }
            return true;
          }
        }
      }
    },
    {
      id: 'fullPoint',
      Header: 'Full',
      accessor: ({ fullPoint }) => {
        return fullPoint;
      },
      validation: {
        atZero: {
          message: 'Full point is at zero.',
          handler: (row) => {
            if (!row.bypassSetpointAlerts && row.fullPoint == 0) {
              return false;
            }
            return true;
          }
        }
      }
    },
    {
      id: 'orderPoint',
      accessor: ({ orderPoint }) => {
        return orderPoint;
      },
      Header: 'Order',
      validation: {
        atZero: {
          message: 'Order point is at zero.',
          handler: (row) => {
            if (!row.bypassSetpointAlerts && row.orderPoint == 0) {
              return false;
            }
            return true;
          }
        }
      }
    },
    {
      id: 'reservePoint',
      Header: 'Reserve',
      accessor: ({ reservePoint }) => {
        return reservePoint;
      },
      validation: {
        atZero: {
          message: 'Reserve point is at zero.',
          handler: (row) => {
            if (!row.bypassSetpointAlerts && row.reservePoint == 0) {
              return false;
            }
            return true;
          }
        }
      }
    },
    {
      id: 'outagePoint',
      accessor: ({ outagePoint }) => {
        return outagePoint;
      },
      Header: 'Outage',
      validation: {
        atZero: {
          message: 'Outage point is at zero.',
          handler: (row) => {
            if (!row.bypassSetpointAlerts && row.outagePoint == 0) {
              return false;
            }
            return true;
          }
        }
      }
    },
/*     {
      id: 'dayOfReservePointMinus3',
      Header: 'Est. Order',
      type: ListViewColumnType.date,
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return 'loading...';
        return metrics?.dayOfReservePointMinus3;
      },
       validation: {
        orderNumber: {
          message: (row) => `Order ${row.orderNumber} placed for this low level customer`,
          handler: (row) => {
            return !row.orderNumber || row.dayOfReservePointMinus3 == 'Unknown';
          },
          color: 'blue'
        },
        orderWarning: {
          message: 'Order warning.',
          handler: (row) => {
            return !row.orderWarning || row.dayOfReservePointMinus3 == 'Unknown';
          }
        },
      }
    }, */
    {
      id: 'dayOfReservePoint',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return 'loading...';
        return metrics?.dayOfReservePoint;
      },
      Header: 'Est. Delivery',
      type: ListViewColumnType.date,
       validation: {
        orderNumber: {
          message: (row) => `Order ${row.reserveNumber} placed with Date Requested of ${formatDate(row.dateRequested)}`,
          handler: (row) => {
            return !row.reserveNumber || row.dayOfReservePoint == 'Unknown';
          },
          color: 'blue'
        },
        orderWarning: {
          message: 'Reserve warning.',
          handler: (row) => {
            return !row.reserveWarning || row.dayOfReservePoint == 'Unknown';
          }
        },
      }
    },
    {
      id: 'dayOfOutagePoint',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return 'loading...';
        return metrics?.dayOfOutagePoint;
      },
      Header: 'Outage Date',
      type: ListViewColumnType.date,
      validation: {
         orderNumber: {
          message: (row) => `Order ${row.outageNumber} placed with Date Requested of ${formatDate(row.dateRequested)}`,
          handler: (row) => {
            return !row.outageNumber || row.dayOfOutagePoint == 'Unknown';
          },
          color: 'blue'
        },
        orderWarning: {
          message: 'Order warning.',
          handler: (row) => {
            return !row.outageWarning || row.dayOfOutagePoint == 'Unknown';
          }
        },
      }
    },
    {
      accessor: 'comment',
      Header: 'Comment',
      hideOverflow: true
    },
    {
      accessor: 'rtupk',
      Header: 'RTUPK',
      hidden: true
    },
    {
      id: 'orderWarning',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return null;
        return metrics?.orderWarning;
      },
      Header: 'orderWarning',
      hidden: true
    },
    {
      id: 'orderNumber',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return null;
        return metrics?.orderNumber;
      },
      Header: 'orderNumber',
      hidden: true
    },
    {
      id: 'outageNumber',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return null;
        return metrics?.outageNumber;
      },
      Header: 'outageNumber',
      hidden: true
    },
    {
      id: 'outageWarning',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return null;
        return metrics?.outageWarning;
      },
      Header: 'outageWarning',
      hidden: true
    },
    {
      id: 'reserveWarning',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return null;
        return metrics?.reserveWarning;
      },
      Header: 'reserveWarning',
      hidden: true
    },
    {
      id: 'reserveNumber',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return null;
        return metrics?.reserveNumber;
      },
      Header: 'reserveNumber',
      hidden: true
    },
    {
      id: 'dateRequested',
      accessor: ({ rtuId, channelId }, { usageMetrics }) => {
        const metrics = usageMetrics?.find(x => x.channelId == channelId && x.rtuId == rtuId);
        if (!metrics) return null;
        return metrics?.dateRequested;
      },
      Header: 'dateRequested',
      hidden: true
    },
    {
      accessor: 'isActive',
      Header: 'isActive',
      hidden: true
    }
    
  ]
} as ListView;


const validators = rtuListView.columns.filter(c => c.validation).map(c => ([...Object.values(c.validation).map((v: any) => v.handler)])).flat(Infinity);
export const hasAlerts = (rtu) => {
  let alerts = false;
  validators.forEach((v: any) => {
    if (!v(rtu)) {
      alerts = true
      return;
    };
  });
  return alerts;
}

export const [RTUListView, repo] = createConnectedListView2(rtuListView, rtuDataSource);
export const RTUListViewAlerts = createListView({ ...rtuListView, noFetchOnMount: true, filters: undefined }, repo as any, state =>  state.rtu.usageMetrics?.data ? state.rtu.usageMetrics?.data?.filter(r => r.isActive && hasAlerts(r)) : []);

export const tabs: FormView = {
  pageHeader: 'RTU',
  permissions: rtuPermissions,
  breadcrumbs: [
    {
      name: 'Users',
      icon: 'people',
      to: '/admin/users',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'RTU',
          items: [
            {
              type: FormItemType.customComponent,
              component: RTUListView as any
            }
          ]
        },
        {
          label: 'Alerts',
          badgeCounter: (state) => {
            const a = state.rtu.usageMetrics?.data ? state.rtu.usageMetrics?.data?.filter(r => r.isActive && hasAlerts(r)) : [];
            return a?.length;
          },
          items: [
            {
              type: FormItemType.customComponent,
              component: RTUListViewAlerts
            }
          ]
        }
      ]
    }
  ]
};

export const RTUTabbedComponent = createConnectedView({ form: tabs as any, dataSource: rtuDataSource as any });