import axios from "axios";
import { handleRequestError, memoizePromiseFn } from "../../requests";

export const recalcInvoice = async (invoice) => {
  const results = await axios.post(`/GasBilling/RecalcInvoice`, invoice).catch(handleRequestError);
  return results.data;
};

export const getGasRecentDeliveries = async (customerId) => {
  const results = await axios.get(`/GasBilling/GetGasRecentDeliveries/${customerId}`).catch(handleRequestError);
  return results.data;
}

export const getGasInvoice = async (trackingid) => {
  const results = await axios.get(`/GasBilling/GetGasInvoice/${trackingid}`).catch(handleRequestError);
  return results.data;
}
export const InsertBilling = async (billing) => {
  const results = await axios.post('/GasBilling/InsertBilling', billing).catch(handleRequestError);
  return results.data;
}

export const getGasInvoices = async (queryDefinition) => {
  const results = await axios.post(`/GasBilling/GetBillingList`, queryDefinition).catch(handleRequestError);
  return results.data;
}
export const getGasPendingDeliveries = async (cst) => {
  const results = await axios.get(`/GasBilling/getGasPendingDeliveries`).catch(handleRequestError);
  return results.data;
}

export const deleteInvoice = async (invoiceId) => {
  const results = await axios.delete(`/GasBilling/DeleteInvoice/${invoiceId}`).catch(handleRequestError);
  return results.data;
};
// /PreAuthorizeCreditCard
export const PreAuthorizeCreditCard = async (billingId) => {
  const results = await axios.get(`/GasBilling/PreAuthorizeCreditCard/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const getBillingHeader = async (billingId) => {
  const results = await axios.get(`/GasBilling/getBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const reverseBilling = async (reverse) => {
  const results = await axios.post(`/GasBilling/reverseBilling`, reverse).catch(handleRequestError);
  return results.data;
};

export const uploadInvoicesJDEDTO = async (invoice) => {
  const results = await axios.post(`/GasBilling/UploadInvoicesToJDE`, invoice).catch(handleRequestError);
  return results.data;
};

export const SendReport = async (report) => {
  const results = await axios.post('/GasBilling/SendReport', report).catch(handleRequestError);
  return results.data;
};
export const downloadReport = async (report) => {
  const results = await axios.post('/GasBilling/DownloadReport', report, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', report.filename);
  document.body.appendChild(link);
  link.click();
};
export const GetJDEErrors = async (billing) => {
  const results = await axios.get('/GasBilling/GetJDEErrors').catch(handleRequestError);
  return results.data;
}
export const GetUnbilledInvoices = async (billing) => {
  const results = await axios.get('/GasBilling/GetUnbilledInvoices').catch(handleRequestError);
  return results.data;
}

export const UpdateBilling = async (billing) => {
  const results = await axios.put('/GasBilling/UpdateBilling', billing).catch(handleRequestError);
  return results.data;
}