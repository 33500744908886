import { getElectronics, getGasAndChassisReadingsObj, getRareGasCustomers, insertElectronics, updateElectronics } from "../../../services/requests";
import { FormItemType } from "../../UI/view/component-map";
import { DataSource } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";

export const electronicsPermissions = {
  read: [1, 20],
  write: [1, 20],
  admin: [1, 20],
};
const getDateString = (dt) => dt?.toISOString ? dt.toISOString() : dt;
export const elecontricsSensorsDataSource = {
  name: 'electronicssensors',
  entityRequest: getGasAndChassisReadingsObj,
  entityRequestArgs: ({ getValues }, containerId) => {
    const { dateRange } = getValues();
    let range = dateRange;
    return 'electronics_' + containerId + '_' + getDateString(range.start) + '_' + getDateString(range.end)
  },
  pk: 'serialNumber'
} as DataSource;

export const electronicsDs = {
  name: 'electronics',
  pk: 'assetId',
  request: getElectronics,
  updateRequest: updateElectronics,
  createRequest: insertElectronics
} as DataSource;

export const electronicsAdd = (isEdit) =>  ({
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Info',
      items: [
        {
          name: 'trackingId',
          label: 'Tracking Id',
          required: true,
          disabled: () => isEdit
        },
        {
          name: 'installer',
          label: 'installer',
          required: true,
          type: FormItemType.select,
          options: [
            {
              id: null,
              text: ''
            },
            {
              id: 'Ningbo',
              text: 'Ningbo',
            },
            {
              id: 'SOPO',
              text: 'SOPO',
            },
            {
              id: 'Huizhou',
              text: 'Huizhou',
            },
            {
              id: 'Leuna',
              text: 'Leuna',
            },
            {
              id: 'Alpha',
              text: 'Alpha',
            },
          ]
        },
        {
          name: 'assetId',
          label: 'Asset Tag',
          required: true,
        },
        {
          name: 'type',
          label: 'Type',
          required: true,
          type: FormItemType.select,
          defaultValue: null,
          options: [
            {
              id: null,
              text: ''
            },
            {
              id: 'MCP',
              text: 'MCP'
            },
            {
              id: 'TT',
              text: 'TT'
            },
          ]
        },
        {
          name: 'owner',
          label: 'Owner',
          required: true,
          type: FormItemType.select,
          defaultValue: null,
          options: [
            {
              id: null,
              text: ''
            },
            {
              id: 'Linde China',
              text: 'Linde China',
            },
            {
              id: 'He&RG',
              text: 'He&RG',
            },
            {
              id: 'Linde Gas Germany',
              text: 'Linde Gas Germany',
            },
          ]
        },
        {
          name: 'isActive',
          label: 'isActive',
          defaultValue: true,
          hideIf: () => true
        },
      ]
    },
  ]

} as FormView);



//export const CreateElectronics = createConnectedView({form: electronicsAdd, dataSource: electronicsDs});

export const electronicsList = {
  /*   variant: 'card', */
  create: electronicsAdd(false),
  update: electronicsAdd(true),
  /*   update: electronicsAdd, */
  filters: [
    {
      type: FilterType.simple,
      fieldName: 'isActive',
      label: 'Active',
      filterValue: true
    }
  ],
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      accessor: 'trackingId',
      Header: 'Tracking Id',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/raregas/eedit/' + row?.original?.assetId,
      defaultSort: true,
    },
    {
      accessor: 'installer',
      Header: 'installer'
    },
    {
      accessor: 'assetId',
      Header: 'Asset Tag'
    },
    {
      accessor: 'type',
      Header: 'Type'
    },
    {
      accessor: 'owner',
      Header: 'Owner'
    },
    {
      accessor: 'isActive',
      Header: 'isActive',
      hidden: true
    },
  ]
} as ListView;

export const ElectronicsListView = createConnectedListView(electronicsList, electronicsDs);