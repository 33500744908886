import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAuditLogs, getEntityAuditLogs, getUserAuditLogs } from '../../services/requests';

const excludes = [
  {
    column: 'LastActive',
    table: 'Users',
  },
];

const initialState = {
  userAuditLogs: {},
  adminAuditLogs: {},
  entityAuditLogs: {},
  userLogsFetch: {
    status: 'idle',
    error: null,
  },
  adminLogsFetch: {
    status: 'idle',
    error: null,
  },
  entityLogsFetch: {
    status: 'idle',
    error: null,
  },
};

export const fetchUserAuditLogs = createAsyncThunk('audit/fetchUserAuditLogs', async (OId) => {
  let response = await getUserAuditLogs(OId);
  response = response?.filter((log) => !excludes.find((e) => e.table === log.tableName && JSON.parse(log.changedColumns)?.includes(e.column)));
  return response;
});

export const fetchAdminAuditLogs = createAsyncThunk('audit/fetchAdminAuditLogs', async () => {
  const response = await getAuditLogs();
  return response;
});

export const fetchEntityAuditLogs = createAsyncThunk('audit/fetchEntityAuditLogs', async (data) => {
  const response = await getEntityAuditLogs(data.tableName, data.tablePK, data.tableSchema);
  return response;
});

export const selectAdminLogsFetchStatus = (state) => state.audit.adminLogsFetch.status;
export const selectUserAuditLogs = (state) => state.audit.userAuditLogs;
export const selectAdminAuditLogs = (state) => state.audit.adminAuditLogs;
export const selectEntityAuditLogs = (state) => state.audit.entityAuditLogs;

export const auditSlice = createSlice({
  name: 'audit',
  initialState,
  reducers: {
    setEntityLogsFetchStatus: (state, action) => {
      state.entityLogsFetch.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUserAuditLogs.pending, (state, action) => {
        state.userLogsFetch.status = 'loading';
      })
      .addCase(fetchUserAuditLogs.fulfilled, (state, action) => {
        state.userLogsFetch.status = 'succeeded';
        state.userAuditLogs = action.payload;
      })
      .addCase(fetchUserAuditLogs.rejected, (state, action) => {
        state.userLogsFetch.status = 'failed';
        state.userLogsFetch.error = action.error.message;
      });
    builder
      .addCase(fetchAdminAuditLogs.pending, (state, action) => {
        state.adminLogsFetch.status = 'loading';
      })
      .addCase(fetchAdminAuditLogs.fulfilled, (state, action) => {
        state.adminLogsFetch.status = 'succeeded';
        state.adminAuditLogs = action.payload;
      })
      .addCase(fetchAdminAuditLogs.rejected, (state, action) => {
        state.adminLogsFetch.status = 'failed';
        state.adminLogsFetch.error = action.error.message;
      });
    builder
      .addCase(fetchEntityAuditLogs.pending, (state, action) => {
        state.entityLogsFetch.status = 'loading';
      })
      .addCase(fetchEntityAuditLogs.fulfilled, (state, action) => {
        state.entityLogsFetch.status = 'succeeded';
        state.entityAuditLogs = action.payload;
      })
      .addCase(fetchEntityAuditLogs.rejected, (state, action) => {
        state.entityLogsFetch.status = 'failed';
        state.entityLogsFetch.error = action.error.message;
      });
  },
});

export const { setEntityLogsFetchStatus } = auditSlice.actions;

export default auditSlice.reducer;
