import React, { useEffect, useState } from 'react';
import {
  Card, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

function AssetDetailsTabs(props) {
  const [activeTab, setActiveTab] = useState('General Info');

  const location = useLocation();

  const maintenanceDisabled = !!props.generalInfoContents.props.currentPackagedProduct;

  useEffect(() => {
    if (location.hash.toLowerCase().includes('general')) {
      setActiveTab('General Info');
    } else if (location.hash.toLowerCase().includes('audit')) {
      setActiveTab('Audit Logs');
    } else if (location.hash.toLowerCase().includes('work') && !maintenanceDisabled) {
      setActiveTab('Work Orders');
    } else if (location.hash.toLowerCase().includes('maintenance') && !maintenanceDisabled) {
      setActiveTab('Maintenance Requests');
    } else if (location.hash.toLowerCase().includes('sensors') && !maintenanceDisabled) {
      setActiveTab('Sensors');
    }
  }, [location.hash, maintenanceDisabled]);

  // function added to remove the work order and maintenance requests tabs
  // if it's a packaged product
  const workOrdersAndMaintenanceRequestTabs = maintenanceDisabled ? null : (
    <>
      <Nav.Item><Nav.Link eventKey="Work Orders">Work Orders</Nav.Link></Nav.Item>
      <Nav.Item><Nav.Link eventKey="Maintenance Requests">Maintenance Requests </Nav.Link></Nav.Item>
    </>
  );

  const workOrdersAndMaintenanceRequestContents = maintenanceDisabled ? null : (
    <>
      <TabPane eventKey="Work Orders" className="flex-grow-1 w-100">
        <div className="d-flex flex-grow-1 flex-column h-100">
          {props.workOrderContent}
        </div>
      </TabPane>
      <TabPane eventKey="Maintenance Requests" className="flex-grow-1 w-100">
        {props.maintenanceRequestContent}
      </TabPane>
    </>
  );

  const historyTab = !props.historyContent ? null : (
    <Nav.Item><Nav.Link eventKey="History">History</Nav.Link></Nav.Item>
  );

  const historyContent = !props.historyContent ? null : (
    <TabPane eventKey="History" className="flex-grow-1 w-100" mountOnEnter>
      {props.historyContent}
    </TabPane>
  );

  const sensorTab = !props.sensorContent ? null : (
    <Nav.Item><Nav.Link eventKey="Sensors">Sensors</Nav.Link></Nav.Item>
  );
  const sensorContent = !props.sensorContent ? null : (
    <TabPane eventKey="Sensors" className="flex-grow-1 w-100" mountOnEnter>
      {props.sensorContent}
    </TabPane>
  );

  return (
    <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
      <TabContainer
        defaultActiveKey="General Info"
        onSelect={(tab) => setActiveTab(tab)}
        activeKey={activeTab}
      >
        <div className="card-header p-0 pt-1">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="General Info">General Info</Nav.Link>
            </Nav.Item>
            {workOrdersAndMaintenanceRequestTabs}
            <Nav.Item>
              <Nav.Link eventKey="Audit Logs">Audit Logs</Nav.Link>
            </Nav.Item>
            {historyTab}
            {sensorTab}
          </Nav>
        </div>
        <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1 d-flex">
          <TabContent className="flex-grow-1 d-flex">
            <TabPane eventKey="General Info" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {props.generalInfoContents}
              </div>
            </TabPane>
            {workOrdersAndMaintenanceRequestContents}
            <TabPane eventKey="Audit Logs" className="flex-grow-1 w-100" mountOnEnter unmountOnExit>
              {props.auditLogContent}
            </TabPane>
            {historyContent}
            {sensorContent}
          </TabContent>
        </div>
      </TabContainer>
    </Card>
  );
}

export default AssetDetailsTabs;
