import { getAttachmentsBulk, getAttachment, createAttachmentRequest, deleteAttachment, getAssetAttachment, getEntityAttachments, getFileAttachmentDropdownValues, getEntityAttachmentsObj, getAttachmentObj, deleteAttachmentObj } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";


export const attachmentDataSource = {
  name: 'attachments',
  pk: 'attachmentId',
  additionalRequests: [
    {
      name: 'fetchAssetAttachment',
      request: getAssetAttachment
    },
    {
      name: 'fetchFileAttachmentDropdownValues',
      request: getFileAttachmentDropdownValues,
    },
    {
      name: 'createAttachment',
      request: createAttachmentRequest
    },
    {
      name: 'fetchEntityAttachments',
      request: getEntityAttachmentsObj,
      appendData: true
    },
    {
      name: 'deleteAttachmentAction',
      request: deleteAttachmentObj
    },
    {
      name: 'fetchAttachment',
      request: getAttachmentObj
    },
    {
      name: 'fetchAttachmentBulk',
      request: getAttachmentsBulk
    },
  ]
} as DataSource