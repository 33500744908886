import { FaMapMarkerAlt } from "react-icons/fa";
import { formatDate, formatFormValue } from "../../../services/format";
import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { rareGasEditDataSource, rareGasSensorsDataSource } from "./raregas-ds";
import { LocationMapWrapper } from "../../UI/organisms/Sensors/location-map-wrapper";
import { convertToPreferred } from "../../../services/user-preferences";
import { SensorChartWrapper } from "../../UI/organisms/Sensors/sensor-chart-wrapper";

export const RareGasSummaryTable = createConnectedListView({
  hideGlobalFilter: true,
  columns: [
    {
      Header: 'Location',
      accessor: 'location',
      disableSortBy: true,
      tooltip: (row) => `Value from: ${formatDate(row.ReadingDate)}`
    },
    {
      Header: 'Battery Voltage',
      accessor: 'batteryLevel',
      defaultSort: true,
      disableSortBy: true,
      tooltip: (row) => `Value from: ${formatDate(row.ReadingDate)}`
    },
    {
      Header: `Ambient Temperature (F)`,
      id: 'ambientTemperature',
      accessor: ({ ambientTemperature }) => {
        return ambientTemperature;
      },
      disableSortBy: true,
      tooltip: (row) => `Value from: ${formatDate(row.ReadingDate)}`
    },
    {
      Header: 'GPS',
      accessor: 'gps',
      disableSortBy: true,
      type: ListViewColumnType.link,
      linkPath: ({ value, row }) => `https://www.google.com/maps/search/?api=1&query=${row.original.Latitude},${row.original.Longitude}`,
      icon: FaMapMarkerAlt

    },
  ]

} as ListView, rareGasEditDataSource, state => {
  const { myLoc, myBattV, myTemp } = state.raregassensors?.current?.data || {};
  if (state.raregassensors?.current?.data)
    return [
      {
        location: myLoc?.Location,
        batteryLevel: myBattV?.ReadingValue,
        ambientTemperature: myTemp?.ReadingValue,
        Latitude: myLoc?.Latitude,
        Longitude: myLoc?.Longitude,
        ReadingDate: myLoc?.ReadingDate,
      }
    ]
  return [];
})


export const getDefaultDateRange = () => {
  const d = new Date();
  d.setMonth(d.getMonth() - 6);

  return {
    start: d,
    end: new Date()
  }
}

export const RareGasSensorTab = createConnectedView({
  form: {
    editMode: true,
    items: [
      {
        type: FormItemType.fieldset,
        isFluid: true,
        label: 'Latest Readings',
        items: [
          {
            type: FormItemType.customComponent,
            component: RareGasSummaryTable
          }
        ]
      },
      {
        type: FormItemType.row,
        items: [
          {
            type: FormItemType.daterange,
            name: 'dateRange',
            defaultValue: getDefaultDateRange(),
            style: { marginLeft: 0, marginTop: 0 },
          },

        ]
      },
      {
        type: FormItemType.row,
        items: [
          {
            type: FormItemType.fieldset,
            label: 'Device Sensors',
            allowGrow: true,
            items: [
              {
                type: FormItemType.customComponent,
                component: SensorChartWrapper
              }
            ]
          },
          {
            type: FormItemType.fieldset,
            label: 'Map',
            allowGrow: true,
            items: [
              {
                type: FormItemType.customComponent,
                componentArgs: {
                  assetType: 'RareGas'
                },
                component: LocationMapWrapper
              }
            ]
          },
        ]
      }
    ]
  } as FormView, dataSource: rareGasSensorsDataSource
});

export const rareGasEdit = {
  editMode: true,
  pageHeader: 'Rare Gas',
  breadcrumbs: [
    {
      name: 'Rare Gas',
      icon: 'currency-dollar',
      to: '/assets#Rare Gas Map',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              header: ({ raregas }) => raregas?.current?.data?.serialNumber,
              infoListItems: [
                {
                  label: 'Product',
                  name: 'product'
                },
                {
                  label: 'Serial Number',
                  name: 'serialNumber'
                },
                {
                  label: 'Seal Number',
                  name: 'sealNumber'
                },
                {
                  label: 'Manufactured Date',
                  name: 'manufacturedDate',
                  value: ({ manufacturedDate }) => formatDate(manufacturedDate)
                },
                {
                  label: 'Last Test Date',
                  name: 'lastTestDate',
                  value: ({ lastTestDate }) => formatDate(lastTestDate)
                },
                {
                  label: 'Retest Due Date',
                  name: 'retestDueDate',
                  value: ({ retestDueDate }) => formatDate(retestDueDate)
                },
                {
                  label: 'CSC Due Date',
                  name: 'cscdueDate',
                  value: ({ cscdueDate }) => formatDate(cscdueDate)
                },
                {
                  label: 'Tare Weight',
                  name: 'tareWeight',
                  value: ({ tareWeight }) => formatFormValue('number', tareWeight)
                },
                {
                  label: 'Water Volume',
                  name: 'waterVolume',
                  value: ({ waterVolume }) => formatFormValue('number', waterVolume)
                },
                {
                  label: 'Fill Pressue',
                  name: 'fillPressure',
                  value: ({ fillPressure }) => formatFormValue('number', fillPressure)
                },
              ]
            }
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: "Sensors",
              items: [
                {
                  type: FormItemType.customComponent,
                  component: RareGasSensorTab
                }
              ]
            }
          ]
        }
      ]
    }
  ]

} as FormView;



export const RareGasComponent = createConnectedView({ form: rareGasEdit, dataSource: rareGasEditDataSource })

