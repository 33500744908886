import { Form } from "react-router-dom";
import { FormItemType } from "../../UI/view/component-map";
import { Repository } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView, createConnectedView2 } from "../../UI/view/view";
import { DewarCustomerSelectList } from "../Customers/customer-dewar-list";
import { GasCustomerSelectList } from "../Customers/customer-gas-list";
import { LiquidCustomerSelectList } from "../Customers/customer-liquid-list";
import { invoiceContentsTable } from "./billing-invoice-details";
import { miscBillingScheduleDS } from "./billing-schedule-ds";
import { get } from "lodash";


export const scheduleOptions = [
  {
    id: '0 0 0 * * 0', //At 00:00 on Sunday
    text: 'Weekly'
  },
  {
    id: '0 0 0 1 * *',//At 00:00 on day-of-month 1
    text: 'Monthly'
  },
  {
    id: '0 0 0 1 */3 *',//At 00:00 on day-of-month 1 in every 3rd month
    text: 'Quarterly'
  },
  {
    id: '0 0 0 1 1 *',//At 00:00 on day-of-month 1 in January
    text: 'Annually'
  },
]

const getBillingTypeTab = (customerSelect) => {
  if(customerSelect){
    return [
      {
        type: FormItemType.boundingBox,
        height: 500,
        items: [
          {
            type: FormItemType.label,
            hideIf: (myThing) => myThing.watch('productType'),
            label: 'Please first pick a product type'
          },
          {
            type: FormItemType.customField,
            style: { height: '100%', paddingBottom: 20 },
            required: true,
            name: 'miscBillingHeader.dewarCustomerId',
            label: 'Packaged Product Customer',
            component: DewarCustomerSelectList,
            hideIf: (myThing) => {
              return myThing.watch('miscBillingHeader.productType') != 'Dewar'
            },
          },
          {
            type: FormItemType.customField,
            style: { height: '100%', paddingBottom: 20 },
            required: true,
            name: 'miscBillingHeader.liquidCustomerId',
            label: 'Liquid Customer',
            component: LiquidCustomerSelectList,
            hideIf: (myThing) => {
              return myThing.watch('miscBillingHeader.productType') != 'Liquid'
            },
          },
          {
            type: FormItemType.customField,
            style: { height: '100%', paddingBottom: 20 },
            required: true,
            name: 'miscBillingHeader.gasCustomerId',
            label: 'Gas Customer',
            component: GasCustomerSelectList,
            hideIf: (myThing) => {
              return myThing.watch('miscBillingHeader.productType') != 'Gas'
            },
          },
        ]
      },
    ]
  }

  return [

  ];
}

export const billingScheduleEdit = (customerSelect) => ({
  modalSize: "xl",
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Schedule Details',
          items: [
            {
              type: FormItemType.fieldset,
              label: 'Billing Schedule',
              items: [
                {
                  type: FormItemType.text,
                  name: 'name',
                  label: 'Name',
                  required: true,
                },
                {
                  type: FormItemType.row,
                  items: [
                    {
                      type: FormItemType.select,
                      defaultValue: '0 0 0 * * 0',
                      options: scheduleOptions,
                      name: 'schedule',
                      label: 'Schedule',
                      required: true,
                    },
                    {
                      type: FormItemType.date,
                      label: 'Start Date',
                      name: 'startDate',
                    },
                    {
                      defaultValue: 'MISC',
                      hideIf: () => true,
                      label: 'billingType',
                      name: 'billingType',
                    }
                  ]
                },
              ]
            },
          ]
        },
        {
          label: 'Invoice Type',
          items: [
            {
              type: FormItemType.fieldset,
              label: 'Details',
              items: [
                {
                  type: FormItemType.select,
                  required: true,
                  label: 'Product Type',
                  name: 'miscBillingHeader.productType',
                  defaultValue: null,
                  fitToContent: true,
                  width: 200,
                  options: [
                    {
                      id: null,
                      text: ''
                    },
                    {
                      id: 'Liquid',
                      text: 'Liquid'
                    },
                    {
                      id: 'Gas',
                      text: 'Gas'
                    },
                    {
                      id: 'Dewar',
                      text: 'Packaged Product'
                    },
                  ]
                },
                ...getBillingTypeTab(customerSelect),
                {
                  name: 'billingType',
                  defaultValue: 'MISC',
                  hideIf: () => true
                },
              ]
            }
          ]
        },
        {
          label: 'Invoice Contents',
          items: [
            {
              type: FormItemType.fieldset,
              label: 'Invoice Contents',
              items: [
                {
                  type: FormItemType.row,
                  items: [
                    {
                      label: 'SO Number',
                      name: 'miscBillingHeader.sonumber',
                      width: 140,
                      fitToContent: true,
                      defaultValue: ''
                    },
                    {
                      name: 'miscBillingHeader.ponumber',
                      label: 'PO Number',
                      minWidth: 200,
                      fitToContent: true,
                      defaultValue: ''
                    },
                    {
                      name: 'miscBillingHeader.holdDate',
                      label: 'Hold Date',
                      minWidth: 200,
                      fitToContent: true,
                      type: FormItemType.date
                    },
                    {
                      name: 'miscBillingHeader.billingType',
                      defaultValue: 'MISC',
                      hideIf: () => true,
                    },

                  ]
                },
                {
                  label: 'Invoice Notes',
                  name: 'miscBillingHeader.invoiceComments',
                  type: FormItemType.textarea,
                  width: 140,
                  fitToContent: true,
                  defaultValue: ''
                },
                {
                  type: FormItemType.label,
                  label: 'Invoice Contents'
                },
                {
                  ...invoiceContentsTable('miscBillingHeader.miscBillingDetails'),
                  name: 'miscBillingHeader.miscBillingDetails'
                }
              ]
            }
          ]
        },
      ]
    },


  ]
} as FormView);
