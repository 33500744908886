import { FormItemType } from "../../../UI/view/component-map";
import { FilterType, ListView, ListViewColumnType, createConnectedListView2 } from "../../../UI/view/list-view";
import { liquidMainDataSource } from "./liquid-billing-datasource";
import { CreateTransfer, CreateTransferDataSource } from "./liquid-create-transfer";


export enum LiquidStatus
{
  PendingManualUpload,
  PendingAutomaticUpload,
  PendingDelivery,
  CreditInvoices,
  UncreditedInvoices,
  RecentDelivery
}

export const liquidBillingInvoices = {
  filters: [
    {
      type: FilterType.multiselect,
      fieldName: 'liquidBillingStatus',
      filterValue: { [LiquidStatus.PendingManualUpload]: true, [LiquidStatus.PendingAutomaticUpload]: true, [LiquidStatus.PendingDelivery]: true, [LiquidStatus.CreditInvoices]: true, [LiquidStatus.UncreditedInvoices]: true, },
      options: () => [LiquidStatus.PendingManualUpload, LiquidStatus.PendingAutomaticUpload, LiquidStatus.PendingDelivery, LiquidStatus.CreditInvoices, LiquidStatus.UncreditedInvoices],
      labels: { [LiquidStatus.PendingManualUpload]: 'Pending Manual Upload', [LiquidStatus.PendingAutomaticUpload]: 'Pending Automatic Upload', [LiquidStatus.PendingDelivery]: 'Pending Delivery', [LiquidStatus.CreditInvoices]: 'Credit Invoices', [LiquidStatus.UncreditedInvoices]: 'Uncredited Invoices', },
    }
  ],
  additionalModals: [
    {
      form: CreateTransfer,
      repository: CreateTransferDataSource,
      name: 'createTransfer',
      title: 'Create Transfer',
    }
  ],
  toolbar: [
    {
      type: FormItemType.button,
      modal: 'createTransfer',
      label: 'Create Transfer',
    }
  ],
  columns: [
    {
      accessor: 'orderTypeName',
      Header: 'Order Type',
      defaultSort: true
    },
    {
      accessor: 'shippingPlant',
      Header: 'Plant'
    },
    {
      accessor: 'customerName',
      Header: 'Customer Name',
      type: ListViewColumnType.linkEdit,
      linkPath: ({row}) => `/billing/liquidedit/${row.original.billingId}`
    },
    {
      accessor: 'customerId',
      Header: 'PX Number'
    },
    {
      accessor: 'dateShipped',
      Header: 'Date Shipped',
      type: ListViewColumnType.datetime
    },
    {
      accessor: 'dateStarted',
      Header: 'Arrival Date',
      type: ListViewColumnType.datetime
    },
    {
      accessor: 'jdeVolume',
      Header: 'Volume'
    },
    {
      accessor: 'containerIdstarted',
      Header: 'Container Number'
    },
    {
      accessor: 'liquidCustomer.city',
      Header: 'City'
    },
    {
      accessor: 'liquidCustomer.state',
      Header: 'State'
    },
    {
      accessor: 'liquidCustomer.isNalscustomer',
      Header: 'NALS',
      type: ListViewColumnType.yesno
    },
    {
      accessor: 'liquidBillingStatus',
      Header: '',
      hidden: true
    },
    {
      accessor: 'billingId',
      Header: '',
      hidden: true
    },
  ]
} as ListView;


export const [LiquidBillingInvoices, LiquidBillingInvoiceDataSource] = createConnectedListView2(liquidBillingInvoices, liquidMainDataSource);