import { List } from "lodash";
import { formatCurrency, formatDate } from "../../../services/format";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { createConnectedView } from "../../UI/view/view";
import { billingPermissions, dewarDamagesDataSource } from "./billing-datasource";
import { sum } from "../../../services/array-helpers";

export const dewarDamagesList = {
  columns: [
    {
      accessor: 'customerName',
      Header: 'Customer Name',
      defaultSort: true,
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/billing/dewardamages/' + row.original.customerID
    },
    {
      accessor: 'dateRange',
      Cell: ({ row: { original: { maxTimestamp, minTimestamp } } }) => maxTimestamp == minTimestamp ? formatDate(maxTimestamp) : formatDate(minTimestamp) + " - " + formatDate(maxTimestamp),
      Header: 'Date Range of Damages'
    },
    {
      accessor: 'magtrackOrder',
      Header: 'MagTrack Order',
      type: ListViewColumnType.yesno
    },
    {
      accessor: 'totalAmount',
      Cell: ({ row: { original: { totalDamageRepair, totalPartRepair } } }) => formatCurrency(totalDamageRepair + totalPartRepair),
      Header: 'Total Amount',
    },
    {
      accessor: 'customerID',
      Header: 'customerID',
      hidden: true
    },
  ]
} as ListView;


export const DewarDamagesList = createConnectedListView(dewarDamagesList, dewarDamagesDataSource);


export const dewarDamagesItems = {
  hideGlobalFilter: true,
  autoCheckAll: true,
  columns: [
    {
      accessor: 'checked',
      type: ListViewColumnType.checkbox,

    },
    {
      Header: 'Serial Number',
      accessor: 'serialNumber'
    },
    {
      Header: 'Item',
      accessor: 'partNumber',
    },
    {
      Header: 'Description',
      accessor: 'description'
    },
    {
      Header: 'Comments',
      accessor: 'comments'
    },
    {
      Header: 'Entered By',
      accessor: 'userName'
    },
    {
      Header: 'Entered On',
      accessor: 'timestamp',
      defaultSort: true,
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Prev PO',
      accessor: 'orderNumber'
    },
    {
      Header: 'Prev RO',
      accessor: 'roNumber'
    },
    {
      Header: 'Part Price',
      accessor: 'partPrice',
      Cell: ({ row: { original: { partPrice } } }) => formatCurrency(partPrice)
    },
    {
      Header: 'Total (inc. Labor)',
      accessor: 'total',
      Cell: ({ row: { original: { total } } }) => formatCurrency(total)
    },
    {
      Header: 'PK',
      accessor: 'pk',
      hidden: true
    },
  ]
} as ListView;

export const DewarDamagesItemList = createConnectedListView(dewarDamagesItems, dewarDamagesDataSource, ({ dewarDamages }) => dewarDamages?.current?.data?.dewarDamageReports)

export const dewarDamageInvoice = {
  editMode: true,
  pageHeader: 'Dewar Damages Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Dewar Damages Billing',
      icon: 'currency-dollar',
      to: '/billing#Dewar%20Damages',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          type: FormItemType.infoPane,
          header: ({ dewarDamages }) => dewarDamages?.current?.data?.customerName,
          infoListItems: [
            {
              label: 'Customer Id',
              name: 'customerId'
            },
            {
              label: 'Customer',
              name: 'customerName'
            },
            {
              label: 'Invoice Date',
              name: 'invoiceDate',
              value: ({ invoiceDate }) => formatDate(invoiceDate)
            },
            {
              label: 'Total Entries',
              name: 'totalEntries',
              value: ({ dewarDamageReports }) => dewarDamageReports?.length
            },
            {
              label: 'Invoice Total',
              name: 'totalEntries',
              value: ({ dewarDamageReports }) => !dewarDamageReports ? null : formatCurrency(sum(dewarDamageReports, 'total'))
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Dewar Damages',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Miscellaneous Billing',
                  items: [
                    {
                      type: FormItemType.customField,
                      label: 'Invoice Contents',
                      name: 'dewarDamageReports',
                      component: DewarDamagesItemList
                    },
                    {
                      label: 'Invoice Notes',
                      name: 'invoiceComments',
                      type: FormItemType.textarea,
                    },
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.button,
                  label: 'Bypass Invoice',
                  variant: 'secondary',
                  handler: async ({ methods, handleSubmit, onSubmit, redirect}) => {
                    const { setValue } = methods;
                    setValue('bypassInvoice', true);
                    let isError = false;
                    let result = null;
                    try {
                      result = await handleSubmit(onSubmit)();
                    } catch {
                      isError = true;
                    }
                    if (isError) {
                      alert('There was an error bypassing this invoice.')
                    } else {
                      //redirect to created invoice. We need the invoice id!\
                      alert('Invoice Bypassed.')
                      redirect('/billing#Dewar%20Damages');
                    }
                  }
                },
                {
                  type: FormItemType.button,
                  label: 'Cancel',
                },
                {
                  type: FormItemType.submitButton,
                  label: 'Create Invoice',
                  variant: 'secondary',
                  onAfterSubmit: ({ redirect }) => {
                    alert('Invoice Successfully Created!')
                    redirect('/billing#Dewar%20Damages');
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
} as FormView;

export const DewarDamageInvoice = createConnectedView({ form: dewarDamageInvoice, dataSource: dewarDamagesDataSource });