import './layout.css';

import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import React, { useEffect, useState } from 'react';
import { Toaster } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';

import { selectIsAdmin, selectIsDev, selectLoggedInUserInfo } from '../../../store/slices/auth-slice';
import { selectRolesStatus } from '../../../store/slices/roles-slice';
import { selectUsersStatus } from '../../../store/slices/users-slice';
import LoginLoading from '../../pages/Login/login-loading';
import NotAuthorized from '../../pages/NotAuthorized/not-authorized';
import AuthLoading from '../../UI/molecules/AuthLoading/auth-loading';
import Footer from '../../UI/organisms/Footer/footer';
import RNNavbar from '../../UI/organisms/Navbar/navbar';
import SideBar from '../../UI/organisms/Sidebar/sidebar';
import ErrorBoundary from '../Errors/error-boundary';

function Layout(props) {
  const adminOnlyRoutes = ['/admin', '/audit-log'];

  // Currenyl no dev only routes.
  const devOnlyRoutes = [];

  const [sidebarOpen, setSidebarOpen] = useState(true);
  const location = useLocation();
  const toggleSidebar = () => {
    if (sidebarOpen) {
      document.body.className = document.body.className.replace(' sidebar-collapse', '');
    } else {
      document.body.className = document.body.className.concat(' sidebar-collapse');
    }
    setSidebarOpen(!sidebarOpen);
  };

  const userIdNotFound = useSelector((state) => state.auth.userProfile.userIdNotFound);
  const userId = useSelector((state) => state.auth.userProfile.userId);
  const userStatus = useSelector(selectUsersStatus);
  const rolesStatus = useSelector(selectRolesStatus);
  const userInfo = useSelector(selectLoggedInUserInfo);

  const isAdmin = useSelector(selectIsAdmin);

  const isDev = useSelector(selectIsDev);

  const [path, setPath] = useState(location.pathname);

  useEffect(
    () => setPath(location.pathname),
    [location.pathname],
  );

  const pageAccessRestricted = (!isAdmin && adminOnlyRoutes.some((route) => path.startsWith(route)))
  || (!isDev && devOnlyRoutes.some((route) => path.startsWith(route)));

  let pageContent;
  if (userStatus === 'failed' || (userStatus === 'succeeded' && rolesStatus === 'succeeded' && pageAccessRestricted)) {
    pageContent = <NotAuthorized />;
  } else if ((userStatus === 'succeeded' || userId)) {
    if (userIdNotFound) {
      pageContent = <NotAuthorized />;
    } else {
      pageContent = (
        <>
          <SideBar open={sidebarOpen} />
          <RNNavbar toggleClick={toggleSidebar} />
          <div className="content-wrapper d-flex">
            {/* <main className='content-outlet'> */}
            {/* <Container fluid> */}
            <section className="content text-sm page-content-section container-fluid flex-grow-1">
              {/* <Container fluid> */}
              <ErrorBoundary key={path}>
                <Outlet />
              </ErrorBoundary>
              {/* </Container> */}
            </section>
            {/* </Container> */}
            {/* </main> */}
          </div>
          <Footer />
          <Toaster
            position="top-right"
          />
        </>
      );
    }
  } else {
    pageContent = <AuthLoading />;
  }

  return (
    <>
      <AuthenticatedTemplate>
        {pageContent}
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <LoginLoading />
      </UnauthenticatedTemplate>
    </>
  );
}

export default Layout;
