import React, { useEffect, useMemo } from 'react';
import {
  Button, Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { selectLoggedInUserActiveLocation, selectLoggedInUserLocations } from '../../../store/slices/auth-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import { createPart } from '../../../store/slices/parts-slice';
import { Typeahead } from '../../UI/molecules/Typeahead';

function CreatePartForm({ onHide }) {
  const defaultPartFormValues = {
    locationId: null,
    binNumber: null,
    partName: null,
    quantityOnHand: 0,
    minQuantity: null,
    maxQuantity: null,
    reorderLevel: null,
    partDescription: null,
    isNonStockItem: false,
    isActive: true,
    partCodeGlobal: null,
    partCodeLocal: null,
    assetTypeId: null,
  };

  const {
    register, handleSubmit, formState: { errors }, setValue, reset, control, getValues,
  } = useForm({
    defaultValues: defaultPartFormValues,
  });

  const unsortedLocations = useSelector(selectLoggedInUserLocations);
  const { assetTypes } = useSelector(selectMaintenanceRequestDropdownValues);

  const locations = unsortedLocations
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim()));

  const locationsList = useMemo(() => {
    return Object.values(locations ?? {});
  }, [locations]);

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const initialWorkOrderLocation = currentUserActiveLocation?.LocationName;

  useEffect(() => {
    const initialLocationId = locationsList?.find(
      (l) => l?.LocationName === initialWorkOrderLocation,
    )?.LocationID;
    setValue('locationId', initialLocationId);
  }, [initialWorkOrderLocation, locationsList]);

  const dispatch = useDispatch();

  const onSubmit = (partData) => {
    dispatch(createPart({ ...partData }));
  };

  return (
    <>
      <Modal.Body>
        <Form className="bg-white h-100">
          <Container fluid className="p-0 m-0">
            <Form.Group className="mb-1">
              <Form.Label>Part Name</Form.Label>
              <Form.Control size="sm" {...register('partName', { required: 'Please enter a part name.' })} isInvalid={!!errors.partName} />
              <Form.Control.Feedback type="invalid">
                {errors.partName && errors.partName.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-1">
              <Form.Label>Asset Type</Form.Label>
              <Form.Select size="sm" {...register('assetTypeId', { required: 'Please select an asset type.' })} isInvalid={!!errors.assetTypeId}>
                <option value={null} key="customer-null" />
                <option value={2} key="assettypevals-1">Liquid</option>
                <option value={1} key="assettypevals-2">Gas</option>
                <option value={4} key="assettypevals-3">Chassis</option>
              </Form.Select>
              <Form.Control.Feedback type="invalid">
                {errors.assetTypeId && errors.assetTypeId.message}
              </Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Global Part Code</Form.Label>
                  <Form.Control size="sm" {...register('partCodeGlobal', { required: 'Please enter a global part code.' })} isInvalid={!!errors.partCodeGlobal} />
                  <Form.Control.Feedback type="invalid">
                    {errors.partCodeGlobal && errors.partCodeGlobal.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Local Part Code</Form.Label>
                  <Form.Control size="sm" {...register('partCodeLocal')} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Location</Form.Label>
                  <Controller
                    control={control}
                    name="locationId"
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        dropup
                        positionFixed
                        value={value}
                        onChange={(e) => {
                          onChange(locations?.find(
                            (l) => l?.LocationName === e[0],
                          )?.LocationID);
                        }}
                        size="sm"
                        id="locationId"
                        options={locations.map((l) => l.LocationName)}
                        defaultSelected={initialWorkOrderLocation ? [initialWorkOrderLocation] : []}
                        isValid={!!value}
                        isInvalid={!!error}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              {/* <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Quantity on Hand</Form.Label>
                  <Form.Control size="sm" {...register('quantityOnHand',
                   { required: 'Please enter a quantity on hand.' })}
                    isInvalid={!!errors.quantityOnHand} />
                  <Form.Control.Feedback type="invalid">
                    {errors.quantityOnHand && errors.quantityOnHand.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Min Quantity</Form.Label>
                  <Form.Control
                    size="sm"
                    type="number"
                    {...register(
                      'minQuantity',
                      {
                        required: 'Please enter a min quantity.',
                        min: { value: 0, message: 'Min quantity must be non-negative' },
                        validate: {
                          lessThanMaxQuantity: (val) => {
                            if (val !== null && getValues().maxQuantity !== null) {
                              return parseInt(val, 10) <= parseInt(getValues().maxQuantity, 10) || 'Min quantity must be less than max quantity';
                            }
                            return null;
                          },
                        },
                      },
                    )}
                    isInvalid={!!errors.minQuantity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.minQuantity && errors.minQuantity.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Max Quantity</Form.Label>
                  <Form.Control
                    size="sm"
                    type="number"
                    {...register(
                      'maxQuantity',
                      {
                        required: 'Please enter a max quantity.',
                        min: { value: 0, message: 'Max quantity must be non-negative' },
                        validate: {
                          greaterThanMinQuantity: (val) => {
                            if (val !== null && getValues().minQuantity !== null) {
                              return parseInt(val, 10) >= parseInt(getValues().minQuantity, 10) || 'Max quantity must be greater than min quantity';
                            }
                            return null;
                          },
                        },
                      },
                    )}
                    isInvalid={!!errors.maxQuantity}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.maxQuantity && errors.maxQuantity.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Reorder Level</Form.Label>
                  <Form.Control size="sm" {...register('reorderLevel', { required: 'Please enter a reorder level.' })} isInvalid={!!errors.reorderLevel} />
                  <Form.Control.Feedback type="invalid">
                    {errors.reorderLevel && errors.reorderLevel.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Quantity on Hand</Form.Label>
                  <Form.Control disabled size="sm" {...register('quantityOnHand')} isInvalid={!!errors.quantityOnHand} />
                  <Form.Control.Feedback type="invalid">
                    {errors.quantityOnHand && errors.quantityOnHand.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Bin Number</Form.Label>
                  <Form.Control size="sm" {...register('binNumber')} isInvalid={!!errors.binNumber} />
                  <Form.Control.Feedback type="invalid">
                    {errors.binNumber && errors.binNumber.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-1">
              <Form.Label>       </Form.Label>
              <Form.Check
                {...register('isNonStockItem')}
                type="switch"
                inline
                id="isNonStockItem-switch"
                label="Non-Stock Item"
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Part Description</Form.Label>
              <Form.Control
                size="sm"
                as="textarea"
                rows={3}
                {...register('partDescription')}
                isInvalid={!!errors.partDescription}
                style={{ resize: 'none' }}
              />
              <Form.Control.Feedback type="invalid">
                {errors.partDescription && errors.partDescription.message}
              </Form.Control.Feedback>
            </Form.Group>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          onClick={onHide}
          variant="secondary"
        >
          Close

        </Button>
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
          onClick={handleSubmit(onSubmit)}
        >
          Submit

        </Button>
      </Modal.Footer>
    </>
  );
}

export default CreatePartForm;
