import React from 'react';
import {
  Card, Container, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';

import RequestsListTable from './requests-list-table';

function RequestsTabs(props) {
  return (
    <Container
      fluid
      className="h-100 m-0 p-0 d-flex flex-column m-0"
    >
      {props.header}
      <Card className="card-tabs card-primary flex-grow-1 overflow-auto m-0">
        <TabContainer defaultActiveKey="Liquid Container">
          <div className="card-header p-0 pt-1">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="Liquid Container">Liquid</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Gas Container">Gas</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Chassis">Chassis</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
            <TabContent className="h-100">
              <TabPane aria-labelledby="" eventKey="Liquid Container" className="h-100">
                <RequestsListTable assetType="LiquidContainer" planned={props.planned} />
              </TabPane>
              <TabPane eventKey="Gas Container" className="h-100">
                <RequestsListTable assetType="GasContainer" planned={props.planned} />
              </TabPane>
              <TabPane eventKey="Chassis" className="h-100">
                <RequestsListTable assetType="Chassis" planned={props.planned} />
              </TabPane>
            </TabContent>
          </div>
        </TabContainer>
      </Card>
    </Container>
  );
}

export default RequestsTabs;
