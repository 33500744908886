import React, { useEffect } from 'react';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { setEntityAttachmentsFetchStatus } from '../../../store/slices/file-attachment-slice';
import { selectCurrentPurchaseRequest, setUpdatePurchaseRequestStatus, updatePurchaseRequest } from '../../../store/slices/purchase-request-slice';
import CustomModal from '../../UI/molecules/Modal/modal';
import FileAttachments from '../../UI/organisms/FileAttachment/file-attachment';

function PurchaseRequestAttachments(props) {
  const defaultRequestFormValues = {
    purchaseRequestId: null,
    finalCost: null,
    partId: null,
    created: null,
    createdBy: null,
    comment: null,
    purchaseOrderId: null,
  };

  const dispatch = useDispatch();

  const purchaseRequestUpdateStatus = useSelector(
    (state) => state.purchaseRequests.purchaseRequestsUpdate.status,
  );
  const purchaseRequestUpdateError = useSelector(
    (state) => state.purchaseRequests.purchaseRequestsUpdate.error,
  );

  const purchaseRequestUpdateSuccessNotify = () => toast.success('Successfully updated Purchase Request');
  const purchaseRequestUpdateFailedNotify = (error) => toast.error(`Failed to update Purchase Request\n${error}`);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultRequestFormValues,
  });

  const onClose = () => {
    dispatch(setEntityAttachmentsFetchStatus('idle'));
    reset();
    props.onHide();
  };

  useEffect(() => {
    if (purchaseRequestUpdateStatus === 'succeeded' && props.show) {
      purchaseRequestUpdateSuccessNotify();
      dispatch(setUpdatePurchaseRequestStatus('idle'));
      onClose();
    }
    if (purchaseRequestUpdateStatus === 'failed' && props.show) {
      purchaseRequestUpdateFailedNotify(purchaseRequestUpdateError);
      dispatch(setUpdatePurchaseRequestStatus('idle'));
      onClose();
    }
  }, [purchaseRequestUpdateStatus, props.show]);

  const maintenanceRequestStatusIsLoading = [
    purchaseRequestUpdateStatus,
  ].includes('loading');

  const currentPurchaseRequest = useSelector(selectCurrentPurchaseRequest);

  const onApprove = (purchaseRequest) => {
    dispatch(updatePurchaseRequest({
      ...purchaseRequest,
    }));
  };

  const onReject = (maintenanceRequest) => {
    dispatch(updatePurchaseRequest(
      { ...maintenanceRequest, maintenanceRequestStatusId: 3 },
    ));
  };

  const onUpdate = (maintenanceRequest) => {
    dispatch(updatePurchaseRequest({ ...maintenanceRequest }));
  };

  useEffect(() => {
    if (currentPurchaseRequest) {
      Object.keys(defaultRequestFormValues).forEach((key) => {
        if ((
          key.toLowerCase().includes('date')
          || key === 'created'
          || key === ['modified']
        )
          && currentPurchaseRequest[key]) {
          const dateObj = new Date(currentPurchaseRequest[key]);
          if (Number.isNaN(dateObj.getTime())) {
            setValue(key, null);
          } else {
            setValue(key, dateObj);
          }
        } else {
          setValue(key, currentPurchaseRequest[key]);
        }
      });
    }
  }, [currentPurchaseRequest, props.show]);

  return (
    <CustomModal
      show={props.show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Purchase Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0 m-0">
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Request ID</Form.Label>
                <Form.Control disabled size="sm" value={currentPurchaseRequest?.purchaseRequestId ?? ''} />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <FileAttachments
          entityName="Purchase Request"
          entityPK={currentPurchaseRequest.purchaseRequestId}
        />
      </Modal.Body>

    </CustomModal>
  );
}

export default PurchaseRequestAttachments;
