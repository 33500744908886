import './profile.scss';

import React, { useEffect, useMemo, useRef } from 'react';
import {
  Accordion,
  Button, Card, Col, Container, Form, Row, useAccordionButton,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
// import { FaMinus } from 'react-icons/fa';
import { BsDashLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { datePreferenceOptions } from '../../../services/format';
import { formatNumber } from '../../../services/user-preferences';
import { selectCurrentUserId, selectLoggedInUserInfo, selectLoggedInUserLocations } from '../../../store/slices/auth-slice';
import {
  getCurrentUser,
  selectUsers,
  selectUsersStatus, selectUserUpdateStatus, setUserUpdateStatus, updateUser,
} from '../../../store/slices/users-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import Table from '../../UI/organisms/Table/table';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => ({}));

  return (
    <button
      type="button"
      className="btn-tool p-0 m-0 border-0 plus-minus-button"
      onClick={decoratedOnClick}
    >
      <span><BsDashLg /></span>
    </button>
  );
}

function Profile(props) {
  const [doctitle, setDocTitle] = usePageTitle('Profile');

  const userProfileDefaultValues = {
    activeDirectoryDisplayName: null,
    activeDirectoryId: null,
    activeDirectoryMail: null,
    activeDirectoryPrincipalName: null,
    datePreference: null,
    displayName: null,
    firstName: null,
    isActive: true,
    lastName: null,
    legacyId: null,
    measurementPreference: null,
    locationPreference: null,
    numberPreference: null,
  };

  const breadcrumbs = useMemo(() => [
    {
      name: 'Profile',
      icon: 'person',
      to: '/profile',

    },
  ], []);

  const roleColumns = useMemo(() => (
    [
      {
        Header: 'Role Name',
        accessor: 'name',
        Cell: ({ value }) => (<p className="font-italic m-0">{value}</p>),
      },
      {
        Header: 'Description',
        accessor: 'description',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ]
  ), []);

  const dispatch = useDispatch();

  const usersUpdateStatus = useSelector(selectUserUpdateStatus);
  const usersFetchStatus = useSelector(selectUsersStatus);
  const currentUsers = useSelector(selectUsers);

  const updateUserSucessNotify = () => toast.success('Updated User Settings');

  const {
    register, handleSubmit, setValue, reset, formState,
  } = useForm();

  useEffect(() => {
    if (usersUpdateStatus === 'succeeded' && formState.isDirty) {
      updateUserSucessNotify();
      dispatch(setUserUpdateStatus('idle'));
      reset({ keepValues: true });
    }
  }, [usersUpdateStatus, formState.isDirty]);

  const currentUserId = useSelector(selectCurrentUserId);

  const loggedInUserLocations = useSelector(selectLoggedInUserLocations);

  const userInfo = useSelector(selectLoggedInUserInfo);

  const roles = useMemo(
    () => userInfo.roles ?? [],
    [userInfo.roles],
  );

  const onSubmit = (user) => {
    dispatch(updateUser({ userId: userInfo.userId, user }));
  };

  useEffect(() => {
    if (userInfo) {
      Object.keys(userProfileDefaultValues).forEach((key) => {
        setValue(key, userInfo[key]);
      });
    }
  }, [userInfo]);

  useEffect(() => {
    dispatch(setUserUpdateStatus('idle'));
    dispatch(getCurrentUser);
  }, []);

  const userStatusIsLoading = usersUpdateStatus === 'loading' || usersFetchStatus === 'loading';
  const roleTableInstance = useRef(null);

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column">
      <PageHeader header="My Settings" breadcrumbs={breadcrumbs} />
      {/* <RolesTable /> */}
      {/* <Roles />
            <Permissions /> */}
      {/* <div className="d-flex h-100"> */}
      <Row className="flex-grow-1 pb-2">
        <Col sm="6">
          {userStatusIsLoading && <LoadingContent />}
          <Card className={`h-100 overflow-auto card-primary card-outline ${userStatusIsLoading ? 'creation-loading' : ''}`}>
            <Card.Header>
              <h3 className="card-title">Settings</h3>
            </Card.Header>
            <Card.Body>
              <Form.Group>
                <p className="help-block">Change your user settings and click save.</p>
                <Row>
                  <Col sm="6">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control size="sm" placeholder="name" {...register('firstName')} />
                  </Col>
                  <Col sm="6">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control size="sm" placeholder="name" {...register('lastName')} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm="6">
                    <Form.Label>Display Name</Form.Label>
                    <Form.Control size="sm" placeholder="name" {...register('displayName')} />
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col sm="4">
                    <Form.Label>System of Measurement</Form.Label>
                    <Form.Select size="sm" {...register('measurementPreference')}>
                      <option value={null} key={-1} />
                      <option value={0} key={0}>Imperial</option>
                      <option value={1} key={1}>Metric</option>
                    </Form.Select>
                  </Col>
                  <Col sm="4">
                    <Form.Label>Date Format</Form.Label>
                    <Form.Select size="sm" {...register('datePreference')}>
                      <option value={null} key={-1} />
                      <option value={0} key={0}>{datePreferenceOptions[0]}</option>
                      <option value={1} key={1}>{datePreferenceOptions[1]}</option>
                      <option value={2} key={2}>{datePreferenceOptions[2]}</option>
                    </Form.Select>
                  </Col>
                  <Col sm="4">
                    <Form.Label>Number Format</Form.Label>
                    <Form.Select size="sm" {...register('numberPreference')}>
                      <option value={null} key={-1} />
                      <option value={0} key={0}>{formatNumber(0, 123456.789)}</option>
                      <option value={1} key={1}>{formatNumber(1, 123456.789)}</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="mt-3">
                  <Col>
                    <Form.Label>Default Location</Form.Label>
                    <Form.Select size="sm" {...register('locationPreference')}>
                      <option value={null} key={-1} />
                      {loggedInUserLocations.map((location, index) => (
                        <option
                          value={location.LocationID}
                          key={`location-${location.LocationID}`}
                        >
                          {location.LocationName}
                        </option>
                      ))}
                    </Form.Select>
                  </Col>

                </Row>
              </Form.Group>
            </Card.Body>
            <Card.Footer>
              <Button
                disabled={!formState.isDirty}
                onClick={handleSubmit(onSubmit)}
              >
                Update
              </Button>

            </Card.Footer>
          </Card>
        </Col>
        <Col>
          <Accordion defaultActiveKey="0" className="h-50 overflow-auto roles-accordion">
            <Card className={`overflow-auto card-primary card-outline ${userStatusIsLoading ? 'creation-loading' : ''}`}>
              <Card.Header>
                <h3 className="card-title">My Roles</h3>
                <div className="card-tools h-0">
                  <CustomToggle eventKey="0">Click me!</CustomToggle>
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0">
                <Table
                  columns={roleColumns}
                  data={roles}
                  ref={roleTableInstance}
                />
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      {/* </div> */}
    </Container>
  );
}

export default Profile;
