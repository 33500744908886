
import { getRecentLiquidDeliveries } from "../../../../services/requests/billing/liquid-billing-requests";
import { DataSource, Repository } from "../../../UI/view/data-source";
import { ListView, createConnectedListView, createConnectedListView2, createListView } from "../../../UI/view/list-view";
import { LiquidBillingInvoiceDataSource } from "./liquid-invoice-list";

export const recentDeliveriesDataSource = {
  name: 'recentLiquidDeliveries',
  pk: 'billingId',
  request: getRecentLiquidDeliveries,
  requestArgs: (state) => {
    return state.liquidbilling.current?.data?.customerId;
  }
} as DataSource;

export const recentDeliveries = {
  columns: [
    {
      Header: 'Date Shipped',
      accessor: 'dateShipped',
      defaultSort: true
    },
    {
      Header: 'Volume Delivered',
      accessor: 'jdeVolume'
    },
  ]
} as ListView;

export const RecentDeliveriesList = createConnectedListView(recentDeliveries, recentDeliveriesDataSource);