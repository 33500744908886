import React, { useEffect } from "react";
import { Form } from "react-bootstrap"
import { Controller } from "react-hook-form";

export const YesNo = ({ format, setValue, watch, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, errors, formState, editTableProps }) => {
  return (
    <Form.Group className="mb-1" title={field.tooltip}>
      {field.label && <Form.Label>{field.label}</Form.Label>}
      <Controller
        control={control}
        name={field.name}
        defaultValue={null}
        render={({
          field: {
            onChange, value,
          },
        }) => {
          return (
            <Form.Check
              aria-label={field.label}
              style={{ position: 'relative !important' } as any}
              className="checkbox-field-custom"
              type="switch"
              label={""}
              onChange={() => {
                onChange(value == 'Yes' ? 'No' : 'Yes');
              }}
              checked={value == 'Yes'}
            />
          );
        }}
      />

    </Form.Group>
  )
}