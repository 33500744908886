import React, { useEffect, useState } from 'react';
import {
  Card, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

function PartDetailsTab(props) {
  const [activeTab, setActiveTab] = useState('General Info');
  const disabled = !!props.generalInfoContents.props.currentPackagedProduct;

  const location = useLocation();

  useEffect(() => {
    if (location.hash.toLowerCase().includes('request')) {
      setActiveTab('Request History');
    }
  }, [location.hash]);
  // function added to remove the work order and maintenance requests tabs
  // if it's a packaged product
  const workOrdersAndMaintenanceRequests = () => {
    if (disabled) {
      return null;
    }
    return (
      <Nav.Item><Nav.Link eventKey="Request History">Request History</Nav.Link></Nav.Item>
    );
  };

  return (
    <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
      <TabContainer defaultActiveKey="General Info" onSelect={(tab) => setActiveTab(tab)} activeKey={activeTab}>
        <div className="card-header p-0 pt-1">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="General Info">General Info</Nav.Link>
            </Nav.Item>
            {workOrdersAndMaintenanceRequests()}
            <Nav.Item>
              <Nav.Link eventKey="Audit Logs">Audit Logs</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1 d-flex">
          <TabContent className="flex-grow-1 d-flex">
            <TabPane eventKey="General Info" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {props.generalInfoContents}
              </div>
            </TabPane>
            <TabPane eventKey="Work Orders" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {props.workOrderContent}
              </div>
            </TabPane>
            <TabPane eventKey="Request History" className="flex-grow-1 w-100">
              {props.purchaseRequestContent}
            </TabPane>
            <TabPane eventKey="Audit Logs" className="flex-grow-1 w-100" mountOnEnter unmountOnExit>
              {props.auditLogContent}
            </TabPane>
          </TabContent>
        </div>
      </TabContainer>
    </Card>
  );
}

export default PartDetailsTab;
