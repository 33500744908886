import React, { useEffect } from 'react';
import {
  Button,
  Container, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { formatAssetType } from '../../../services/format';
import {
  createMaintenanceRequestType,
  selectCurrentMaintenanceRequestTypeData, selectCurrentMaintenanceRequestTypeId,
  selectMaintenanceRequestDropdownValues,
  setCreateMaintenanceRequestTypeStatus,
  setCurrentMaintenanceRequestTypeId,
  setUpdateMaintenanceRequestTypeStatus,
  updateMaintenanceRequestType,
} from '../../../store/slices/maintenance-requests-slice';

function RequestTypeModal(props) {
  const defaultRequestTypeModalValues = {
    maintenanceRequestTypeId: '',
    name: null,
    isPlanned: false,
    assetTypeId: null,
    isActive: true,
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultRequestTypeModalValues,
  });

  const currentMaintenanceRequestTypeId = useSelector(selectCurrentMaintenanceRequestTypeId);
  const currentMaintenanceRequestType = useSelector(selectCurrentMaintenanceRequestTypeData);
  // Populate form with default values
  useEffect(() => {
    if (currentMaintenanceRequestType !== null) {
      setValue('maintenanceRequestTypeId', currentMaintenanceRequestType.maintenanceRequestTypeId);
      setValue('name', currentMaintenanceRequestType.name);
      setValue('assetTypeId', currentMaintenanceRequestType.assetTypeId);
    }
  }, [currentMaintenanceRequestType]);

  const { assetTypes } = useSelector(selectMaintenanceRequestDropdownValues);

  const dispatch = useDispatch();

  const onSubmit = (maintenanceRequest) => {
    dispatch(createMaintenanceRequestType({ ...maintenanceRequest }));
  };

  const onUpdate = (maintenanceRequest) => {
    dispatch(updateMaintenanceRequestType({ ...maintenanceRequest }));
  };

  const maintRequestUpdateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypeUpdate.status,
  );

  const maintRequestCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypeCreate.status,
  );

  const maintRequestDeleteStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypeRemove.status,
  );

  const maintRequestTypeUpdateNotify = () => toast.success('Updated Maintenance Request Type');
  const maintRequestTypeCreateNotify = () => toast.success('Created Maintenance Request Type');
  const maintRequestTypeUpdateFailedNotify = () => toast.error('Failed updating Maintenance Request Type');
  const maintRequestTypeCreateFailedNotify = () => toast.error('Failed creating Maintenance Request Type');

  useEffect(() => {
    if (maintRequestUpdateStatus === 'succeeded') {
      maintRequestTypeUpdateNotify();
      dispatch(setUpdateMaintenanceRequestTypeStatus('idle'));
      props.handleModalClose();
    }
    if (maintRequestCreateStatus === 'succeeded') {
      maintRequestTypeCreateNotify();
      dispatch(setCreateMaintenanceRequestTypeStatus('idle'));
      props.handleModalClose();
    }
    if (maintRequestUpdateStatus === 'failed') {
      maintRequestTypeUpdateFailedNotify();
      dispatch(setUpdateMaintenanceRequestTypeStatus('idle'));
    }
    if (maintRequestCreateStatus === 'failed') {
      maintRequestTypeCreateFailedNotify();
      dispatch(setCreateMaintenanceRequestTypeStatus('idle'));
    }
  }, [
    maintRequestUpdateStatus, maintRequestCreateStatus,
    maintRequestDeleteStatus,
  ]);

  const requestTypeStatusIsLoading = [maintRequestUpdateStatus,
    maintRequestCreateStatus,
    maintRequestDeleteStatus].includes('loading');

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {requestTypeStatusIsLoading ? <Spinner animation="border" variant="primary" className="create-spinner" /> : ''}
        <Form className="bg-white h-100">
          <Container fluid className={`p-0 m-0${requestTypeStatusIsLoading ? ' creation-loading' : ''}`}>
            <Row>
              <Form.Group className="mb-1">
                <Form.Label>Name</Form.Label>
                <Form.Control disabled={props.planned} size="sm" {...register('name', { required: 'Please enter a name.' })} isInvalid={!!errors.name} />
              </Form.Group>
            </Row>
            <Row>
              <Form.Group className="mb-1">
                <Form.Label>Asset Type</Form.Label>
                <Form.Select
                  size="sm"
                  {...register('assetTypeId', { required: true })}
                  disabled={props.planned}
                >
                  {assetTypes.filter((type) => type.name !== 'PackagedProduct').map((type, i) => (
                    <option value={type.id} key={`asset-type-${i}`}>{formatAssetType(type.name)}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={() => { dispatch(setCurrentMaintenanceRequestTypeId(null)); props.handleModalClose(); }} variant="secondary">Close</Button>
        {props.createMode ? <Button type="submit" className="mt-3 position-sticky float-end bottom-0" onClick={handleSubmit(onSubmit)}>Submit</Button>
          : <Button type="update" disabled={props.planned} className="mt-3 position-sticky float-end bottom-0" onClick={handleSubmit(onUpdate)}>Update</Button>}
      </Modal.Footer>
    </>
  );
}

export default RequestTypeModal;
