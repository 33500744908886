import React, {
  useCallback,
  useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Card, Collapse, Form, InputGroup,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import { filterDatesByRange, getDateRangeFilterValue, getDisplayNameByOID } from '../../../services/table-helpers';
import { selectAdminAuditLogs, selectAdminLogsFetchStatus } from '../../../store/slices/audit-slice';
import { selectUsers, selectUsersByOid } from '../../../store/slices/users-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ChangesPopover from '../../UI/molecules/ChangesPopover/changes-popover';
import StyledDatePicker from '../../UI/molecules/DateInput/styled-date-picker';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import {
  actions, entities, links,
} from './log-table-values';

const hiddenColumns = [
  'userName',
];

function AuditLogTable(props) {
  const auditLogs = useSelector(selectAdminAuditLogs);
  const auditLogsfetchStatus = useSelector(selectAdminLogsFetchStatus);
  const usersByOid = useSelector(selectUsersByOid);
  const users = Object.values(useSelector(selectUsers));
  const [filterOpen, setFilterOpen] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const tableInstance = useRef(null);

  const getDisplayName = useCallback((OID) => {
    return getDisplayNameByOID(OID, usersByOid);
  }, [usersByOid]);
  // Update to autit log needs
  const columns = useMemo(() => [
    {
      Header: 'User',
      id: 'auditUser',
      filter: 'exactText',
      accessor: ({ auditUser }) => (getDisplayName(auditUser)),

    },
    {
      Header: 'Action',
      id: 'auditAction',
      accessor: ({ auditAction, tableName }) => (`${actions[auditAction]} ${entities[tableName]}`),
    },
    {
      Header: 'Entity Type',
      accessor: 'tableName',
    },
    {
      Header: 'Entity Name',
      accessor: 'tablePK',
      Cell: ({ value, row }) => (
        row.original.tableName in links
          ? <NavLink to={`${links[row.original.tableName]}${value}`}>{value}</NavLink>
          : value
      ),
    },
    {
      Header: 'Date',
      accessor: 'auditDate',
      filter: filterDatesByRange,
      Cell: ({ value }) => <ReactTimeAgo date={Date.parse(`${value}Z`)} locale="en-US" />,
    },
    {
      Header: 'Changes',
      id: 'changes',
      Cell: ({ value, row }) => (
        <ChangesPopover row={row} />
      ),
    },
    // Hidden Columns
    { // this column is created so that the global filter can search by name the display name value.
      Header: 'user name',
      id: 'userName',
      accessor: (row) => usersByOid[row.auditUser]?.displayName || null,
    },
  ], [auditLogs]);

  const auditLogData = useMemo(() => {
    if (auditLogs) {
      const activity = Object.values(auditLogs).sort(
        (a, b) => Date.parse(b.auditDate) - Date.parse(a.auditDate),
      );
      return activity;
    }
    return [];
  }, [auditLogs]);

  const handleRangeDateFilterChange = (timeObj, dateChangeId) => {
    const filterValue = getDateRangeFilterValue(
      timeObj,
      dateChangeId,
      setStartDateFilter,
      setEndDateFilter,
      startDateFilter,
      endDateFilter,
    );

    tableInstance.current.setFilter('auditDate', filterValue);
  };

  const requestsAreLoading = [
    auditLogsfetchStatus,
  ].includes('loading');

  return (
    <Card className="card-primary card-outline flex-grow-1 overflow-auto">
      <Card.Header>
        <h3 className="card-title">Recent Activity</h3>
      </Card.Header>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={tableInstance} />
              &nbsp;
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select
                  size="sm"
                  onChange={(e) => { tableInstance.current.setFilter('auditUser', e.target.value); }}
                >
                  <option value="" key="auditUser-null">-- User --</option>
                  {users.map((user, index) => (
                    <option key={`auditUser-${index}`}>{user.displayName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'startDate'); }}
                  value={startDateFilter}
                />
                <Form.Text className="text-muted">Start Date</Form.Text>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'endDate'); }}
                  value={endDateFilter}
                />
                <Form.Text className="text-muted">End Date</Form.Text>
              </Form.Group>
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={columns}
        data={auditLogData}
        ref={tableInstance}
        initialState={{ hiddenColumns }}
        rowProps={() => ({})}
      />
      {requestsAreLoading && <LoadingContent />}
    </Card>
  );
}

export default AuditLogTable;
