import { FormItemType } from "../../UI/view/component-map";
import { Repository } from "../../UI/view/data-source";
import { ListViewColumnType, createListView } from "../../UI/view/list-view";
import { generateNumbers } from "./rtu-chart";
import { rtuDetailsDataSource } from "./rtu-data-source";
import { createConnectedView2 } from "../../UI/view/view";
import { FormView } from "../../UI/view/form-view";

export const readingsLIst = {
  enableExport: true,
  preventFetch: true,
  noGlobalFilterPadding: true,
  columns: [
    {
      Header: 'Last Reading Date',
      defaultSort: true,
      accessor: 'lastReadingShortDate',
      type: ListViewColumnType.date,
    },
    {
      Header: 'PX Number',
      accessor: 'praxairNumber',

    },
    {
      Header: 'Serial #',
      accessor: 'rtuSerialNumber',

    },
    {
      Header: 'Channel',
      accessor: 'channelId',

    },
    {
      Header: 'Reading Value',
      accessor: 'readingValue',

    },
    {
      Header: 'Full Point',
      accessor: 'fullPoint',

    },
    {
      Header: 'Order Point',
      accessor: 'orderPoint',

    },
    {
      Header: 'Reserve Point',
      accessor: 'reservePoint',

    },
    {
      Header: 'Outage Point',
      accessor: 'outagePoint',

    },
    {
      Header: 'Avg psi/day',
      accessor: 'avgUsage',

    },
    {
      Header: 'Est. Delivery',
      accessor: 'dayOfReservePoint',

    },
    {
      Header: 'Outage Date',
      accessor: 'dayOfOutagePoint',

    },
    {
      Header: 'Comment',
      accessor: 'comment',

    }
  ]
};


export const rTUDetailsListPage = {
  editMode: true,
  items: [
    {
      type: FormItemType.fieldset,
      label: 'RTU Details',
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.label,
              label: 'Days',
              fitToContent: true,
              defaultValue: 30,
            },
            {
              type: FormItemType.select,
              options: generateNumbers(30).map(x => {
                return { id: 31 - x, text: `${31 - x} Days` }
              }),
              name: 'days',
            },
            {
              type: FormItemType.checkbox,
              label: 'View All Readings',
              name: 'viewAllReadings',
              fitToContent: true,
              defaultValue: false
            }
          ]
        },
        {
          type: FormItemType.customField,
          name: 'readings',
          label: 'Readings',
          getComponent: () => RTUReadingsListComponent
        }
      ]
    }
  ]
} as FormView;

export const [RTUDetailsListPage, RTUDetailsListPageRepo] = createConnectedView2({ form: rTUDetailsListPage, dataSource: rtuDetailsDataSource });


export const RTUReadingsListComponent = createListView(readingsLIst as any, RTUDetailsListPageRepo as Repository, state => state.rtuDetails?.current?.data?.readings);

