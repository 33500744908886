import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setLiquidContainerCreateStatus } from '../../../store/slices/liquid-containers-slice';
import CreateLiquidContainerForm from './create-liquid-container-form';

function CreateLiquidContainer(props) {
  const liquidContainerCreateStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerCreate.status,
  );
  const liquidContainerCreateError = useSelector(
    (state) => state.liquidContainer.liquidContainerCreate.error,
  );

  const currentLiquidContainerId = useSelector((
    state,
  ) => state.liquidContainer.currentLiquidContainer.liquidContainerId);

  const liquidContainerCreateSuccessNotify = () => toast.success('Successfully created Liquid Container');
  const liquidContainerCreateFailedNotify = (error) => toast.error(`Failed to create Liquid Container\n${error}`);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (liquidContainerCreateStatus === 'succeeded') {
      liquidContainerCreateSuccessNotify();
      dispatch(setLiquidContainerCreateStatus('idle'));
      navigate(`/assets/liquid-container/${currentLiquidContainerId}`);
    }
    if (liquidContainerCreateStatus === 'failed') {
      liquidContainerCreateFailedNotify(liquidContainerCreateError);
      dispatch(setLiquidContainerCreateStatus('idle'));
    }
  }, [liquidContainerCreateStatus, liquidContainerCreateError]);

  // const currentLiquidContainer = useSelector(selectCurrentLiquidContainer);

  const liquidContainerStatusIsLoading = liquidContainerCreateStatus === 'loading';

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          Add New Liquid Container
        </Modal.Title>
      </Modal.Header>
      <CreateLiquidContainerForm
        onHide={props.onHide}
        liquidContainerStatusIsLoading={liquidContainerStatusIsLoading}
      />
    </>
  );
}

export default CreateLiquidContainer;
