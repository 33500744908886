/* eslint-disable unused-imports/no-unused-imports */
/* eslint-disable indent */
import 'chartjs-adapter-date-fns';

import {
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useMemo, useRef } from 'react';
import {
  Card, Col, Container, Row,
} from 'react-bootstrap';
import { Line } from 'react-chartjs-2';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';

// eslint-disable-next-line unused-imports/no-unused-imports
import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
/* import { fetchLiquidContainerReadings, selectCurrentLiquidContainer, selectCurrentLiquidContainerReadings } from '../../../../store/slices/liquid-containers-slice';
import PaginatedSelectTable from '../Table/paginated-select-table'; */

const myColors = ['#005591', '#6c757d', '#00a0e1', '#002f5a'];

export function SensorChart(props) {
  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);

  const getTimestamp = (dateString) => {
    const dt = new Date(dateString);
    return dt.getTime();
  };
  const formatLabelDate = (dateString) => {
    const dt = new Date(dateString);
    const datePortion = formatDate(dt, datePreference);
    const hours = dt.getHours().toString().length === 1 ? `0${dt.getHours().toString()}` : dt.getHours().toString();
    const minutes = dt.getMinutes().toString().length === 1 ? `0${dt.getMinutes().toString()}` : dt.getMinutes().toString();
    return `${datePortion}`;
  };
  const dp = (val, element) => {
    return {
      x: getTimestamp(element.readingTime),
      y: val,
    };
  };
  const dataSets = React.useMemo(() => props.containerReadings.map((reading, idx) => ({
      label: reading.label,
      data: reading.data,
      borderColor: myColors[idx],
      backgroundColor: myColors[idx],
      radius: 0,
      spanGaps: true,
    })), [props.containerReadings]);

  const options = {
    animation: false,
    parsing: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales:
    {
      y: {
        ticks:
        {
          beginAtZero: true,
        },
      },
      x: {
        type: 'time',
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          callback: (value) => {
            // eslint-disable-next-line key-spacing
            return formatLabelDate(value);
          },
        },
      },
    },
    tooltips: {
      mode: 'x-axis',
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
      decimation: {
        enabled: true,
        algorithm: 'min-max',
        samples: 200,
      },
    },
  };

  ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Decimation,
  );

  return (
    <Line
      options={options}
      data={{
        datasets: dataSets,
      }}
    />
  );
}
