import { createConnectedListView, createConnectedListView2, createListView, FilterType, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { worldMapDataSource, worldMapRareGasDataSource } from "./worldmap-data-source";

export const worldMapList: ListView = {
  filters: [
    {
      type: FilterType.multiselect,
      label: 'Asset Type',
      fieldName: 'assetType',
      options: (state) => ['Liquid', 'Gas', 'Chassis'],
      filterValue: {'Liquid': true, 'Gas': true, 'Chassis': true}
    }
  ],
  columns: [
    {
      Header: 'Asset Id',
      accessor: 'containerId',
      defaultSort: true,
      type: ListViewColumnType.link,
      linkPath: ({value, row}) => `/assets/${assetRoute(row.original.assetType)}/${value}#sensors`
/*       linkPath: ({value, row}) => `/raregas/edit/${value}#sensors` */
    },
    {
      Header: 'Asset Type',
      accessor: 'assetType',
    },
    {
      Header: 'Latitude',
      accessor: 'latitude',
    },
    {
      Header: 'Longitude',
      accessor: 'longitude',
    },
  ] as ListViewColumn[]

};
export const assetRoute = (type) => type === 'Liquid' ? 'liquid-container' : type === 'Gas' ? 'gas-container' : type === 'Chassis' ? 'chassis' : type === 'RareGas' ? 'raregas' : null;

export const [WorlMapListComponent, WorlMapListComponentStore] = createConnectedListView2(worldMapList, worldMapDataSource);

export const [WorlMapRareGasListComponent, WorlMapRareGasListComponentStore] = createConnectedListView2({
  ...worldMapList,
  filters: [
/*     {
      type: FilterType.simple,
      fieldName: 'isActive',
      label: 'Active',
      filterValue: true
    }, */

/*     {
      id: null,
      text: ''
    },
    {
      id: 'MCP',
      text: 'MCP'
    },
    {
      id: 'TT',
      text: 'Trailer'
    },
    {
      id: 'module',
      text: 'Module'
    }, */


    {
      type: FilterType.multiselect,
      fieldName: 'moduleType',
      filterValue: { ['MCP']: true, ['TT']: true, ['module']: true },
      options: () => ['MCP', 'TT', 'module'],
      labels: { ['MCP']: 'MCP', ['TT']: 'Trailer', ['module']: 'Module'},
    },
    {
      type: FilterType.combo,
      label: 'Owner',
      fieldName: 'region',
    },
    {
      type: FilterType.combo,
      label: 'Product Type',
      fieldName: 'product'
    },
  ],
/*   filters: [
    {
      type: FilterType.multiselect,
      label: 'Asset Type',
      fieldName: 'assetType',
      options: (state) => ['RareGas', 'electronics'],
      labels: {
        'RareGas' : 'Rare Gas',
        'electronics' : 'Electronics'
      },
      filterValue: {'RareGas': true, 'electronics': true}
    }
  ],  */
  columns: [
    {
      Header: 'Asset Id',
      accessor: 'containerId',
      defaultSort: true,
      type: ListViewColumnType.link,
      linkPath: ({value, row}) => `/raregas/edit/${value}#sensors`
    },
    {
      Header: 'Asset Type',
      accessor: 'moduleType',
    },
    {
      Header: 'Latitude',
      accessor: 'latitude',
    },
    {
      Header: 'Longitude',
      accessor: 'longitude',
    },
    {
      Header: 'Product',
      accessor: 'product',
    },
    {
      Header: 'Owner',
      accessor: 'region',
    },
  ] as ListViewColumn[]
}, worldMapRareGasDataSource);