import { Form } from "react-hook-form";
import { formatDate } from "../../../../services/format";
import { getGasContainers } from "../../../../services/requests";
import { getBillingHeader, getGasInvoices, UpdateBilling } from "../../../../services/requests/billing/gas-billing-requests";
import { FormItemType } from "../../../UI/view/component-map";
import { DataSource } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createConnectedView2 } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { gasBillingPendingDeliveryDataSource } from "./gas-billing-datasource";

export const gasinvoicesDS = {
  request: getGasInvoices,
  pk: 'billingId',
  name: 'gasBillingInvoice',
  updateRequest: UpdateBilling,
  entityRequest: getBillingHeader,
} as DataSource;


const gasVolume = (psig: number, temperature: number, waterVolume: number, heliumPercentage: number = 1) => {
  const waterVolumeAtPT = waterVolume * (1 + (7.4e-7 * psig));
  const volume = (
    (((psig + 14.7000) / 14.7000) * (529.7000 / (temperature + 459.67000)) * (1.00049 / (1 + (((10.2297e-5) - ((19.2998e-8) * (temperature + 459.67) + ((1.1836e-10) * Math.pow((temperature + 459.67000), 2))) * (psig + 14.7) - ((2.217e-10) * Math.pow((psig + 14.7000), 2))))))) * waterVolumeAtPT
  );
  return volume * heliumPercentage;
}


export const getBillingInvoiceEdit = {
  editMode: true,
  pageHeader: 'Gas Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Gas Billing',
      icon: 'currency-dollar',
      to: '/billing/gasproduct#Invoices',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              header: ({ gasBillingInvoice }) => gasBillingInvoice?.current?.data?.customerName,
              infoListItems: [

                {
                  label: 'Customer',
                  name: 'customerName'
                },
                {
                  label: 'Customer Id',
                  name: 'customerId'
                },

              ]
            },

          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Gas Invoice',
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  label: 'Create',
                  onAfterSubmit: ({ redirect }) => {
                    alert('Invoice Successfully Created')
                    redirect('/billing/gasproduct#Invoices');
                  }
                }
              ],
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Details',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Shipping Order #',
                          name: 'socpo',
                          disabled: () => true
                        },
                        {
                          label: 'Tracking #',
                          name: 'trackingNumberId',
                          type: FormItemType.labelField,
                          link: ({ watch }) => `https://vts.linde.grp/Helium/VTS/Gas/Shipping.aspx?gtrkno=${watch('trackingNumberId')}`,
                        },
                      ]
                    },

                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Container Started',
                          name: 'containerIdstarted',
                          disabled: () => true
                        },
                        {
                          label: 'Container Ended',
                          name: 'containerIdended',
                          type: FormItemType.typeahead,
                          options: async () => {
                            let list = await getGasContainers();
                            return [...list.map(t => ({ id: t.serialNumber, label: `${t.serialNumber}` }))]
                          },
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'PO #',
                          name: 'ponumber',
                        },
                        {
                          label: 'Release #',
                          name: 'roNumber',
                        },
                      ]
                    },


                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Use Adjusted volume',
                          name: 'jdeadjusted',
                          type: FormItemType.checkboxField
                        },
                        {
                          label: 'Adjusted volume',
                          name: 'jdeadjustedVolume',
                          disabled: ({ watch }) => !watch('jdeadjusted'),
                        },
                      ]
                    },

                    {
                      label: 'Delivery Comments',
                      name: 'deliveryComments',
                      type: FormItemType.textarea
                    },
                    {
                      label: 'Approval Comments',
                      name: 'approvalComments',
                      type: FormItemType.textarea
                    },
                    {
                      name: 'billingType',
                      defaultValue: 'GASBILLING',
                      hideIf: () => true
                    },
                    {
                      name: 'productType',
                      defaultValue: 'Gas',
                      hideIf: () => true
                    }
                  ]
                }
              ]
            },
            {
              label: 'Invoice Contents',
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  label: 'Create',
                  onAfterSubmit: ({ redirect }) => {
                    alert('Invoice Successfully Created')
                    redirect('/billing/gasproduct#Invoices');
                  }
                }
              ],
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Contents',
                  items: [
                    {
                      type: FormItemType.editTable,
                      name: 'gasBillingDetails',
                      variant: 'tabular',
                      pk: 'id',
                      hideAddButton: true,
                      columns: [
                        {
                          Header: 'Bank',
                          accessor: 'bank',
                          formItemProps: {
                            name: 'bank',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Pressure Start',
                          accessor: 'pressureStarted',
                          formItemProps: {
                            name: 'pressureStarted',
                            onChange: (v, getValues, setValue, editTableProps) => {
                              const { rowIdentity } = editTableProps;
                              const { gasBillingDetails } = getValues();
                              const pressureStartedNum = Number(v ?? 0);
                              const { tempStarted, waterVollumeStarted } = gasBillingDetails[rowIdentity];

                              const tempStartedNum = Number(tempStarted ?? 0);
                              const waterVolumeStartedNum = Number(waterVollumeStarted ?? 0);
                              const volCalculated = gasVolume(pressureStartedNum, tempStartedNum, waterVolumeStartedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcStart`, Math.trunc(volCalculated ?? 0))
                            },
                          }
                        },
                        {
                          Header: 'Temp Start',
                          accessor: 'tempStarted',
                          formItemProps: {
                            name: 'tempStarted',
                            onChange: (v, getValues, setValue, editTableProps) => {
                              const { rowIdentity } = editTableProps;
                              const { gasBillingDetails } = getValues();
                              const tempStartedNum = Number(v ?? 0);
                              const { pressureStarted, waterVollumeStarted } = gasBillingDetails[rowIdentity];

                              const pressureStartedNum = Number(pressureStarted ?? 0);
                              const waterVolumeStartedNum = Number(waterVollumeStarted ?? 0);
                              const volCalculated = gasVolume(pressureStartedNum, tempStartedNum, waterVolumeStartedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcStart`, Math.trunc(volCalculated ?? 0))
                            },
                          }
                        },
                        {
                          Header: 'Volume Start',
                          accessor: 'waterVolumeStarted',
                          formItemProps: {
                            name: 'waterVollumeStarted',
                            onChange: (v, getValues, setValue, editTableProps) => {
                              const { rowIdentity } = editTableProps;
                              const { gasBillingDetails } = getValues();
                              const waterVolumeStartedNum = Number(v ?? 0);
                              const { tempStarted, pressureStarted } = gasBillingDetails[rowIdentity];

                              const tempStartedNum = Number(tempStarted ?? 0);
                              const pressureStartedNum = Number(pressureStarted ?? 0);
                              const volCalculated = gasVolume(pressureStartedNum, tempStartedNum, waterVolumeStartedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcStart`, Math.trunc(volCalculated ?? 0))


              
                              const { tempEnded, pressureEnded, waterVolumeEnded } = gasBillingDetails[rowIdentity];
                              const waterVolumeEndedNum = Number(waterVolumeEnded ?? 0);
                              const tempEndedNum = Number(tempEnded ?? 0);
                              const pressureEndedNum = Number(pressureEnded ?? 0);
                              const volCalculatedEnd = gasVolume(pressureEndedNum, tempEndedNum, waterVolumeEndedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcNet`, Math.trunc(volCalculated ?? 0) - Math.trunc(volCalculatedEnd ?? 0))
                            },
                          }
                        },
                        {
                          Header: 'Volume',
                          accessor: 'volumeCalcStart',
                          formItemProps: {
                            name: 'volumeCalcStart',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Pressure End',
                          accessor: 'pressureEnded',
                          formItemProps: {
                            name: 'pressureEnded',
                            onChange: (v, getValues, setValue, editTableProps) => {
                              const { rowIdentity } = editTableProps;
                              const { gasBillingDetails } = getValues();
                              const pressureEndedNum = Number(v ?? 0);
                              const { tempEnded, waterVollumeEnded } = gasBillingDetails[rowIdentity];

                              const tempEndedNum = Number(tempEnded ?? 0);
                              const waterVolumeEndedNum = Number(waterVollumeEnded ?? 0);
                              const volCalculated = gasVolume(pressureEndedNum, tempEndedNum, waterVolumeEndedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcEnd`, Math.trunc(volCalculated ?? 0))
                            },
                          }
                        },
                        {
                          Header: 'Temp End',
                          accessor: 'tempEnded',
                          formItemProps: {
                            name: 'tempEnded',
                            onChange: (v, getValues, setValue, editTableProps) => {
                              const { rowIdentity } = editTableProps;
                              const { gasBillingDetails } = getValues();
                              const tempEndedNum = Number(v ?? 0);
                              const { pressureEnded, waterVollumeEnded } = gasBillingDetails[rowIdentity];

                              const pressureEndedNum = Number(pressureEnded ?? 0);
                              const waterVolumeEndedNum = Number(waterVollumeEnded ?? 0);
                              const volCalculated = gasVolume(pressureEndedNum, tempEndedNum, waterVolumeEndedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcEnd`, Math.trunc(volCalculated ?? 0))
                            },
                          }
                        },
                        {
                          Header: 'Volume End',
                          accessor: 'waterVolumeEnded',
                          formItemProps: {
                            name: 'waterVollumeEnded',
                            onChange: (v, getValues, setValue, editTableProps) => {
                              const { rowIdentity } = editTableProps;
                              const { gasBillingDetails } = getValues();
                              const waterVolumeEndedNum = Number(v ?? 0);
                              const { tempEnded, pressureEnded } = gasBillingDetails[rowIdentity];

                              const tempEndedNum = Number(tempEnded ?? 0);
                              const pressureEndedNum = Number(pressureEnded ?? 0);
                              const volCalculated = gasVolume(pressureEndedNum, tempEndedNum, waterVolumeEndedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcEnd`, Math.trunc(volCalculated ?? 0))


                              const { tempStarted, pressureStarted, waterVolumeStarted } = gasBillingDetails[rowIdentity];
                              const waterVolumeStartedNum = Number(waterVolumeStarted ?? 0);
                              const tempStartedNum = Number(tempStarted ?? 0);
                              const pressureStartedNum = Number(pressureStarted ?? 0);
                              const volCalculatedStart = gasVolume(pressureStartedNum, tempStartedNum, waterVolumeStartedNum);
                              setValue(`gasBillingDetails[${rowIdentity}].volumeCalcNet`, Math.trunc(volCalculatedStart ?? 0) - Math.trunc(volCalculated ?? 0))
                            },
                          }
                        },
                        {
                          Header: 'Volume',
                          accessor: 'volumeCalcEnd',
                          formItemProps: {
                            name: 'volumeCalcEnd',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Net',
                          accessor: 'volumeCalcNet',
                          formItemProps: {
                            name: 'volumeCalcNet',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                      ]
                    }
                  ]
                }

              ]
            }
          ]
        }
      ]
    }
  ]

} as FormView;


export const [GasBillingInvoiceCreate, GasBillingInvoiceCreateRepo] = createConnectedView2({ form: getBillingInvoiceEdit, dataSource: gasBillingPendingDeliveryDataSource as DataSource });