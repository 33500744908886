import React, { useMemo, useRef, useState } from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { BsFillPencilFill, BsPaperclip, BsPlusLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import {
  formatAssetType, formatDate, getAssetNavigationPath, splitStringOnUppercase,
} from '../../../services/format';
import { filterDatesByRange } from '../../../services/table-helpers';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  selectMaintenanceRequestDropdownObjects,
  selectPendingMaintenanceRequests,
  setCurrentMaintenanceRequestId,
} from '../../../store/slices/maintenance-requests-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import AddRequestToWorkOrderModal from './add-request-to-work-order';
import MaintenanceRequestAttachments from './maintenance-request-attachments';
import RequestDetailsForm from './request-details-form';

function PendingRequestsTable(props) {
  const pendingMaintenaceRequests = useSelector(selectPendingMaintenanceRequests);
  const { datePreference } = useSelector(selectLoggedInUserPreferences);
  const { maintenanceRequestStatuses } = useSelector(selectMaintenanceRequestDropdownObjects);
  const maintenanceRequestFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );
  const maintenanceRequestFetchError = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.error,
  );

  const maintenanceRequestTypeFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypesFetch.status,
  );

  const [editModalShow, setEditModalShow] = useState(false);
  const [addToWorkOrderModaShow, setAddToWorkOrderModaShow] = useState(false);
  const [selectedMaintenanceRequest, setSelectedMaintenanceRequest] = useState(null);
  const [attachmentsModalShow, setAttachmentsModalShow] = useState(false);
  const dispatch = useDispatch();

  const handleEditModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setEditModalShow(false);
  };

  const handleEditModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    setEditModalShow(true);
  };

  const handleWorkOrderModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setSelectedMaintenanceRequest(null);
    setAddToWorkOrderModaShow(false);
  };

  const handleWorkOrderModalShow = (maintenanceRequest) => {
    dispatch(setCurrentMaintenanceRequestId(maintenanceRequest.maintenanceRequestId));
    setSelectedMaintenanceRequest(maintenanceRequest);
    setAddToWorkOrderModaShow(true);
  };

  const handleAttachmentsModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setAttachmentsModalShow(false);
  };

  const handleAttachmentsModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    setAttachmentsModalShow(true);
  };

  const getAssetLink = (row) => {
    const navPath = getAssetNavigationPath(row.original.assetTypeId);
    return (<NavLink to={`/assets/${navPath}/${row.original.assetSerialNumber}`}>{row.original.assetSerialNumber}</NavLink>);
  };

  const data = useMemo(() => {
    return pendingMaintenaceRequests;
  }, [pendingMaintenaceRequests]);

  const maintenanceRequestsColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ row }) => (
          <div className="d-flex">
            <Button
              size="xsm"
              title="Edit"
              onClick={() => handleEditModalShow(row.original.maintenanceRequestId)}
              className="mr-1"
            >
              <BsFillPencilFill />
            </Button>
            <Button
              size="xsm"
              title="Add to Work Order"
              disabled={row.original.maintenanceRequestStatusId === 3}
              onClick={() => handleWorkOrderModalShow(row.original)}
              className="mr-1"
            >
              <BsPlusLg />
            </Button>
            <Button
              size="xsm"
              title="Attach File"
              onClick={() => handleAttachmentsModalShow(row.original.maintenanceRequestId)}
            >
              <BsPaperclip />
            </Button>
          </div>
        ),
      },
      {
        Header: 'ID',
        accessor: 'maintenanceRequestId',
      },
      {
        Header: 'Status',
        accessor: 'maintenanceRequestStatusId',
        Cell: ({ value }) => (value in maintenanceRequestStatuses
          ? splitStringOnUppercase(maintenanceRequestStatuses[value].name)
          : '?'),
      },
      {
        Header: 'Request Type Name',
        accessor: 'maintenanceRequestType',
      },
      {
        Header: 'Unit ID',
        accessor: 'assetSerialNumber',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        Cell: ({ value, row }) => (getAssetLink(row)),
      },
      {
        Header: 'Asset Type',
        accessor: 'assetTypeId',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        Cell: ({ value }) => (formatAssetType(value)),
      },
      {
        Header: 'Estimated Effort​',
        accessor: 'estimatedEffort',
      },
      {
        Header: 'Due Date',
        accessor: 'plannedDueDate',
        filter: filterDatesByRange,
        Cell: ({ value }) => (value == null ? null : formatDate(value, datePreference)),
      },
      {
        Header: 'Description',
        accessor: 'details',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [maintenanceRequestStatuses],
  );

  const tableInstance = useRef(null);

  const content = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <InputGroup.Text><i className="bi bi-search" /></InputGroup.Text>
          <TableSearchFilter tableInstance={tableInstance} />
        </InputGroup>
      </div>
      <PaginatedSelectTable
        columns={maintenanceRequestsColumns}
        data={data}
        ref={tableInstance}
        rowProps={() => ({})}
      />
      <RequestDetailsForm
        show={editModalShow}
        onHide={handleEditModalClose}
      />
      <AddRequestToWorkOrderModal
        assetTypeId={selectedMaintenanceRequest?.assetTypeId}
        assetSerialNumber={selectedMaintenanceRequest?.assetSerialNumber}
        show={addToWorkOrderModaShow}
        onHide={handleWorkOrderModalClose}
      />
      <MaintenanceRequestAttachments
        show={attachmentsModalShow}
        onHide={handleAttachmentsModalClose}
      />
    </>
  );

  const requestsAreLoading = [
    maintenanceRequestFetchStatus,
    maintenanceRequestTypeFetchStatus,
  ].includes('loading');

  const requestsHaveError = [
    maintenanceRequestFetchStatus,
  ].includes('error');

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${requestsAreLoading ? ' creation-loading' : ''}`}>
        {requestsAreLoading ? null : content}
      </div>
      {requestsHaveError && <ErrorContent errorMessage={maintenanceRequestFetchError} />}
      {requestsAreLoading && <LoadingContent />}
    </>
  );
}

export default PendingRequestsTable;
