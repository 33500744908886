import { OverlayTrigger, Popover } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { RedIcon } from '../CircleFillIcon/circle-fill-icon';

function SideBarButton({ redReasons, wos, value }) {
  return (
    <OverlayTrigger
      placement="bottom"
      delay={{ show: 250, hide: 1500 }}
      overlay={(
        <Popover id="reason-tooltip" style={{ width: '300px' }}>
          <Popover.Header as="div">
            <small>
              {redReasons && redReasons.map((reason, i) => {
                return <div key={`Reason${value}${i}`}><b>{reason}</b></div>;
              })}
              {wos?.length > 0 && (
                <>
                  <div>Work Orders:</div>
                  {wos.map((wo, i) => (
                    <div key={`ReasonWO${value}${i}`} style={{ cursor: 'pointer' }}>
                      <Link target="_blank" to={`/maintenance/work-orders/${wo.replace('ID: ', '')}`}>{wo}</Link>
                    </div>
                  ))}
                </>
              )}
            </small>
          </Popover.Header>
        </Popover>
      )}
    >
      <div>
        <RedIcon />
      </div>
    </OverlayTrigger>
  );
}

export default SideBarButton;
