/* eslint-disable */
import React from 'react';
import {
  OverlayTrigger, Tooltip as TooltipInner
} from 'react-bootstrap';

export function Tooltip(props) {
  return (
    <OverlayTrigger
      placement="top"
      overlay={(
        <TooltipInner>
          {props.title}
        </TooltipInner>
      )}
    >
    {props.children}
  </OverlayTrigger>
  );
}