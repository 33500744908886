import React, { useMemo } from "react";
import { Nav, Badge } from "react-bootstrap";
import { TabHeaderButton } from "./TabHeaderButton";

export const TabHeaderRow = (props) => {
  const {field, formView } = props;
  const { items, formState, editMode } = formView;
  const { register, control, watch, formState: { errors }, setValue, handleSubmit, onSubmit, getValues } = formState;
  const hasErrors = useMemo(() => {
    
  }, [errors])
  return (
    <div className="card-header p-0 pt-1">
      <Nav variant="tabs">
        {field.items.map((tab, i) => {
          return (
            <TabHeaderButton {...props} tab={tab}/>
          )
        })}
      </Nav>
    </div>
  );
}