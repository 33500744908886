import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Col, Form, InputGroup,
  Modal, Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { BsXLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { sortByBasic } from '../../../services/table-helpers';
import {
  createRole,
  selectCreateRoleStatus,
  selectCurrentRoleData,
  selectCurrentRoleId,
  setCreateRoleStatus,
  setCurrentRoleId,
  setUpdateRoleStatus,
  updateCurrentRoleUser,
  updateRole,
} from '../../../store/slices/roles-slice';
import { fetchUsers, selectUsersNotInRole } from '../../../store/slices/users-slice';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { Typeahead } from '../../UI/molecules/Typeahead';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

function RoleCreateModal({ show, onHide }) {
  const createRoleStatus = useSelector(selectCreateRoleStatus);

  const currentRoleStatus = useSelector((state) => state.role.currentRole.status);
  const updateRoleStatus = useSelector((state) => state.role.roleUpdate.status);

  const currentRole = useSelector(selectCurrentRoleData);
  const currentRoleId = useSelector(selectCurrentRoleId);

  const usersNotInRole = useSelector(selectUsersNotInRole);
  const dispatch = useDispatch();

  const [userToAdd, setUserToAdd] = useState(null);

  const typeaheadRef = useRef(null);

  const onSubmit = (role) => {
    if (currentRoleId !== null) {
      dispatch(updateRole({ userId: currentRoleId, user: role }));
    } else {
      dispatch(createRole(role));
    }
  };

  const {
    register, handleSubmit, setValue, reset,
  } = useForm();

  const onModalHide = () => {
    onHide();
    setUserToAdd(null);
    reset();
    // dispatch(setCreateRoleStatus('idle'));
    dispatch(setCurrentRoleId(null));
  };

  const onUserAdd = (userId) => {
    dispatch(updateCurrentRoleUser({ userId, action: 'add' }));
  };

  const onUserRemove = (userId) => {
    dispatch(updateCurrentRoleUser({ userId, action: 'remove' }));
  };

  const currentUsersColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => (
          <Button
            size="xsm"
            variant="danger"
            onClick={() => {
              onUserRemove(cell.row.original.userId);
            }}
          >
            <BsXLg />
          </Button>
        ),
      },
      {
        Header: 'Name',
        accessor: 'displayName',
      },
    ],
    [],
  );

  const currentUsersTableInstance = useRef(null);

  const roleUsersData = useMemo(() => {
    if (currentRole) {
      const roleUsers = [...Object.values(currentRole.users ?? {}).filter((u) => u.isActive)];
      roleUsers.sort((a, b) => sortByBasic(a.displayName, b.displayName));
      return roleUsers;
    }

    return [];
  }, [currentRole]);

  const currentUsersTable = (
    <PaginatedSelectTable
      columns={currentUsersColumns}
      data={roleUsersData}
      ref={currentUsersTableInstance}
      rowProps={(row) => ({})}
    />
  );

  const roleCreateSuccessNotify = () => toast.success('Successfully created role');

  const roleUpdateSuccessNotify = () => toast.success('Updated Role');

  const roleUpdateFailureNotify = () => toast.error('Failed to update Role');

  useEffect(() => {
    if (createRoleStatus === 'succeeded') {
      roleCreateSuccessNotify();
      dispatch(setCreateRoleStatus('idle'));
      onModalHide();
    }
  }, [createRoleStatus]);

  useEffect(() => {
    if (currentRoleStatus === 'succeeded') {
      setUserToAdd(null);
      if (typeaheadRef?.current) {
        typeaheadRef.current.clear();
      }
    }
  }, [currentRoleStatus]);

  useEffect(() => {
    if (updateRoleStatus === 'succeeded') {
      roleUpdateSuccessNotify();
      dispatch(setUpdateRoleStatus('idle'));
      onModalHide();
    }
    if (updateRoleStatus === 'failed') {
      roleUpdateFailureNotify();
      dispatch(setUpdateRoleStatus('idle'));
      onModalHide();
    }
  }, [updateRoleStatus]);

  const roleStatusIsLoading = createRoleStatus === 'loading'
    || currentRoleStatus === 'loading' || updateRoleStatus === 'loading';

  useEffect(() => {
    if (currentRole !== null) {
      setValue('name', currentRole.name);
      setValue('description', currentRole.description);
    }
  }, [currentRole]);
  useEffect(() => {
    if (show === true) dispatch(fetchUsers());
  }, [show]);

  return (
    <Modal
      show={show}
      dialogClassName="rbac-add-modal"
      onHide={() => onModalHide()}
      size="lg"
      centered
      scrollable
      backdrop={roleStatusIsLoading ? 'static' : true}
    >
      <Modal.Header closeButton={!roleStatusIsLoading}>
        <Modal.Title id="contained-modal-title-vcenter">
          {currentRoleId === null ? 'Add Role' : 'Edit Role'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        {roleStatusIsLoading ? <LoadingContent /> : ''}
        <Row className={`${roleStatusIsLoading ? ' creation-loading' : ''}`}>
          <Col className="role-input-section">

            <Form onSubmit={handleSubmit(onSubmit)}>

              <Form.Group controlId="adInfo">
                <h5>Helium3 Role Info</h5>

                <Row className="mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control disabled={currentRoleId} size="sm" placeholder="name" {...register('name')} />
                  </Col>
                  <Col>
                    <Form.Label>Description</Form.Label>
                    <Form.Control size="sm" placeholder="name" {...register('description')} />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        {currentRoleId !== null && (
          <>
            <Form.Group>
              <Form.Label>Users In Role</Form.Label>
              <Row className="pb-3">
                <Col>
                  <InputGroup>
                    <Typeahead
                      id="add-user-to-role-typeahead"
                      labelKey="displayName"
                      size="sm"
                      className="flex-grow-1"
                      onChange={(e) => {
                        setUserToAdd(e[0]);
                      }}
                      ref={typeaheadRef}
                      options={usersNotInRole}
                      placeholder="Add user to role..."
                    />
                    &nbsp;
                    <Button
                      size="sm"
                      onClick={() => onUserAdd(userToAdd.userId)}
                      disabled={!userToAdd?.userId}
                    >
                      Add User
                    </Button>
                  </InputGroup>
                </Col>
              </Row>

            </Form.Group>
            <div className="d-flex flex-column flex-grow-1 overflow-auto">
              {currentUsersTable}
            </div>

          </>
        )}

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={onHide} variant="secondary" className="mt-3 position-sticky float-end bottom-0" disabled={roleStatusIsLoading}>Close</Button>
        <Button type="submit" className="mt-3 position-sticky float-end bottom-0" onClick={handleSubmit(onSubmit)} disabled={roleStatusIsLoading}>Submit</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default RoleCreateModal;
