import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col,
  Container, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { truncateString } from '../../../services/format';
import { selectAllActiveLocations, selectAllLocationObjects } from '../../../store/slices/legacy-slice';
import {
  assignMaintenanceRequest,
  createWorkOrderAssignMaintenanceRequest,
  selectCurrentMaintenanceRequest,
  selectMaintenanceRequestDropdownValues,
  setAssignMaintenanceRequestStatus,
  setCreateWorkOrderAssignMaintenanceRequest,
} from '../../../store/slices/maintenance-requests-slice';
import { selectWorkOrdersForAsset } from '../../../store/slices/work-orders-slice';
import CustomModal from '../../UI/molecules/Modal/modal';
import {
  Typeahead,
} from '../../UI/molecules/Typeahead';

function AddRequestToWorkOrderModal(props) {
  const defaultWorkOrderFormValues = {
    assetSerialNumber: null,
    assetTypeId: null,
    locationId: null,
    workOrderStatusId: null,
    maintenanceRequestId: null,
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultWorkOrderFormValues,
  });

  const dispatch = useDispatch();

  const currentMaintenanceRequest = useSelector(selectCurrentMaintenanceRequest);

  const [selectCreateWorkOrder, setSelectCreateWorkOrder] = useState(false);
  const [locationName, setLocationName] = useState(null);

  const onClose = () => {
    setLocationName(null);
    setSelectCreateWorkOrder(false);
    props.onHide();
  };

  const workOrders = useSelector(
    (state) => selectWorkOrdersForAsset(state, props.assetSerialNumber, props.assetTypeId),
  );

  const locations = useSelector(selectAllLocationObjects);

  const userLocations = useSelector(selectAllActiveLocations);

  const locationsList = useMemo(() => {
    return Object.values(userLocations ?? {});
  }, [userLocations]);

  const filteredWorkOrders = useMemo(() => {
    return workOrders.filter((wo) => {
      return wo.workOrderStatusId < 4;
    });
  }, [workOrders]);

  const [selectedWorkOrder, setSelectedWorkOrder] = useState(null);

  const handleSelectedWorkOrder = (e) => {
    if (e.target.value === 'createWorkOrder') {
      setSelectCreateWorkOrder(true);
    } else if (e.target.value === '-- Work Order --') {
      setSelectCreateWorkOrder(false);
      setLocationName(null);
    } else {
      setSelectedWorkOrder(e.target.value);
    }
  };

  const maintenanceRequestAssignStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestAssign.status,
  );

  const createWorkOrderAssignMaintenanceRequestStatus = useSelector(
    (state) => state.maintenanceRequest.workOrderCreateAssignMaintenanceRequest.status,
  );

  const maintenanceRequestAssignSuccessNotify = () => toast.success('Added Maintenance Request to Work Order');
  const maintenanceRequestAssignFailedNotify = () => toast.error('Failed to add maintenance request to work order');

  const workOrderCreateAssignSuccessNotify = () => toast.success('Added Work Order to Maintenance Request');
  const workOrderCreateAssignFailedNotify = () => toast.error('Failed to add work order to maintenance request');

  useEffect(() => {
    if (maintenanceRequestAssignStatus === 'succeeded' && props.show) {
      maintenanceRequestAssignSuccessNotify();
      dispatch(setAssignMaintenanceRequestStatus('idle'));
      onClose();
    }
    if (maintenanceRequestAssignStatus === 'failed' && props.show) {
      maintenanceRequestAssignFailedNotify();
      dispatch(setAssignMaintenanceRequestStatus('idle'));
    }
  }, [maintenanceRequestAssignStatus, props.show]);

  useEffect(() => {
    if (createWorkOrderAssignMaintenanceRequestStatus === 'succeeded' && props.show) {
      workOrderCreateAssignSuccessNotify();
      dispatch(setCreateWorkOrderAssignMaintenanceRequest('idle'));
      onClose();
    }
    if (createWorkOrderAssignMaintenanceRequestStatus === 'failed' && props.show) {
      workOrderCreateAssignFailedNotify();
      dispatch(setCreateWorkOrderAssignMaintenanceRequest('idle'));
    }
  }, [createWorkOrderAssignMaintenanceRequestStatus, props.show]);

  const onSubmit = (request) => {
    if (selectCreateWorkOrder === true) {
      request.assetSerialNumber = currentMaintenanceRequest.assetSerialNumber;
      request.assetTypeId = currentMaintenanceRequest.assetTypeId;
      request.maintenanceRequestId = currentMaintenanceRequest.maintenanceRequestId;
      request.workOrderStatusId = 1;
      request.customerId = currentMaintenanceRequest.assetDetails.ownerCustomer;
      dispatch(createWorkOrderAssignMaintenanceRequest(request));
    } else {
      dispatch(assignMaintenanceRequest({
        maintenanceRequestId: currentMaintenanceRequest.maintenanceRequestId,
        workOrderId: selectedWorkOrder,
      }));
    }
  };

  const maintenanceRequestStatusIsLoading = [
    maintenanceRequestAssignStatus,
  ].includes('loading');

  const { workOrderStatuses } = useSelector(selectMaintenanceRequestDropdownValues);
  return (
    <CustomModal
      show={props.show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          Add Request to Work Order
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${maintenanceRequestStatusIsLoading ? ' visible' : ' invisible'}`}
        />
        <Form className={`${maintenanceRequestStatusIsLoading ? ' creation-loading' : ''}`}>
          <Container className="p-0 m-0">
            <Row className="mb-2">
              <Col>
                <Form.Group>
                  <Form.Label>Request ID</Form.Label>
                  <Form.Control disabled size="sm" value={currentMaintenanceRequest?.maintenanceRequestId ?? ''} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>Request Type</Form.Label>
                  <Form.Control disabled size="sm" value={currentMaintenanceRequest?.maintenanceRequestType ?? ''} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Select
                  onChange={handleSelectedWorkOrder}
                  size="sm"
                >
                  <option value={null} key="customer-null">-- Work Order --</option>
                  {
              filteredWorkOrders.map((wo) => (
                <option
                  key={`wo-${wo.workOrderId}`}
                  value={wo.workOrderId}
                >
                  {`ID: ${wo.workOrderId} `}
                  --
                  STATUS:
                  {' '}
                  {wo.workOrderStatusId in workOrderStatuses
                    ? workOrderStatuses[wo.workOrderStatusId - 1].name
                    : wo.workOrderStatusId}
                  {' '}
                  --
                  LOCATION:
                  {' '}
                  {locations[wo.locationId]
                    ? locations[wo.locationId].LocationName
                    : wo.locationId}
                  {wo.comments ? ` -- DESCRIPTION: ${truncateString(wo.comments, 30)}` : ''}
                </option>
              ))
            }
                  <option value="createWorkOrder" key="create-new-work-order">-- Create New Work Order --</option>

                </Form.Select>
                {selectCreateWorkOrder ? (
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>Location</Form.Label>
                      <Controller
                        control={control}
                        name="locationId"
                        defaultValue={null}
                        rules={{ required: true }}
                        render={({
                          field: {
                            onChange, value, ref,
                          },
                          fieldState: {
                            invalid, isTouched, isDirty, error,
                          },
                          formState,
                        }) => (
                          <Typeahead
                            ref={ref}
                            positionFixed
                            value={value}
                            {...register('locationId')}
                            onChange={(e) => {
                              onChange(locationsList?.find(
                                (l) => l?.LocationName === e[0],
                              )?.LocationID);
                              setLocationName(e[0]);
                            }}
                            size="sm"
                            id="locationId"
                            options={locationsList.map((l) => l.LocationName)}
                            isInvalid={!!error}
                          />
                        )}
                      />
                    </Form.Group>
                  </Col>
                ) : null}
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={onClose} variant="secondary">Close</Button>
        <Button variant="success" type="submit" disabled={!selectedWorkOrder && !locationName} onClick={handleSubmit(onSubmit)}>Add</Button>
      </Modal.Footer>
    </CustomModal>
  );
}

export default AddRequestToWorkOrderModal;
