import React from 'react';
import { useSelector } from 'react-redux';

import { selectLoggedInUserInfo } from '../../../store/slices/auth-slice';
import ErrorPageTemplate from '../../templates/Errors/error-template';

function NotAuthorized(props) {
  const userInfo = useSelector(selectLoggedInUserInfo);

  const content = (
    <>
      <p>
        We are sorry but you need an application account created in
        order to access this application. Please contact an Administrator
        to create an account.
      </p>
      <p>
        IT have been notified and has been sent a
        detailed error report in order to fix this issue.
      </p>
      <p>
        Username:
        {' '}
        {userInfo ? userInfo.displayName : 'Unknown'}
      </p>
    </>
  );
  return (
    <ErrorPageTemplate
      errorCode={403}
      pageTitle="'! Not Authorized'"
      title="Unauthorized User."
      content={content}
    />
  );
}

export default NotAuthorized;
