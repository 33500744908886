import { formatCurrency } from "../../../../services/format";
import { jdeUploadByOrderIds } from "../../../../services/requests/billing/dewar-billing-requests";
import { FormItemType } from "../../../UI/view/component-map";
import { ListView, ListViewColumnType, createConnectedListView, createConnectedListView2 } from "../../../UI/view/list-view";
import { dewarPendingDataSource } from "./dewar-datasource";



/* Order ID	Praxair #	Customer Name	Shipping Location	City	State	Zip	Date Delivered	Total $	He Vol (Lt) */
export const dewarPendingList = {
  toolbar: [
    {
      type: FormItemType.button,
      label: 'Upload Selected to JDE',
      permissions: {
        read: [1, 14, 15, 18],
        write: [1, 15],
        admin: [1, 15],
      },
      handler: async ({ getValues, confirm }) => {
        const vals = getValues();
        const billingList = vals['pendingDewarInvoices'];
        if (!billingList?.length) {
          await confirm('No invoices are selected. Please select invoices from the list below then press upload.', true)
        } else {
          if (await confirm("Are you sure you want to upload the selected invoices to JDE?") == true) {
            let isError = false;
            try {
              await jdeUploadByOrderIds({ orderIds: billingList.map(b => b.orderNumber) });
            } catch (e) {
              isError = true;
              await confirm('There was an error uploading to JDE:' + e, true)
            }
            if (!isError) {
              await confirm('JDE Upload process is completed.', true);
              location.reload();
            }
          }
        }
      }
    }
  ],
  columns: [
    {
      Header: '',
      accessor: 'checked',
      type: ListViewColumnType.checkbox,
/*       disabled: ({ status, jdebypassed, holdDate }) => moment(moment(holdDate).startOf('day')) > moment(moment().startOf('day')) || (Math.floor(status) != 0 && Math.floor(status) != 9) || jdebypassed, */
      excludeGlobalFilter: true,
    },
    {
      accessor: 'orderNumber',
      Header: 'Order ID',
      defaultSort: true,
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => `/billing/dewarproductpending/${row.original.orderNumber}`,
    },
    {
      accessor: 'customerId',
      Header: 'Praxair #'
    },
    {
      accessor: 'dewarCustomer.accountName',
      Header: 'Customer Name',
      serverExpression: 'DewarCustomer.AccountName'
    },
    {
      accessor: 'location.locationName',
      Header: 'Shipping Location',
      serverExpression: 'location.locationName'
    },
    {
      accessor: 'city',
      serverExpression: 'city',
      Header: 'City'
    },
    {
      accessor: 'state',
      serverExpression: 'state',
      Header: 'State'
    },
    {
      accessor: 'zip',
      serverExpression: 'zip',
      Header: 'Zip'
    },
    {
      accessor: 'dateFulfilled',
      serverExpression: 'dateFulfilled',
      Header: 'Date Delivered',
      type: ListViewColumnType.date
    },
    {
      id: 'totalOrderAmount',
      accessor: ({totalOrderAmount}) => formatCurrency(totalOrderAmount),
      excludeGlobalFilter: true,    
      disableSortBy: true,
      Header: 'Total $'
    },
    {
      accessor: 'totalHeVolume',
      excludeGlobalFilter: true,
      disableSortBy: true,
      Header: 'He Vol (Lt)'
    },
  ]
} as ListView;


export const [DewarBillingPendingList, DewarBillingPendingListRepo] = createConnectedListView2(dewarPendingList, dewarPendingDataSource);