import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  InputGroup,
} from 'react-bootstrap';
import { BsFillPencilFill } from 'react-icons/bs';
// import CardHeader from 'react-bootstrap/esm/CardHeader';
import { useDispatch, useSelector } from 'react-redux';

import { fetchDropdownValues } from '../../../store/slices/legacy-slice';
import { selectAllLocationObjectsWithUsers, setCurrentUserLocationId } from '../../../store/slices/roles-slice';
import ActiveIcon from '../../UI/atoms/ActiveIcon/active-icon';
import InactiveIcon from '../../UI/atoms/InactiveIcon/inactive-Icon';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import LocationAddModal from './location-add-modal';

function LocationRolesTable(props) {
  const locations = useSelector(selectAllLocationObjectsWithUsers);
  const dropdownValuesFetchStatus = useSelector((state) => state.legacy.dropdownValues.status);
  const dropdownValuesFetchError = useSelector((state) => state.legacy.dropdownValues.error);

  const [modalShow, setModalShow] = useState(false);
  const toggleModal = () => setModalShow(!modalShow);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    dispatch(fetchDropdownValues());
  }, [dropdownValuesFetchStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const locationsTableInstance = useRef(null);

  const locationsData = useMemo(() => {
    if (locations) {
      return [...Object.values(locations)];
    }

    return [];
  }, [locations]);

  const locationsColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell, row }) => {
          return (
            <Button
              size="xsm"
              title="Edit"
              onClick={() => {
                dispatch(setCurrentUserLocationId(row.original.LocationID));
                setModalShow(true);
              }}
            >
              <BsFillPencilFill />
            </Button>
          );
        },
      },
      {
        Header: '# Users',
        accessor: (r) => (r?.userIds ? r.userIds.length : 0),
      },
      {
        Header: 'Name',
        accessor: 'LocationName',
      },
      {
        Header: 'Country',
        accessor: 'ResidentCountry',
      },
      {
        Header: 'Active',
        accessor: 'Active',
        Cell: ({ value }) => (value === 'True' ? <ActiveIcon /> : <InactiveIcon />),
      },
    ],
    [],
  );

  const locationsTable = (
    <PaginatedSelectTable
      columns={locationsColumns}
      data={locationsData}
      ref={locationsTableInstance}
      rowProps={() => ({})}
    />
  );

  let locationsContent;
  if (dropdownValuesFetchStatus === 'failed') {
    locationsContent = <ErrorContent errorMessage={dropdownValuesFetchError} />;
  } else if (dropdownValuesFetchStatus === 'loading') {
    locationsContent = <LoadingContent />;
  }

  const [activeTab, setActiveTab] = useState('Roles');

  // const [modalShow, setModalShow] = useState(false);
  // const toggleModal = () => setModalShow(!modalShow);
  return (
    <div className={`d-flex flex-column flex-grow-1 h-100${dropdownValuesFetchStatus === 'loading' ? ' creation-loading' : ''}`}>
      <div className="card-tools">
        <InputGroup size="sm">
          <InputGroup.Text><i className="bi bi-search fas" /></InputGroup.Text>
          <TableSearchFilter tableInstance={locationsTableInstance} />
        </InputGroup>
      </div>
      {locationsTable}
      {locationsContent}
      <LocationAddModal show={modalShow} onHide={() => toggleModal()} />
    </div>
  );
}

export default LocationRolesTable;
