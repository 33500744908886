import './date-input.scss';

import React from 'react';
import { Controller } from 'react-hook-form';

import StyledDatePicker from './styled-date-picker';

function DateInput(props) {
  return (
    <Controller
      name={props.formControlName}
      control={props.control}
      defaultValue=""
      rules={props.rules}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        let converted = typeof value === 'string' ? new Date(value.replace('Z', '')) : value;
        if (Number.isNaN(converted?.getDate())) {
          converted = null;
        }
        return (
          <StyledDatePicker
            className={props.className}
            style={props.style}
            onChange={(newVal) => {
              onChange(newVal);
            }}
            value={converted}
            disabled={props.disabled}
            invalid={!!error}
          />
        );
      }}
    />
  );
}

export default DateInput;
