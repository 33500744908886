import React, { useState } from 'react';
import {
  Card, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';

import PurchaseRequestsTable from './purchase-requests-table';

function PurchaseRequestsTabs(props) {
  const [activeTab, setActiveTab] = useState('Liquid Container');

  return (

    <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
      <TabContainer defaultActiveKey="Liquid Container" onSelect={(tab) => setActiveTab(tab)}>
        <div className="card-header p-0 pt-1">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link data-cy="liquid-purchase-request-tab" eventKey="Liquid Container">Liquid</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Gas Container">Gas</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Chassis">Chassis</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
          <TabContent className="h-100">
            <TabPane aria-labelledby="" eventKey="Liquid Container" className="h-100">
              <PurchaseRequestsTable
                assetTypeId={2}
              />
            </TabPane>
            <TabPane eventKey="Gas Container" className="h-100">
              <PurchaseRequestsTable
                assetTypeId={1}
              />
            </TabPane>
            <TabPane eventKey="Chassis" className="h-100">
              <PurchaseRequestsTable
                assetTypeId={4}
              />
            </TabPane>
          </TabContent>
        </div>
      </TabContainer>
    </Card>
  );
}

export default PurchaseRequestsTabs;
