import { getLateReadings, getMaintenanceInfo, postMaintenanceRequest, putMaintenanceRequest } from "../../../../services/requests";
import { DataSource } from "../../../UI/view/data-source";

export const lateReadingsDataSource = {
  name: 'lateReadings',
  pk: 'containerId',
  request: getLateReadings,
  serverSideEvents: true,
/*   additionalRequests: [
    {
      name: 'maintenanceInfo',
      request: async (data) => {
        const mRs = await getMaintenanceInfo(data.map(d => d.containerId));
        return mRs;
      },
    },
  ], */
} as DataSource;


export const maintenanceRequestDataSource = {
  name: 'createMaintenanceRequest',
  pk: 'maintenanceRequestId',
  createRequest: postMaintenanceRequest,
  updateRequest: putMaintenanceRequest,
  serverSideEvents: true,
  additionalRequests: [
    
  ]
} as DataSource;