import React, { useCallback, useEffect, useState } from 'react';
import {
  Badge,
  Card, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { selectLoggedInUserInfo } from '../../../store/slices/auth-slice';
import {
  fetchParts, selectCreatePartError, selectCreatePartStatus, selectReorderParts,
  setPartCreateStatus, setPartFetchStatus,
} from '../../../store/slices/parts-slice';
import { selectCreatePurchaseRequstsStatus, setCreatePurchaseRequestsStatus } from '../../../store/slices/purchase-request-slice';
import PartsListTable from './parts-list-table';

function PartsListTabs(props) {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState('Parts');
  const [purchaseRequestModalShow, setPurchaseRequestModalShow] = useState(false);

  const userInfo = useSelector(selectLoggedInUserInfo);
  const [partLocationFilter, setPartLocationFilter] = useState(userInfo?.locationPreference);

  const purchaseRequestCreateSuccessNotify = () => toast.success('Successfully created purchase request(s)');
  const purchaseRequestCreateFailureNotify = () => toast.error('Failed to create purchase request(s)');
  const purchaseRequestCreateStatus = useSelector(selectCreatePurchaseRequstsStatus);
  const partCreateSuccessNotify = () => toast.success('Successfully created part');
  const partCreateFailureNotify = (error) => toast.error(`Failed to create part\n${error}`);
  const partCreateStatus = useSelector(selectCreatePartStatus);
  const partCreateError = useSelector(selectCreatePartError);
  const partsStatus = useSelector((state) => state.parts.partsFetch.status);

  useEffect(() => {
    if (purchaseRequestCreateStatus === 'succeeded') {
      purchaseRequestCreateSuccessNotify();
      dispatch(setCreatePurchaseRequestsStatus('idle'));
      setPurchaseRequestModalShow(false);
    }
    if (purchaseRequestCreateStatus === 'failed') {
      purchaseRequestCreateFailureNotify();
      dispatch(setCreatePurchaseRequestsStatus('idle'));
      setPurchaseRequestModalShow(false);
    }
  }, [purchaseRequestCreateStatus]);

  useEffect(() => {
    if (partCreateStatus === 'succeeded') {
      partCreateSuccessNotify();
      dispatch(setPartCreateStatus('idle'));
    }
    if (partCreateStatus === 'failed') {
      partCreateFailureNotify(partCreateError);
      dispatch(setPartCreateStatus('idle'));
    }
  }, [partCreateStatus]);

  const loadParts = useCallback(async () => {
    try {
      await dispatch(fetchParts(partLocationFilter)).unwrap();
      // Setting the fetch back to idle so that other pages/functionality dependent on having full
      // parts data in redux state will make the request to get all parts and not break.
      // additionally this ensures that teh fetch is called initallu on page navigation only once
      dispatch(setPartFetchStatus('idle'));
    } catch (error) {
      toast.error('Failed to get parts');
    }
  }, [partLocationFilter, fetchParts, setPartFetchStatus, dispatch]);

  useEffect(() => {
    if (partsStatus === 'idle' || partsStatus === 'failed') { // used to keep from loading twice on nav cahnge
      loadParts();
    }
  }, [partLocationFilter]);

  useEffect(() => {
    setPartLocationFilter(userInfo?.locationPreference);
  }, [userInfo?.locationPreference]);

  const purchaseRequestCreateIsLoading = purchaseRequestCreateStatus === 'loading';

  const partCreateIsLoading = partCreateStatus === 'loading';

  // const reorderPartsCount = useSelector(selectReorderPartsCount);
  const reorderParts = useSelector(selectReorderParts);

  const reorderPartsCount = Object.values(reorderParts).filter((p) => p.locationId === partLocationFilter)?.length;
  return (

    <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
      <TabContainer defaultActiveKey="Parts" onSelect={(tab) => setActiveTab(tab)}>
        <div className="card-header p-0 pt-1">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="Parts">Parts</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Reorder">
                <div className="d-flex">
                  <span>
                    Re-order
                  </span>
                  {reorderPartsCount > 0
                    && (
                      <Badge pill bg="danger" className="d-inline-flex align-items-center justify-content-center ml-2">
                        {reorderPartsCount}
                      </Badge>
                    )}
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
          <TabContent className="h-100">
            <TabPane eventKey="Parts" className="h-100">
              <PartsListTable
                type="All"
                tabIsActive={activeTab === 'Parts'}
                partCreateIsLoading={partCreateIsLoading}
                partCreateStatus={partCreateStatus}
                purchaseRequestCreateStatus={purchaseRequestCreateIsLoading}
                purchaseRequestModalShow={purchaseRequestModalShow}
                setPurchaseRequestModalShow={setPurchaseRequestModalShow}
                partLocationFilter={partLocationFilter}
                setPartLocationFilter={setPartLocationFilter}
              />
            </TabPane>
            <TabPane eventKey="Reorder" className="h-100">
              <PartsListTable
                type="Reorder"
                tabIsActive={activeTab === 'Reorder'}
                partCreateIsLoading={partCreateIsLoading}
                partCreateStatus={partCreateStatus}
                purchaseRequestCreateStatus={purchaseRequestCreateIsLoading}
                purchaseRequestModalShow={purchaseRequestModalShow}
                setPurchaseRequestModalShow={setPurchaseRequestModalShow}
                partLocationFilter={partLocationFilter}
                setPartLocationFilter={setPartLocationFilter}
              />
            </TabPane>
          </TabContent>
        </div>
      </TabContainer>
    </Card>
  );
}

export default PartsListTabs;
