import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Badge, Button,
  Collapse, Form,
  InputGroup, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import { maintenanceStatusValues } from '../../../services/maintenance-helpers';
import {
  sortByBasic, sortByDateCustom, sortByDateField,
} from '../../../services/table-helpers';
import { selectLoggedInUserActiveLocation, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { fetchChassis, fetchMaintenanceStatusForChassis, selectChassisMaintenanceStatuses } from '../../../store/slices/chassis-slice';
import { selectAllActiveLocationObjects, selectLiquidCustomers } from '../../../store/slices/legacy-slice';
import {
  selectChassisWithLocationInfo,
  selectMaintenanceRequestTypes,
} from '../../../store/slices/maintenance-requests-slice';
import { useTablePageRef } from '../../hooks/useStateRef';
import { YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';
import RedFlagAlert from '../../UI/atoms/RedFlagAlert/red-flag-alert';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { NextDueDate } from '../../UI/molecules/NextDueDate/next-due-date';
import ExpandablePaginatedRowSelectTable from '../../UI/organisms/Table/expandable-paginated-row-select-table';
import {
  chassisLength, chassisTypes,
} from '../Chassis/chassis-values';
import CreateRequestForm from './create-request-form';
import MaintenanceRequestList from './maintenance-requests-list';

function ChassisRequestsTable(props) {
  const chassisWithLocationInfo = useSelector(selectChassisWithLocationInfo);
  const locations = useSelector(selectAllActiveLocationObjects);
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);

  const chassisMaintenanceStatuses = useSelector(
    selectChassisMaintenanceStatuses,
  );

  const chassisMaintenanceFetchStatus = useSelector(
    (state) => state.chassis.chassisMaintenanceStatusFetch.status,
  );

  const maintenanceRequestFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const assetLocationFetchStatus = useSelector(
    (state) => state.assetLocation.status,
  );

  const chassisStatus = useSelector(
    (state) => state.chassis.chassisFetch.status,
  );

  const chassisCustomersArr = useSelector(selectLiquidCustomers);

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (chassisStatus === 'idle' || chassisStatus === 'failed') {
      dispatch(fetchChassis());
    }
  }, [
    chassisStatus,
  ]);

  useEffect(() => {
    loadData();
  }, []);

  const maintenanceRequestFetchError = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.error,
  );

  const [selectedRequests, setSelectedRequests] = useState([]);

  const [createModalShow, setCreateModalShow] = useState(false);

  const [filterOpen, setFilterOpen] = useState(false);

  const [showActiveLocation, setShowActiveLocations] = useState(true);

  const handleCreateModalClose = () => {
    setCreateModalShow(false);
  };
  const handleCreateModalShow = (assetType) => {
    setCreateModalShow(true);
  };

  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );

  const [currentPage, chassisTable, chassisTableInstance] = useTablePageRef();

  const mappedPage = currentPage?.map((r) => r.original.serialNumber);

  useEffect(() => {
    if (chassisWithLocationInfo) {
      dispatch(fetchMaintenanceStatusForChassis(
        { assetTypeId: 4, assetIds: Object.keys(chassisWithLocationInfo) },
      ));
    }
  }, [JSON.stringify(chassisWithLocationInfo)]);

  const chassisData = useMemo(() => {
    if (chassisWithLocationInfo) {
      return Object.values(chassisWithLocationInfo)
        .sort((a, b) => sortByBasic(new Date(a.modified), new Date(b.modified))).reverse();
    }
    return [];
  }, [chassisWithLocationInfo]);

  const handleFilterChange = (event, filterId) => {
    chassisTable.setFilter(filterId, event.target.checked);
  };

  const handleSelectFilterChange = (event, filterId) => {
    chassisTable.setFilter(filterId, event.target.value);
  };

  const setActiveLocationFilter = () => {
    chassisTable.setFilter('currentLocationInfo.LocationID', currentUserActiveLocation.LocationID);
  };

  const removeActiveLocationFilter = () => {
    chassisTable.setFilter('currentLocationInfo.LocationID', '');
  };

  const handleLocationFilterChange = (event, filterId) => {
    if (showActiveLocation === false) {
      setActiveLocationFilter();
      localStorage.setItem('showChassisActiveLocation', JSON.stringify(!showActiveLocation));
      setShowActiveLocations(!showActiveLocation);
    } else {
      removeActiveLocationFilter();
      localStorage.setItem('showChassisActiveLocation', JSON.stringify(!showActiveLocation));
      setShowActiveLocations(!showActiveLocation);
    }
  };

  const setLocalStorage = (v) => {
    localStorage.setItem('showChassisActiveLocation', JSON.stringify(v));
  };

  useEffect(() => {
    // Make sure we don't attempt to filter before the data has been loaded
    if (chassisTable && chassisStatus === 'succeeded') {
      // Depending on if we have anything stored in activeLocation, we filter accordingly.
      if (localStorage.getItem('showChassisActiveLocation') === null) {
        if (currentUserActiveLocation) {
          setLocalStorage(true);
        } else {
          setLocalStorage(false);
        }
      }
      if (localStorage.getItem('showChassisActiveLocation') === 'true' && currentUserActiveLocation) {
        if (showActiveLocation === false) {
          setShowActiveLocations(!showActiveLocation);
        }
        setActiveLocationFilter();
      } else {
        if (showActiveLocation === true) {
          setShowActiveLocations(!showActiveLocation);
        }
        removeActiveLocationFilter();
      }
    }
  }, [chassisStatus, currentUserActiveLocation, chassisTable]);

  const maintenanceColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'expander',
        accessor: 'serialNumber',
        disableSortBy: true,
        width: 50,
        Cell: ({ value, row }) => (
          <div className="d-flex">
            <div className="w-50">
              <Badge rounded="true">
                {chassisMaintenanceStatuses[value]?.totalMaintenanceRequests ?? 0}
              </Badge>
            </div>
            {chassisMaintenanceStatuses[value] && (
              <>
                &nbsp;
                <div className="w-50">
                  <span {...row.getToggleRowExpandedProps()}>
                    <i className={`bi-chevron-right ${row.isExpanded ? 'expanded-chevron' : ''}`} />
                  </span>
                </div>
              </>
            )}
          </div>
        ),
      },
      {
        Header: 'Unit Number',
        accessor: 'serialNumber',
        Cell: ({ value }) => (<NavLink to={`/assets/chassis/${value}#maintenance`}>{value}</NavLink>),
      },
      {
        Header: '',
        accessor: 'serialNumber',
        id: 'maintenanceStatus',
        filter: 'exactText',
        sortType: (a, b) => {
          const aStatus = chassisMaintenanceStatuses[a.values.serialNumber]?.maintenanceStatus ?? '';
          const bStatus = chassisMaintenanceStatuses[b.values.serialNumber]?.maintenanceStatus ?? '';

          const aValue = maintenanceStatusValues[aStatus];
          const bValue = maintenanceStatusValues[bStatus];
          if (chassisMaintenanceFetchStatus === 'loading') {
            return 0;
          }
          return sortByBasic(aValue, bValue);
        },
        Cell: ({ value }) => {
          const status = chassisMaintenanceStatuses[value]?.maintenanceStatus ?? '';
          if (status === 'Red') {
            return <RedFlagAlert redReasons={chassisMaintenanceStatuses[value]?.redReason} wos={chassisMaintenanceStatuses[value]?.wo} value={value} />;
          }
          if (status === 'Yellow') {
            return <YelloIcon />;
          }
          if (chassisMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return '';
        },
      },
      {
        Header: 'Maintenance Facility',
        accessor: 'currentLocationInfo.LocationID',
        Cell: ({ value }) => (
          <span>
            {value in locations ? locations[value].LocationName
              : ''}
          </span>
        ),
      },
      {
        Header: 'Arrival Date',
        accessor: 'currentLocationInfo.ArriveDate',
        sortType: sortByDateField,
        Cell: ({ value }) => (value ? formatDate(value, datePreference) : ''),
      },
      {
        Header: 'Scheduled Departure Date',
        accessor: 'currentLocationInfo.ShipDate',
        sortType: sortByDateField,
        Cell: ({ value }) => (value ? formatDate(value, datePreference) : ''),
      },
      {
        Header: 'Next A Inspection',
        accessor: 'serialNumber',
        id: 'AInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = chassisMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['A Inspection'] ?? '';
          const maintenanceDate2 = chassisMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['A Inspection'] ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = chassisMaintenanceStatuses[value]?.maintenanceDates?.['A Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next DOT Inspection',
        accessor: 'serialNumber',
        id: 'BInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = chassisMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['DOT Inspection'] ?? '';
          const maintenanceDate2 = chassisMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['DOT Inspection'] ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = chassisMaintenanceStatuses[value]?.maintenanceDates?.['DOT Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next Rehab',
        accessor: 'serialNumber',
        id: 'lastRebuildDate',
        sortType: (a, b) => {
          const maintenanceDate = chassisMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.Rehab ?? '';
          const maintenanceDate2 = chassisMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.Rehab ?? '';
          return sortByDateCustom(maintenanceDate?.dueDate, maintenanceDate2?.dueDate);
        },
        Cell: ({ value }) => {
          const maintenanceDate = chassisMaintenanceStatuses[value]?.maintenanceDates?.Rehab ?? '';
          return (
            <NextDueDate
              dueDate={maintenanceDate?.dueDate}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Owner',
        accessor: 'ownerCustomer',
      },
      {
        Header: 'Length',
        accessor: 'length',
      },
      {
        Header: 'Type',
        accessor: 'type',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
      },
      {
        Header: 'Skikda Authorized',
        accessor: 'isSkikdaAuthorized',
      },
    ],
    [locations, chassisMaintenanceStatuses, chassisMaintenanceFetchStatus],
  );

  const hiddenColumns = [
    'ownerCustomer',
    'length',
    'type',
    'isActive',
    'isSkikdaAuthorized',
  ];

  const renderRowSubComponent = useCallback(({ row }) => (
    <MaintenanceRequestList
      isExpanded={row?.isExpanded}
      assetSerialNumber={row.original.serialNumber}
      assetTypeId={4}
      expectedSize={chassisMaintenanceStatuses[
        row.original.serialNumber]?.totalMaintenanceRequests || 0}
      handleWorkOrderModalShow={props.handleWorkOrderModalShow}
    />
  ), [chassisMaintenanceStatuses]);

  const chassisContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={chassisTable} hookInstance />
          <Button variant="primary" size="sm" disabled={!(selectedRequests.length > 0)} onClick={handleCreateModalShow}>Add Maintenance Request</Button>
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'ownerCustomer'); }}>
                  <option value="" key="customer-null">-- Owner --</option>
                  {chassisCustomersArr.map((customer, index) => (
                    <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'length'); }}>
                  <option value="" key="length-null">-- Length --</option>
                  {chassisLength.map((length, index) => (
                    <option value={index} key={`length-${index}`}>{length}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'type'); }}>
                  <option value="">-- Type --</option>
                  {chassisTypes.map((type, i) => (
                    <option value={i} key={type}>{type}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              {currentUserActiveLocation.LocationName ? (
                <Form.Check
                  type="switch"
                  className="mb-1"
                  inline
                  id="location-switch"
                  label={showActiveLocation ? currentUserActiveLocation.LocationName : 'All Locations'}
                  checked={showActiveLocation}
                  onChange={(e) => handleLocationFilterChange(e, 'showActiveLocation')}
                />
              ) : null}
              <Form.Check
                className="mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Active"
                defaultChecked
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
              <Form.Check
                type="switch"
                className="ms-1 mb-1"
                inline
                id="skikda-switch"
                label="Skikda"
                onChange={(e) => handleFilterChange(e, 'isSkikdaAuthorized')}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <ExpandablePaginatedRowSelectTable
        columns={maintenanceColumns}
        data={chassisData}
        ref={chassisTableInstance}
        initialState={{ hiddenColumns, filters: [{ id: 'isActive', value: true }] }}
        renderRowSubComponent={renderRowSubComponent}
        onRowSelect={(rows) => setSelectedRequests(rows)}

      />
    </>
  );

  const requestsAreLoading = [
    maintenanceRequestFetchStatus,
    assetLocationFetchStatus,
    chassisStatus,
  ].includes('loading');

  const requestsHaveError = [
    maintenanceRequestFetchStatus,
    assetLocationFetchStatus,
    chassisStatus,
  ].includes('error');

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${requestsAreLoading ? ' creation-loading' : ''}`}>
        {chassisContent}
      </div>
      {requestsAreLoading && <LoadingContent />}

      <CreateRequestForm
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType={props.createModalAssetType}
        selectedRequests={selectedRequests}
        asset={props.asset}
      />
    </>
  );
}

export default ChassisRequestsTable;
