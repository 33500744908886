/* eslint-disable */
import './line-items.scss';

import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Card, Col, Container,
  Form, ListGroup, OverlayTrigger, Row, Spinner, Tooltip,
} from 'react-bootstrap';
import {
  Typeahead,
} from 'react-bootstrap-typeahead';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import {
  BsArrowCounterclockwise,
  BsCheckLg, BsExclamationTriangle, BsFillArrowRightCircleFill,
} from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  bulkUpdateMainteanceRequestLineItems,
  deleteMaintenanceRequestLineItem,
  undoMaintenanceRequestLineItem,
  updateMaintenanceRequestLineItemStatus,
  bulkUpdateAndCompleteMaintenanceRequestLineItems,
  updateMaintenanceRequestLineItem,
} from '../../../store/slices/maintenance-request-line-items-slice';
import { selectMaintenanceRequestDropdownValues, selectMaintenanceRequests } from '../../../store/slices/maintenance-requests-slice';
import { selectParts } from '../../../store/slices/parts-slice';
import { selectUsers } from '../../../store/slices/users-slice';
import { selectCurrentWorkOrder } from '../../../store/slices/work-orders-slice';
import Table from 'react-bootstrap/Table';
import {Tooltip as CustomTooltip} from '../../UI/atoms/Tooltip/Tooltip';

function MaintenanceRequestLineItemList({ maintenanceRequestId, forWorkOrder }) {
  const [disableSave, setDisableSave] = useState(false);
  const maintenanceRequests = useSelector(selectMaintenanceRequests);
  const maintenanceRequestLineItems = (maintenanceRequests[maintenanceRequestId].maintenanceRequestLineItems ?? []).filter(
    (li) => li.isActive,
  );

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const lineItemCreateStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemCreate.status,
  );

  const lineItemDeleteStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemDelete.status,
  );

  const lineItemBulkUpdateStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemsBulkUpdate.status,
  );

  const lineItemBulkUpdateAndCompleteStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.status,
  );

  const lineItemStatusUpdateStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemStatusUpdate.status,
  );

  const createLineItemMaintenanceRequestId = useSelector(
    (state) => state.maintenanceRequestLineItem
      .maintenanceRequestLineItemCreate.currentMaintenanceRequestId,
  );

  const deleteLineItemMaintenanceRequestId = useSelector(
    (state) => state.maintenanceRequestLineItem
      .maintenanceRequestLineItemDelete.currentMaintenanceRequestId,
  );

  const lineItemBulkUpdateMaintenanceRequestId = useSelector(
    (state) => state.maintenanceRequestLineItem
      .maintenanceRequestLineItemsBulkUpdate.currentMaintenanceRequestId,
  );
  const workOrderLineItemCreateLoading = useSelector(
    (state) => state.maintenanceRequestLineItem
      .workOrderLineItemCreateLoading,
  );

  const lineItemStatusUpdateMaintenanceRequestId = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemStatusUpdate
      .currentMaintenanceRequestId,
  );

  const { maintenanceRequestLineItemTypes } = useSelector(selectMaintenanceRequestDropdownValues);

  const currentWorkOrder = useSelector(selectCurrentWorkOrder);

  const lineItemCreateIsLoading = (lineItemCreateStatus === 'loading'
    && createLineItemMaintenanceRequestId === maintenanceRequestId)
    || (forWorkOrder && workOrderLineItemCreateLoading);

  const lineItemDeleteIsLoading = (lineItemDeleteStatus === 'loading'
    && deleteLineItemMaintenanceRequestId === maintenanceRequestId);

  const lineItemBulkUpdateIsLoading = (lineItemBulkUpdateStatus === 'loading'
    && lineItemBulkUpdateMaintenanceRequestId === maintenanceRequestId);

  const lineItemStatusUpdateIsLoading = (lineItemStatusUpdateStatus === 'loading'
    && lineItemStatusUpdateMaintenanceRequestId === maintenanceRequestId);

  const lineItemBulkUpdateAndCompleteIsLoading = (lineItemBulkUpdateAndCompleteStatus === 'loading'
    && lineItemBulkUpdateMaintenanceRequestId === maintenanceRequestId);

  const lineItemsListIsLoading = lineItemCreateIsLoading
    || lineItemDeleteIsLoading || lineItemBulkUpdateIsLoading
    || lineItemStatusUpdateIsLoading || lineItemBulkUpdateAndCompleteIsLoading;

  const users = useSelector(selectUsers);

  const activeTechnicianUsersData = useMemo(() => {
    if (users) {
      return [...Object.values(users).filter((u) => u.isActive
        && u.roles.some((r) => r.roleId === 3 || r.roleId === 4))];
    }

    return [];
  }, [users]);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const parts = useSelector(selectParts);

  const lineItemListDefaultValues = {
    lineItems: [],
  };
  const lineItemDefaultValues = {
    description: null,
    partId: null,
    isActive: null,
    laborBillOutCostPerHour: null,
    maintenanceRequestLineItemTypeId: null,
    maintenanceRequestLineItemId: null,
    mechanicUserId: null,
    partsBillOutCost: null,
    quantity: null,
    startingmTorr: null,
    endingmTorr: null,
    duration: null,
    isCompleted: null,
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
    trigger,
  } = useForm({
    defaultValues: lineItemListDefaultValues,
  });

  const formName = `lineItems${maintenanceRequestId}`;

  const {
    fields,
    append,
    remove,
    update,
  } = useFieldArray({
    control,
    watch,
    name: formName,
  });

  const onSubmit = (formContents) => {
    dispatch(bulkUpdateMainteanceRequestLineItems({
      maintenanceRequestId,
      lineItems: formContents[formName],
    })).unwrap().then((data) => {
      data = data.filter((lineItem) => lineItem.isActive === true);
      for (let i = 0; i < data.length; i += 1) {
        const updateIndex = fields.findIndex(
          (element) => element.maintenanceRequestLineItemId
          === data[i].maintenanceRequestLineItemId,
        );
        update(updateIndex, data[i]);
      }
    });
  };

  const onSaveandConsumeAll = (formContents) => {
    dispatch(bulkUpdateMainteanceRequestLineItems({
      maintenanceRequestId,
      lineItems: formContents[formName],
    })).unwrap().then((data) => {
      data = data.filter((lineItem) => lineItem.isActive === true);
      for (let i = 0; i < data.length; i += 1) {
        const updateIndex = fields.findIndex(
          (element) => element.maintenanceRequestLineItemId
          === data[i].maintenanceRequestLineItemId,
        );
        update(updateIndex, data[i]);
      }
      dispatch(bulkUpdateAndCompleteMaintenanceRequestLineItems({
        maintenanceRequestId,
        lineItems: formContents[formName],
      })).unwrap().then((data) => {
        data = data.filter((lineItem) => lineItem.isActive === true);
        for (let i = 0; i < data.length; i += 1) {
          const updateIndex = fields.findIndex(
            (element) => element.maintenanceRequestLineItemId
            === data[i].maintenanceRequestLineItemId,
          );
          update(updateIndex, data[i]);
        }
      });
    });   
  };

  const deleteLineItem = (maintenanceRequestLineItemId, index) => {
    if (lineItemsListIsLoading || disableSave) {
      return;
    }
    dispatch(deleteMaintenanceRequestLineItem({
      maintenanceRequestId,
      lineItemId: maintenanceRequestLineItemId,
    })).unwrap().then(() => {
      remove(index);
      setDisableSave(false);
    });
  };

  const completeLineItemLineItem = (lineItemUnedited, index) => {
    setDisableSave(true);
    if (lineItemsListIsLoading || disableSave) {
      return;
    }
    const lineItemEdited = getValues()[formName][index];
    dispatch(updateMaintenanceRequestLineItem({
      lineItem: lineItemEdited,
      maintenanceRequestId, 
    })).unwrap().then((data) => {
      update(index, data);
      data.isCompleted = true;
      dispatch(updateMaintenanceRequestLineItemStatus({
        maintenanceRequestId,
        lineItem: data,
      })).unwrap().then((data) => {
        update(index, data);
        setDisableSave(false);
      });
    });
  };

  const undoLineItem = (lineItemUnedited, index) => {
    setDisableSave(true);
    if (lineItemsListIsLoading || disableSave) {
      return;
    }
    const lineItem = { ...lineItemUnedited };
    lineItem.isCompleted = false;
    dispatch(undoMaintenanceRequestLineItem({
      maintenanceRequestId,
      lineItem,
    })).unwrap().then((data) => {
      update(index, data);
      setDisableSave(false);
    });
  };

  useEffect(() => {
    if (maintenanceRequestLineItems && !lineItemsListIsLoading) {
      maintenanceRequestLineItems.forEach((item, index) => {
        const inFields = (fields.find(
          (element) => element.maintenanceRequestLineItemId === item.maintenanceRequestLineItemId,
        ));
        if (!inFields) {
          append(
            {
              description: item?.description,
              partId: item?.partId,
              isActive: item?.isActive,
              laborBillOutCostPerHour: item?.laborBillOutCostPerHour,
              maintenanceRequestLineItemTypeId: item?.maintenanceRequestLineItemTypeId,
              maintenanceRequestLineItemId: item?.maintenanceRequestLineItemId,
              mechanicUserId: item?.mechanicUserId,
              partsBillOutCost: item?.partsBillOutCost,
              quantity: item?.quantity,
              startingmTorr: item?.startingmTorr,
              endingmTorr: item?.endingmTorr,
              duration: item?.duration,
              isCompleted: item?.isCompleted,
            },
          );
        }
      });
    }
  }, [maintenanceRequestLineItems, lineItemsListIsLoading]);

  const partsLineItems = maintenanceRequestLineItems.filter(
    (li) => li.maintenanceRequestLineItemTypeId === 1,
  ).length;

  const laborLineItems = maintenanceRequestLineItems.filter(
    (li) => li.maintenanceRequestLineItemTypeId === 2,
  ).length;

  const vacuumLineItems = maintenanceRequestLineItems.filter(
    (li) => li.maintenanceRequestLineItemTypeId === 3,
  ).length;

  const workOrderIsClosed = currentWorkOrder?.workOrderStatusId === 5;

  const quantityTooHighTooltip = (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id="button-tooltip">
          Line Item Cannot Be Completed: Quantity Requested is higher than Quantity Available
        </Tooltip>
      )}
    >
      <span>
        <BsExclamationTriangle className="text-danger" />
      </span>
    </OverlayTrigger>
  );

  const quantityWillBeZeroTooltip = (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id="button-tooltip">
          Quantity Requested will bring quantity in inventory to zero
        </Tooltip>
      )}
    >
      <span>
        <BsExclamationTriangle className="text-warning" />
      </span>
    </OverlayTrigger>
  );

  const tableContent = (
    <Table size='sm'>
      <thead>
        <tr>
          <th></th>
          <th>Type</th>
          <th></th>
          <th></th>
          <th style={{width: '30%'}}>Description</th>
        </tr>
      </thead>
      <tbody>
        {fields
          .map((lineItem, index) => (
            <tr key={lineItem.id}>
              <td style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>

                  <Button
                    style={{ marginRight: '2px' }}
                    size="xsm"
                    variant="danger"
                    title="Delete"
                    disabled={lineItem.isCompleted || lineItemsListIsLoading || disableSave}
                    onClick={() => deleteLineItem(
                      lineItem.maintenanceRequestLineItemId,
                      index,
                    )}
                  >
                    <AiFillDelete />
                  </Button>
                  {!lineItem.isCompleted
                  && (
                  <Button
                    size="xsm"
                    variant="success"
                    title="Complete Line Item"
                    disabled={lineItem.isCompleted || lineItemsListIsLoading || disableSave}
                    onClick={() => {
                      completeLineItemLineItem(
                        lineItem,
                        index,
                      );
                    }}
                  >
                    <BsCheckLg />
                  </Button>
                  )}
                  {lineItem.isCompleted
                  && (
                  <Button
                    size="xsm"
                    variant="success"
                    title="Undo Line Item Completion"
                    disabled={lineItemsListIsLoading || disableSave}
                    onClick={() => {
                      undoLineItem(
                        lineItem,
                        index,
                      );
                    }}
                  >
                    <BsArrowCounterclockwise />
                  </Button>
                  )}
             
              </td>
              <td style={{paddingTop: 0, paddingBottom: 0}}>
                <Form.Select
                  defaultValue={lineItem.maintenanceRequestLineItemTypeId}
                  size="sm"
                  disabled
                  {...register(`${formName}.${index}.maintenanceRequestLineItemTypeId`)}
                >
                  {maintenanceRequestLineItemTypes.map((type, i) => (
                    <option
                      value={type.id}
                      key={`lineItemType-${type.id}`}
                    >
                      {type.name}
                    </option>
                  ))}
                </Form.Select>
              </td>
              <td style={{display: 'flex', paddingTop: 0, paddingBottom: 0}}>
                {(lineItem.maintenanceRequestLineItemTypeId === 1) && (    
                  <>      
                  <Controller
                    defaultValue={lineItem.partId}
                    key={lineItem.id}
                    control={control}
                    rules={{ required: true }}
                    name={`${formName}.${index}.partId`}
                    render={({
                      field: { onChange, value, ref },
                      fieldState: {
                        isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                        <Typeahead
                          style={{flexGrow: 1}}
                          inputProps={{ title: 'Part' }}
                          key={lineItem.id}
                          defaultValue={lineItem.partId}
                          ref={ref}
                          value={value}
                          {...register(`${formName}.${index}.partId`)}
                          disabled={lineItem.partId || workOrderIsClosed}
                          onChange={(e) => {
                            onChange(e[0]?.partId);
                          }}
                          flip
                          size="sm"
                          id={`${formName}.${index}.partId`}
                          labelKey={(selected) => `${selected.partName} (Quantity Available: ${selected.quantityOnHand})`}
                          menuContainerStyle={{ zIndex: 5 }}
                          options={Object.values(parts)
                            .sort((a,b) => (a.partName ?? '').trim().localeCompare((b.partName ?? '').trim()))
                            .filter(
                              (p) => (p?.locationId === currentWorkOrder?.locationId
                              && p.isActive === true)
                              || p.partName === lineItem?.part?.partName,
                            )}
                          positionFixed
                          isInvalid={!!error}
                          placeholder={lineItem?.partId ? `${Object.values(parts).find(
                            (p) => p.partId === lineItem?.partId,
                          )?.partName} (Quantity Available: ${Object.values(parts).find(
                            (p) => p.partId === lineItem?.partId,
                          )?.quantityOnHand})` : ''}
                        />
                    )}
                  />
                  {lineItem?.partId
                    && <BsFillArrowRightCircleFill style={{marginTop:6}} disabled={!lineItem?.partId} title="Go to Part" onClick={(e) => (navigate(`/maintenance/parts-inventory/${lineItem?.partId}`))} className="btn-link" />}
                    {' '}
                    {!lineItem.isCompleted && (Object.values(parts).find(
                      (p) => p.partId === lineItem?.partId,
                    )?.quantityOnHand < lineItem.quantity)
                    && (
                    <div className="d-inline-flex">
                      { quantityTooHighTooltip }
                    </div>
                    )}
                    {!lineItem.isCompleted && (Object.values(parts).find(
                      (p) => p.partId === lineItem?.partId,
                    )?.quantityOnHand === parseInt(lineItem.quantity, 10))
                    && (
                    <div className="d-inline-flex">
                      { quantityWillBeZeroTooltip }
                    </div>
                    )}
                  </>
                  
                )}
                {lineItem.maintenanceRequestLineItemTypeId === 2 && (
                  <CustomTooltip title="Technician">
                    <Form.Select
                      defaultValue={lineItem.mechanicUserId}
                      key={lineItem.id}
                      size="sm"
                      {...register(`${formName}.${index}.mechanicUserId`)}
                      isInvalid={!!errors.mechanicUserId}
                      disabled={lineItem.isCompleted}
                    >
                      {activeTechnicianUsersData.map((type, i) => (
                        <option value={type.userId} key={`user-${i}`}>{type.displayName}</option>
                      ))}
                    </Form.Select>
                  </CustomTooltip>
                  
                )}
                {lineItem.maintenanceRequestLineItemTypeId === 3 && (
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <CustomTooltip title="Start mTorr">
                        <Form.Control defaultValue={lineItem.startingmTorr} size="sm" {...register(`${formName}.${index}.startingmTorr`)} disabled={lineItem.isCompleted} />
                      </CustomTooltip>           
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <CustomTooltip title="End mTorr">
                        <Form.Control size="sm" defaultValue={lineItem.endingmTorr} {...register(`${formName}.${index}.endingmTorr`)} disabled={lineItem.isCompleted} />
                      </CustomTooltip>              
                    </Col>            
                  </Row>       
                )}
              </td>
              <td style={{paddingTop: 0, paddingBottom: 0}}>
                {(lineItem.maintenanceRequestLineItemTypeId === 1) && (
                  <CustomTooltip title="Quantity">
                    <Form.Control defaultValue={lineItem.quantity} disabled={lineItem.isCompleted} size="sm" {...register(`${formName}.${index}.quantity`)} />
                  </CustomTooltip>                 
                )}
                {lineItem.maintenanceRequestLineItemTypeId === 2 && (
                  <Row>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <CustomTooltip title="Hours">
                        <Form.Control defaultValue={lineItem.quantity} size="sm" {...register(`${formName}.${index}.quantity`)} disabled={lineItem.isCompleted} />
                      </CustomTooltip>                 
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <CustomTooltip title="$/hr">
                        <Form.Control defaultValue={lineItem.laborBillOutCostPerHour} size="sm" {...register(`${formName}.${index}.laborBillOutCostPerHour`)} disabled={lineItem.isCompleted} />
                      </CustomTooltip>                  
                    </Col>            
                  </Row>       
                )}
                {lineItem.maintenanceRequestLineItemTypeId === 3 && (
                  <CustomTooltip title="Duration">
                    <Form.Control size="sm" defaultValue={lineItem.duration} {...register(`${formName}.${index}.duration`)} disabled={lineItem.isCompleted} /> 
                  </CustomTooltip>                     
                )}
              </td>
              <td style={{paddingTop: 0, paddingBottom: 0}}>
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id="button-tooltip">
                      {lineItem.description}
                    </Tooltip>
                  )}
                >
                  <Form.Control defaultValue={lineItem.description} disabled={lineItem.isCompleted} size="sm" {...register(`${formName}.${index}.description`)} />
                </OverlayTrigger>
              </td>
            </tr>
          ))
        }
      </tbody>
    </Table>
  );

  return (

    <div className="position-relative">
      <Spinner animation="border" variant="primary" className={`create-spinner${lineItemsListIsLoading ? ' visible' : ' invisible'}`} />
      <div className={`p-2 ${lineItemsListIsLoading ? ' creation-loading' : ''}`}>
        <Row className="m-0">
          <Col>
            <h6 style={{ fontSize: '14px' }}>
              Line Items (Parts:
              {' '}
              {partsLineItems}
              {' '}
              Labor:
              {' '}
              {laborLineItems}
              {' '}
              Vacuum:
              {' '}
              {vacuumLineItems}
              {' '}
              )
            </h6>
          </Col>
          <Col className="mb-1">
            {!workOrderIsClosed && (
              <Row className="m-0">
                <Col style={{paddingRight: 0}}>             
                  <Button size="sm" className="float-right my-1" style={{ fontSize: '12px !important' }} onClick={handleSubmit(onSubmit)}>
                    Save Changes
                  </Button>
                  <Button size="sm" className="float-right my-1" style={{ fontSize: '12px !important', marginRight: 2 }} onClick={handleSubmit(onSaveandConsumeAll)}>
                    Save and Consume All
                  </Button>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
        <Card className="line-items-list-container m-0">
          {tableContent}
        </Card>
      </div>
    </div>
  );
}

export default MaintenanceRequestLineItemList;
