import axios from "axios";
import { handleRequestError, memoizePromiseFn } from "../../requests";

/* GetBillingScheduleList */
export const GetBillingScheduleList = async () => {
  const results = await axios.get('/MiscBilling/GetBillingSchedules').catch(handleRequestError);
  return results.data;
}
export const UpdateBillingSchedule = async (schedule) => {
  const results = await axios.put('/MiscBilling/UpdateBillingSchedule', schedule).catch(handleRequestError);
  return results.data;
}
export const InsertBillingSchedule = async (schedule) => {
  const results = await axios.post('/MiscBilling/InsertBillingSchedule', schedule).catch(handleRequestError);
  return results.data;
}
export const InsertBilling = async (billing) => {
  const results = await axios.post('/MiscBilling/InsertBilling', billing).catch(handleRequestError);
  return results.data;
}

export const GetMiscBillingList = async (queryDefinition) => {
  const results = await axios.post('/MiscBilling/GetMiscBillingList', queryDefinition).catch(handleRequestError);
  return results.data;
}

export const deleteInvoice = async (invoiceId) => {
  const results = await axios.delete(`/MiscBilling/DeleteInvoice/${invoiceId}`).catch(handleRequestError);
  return results.data;
};
// /PreAuthorizeCreditCard
export const PreAuthorizeCreditCard = async (billingId) => {
  const results = await axios.get(`/MiscBilling/PreAuthorizeCreditCard/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const getBillingHeader = async (billingId) => {
  const results = await axios.get(`/MiscBilling/getBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const reverseBilling = async (reverse) => {
  const results = await axios.post(`/MiscBilling/reverseBilling`, reverse).catch(handleRequestError);
  return results.data;
};

export const uploadInvoicesJDEDTO = async (invoice) => {
  const results = await axios.post(`/MiscBilling/UploadInvoicesToJDE`, invoice).catch(handleRequestError);
  return results.data;
};

export const InsertBillingItemType = async (type) => {
  const results = await axios.post('/MiscBilling/InsertBillingItemType', type).catch(handleRequestError);
  return results.data;
};
export const UpdateBillingItemType = async (type) => {
  const results = await axios.put('/MiscBilling/UpdateBillingItemType', type).catch(handleRequestError);
  return results.data;
};
export const GetBillingItemTypes = async () => {
  const results = await axios.get('/MiscBilling/GetBillingItemTypes').catch(handleRequestError);
  return results.data;
};
export const getBillingItemTypes = memoizePromiseFn(GetBillingItemTypes);
export const sendMiscReport = async (report) => {
  const results = await axios.post('/MiscBilling/SendMiscReport', report).catch(handleRequestError);
  return results.data;
};
export const downloadReport = async (report) => {
  const results = await axios.post('/MiscBilling/DownloadReport', report, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', report.filename);
  document.body.appendChild(link);
  link.click();
};
export const GetJDEErrors = async (billing) => {
  const results = await axios.get('/MiscBilling/GetJDEErrors').catch(handleRequestError);
  return results.data;
}
export const GetUnbilledInvoices = async (billing) => {
  const results = await axios.get('/MiscBilling/GetUnbilledInvoices').catch(handleRequestError);
  return results.data;
}

export const UpdateBilling = async (billing) => {
  const results = await axios.put('/MiscBilling/UpdateBilling', billing).catch(handleRequestError);
  return results.data;
}