/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col, Container, Form, Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { checkFormIsDirty } from '../../../services/form-validation';
import { splitStringOnUppercase } from '../../../services/format';
import { selectIsAdmin, selectIsAssetAdmin, selectIsMaintenanceManager } from '../../../store/slices/auth-slice';
import { selectAllActiveLocationObjects } from '../../../store/slices/legacy-slice';
import { selectMaintenanceRequestDropdownObjects } from '../../../store/slices/maintenance-requests-slice';
import { updatePart } from '../../../store/slices/parts-slice';
import Card from '../../UI/molecules/Card/card';

function PartForm({
  currentPart, partStatusIsLoading,
}) {
  const defaultPartFormValues = {
    partId: '',
    locationId: null,
    binNumber: null,
    partName: null,
    quantityOnHand: null,
    minQuantity: null,
    maxQuantity: null,
    reorderLevel: null,
    partDescription: null,
    supplierPartDescription: null,
    isNonStockItem: null,
    isActive: null,
    assetTypeId: null,
    partCodeGlobal: null,
    partCodeLocal: null,
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty, dirtyFields },
    getFieldState,
  } = useForm({
    defaultValues: defaultPartFormValues,
  });

  const dispatch = useDispatch();
  const [formIsDirty, setFormIsDirty] = useState(false);
  const activeLocations = useSelector(selectAllActiveLocationObjects);
  const maintenanceDropdownValues = useSelector(selectMaintenanceRequestDropdownObjects);

  const isAdmin = useSelector(selectIsAdmin);
  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);

  useEffect(() => {
    const subscription = watch((value) => {
      setFormIsDirty(checkFormIsDirty(value, currentPart));
    });
    return () => subscription.unsubscribe();
  }, [watch, currentPart]);

  // form will only be used to update b/c we will create a creat specific modal
  const onSubmit = (updatedPart) => {
    dispatch(updatePart({ ...currentPart, ...updatedPart, assetType: 'Part' }));
    setFormIsDirty(false);
  };

  // Populate form with default values
  useEffect(() => {
    if (currentPart) {
      Object.keys(defaultPartFormValues).forEach((key) => {
        if (key !== 'isIgnoreCscDates' && key.toLowerCase().includes('date') && currentPart[key]) {
          const dateObj = new Date(currentPart[key].replace('Z', ''));
          if (Number.isNaN(dateObj.getTime())) {
            setValue(key, null);
          } else {
            setValue(key, dateObj);
          }
        } else {
          setValue(key, currentPart[key]);
        }
      });
    }
  }, [currentPart]);

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  return (
    <>
      <Form className="overflow-auto pt-3 flex-grow-1">
        <Spinner animation="border" variant="primary" className={`create-spinner${partStatusIsLoading ? ' visible' : ' invisible'}`} />
        <fieldset disabled={!isAssetAdmin}>
          <Container fluid className={` ${partStatusIsLoading ? ' creation-loading' : ''}`}>
            <Row>
              <Col>
                <Card header="Info" className="card-secondary card-outline">
                  <Container>
                    <Row>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Part Name</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={currentPart.partName}
                            {...register('partName')}
                          />
                          <Form.Label>Global Part Code</Form.Label>
                          <Form.Control
                            size="sm"
                            {...register('partCodeGlobal', { required: 'Please enter a global part code.' })}
                            disabled={(!isMaintenanceManager && !isAdmin) || currentPart?.partCodeGlobal}
                            isInvalid={!!errors.partCodeGlobal}
                          />

                          <Form.Label>Location</Form.Label>
                          <Form.Control
                            size="sm"
                            disabled
                            placeholder={currentPart.locationId && currentPart.locationId in activeLocations ? activeLocations[currentPart.locationId].LocationName : 'N/A'}
                          />
                          <Form.Label>Quantity on Hand</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={currentPart.quantityOnHand}
                            disabled
                          />
                          <Form.Label>LastCost</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={currentPart.lastCost}
                            disabled
                          />
                          <Form.Label>Asset Type</Form.Label>
                          <Form.Control
                            size="sm"
                            disabled
                            placeholder={currentPart.assetTypeId && currentPart.assetTypeId in maintenanceDropdownValues.assetTypes ? splitStringOnUppercase(maintenanceDropdownValues.assetTypes[currentPart.assetTypeId].name) : 'N/A'}
                          />
                          <br />
                          <Form.Check
                            {...register('isNonStockItem')}
                            type="switch"
                            inline
                            id="isNonStockItem-switch"
                            label="Is Non-Stock Item"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-1">
                          <Form.Label>Maximum Quantity</Form.Label>
                          <Form.Control
                            size="sm"
                            type="number"
                            placeholder={currentPart.maxQuantity}
                            {...register(
                              'maxQuantity',
                              {
                                required: 'Please enter a max quantity.',
                                min: { value: 0, message: 'Max quantity must be non-negative' },
                                validate: {
                                  greaterThanMinQuantity: (val) => {
                                    if (val !== null && getValues().minQuantity !== null) {
                                      return parseInt(val, 10) >= parseInt(getValues().minQuantity, 10) || 'Max quantity must be greater than min quantity';
                                    }
                                    return null;
                                  },
                                },
                              },
                            )}
                            isInvalid={!!errors.maxQuantity}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.maxQuantity && errors.maxQuantity.message}
                          </Form.Control.Feedback>
                          <Form.Label>Local Part Code</Form.Label>
                          <Form.Control size="sm" {...register('partCodeLocal')} />
                          <Form.Label>Reorder Level</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={currentPart.reorderLevel}
                            {...register('reorderLevel')}
                          />
                          <Form.Label>Part Description</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={currentPart.partDescription}
                            {...register('partDescription')}
                          />
                          <Form.Label>Supplier Part Description</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={currentPart.supplierPartDescription}
                            {...register('supplierPartDescription')}
                          />
                          <Form.Label>Minimum Quantity</Form.Label>
                          <Form.Control
                            size="sm"
                            type="number"
                            placeholder={currentPart.minQuantity}
                            {...register(
                              'minQuantity',
                              {
                                required: 'Please enter a min quantity.',
                                min: { value: 0, message: 'Min quantity must be non-negative' },
                                validate: {
                                  lessThanMaxQuantity: (val) => {
                                    if (val !== null && getValues().maxQuantity !== null) {
                                      return parseInt(val, 10) <= parseInt(getValues().maxQuantity, 10) || 'Min quantity must be less than max quantity';
                                    }
                                    return null;
                                  },
                                },
                              },
                            )}
                            isInvalid={!!errors.minQuantity}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.minQuantity && errors.minQuantity.message}
                          </Form.Control.Feedback>
                          <Form.Label>Bin Number</Form.Label>
                          <Form.Control
                            size="sm"
                            placeholder={currentPart.binNumber}
                            {...register('binNumber')}
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                  </Container>
                </Card>
              </Col>
            </Row>
          </Container>
        </fieldset>
      </Form>

      <div className="d-flex flex-row-reverse position-sticky bottom-0 p-2 pr-1 bg-body border-top">
        {isAssetAdmin && (
          <Button
            type="submit"
            disabled={partStatusIsLoading || !formIsDirty}
            onClick={handleSubmit(onSubmit)}
          >
            Update
          </Button>
        )}
      </div>
    </>
  );
}

export default PartForm;
