import { EditTableProps } from "../../UI/view/FormItems/EditTable";
import { FormItemType } from "../../UI/view/component-map";
import { ListViewColumnType } from "../../UI/view/list-view";

export const nitrogenTanksEditTable = {
  name: 'nitrogenTanks',
  type: FormItemType.editTable,
  columns: [
    {
      type: ListViewColumnType.delete,
      accessor: 'delete',
    },
    {
      accessor: 'name',
      Header: 'Name',
      formItemProps: {
        name: 'name',
        required: true,
        defaultValue: null,
        maxLength: 50
      }
    },
    {
      accessor: 'active',
      Header: 'Active',
      formItemProps: {
        name: 'active',
        required: true,
        defaultValue: true,
        type: FormItemType.checkbox
      }
    },
  ]

};

/* Location	Analyzer - Model	Component	LDL	Tag	Serial	MSA Tag	MSA File */
