import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Container, Modal, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { createPurchaseRequests } from '../../../store/slices/purchase-request-slice';
import EditablePaginatedSelectTable from '../../UI/organisms/Table/editable-paginated-select-table';

function PurchaseRequestModal({
  show, onHide, data, purchaseRequestCreateStatus,
}) {
  const dispatch = useDispatch();
  const onModalHide = () => {
    onHide();
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty, dirtyFields },
    getFieldState,
  } = useForm({
    defaultValues: [],
    mode: 'onChange',
  });

  const requestModalColumns = useMemo(() => [
    {
      Header: 'Part Name',
      accessor: 'partName',
    },
    {
      Header: 'Part Id',
      accessor: 'partId',
    },
    {
      Header: 'Part Quantity',
      accessor: 'quantityRequested',
    },
  ], []);

  const requestTableInstance = useRef(null);

  let transformedData = [...data];
  const [dataTable, setData] = useState(transformedData);

  useEffect(() => {
    transformedData = data.map((element) => { return { ...element }; });

    transformedData.forEach((element) => {
      element.quantityRequested = '';
      element.priority = 1;
      // reset isActive
      element.isActive = true;
      element.newCost = element.lastCost;

      delete element.isNonStockItem;
      delete element.assetTypeId;
      delete element.locationId;
      delete element.maxQuantity;
      delete element.minQuantity;
      delete element.partDescription;
      delete element.quantityOnHand;
      delete element.reorderLevel;
    });

    setData(transformedData);
  }, [data]);

  const [originalData] = useState(transformedData);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const updateData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    }));
  };

  useEffect(() => {
    setSkipPageReset(false);
  }, [dataTable]);

  const onSubmit = () => {
    dispatch(createPurchaseRequests(dataTable));
  };

  const partsTable = (
    <EditablePaginatedSelectTable
      columns={requestModalColumns}
      data={dataTable || []}
      ref={requestTableInstance}
      rowProps={(row) => ({})}
      updateData={updateData}
    />
  );

  const submitIsDiabled = purchaseRequestCreateStatus
   || !(dataTable && dataTable.every((pr) => pr.quantityRequested));

  return (
    <Modal
      show={show}
      onHide={() => onModalHide()}
      size="lg"
      centered
      scrollable
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Create Purchase Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        <Spinner animation="border" variant="primary" className={`create-spinner${purchaseRequestCreateStatus ? ' visible' : ' invisible'}`} />
        <Container fluid className={` ${purchaseRequestCreateStatus ? ' creation-loading' : ''}`}>
          {partsTable}
        </Container>

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={onHide} variant="secondary" className="mt-3 position-sticky float-end bottom-0">Close</Button>
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
          disabled={submitIsDiabled}
          onClick={handleSubmit(onSubmit)}
        >
          Submit

        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default PurchaseRequestModal;
