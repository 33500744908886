import './basic-input.scss';

import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  DropdownButton,
  FormControl,
  InputGroup,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { BsCalculator, BsInfoCircle } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import {
  measuementSystemPreference,
  roundNumber, unitConversions, unitPreferences,
} from '../../../../services/user-preferences';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import { selectUsersStatus } from '../../../../store/slices/users-slice';

/* To create an input field, pass the units as options and the default unit as defaultUnit */
function BasicInput(props) {
  const usersStatus = useSelector(selectUsersStatus);

  const measurementPreference = measuementSystemPreference[
    useSelector(selectLoggedInUserPreferences).measurementPreference];
  const currentUnit = unitPreferences[props.defaultUnit][measurementPreference];
  // useEffect(() => {
  //     if (userInfo) {
  //         Object.keys(userProfileDefaultValues).forEach(key => {
  //             setValue(key, userInfo[key])
  //         })
  //     }
  // }, [userInfo])

  const [eUnit, setUnit] = useState(props.defaultUnit);
  const [savedValue, setSavedValue] = useState(null);
  const [fieldValue, setFieldValue] = useState(null);
  const [show, setShow] = useState(false);
  const target = useRef(null);

  // const updateValueSaved = (Unit) => {
  //   const currentUnit = unitPreferences[props.defaultUnit][measurementPreference];

  //   // setSavedValue(unitConversions[props.defaultUnit][Unit].convertToBase(fieldValue));
  //   setFieldValue(unitConversions[props.defaultUnit][Unit].convertFromBase(fieldValue));
  // };

  useEffect(() => {
    if (fieldValue !== '' && fieldValue !== null) {
      setSavedValue(
        roundNumber(unitConversions[props.defaultUnit][eUnit].convertToBase(fieldValue), 3),
      );
    } else {
      setSavedValue(null);
    }
  }, [fieldValue]);

  useEffect(() => {
    if (savedValue !== '' && savedValue !== null) {
      setFieldValue(
        roundNumber(unitConversions[props.defaultUnit][eUnit].convertFromBase(savedValue), 3),
      );
    } else {
      setFieldValue(null);
    }
  }, [eUnit]);

  const handleSelect = (e) => {
    setUnit(e);
  };

  const renderTooltip = (value) => {
    const getConversion = () => Object.keys(unitConversions[props.defaultUnit]).map((unit) => ({
      id: unit,
      value: roundNumber(unitConversions[props.defaultUnit][unit].convertFromBase(value), 3),
    }));

    const insert = getConversion();
    // className="tooltip" id="tooltip" style={{ opacity: 1, minWidth: 250, maxHeight: 150 }}
    return (
      <Popover style={{ minWidth: 300 }}>
        <Popover.Header>
          Unit Conversions
        </Popover.Header>
        <Popover.Body>
          <Container>
            {insert.map((item) => (
              <Row key={item.id}>
                <Col lg="4" style={{ textAlign: 'left' }}>
                  {' '}
                  {item.id}
                  :
                </Col>
                <Col lg="auto" style={{ textAlign: 'left' }}>
                  {' '}
                  {
                    item.value !== '' && item.value !== null && !Number.isNaN(item.value)
                    && Number(Number.parseFloat(item.value).toFixed(3))
                  }
                  {' '}
                </Col>
              </Row>
            ))}
          </Container>
        </Popover.Body>
      </Popover>
    );
  };

  const mainName = props.name;

  const {
    valueIsLoaded, watch, getFieldState,
    errorInfo, initialValue,
    trigger,
    register, registerOptions,
  } = props;
  // const initialValue = watch(mainName);

  const fieldState = getFieldState(mainName);
  useEffect(() => {
    register(mainName, registerOptions);
  }, []);

  useEffect(() => {
    if (usersStatus === 'succeeded' && valueIsLoaded && (initialValue || initialValue === null || initialValue === 0)) {
      setSavedValue(initialValue !== null ? initialValue : '');
      setUnit(currentUnit);
      setFieldValue(initialValue !== null ? roundNumber(unitConversions[props.defaultUnit][eUnit].convertFromBase(initialValue), 3) : '');

      // setFieldValue(currentFieldValue);

      props.setValue(mainName, initialValue !== null ? initialValue : '', { shouldTouch: true });
    }
  }, [valueIsLoaded, initialValue, usersStatus]);

  useEffect(() => {
    if (fieldState.isTouched) {
      props.setValue(mainName, savedValue, { shouldDirty: true });
      if (fieldState.isDirty && !props.createMode) {
        trigger(mainName);
      }
    }
  }, [savedValue]);

  return (
    <InputGroup size="sm" className="basic-input-group">
      <FormControl
        placeholder={props.type}
        aria-label="Text input with dropdown button"
        onChange={(e) => {
          handleSelect(eUnit);
          setFieldValue(e.target.value);
        }}
        isInvalid={props.isInvalid}
        size="sm"
        value={fieldValue}
        inputMode="numeric"
        type="number"
      />
      <OverlayTrigger
        placement="top"
        delay={{ show: 0, hide: 0 }}
        overlay={renderTooltip(savedValue)}
      >
        <Button
          size="sm"
          variant="outline-secondary"
          className="conversion-info-button"
          style={{ backgroundColor: 'transparent', color: 'unse' }}
          ref={target}
          onClick={() => setShow(!show)}
        >
          <BsInfoCircle size={15} />
        </Button>
      </OverlayTrigger>
      <DropdownButton
        variant="outline-secondary"
        title={eUnit}
        id="input-group-dropdown-1"
        align="end"
        onSelect={(e) => {
          handleSelect(e);
        }}
      >
        {props.options.map((item) => (
          <Dropdown.Item key={item} eventKey={item}>{item}</Dropdown.Item>
        ))}
      </DropdownButton>
      {props.onCalculate && (
        <Button
          size="sm"
          variant="outline-secondary"
          onClick={() => {
            const calc = props.onCalculate();
            setFieldValue(calc);
          }}
        >
          <BsCalculator size={15} />
        </Button>
      )}
    </InputGroup>
  );
}
export default BasicInput;
