import axios from "axios";
import { handleRequestError, memoizePromiseFn } from "../../requests";

export const InsertEmptyBilling = async (billing) => {
  const results = await axios.post('/LiquidRentalBilling/InsertEmptyInvoice', billing).catch(handleRequestError);
  return results.data;
}

export const GetLiquidRentalBillingList = async (queryDefinition) => {
  const results = await axios.post('/LiquidRentalBilling/GetLiquidRentalBillingList', queryDefinition).catch(handleRequestError);
  return results.data;
};

export const getLiquidRental = async (dewarRental) => {
  const results = await axios.get(`/LiquidRentalBilling/getLiquidRental/${dewarRental}`).catch(handleRequestError);
  return results.data;
};
export const getLiquidRentalsUnbilled = async (def) => {
  const results = await axios.get(`/LiquidRentalBilling/getLiquidRentalsUnbilled`).catch(handleRequestError);
  return results.data;
};
export const getLiquidRentalsMTD = async (def) => {
  const results = await axios.get(`/LiquidRentalBilling/getLiquidRentalsMTD`).catch(handleRequestError);
  return results.data;
};

export const InsertBilling = async (billing) => {
  const results = await axios.post('/LiquidRentalBilling/InsertBilling', billing).catch(handleRequestError);
  return results.data;
}

export const deleteInvoice = async (invoiceId) => {
  const results = await axios.delete(`/LiquidRentalBilling/DeleteInvoice/${invoiceId}`).catch(handleRequestError);
  return results.data;
};
// /PreAuthorizeCreditCard
export const PreAuthorizeCreditCard = async (billingId) => {
  const results = await axios.get(`/LiquidRentalBilling/PreAuthorizeCreditCard/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const getBillingHeader = async (billingId) => {
  const results = await axios.get(`/LiquidRentalBilling/getBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const reverseBilling = async (reverse) => {
  const results = await axios.post(`/LiquidRentalBilling/reverseBilling`, reverse).catch(handleRequestError);
  return results.data;
};

export const uploadInvoicesJDEDTO = async (invoice) => {
  const results = await axios.post(`/LiquidRentalBilling/UploadInvoicesToJDE`, invoice).catch(handleRequestError);
  return results.data;
};

export const SendReport = async (report) => {
  const results = await axios.post('/LiquidRentalBilling/SendReport', report).catch(handleRequestError);
  return results.data;
};
export const downloadReport = async (report) => {
  const results = await axios.post('/LiquidRentalBilling/DownloadReport', report, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', report.filename);
  document.body.appendChild(link);
  link.click();
};
export const GetJDEErrors = async (billing) => {
  const results = await axios.get('/LiquidRentalBilling/GetJDEErrors').catch(handleRequestError);
  return results.data;
}
export const GetUnbilledInvoices = async (billing) => {
  const results = await axios.get('/LiquidRentalBilling/GetUnbilledInvoices').catch(handleRequestError);
  return results.data;
}

export const UpdateBilling = async (billing) => {
  const results = await axios.put('/LiquidRentalBilling/UpdateBilling', billing).catch(handleRequestError);
  return results.data;
}

export const InvoiceAllLiquidRentals = async () => {
  const results = await axios.post('/LiquidRentalBilling/InvoiceAll').catch(handleRequestError);
  return results.data;
}