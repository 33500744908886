import React from 'react';
import { Form } from 'react-bootstrap';

function TableSearchFilter(props) {
  const {
    onFilterChanged, tableInstance, hookInstance, ...rest
  } = props;

  return (
    <Form.Control
      data-testid="table-search-filter"
      onChange={(e) => {
        if (hookInstance) {
          tableInstance.setGlobalFilter(e.target.value);
        } else {
          tableInstance?.current?.setGlobalFilter(e.target.value);
        }
        if (onFilterChanged) onFilterChanged(e.target.value);
      }}
      type="search"
      placeholder="Filter"
      {...rest}
    />
  );
}

export default TableSearchFilter;
