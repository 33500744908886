import axios from "axios";
import { handleRequestError } from "../requests";

export const getLocationUserRoles = async () => {
  const results = await axios.get('/Administration/getLocationUserRoles').catch(handleRequestError);
  return results.data;
}
export const updateLocationUserRoles = async (obj) => {
  const results = await axios.put('/Administration/updateLocationUserRole', obj).catch(handleRequestError);
  return results.data;
}
export const insertLocationUserRoles = async (obj) => {
  const results = await axios.post('/Administration/insertLocationUserRole', obj).catch(handleRequestError);
  return results.data;
}