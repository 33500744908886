import { createListView, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { vendorStore } from "../../../store/store";
import { vendorCreate } from "./vendor-form-create";
import { vendorUpdate } from "./vendor-form-update";

export const vendorList: ListView = {
  variant: 'card',
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      Header: 'Vendor Name',
      accessor: 'vendorName',
      defaultSort: true,
    },
    {
      Header: 'Address 1',
      accessor: 'address1'
    },
    {
      Header: 'Address 2',
      accessor: 'address2'
    },
    {
      Header: 'City',
      accessor: 'city'
    },
    {
      Header: 'State',
      accessor: 'state'
    },
    {
      Header: 'Zip',
      accessor: 'zip'
    },
    {
      Header: 'Country',
      accessor: 'country'
    }
  ] as ListViewColumn[],
  pageHeader: 'Vendors',
  breadcrumbs: [
    {
      name: 'Vendors',
      icon: 'shop',
      to: '/maintenance/vendors',
    },
  ],
  create: vendorCreate,
  update: vendorUpdate

/*   dataSourceSelectors: [
    (state: any) => state.fetchVendors
  ] */
};

export const VendorListComponent = createListView(vendorList, vendorStore as any);