import { uniqBy } from "lodash"
import React, { useMemo } from "react"
import { SensorChart } from "./sensor-chart"

const getTimestamp = (dateString) => {
  const dt = new Date(dateString);
  return dt.getTime();
};
const dp = (val, element) => {
  return {
    x: getTimestamp(element.readingTime),
    y: val,
  };
};
export const SensorChartWrapper = (props) => {
  const { entity } = props;

  const containerReadings = useMemo(() => {
    return [
      {
        label: 'Battery Voltage (V)',
        data: uniqBy(entity?.myReadings.filter((x) => x.BatteryVoltage !== 'NaN' && !Number.isNaN(x.BatteryVoltage)).map((x) => dp((!Number.isNaN(x.BatteryVoltage) ? x.BatteryVoltage : ''), x)), JSON.stringify),
      },
      {
        label: 'Temperature (F)',
        data: uniqBy(entity?.myReadings.filter((x) => x.AmbientTemperature !== 'NaN' && !Number.isNaN(x.AmbientTemperature)).map((x) => dp((!Number.isNaN(x.AmbientTemperature) ? x.AmbientTemperature : ''), x)), JSON.stringify),
      },
    ]
  }, [entity]);

  return <SensorChart
    containerReadings={
      containerReadings
    }
  />
}