import { exportDemandForecasting } from "../../../services/requests"

export const rtuExport = async (rawData) => {
  exportDemandForecasting({
    fileName: 'readings.xlsx',
    tabs: [
      {
        label: 'readings',
        rows: [
          {
            cells: [
              {
                text: 'Last Reading Date',
                type: 'text',
                width: 20,
              },
              {
                text: 'PX Number',
                type: 'text',
                width: 20,
              },
              {
                text: 'Serial #',
                type: 'text',
                width: 20,
              },
              {
                text: 'Channel',
                type: 'text',
                width: 20,
              },
              {
                text: 'Reading Value',
                type: 'text',
                width: 20,
              },
              {
                text: 'Full Point',
                type: 'text',
                width: 20,
              },
              {
                text: 'Order Point',
                type: 'text',
                width: 20,
              },
              {
                text: 'Reserve Point',
                type: 'text',
                width: 20,
              },
              {
                text: 'Outage Point',
                type: 'text',
                width: 20,
              },
              {
                text: 'Avg psi/day',
                type: 'text',
                width: 20
              },
              {
                text: 'Est. Delivery',
                type: 'text',
                width: 20
              },
              {
                text: 'Outage Date',
                type: 'text',
                width: 20
              },
              {
                text: 'Comment',
                type: 'text',
                width: 20
              }
            ]
          },
          ...rawData.map(d => ({
            cells: [
              {
                text: d.lastReadingShortDate,
                type: 'text',
              },
              {
                text: d.praxairNumber,
                type: 'text',
              },
              {
                text: d.rtuSerialNumber,
                type: 'text',
              },
              {
                value: d.channelId,
                type: 'number',
              },
              {
                complexValue: d.readingValue,
                type: 'complex',
              },
              {
                complexValue: d.fullPoint,
                type: 'complex',
              },
              {
                complexValue: d.orderPoint,
                type: 'complex'
              },
              {
                complexValue: d.reservePoint,
                type: 'complex'
              },
              {
                complexValue: d.outagePoint,
                type: 'complex'
              }, 
              {
                complexValue: d.avgUsage,
                type: 'complex'
              },
              {
                text: d.dayOfReservePoint,
                type: 'text',
              },
              {
                text: d.dayOfOutagePoint,
                type: 'text',
              },
              {
                text: d.comment,
                type: 'text',
              }  
            ]
          }))
        ]
      }
    ]
  }, 'readings.xlsx')
};