
import { FormItemType } from "../../../UI/view/component-map";
import { DataSource, Repository } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { ListView, createConnectedListView2 } from "../../../UI/view/list-view";
import { createConnectedView, createView } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { CreateTransfer, CreateTransferDataSource } from "./liquid-create-transfer";
import { LiquidBillingInvoiceDataSource, LiquidBillingInvoices } from "./liquid-invoice-list";
import { UnlinkedCreditInvoices } from "./liquid-unlinked-credit-invoices";

export const liquidMain = {
  pageHeader: 'Liquid Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Liquid Billing',
      icon: 'currency-dollar',
      to: '/billing/liquidproduct',
    },
  ],
  editMode: false,
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Invoices',
          items: [
            {
              type: FormItemType.customComponent,
              component: LiquidBillingInvoices,
            }
          ]
        },
        {
          label: 'Unlinked Credit Invoices',
          items: [
            {
              type: FormItemType.customComponent,
              component: UnlinkedCreditInvoices,
            }
          ]
        },
      ]
    }
  ]
} as FormView;

export const LiquidBillingMain = createView({ form: liquidMain, repository: LiquidBillingInvoiceDataSource as Repository});