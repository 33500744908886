import React, { useMemo } from 'react';
import { ListGroup, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { getCurrentRequest, getNextDueDate } from '../../../services/maintenance-helpers';
import { sortByBasic } from '../../../services/table-helpers';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectLiquidCustomerObjects } from '../../../store/slices/legacy-slice';
import {
  selectLiquidMaintenanceRequestsByAssetId,
  selectMaintenanceRequestTypes,
} from '../../../store/slices/maintenance-requests-slice';
// import { liquidContainerStyles } from './liquid-container-values';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';
import { NextDueDate } from '../../UI/molecules/NextDueDate/next-due-date';
import {
  liquidContainerMawps,
  liquidContainerStyleTypes,
  liquidContainerTypes,
} from './liquid-container-values';

function LiquidContainerInfo({
  currentLiquidContainer,
  liquidContainerStatusIsLoading,
  condensed,
}) {
  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);
  // const currentLiquidContainer = useSelector(selectCurrentLiquidContainer);
  const liquidCustomers = useSelector(selectLiquidCustomerObjects);
  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );

  const maintenanceRequests = useSelector(selectLiquidMaintenanceRequestsByAssetId);
  const maintenanceRequestsData = useMemo(() => {
    if (maintenanceRequests && currentLiquidContainer?.serialNumber in maintenanceRequests) {
      const requestsList = maintenanceRequests[currentLiquidContainer.serialNumber];
      return requestsList.sort(
        (a, b) => sortByBasic(new Date(a.modified), new Date(b.modified)),
      ).reverse();
    }
    return [];
  }, [maintenanceRequests, currentLiquidContainer]);

  const nextCSCReq = getCurrentRequest('CSC', maintenanceRequestsData, maintenanceRequestTypes);
  const CSCIsOverridden = !!nextCSCReq?.overriddenDueDate;
  const cscDueDate = CSCIsOverridden
    ? nextCSCReq?.overriddenDueDate : nextCSCReq?.plannedDueDate;

  const nextFiveYrReq = getCurrentRequest('5yr Inspection', maintenanceRequestsData, maintenanceRequestTypes);
  const fiveYrIsOverridden = !!nextFiveYrReq?.overriddenDueDate;
  const fiverYrDueDate = fiveYrIsOverridden
    ? nextFiveYrReq?.overriddenDueDate : nextFiveYrReq?.plannedDueDate;

  return (
    <Callout className={`${liquidContainerStatusIsLoading ? ' creation-loading' : ''}`}>
      {!condensed && (
      <>
        <h4>{currentLiquidContainer ? currentLiquidContainer.serialNumber : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}</h4>
        <p><i>{currentLiquidContainer ? currentLiquidContainer.comments : (<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}</i></p>
      </>
      )}
      {currentLiquidContainer
        ? (
          <ListGroup variant="unbordered">
            <ListGroup.Item>
              <InfoListItem
                name="Owner"
                value={currentLiquidContainer.ownerCustomer && currentLiquidContainer.ownerCustomer in liquidCustomers ? liquidCustomers[currentLiquidContainer.ownerCustomer].CustomerName : 'N/A'}
              />
            </ListGroup.Item>
            <ListGroup.Item><InfoListItem name="MAWP" value={currentLiquidContainer.containerMawp !== null ? liquidContainerMawps[currentLiquidContainer.containerMawp] : 'N/A'} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="He Shield" value={currentLiquidContainer.hasHeliumShield ? 'Yes' : 'No'} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="Type" value={currentLiquidContainer.containerType !== null ? liquidContainerTypes[currentLiquidContainer.containerTypeCode] : 'N/A'} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="Style" value={currentLiquidContainer.containerStyle ? liquidContainerStyleTypes[currentLiquidContainer.containerStyle] : 'N/A'} /></ListGroup.Item>
            <ListGroup.Item>
              <InfoListItem
                name="Next 2.5 Year"
                value={(
                  <NextDueDate
                    dueDate={getNextDueDate(cscDueDate, currentLiquidContainer?.cscDate, 30)}
                    isOverridden={CSCIsOverridden}
                    datePreference={datePreference}
                  />
                )}
              />
            </ListGroup.Item>
            <ListGroup.Item>
              <InfoListItem
                name="Next 5 Year"
                value={(
                  <NextDueDate
                    dueDate={getNextDueDate(fiverYrDueDate, currentLiquidContainer?.lastFiveYearInspectionDate, 60)}
                    isOverridden={fiveYrIsOverridden}
                    datePreference={datePreference}
                  />
                )}
              />
            </ListGroup.Item>
          </ListGroup>
        )
        : (
          <ListGroup variant="flush">
            <ListGroup.Item><InfoListItem name="Owner" value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="MAWP" value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="Shield" value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="Type" value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="Style" value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="Next 2.5 Year" value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>
            <ListGroup.Item><InfoListItem name="Next 5 Year" value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>

          </ListGroup>
        )}
    </Callout>
  );
}

export default LiquidContainerInfo;
