import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { getAssetOrderHistoryLogs } from '../../services/requests';

const initialState = {
  assetOrderHistoryLogs: {},
  assetOrderHistoryLogsFetch: {
    status: 'idle',
    error: null,
  },
};

export const fetchAssetOrderHistoryLogs = createAsyncThunk('assetOrderHistory/fetchAssetOrderHistoryLogs', async ({ assetType, serialNumber }) => {
  const response = await getAssetOrderHistoryLogs(assetType, serialNumber);
  return response;
});

export const selectAssetOrderHistoryLogs = (state) => state.assetOrderHistory.assetOrderHistoryLogs;

export const selectPackagedProductOrderHistoryLogs = (
  state,
) => state.assetOrderHistory.assetOrderHistoryLogs?.packagedProductOrderHistoryLogs ?? [];

export const selectPackagedProductTransactionLogs = (
  state,
) => state.assetOrderHistory.assetOrderHistoryLogs?.packagedProductTransactionLogs ?? [];

export const selectChassisOrderHistoryLogs = (
  state,
) => state.assetOrderHistory.assetOrderHistoryLogs?.chassisOrderHistoryLogs ?? [];

export const assetOrderHistorySlice = createSlice({
  name: 'assetOrderHistory',
  initialState,
  reducers: {
    setAssetOrderHistoryLogsFetchStatus: (state, action) => {
      state.assetOrderHistoryLogsFetch.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAssetOrderHistoryLogs.pending, (state) => {
        state.assetOrderHistoryLogsFetch.status = 'loading';
      })
      .addCase(fetchAssetOrderHistoryLogs.fulfilled, (state, action) => {
        state.assetOrderHistoryLogsFetch.status = 'succeeded';
        state.assetOrderHistoryLogs = action.payload;
      })
      .addCase(fetchAssetOrderHistoryLogs.rejected, (state, action) => {
        state.assetOrderHistoryLogsFetch.status = 'failed';
        state.assetOrderHistoryLogs.error = action.error.message;
      });
  },
});

export const { setEntityLogsFetchStatus } = assetOrderHistorySlice.actions;

export default assetOrderHistorySlice.reducer;
