import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setCurrentMaintenanceRequestId } from '../../../store/slices/maintenance-requests-slice';
import {
  fetchPurchaseOrderPartsPurchased,
  fetchPurchaseOrderSingle, selectCurrentPurchaseOrder,
  selectPurchaseOrders, setCurrentPurchaseOrderId, setUpdatePurchaseOrderStatus,
} from '../../../store/slices/purchase-orders-slice';
import { setAssignPurchaseRequestStatus, setFetchPurchaseRequestsStatus } from '../../../store/slices/purchase-request-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import FileAttachment from '../../UI/organisms/FileAttachment/file-attachment';
import PurchaseOrderDetailsTabs from '../../UI/organisms/PurchaseOrderDetailsTabs/purchase-order-details-tabs';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import PurchaseRequestAttachments from '../PurchaseRequests/purchase-request-attachments';
import PurchaseOrderForm from './purchase-order-form';
import PurchaseOrderInfo from './purchase-order-info';
import PurchaseOrderOutstandingRequestsTable from './purchase-order-outstanding-requests-table';
import PurchaseOrderPurchaseRequests from './purchase-order-purchase-requests';
import PurchaseOrderShipments from './purchase-order-shipments';
import PurchaseOrderStatus from './purchase-order-status';

function PurchaseOrderDetails(props) {
  usePageTitle('Purchase Order');
  const { purchaseOrderId } = useParams();

  const purchaseOrdersFetchStatus = useSelector(
    (state) => state.purchaseOrder.purchaseOrdersFetchSingle.status,
  );

  const purchaseOrdersUpdateStatus = useSelector(
    (state) => state.purchaseOrder.purchaseOrderUpdate.status,
  );

  const purchaseRequestAssignStatus = useSelector(
    (state) => state.purchaseRequests.purchaseRequestAssign.status,
  );

  const purchaseOrderPartsPurchasedFetchStatus = useSelector(
    (state) => state.purchaseOrder.purchaseOrderPartsPurchasedFetch.status,
  );

  const purchaseOrderUpdateNotify = () => toast.success('Successfully Updated Purchase Order');
  const purchaseOrderUpdateNotifyFailure = (error) => toast.error(`Failed to Update Purchase Order\n${error}`);
  const purchaseOrderError = useSelector((state) => state.purchaseOrder.purchaseOrderUpdate.error);

  const purchaseRequestAssignNotify = () => toast.success('Successfully Assigned Purchase Request to Purchase Order');
  const purchaseOrderUpdateFailedNotify = () => toast.error('Failed to update Purchase Order');

  const currentPurchaseOrder = useSelector(selectCurrentPurchaseOrder);
  const purchaseOrders = useSelector(selectPurchaseOrders);

  const breadcrumbs = useMemo(() => [
    {
      name: 'Maintenance',
      icon: 'wrench-adjustable',
      to: '/maintenance',
    },
    {
      name: 'Purchase Order',
      icon: 'clipboard',
      to: '/maintenance/purchase-order',
    },
    {
      name: purchaseOrderId,
      icon: 'wrench',
      to: '',
    },
  ], [purchaseOrderId]);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (purchaseOrdersFetchStatus === 'idle' || purchaseOrdersFetchStatus === 'failed') {
      dispatch(fetchPurchaseOrderSingle(purchaseOrderId));
    }

    if (purchaseOrderPartsPurchasedFetchStatus === 'idle') {
      dispatch(fetchPurchaseOrderPartsPurchased(purchaseOrderId));
    }

    dispatch(setCurrentPurchaseOrderId(purchaseOrderId));
  }, [
    purchaseOrdersFetchStatus,
    purchaseOrderId,
    dispatch,
    currentPurchaseOrder,
  ]);

  useEffect(() => {
    if (purchaseOrdersUpdateStatus === 'succeeded') {
      purchaseOrderUpdateNotify();
      dispatch(setUpdatePurchaseOrderStatus('idle'));
    }
    if (purchaseOrdersUpdateStatus === 'failed') {
      purchaseOrderUpdateNotifyFailure(purchaseOrderError);
      dispatch(setUpdatePurchaseOrderStatus('idle'));
    }
    if (purchaseRequestAssignStatus === 'succeeded') {
      purchaseRequestAssignNotify();
      dispatch(setAssignPurchaseRequestStatus('idle'));
    }
    if (purchaseRequestAssignStatus === 'failed') {
      purchaseOrderUpdateFailedNotify();
      dispatch(setAssignPurchaseRequestStatus('idle'));
    }
  }, [purchaseOrdersUpdateStatus, purchaseRequestAssignStatus]);

  useEffect(() => {
    loadData();
  }, [currentPurchaseOrder]);

  useEffect(() => {
    dispatch(setFetchPurchaseRequestsStatus('idle'));
    dispatch(fetchPurchaseOrderSingle(purchaseOrderId));
  }, []);

  const list = useMemo(
    () => currentPurchaseOrder?.attachmentList ?? [],
    [purchaseOrders],
  );

  const [
    maintenanceRequestAttachmentsModalShow,
    setMaintenanceRequestAttachmentsModalShow,
  ] = useState(false);

  const handleMaintenanceRequestAttachmentsModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setMaintenanceRequestAttachmentsModalShow(false);
  };
  const handleMaintenanceRequestAttachmentsModalShow = (assetType) => {
    setMaintenanceRequestAttachmentsModalShow(true);
  };

  const purchaseOrderStatusIsLoading = purchaseOrdersFetchStatus === 'loading' || purchaseOrdersUpdateStatus === 'loading';
  return (
    <Container
      fluid
      className="h-100 d-flex flex-column"
    >
      <PageHeader
        header="Purchase Order"
        breadcrumbs={breadcrumbs}
      />
      <Row className="h-100 flex-grow-1 overflow-auto">
        <Col lg={3} className="h-100 fluid flex-column overflow-auto">
          <PurchaseOrderInfo purchaseOrderStatusIsLoading={purchaseOrderStatusIsLoading} />
          {/* <PurchaseOrderShipmentInfo /> */}
          <FileAttachment entityPK={purchaseOrderId} entityName="Purchase Order" />
          <PurchaseOrderStatus />
        </Col>
        <Col className="h-100 d-flex fluid">
          <PurchaseOrderDetailsTabs
            generalInfoContents={(
              <PurchaseOrderForm
                currentPurchaseOrder={currentPurchaseOrder}
                purchaseOrderStatusIsLoading={purchaseOrderStatusIsLoading}
              />
              )}
            maintenanceRequestContents={(
              <PurchaseOrderPurchaseRequests
                type="requests"
                purchaseOrderId={purchaseOrderId}
                purchaseOrderStatusIsLoading={purchaseOrderStatusIsLoading}
                handleMaintenanceRequestAttachmentsModalShow={
                    handleMaintenanceRequestAttachmentsModalShow
                  }
              />
              )}
            outstandingRequestContents={(
              <PurchaseOrderOutstandingRequestsTable
                purchaseOrderStatusIsLoading={purchaseOrderStatusIsLoading}
                currentPurchaseOrder={currentPurchaseOrder}
                handleMaintenanceRequestAttachmentsModalShow={
                    handleMaintenanceRequestAttachmentsModalShow
                  }
                type="outstanding"
              />
              )}
            shipmentsContent={(
              <PurchaseOrderShipments
                currentPurchaseOrder={currentPurchaseOrder}
                purchaseOrderStatusIsLoading={purchaseOrderStatusIsLoading}
              />
            )}
            auditLogContent={(
              <EntityAuditLogs
                tableSchema="Maintenance"
                entityId={9}
                tablePK={purchaseOrderId}
              />
              )}
          />
        </Col>
      </Row>
      <PurchaseRequestAttachments
        show={maintenanceRequestAttachmentsModalShow}
        onHide={handleMaintenanceRequestAttachmentsModalClose}
      />
    </Container>
  );
}

export default PurchaseOrderDetails;
