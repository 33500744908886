import { formatCurrency, formatDate } from "../../../services/format";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createConnectedView } from "../../UI/view/view";
import { billingPermissions } from "./billing-datasource";
import { invoiceContentsTable } from "./billing-invoice-details";
import { BillingItemList } from "./billing-item-types";
import { sum } from "../../../services/array-helpers";
import { miscBillingDataSource } from "./billing-invoice-list";
import { PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, sendMiscReport } from "../../../services/requests/billing/misc-billing-requests";

export const miscReportLink = (billingId) => `http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fMisc_Billing_Invoice&rs:Command=Render&BillingID=${billingId}`;
export const dewarRentalReportLink = (billingId) => `http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fDewar_Rental_Billing_Invoice&rs:Command=Render&rentalid=${billingId}`;
export const liquidRentalReportLink = (billingId) => `http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fRental_Billing_Invoice&rs:Command=Render&rentalid=${billingId}`;
export const gasRentalReportLink = (billingId) => `http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fGas_Rental_Billing_Invoice&rs:Command=Render&rentalid=${billingId}`;


export const footerItems = (reportName, linkFn, PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, sendReport) => [
  {
    type: FormItemType.button,
    label: 'Delete Invoice',
    variant: 'secondary',
    disabled: ({ watch }) => watch('jdebatchNumber') != null,
    style: { marginLeft: 2 },
    handler: async ({ getValues, watch, redirect, confirm }) => {
      let confirmed = false;
      confirmed = await confirm('Are you sure you want to delete this invoice?')
      if (confirmed) {
        const success = await deleteInvoice(watch('billingId'));
        await confirm('Invoice has been marked as deleted.', true);
/*         redirect('/billing'); */
      }
    }
  },
  {
    type: FormItemType.button,
    variant: 'secondary',
    style: { marginLeft: 2 },
    label: 'Print Invoice',
    handler: ({ watch, setValue, getValues, editTableProps, methods }) => {
      const { billingId } = getValues();
      const url = linkFn(billingId);//`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fMisc_Billing_Invoice&rs:Command=Render&BillingID=${billingId}`;
      window.open(url, '_blank');
    }
  },

  {
    type: FormItemType.button,
    variant: 'secondary',
    label: 'Download Invoice',
    style: { marginLeft: 2 },
    handler: async ({ getValues }) => {
      const { billingId } = getValues();
      downloadReport({ filename: `${reportName}.pdf`, billingId });
    }
  },
  {
    type: FormItemType.button,
    label: 'Send Invoice',
    variant: 'secondary',
    style: { marginLeft: 2 },
    disabled: () => sendReport == null,
    handler: async ({ getValues, confirm }) => {
      const { billingId, emailAddress } = getValues();
      const success = await sendReport({ filename: `${reportName}.pdf`, billingId, emails: emailAddress });
      if (success) {
        await confirm("Invoice Successfully sent to recipients.", true);
      } else {
        await confirm("An error occured while sending invoice to recipients. Check customer email is correct.", true);
      }
    }
  },
  {
    type: FormItemType.button,
    style: { marginLeft: 2 },
    label: 'PreAuthorize Credit Card',
    variant: 'secondary',
    disabled: ({ watch }) => !['not charged', 'error'].includes(watch('creditCardStatusConverted')?.toLowerCase()),
    handler: async ({ getValues }) => {
      const { billingId, emailAddress } = getValues();
      var result = await PreAuthorizeCreditCard(billingId);
      if (result) {
        alert(result);
      } else {
        alert("An error occured while authorizing credit card.");
      }
      location.reload();
    }
  },
  {
    type: FormItemType.button,
    variant: 'danger',
    style: { marginLeft: 2 },
    label: 'Backout...',
    hideIf: ({ watch }) => {
      const charged = watch('creditCardStatusConverted');
      const jdebypassed = watch('jdebypassed');
      const jdeBatchNumber = watch('jdebatchNumber');
      if (!jdeBatchNumber || jdebypassed || charged?.toLowerCase() != 'not charged') return true;
      return false;
    },
    handler: async ({ watch, confirm }) => {

      const reversable = watch('reversable')
      let confirmed = false;
      if (reversable) {
        confirmed = await confirm('This invoice has yet to be processed by JDE. By performing this action you will completely reverse this invoice.')
      } else {
        confirmed = await confirm('This invoice has already been processed by JDE. This action will only undo changes within VTS. Please verify the necessary steps have been performed in JDE to reverse this invoice.')
      }
      if (confirmed) {
        const success = await reverseBilling({ billingId: watch('billingId') });
        alert('Invoice has been backed out successfully.');
        location.reload();
      }

    }
  },
  {
    type: FormItemType.submitButton,
/*     disabled: ({ watch }, entity) => {
      return entity?.jdebypassed || entity?.jdebatchNumber != null
    } */
  }

];

export const invoiceEdit = (showFooter) => ({
  editMode: true,
  pageHeader: 'Miscellaneous Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Billing',
      icon: 'currency-dollar',
      to: '/billing',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              header: ({ billing }) => billing?.current?.data?.customerName,
              infoListItems: [
                {
                  label: 'Customer',
                  name: 'customerName'
                },
                {
                  label: 'Invoice Number',
                  name: 'billingId'
                },
                {
                  label: 'Legacy Invoice Number',
                  name: 'legacyBillingId'
                },
                {
                  label: 'Date Invoiced',
                  name: 'dateInvoiced',
                  value: ({ dateInvoiced }) => formatDate(dateInvoiced)
                },
                {
                  label: 'Date JDE Processed',
                  name: 'dateJdeprocessed',
                  value: ({ dateJdeprocessed }) => formatDate(dateJdeprocessed)
                },
                {
                  label: 'Has Credit Card',
                  name: 'creditCardOnFileStr'
                },
                {
                  label: 'Credit Card Status',
                  name: 'creditCardStatusConverted'
                },
                {
                  label: 'JDE Batch Number',
                  name: 'jdebatchNumber'
                },
                {
                  label: 'Total Entries',
                  name: 'totalEntries',
                  value: ({ miscBillingDetails }) => miscBillingDetails?.length || 0
                },
                {
                  label: 'Invoice Total',
                  name: 'totalEntries',
                  value: ({ miscBillingDetails }) => !miscBillingDetails ? null : formatCurrency(sum(miscBillingDetails, 'value'))
                },
              ]
            },
            {
              type: FormItemType.fileAttachments,
              entityTypeId: 13,
              entityName: 'Billing Invoice',
              permissions: billingPermissions,
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'General',
              footerItems: showFooter && footerItems('Misc_Billing_Invoice', miscReportLink, PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, sendMiscReport),
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Misc Billing',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'SO Number',
                          name: 'sonumber',
                          width: 140,
                          fitToContent: true,
                          defaultValue: ''
                        },
                        {
                          label: 'Date Invoiced',
                          name: 'dateInvoiced',
                          type: FormItemType.date,
                          minWidth: 200,
                          fitToContent: true,
                          required: true
                        },
                        {
                          name: 'ponumber',
                          label: 'PO Number',
                          minWidth: 200,
                          fitToContent: true,
                          defaultValue: ''
                        },
                        {
                          name: 'holdDate',
                          label: 'Hold Date',
                          minWidth: 200,
                          fitToContent: true,
                          type: FormItemType.date
                        },
                      ]
                    },
                    {
                      label: 'Invoice Notes',
                      name: 'invoiceComments',
                      type: FormItemType.textarea,
                      width: 140,
                      fitToContent: true,
                      defaultValue: ''
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Customer Email',
                          name: 'emailAddress',


                        },
                        {
                          type: FormItemType.button,
                          style: { marginTop: 28 },
                          label: 'Go to Customer',
                          width: 128,
                          fitToContent: true,
                          handler: ({ getValues }) => {
                            const { productType, dewarCustomerID, gasCustomerId, liquidCustomerId } = getValues();
                            if (productType == 'Liquid') {
                              window.open(`/customers/edit/liquid/${liquidCustomerId}`, '_blank');
                            } else if (productType == 'Gas') {
                              window.open(`/customers/edit/gas/${gasCustomerId}`, '_blank');
                            } else {
                              window.open(`/customers/edit/dewars/${dewarCustomerID}`, '_blank');
                            }
                          }
                        }
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'JDE Batch Number',
                          name: 'jdebatchNumber',
                          width: 140,
                          fitToContent: true,
                          disabled: () => true,
                          hideIf: ({ watch }) => !watch('jdebatchNumber')
                        },
                      ]
                    },
                    {
                      label: 'Bypass Invoice',
                      name: 'jdebypassed',
                      type: FormItemType.checkboxField,
                      width: 140,
                      fitToContent: true,
                      hideIf: ({ watch }) => watch('jdebatchNumber')
                    },
                    {
                      label: 'reversable',
                      name: 'reversable',
                      hideIf: ({ watch }) => true
                    },
                    {
                      label: 'creditCardStatusConverted',
                      name: 'creditCardStatusConverted',
                      hideIf: ({ watch }) => true
                    }
                  ]
                }
              ]
            },

            {
              label: 'Invoice Contents',
              footerItems: showFooter && footerItems('Misc_Billing_Invoice', miscReportLink, PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, sendMiscReport),
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Contents',
                  items: [
                    invoiceContentsTable('miscBillingDetails')
                  ]
                }
              ]
            },
            /*             {
                          label: 'Item Types',
                          items: [
                            {
                              type: FormItemType.customComponent,
                              component: BillingItemList
                            }
                          ]
                        }, */
            {
              label: 'Audit Logs',
              unMountOnExit: true,
              items: [
                {
                  type: FormItemType.auditLogs,
                  name: 'BillingHeaders',
                  tableSchema: 'Billing'
                }
              ]
            }
          ]
        }
      ]
    }
  ]
} as FormView);



export const InvoiceEdit = createConnectedView({ form: invoiceEdit(true), dataSource: miscBillingDataSource });

