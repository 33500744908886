import { sortByBasic } from './table-helpers';

export const getCurrentRequest = (requestName, requestList, requestTypes) => {
  return requestList?.filter(
    // eslint-disable-next-line max-len
    (mr) => requestTypes[mr.maintenanceRequestTypeId]?.name === requestName && mr.maintenanceRequestStatusId !== 3 && mr.workOrderStatus !== 'Closed',
  ).sort((a, b) => sortByBasic(new Date(a.modified), new Date(b.modified))).reverse()[0];
};

export const getStatusLight = (maintenanceRequest, requestTypes) => {
  let statusLight = 'Green';

  const requestType = requestTypes[maintenanceRequest?.maintenanceRequestTypeId];
  if (!requestType) {
    return '';
  }

  const currentDate = new Date(Date.now());
  currentDate.setHours(0, 0, 0, 0);
  let mrDueDate = new Date(maintenanceRequest.plannedDueDate);
  if (maintenanceRequest.overriddenDueDate) {
    mrDueDate = new Date(maintenanceRequest.overriddenDueDate);
  }

  const isLiquid = requestType.assetTypeId === 2;
  const isRehab = requestType.name === 'Rehab';
  const preventRed = isLiquid && isRehab;

  if (maintenanceRequest?.maintenanceRequestStatusId === 1
     || maintenanceRequest?.maintenanceRequestStatusId === 2) {
    if (requestType?.isPlanned) {
      const daysDiff = Math.ceil((mrDueDate - currentDate) / 1000 / 60 / 60 / 24); // millisecods to days
      if (daysDiff <= 60) {
        statusLight = 'Yellow';
      }
      if (daysDiff <= 30 && !preventRed) {
        statusLight = 'Red';
      }
    } else if (maintenanceRequest?.containerStatus === 1 || (preventRed && maintenanceRequest?.containerStatus === 0)) {
      statusLight = 'Yellow';
    } else if (maintenanceRequest?.containerStatus === 0) {
      statusLight = 'Red';
    }
  }
  return statusLight;
};
const isValidDate = (dt) => dt instanceof Date && !Number.isNaN(dt);
export const getDueDate = (maintenanceRequest, requestTypes) => {
  const requestType = requestTypes[maintenanceRequest?.maintenanceRequestTypeId];
  if (!requestType) {
    return null;
  }
  if (!requestType?.isPlanned && maintenanceRequest.plannedDueDate) {
    const mrDueDate = new Date(maintenanceRequest.plannedDueDate);
    if (isValidDate(mrDueDate)) {
      return mrDueDate;
    }
  }
  if (requestType?.isPlanned && maintenanceRequest.overriddenDueDate) {
    const mrDueDate = new Date(maintenanceRequest.overriddenDueDate);
    if (isValidDate(mrDueDate)) {
      return mrDueDate;
    }
  }
  if (requestType?.isPlanned && maintenanceRequest.plannedDueDate) {
    const mrDueDate = new Date(maintenanceRequest.plannedDueDate);
    if (isValidDate(mrDueDate)) {
      return mrDueDate;
    }
  }
  return null;
};

export const plannedMaintenanceRequestFrequency = {
  1: '6 months',
  2: '1 year',
  3: '10 years',
  4: '6 months',
  5: '1 year',
  6: '5-10 years',
  7: '5 years',
  8: '10 years',
  9: '10-15 years',
  10: '2.5 years',
  11: '1 year',
  12: '6 months',
  13: '1 year',
  14: '5 years',
  15: 'Last Rebuild Date + 5-7.5 years',
  16: '2.5 years',
  17: '1 year',
  18: '2.5 years',
  19: '2.5 years',
};

export const maintenanceStatusValues = {
  '': 0,
  Green: 1,
  Yellow: 2,
  Red: 3,
};

export const getNextDueDate = (maintenanceDate, lastMaintenanceDate, increment) => {
  const computed = lastMaintenanceDate ? new Date(lastMaintenanceDate).setMonth(new Date(lastMaintenanceDate).getMonth() + increment) : null;
  const date = maintenanceDate || (typeof computed !== 'undefined' && computed ? computed : '');
  return date;
};

export const isRehab = ({ maintenanceRequestTypeId, assetTypeId }) => {
  if (assetTypeId === 2 && maintenanceRequestTypeId === 15) return true;
  if (assetTypeId === 1 && maintenanceRequestTypeId === 9) return true;
  if (assetTypeId === 4 && maintenanceRequestTypeId === 3) return true;
  return false;
};

export const getLatestRehab = (maintenanceRequests, requestTypes) => {
  const requests = maintenanceRequests.sort((a, b) => {
    return new Date((getDueDate(a, requestTypes))) - new Date(getDueDate(b, requestTypes));
  });
  const request = requests.find((mr) => mr.maintenanceRequestStatusId === 4 && isRehab(mr) && ['Open', 'Scheduled', 'InProgress'].includes(mr.workOrderStatus));
  return request;
};
