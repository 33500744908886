/* Name	Production Rate (hrs/cont.)	Has Dial Down Capability	Dial Down Percentage	Preferred Hot Fill Location	Additional Hot Fill Hrs./Cont.	Prefer International Shipments	Active	Disallowed MAWP */


import { EditTableProps } from "../../UI/view/FormItems/EditTable";
import { FormItemType } from "../../UI/view/component-map";
import { ListViewColumnType } from "../../UI/view/list-view";

export const fillSlotsEditTable = {
  name: 'fillSlots',
  type: FormItemType.editTable,
  columns: [
    {
      type: ListViewColumnType.delete,
      accessor: 'delete',
    },
    {
      accessor: 'name',
      Header: 'Name',
      formItemProps: {
        name: 'name',
        required: true,
        defaultValue: null
      }
    },
    {
      accessor: 'rateOfProduction',
      Header: 'Production Rate (hrs/cont.)',
      formItemProps: {
        name: 'rateOfProduction',
        required: true,
        number: true
      }
    },
    {
      accessor: 'hasDialDown',
      Header: 'Has Dial Down Capability',
      formItemProps: {
        name: 'hasDialDown',
        required: true,
        defaultValue: false,
        type: FormItemType.checkbox
      }
    },
    {
      accessor: 'dialedDownProductionPercentage',
      Header: 'Dial Down Percentage',
      formItemProps: {
        name: 'dialedDownProductionPercentage',
        required: true,
        defaultValue: false,
        number: true
      }
    },
    {
      accessor: 'hotFillProductionTimeIncrease',
      Header: 'Additional Hot Fill Hrs./Cont.',
      formItemProps: {
        name: 'hotFillProductionTimeIncrease',
        required: true,
        number: true
      }
    },
    {
      accessor: 'isPreferredInternational',
      Header: 'Prefer International Shipments',
      formItemProps: {
        name: 'isPreferredInternational',
        required: true,
        defaultValue: false,
        type: FormItemType.checkbox
      }
    },
    {
      accessor: 'isActive',
      Header: 'Active',
      formItemProps: {
        name: 'isActive',
        required: true,
        defaultValue: true,
        type: FormItemType.checkbox
      }
    },
    {
      accessor: 'disallowedMawpgt',
      Header: 'Disallowed MAWP (>)',
      formItemProps: {
        name: 'disallowedMawpgt',
        required: true,
        number: true
      }
    },
    //Disallowed MAWP
    //disallowedMAWPGT
  ]

};