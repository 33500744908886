import React from "react"
import { Card, TabContainer, TabContent, TabPane } from "react-bootstrap"
import { TabHeaderRow } from "./TabHeaderRow"
import { FormItemType } from "../component-map";
import { EventKey } from "@restart/ui/esm/types";

export const TabControl = (props: { field: any; renderItem: any; formView: any; }) => {
  const {field, renderItem, formView} = props;
  const defaultTab = field.items.map((t: { label: any; }) => t.label).find((t: string) => t?.toLowerCase() === decodeURIComponent(location.hash?.replace('#', ''))?.toLowerCase());
  const { items, formState, editMode } = formView;
  const { register, control, watch, formState: { errors }, setValue, handleSubmit, onSubmit, getValues } = formState;
  //tab.items?.length && tab.items[0].type == FormItemType.fieldset ? 8 : undefined
  return (
    <Card className="card-tabs card-primary flex-grow-1 overflow-auto h-100 p-0">
      <TabContainer defaultActiveKey={defaultTab ?? field.items[0].label}>
        <TabHeaderRow {...props} />
        <div className="card-body p-0 g-0 w-100 mh-100 flex-grow-1" style={{ overflowX: 'hidden' }}>
          <TabContent className="flex-grow-1 d-flex h-100">
            {field.items.map((tab: { mountOnEnter?: boolean, label: EventKey; unMountOnExit: boolean; items: any[]; footerItems: any; horizontalScroll?: boolean; disableScroll?: boolean }, i: React.Key) => (
              <TabPane key={i} eventKey={tab.label} style={{paddingTop: 12 }} className="flex-grow-1 w-100 h-100" mountOnEnter={tab.mountOnEnter ?? true} unmountOnExit={tab.unMountOnExit}>
                <div style={{
                  width: '100%',
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  flexWrap: 'nowrap'
                }}>
                  <div style={{
                    flexGrow: 1,
                    overflow: tab.disableScroll ? 'hidden' : 'auto',
                    overflowX: tab.horizontalScroll ? 'auto' : 'hidden',
                    minHeight: '2em',

                    width: '100%',
                    height: '100%',
                    display: 'flex',
                    flexFlow: 'column'
                  }}>
                    {tab.items?.map((x: any) => renderItem(x))}
                  </div>

                  {tab.footerItems && (
                    // @ts-ignore
                    <div style={{ flexShrink: 0, marginLeft: 'auto', padding: 8, borderTop: '1px solid #dee2e6', width: '100%', display: 'inline-flex' }}>
                      {tab.footerItems?.map((x: any) => renderItem(x))}
                    </div>
                  )}
                </div>
              </TabPane>
            ))}
          </TabContent>
        </div>
      </TabContainer>
    </Card>
  )
}