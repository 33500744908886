import React, {
  useCallback, useEffect, useMemo, useReducer,
  useRef, useState,
} from 'react';
import {
  Button, Card,
  Collapse, Form, InputGroup,
} from 'react-bootstrap';
import { BsPencilFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { formatCurrency, formatDate } from '../../../services/format';
import { selectAllActiveUserRepairLocationObjects, selectLoggedInUserInfo, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { fetchPurchaseOrders, selectPurchaseOrders } from '../../../store/slices/purchase-orders-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

const initFilterState = {
  filterOpen: false,
};

const hiddenColumns = [
  'locationTextSearch',
  'isActive',
];

function filtersReducer(state, action) {
  switch (action.type) {
    case 'filterOpen':
      return { ...state, filterOpen: !state.filterOpen };
    default:
      throw new Error('invalid case');
  }
}

function PurchaseOrdersTable(props) {
  const dispatch = useDispatch();
  const purchaseOrders = useSelector(selectPurchaseOrders);
  const purchaseOrderStatus = useSelector(
    (state) => state.purchaseOrder.purchaseOrdersFetch.status,
  );
  const vendors = useSelector((state) => state.vendors.data);
  const purchaseOrderError = useSelector((state) => state.purchaseOrder.purchaseOrdersFetch.error);
  const { datePreference } = useSelector(selectLoggedInUserPreferences);
  const usersByOid = useSelector(selectUsersByOid);
  const userInfo = useSelector(selectLoggedInUserInfo);
  const repairLocations = useSelector(selectAllActiveUserRepairLocationObjects);
  const locations = Object.values(repairLocations)
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim()));
  const purchaseOrdersTableInstance = useRef(null);

  const [locationFilter, setLocationFilter] = useState(userInfo?.locationPreference);

  const handleSelectFilterChange = (event, filterId) => {
    setLocationFilter(event.target.value);
    purchaseOrdersTableInstance.current.setFilter(filterId, event.target.value);
  };

  const [filtersState, dispatchFilters] = useReducer(filtersReducer, initFilterState);

  const handleShowInactiveFilterChange = (event, filterId) => {
    if (event.target.checked) {
      purchaseOrdersTableInstance.current.setFilter(filterId, '');
    } else {
      purchaseOrdersTableInstance.current.setFilter(filterId, true);
    }
  };

  const handleFilterChange = (event, filterId) => {
    purchaseOrdersTableInstance.current.setFilter(filterId, !filtersState[filterId]);
    dispatchFilters({ type: filterId });
  };

  const setDefaultFilters = () => {
    Object.keys(filtersState).forEach((filterId) => {
      if (filterId !== 'filterOpen' && filtersState[filterId] === true) {
        purchaseOrdersTableInstance.current.setFilter(filterId, true);
      }
    });
    purchaseOrdersTableInstance.current.setFilter('locationId', locationFilter);
    purchaseOrdersTableInstance.current.setFilter('isActive', true);
  };

  const purchaseOrdersData = useMemo(() => {
    return Object.values(purchaseOrders);
  }, [purchaseOrders]);

  const loadData = useCallback(() => {
    if (purchaseOrderStatus === 'idle' || purchaseOrderStatus === 'failed') {
      dispatch(fetchPurchaseOrders());
    }
  }, [purchaseOrderStatus, dispatch]);

  useEffect(() => {
    loadData();
    setDefaultFilters();
  }, []);

  const navigate = useNavigate();

  const purchaseOrdersColumns = useMemo(() => [
    {
      Header: '',
      id: 'actionColumn',
      Cell: ({ cell }) => (
        <div className="d-flex">
          <Button
            size="xsm"
            title="Edit"
            className="ms-3 me-1"
            onClick={() => {
              navigate(`/maintenance/purchase-order/${cell.row.original.purchaseOrderId}`);
            }}
          >
            <BsPencilFill />
          </Button>

        </div>
      ),
    },
    {
      Header: 'PO #',
      accessor: 'purchaseOrderNumber',
      Cell: ({ value, row }) => (<NavLink to={`/maintenance/purchase-order/${row.original.purchaseOrderId}`}>{value}</NavLink>),
    },
    // {
    //   Header: 'Purchase Order Id',
    //   accessor: 'purchaseOrderId',
    // },
    {
      Header: 'Vendor',
      accessor: ({ vendorId }) => {
        const vendor = vendors.find((v) => v.vendorId === vendorId);
        return vendor?.vendorName;
      },
    },
    {
      Header: 'Approved',
      accessor: 'approvedDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    // {
    //   Header: 'Approved By',
    //   accessor: 'approvedBy',
    //   Cell: ({ value, row }) => (value ? u
    // sersByOid[value]?.displayName ?? 'User Not Found' : null),
    // },
    {
      Header: 'Submitted',
      accessor: 'created',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    // {
    //   Header: 'Submitted To Supplier By',
    //   accessor: 'submittedBy',
    //   Cell: ({ value, row }) => (value ? usersBy
    // Oid[value]?.displayName ?? 'User Not Found' : null),
    // },
    {
      Header: 'Estimated Delivery',
      accessor: 'estimatedDeliveryDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'Actual Delivery',
      accessor: 'actualDeliveryDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'BOE Number',
      accessor: 'boeNumber',
    },
    // {
    //   Header: 'HS Code',
    //   accessor: 'hsCode',
    // },
    {
      Header: 'Comments',
      accessor: 'comments',
      className: 'overflow-hide',
      Cell: ({ value }) => <span title={value}>{value}</span>,
    },
    {
      Header: 'Status',
      accessor: 'purchaseOrderStatus',
    },
    {
      Header: 'Location',
      accessor: 'locationId',
      Cell: ({ value }) => repairLocations[value]?.LocationName ?? value,
    },
    {
      id: 'locationTextSearch',
      accessor: ({ locationId: value }) => repairLocations[value]?.LocationName ?? value,
    },
    {
      Header: 'Freight Cost',
      id: 'freightCost',
      accessor: ({ freightCost }) => formatCurrency(freightCost),
    },
    {
      Header: 'IsActive',
      accessor: 'isActive',
    },
  ], [repairLocations, vendors]);

  const packagedProductsContent = (
    <Card className="card-primary card-outline flex-grow-1 overflow-auto">

      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            data-cy="filter-button"
            variant={filtersState.filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => dispatchFilters({ type: 'filterOpen' })}
          >
            <i className={filtersState.filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={purchaseOrdersTableInstance} />
          &nbsp;
          <Button variant="primary" size="sm" onClick={() => { props.setModalShow(true); }}>Create Purchase Order</Button>
        </InputGroup>
        <Collapse in={filtersState.filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select data-cy="location-filter" value={locationFilter} size="sm" onChange={(e) => { handleSelectFilterChange(e, 'locationId'); }}>
                  <option value="" key="customer-null">-- Location --</option>
                  {Object.values(locations).map((location, index) => (
                    <option
                      value={location.LocationID}
                      key={`location-${index}`}
                    >
                      {location.LocationName}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Check
                data-cy="showInactive-filter"
                className="ms-1 mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Show InActive"
                onChange={(e) => handleShowInactiveFilterChange(e, 'isActive')}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={purchaseOrdersColumns}
        data={purchaseOrdersData}
        ref={purchaseOrdersTableInstance}
        initialState={{
          hiddenColumns,
          sortBy: [
            {
              id: 'created',
              desc: true,
            },
          ],
        }}
        rowProps={() => ({})}
      />
    </Card>
  );

  return (
    <>
      <div data-cy="purchase-orders" className={`d-flex flex-column flex-grow-1 h-100${purchaseOrderStatus === 'loading' ? ' creation-loading' : ''}`}>
        {packagedProductsContent}
        {purchaseOrderStatus === 'failed' && <ErrorContent errorMessage={purchaseOrderError} />}
      </div>
      {purchaseOrderStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default PurchaseOrdersTable;
