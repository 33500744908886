import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap"
import DateInput from "../../molecules/DateInput/date-input";
import { useValidation } from "../../../hooks/useValidation";

export const DateField = ({watch, editTableProps, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, errors}) => {

  const [customValidation, customValidatorNames, rowError] = useValidation({field, validation, getValues, data, rowIdentity, isUnique, errors });
  return (
    <Form.Group className="mb-1" >
      {field.label && <Form.Label>{field.label}</Form.Label>}
      <DateInput
        className={field.className}
        disabled={field.disabled ? field.disabled({ watch, editTableProps }) : undefined}
        style={field.minWidth ? {minWidth: field.minWidth} : undefined}
        control={control}
        formControlName={field.name}
        rules={{
          required: required && 'Please enter a valid date',
          validate: { ...customValidation },
        }}
      />
      {
        fieldErrors && (customValidatorNames as string[])?.includes(fieldErrors.type) && <Form.Control.Feedback type="invalid">
          {validation[fieldErrors.type].message}
        </Form.Control.Feedback>
      }
    </Form.Group>
  )
}