import { formatDate } from "../../../../services/format";
import { BypassInvoiceFromInvoice, bypassInvoiceFromOrder, deleteInvoice } from "../../../../services/requests/billing/dewar-billing-requests";
import { FormItemType } from "../../../UI/view/component-map";
import { Repository } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";

import { billingPermissions } from "../billing-datasource";
import { DewarBillingPendingListRepo } from "./dewar-billing-pending-list";
import {BillingDewarListViewRepo} from "./dewar-billing-invoice-list";
import { ListViewColumnType } from "../../../UI/view/list-view";



/* Order ID:	65775905
PX Number:	92403544
Customer Name:	Pfizer (CST)
CBT Number:	Bruker Biospin Corporation (CBT) - 10424279
City:	Groton	
State:	CT	 		
Zip Code:	06340
Shipping Location:	699
Order Completion Date:	5/30/2024 12:00:00 AM
Order Created By:	linde\b0ka51
PO Required:	Yes
PO Number:	BE500894

Invoice Contents
# of returning containers planned: 5
# of returning containers actual: 2

# of outbound containers planned: 0
# of outbound containers actual: 0

Outbound Product Manifest:

Invoice Notes (From CRM) */



export const dewarBillingInvoiceEdit = {
  editMode: true,
  pageHeader: 'Dewar Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Dewar Billing',
      icon: 'currency-dollar',
      to: '/billing/dewarproduct',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          type: FormItemType.infoPane,
          flexHeight: true,
          header: ({ dewarinvoices }) => dewarinvoices?.current?.data?.dewarCustomer?.accountName,
          infoListItems: [
            { label: 'Order Id', name: 'orderId' },
            { label: 'Customer Id', name: 'customerId' },
            { label: 'Customer Name', name: 'customerName', value: ({ dewarCustomer: { accountName } }) => `${accountName}` },
            { label: 'CBT', name: 'cbt', value: ({ dewarCustomer: { parent } }) => `${parent}` },
            { label: 'City', name: 'city', value: ({ order }) => order?.city },
            { label: 'State', name: 'state', value: ({ order }) => order?.state },
            { label: 'Zip Code', name: 'zip', value: ({ order }) => order?.zip },
            { label: 'Shipping Location', name: 'shippingLocation', value: ({ order }) => order?.location?.locationName },
            { label: 'Order Completion Date', name: 'dateFulfilled', value: ({order}) => formatDate(order?.dateFulfilled) },
            { label: 'Order Created By', name: 'createdBy', value: ({order}) => order?.createdBy},
            { label: 'PO Required', name: 'porequired', value: ({ dewarCustomer: { po } }) => [1, 3].includes(po) ? 'Yes' : 'No' },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'General',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Notes',
                  items: [
                    { label: 'Order Number', name: 'order.orderNumber', disabled: () => true },
                    { label: 'PO Number', name: 'order.ponumber', disabled: () => true },
                    { label: '# of returning containers planned', name: 'order.numReturningContainers', disabled: () => true },
                    { label: '# of returning containers actual', name: 'order.numReturningContainersActual', disabled: () => true },
        
                    { label: '# of outbound containers planned', name: 'order.numOutboundContainersOrdered', disabled: () => true },
                    { label: '# of outbound containers actual', name: 'order.numOutboundContainersActual', disabled: () => true },
                    {
                      label: 'Outbound Product Manifest',
                      type: FormItemType.textarea,
                      disabled: () => true,
                      name: 'order.outboundProductManifestString',
                    },
                    {
                      label: 'Delivery Instructions',
                      name: 'order.deliveryInstructions',
                      type: FormItemType.textarea,
                      disabled: () => true
                    },
                    {
                      label: 'Other Notes',
                      name: 'order.otherNotes',
                      type: FormItemType.textarea,
                      disabled: () => true
                    },
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.button,
                  label: 'Delete Invoice',
                  variant: 'secondary',
                  disabled: ({ watch }) => watch('jdebatchNumber') != null,
                  style: { marginLeft: 2 },
                  handler: async ({ getValues, watch, redirect, confirm }) => {
                    let confirmed = false;
                    confirmed = await confirm('Are you sure you want to delete this invoice?')
                    if (confirmed) {
                      const success = await deleteInvoice(watch('billingId'));
                      await confirm('Invoice has been deleted.', true);
                      redirect('/billing/dewarproduct');
              /*         redirect('/billing'); */
                    }
                  }
                },
                {
                  type: FormItemType.button,
                  label: 'Bypass',
                  variant: 'primary',
                  style: { marginLeft: 'auto' },
                  handler: async ({ methods, handleSubmit, onSubmit, redirect, confirm }) => {
                    const { getValues } = methods;
                    const { billingId } = getValues();
                    let isError = false;
                    let result = null;
                    try {
                      result = await BypassInvoiceFromInvoice(billingId);
                    } catch {
                      isError = true;
                    }
                    if (isError) {
                      await confirm('There was an error bypassing this invoice.', true);
                    } else {
                      //redirect to created invoice. We need the invoice id!\
                      await confirm('Invoice Bypassed.', true);
                      redirect('/billing/dewarproduct');
                    }
                  }
                }
              ]
            },
            {
              label: 'Invoice Contents', 
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Contents',
                  footerItems: [
                    {
                      type: FormItemType.submitButton,
                      label: 'Update Invoice',
                      variant: 'secondary',
                    }
                  ],
                  items: [
                    {
                      type: FormItemType.editTable,
                      name: 'dewarBillingDetails',
                      variant: 'tabular',
                      pk: 'id',
                      hideAddButton: true,
                      columns: [
        /*                 {
                          type: ListViewColumnType.delete,
                          id: 'deleteme'
                        }, */
                        {
                          Header: 'Dewar S/N',
                          accessor: 'serialNumber',
                          formItemProps: {
                            name: 'serialNumber',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Delivery Date',
                          accessor: 'deliveryDate',
                          formItemProps: {
                            name: 'deliveryDate',
                            disabled: () => true,
                            type: FormItemType.date,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Delivery Method',
                          accessor: 'freight',
                          formItemProps: {
                            name: 'freight',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Volume/Quantity',
                          accessor: 'quantity',
                          formItemProps: {
                            name: 'quantity',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Price/Unit',
                          accessor: 'unitPrice',
                          formItemProps: {
                            name: 'unitPrice',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Total',
                          accessor: 'value',
                          formItemProps: {
                            name: 'value',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
    
                      ]
    
                    },
                  ]
                }
    
              ]
            }
          ]
        },

      ]
    }
  ]

} as FormView;


export const [DewarBillingInviceEdit, DewarBillingEditInvoiceRepo] = createConnectedView2({ form: dewarBillingInvoiceEdit, existingRepo: BillingDewarListViewRepo as Repository });