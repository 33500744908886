import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Col, Container, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { convertDateToUTCDay, formatAssetType } from '../../../services/format';
import {
  fetchChassis, selectChassis, selectCurrentChassis, selectCurrentChassisId,
} from '../../../store/slices/chassis-slice';
import {
  fetchGasContainers, selectCurrentGasContainer, selectCurrentGasContainerId, selectGasContainers,
} from '../../../store/slices/gas-containers-slice';
import { selectAllActiveLocations, selectGasCustomers, selectLiquidCustomers } from '../../../store/slices/legacy-slice';
import {
  fetchLiquidContainers, selectCurrentLiquidContainer,
  selectCurrentLiquidContainerId, selectLiquidContainers,
} from '../../../store/slices/liquid-containers-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import { createWorkOrder } from '../../../store/slices/work-orders-slice';
import DateInput from '../../UI/molecules/DateInput/date-input';
import {
  Typeahead,
} from '../../UI/molecules/Typeahead';

function CreateWorkOrderForm(props) {
  const defaultWorkOrderFormValues = {
    approvedBy: null,
    assetSerialNumber: null,
    assetTypeId: null,
    comments: null,
    customerId: null,
    estimatedDuration: null, // a user input or something that's computed?
    locationId: null,
    maintenanceRequests: null,
    estimatedStartDate: null,
    estimatedEndDate: null,

    workOrderId: null,
    workOrderStatusId: null,
  };
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultWorkOrderFormValues,
  });

  // forcing workorderStatusId to 1 "Open"
  setValue('workOrderStatusId', 1);

  const { assetTypes } = useSelector(selectMaintenanceRequestDropdownValues);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const gasContainerStatus = useSelector((state) => state.gasContainer.gasContainersFetch.status);
  const liquidContainerStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.status,
  );
  const chassisStatus = useSelector((state) => state.chassis.chassisFetch.status);

  const loadData = useCallback(async () => {
    if (gasContainerStatus === 'idle' || gasContainerStatus === 'failed') {
      dispatch(fetchGasContainers());
    }
    if (liquidContainerStatus === 'idle' || liquidContainerStatus === 'failed') {
      dispatch(fetchLiquidContainers());
    }
    if (chassisStatus === 'idle' || chassisStatus === 'failed') {
      dispatch(fetchChassis());
    }
  }, [gasContainerStatus, liquidContainerStatus, chassisStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const gasContainers = useSelector(selectGasContainers);
  const liquidContainers = useSelector(selectLiquidContainers);
  const chassis = useSelector(selectChassis);
  const gasCustomers = useSelector(selectGasCustomers);
  const liquidCustomers = useSelector(selectLiquidCustomers);

  const gasContainerIds = useMemo(() => {
    if (gasContainers) {
      return Object.keys(gasContainers);
    }
    return [];
  }, [gasContainers]);

  const liquidContainerIds = useMemo(() => {
    if (liquidContainers) {
      return Object.keys(liquidContainers);
    }
    return [];
  }, [liquidContainers]);

  const chassisIds = useMemo(() => {
    if (chassis) {
      return Object.keys(chassis);
    }
    return [];
  }, [chassis]);

  const [assetIds, setSelectedAssetIds] = useState([]);
  const [customers, setSelectedCustomers] = useState([]);
  const [currentID, setCurrentId] = useState();
  // const [currCust, setCurrentCust] = useState([]);

  const liquidId = useSelector(selectCurrentLiquidContainerId);
  const gasId = useSelector(selectCurrentGasContainerId);
  const chassisId = useSelector(selectCurrentChassisId);
  const liquidContainer = useSelector(selectCurrentLiquidContainer);
  const gasContainer = useSelector(selectCurrentGasContainer);
  const chassisCurrent = useSelector(selectCurrentChassis);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'assetTypeId') {
        if (value.assetTypeId === '1') {
          setSelectedAssetIds(gasContainerIds);
          setSelectedCustomers(gasCustomers);
          setCurrentId(gasId);
          setValue('assetType', 'GasContainer');
          setValue('assetSerialNumber', gasId);
          setValue('customerId', gasContainer.ownerCustomer);
        } else if (value.assetTypeId === '2') {
          setSelectedAssetIds(liquidContainerIds);
          setSelectedCustomers(liquidCustomers);
          setCurrentId(liquidId);
          setValue('assetType', 'LiquidContainer');
          setValue('assetSerialNumber', liquidId);
          setValue('customerId', liquidContainer.ownerCustomer);
        } else if (value.assetTypeId === '4') {
          setSelectedAssetIds(chassisIds);
          setSelectedCustomers(liquidCustomers);
          setCurrentId(chassisId);
          setValue('assetType', 'Chassis');
          setValue('assetSerialNumber', chassisId);
          setValue('customerId', chassisCurrent.ownerCustomer);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, gasContainerIds, liquidContainerIds, chassisIds, liquidId, liquidCustomers]);

  const locations = useSelector(selectAllActiveLocations);

  const locationsList = useMemo(() => {
    return Object.values(locations ?? {});
  }, [locations]);

  useEffect(() => {
    setValue('assetTypeId', props.createModalAssetType);
  }, [props.createModalAssetType]);

  const onSubmit = (workOrder) => {
    Object.keys(workOrder).forEach((key) => {
      if (key.toLowerCase().includes('date') && workOrder[key]) {
        workOrder[key] = convertDateToUTCDay(workOrder[key]);
      }
    });
    dispatch(createWorkOrder({ ...workOrder })).unwrap().then((result) => {
      navigate(`/maintenance/work-orders/${result.workOrderId}`);
    }).catch((error) => {
      console.log(`Error creating work order: ${error.message}`);
    });
  };

  return (
    <>
      <Modal.Body>
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${props.createWorkOrderStatusIsLoading ? ' visible' : ' invisible'}`}
        />
        <Form className="bg-white h-100 overflow-auto">
          <Container fluid className={` ${props.createWorkOrderStatusIsLoading ? ' creation-loading' : ''}`}>
            <Container fluid className="p-0 m-0">
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Asset Type</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('assetTypeId', { required: true })}
                      disabled={props.asset}
                      isInvalid={!!errors.assetTypeId}
                    >
                      {assetTypes.filter((type) => type.name !== 'PackagedProduct').map((type, i) => (
                        <option value={type.id} key={`asset-type-${i}`}>{formatAssetType(type.name)}</option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Serial Number</Form.Label>
                    <Form.Control
                      size="sm"
                      {...register('assetSerialNumber')}
                      disabled
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Estimated Repair Start Date</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="estimatedStartDate"
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Estimated Repair End Date</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="estimatedEndDate"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Estimated Duration</Form.Label>
                    <Form.Control size="sm" {...register('estimatedDuration')} />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Location</Form.Label>
                    <Controller
                      control={control}
                      name="locationId"
                      defaultValue={null}
                      rules={{ required: true }}
                      render={({
                        field: {
                          onChange, value, ref,
                        },
                        fieldState: {
                          invalid, isTouched, isDirty, error,
                        },
                        formState,
                      }) => (
                        <Typeahead
                          ref={ref}
                          value={value}
                          onChange={(e) => {
                            onChange(locationsList?.find(
                              (l) => l?.LocationName === e[0],
                            )?.LocationID);
                          }}
                          size="sm"
                          id="mainteanance-request"
                          options={locationsList.map((l) => l.LocationName)}
                          isInvalid={!!error}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-1">
                <Form.Label>Description</Form.Label>
                <Form.Control size="sm" as="textarea" rows={3} {...register('comments')} style={{ resize: 'none' }} />
              </Form.Group>
            </Container>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={props.onHide} variant="secondary">Close</Button>
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
          disabled={props.createWorkOrderStatusIsLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </>
  );
}

export default CreateWorkOrderForm;
