export const assetSort = (asset1, asset2) => {
  if (asset1.isActive && !asset2.isActive) {
    return -1;
  }
  if (!asset1.isActive && asset2.isActive) {
    return 1;
  }
  if (asset1.modified && asset2.modified) {
    return new Date(asset2.modified) - new Date(asset1.modified);
  }
  return 0;
};

export const getDateRangeFilterValue = (
  timeObj,
  dateChangeId,
  setStartDate,
  setEndDate,
  currentStartDate,
  currentEndDate,
) => {
  try {
    const filterValue = { start: null, end: null };

    if (dateChangeId === 'startDate') {
      setStartDate(timeObj);
      filterValue.start = timeObj?.getTime() ?? null;
      filterValue.end = currentEndDate?.getTime() ?? null;
    }
    if (dateChangeId === 'endDate') {
      setEndDate(timeObj);
      filterValue.start = currentStartDate?.getTime() ?? null;
      filterValue.end = timeObj?.getTime() ?? null;
    }

    return filterValue;
  } catch (error) {
    console.error('Failed to get date range filter value', error);
    return { start: null, end: null };
  }
};

// Function can be used when no special logic is needed for date range filtering
export const filterDatesByRange = (rows, ids, filterValue) => {
  let { start, end } = filterValue;

  // check that values are not NaN
  start = Number.isNaN(start) ? null : start;
  end = Number.isNaN(end) ? null : end;

  // Set proper min and max bounds
  let min = new Date(start).getTime();
  let max;
  if (end == null || end === '') {
    max = Infinity;
  } else {
    // exdend end date to the start of the next day? get buy in on behavior?
    max = new Date(end).getTime() + 86400000;
  }

  // Swap if min is less than max
  if (min > max) {
    const temp = min;
    min = max;
    max = temp;
  }

  return rows.filter((row) => ids.every((id) => {
    const rowValue = row.values[id];
    const rowTimeStamp = new Date(rowValue).getTime();
    return rowTimeStamp >= min && rowTimeStamp <= max;
  }));
};

export const sortByBoolean = (row1, row2, columnId) => {
  if (row1.values[columnId] && !row2.values[columnId]) {
    return -1;
  }
  if (!row1.values[columnId] && row2.values[columnId]) {
    return 1;
  }
  return 0;
};

export const sortByBasic = (value1, value2) => {
  if (value1 > value2) {
    return 1;
  }
  if (value2 > value1) {
    return -1;
  }
  return 0;
};

export const sortByDisplayValue = (row1, row2, columnId, displayArray, displayValue) => {
  if (displayArray[row1.values[columnId]] === undefined
    || displayArray[row1.values[columnId]][displayValue] == null) {
    if (displayArray[row2.values[columnId]] === undefined
      || displayArray[row2.values[columnId]][displayValue] == null) {
      return 0;
    }
    return -1;
  }
  if (displayArray[row2.values[columnId]] === undefined
    || displayArray[row2.values[columnId]][displayValue] == null) {
    if (displayArray[row1.values[columnId]] === undefined
      || displayArray[row1.values[columnId]][displayValue] == null) {
      return 0;
    }
    return 1;
  }
  // eslint-disable-next-line max-len
  return sortByBasic(displayArray[row1.values[columnId]][displayValue], displayArray[row2.values[columnId]][displayValue]);
};

export const sortByDictValue = (row1, row2, columnId, dictValue, extraValue = null) => {
  if (extraValue) {
    if (dictValue[row1.values[columnId][extraValue]] === undefined
      || dictValue[row1.values[columnId][extraValue]] == null) {
      if (dictValue[row2.values[columnId][extraValue]] === undefined
        || dictValue[row2.values[columnId][extraValue]] == null) {
        return 0;
      }
      return -1;
    }
    if (dictValue[row2.values[columnId][extraValue]] === undefined
      || dictValue[row2.values[columnId][extraValue]] == null) {
      if (dictValue[row1.values[columnId][extraValue]] === undefined
        || dictValue[row1.values[columnId][extraValue]] == null) {
        return 0;
      }
      return 1;
    }
    // eslint-disable-next-line max-len
    return sortByBasic(dictValue[row1.values[columnId][extraValue]], dictValue[row2.values[columnId][extraValue]]);
  }
  if (dictValue[row1.values[columnId]] === undefined
    || dictValue[row1.values[columnId]] == null) {
    if (dictValue[row2.values[columnId]] === undefined
      || dictValue[row2.values[columnId]] == null) {
      return 0;
    }
    return -1;
  }
  if (dictValue[row2.values[columnId]] === undefined
    || dictValue[row2.values[columnId]] == null) {
    if (dictValue[row1.values[columnId]] === undefined
      || dictValue[row1.values[columnId]] == null) {
      return 0;
    }
    return 1;
  }

  // eslint-disable-next-line max-len
  return sortByBasic(dictValue[row1.values[columnId]], dictValue[row2.values[columnId]]);
};

export const getDisplayNameByOID = (OID, usersByOid) => {
  if (OID === 'DefaultUser') {
    return 'Helium-3 System';
  }

  if (OID && !(OID in usersByOid)) {
    return OID;
  }

  return usersByOid[OID]?.displayName ?? 'User Not Found';
};

export const sortByDateField = (row1, row2, columnId) => {
  try {
    const value1 = row1.values[columnId];
    const value2 = row2.values[columnId];
    const dateValue1 = new Date(value1);
    const dateValue2 = new Date(value2);
    if (dateValue1 - dateValue2 > 0) {
      return 1;
    }
    if (dateValue2 - dateValue1 > 0) {
      return -1;
    }
    return 0;
  } catch (error) {
    return console.log(`There was an error" ${error}`);
  }
};

export function isValidDate(d) {
  // eslint-disable-next-line no-restricted-globals
  return d instanceof Date && !isNaN(d);
}

export const sortByDateCustom = (a, b) => {
  const aDate = new Date(a);
  const aValid = isValidDate(aDate);
  const bDate = new Date(b);
  const bValid = isValidDate(bDate);

  if (aDate - bDate > 0 || (!bValid && aValid)) {
    return 1;
  }
  if (bDate - aDate > 0 || (!aValid && bValid)) {
    return -1;
  }
  return 0;
};

/* export const exportData = (items) => {
  let csv;

  // Loop the array of objects
  // eslint-disable-next-line no-plusplus
  for (let row = 0; row < items.length; row++) {
    const keysAmount = Object.keys(items[row]).length;
    let keysCounter = 0;

    // If this is the first row, generate the headings
    if (row === 0) {
      // Loop each property of the object
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in items[row]) {
        // This is to not add a comma at the last cell
        // The '\r\n' adds a new line
        csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
        // eslint-disable-next-line no-plusplus
        keysCounter++;
      }
    } else {
      // eslint-disable-next-line no-restricted-syntax, guard-for-in
      for (const key in items[row]) {
        csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n');
        // eslint-disable-next-line no-plusplus
        keysCounter++;
      }
    }

    keysCounter = 0;
  }

  // Once we are done looping, download the .csv by creating a link
  const link = document.createElement('a');
  link.id = 'download-csv';
  link.setAttribute('href', `data:text/plain;charset=utf-8,${encodeURIComponent(csv)}`);
  link.setAttribute('download', 'yourfiletextgoeshere.csv');
  document.body.appendChild(link);
}; */

export const exportData2 = (filename, rows) => {
  const processRow = function (row) {
    let finalVal = '';
    // eslint-disable-next-line no-plusplus
    for (let j = 0; j < row.length; j++) {
      let innerValue = row[j] === null ? '' : row[j].toString();
      if (row[j] instanceof Date) {
        innerValue = row[j].toLocaleString();
      }
      let result = innerValue.replace(/"/g, '""');
      if (result.search(/("|,|\n)/g) >= 0) { result = `"${result}"`; }
      if (j > 0) { finalVal += ','; }
      finalVal += result;
    }
    return `${finalVal}\n`;
  };

  let csvFile = '';
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < rows.length; i++) {
    csvFile += processRow(rows[i]);
  }

  const blob = new Blob([csvFile], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, filename);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', filename);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const StringToCSVCell = (str) => {
  if (str === null) return '';
  if (typeof str !== 'string') return str;
  const mustQuote = (str.includes(',') || str.includes('"') || str.includes('\r') || str.includes('\n'));
  if (mustQuote) {
    let sb = '';
    sb += '"';
    [...str].forEach((nextChar) => {
      sb += nextChar;
      if (nextChar === '"') { sb += '"'; }
    });
    sb += '"';
    return sb;
  }

  return str;
};
export function mergeData(objArray, headers, orderedColumns, additionalSets) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  const str = [];
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    const line = {};
    // eslint-disable-next-line no-restricted-syntax
    for (const header of headers) {
      // eslint-disable-next-line eqeqeq
      let rowValue = array[i][header];
      const displayColumn = orderedColumns.find((c) => c.id === header);
      if (displayColumn && typeof displayColumn.accessor === 'function') {
        rowValue = displayColumn.accessor(array[i], additionalSets);
      }
      line[header] = rowValue;
    }
    str.push(line);
  }

  return str;
}
function convertToCSV(objArray, headers, orderedColumns, additionalSets) {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';

  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < array.length; i++) {
    let line = '';
    // eslint-disable-next-line no-restricted-syntax, guard-for-in
    /*     for (const index in array[i]) {
      // eslint-disable-next-line eqeqeq
      if (line != '') line += ',';

      line += array[i][index];
    } */

    // eslint-disable-next-line no-restricted-syntax
    for (const header of headers) {
      // eslint-disable-next-line eqeqeq
      if (line != '') line += ',';

      /*   line += array[i][header]; */
      let rowValue = array[i][header];
      const displayColumn = orderedColumns.find((c) => c.id === header);
      if (displayColumn && typeof displayColumn.accessor === 'function') {
        rowValue = displayColumn.accessor(array[i], additionalSets);
      }
      line += StringToCSVCell(rowValue);
    }

    str += `${line}\r\n`;
  }

  return str;
}

export function exportData(fileTitle, items, orderedColumns, additionalSets) {
  let headers = Object.keys(items[0]);
  const orderedHeaders = [...new Set(orderedColumns.filter((h) => typeof h.accessor === 'function' || headers.includes(h.id ?? h.accessor)).map((col) => col.id ?? col.accessor))];
  headers = [
    ...orderedHeaders,
    ...headers.filter((h) => !orderedHeaders.includes(h)),
  ];

  const display = (accessor) => {
    const header = orderedColumns.find((c) => (c.id ?? c.accessor) === accessor && c.Header && typeof c.Header === 'string');
    return header?.Header;
  };
  const headerString = `${headers.map((h) => display(h) ?? h).join(',')}\r\n`;

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);

  const csv = headerString + convertToCSV(jsonObject, headers, orderedColumns, additionalSets);

  const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
  if (navigator.msSaveBlob) { // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) { // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
}

export const isAsync = (theFunc) => {
  return theFunc.constructor.name === 'AsyncFunction';
};
