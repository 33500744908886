/* eslint-disable max-len */
import React, {
  useEffect,
  useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Collapse, Form, InputGroup,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatAssetType, formatDate, formatWorkOrderStatus } from '../../../services/format';
import { getDateRangeFilterValue, sortByBoolean, sortByDictValue } from '../../../services/table-helpers';
import { selectAllActiveUserRepairLocationObjects, selectLoggedInUserInfo, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { fetchGasContainers } from '../../../store/slices/gas-containers-slice';
import { fetchLiquidContainers } from '../../../store/slices/liquid-containers-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import { fetchWorkOrders, selectWorkOrders } from '../../../store/slices/work-orders-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import StyledDatePicker from '../../UI/molecules/DateInput/styled-date-picker';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import WorkOrdersMetricsCallout from './work-orders-metrics-callout';

const hiddenColumns = [
  'dateFilterColumn',
  'locationTextSearch',
];

function WorkOrdersTable(props) {
  const [filterOpen, setFilterOpen] = useState(false);
  const userInfo = useSelector(selectLoggedInUserInfo);
  const workOrders = useSelector(selectWorkOrders);
  const workOrdersFetchStatus = useSelector((state) => state.workOrder.workOrdersFetch.status);
  const repairLocations = useSelector(selectAllActiveUserRepairLocationObjects);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [tableFilterUpdate, setTableFilterUpdate] = useState(false);

  const filters = useMemo(() => [
    { id: 'locationId', value: userInfo?.locationPreference },
  ], [userInfo?.locationPreference]);

  const locations = useMemo(() => Object.values(repairLocations)
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim())), [repairLocations]);

  const workOrdersFetchError = useSelector((state) => state.workOrder.workOrdersFetch.error);
  const { assetTypes, workOrderStatuses } = useSelector(selectMaintenanceRequestDropdownValues);

  const workOrdersData = useMemo(() => {
    if (workOrders) {
      return Object.values(workOrders);
    }
    return [];
  }, [workOrders]);

  const dispatch = useDispatch();

  const liquidContainersFetchStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.status,
  );

  const gasContainersFetchStatus = useSelector(
    (state) => state.gasContainer.gasContainersFetch.status,
  );

  // need to get liquid & gas containers because some workOrders do not have customerId's
  // customerId's are required for determining which work orders are "Linde Owned"
  useEffect(() => {
    if (liquidContainersFetchStatus === 'idle' || liquidContainersFetchStatus === 'failed') {
      dispatch(fetchLiquidContainers());
    }
    if (gasContainersFetchStatus === 'idle' || gasContainersFetchStatus === 'failed') {
      dispatch(fetchGasContainers());
    }
  }, []);

  const sortByStatus = (row1, row2, columnId) => {
    const statusDict = {
      Open: 'Open',
      1: 'Open',
      Scheduled: 'Scheduled',
      2: 'Scheduled',
      InProgress: 'In Progress',
      3: 'In Progress',
      Completed: 'Completed',
      4: 'Completed',
      Closed: 'Closed',
      5: 'Closed',
      Cancelled: 'Cancelled',
      6: 'Cancelled',
    };

    try {
      return sortByDictValue(row1, row2, columnId, statusDict);
    } catch (error) {
      return console.log(`There was an error" ${error}`);
    }
  };

  const sortByAssetType = (row1, row2, columnId) => {
    const assetDict = {
      GasContainer: 'GasContainer',
      1: 'GasContainer',
      LiquidContainer: 'LiquidContainer',
      2: 'LiquidContainer',
      PackagedProduct: 'PackagedProduct',
      3: 'PackagedProduct',
      Chassis: 'Chassis',
      4: 'Chassis',
    };

    try {
      return sortByDictValue(row1, row2, columnId, assetDict);
    } catch (error) {
      return console.log(`There was an error" ${error}`);
    }
  };

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const filterDatesByRange = (rows, ids, filterValue) => {
    let { start, end } = filterValue;

    // check that values are not NaN
    start = Number.isNaN(start) ? null : start;
    end = Number.isNaN(end) ? null : end;

    // Set proper min and max bounds
    let min = new Date(start).getTime();
    let max;
    if (end == null || end === '') {
      max = Infinity;
    } else {
      max = new Date(end).getTime();
    }

    // Swap is min is less tahn max
    if (min > max) {
      const temp = min;
      min = max;
      max = temp;
    }

    ids = ['estimatedStartDate', 'estimatedEndDate'];

    return rows.filter((row) => ids.every((id) => {
      const rowValue = row.values[id];
      // do not filter out if a date is null. (unless its the start date)
      if (id !== 'estimatedStartDate' && row.values[id] == null) {
        return true;
      }

      const rowTimeStamp = new Date(rowValue).getTime();
      return rowTimeStamp >= min && rowTimeStamp <= max;
    }));
  };

  const workOrdersColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'workOrderId',
        Cell: ({ value }) => (<NavLink to={`/maintenance/work-orders/${value}`}>{value}</NavLink>),
      },
      {
        Header: 'Status',
        accessor: 'workOrderStatusId',
        filter: 'exactText',
        sortType: sortByStatus,
        Cell: ({ value }) => formatWorkOrderStatus(value),
      },
      {
        Header: 'Description',
        accessor: 'comments',
        disableSortBy: true,
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
      {
        Header: 'Asset Type',
        accessor: 'assetTypeId',
        filter: 'exactText',
        sortType: sortByAssetType,
        Cell: ({ value }) => formatAssetType(value),
      },
      {
        Header: 'Unit Number',
        accessor: 'assetSerialNumber',
        disableSortBy: true,
      },
      {
        Header: 'Approved By',
        accessor: 'approvedBy',
      },
      {
        Header: 'Estimated Start Date',
        accessor: 'estimatedStartDate',
        Cell: ({ value }) => formatDate(value, datePreference),
        filter: filterDatesByRange,
      },
      {
        Header: 'Estimated End Date',
        accessor: 'estimatedEndDate',
        Cell: ({ value }) => formatDate(value, datePreference),
        filter: filterDatesByRange,
      },
      // Estimated duration
      {
        Header: 'Completed Date',
        accessor: 'completedDate',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
      //  Commented out becasue not in slide for maintenace workshop
      {
        Header: 'Override Owner',
        accessor: 'overrideOwner',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Location',
        accessor: 'locationId',
        filter: 'exactText',
        Cell: ({ value }) => repairLocations[value]?.LocationName ?? value,
      },
      {
        id: 'locationTextSearch',
        filter: 'exactText',
        accessor: ({ locationId: value }) => repairLocations[value]?.LocationName ?? value,
      },
      {
        Header: 'Filter',
        id: 'dateFilterColumn',
        filter: filterDatesByRange,
      },
    ],
    [repairLocations],
  );

  const pageIsLoading = [
    workOrdersFetchStatus,
    gasContainersFetchStatus,
    liquidContainersFetchStatus,
  ].includes('loading');

  const tableInstance = useRef(null);

  const handleSelectFilterChange = (event, filterId) => {
    tableInstance.current.setFilter(filterId, event.target.value);
  };

  const handleRangeDateFilterChange = (timeObj, dateChangeId) => {
    const filterValue = getDateRangeFilterValue(
      timeObj,
      dateChangeId,
      setStartDateFilter,
      setEndDateFilter,
      startDateFilter,
      endDateFilter,
    );

    tableInstance.current.setFilter('dateFilterColumn', filterValue);
  };

  useEffect(() => {
    if (workOrdersFetchStatus === 'idle' || workOrdersFetchStatus === 'failed') {
      dispatch(fetchWorkOrders());
    }
    tableInstance.current.setAllFilters(filters);
  }, [workOrdersFetchStatus, filters]);

  const workOrdersContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={tableInstance} />
              &nbsp;
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select value={tableInstance?.current?.state?.filters?.find((filter) => filter.id === 'locationId')?.value} size="sm" onChange={(e) => { handleSelectFilterChange(e, 'locationId'); }}>
                  <option value="" key="customer-null">-- Location --</option>
                  {locations.map((location, index) => (
                    <option
                      value={location.LocationID}
                      key={`location-${index}`}
                    >
                      {location.LocationName}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'assetTypeId'); }}>
                  <option value="" key="assetTypeId-null">-- Asset Type --</option>
                  {assetTypes.map((type, index) => (
                    <option value={type.id} key={`assetTypeId-${index}`}>{formatAssetType(type.name)}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              {/* Add Filter by Region */}
              {/* Add Maintenance Facility */}
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'workOrderStatusId'); }}>
                  <option value="" key="workOrderStatusId-null">-- Status --</option>
                  {workOrderStatuses.map((status, index) => (
                    <option value={status.id} key={`workOrderStatusId-${index}`}>{status.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'startDate'); }}
                  value={startDateFilter}
                />
                <Form.Text className="text-muted">Start Date</Form.Text>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'endDate'); }}
                  value={endDateFilter}
                />
                <Form.Text className="text-muted">End Date</Form.Text>
              </Form.Group>
            </div>
          </div>
        </Collapse>
      </div>
      <WorkOrdersMetricsCallout workOrders={tableInstance?.current?.filteredRows} />
      <PaginatedSelectTable
        columns={workOrdersColumns}
        data={workOrdersData}
        ref={tableInstance}
        initialState={{ hiddenColumns }}
        setTableFilterUpdate={setTableFilterUpdate}
        rowProps={() => ({})}
      />
    </>
  );

  return (
    <>
      <div className={`d-flex flex-column h-100${workOrdersFetchStatus === 'loading' ? ' creation-loading' : ''}`}>
        {workOrdersContent}
      </div>
      {pageIsLoading && <LoadingContent />}
    </>
  );
}

export default WorkOrdersTable;
