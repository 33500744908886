import React, { useEffect, useMemo, useState } from 'react';
import {
  Card, Container,
  Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { usePageTitle } from '../../hooks/usePageTitle';
import { usePermissions } from '../../hooks/usePermissions';
import CustomModal from '../../UI/molecules/Modal/modal';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import { WorldMap } from '../../UI/organisms/Sensors/world-map';
// import LargeModal from '../../UI/molecules/LargeModal/large-modal';
import ChassisTable from '../Chassis/chassis-table';
import CreateChassis from '../Chassis/create-chassis';
import CreateGasContainer from '../GasContainer/create-gas-container';
import GasContainersTable from '../GasContainer/gas-containers-table';
import CreateLiquidContainer from '../LiquidContainer/create-liquid-container';
import LiquidContainersTable from '../LiquidContainer/liquid-containers-table';
import CreatePackagedProduct from '../PackagedProduct/create-packaged-product';
import PackagedProductsTable from '../PackagedProduct/packaged-products-table';
import { electronicsPermissions } from '../RareGas/electronics';
import { rareGasPermissions } from '../RareGas/raregas-ds';
import { RareGasListView } from '../RareGas/raregas-list';
import {
  WorlMapListComponent, WorlMapListComponentStore, WorlMapRareGasListComponent, WorlMapRareGasListComponentStore,
} from '../WorldMap/worldmap-list';

const mapFilters = [
  {
    type: 'Liquid', color: [0, 85, 145], legendColor: 'primary', display: 'Liquid',
  },
  {
    type: 'Gas', color: '#28a745', legendColor: 'success', display: 'Gas',
  },
  {
    type: 'Chassis', color: 'red', legendColor: 'danger', display: 'Chassis',
  },
];
const mapFiltersRareGas = [
  {
    type: 'Krypton', color: [0, 85, 145], legendColor: 'primary', display: 'Krypton',
  },
  {
    type: 'Neon', color: '#28a745', legendColor: 'success', display: 'Neon',
  },
  {
    type: 'Crude Neon', color: '#17a2b8', legendColor: 'info', display: 'Crude Neon',
  },
  {
    type: 'C2', color: 'red', legendColor: 'danger', display: 'C2',
  },
  {
    type: 'Crude Oxygen', color: '#ffc107', legendColor: 'warning', display: 'Crude Oxygen',
  },
];

function Assets(props) {
  usePageTitle('Assets');
  const location = useLocation();

  const [, rareGasAccess] = usePermissions(rareGasPermissions);
  const [, electronicsAccess] = usePermissions(electronicsPermissions);
  const [activeTab, setActiveTab] = useState('Liquid Container');
  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  useEffect(() => {
    if (location.hash.toLowerCase().includes('liquid')) {
      setActiveTab('Liquid Container');
    } else if (location.hash.toLowerCase().includes('electronics')) {
      setActiveTab('electronics');
    } else if (location.hash.toLowerCase().includes('rare')) {
      setActiveTab('worldmaprare');
    } else if (location.hash.toLowerCase().includes('global')) {
      setActiveTab('worldmap');
    } else if (location.hash.toLowerCase().includes('gas')) {
      setActiveTab('Gas Container');
    } else if (location.hash.toLowerCase().includes('chassis')) {
      setActiveTab('Chassis');
    } else if (location.hash.toLowerCase().includes('packaged')) {
      setActiveTab('Packaged Product');
    }
  }, [location.hash]);

  const breadcrumbs = useMemo(() => [
    {
      name: 'Assets',
      icon: 'boxes',
      to: '/assets',
    },

  ], []);

  const modalContent = useMemo(() => {
    switch (activeTab) {
      case 'Liquid Container':
        return <CreateLiquidContainer onHide={handleModalClose} />;
      case 'Gas Container':
        return <CreateGasContainer onHide={handleModalClose} />;
      case 'Packaged Product':
        return <CreatePackagedProduct onHide={handleModalClose} />;
      case 'Chassis':
        return <CreateChassis onHide={handleModalClose} />;
      default:
        return '';
    }
  }, [activeTab]);

  return (
    <>
      <Container
        fluid
        className="h-100 m-0 p-0 d-flex flex-column mb-2"
      >
        <PageHeader
          header="Assets"
          breadcrumbs={breadcrumbs}
        />
        <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
          <TabContainer
            defaultActiveKey="Liquid Container"
            onSelect={(tab) => setActiveTab(tab)}
            activeKey={activeTab}
          >
            <div className="card-header p-0 pt-1">
              <Nav variant="tabs">
                <Nav.Item>
                  <Nav.Link eventKey="Liquid Container">Liquid</Nav.Link>
                </Nav.Item>
                <Nav.Item data-cy="gas-tab">
                  <Nav.Link eventKey="Gas Container">Gas</Nav.Link>
                </Nav.Item>
                <Nav.Item data-cy="packaged-products-tab">
                  <Nav.Link eventKey="Packaged Product">Packaged Product</Nav.Link>
                </Nav.Item>
                <Nav.Item data-cy="chassis-tab">
                  <Nav.Link eventKey="Chassis">Chassis</Nav.Link>
                </Nav.Item>
                <Nav.Item data-cy="worldmap-tab">
                  <Nav.Link eventKey="worldmap">Global Map</Nav.Link>
                </Nav.Item>
                {electronicsAccess && (
                  <Nav.Item data-cy="electronics-tab">
                    <Nav.Link eventKey="electronics">Rare Gas</Nav.Link>
                  </Nav.Item>
                )}
                {rareGasAccess && (
                  <Nav.Item data-cy="worldmaprare-tab">
                    <Nav.Link eventKey="worldmaprare">Rare Gas Map</Nav.Link>
                  </Nav.Item>
                )}
              </Nav>
            </div>
            <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
              <TabContent className="h-100">
                <TabPane aria-labelledby="" eventKey="Liquid Container" className="h-100" mountOnEnter>
                  <LiquidContainersTable handleModalShow={handleModalShow} />
                </TabPane>
                <TabPane eventKey="Gas Container" className="h-100" mountOnEnter>
                  <GasContainersTable handleModalShow={handleModalShow} />
                </TabPane>
                <TabPane eventKey="Packaged Product" className="h-100" mountOnEnter>
                  <PackagedProductsTable handleModalShow={handleModalShow} />
                </TabPane>
                <TabPane eventKey="Chassis" className="h-100" mountOnEnter>
                  <ChassisTable handleModalShow={handleModalShow} />
                </TabPane>
                <TabPane eventKey="worldmap" className="h-100" mountOnEnter>
                  <WorldMap listComponent={WorlMapListComponent} storeComponent={WorlMapListComponentStore} filters={mapFilters} />
                </TabPane>
                {electronicsAccess && (
                  <TabPane eventKey="electronics" className="h-100" mountOnEnter>
                    <RareGasListView />
                  </TabPane>
                )}
                {rareGasAccess && (
                  <TabPane eventKey="worldmaprare" className="h-100" mountOnEnter>
                    <WorldMap
                      detailsLink={(breadcrumb) => `/raregas/edit/${breadcrumb.containerId}#sensors`}
                      listComponent={WorlMapRareGasListComponent}
                      storeComponent={WorlMapRareGasListComponentStore}
                      filters={mapFiltersRareGas}
                      filterField="product"
/*                       filters={[
                        {
                          type: 'RareGas', color: [0, 85, 145], legendColor: 'primary', display: 'Rare Gas',
                        },
                        {
                          type: 'electronics', color: '#28a745', legendColor: 'success', display: 'Electronics',
                        },
                      ]} */
                    />
                  </TabPane>
                )}
              </TabContent>
            </div>
          </TabContainer>
        </Card>
      </Container>
      <CustomModal
        show={modalShow}
        onHide={handleModalClose}
      >
        {modalContent}
      </CustomModal>
    </>
  );
}

export default Assets;
