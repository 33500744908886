import './error-content.scss';

import React from 'react';

function ErrorContent(props) {
  return (
    <div className="error-content-message">
      <h2>Error</h2>
      <p>{props.errorMessage}</p>
    </div>
  );
}

export default ErrorContent;
