import React from 'react';
import { NavDropdown } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { selectLoggedInUserActiveLocation, selectLoggedInUserInfo, selectLoggedInUserLocations } from '../../../../store/slices/auth-slice';
import { updateUser } from '../../../../store/slices/users-slice';

function ActiveLocationNavItem(props) {
  const loggedInUserLocations = useSelector(selectLoggedInUserLocations).sort();
  const userInfo = useSelector(selectLoggedInUserInfo);
  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);
  const dispatch = useDispatch();

  const onClick = (locationId) => {
    const updateUserInfo = { ...userInfo };
    updateUserInfo.locationPreference = locationId;
    dispatch(updateUser({ userId: userInfo.userId, user: updateUserInfo }));
  };

  const activeDropDownLink = (LinkLocationId) => {
    return currentUserActiveLocation?.LocationID === LinkLocationId;
  };

  return (loggedInUserLocations && loggedInUserLocations.length) ? (
    <NavDropdown
      title={userInfo?.locationPreference ? `Active Location: ${currentUserActiveLocation?.LocationName}` : 'Set your active location'}
      style={{ textAlign: 'right' }}
      id="nav-dropdown"
      active
    >
      {loggedInUserLocations.map((location, index) => {
        return (
          <NavDropdown.Item
            onClick={() => onClick(location.LocationID)}
            eventKey={`nav-location-${index}`}
            key={`nav-location-${index}`}
            active={activeDropDownLink(location.LocationID)}
          >
            {location.LocationName}
          </NavDropdown.Item>
        );
      })}
      <NavDropdown.Divider />
      <NavDropdown.Item as={Link} active={false} to="/profile" eventKey="activeLocation">Update in profile</NavDropdown.Item>
    </NavDropdown>
  ) : null;
}

export default ActiveLocationNavItem;
