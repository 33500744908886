import { InsertGasCustomer, GetDewarCustomers, GetGasCustomers, GetLiquidCustomers, UpdateDewarCustomer, UpdateGasCustomer, UpdateLiquidCustomer, InsertLiquidCustomer, GetLiquidCustomer } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";

export const dewarCustomerDataSource = {
  name: 'dewarcustomers',
  pk: 'site',
  request: GetDewarCustomers,
  updateRequest: UpdateDewarCustomer,
  serverSideEvents: true,
} as DataSource

export const gasCustomerDataSource = {
  name: 'gascustomers',
  pk: 'customerId',
  request: GetGasCustomers,
  updateRequest: UpdateGasCustomer,
  createRequest: InsertGasCustomer
} as DataSource

export const liquidCustomerDataSource = {
  name: 'liquidcustomers',
  pk: 'customerId',
  request: GetLiquidCustomers,
  entityRequest: GetLiquidCustomer,
  updateRequest: UpdateLiquidCustomer,
  createRequest: InsertLiquidCustomer,
} as DataSource


export const customerPermissions = {
  read: [1,2,16],
  write: [1, 16],
  admin: [1, 16],
};