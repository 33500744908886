import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { guid } from '../../../services/form-helpers';
import { fetchChassis } from '../../../store/slices/chassis-slice';
import { fetchGasContainer } from '../../../store/slices/gas-containers-slice';
import { fetchLiquidContainer } from '../../../store/slices/liquid-containers-slice';
import {
  fetchAssetMaintenanceRequests,
  selectMaintenanceRequestTypes, setCurrentMaintenanceRequestId, setFetchMaintenanceRequestStatus,
} from '../../../store/slices/maintenance-requests-slice';
import { fetchParts, setPartFetchStatus } from '../../../store/slices/parts-slice';
import {
  fetchWorkOrder, selectCurrentWorkOrder,
  selectWorkOrders, setCurrentWorkOrderId, setUpdateWorkOrderStatus,
} from '../../../store/slices/work-orders-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import { attachmentDataSource } from '../../UI/view/FormItems/entity-attachments-data-source';
import { createEntityAttachment } from '../../UI/view/FormItems/entity-attachments-local';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import MaintenanceRequestAttachments from '../MaintenanceDashboard/maintenance-request-attachments';
import WorkOrderMaintenanceRequests from '../MaintenanceDashboard/work-order-maintenance-requests';
import PartsConsumed from './parts-consumed';
import WorkOrderDetailsTabs from './work-order-details-tabs';
import WorkOrderForm from './work-order-form';
import WorkOrderInfo from './work-order-info';
import WorkOrderLineItemsSummary from './work-order-line-items-summary';
import WorkOrderStatus from './work-order-status';

const [MaintenanceAttachments, MRAttachmentRepo] = createEntityAttachment({

}, { ...attachmentDataSource, name: 'maintenanceAttachments' });

const [WorkOrderAttachments, WOAttachmentRepo] = createEntityAttachment({

}, { ...attachmentDataSource, name: 'workOrderAttachments' });

function WorkOrderDetails(props) {
  usePageTitle('Work Order');
  const { workOrderId } = useParams();
  const [maintenanceAttachmentsRefresh, setmaintenanceAttachmentsRefresh] = useState(guid());
  const [activeTab, setActiveTab] = useState('Requests on Work Order');
  const workOrdersFetchStatus = useSelector((state) => state.workOrder.workOrderFetchSingle.status);
  const workOrdersUpdateStatus = useSelector((state) => state.workOrder.workOrderUpdate.status);
  const workOrdersUpdateError = useSelector((state) => state.workOrder.workOrderUpdate.error);

  const partsFetchStatus = useSelector((state) => state.parts.partsFetch.status);
  // eslint-disable-next-line max-len
  const maintenanceRequestsFetchStatus = useSelector((state) => state.maintenanceRequest.maintenanceRequestsFetch.status);
  const auditLogsFetchStatus = useSelector((state) => state.audit.entityLogsFetch.status);

  const maintenanceTypes = useSelector(selectMaintenanceRequestTypes);

  const workOrderUpdateNotify = () => toast.success('Successfully Updated Work Order');

  const currentWorkOrder = useSelector(selectCurrentWorkOrder);
  const workOrders = useSelector(selectWorkOrders);

  const hasC2CInspection = currentWorkOrder?.maintenanceRequests?.find((mr) => maintenanceTypes && maintenanceTypes[mr.maintenanceRequestTypeId]?.name === 'C2C Inspection');

  const c2cSelector = useSelector((state) => state.containerC2C?.current?.data);
  const preventClose = hasC2CInspection && !c2cSelector?.isCompleted;

  const breadcrumbs = useMemo(() => [
    {
      name: 'Maintenance',
      icon: 'wrench-adjustable',
      to: '/maintenance',
    },
    {
      name: 'Work Orders',
      icon: 'clipboard',
      to: '/maintenance#Work Orders',
    },
    {
      name: workOrderId,
      icon: 'wrench',
      to: '',
    },
  ], [workOrderId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (workOrdersUpdateStatus === 'succeeded') {
      workOrderUpdateNotify();
      dispatch(setUpdateWorkOrderStatus('idle'));
    } else if (workOrdersUpdateStatus === 'failed') {
      toast.error(`Failed to update work order: ${workOrdersUpdateError}`);
      dispatch(setUpdateWorkOrderStatus('idle'));
    }
  }, [workOrdersUpdateStatus]);

  const assetFetchCallsByType = {
    1: fetchGasContainer,
    2: fetchLiquidContainer,
    4: fetchChassis,
  };

  const loadData = useCallback(async () => {
    try {
      const workOrder = await dispatch(fetchWorkOrder(workOrderId)).unwrap();
      dispatch(setCurrentWorkOrderId(workOrderId));

      await Promise.all([
        dispatch(fetchParts(workOrder?.locationId)).unwrap(),
        dispatch(fetchAssetMaintenanceRequests({
          assetType: workOrder.assetTypeId,
          assetSerialNumber: workOrder.assetSerialNumber,
        })).unwrap(),
        dispatch(
          assetFetchCallsByType[workOrder.assetTypeId](workOrder.assetSerialNumber),
        ).unwrap(),
      ]);

      dispatch(setPartFetchStatus('idle'));
      dispatch(setFetchMaintenanceRequestStatus('idle'));
    } catch (error) {
      toast.error(`Failed to load data for page ${error.message}`);
    }
  }, [
    fetchAssetMaintenanceRequests, workOrderId,
    dispatch, partsFetchStatus, fetchParts,
    fetchWorkOrder, setCurrentWorkOrderId,
  ]);

  useEffect(() => {
    loadData();
  }, []);

  const loading = [
    partsFetchStatus,
    workOrdersFetchStatus,
    maintenanceRequestsFetchStatus,
    auditLogsFetchStatus,
    workOrdersUpdateStatus,
  ].includes('loading');

  const list = useMemo(
    () => currentWorkOrder?.attachmentList ?? [],
    [workOrders],
  );

  const [
    maintenanceRequestAttachmentsModalShow,
    setMaintenanceRequestAttachmentsModalShow,
  ] = useState(false);

  const handleMaintenanceRequestAttachmentsModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setMaintenanceRequestAttachmentsModalShow(false);
  };
  const handleMaintenanceRequestAttachmentsModalShow = (assetType) => {
    setMaintenanceRequestAttachmentsModalShow(true);
  };

  useEffect(() => {
    if (!maintenanceRequestAttachmentsModalShow) {
      setmaintenanceAttachmentsRefresh(guid());
    }
  }, [maintenanceRequestAttachmentsModalShow]);

  const mrAttachments = useMemo(() => currentWorkOrder?.maintenanceRequests?.map((mr) => ({ entityPK: mr.maintenanceRequestId, entityTypeId: 6 })), [currentWorkOrder]);
  return (
    <Container
      fluid
      className="h-100 d-flex flex-column"
    >
      <PageHeader
        header="Work Order"
        breadcrumbs={breadcrumbs}
      />
      <Row className="h-100 flex-grow-1 overflow-auto">
        <Col lg={3} className="h-100 fluid flex-column overflow-auto">
          <WorkOrderInfo workOrderStatusIsLoading={loading} />
          <WorkOrderAttachments
            entityPk={workOrderId}
            field={{
              permissions: null,
              entityName: 'Work Order Attachments',
              entityTypeId: 5,
            }}
          />
          <MaintenanceAttachments
            field={{
              permissions: null,
              entityName: 'Maint. Request Attachments',
              entityTypeId: 6,
            }}
            key={maintenanceAttachmentsRefresh}
            append={mrAttachments || []}
            readOnly
          />
          <WorkOrderStatus preventClose={preventClose} setActiveTab={setActiveTab} />
          <WorkOrderLineItemsSummary />
        </Col>
        <Col className="h-100 d-flex fluid">
          <WorkOrderDetailsTabs
            assetTypeId={currentWorkOrder?.assetTypeId}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            workOrderId={workOrderId}
            hasC2CInspection={hasC2CInspection}
            generalInfoContents={(
              <WorkOrderForm
                currentWorkOrder={currentWorkOrder}
                workOrderStatusIsLoading={loading}
              />
            )}
            maintenanceRequestContents={(
              <WorkOrderMaintenanceRequests
                type="requests"
                handleMaintenanceRequestAttachmentsModalShow={
                  handleMaintenanceRequestAttachmentsModalShow
                }
              />
            )}
            outstandingRequestContents={(
              <WorkOrderMaintenanceRequests
                currentWorkOrder={currentWorkOrder}
                handleMaintenanceRequestAttachmentsModalShow={
                  handleMaintenanceRequestAttachmentsModalShow
                }
                type="outstanding"
              />
            )}
            auditLogContent={(
              <EntityAuditLogs
                tableSchema="Maintenance"
                entityId={5}
                tablePK={workOrderId}
              />
            )}
            partsConsumedContent={(
              <PartsConsumed />
            )}
          />
        </Col>
      </Row>
      <MaintenanceRequestAttachments
        show={maintenanceRequestAttachmentsModalShow}
        onHide={handleMaintenanceRequestAttachmentsModalClose}
      />
    </Container>
  );
}

export default WorkOrderDetails;
