import React from "react";
import { Col, Form, Row } from "react-bootstrap";

export const InlineField = ({renderItem, field, rowIdentity, editTableProps}) => {
  return (
    <Row className="uiRow">
      <Col style={{
        width: field.width ? field.width : undefined,
        maxWidth: field.width ? field.width : undefined,
      }}>
        <Form.Label>{field.label}</Form.Label>
      </Col>
      <Col>
        {renderItem({ ...field, inline: false, label: undefined }, rowIdentity, editTableProps)}
      </Col>
    </Row>
  );
}