import { useEffect, useState } from 'react';

const prefix = '³He';

const usePageTitle = (title) => {
  const [doctitle, setDocTitle] = useState(title);

  useEffect(() => {
    document.title = `${prefix} | ${doctitle}`;
  }, [doctitle]);

  return [doctitle, setDocTitle];
};

export { usePageTitle };
