
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { billingPermissions } from "./billing-datasource";

import { DataSource } from "../../UI/view/data-source";
import { GetDewarProductTypeList, InsertDewarProductType, UpdateDewarProductType } from "../../../services/requests/billing/dewar-billing-requests";

export const dewarProductTypeDS = {
  request: GetDewarProductTypeList,
  pk: 'id',
  name: 'dewarProductType',
  updateRequest: UpdateDewarProductType,
  createRequest: InsertDewarProductType,
} as DataSource;

export const dewarProductTypeEdit = {
  editMode: true,
  permissions: billingPermissions,
  items: [
    {
      type: FormItemType.fieldset,
      items: [
        {
          label: 'Product Number',
          name: 'productNumber',
          required: true,
        },
        {
          label: 'Description',
          name: 'description',
          required: true,
        },
        {
          label: 'Product Category',
          name: 'productCategory',
        },
        {
          label: 'Default Volume',
          number: true,
          name: 'defaultVolume',
          required: true,
        },
        {
          label: 'Jdecodes',
          name: 'jdecodes',
        },
      ]
    }
  ]
} as FormView;

export const dewarProductTypeCreate = {
  ...dewarProductTypeEdit,
  editMode: false
}

export const dewarProductTypeList = {
  activeFlag: 'isActive',
  filters: [
    {
      type: FilterType.simple,
      fieldName: 'isActive',
      label: 'Active',
      filterValue: true
    }
  ],
  permissions: billingPermissions,
  create: dewarProductTypeCreate,
  update: dewarProductTypeEdit,
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      Header: 'Id',
      accessor: 'id',
      defaultSort: true
    },
    {
      Header: 'Product Number',
      accessor: 'productNumber',
    },
    {
      Header: 'Description',
      accessor: 'description',
    },
    {
      Header: 'Product Category',
      accessor: 'productCategory',
    },
    {
      Header: 'Default Volume',
      accessor: 'defaultVolume',
    },
    {
      Header: 'Jde Codes',
      accessor: 'jdecodes',
    },
    {
      Header: 'Created On',
      accessor: 'createdOn',
    },
    {
      Header: 'Created By',
      accessor: 'createdBy',
    },
    {
      Header: 'isActive',
      accessor: 'isActive',
      hidden: true
    },
  ]
} as ListView;


export const DewarProductTypeList = createConnectedListView(dewarProductTypeList, dewarProductTypeDS);


