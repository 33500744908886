import React from 'react';

function Card(props) {
  return (
  // <RbCard {...props}>
  //     <RbCard.Header>{props.header}</RbCard.Header>
  //     <RbCard.Body>
  //     {props.children}
  //     </RbCard.Body>
  // </RbCard>
    <div className={`card ${props.className}`}>
      <div className="card-header">
        <h3 className="card-title">
          {props.header}
        </h3>
      </div>
      <div className="card-body">
        {props.children}
      </div>
    </div>
  );
}

export default Card;
