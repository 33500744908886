import React from "react";
import { Col, Container, Form, Nav, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

export const FormLabelField = ({width, height, field, renderItem, rowIdentity, editTableProps, watch}) => {

  const hide = field.hideIf ? field.hideIf({editTableProps, rowIdentity, watch}) : false;
  const val = watch(field.name);
  const link = field.link ? field.link({watch}) : null;
  const linkElement = <NavLink style={{fontWeight: 400, display: 'block', ...field.style}} target={"_blank"} to={link}>{val}</NavLink>;
  return hide ? null : (
    <Form.Group className="mb-1" style={field.style}>
    {field.label && <Form.Label>{field.label}</Form.Label>}
    {!link && <Form.Label style={{fontWeight: 400, display: 'block', ...field.style}}>{val}</Form.Label>}
    {link && linkElement}
  </Form.Group>
  )
}