import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { setCreateWorkOrderStatus } from '../../../store/slices/work-orders-slice';
import CreateWorkOrderForm from './create-work-order-form';

function CreateWorkOrder(props) {
  const workOrderCreateStatus = useSelector(
    (state) => state.workOrder.workOrderCreate.status,
  );
  const workOrderCreateError = useSelector(
    (state) => state.workOrder.workOrderCreate.error,
  );

  const currentWorkOrderId = useSelector((
    state,
  ) => state.workOrder.currentWorkOrder.workOrderId);

  const workOrderCreateSuccessNotify = () => toast.success('Successfully created Work Order');
  const workOrderCreateFailedNotify = (error) => toast.error(`Failed to create Work Order\n${error}`);

  const dispatch = useDispatch();
  useEffect(() => {
    if (workOrderCreateStatus === 'succeeded') {
      workOrderCreateSuccessNotify();
      dispatch(setCreateWorkOrderStatus('idle'));
      props.onHide();
    }
    if (workOrderCreateStatus === 'failed') {
      workOrderCreateFailedNotify(workOrderCreateError);
      dispatch(setCreateWorkOrderStatus('idle'));
      props.onHide();
    }
  }, [workOrderCreateStatus, workOrderCreateError]);

  const createWorkOrderStatusIsLoading = workOrderCreateStatus === 'loading';

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          Create New Work Order
        </Modal.Title>
      </Modal.Header>
      <CreateWorkOrderForm
        show={props.createModalShow}
        asset={props.asset}
        onHide={props.onHide}
        createModalAssetType={props.createModalAssetType}
        createWorkOrderStatusIsLoading={createWorkOrderStatusIsLoading}
      />
    </>

  );
}

export default CreateWorkOrder;
