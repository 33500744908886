import './table.scss';

import { range } from 'lodash';
import React, {
  forwardRef, useEffect, useImperativeHandle, useMemo,
} from 'react';
import {
  Button, ButtonGroup, Form, Table as BootstrapTable,
} from 'react-bootstrap';
import { BsArrowDownUp, BsSortDown, BsSortUp } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import {
  useFilters,
  useGlobalFilter,
  usePagination, useRowSelect, useSortBy, useTable,
} from 'react-table';

import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';

// eslint-disable-next-line object-curly-newline
const PaginatedSelectTable = forwardRef(({
  columns,
  data,
  rowProps,
  initialState,
  setTableFilterUpdate,
  customTableClass,
  forcePaginationControl,
  manualGlobalFilter,
  manualPagination,
  manualSorting,
  onPaginationChange,
  onSortingChange,
  pageCount: pageCountProp,
  defaultPageSize,
}, ref) => {
  const startState = { pageIndex: 0, pageSize: defaultPageSize || 25, ...initialState };
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);

  const dateFormatter = (dt) => {
    return formatDate(dt, datePreference);
  };

  const defaultColumn = useMemo(() => ({
    minWidth: 10,
    maxWidth: 100,
    width: 50,
  }), []);

  const tablePropsObj = {
    columns,
    data,
    initialState: startState,
    autoResetFilters: false,
    defaultColumn,
    autoResetSortBy: !manualPagination,
    autoResetPage: !manualPagination,
    autoResetGlobalFilter: false,
    manualGlobalFilter,
    manualPagination,
    manualSortBy: manualSorting,
    onPaginationChange,
    onSortingChange,
  };
  if (pageCountProp) {
    tablePropsObj.pageCount = pageCountProp;
  }
  const instance = useTable(
    tablePropsObj,
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = instance;

  useEffect(() => {
    if (onPaginationChange && manualPagination) {
      onPaginationChange({ pageIndex, pageSize });
    }
  }, [pageIndex, pageSize]);

  useEffect(() => {
    if (onSortingChange && manualSorting) {
      onSortingChange(sortBy);
    }
  }, [sortBy]);

  useImperativeHandle(ref, () => instance);

  const getPageRange = (currentPage, numPages) => {
    if (numPages < 5) {
      return range(1, numPages + 1);
    }
    if (currentPage <= 3) {
      return range(1, 6);
    }
    if (currentPage + 3 > numPages) {
      return range(numPages - 4, numPages + 1);
    }
    return range(currentPage - 2, currentPage + 3);
  };

  const getTooltip = (column, row) => {
    if (!column.title) return undefined;
    if (typeof column.title === 'string') {
      return column.title;
    }
    return column.title(column, row, dateFormatter);
  };

  useEffect(() => {
    if (setTableFilterUpdate) {
      setTableFilterUpdate(rows.map((row) => row.original));
    }
  }, [setTableFilterUpdate, rows]);

  return (
    // apply the table props
    <div className="d-flex flex-column overflow-auto flex-grow-1 justify-content-between">
      <div className="flex-grow-1 overflow-auto">
        <BootstrapTable size="sm" striped hover {...getTableProps()} className={`custom-table ${customTableClass ?? ''}`}>
          <thead>
            {// Loop over the header rows
              headerGroups.map((headerGroup) => (
                // Apply the header row props
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {// Loop over the headers in each row
                    headerGroup.headers.map((column) => (
                      // Apply the header cell props
                      <th
                        {...column.getHeaderProps({
                          ...column.getSortByToggleProps(),
                          style: {
                            ...column.getSortByToggleProps()?.style,
                            width: column.fixedWidth,
                          },
                        })}
                      >
                        <div className="d-flex justify-content-between">
                          <div>
                            {// Render the header
                              column.render('Header')
                            }
                          </div>
                          <div>
                            {column.canSort && (
                              <span>
                                {
                                  /* eslint-disable */
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? <BsSortDown />
                                      : <BsSortUp />
                                    : <BsArrowDownUp color="gray" />}
                              </span>
                            )}
                          </div>
                        </div>
                      </th>
                    ))
                  }
                </tr>
              ))
            }
          </thead>
          {/* Apply the table body props */}
          <tbody {...getTableBodyProps()}>
            {// Loop over the table rows
              page.map((row) => {
                // Prepare the row for display
                prepareRow(row);
                return (
                  // Apply the row props
                  <tr data-cy="table-row" {...row.getRowProps(rowProps(row))} >
                    {// Loop over the rows cells
                      row.cells.map((cell) => (
                        <td title={getTooltip(cell.column, row)} {...cell.getCellProps([
                          {
                            className: cell.column.className,
                            style: cell.column.style,
                          },
                        ])}
                        >
                          {// Render the cell contents
                            cell.render('Cell')
                          }
                        </td>
                      ))
                    }
                  </tr>
                );
              })
            }
          </tbody>
        </BootstrapTable>
      </div>
      {(data.length > 25 || forcePaginationControl || manualPagination) &&
        <div className="w-100 bg-white bottom-0 pb-2 pt-2 pr-4 pl-4">
          <div className="pagination d-flex align-items-center justify-content-between">

            <Form.Group className="pr-2">
              <Form.Select
                size="sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[25, 50, 75, 100, data.length].map((size, i) => (
                  <option key={size} value={size} disabled={i == 4 && manualPagination}>
                    Show
                    {' '}
                    {i === 4 ? 'ALL' : size}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <ButtonGroup size="sm">
              <Button
                variant="outline-primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {'<'}
              </Button>
              {getPageRange(pageIndex + 1, pageOptions.length).map((pg) => (
                <Button
                  key={`button-pg-${pg}`}
                  variant="outline-primary"
                  onClick={() => gotoPage(pg - 1)}
                  active={pageIndex + 1 === pg}
                >
                  {pg}
                </Button>
              ))}
              <Button
                variant="outline-primary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'>'}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </Button>
            </ButtonGroup>
            <div>
              Page
              {' '}
              <strong>
                {pageIndex + 1}
                {' '}
                of
                {' '}
                {pageOptions.length}
              </strong>
              {' '}
            </div>
          </div>
        </div>
      }
    </div>
  );
});

export default PaginatedSelectTable;
