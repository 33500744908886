import { formatDate } from "../../../../services/format";
import { getGasContainers } from "../../../../services/requests";
import { deleteInvoice, getBillingHeader, getGasInvoices, recalcInvoice, reverseBilling, UpdateBilling } from "../../../../services/requests/billing/gas-billing-requests";
import { FormItemType } from "../../../UI/view/component-map";
import { DataSource } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createConnectedListView2, ListView, ListViewColumnType } from "../../../UI/view/list-view";
import { createConnectedView2 } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { gasBillingRecentDeliveriesDS } from "./gas-billing-datasource";

export const gasinvoicesDS = {
  request: getGasInvoices,
  pk: 'billingId',
  name: 'gasBillingInvoice',
  updateRequest: UpdateBilling,
  entityRequest: getBillingHeader,
} as DataSource;


const gasVolume = (psig: number, temperature: number, waterVolume: number, heliumPercentage: number = 1) => {
  const waterVolumeAtPT = waterVolume * (1 + (7.4e-7 * psig));
  const volume = (
    (((psig + 14.7000) / 14.7000) * (529.7000 / (temperature + 459.67000)) * (1.00049 / (1 + (((10.2297e-5) - ((19.2998e-8) * (temperature + 459.67) + ((1.1836e-10) * Math.pow((temperature + 459.67000), 2))) * (psig + 14.7) - ((2.217e-10) * Math.pow((psig + 14.7000), 2))))))) * waterVolumeAtPT
  );
  return volume * heliumPercentage;
}


export const getBillingInvoiceEdit = {
  editMode: true,
  pageHeader: 'Gas Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Gas Billing',
      icon: 'currency-dollar',
      to: '/billing/gasproduct#Invoices',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              header: ({ gasBillingInvoice }) => gasBillingInvoice?.current?.data?.customerName,
              infoListItems: [

                {
                  label: 'Customer',
                  name: 'customerName'
                },
                {
                  label: 'Customer Id',
                  name: 'customerId'
                },
                {
                  label: 'Invoice Number',
                  name: 'billingId'
                },
                {
                  label: 'JDE Batch Number',
                  name: 'jdebatchNumber'
                },
                {
                  label: 'Date Invoiced',
                  name: 'dateInvoiced',
                  value: ({ dateInvoiced }) => formatDate(dateInvoiced)
                },
              ]
            },
            {
              type: FormItemType.fileAttachments,
              entityTypeId: 13,
              entityName: 'Billing Invoice',
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Gas Invoice',
              footerItems: [
                {
                  type: FormItemType.button,
                  variant: 'danger',
                  style: { marginLeft: 2 },
                  label: 'Backout...',
                  hideIf: ({ watch }) => {
                    const jdebypassed = watch('jdebypassed');
                    const jdeBatchNumber = watch('jdebatchNumber');
                    if (!jdeBatchNumber || jdebypassed) return true;
                    return false;
                  },
                  handler: async ({ watch, confirm }) => {

                    const confirmed = await confirm('This invoice may have already been processed by JDE. This action will only undo changes within VTS. Please verify the necessary steps have been performed in JDE to reverse this invoice.')

                    if (confirmed) {
                      const success = await reverseBilling({ billingId: watch('billingId') });
                      alert('Invoice has been backed out successfully.');
                      location.reload();
                    }

                  }
                },
                {
                  type: FormItemType.button,
                  label: 'Delete Invoice',
                  variant: 'secondary',
                  disabled: ({ watch }) => watch('jdebatchNumber') != null,
                  style: { marginLeft: 2 },
                  handler: async ({ getValues, watch, redirect, confirm }) => {
                    let confirmed = false;
                    confirmed = await confirm('Are you sure you want to delete this invoice?')
                    if (confirmed) {
                      const success = await deleteInvoice(watch('billingId'));
                      await confirm('Invoice has been marked as deleted.', true);
                    }
                  }
                },
                {
                  type: FormItemType.submitButton,
                  label: 'Save',
                }
              ],
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Details',
                  items: [
                    {
                      label: 'Shipping Order #',
                      name: 'socpo',
                      disabled: () => true
                    },
                    {
                      label: 'Container Started',
                      name: 'containerId',
                      disabled: () => true
                    },
                    {
                      label: 'Container Ended',
                      name: 'containerIdended',
                      type: FormItemType.typeahead,
                      options: async () => {
                        let list = await getGasContainers();
                        return [...list.map(t => ({ id: t.serialNumber, label: `${t.serialNumber}` }))]
                      },
                    },

                    {
                      label: 'PO #',
                      name: 'ponumber',
                    },
                    {
                      label: 'Release #',
                      name: 'releaseOrderNumber',
                    },
                    {
                      label: 'Tracking #',
                      name: 'trackingNumberId',
                      type: FormItemType.labelField,
                      link: ({ watch }) => `https://vts.linde.grp/Helium/VTS/Gas/Shipping.aspx?gtrkno=${watch('trackingNumberId')}`,
                    },
                    {
                      label: 'Delivery Comments',
                      name: 'deliveryComments',
                      type: FormItemType.textarea
                    },
                    {
                      label: 'Approval Comments',
                      name: 'approvalComments',
                      type: FormItemType.textarea
                    },
                    {
                      label: 'Bypass Invoice',
                      name: 'jdebypassed',
                      type: FormItemType.checkboxField,
                      width: 140,
                      fitToContent: true,
                      hideIf: ({ watch }) => watch('jdebatchNumber')
                    },
                    {

                      name: 'billingId',
                      hideIf: () => true
                    },
                  ]
                }
              ]
            },
            {
              label: 'Invoice Contents',
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  label: 'Save',
                }
              ],
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Contents',
                  items: [
                    {
                      type: FormItemType.editTable,
                      name: 'gasBillingDetails',
                      variant: 'tabular',
                      pk: 'id',
                      toolbar: [
                        {
                          type: FormItemType.button,
                          label: 'Recalculate Net',
                          handler: async ({ getValues, setValue }) => {
                            const { gasBillingDetails } = getValues();
                            const recalced = await recalcInvoice(getValues());
                            let i = 0;
                            for (let detail of recalced.gasBillingDetails) {
                              setValue(`gasBillingDetails[${i}].volumeCalcStart`, recalced.gasBillingDetails[i].volumeCalcStart);
                              setValue(`gasBillingDetails[${i}].volumeCalcEnd`, recalced.gasBillingDetails[i].volumeCalcEnd);
                              setValue(`gasBillingDetails[${i}].netJDEVolume`, recalced.gasBillingDetails[i].netJDEVolume);
                              i++;
                            }
                          }
                        }
                      ],
                      columns: [
                        {
                          Header: 'Bank',
                          accessor: 'bank',
                          width: 60,
                          minWidth: 60,
                          formItemProps: {
                            name: 'bank',
                            type: FormItemType.select,
                            defaultValue: null,
                            width: 60,
                            minWidth: 60,
                            options: [
                              { id: null, text: '' },
                              { id: 'A', text: 'A' },
                              { id: 'B', text: 'B' },
                              { id: 'C', text: 'C' },
                              { id: 'CT', text: 'CT' },
                              { id: 'D', text: 'D' },
                              { id: 'E', text: 'E' },
                              { id: 'F', text: 'F' },
                              { id: 'G', text: 'G' },
                              { id: 'H', text: 'H' },
                              { id: 'I', text: 'I' },
                              { id: 'J', text: 'J' },
                              { id: 'K', text: 'K' },
                              { id: 'L', text: 'L' },
                              { id: 'M', text: 'M' },
                              { id: 'X', text: 'X' },
                            ]
                          }
                        },
                        {
                          Header: 'Pressure Start',
                          accessor: 'pressureEnded',
                          formItemProps: {
                            name: 'pressureEnded',
                          }
                        },
                        {
                          Header: 'Temp Start',
                          accessor: 'tempEnded',
                          formItemProps: {
                            name: 'tempEnded',
                          }
                        },
                        {
                          Header: 'Volume Start',
                          accessor: 'waterVolumeEnded',
                          formItemProps: {
                            name: 'waterVolumeEnded',
                          }
                        },
                        {
                          Header: 'Volume',
                          accessor: 'volumeCalcEnd',
                          formItemProps: {
                            name: 'volumeCalcEnd',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Pressure End',
                          accessor: 'pressureStarted',
                          formItemProps: {
                            name: 'pressureStarted',
                          }
                        },
                        {
                          Header: 'Temp End',
                          accessor: 'tempStarted',
                          formItemProps: {
                            name: 'tempStarted',
                          }
                        },
                        {
                          Header: 'Volume End',
                          accessor: 'waterVolumeStarted',
                          formItemProps: {
                            name: 'waterVolumeStarted',
                          }
                        },
                        {
                          Header: 'Volume',
                          accessor: 'volumeCalcStart',
                          formItemProps: {
                            name: 'volumeCalcStart',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Net',
                          accessor: 'netJDEVolume',
                          formItemProps: {
                            name: 'netJDEVolume',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                      ]
                    }
                  ]
                }

              ]
            },
            {
              label: 'Recent Deliveries',
              items: [
                {
                  type: FormItemType.customField,
                  name: 'recentDeliveries',
                  getComponent: () => RecentDeliveriesView
                }
              ]

            }
          ]
        }
      ]
    }
  ]

} as FormView;


export const [GasBillingInvoiceEdit, GasBillingInvoiceEditRepo] = createConnectedView2({ form: getBillingInvoiceEdit, dataSource: gasinvoicesDS as DataSource });


export const RecentDeliveries = {
  columns: [
    {
      accessor: 'dateEnded',
      Header: 'Date of Delivery',
      defaultSort: true,
      type: ListViewColumnType.date,
    },
    {
      accessor: 'actualVolumeSentToJDE',
      Header: 'Volume Delivered',
    }
  ]
} as ListView;

export const [RecentDeliveriesView, RecentDeliveriesViewRepo] = createConnectedListView2(RecentDeliveries, gasBillingRecentDeliveriesDS);
