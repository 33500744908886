import React from "react";

export const BoundingBox = (field) => {
  const {width, height, field: bb, renderItem, rowIdentity, editTableProps, watch} = field;
  const hide = bb.hideIf ? bb.hideIf({editTableProps, rowIdentity, watch}) : false;
  return hide ? null : (
    <div style={{width: bb.width || '100%', height: bb.height || '100%'}}>
      {
        bb.items.map(item => renderItem(item, rowIdentity, editTableProps))
      }
    </div>
  )
}