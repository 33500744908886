/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-len */
import './home.scss';

import React, {
  useEffect, useMemo,
  useRef, useState,
} from 'react';
import {
  Accordion, Card, Col, Container, Image,
  Row, useAccordionButton,
} from 'react-bootstrap';
import { BsDashLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import ReactTimeAgo from 'react-time-ago';

import bannerImage from '../../../assets/images/MainBanner.png';
import { fetchUserAuditLogs, selectUserAuditLogs } from '../../../store/slices/audit-slice';
import { selectCurrentUserId, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectUsers } from '../../../store/slices/users-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import ChangesPopover from '../../UI/molecules/ChangesPopover/changes-popover';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import {
  actions, entities, links,
} from '../AuditLog/log-table-values';

function CustomToggle({ children, eventKey }) {
  const decoratedOnClick = useAccordionButton(eventKey, () => console.log('totally custom!'));

  return (
    <button
      type="button"
      className="btn-tool p-0 m-0 border-0 plus-minus-button"
      onClick={decoratedOnClick}
    >
      <span><BsDashLg /></span>
    </button>
  );
}

function Home(props) {
  const [doctitle, setDocTitle] = usePageTitle('Home');
  const [modalShow, setModalShow] = useState(false);

  const toggleModal = () => setModalShow(!modalShow);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);
  const dispatch = useDispatch();
  const users = useSelector(selectUsers);

  const currentUser = useSelector(selectCurrentUserId);
  const recentActivity = useSelector(selectUserAuditLogs);
  const fetchActivityStatus = useSelector((state) => state.audit.userLogsFetch.status);

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const breadcrumbs = useMemo(() => [], []);

  const getDisplayName = (OID) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(users)) {
      if (value.activeDirectoryId === OID) {
        return value.displayName;
      }
    }
    return null;
  };

  useEffect(() => {
    dispatch(fetchUserAuditLogs(currentUser));
  }, []);

  const recentActivityTableInstance = useRef(null);

  const activityColumns = useMemo(() => [
    {
      Header: 'Action',
      id: 'auditAction',
      accessor: (row) => (`${actions[row.auditAction]} ${entities[row.tableName] || row.tableName}`),
    },
    {
      Header: 'Id',
      accessor: 'tablePK',
      Cell: ({ value, row }) => (
        row.original.tableName in links
          ? <NavLink to={`${links[row.original.tableName]}${value}`}>{value}</NavLink>
          : value
      ),
    },
    {
      Header: 'Date',
      accessor: 'auditDate',
      Cell: ({ value }) => <ReactTimeAgo date={Date.parse(`${value}Z`)} locale="en-US" />,
    },
    {
      Header: 'Changes',
      id: 'changes',
      Cell: ({ value, row }) => (
        <ChangesPopover row={row} />
      ),

    },

  ], []);

  const activityData = useMemo(() => {
    if (recentActivity) {
      const activity = Object.values(recentActivity).sort(
        (a, b) => Date.parse(b.auditDate) - Date.parse(a.auditDate),
      );
      return activity;
    }
    return [];
  }, [recentActivity]);

  const activityStatusIsLoading = fetchActivityStatus === 'loading';

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column">
      <PageHeader
        header={(
          <>
            <sup>3</sup>
            He Home
          </>
        )}
        breadcrumbs={breadcrumbs}
      />
      <Row>
        <Col>
          <Image src={bannerImage} className="img-responsive w-100 my-2" />
        </Col>
      </Row>
      <Row className="flex-grow-1 pb-2 overflow-auto">
        <Col className="h-100">
          <Card className="card-primary card-outline h-100 overflow-auto d-flex flex-column m-0">
            <Card.Header>
              <h3 className="card-title">
                <i className="ion ion-ios-color-wand mr-1" />
                User Guide
              </h3>
            </Card.Header>
            <div className="card-body flex-grow-1 overflow-auto">
              <p className="lead">
                <b>
                  Welcome to
                  {' '}
                  <sup>3</sup>
                  He (Helium-3)
                </b>
              </p>
              <p><i>As part of the Helium and Rare Gas's Team you've been granted access. Please take a moment to familiarize youself with the environment.</i></p>
              <p><b>Assets</b></p>
              <p>
                This page containers tables for the four assets related to helium: Liquid Containers, Gas Containers, Packaged Products (Dewars, Cylinders), and Chassis.
                From here you can search for an asset, create a new asset, and navigate to an asset details page
              </p>
              <p>
                On an Asset's details page, you can view and update information about the asset. You can also add and download attachments for the asset.
                You can also manage maintenance items (Maintenance Request and Work Orders) for an asset
              </p>
              <p><b>Maintenance</b></p>
              <p>
                For the assets that will have work orders (Liquid Containers, Gas Containers, Chassis), this view lists all assets that have maintenance requests on them.
                From here you can add maintenance requests to a work order.
              </p>
              <p>
                On the Request Type Management page, you can view planned and unplanned maintenance request types. Additionally, you can add, edit, and delete unplanned maintenance request types.
                From this page you can view maintenance request type templates by selecting a maintenance request type. You can add, edit, and remove line items from the associated template.
              </p>
              <p>
                From the Parts Inventory page, you can view all the parts in your location. You can also create and delete parts. By clicking the checkboxes next to the parts, you can select parts
                to be included in a purchase request.
              </p>
            </div>
          </Card>
        </Col>
        <Col className="h-100">
          <Accordion defaultActiveKey="0" className=" h-100">
            <Card className={`card-primary card-outline h-100 d-flex flex-column m-0 ${activityStatusIsLoading ? ' creation-loading' : ''}`}>
              <Card.Header>
                <h3 className="card-title">Your Recent Activity</h3>
                <div className="card-tools h-0">
                  {/* <CustomToggle eventKey="0">Click me!</CustomToggle> */}
                </div>
              </Card.Header>
              <Accordion.Collapse eventKey="0" className="overflow-auto flex-grow-1 d-flex">
                <PaginatedSelectTable
                  columns={activityColumns}
                  data={activityData}
                  ref={recentActivityTableInstance}
                  rowProps={() => ({})}
                />

              </Accordion.Collapse>
              {activityStatusIsLoading && <LoadingContent />}
            </Card>
          </Accordion>
        </Col>
      </Row>
    </Container>

  );
}

export default Home;
