import { GetRTUDetailsDTO, getRTU, getRTUDetails, getRTUList, getUsageMetrics, updateLocation, updateRTU } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";


export const rtuDetailsDataSource = {
  entityRequest: GetRTUDetailsDTO,
  additionalSelector: (state) => {
    return state.rtu?.current?.data;
  },
  entityRequestArgs: ({getValues, additionaSelector}) => {
    if(!additionaSelector?.rtuId) return null;
    const entity = additionaSelector;
    const vals = getValues();
    return `${entity?.rtuId}_${entity?.channelId}_${vals.days ?? 30}_${vals.viewAllReadings ?? false}`;
  },
  name: 'rtuDetails',
  pk: 'rtupk',
} as any;

export const rtuDataSource = {
  request: getRTUList,
  entityRequest: getRTU,
  updateRequest: updateRTU,
  additionalRequests: [
    {
      name: 'usageMetrics',
      request: getUsageMetrics,
    },
  ],
  name: 'rtu',
  pk: 'rtupk',
} as DataSource;


export const rtuPermissions = {
  read: [1, 2, 13],
  write: [1, 2, 13],
  admin: [1, 13]
};