
import { getGasInvoice, getGasInvoices, getGasPendingDeliveries, getGasRecentDeliveries, InsertBilling } from "../../../../services/requests/billing/gas-billing-requests";
import { DataSource } from "../../../UI/view/data-source";

export const gasBillingDataSource = {
  name: 'gasbilling',
  pk: 'billingId',
  request: getGasInvoices,
  serverSideEvents: true
} as DataSource;

export const gasBillingPendingDeliveryDataSource = {
  name: 'gasbillingDelivery',
  pk: 'trackingNumberId',
  request: getGasPendingDeliveries,
  entityRequest: getGasInvoice,
  updateRequest: InsertBilling,
} as DataSource;



export const gasBillingRecentDeliveriesDS = {
  name: 'gasbillingRecentDelivery',
  pk: 'billingId',
  request: getGasRecentDeliveries,
  requestArgs: (state) => {
    return state.gasBillingInvoice.current?.data?.customerId;
  },
} as DataSource;

/* export const liquidHistoryDataSource = {
  request: getCustomerLiquidContainerHistory,
  requestArgs: (state) => {
    return state.liquidcustomers.current.data.customerId;
  },
  name: 'liquidHistory',
  pk: 'trackingNumberID'
} as DataSource */