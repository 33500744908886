import React, { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchPurchaseRequests } from '../../../store/slices/purchase-request-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import PurchaseRequestsTabs from './purchase-requests-tabs';

function PurchaseRequests() {
  usePageTitle('Purchase Requests');

  const breadcrumbs = useMemo(() => [
    {
      name: 'Purchase Requests',
      icon: 'receipt',
      to: 'maintenance/purchase-requests',
    },
  ], []);

  const dispatch = useDispatch();
  // eslint-disable-next-line max-len
  const purchaseRequestsFetchStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsFetch.status);

  useEffect(() => {
    if (purchaseRequestsFetchStatus === 'idle' || purchaseRequestsFetchStatus === 'failed') {
      dispatch(fetchPurchaseRequests());
    }
  }, [purchaseRequestsFetchStatus, dispatch]);

  useEffect(() => {
    dispatch(fetchPurchaseRequests());
  }, []);

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column mb-2">
      <PageHeader header="Purchase Requests" breadcrumbs={breadcrumbs} />
      <PurchaseRequestsTabs />
    </Container>
  );
}

export default PurchaseRequests;
