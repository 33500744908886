
import { createDewarDamagesInvoice, getDewarDamageReport, getDewarDamageReportHeaders } from "../../../services/requests/billing/dewar-billing-requests";
import { DataSource } from "../../UI/view/data-source";


export const dewarDamagesDataSource = {
  name: 'dewarDamages',
  request: getDewarDamageReportHeaders,
  updateRequest: createDewarDamagesInvoice,
  skipLocalUpdate: true,
  entityRequest: getDewarDamageReport,
  pk: 'pk'
} as DataSource

export const billingPermissions = {
  read: [1, 14, 15, 18],
  write: [1, 15, 18],
  admin: [1, 15],
};