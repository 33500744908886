import React, { useEffect } from "react";
import { Form } from "react-bootstrap"

export const CheckBox = ({ format, setValue, watch, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, errors, formState, editTableProps }) => {
  return (
    <Form.Check
      className="flex-row-reverse form-switch"
      style={{ gap: 7 }}
      inline
      {...register(field.name)}
      type="switch"
      formControlName={field.name}
      label={field.label}
      title={field.tooltip}
    />
  )
}