import { createListView, FilterType, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { userStore } from "../../../store/store";
import { userCreate } from "./user-create";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createView } from "../../UI/view/view";

export const userList: ListView = {
  enableExport: true,
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      Header: 'Display Name',
      accessor: 'displayName',
      defaultSort: true,
    },
    {
      Header: 'First Name',
      accessor: 'firstName'
    },
    {
      Header: 'Last Name',
      accessor: 'lastName'
    },
    {
      Header: 'Email',
      accessor: 'activeDirectoryMail'
    },
    {
      Header: 'Last Active',
      accessor: 'lastActive',
      type: ListViewColumnType.datetime
    },
  ] as ListViewColumn[],
  create: userCreate,
  update: userCreate,
};

export const UserListActives = createListView(
  userList, 
  userStore as any, 
  (state: any) => state.users.data.filter(user => user.isActive)
);

export const UserListInactives = createListView(
  {...userList, create: undefined} as ListView, 
  userStore as any, 
  (state: any) => state.users.data.filter(user => !user.isActive)
);

export const userListTabs: FormView = {
  pageHeader: 'Users',
  breadcrumbs: [
    {
      name: 'Users',
      icon: 'people',
      to: '/admin/users',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Active Users',
          items: [
            {
              type: FormItemType.customComponent,
              component: UserListActives
            }
          ]
        },
        {
          label: 'Inactive Users',
          items: [
            {
              type: FormItemType.customComponent,
              component: UserListInactives
            }
          ]
        }
      ]
    }
  ]
};

export const UserTabbedComponent = createView({form: userListTabs as any, repository: userStore as any});