/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import {
  Button, Col, Container, Row,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchPart, reactivatePart, selectCurrentPart,
  setCreateAttachmentStatus,
  setPartUpdateStatus, setReactivatePartStatus, setRemoveAttachmentStatus,
} from '../../../store/slices/parts-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import FileAttachment from '../../UI/organisms/FileAttachment/file-attachment';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import PartsDetailTabs from './part-details-tabs';
import PartForm from './part-form';
import PartInfo from './part-info';
import PartPurchaseRequests from './part-purchase-requests';

function PartDetails(props) {
  const { partId } = useParams();

  const partsFetchSingleStatus = useSelector((state) => state.parts.partFetchSingle.status);
  const partUpdateStatus = useSelector((state) => state.parts.partUpdate.status);
  const partUpdateError = useSelector((state) => state.parts.partUpdate.error);
  const reactivatePartStatus = useSelector((state) => state.parts.reactivatePart.status);
  const reactivatePartError = useSelector((state) => state.parts.reactivatePart.error);
  const partAttachmentCreateStatus = useSelector((state) => state.parts.partAttachmentCreate.status);
  const partAttachmentRemoveStatus = useSelector((state) => state.parts.partAttachmentRemove.status);
  const purchaseRequestsByPartFetchStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsByPartFetch.status);

  usePageTitle('Part Details');

  const partUpdateSuccessNotify = () => toast.success('Successfully Updated Part');
  const partUpdateFailedNotify = (error) => toast.error(`Failed to update Part\n${error}`);
  const partUpdateStatusSuccessNotify = () => toast.success('Successfully Updated Part Status');
  const partUpdateStatusFailedNotify = (error) => toast.error(`Failed to update Part Status\n${error}`);
  const partAttachmentCreateNotify = () => toast.success('Successfully Uploaded attachment');
  const partAttachmentRemoveNotify = () => toast.success('Successfully Deleted attachment');

  const currentPart = useSelector(selectCurrentPart);
  const breadcrumbs = useMemo(() => [
    {
      name: 'Parts Inventory',
      icon: 'tools',
      to: '/maintenance/parts-inventory',
    },
    {
      name: partId,
      icon: '',
      to: '',
    },
  ], [partId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (partUpdateStatus === 'succeeded') {
      partUpdateSuccessNotify();
      dispatch(setPartUpdateStatus('idle'));
    }
    if (partUpdateStatus === 'failed') {
      partUpdateFailedNotify(partUpdateError);
      dispatch(setPartUpdateStatus('idle'));
    }
    // NOTE:Prep for attachment story
    if (partAttachmentCreateStatus === 'succeeded') {
      partAttachmentCreateNotify();
      dispatch(setCreateAttachmentStatus('idle'));
    }
    if (partAttachmentRemoveStatus === 'succeeded') {
      partAttachmentRemoveNotify();
      dispatch(setRemoveAttachmentStatus('idle'));
    }
    if (reactivatePartStatus === 'succeeded') {
      partUpdateStatusSuccessNotify();
      dispatch(setReactivatePartStatus('idle'));
    }
    if (reactivatePartStatus === 'failed') {
      partUpdateStatusFailedNotify(reactivatePartError);
      dispatch(setReactivatePartStatus('idle'));
    }
  }, [partUpdateStatus, reactivatePartStatus]);

  useEffect(() => {
    dispatch(fetchPart(partId));
  }, []);

  const dispatchReactivate = () => {
    dispatch(reactivatePart(currentPart.partId));
  };

  const loading = [
    partsFetchSingleStatus,
    partUpdateStatus,
    purchaseRequestsByPartFetchStatus,
  ].includes('loading');

  return (
    <Container
      fluid
      className="h-100 d-flex flex-column"
    >
      <PageHeader
        header="Part Details"
        breadcrumbs={breadcrumbs}
      />
      <Row className="h-100 flex-grow-1 overflow-auto">
        <Col lg={3} className="h-100 fluid flex-column overflow-auto">
          <PartInfo
            partStatusIsLoading={loading}
            currentPart={currentPart}
          />
          <FileAttachment entityPK={partId} entityName="Part Details" />
          {currentPart.isActive === false
            && (
            <Button onClick={() => {
              dispatchReactivate();
            }}
            >
              {' '}
              Reactivate Part
              {' '}

            </Button>
            )}
        </Col>
        <Col className="h-100 d-flex fluid">
          <PartsDetailTabs
            purchaseRequestContent={<PartPurchaseRequests />}
            generalInfoContents={(
              <PartForm
                currentPart={currentPart}
                partStatusIsLoading={loading}
              />
            )}
            auditLogContent={(
              <EntityAuditLogs
                entityId={10}
                tablePK={partId}
                tableSchema="Maintenance"
              />
            )}
          />
        </Col>
      </Row>
    </Container>
  );
}
export default PartDetails;
