import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Card, Form, Spinner,
} from 'react-bootstrap';
import Dropzone, { useDropzone } from 'react-dropzone';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../../services/format';
import { selectIsAssetAdmin, selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import { createChassisAttachment, removeChassisAttachment } from '../../../../store/slices/chassis-slice';
import { fetchAssetAttachment } from '../../../../store/slices/file-attachment-slice';
import { createGasAttachment, removeGasAttachment } from '../../../../store/slices/gas-containers-slice';
import { selectGasFileTypes, selectLiquidFileTypes } from '../../../../store/slices/legacy-slice';
import { createLiquidAttachment, removeLiquidAttachment } from '../../../../store/slices/liquid-containers-slice';
import { createPackagedProductAttachment, removePackagedProductAttachment } from '../../../../store/slices/packaged-products-slice';
import useConfirm from '../ConfirmDialog/use-confirm';
import PaginatedSelectTable from '../Table/paginated-select-table';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

function AssetFileAttachment(props) {
  const defaultAttachmentValues = {
    file: null, // name of the file uploaded
    moduleName: null, // asset type
    moduleKey: null, // value from dropdown
    moduleId: null, // serial number of the asset
  };

  const {
    register, handleSubmit, formState: { errors }, setValue, reset,
  } = useForm({
    defaultValues: defaultAttachmentValues,
  });

  const {
    isFocused,
  } = useDropzone();

  const dispatch = useDispatch();

  const [moduleKey, setModuleKey] = useState('General');
  const [filteredAttachments, setFilteredAttachments] = useState(props.attachmentList);

  const tableData = useMemo(
    () => filteredAttachments ?? [],
    [filteredAttachments],
  );

  const getFilteredList = (sortKey) => {
    if (sortKey === 'General') {
      return props.attachmentList;
    }
    return (props.attachmentList ?? []).filter((a) => a.moduleKey === sortKey);
  };

  useEffect(() => {
    setFilteredAttachments(getFilteredList(moduleKey));
  }, [props.attachmentList]);

  const handleSelect = (e) => {
    setModuleKey(e.target.value);
    setFilteredAttachments(getFilteredList(e.target.value));
  };

  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);

  const onDrop = (fileAttachment) => {
    let validate = true;
    const filesExists = tableData.filter((x) => fileAttachment.filter((z) => z.name === x.fileName).length > 0).map((x) => x.fileName).join(',');
    if (filesExists !== '') {
      if (!window.confirm(`The files ${filesExists} already exists. Do you want to overwrite?`)) validate = false;
    }

    if (validate) {
      const keys = Object.keys(fileAttachment);
      keys.forEach((key) => {
        const form = new FormData();
        const file = new Blob([fileAttachment[key]]);
        form.append('file', file, fileAttachment[key].name);
        form.append('moduleName', props.moduleName);
        form.append('moduleKey', moduleKey);
        form.append('moduleId', props.moduleId);
        // add if statements to decide which function to dispatch based on moduleName
        if (props.moduleName === 'LiquidContainers') {
          dispatch(createLiquidAttachment(form));
        } else if (props.moduleName === 'GasContainers') {
          dispatch(createGasAttachment(form));
        } else if (props.moduleName === 'PackagedProducts') {
          dispatch(createPackagedProductAttachment(form));
        } else if (props.moduleName === 'Chassis') {
          dispatch(createChassisAttachment(form));
        }
      });
    }
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }), [
    isFocused,
  ]);

  const { confirm } = useConfirm();

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  const fileColumns = useMemo(() => (
    [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => (
          <div className="d-flex">

            {isAssetAdmin && (
              <Button
                size="xsm"
                variant="secondary"
                onClick={async () => {
                  const isConfirmed = await confirm('Are you sure you want to delete this attachment?');
                  if (isConfirmed) {
                    if (props.moduleName === 'LiquidContainers') {
                      dispatch(
                        removeLiquidAttachment(cell.row.original.attachmentId),
                      );
                    } else if (props.moduleName === 'GasContainers') {
                      dispatch(
                        removeGasAttachment(
                          cell.row.original.attachmentId,
                        ),
                      );
                    } else if (props.moduleName === 'PackagedProducts') {
                      dispatch(removePackagedProductAttachment(
                        cell.row.original.attachmentId,
                      ));
                    } else if (props.moduleName === 'Chassis') {
                      dispatch(removeChassisAttachment(
                        cell.row.original.attachmentId,
                      ));
                    }
                  }
                }}
              >
                <i className="bi-trash" />
              </Button>
            )}
            &nbsp;
            <Button
              size="xsm"
              variant="secondary"
              onClick={() => {
                dispatch(fetchAssetAttachment({
                  attachmentId: cell.row.original.attachmentId,
                  fileName: cell.row.original.fileName,
                }));
              }}
            >
              <i className="bi-file-earmark-arrow-down" />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Type',
        accessor: 'moduleKey',
      },
      {
        Header: 'File',
        accessor: 'fileName',
      },
      {
        Header: 'Uploaded',
        accessor: 'created',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
    ]
  ), [props.attachmentList]);

  let fileTypes = [];
  const attachmentsTableInstance = useRef(null);
  if (props.moduleName === 'LiquidContainers') {
    fileTypes = useSelector(selectLiquidFileTypes);
  } else if (props.moduleName === 'GasContainers') {
    fileTypes = useSelector(selectGasFileTypes);
  }

  const liquidAttachmentCreateStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerAttachmentCreate.status,
  );
  const liquidAttachmentRemoveStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerAttachmentRemove.status,
  );
  const liquidContainerFetchSingleStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerFetchSingle.status,
  );

  const gasAttachmentCreateStatus = useSelector(
    (state) => state.gasContainer.gasContainerAttachmentCreate.status,
  );
  const gasAttachmentRemoveStatus = useSelector(
    (state) => state.gasContainer.gasContainerAttachmentRemove.status,
  );
  const gasContainerFetchSingleStatus = useSelector(
    (state) => state.gasContainer.gasContainersFetchSingle.status,
  );

  const chassisAttachmentCreateStatus = useSelector(
    (state) => state.chassis.chassisAttachmentCreate.status,
  );
  const chassisAttachmentRemoveStatus = useSelector(
    (state) => state.chassis.chassisAttachmentRemove.status,
  );
  const chassisFetchSingleStatus = useSelector(
    (state) => state.chassis.chassisFetchSingle.status,
  );

  const packagedProductAttachmentCreateStatus = useSelector(
    (state) => state.packagedProduct.packagedProductAttachmentCreate.status,
  );
  const packagedProductAttachmentRemoveStatus = useSelector(
    (state) => state.packagedProduct.packagedProductAttachmentRemove.status,
  );
  const packagedProductsFetchSingleStatus = useSelector(
    (state) => state.packagedProduct.packagedProductsFetchSingle.status,
  );

  const assetAttachmentFetchStatus = useSelector(
    (state) => state.attachment.assetAttachmentFetch.status,
  );

  const attachmentsLoading = [
    liquidAttachmentCreateStatus,
    liquidAttachmentRemoveStatus,
    liquidContainerFetchSingleStatus,
    gasAttachmentCreateStatus,
    gasAttachmentRemoveStatus,
    gasContainerFetchSingleStatus,
    chassisAttachmentCreateStatus,
    chassisAttachmentRemoveStatus,
    chassisFetchSingleStatus,
    packagedProductAttachmentCreateStatus,
    packagedProductAttachmentRemoveStatus,
    packagedProductsFetchSingleStatus,
    assetAttachmentFetchStatus,
  ].includes('loading');

  return (
    <Card className="mt-2 card-primary card-outline flex-grow-1 d-flex flex-column overflow-auto">
      <Spinner
        animation="border"
        variant="primary"
        className={`create-spinner${attachmentsLoading ? ' visible' : ' invisible'}`}
      />
      <Card.Header>
        <h3 className="card-title">Attachments</h3>
      </Card.Header>
      <Card.Body className={`d-flex flex-column overflow-auto ${attachmentsLoading ? ' creation-loading' : ''}`}>
        {isAssetAdmin && (
          <>
            <Dropzone onDrop={(accepted) => { onDrop(accepted); }}>
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps({ style })}>
                    <input {...getInputProps()} />
                    <p>Click or Drag & Drop files here...</p>
                  </div>
                </section>
              )}
            </Dropzone>

            <Form.Select
              className="mb-2 mt-2 btn-block"
              variant="outline-secondary"
              title={moduleKey}
              onChange={handleSelect}
              size="sm"
            >
              <option
                value="General"
                key="fileType-0"
              >
                General

              </option>
              {(fileTypes ?? []).map((fileType, index) => (
                <option
                  value={fileType.name}
                  key={`fileType-${index + 1}`}
                >
                  {fileType.FileType}

                </option>
              ))}
            </Form.Select>
          </>
        )}
        <div className="d-flex flex-column flex-grow-1 overflow-auto ">
          <PaginatedSelectTable
            columns={fileColumns}
            data={tableData}
            ref={attachmentsTableInstance}
            rowProps={() => ({})}
          />
        </div>
      </Card.Body>
    </Card>

  );
}

export default AssetFileAttachment;
