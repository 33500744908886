import { FormItemType } from "../../../UI/view/component-map";

export const bulkLineItem = ({ label, fieldName, hideCheck, hideRow } : {label: string, fieldName: string, hideCheck?: boolean, hideRow?: ({watch}) => boolean }) => {
  return (
    {
      type: FormItemType.row,
      hideIf: hideRow ? hideRow : undefined,
      items: [
        {
          name: `${fieldName}OverrideBOOL`,
          defaultValue: false,
          type: FormItemType.checkboxField,
          tooltip: 'Override',
          width: 100,
          hideIf: hideCheck ? () => true : undefined,
        },
        {
          type: FormItemType.label,
          label: label,
          name: label,
          width: 200
        },
        {
          name: fieldName,
          disabled: () => true,
          hide: ({ watch }) => {
            return watch(`${fieldName}OverrideBOOL`);
          },
          hideIf: ({ editTableProps, rowIdentity, watch }) => {
            return watch(`${fieldName}OverrideBOOL`);
          },
          number: true,
          defaultValue: 0,
          fitToContent: true,
          required: true,
          width: 200,
          min: 0,
        },
        {
          name: `${fieldName}Override`,
          hide: ({ watch }) => {
            return !watch(`${fieldName}OverrideBOOL`);
          },
          hideIf: ({ editTableProps, rowIdentity, watch }) => {
            return !watch(`${fieldName}OverrideBOOL`);
          },
          number: true,
          computedValue: (getValues) => {
            console.log(getValues(fieldName));
            return  getValues(fieldName);
          },
       /*    defaultValue: 0, */
          fitToContent: true,
          required: true,
          width: 200,
          min: 0,
        },

      ]
    }
  );
}

export const liquidBulkLineItems = [
  bulkLineItem({ label: 'Gross volume (CF)', fieldName: 'nvValue' }),
  bulkLineItem({ label: 'Helium Charge', fieldName: 'bcValue' }),
  bulkLineItem({ label: 'Depressurization charge', fieldName: 'dcValue' }),
  bulkLineItem({ label: 'Temp at 3PSIG', fieldName: 'heTempAt3Psig', hideRow: ({watch}) => watch('isStagingInvoice') }),
  bulkLineItem({ label: 'Cooldown charge', fieldName: 'ccValue' }),
  bulkLineItem({ label: 'Nitrogen charge', fieldName: 'ncValue' }),
  bulkLineItem({ label: 'Inland Freight', fieldName: 'tcValue' }),
  bulkLineItem({ label: 'Service charge', fieldName: 'scValue' }),
  bulkLineItem({ label: 'Analytical charge', fieldName: 'acValue' }),
  bulkLineItem({ label: 'Repair charge', fieldName: 'rcValue' }),
  bulkLineItem({ label: 'Export Insp. Charge', fieldName: 'ecValue' }),
  bulkLineItem({ label: 'Container Wash', fieldName: 'cwValue' }),
  bulkLineItem({ label: 'Ocean Freight', fieldName: 'ofValue' }),
  bulkLineItem({ label: 'Export Fee', fieldName: 'efValue' }),
  bulkLineItem({ label: 'Customs & Broker Fee', fieldName: 'cfValue' }),
  bulkLineItem({ label: 'Forwarding & Cust Fee', fieldName: 'ffValue' }),

]