import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Collapse, InputGroup,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BsPlusLg } from 'react-icons/bs';
import { MdAttachFile } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  assignMaintenanceRequest,
  selectOutstandingRequests,
  setAssignMaintenanceRequestStatus,
  setCurrentMaintenanceRequestId,
} from '../../../store/slices/maintenance-requests-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

function WorkOrderOutstandingRequestsTable(props) {
  const [filterOpen, setFilterOpen] = useState(false);

  const currentOutstandingRequests = useSelector(
    (state) => selectOutstandingRequests(state, props.currentWorkOrder?.assetTypeId),
  );

  const maintenanceRequestUpdateStatus = useSelector(
    (state) => state.maintenanceRequest?.maintenanceRequestUpdate.status,
  );

  const maintenanceRequestAssignStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestAssign.status,
  );

  const requestUpdateNotify = () => toast.success('Added Maintenance Request to Work Order');

  const dispatch = useDispatch();

  useEffect(() => {
    if (maintenanceRequestAssignStatus === 'succeeded') {
      requestUpdateNotify();
      dispatch(setAssignMaintenanceRequestStatus('idle'));
    }
  }, [maintenanceRequestAssignStatus]);

  const maintenanceRequestsFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const maintenanceRequestsData = useMemo(() => {
    if (props.currentWorkOrder) {
      return currentOutstandingRequests;
    }
    return [];
  }, [currentOutstandingRequests,
    props.currentWorkOrder,
    maintenanceRequestsFetchStatus,
    maintenanceRequestUpdateStatus,
  ]);

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const editModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    props.handleEditModalShow();
  };

  const workOrderModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    props.handleWorkOrderModalShow();
  };

  const attachmentsModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    props.handleMaintenanceRequestAttachmentsModalShow();
  };

  const isAddToWorkOrderDisabled = useCallback((row) => {
    return (!!row.workOrderId || row.maintenanceRequestStatusId === 3);
  }, []);

  const maintenanceRequestsColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell, row }) => (
          <div className="d-flex">
            <Button
              size="xsm"
              title="Edit"
              onClick={() => {
                attachmentsModalShow(row.original.maintenanceRequestId);
              }}
              className="mr-1"
            >
              <MdAttachFile />
            </Button>
            <div className={isAddToWorkOrderDisabled(cell.row.original) ? 'disabled-cursor' : ''}>
              <Button
                size="xsm"
                disabled={isAddToWorkOrderDisabled(cell.row.original)}
                title={cell.row.original.workOrderId ? '' : 'Add to current work order'}
                onClick={() => {
                  if (!cell.row.original.workOrderId) {
                    dispatch(assignMaintenanceRequest({
                      maintenanceRequestId: cell.row.original.maintenanceRequestId,
                      workOrderId: props.currentWorkOrder.workOrderId,
                    }));
                  }
                }}
              >
                <BsPlusLg />
              </Button>
            </div>
          </div>
        ),
      },
      {
        Header: 'Status',
        accessor: 'maintenanceRequestStatus',
      },
      {
        Header: 'Request Type Name',
        accessor: 'maintenanceRequestType',
      },
      {
        Header: 'Estimated Effort​',
        accessor: 'estimatedEffort',
      },
      {
        Header: 'Due Date',
        accessor: 'plannedDueDate',
        Cell: ({ value }) => (value == null ? null : formatDate(value, datePreference)),
      },
      {
        Header: 'Description',
        accessor: 'details',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [props.currentWorkOrder?.workOrderId],
  );

  const maintenanceRequestsTableInstance = useRef(null);

  const statusIsLoading = [
    maintenanceRequestUpdateStatus,
    maintenanceRequestsFetchStatus,
    maintenanceRequestAssignStatus].includes('loading');

  const maintenanceRequestsContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={maintenanceRequestsTableInstance} />
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              Filters
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={maintenanceRequestsColumns}
        data={maintenanceRequestsData}
        ref={maintenanceRequestsTableInstance}
        rowProps={() => ({})}
      />
    </>
  );

  return (
    <>
      <div className={`d-flex flexbox flex-column overflow-auto flex-grow-1 h-100${statusIsLoading ? ' creation-loading' : ''}`}>
        {maintenanceRequestsContent}
      </div>
      {statusIsLoading && <LoadingContent />}
    </>
  );
}

export default WorkOrderOutstandingRequestsTable;
