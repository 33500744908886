import './file-attachment.scss';

import React, {
  useCallback, useEffect,
  useMemo, useRef, useState,
} from 'react';
import {
  Button, Card, Form,
  Spinner,
} from 'react-bootstrap';
import Dropzone, { useDropzone } from 'react-dropzone';
import toast from 'react-hot-toast';
import { BsFillFileEarmarkArrowDownFill, BsFillTrashFill } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import {
  createAttachment,
  deleteAttachmentAction,
  fetchAttachment,
  fetchEntityAttachments,
  selectAttachmentBulkDownloadStatus,
  selectAttachmentDeleteStatus,
  selectAttachmentDownloadStatus,
  selectAttachmentDropdownValues,
  selectAttachmentUploadStatus,
  selectEntityAttachments,
  selectEntityAttachmentsFetchStatus,
  setAttachmentDeleteStatus,
  setAttachmentUploadStatus,
  setEntityAttachmentsFetchStatus,
} from '../../../../store/slices/file-attachment-slice';
import useConfirm from '../ConfirmDialog/use-confirm';
import PaginatedSelectTable from '../Table/paginated-select-table';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

function FileAttachments(props) {
  const {
    isFocused,
  } = useDropzone();

  const dispatch = useDispatch();

  const [moduleKey, setModuleKey] = useState('General');

  const entityAttachmentsFetchStatus = useSelector(selectEntityAttachmentsFetchStatus);

  const entityAttachmentDeleteStatus = useSelector(selectAttachmentDeleteStatus);

  const entityAttachmentDownloadStatus = useSelector(selectAttachmentDownloadStatus);

  const entityAttachmentUploadStatus = useSelector(selectAttachmentUploadStatus);

  const entityAttachmentBulkDownloadStatus = useSelector(selectAttachmentBulkDownloadStatus);

  const attachmentDropdownValues = useSelector(selectAttachmentDropdownValues);

  const dropdownValuesFetchStatus = useSelector(
    (state) => state.attachment.dropdownValuesFetch.status,
  );

  // eslint-disable-next-line max-len
  // const dropdownValuesFetch = useSelector((state) => state.attachment.dropdownValuesFetch.status);

  const entityAttachmentDetails = attachmentDropdownValues[props.entityName]
    ?? { types: [] };

  const entityTypeId = parseInt(entityAttachmentDetails?.details?.id, 10);

  const loadData = useCallback(async () => {
    if (
      (entityAttachmentsFetchStatus === 'idle'
        || entityAttachmentsFetchStatus === 'failed') && props.entityPK !== undefined
    ) {
      dispatch(fetchEntityAttachments({ entityTypeId, entityPK: props.entityPK }));
    }
  }, [entityAttachmentsFetchStatus, entityTypeId, props.entityPK, dispatch]);

  useEffect(() => {
    if (dropdownValuesFetchStatus === 'succeeded') {
      loadData();
    }
  }, [props.entityPK, dropdownValuesFetchStatus]);

  useEffect(() => {
    if (entityAttachmentsFetchStatus === 'succeeded' || entityAttachmentsFetchStatus === 'failed') {
      dispatch(setEntityAttachmentsFetchStatus('idle'));
    }
  }, [entityAttachmentsFetchStatus]);

  const entityAttachments = useSelector(selectEntityAttachments);

  const handleSelect = (e) => {
    setModuleKey(e.target.value);
  };

  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);

  const fileTypes = entityAttachmentDetails.types;

  const onDrop = ([fileAttachment]) => {
    const form = new FormData();
    const file = new Blob([fileAttachment]);

    const attachmentTypeId = entityAttachmentDetails.types.find((t) => t.name === moduleKey).id;
    form.append('file', file, fileAttachment.name);
    form.append('attachmentTypeId', parseInt(attachmentTypeId, 10));
    form.append('entityTypeId', parseInt(entityAttachmentDetails?.details?.id, 10));
    form.append('entityPK', props.entityPK);

    dispatch(createAttachment(form));
  };

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }), [
    isFocused,
  ]);

  const { confirm } = useConfirm();

  const fileColumns = useMemo(() => (
    [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => (
          <div className="d-flex">
            <Button
              size="xsm"
              variant="secondary"
              onClick={async () => {
                const isConfirmed = await confirm('Are you sure you want to delete this attachment?');
                if (isConfirmed) {
                  dispatch(deleteAttachmentAction({
                    attachmentId: cell.row.original.attachmentId,
                  }));
                }
              }}
            >
              <BsFillTrashFill />
            </Button>
            &nbsp;
            <Button
              size="xsm"
              variant="secondary"
              onClick={() => {
                dispatch(fetchAttachment({
                  attachmentId: cell.row.original.attachmentId,
                  fileName: cell.row.original.attachmentName,
                }));
              }}
            >
              <BsFillFileEarmarkArrowDownFill />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Type',
        accessor: 'attachmentTypeId',
        Cell: ({ value }) => entityAttachmentDetails.types.find(
          (t) => parseInt(t.id, 10) === value,
        ).name,
      },
      {
        Header: 'File',
        accessor: 'attachmentName',
      },
      {
        Header: 'Uploaded',
        accessor: 'created',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
    ]
  ), [props.attachmentList, entityAttachmentDetails]);

  const tableData = useMemo(
    () => (entityAttachments ? Object.values(entityAttachments) : []),
    [entityAttachments],
  );

  const attachmentUploadNotify = () => toast.success('Successfully uploaded attachment');
  const attachmentDeleteNotify = () => toast.success('Successfully Deleted attachment');

  useEffect(() => {
    // setModuleKey(entityAttachmentDetails.types.find((t) => t.name === 'General').name);
    if (entityAttachmentUploadStatus === 'succeeded') {
      attachmentUploadNotify();
      dispatch(setAttachmentUploadStatus('idle'));
    }
  }, [entityAttachmentUploadStatus]);

  useEffect(() => {
    // setModuleKey(entityAttachmentDetails.types.find((t) => t.name === 'General').name);
    if (entityAttachmentDeleteStatus === 'succeeded') {
      attachmentDeleteNotify();
      dispatch(setAttachmentDeleteStatus('idle'));
    }
  }, [entityAttachmentDeleteStatus]);

  const attachmentsTableInstance = useRef(null);

  const dependeciesLoading = [
    dropdownValuesFetchStatus,
  ].includes('loading');

  const attachmentsLoading = [
    entityAttachmentsFetchStatus,
    entityAttachmentDeleteStatus,
    entityAttachmentDownloadStatus,
    entityAttachmentUploadStatus,
    entityAttachmentBulkDownloadStatus,
  ].includes('loading');

  return (
    <Card className="mt-2 card-primary card-outline flex-grow-1 d-flex flex-column overflow-auto">
      <Spinner
        animation="border"
        variant="primary"
        className={`create-spinner${attachmentsLoading ? ' visible' : ' invisible'}`}
      />
      <Card.Header>
        <h3 className="card-title">Attachments</h3>
      </Card.Header>
      <Card.Body className={`d-flex flex-column overflow-auto ${attachmentsLoading ? ' creation-loading' : ''}`}>
        <Dropzone onDrop={(accepted) => { onDrop(accepted); }}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style })}>
                <input {...getInputProps()} />
                <p>Click or Drag & Drop files here...</p>
              </div>
            </section>
          )}
        </Dropzone>
        <div className="d-flex justify-content-between">
          {/* Commenting out until zip download issue is resolved */}
          {/* <Button
            className="mb-2 mt-2 btn-block w-25 mr-2"
            variant="outline-secondary"
            onClick={() => {
              dispatch(fetchAttachmentBulk({
                entityTypeId,
                entityPK: props.entityPK,
                archiveName: `${props.entityName} - ${props.entityPK}`,
              }));
            }}
          >
            Download All
          </Button> */}
          <Form.Select
            className="mb-2 mt-2 btn-block w-100"
            variant="outline-secondary"
            title={moduleKey}
            onChange={handleSelect}
            size="sm"
          >
            {(fileTypes ?? []).map((fileType, index) => (
              <option
                value={fileType.name}
                key={`fileType-${index}`}
              >
                {fileType.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="d-flex flex-column flex-grow-1 overflow-auto ">
          {dependeciesLoading ? null : (
            <PaginatedSelectTable
              columns={fileColumns}
              data={tableData}
              ref={attachmentsTableInstance}
              rowProps={() => ({})}
            />
          )}
        </div>
      </Card.Body>
    </Card>

  );
}

export default FileAttachments;
