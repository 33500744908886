import React, {
  useCallback, useEffect, useMemo, useReducer,
} from 'react';
import {
  Button, Collapse, Form, InputGroup, OverlayTrigger, Popover, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import {
  getNextDueDate,
  maintenanceStatusValues,
} from '../../../services/maintenance-helpers';
import {
  assetSort,
  exportData, sortByBasic,
  sortByBoolean,
  sortByDateCustom,
  sortByDateField,
  sortByDictValue,
} from '../../../services/table-helpers';
import { convertToPreferred, getPreferredUnit } from '../../../services/user-preferences';
import { selectIsAssetAdmin, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectLiquidCustomerObjects, selectLiquidCustomers } from '../../../store/slices/legacy-slice';
import {
  fetchLiquidContainers,
  fetchMaintenanceStatusForLiquidContainers, selectLiquidContainers,
  selectLiquidContainersMaintenanceStatuses,
} from '../../../store/slices/liquid-containers-slice';
import { useTablePageRef } from '../../hooks/useStateRef';
import ActiveIcon from '../../UI/atoms/ActiveIcon/active-icon';
import { YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';
import InactiveIcon from '../../UI/atoms/InactiveIcon/inactive-Icon';
import RedFlagAlert from '../../UI/atoms/RedFlagAlert/red-flag-alert';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { NextDueDate } from '../../UI/molecules/NextDueDate/next-due-date';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import {
  getLiquidOwnerCustomer,
  getLiquidOwnerMawp,
  liquidContainerStyleTypes,
  liquidContainerTypes,
} from './liquid-container-values';

const initFilterState = {
  filterOpen: false,
  isActive: true,
  isSkikdaAuthorized: false,
  isSkikdaNoFlyList: false,
  hasHeliumShield: false,
  isLeased: false,
  isPbcRequired: false,
  hasRemoteReadoutConnections: false,
  hasIOT: false,
};

// Define reducer for controlling the packaged assets filter state
function filtersReducer(state, action) {
  switch (action.type) {
    case 'filterOpen':
      return { ...state, filterOpen: !state.filterOpen };
    case 'isActive':
      return { ...state, isActive: !state.isActive };
    case 'isSkikdaAuthorized':
      return { ...state, isSkikdaAuthorized: !state.isSkikdaAuthorized };
    case 'isSkikdaNoFlyList':
      return { ...state, isSkikdaNoFlyList: !state.isSkikdaNoFlyList };
    case 'hasHeliumShield':
      return { ...state, hasHeliumShield: !state.hasHeliumShield };
    case 'isLeased':
      return { ...state, isLeased: !state.isLeased };
    case 'isPbcRequired':
      return { ...state, isPbcRequired: !state.isPbcRequired };
    case 'hasRemoteReadoutConnections':
      return { ...state, hasRemoteReadoutConnections: !state.hasRemoteReadoutConnections };
    case 'hasIOT':
      return { ...state, hasIOT: !state.hasIOT };
    default:
      throw new Error('invalid case');
  }
}

const hiddenColumns = [
  'isSkikdaNoFlyList',
  'isSkikdaAuthorized',
  'isLeased',
  'isPbcRequired',
  'hasRemoteReadoutConnections',
  'containerTypeCode',
  'hasIOT',
];

function LiquidContainersTable(props) {
  const liquidContainers = useSelector(selectLiquidContainers);
  const liquidContainersMaintenanceStatuses = useSelector(
    selectLiquidContainersMaintenanceStatuses,
  );
  const liquidContainerStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.status,
  );
  const liquidContainerError = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.error,
  );

  const liquidContainerMaintenanceFetchStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersMaintenanceStatusFetch.status,
  );

  const liquidCustomersArr = useSelector(selectLiquidCustomers);
  const liquidCustomers = useSelector(selectLiquidCustomerObjects);
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);

  const sortByProducer = (row1, row2, columnId) => {
    // eslint-disable-next-line quote-props
    const manufacturerToCountry = { 'Gardner': 'USA', 'Cryo AB': 'Sweden', 'Linde': 'Germany' };
    try {
      return sortByDictValue(row1, row2, columnId, manufacturerToCountry);
    } catch (error) {
      return console.log(`There was an error" ${error}`);
    }
  };

  const [currentPage, liquidTable, liquidContainersTableInstance] = useTablePageRef();

  const mappedPage = currentPage?.map((r) => r.original.serialNumber);

  const [filtersState, dispatchFilters] = useReducer(filtersReducer, initFilterState);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (liquidContainers) {
      dispatch(fetchMaintenanceStatusForLiquidContainers(
        { assetTypeId: 2, assetIds: Object.keys(liquidContainers) },
      ));
    }
  }, [JSON.stringify(liquidContainers)]);

  const handleFilterChange = (event, filterId) => {
    liquidTable.setFilter(filterId, !filtersState[filterId]);
    dispatchFilters({ type: filterId });
  };

  const handleSelectFilterChange = (event, filterId) => {
    liquidTable.setFilter(filterId, event.target.value);
  };

  const liquidContainersColumns = useMemo(
    () => [
      {
        Header: 'Unit Number',
        accessor: 'serialNumber',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        Cell: ({ value }) => (<NavLink to={`/assets/liquid-container/${value}`}>{value}</NavLink>),
      },
      {
        Header: '',
        id: 'maintenanceStatus',
        filter: 'exactText',
        sortType: (a, b) => {
          const aStatus = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceStatus ?? '';
          const bStatus = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceStatus ?? '';

          const aValue = maintenanceStatusValues[aStatus];
          const bValue = maintenanceStatusValues[bStatus];
          if (liquidContainerMaintenanceFetchStatus === 'loading') {
            return 0;
          }
          return sortByBasic(aValue, bValue);
        },
        accessor: (row) => {
          const status = liquidContainersMaintenanceStatuses[row.serialNumber]?.maintenanceStatus ?? '';
          return status;
        },
        Cell: ({ value, row }) => {
          // return liquidContainersMaintenanceStatuses[value] ?? '';
          const status = liquidContainersMaintenanceStatuses[row.values.serialNumber]?.maintenanceStatus ?? '';
          if (status === 'Red') {
            return <RedFlagAlert redReasons={liquidContainersMaintenanceStatuses[row.values.serialNumber]?.redReason} wos={liquidContainersMaintenanceStatuses[row.values.serialNumber]?.wo} value={row.values} />;
          }
          if (status === 'Yellow') {
            return <YelloIcon />;
          }
          if (liquidContainerMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return '';
        },
      },
      {
        Header: (_) => (<i className="bi-shield-shaded" />),
        accessor: 'hasHeliumShield',
        sortType: sortByBoolean,
        width: 30,
        Cell: ({ value }) => (value
          ? (
            <OverlayTrigger
              placement="bottom"
              delay={{ show: 250, hide: 400 }}
              overlay={(
                <Popover id="shield-tooltip">
                  <Popover.Header as="div">
                    <small>Has Helium Shield</small>
                  </Popover.Header>
                </Popover>
              )}
            >
              <i className="bi-shield-shaded" />
            </OverlayTrigger>
          ) : null),
      },
      {
        Header: 'Owner',
        id: 'ownerCustomer',
        accessor: (row) => getLiquidOwnerCustomer(liquidCustomers, row.ownerCustomer),
        filter: 'exactText',
      },
      {
        Header: 'Manufacturer',
        accessor: 'manufacturer',
      },
      {
        Header: 'MAWP',
        id: 'containerMawp',
        accessor: (row) => getLiquidOwnerMawp(row.containerMawp),
        width: 50,
      },
      {
        Header: 'Next 2.5yr Insp',
        id: 'cscDate',
        sortType: (a, b) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['2.5 Year Inspection'] ?? '';
          const maintenanceDate2 = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['2.5 Year Inspection'] ?? '';
          const nextA = getNextDueDate(maintenanceDate?.dueDate, a.original.cscDate, 30);
          const nextB = getNextDueDate(maintenanceDate2.dueDate, b.original.cscDate, 30);
          return sortByDateCustom(nextA, nextB);
        },
        accessor: (row) => {
          const stat = liquidContainersMaintenanceStatuses[row.serialNumber];
          const maintenanceDate = stat?.maintenanceDates?.['2.5 Year Inspection'] ?? '';
          return formatDate(getNextDueDate(maintenanceDate?.dueDate, row.cscDate, 30), datePreference);
        },
        Cell: ({ value, row }) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[row.original.serialNumber]?.maintenanceDates?.['2.5 Year Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={getNextDueDate(maintenanceDate?.dueDate, row.original.cscDate, 30)}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next 5yr Insp',
        id: 'lastFiveYearInspectionDate',
        sortType: (a, b) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['5yr Inspection'] ?? '';
          const maintenanceDate2 = liquidContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['5yr Inspection'] ?? '';
          const nextA = getNextDueDate(maintenanceDate?.dueDate, a.original.lastFiveYearInspectionDate, 60);
          const nextB = getNextDueDate(maintenanceDate2.dueDate, b.original.lastFiveYearInspectionDate, 60);
          return sortByDateCustom(nextA, nextB);
        },
        accessor: (row) => {
          const stat = liquidContainersMaintenanceStatuses[row.serialNumber];
          const maintenanceDate = stat?.maintenanceDates?.['5yr Inspection'] ?? '';
          return formatDate(getNextDueDate(maintenanceDate?.dueDate, row.lastFiveYearInspectionDate, 60), datePreference);
        },
        Cell: ({ value, row }) => {
          const maintenanceDate = liquidContainersMaintenanceStatuses[row.original.serialNumber]?.maintenanceDates?.['5yr Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={getNextDueDate(maintenanceDate?.dueDate, row.original.lastFiveYearInspectionDate, 60)}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: `Dry Tare (${getPreferredUnit('lbs', measurementPreference)})`,
        id: 'dryTareWeight',
        accessor: (row) => (row.dryTareWeight == null ? null : `${convertToPreferred('lbs', measurementPreference, row.dryTareWeight)}`),
      },
      {
        Header: `Wet Tare (${getPreferredUnit('lbs', measurementPreference)})`,
        id: 'tareWeight',
        // eslint-disable-next-line dot-notation
        accessor: (row) => (row.tareWeight == null ? null : `${convertToPreferred('lbs', measurementPreference, row.tareWeight)}`),
      },
      {
        Header: `LIN Weight (${getPreferredUnit('lbs', measurementPreference)})`,
        id: 'lniWeight',
        accessor: (row) => (row.lniWeight == null ? null : `${convertToPreferred('lbs', measurementPreference, row.lniWeight)}`),
      },
      {
        Header: 'Style',
        id: 'containerStyle',
        accessor: (row) => (row.containerStyle == null
          ? null : liquidContainerStyleTypes[row.containerStyle]),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        disableSortBy: true,
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
      {
        Header: 'Active',
        width: 35,
        accessor: 'isActive',
        Cell: ({ value }) => (value ? <ActiveIcon /> : <InactiveIcon />),
      },
      // Hidden Columns
      {
        Header: 'Skikda Authorized',
        accessor: 'isSkikdaAuthorized',
      },
      {
        Header: 'Skikda No Fly',
        accessor: 'isSkikdaNoFlyList',
      },
      {
        Header: 'Leased',
        accessor: 'isLeased',
      },
      {
        Header: 'PBC Req.',
        accessor: 'isPbcRequired',
      },
      {
        Header: 'Remote Readout Conn. (IoT)',
        accessor: 'hasRemoteReadoutConnections',
      },
      {
        Header: 'Container Type Code',
        accessor: 'containerTypeCode',
        filter: 'exactText',
      },
      {
        Header: 'Last Modified',
        id: 'modified',
        sortType: sortByDateField,
        accessor: (row) => formatDate(row.modified, datePreference),
      },
      {
        Header: 'Has IOT',
        id: 'hasIOT',
        accessor: ({ telemetryUnitId }) => {
          return telemetryUnitId != null;
        },
      },
    ],
    [liquidCustomers, liquidContainersMaintenanceStatuses, liquidContainerMaintenanceFetchStatus],
  );

  const liquidContainersData = useMemo(() => {
    if (liquidContainers) {
      return Object.values(liquidContainers).sort(assetSort);
    }

    return [];
  }, [liquidContainers]);

  const loadData = useCallback(async () => {
    if (liquidContainerStatus === 'idle' || liquidContainerStatus === 'failed') {
      dispatch(fetchLiquidContainers());
    }
  }, [liquidContainerStatus, dispatch]);

  const setDefaultFilters = () => {
    Object.keys(filtersState).forEach((filterId) => {
      if (filterId !== 'filterOpen' && filtersState[filterId] === true) {
        if (liquidTable != null) {
          liquidTable.setFilter(filterId, true);
        }
      }
    });
  };

  useEffect(() => {
    loadData();
  }, []);

  // Life cycle for applying filters when liquid container data updated.
  useEffect(() => {
    setDefaultFilters();
  }, [liquidContainersData]);

  const handleRowClick = (liquidContainerId) => {
    // Leveraging two methods for passing data to a new page for examples:
    // 1. State / useLocation
    // 2. uri path / useParam
    navigate(`/assets/liquid-container/${liquidContainerId}`);
  };

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  const onExport = () => {
    exportData('liquidcontainers', liquidContainersData, liquidContainersColumns);
  };

  const liquidContainersContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            data-cy="open-filters"
            variant={filtersState.filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => dispatchFilters({ type: 'filterOpen' })}
          >
            <i className={filtersState.filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={liquidTable} hookInstance />
          <Button variant="primary" size="sm" onClick={onExport}><i className="bi bi-download" /></Button>
          {isAssetAdmin && (
            <>
              &nbsp;
              <Button variant="primary" size="sm" onClick={props.handleModalShow}>Add Liquid Container</Button>
            </>
          )}
        </InputGroup>
        <Collapse in={filtersState.filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select data-cy="ownerCustomer-filter" size="sm" onChange={(e) => { handleSelectFilterChange(e, 'ownerCustomer'); }}>
                  <option value="" key="customer-null">-- Owner --</option>
                  {liquidCustomersArr.map((customer, index) => (
                    <option key={`customer-${index}`}>{customer.CustomerName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select data-cy="containerTypeCode-filter" size="sm" onChange={(e) => { handleSelectFilterChange(e, 'containerTypeCode'); }}>
                  <option value="">-- Type --</option>
                  {liquidContainerTypes.map((type, i) => (
                    <option value={i} key={type}>{type}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Check
                type="switch"
                className="mb-1"
                inline
                id="skikda-switch"
                label="Skikda"
                checked={filtersState.isSkikdaAuthorized}
                onChange={(e) => handleFilterChange(e, 'isSkikdaAuthorized')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="noFly-switch"
                label="Restricted List"
                checked={filtersState.isSkikdaNoFlyList}
                onChange={(e) => handleFilterChange(e, 'isSkikdaNoFlyList')}
              />
              <Form.Check
                data-cy="isActive-filter"
                className="ms-1 mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Active"
                checked={filtersState.isActive}
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
              <Form.Check
                data-cy="hasHeliumShield-filter"
                className="ms-1 mb-1"
                type="switch"
                inline
                id="shield-switch"
                label="Shield"
                checked={filtersState.hasHeliumShield}
                onChange={(e) => handleFilterChange(e, 'hasHeliumShield')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="isLeased-switch"
                label="Leased"
                checked={filtersState.isLeased}
                onChange={(e) => handleFilterChange(e, 'isLeased')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="pbc-switch"
                label="PBC Req."
                checked={filtersState.isPbcRequired}
                onChange={(e) => handleFilterChange(e, 'isPbcRequired')}
              />
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="containerTypeCode-select"
                label="Remote Readout Conn. (IoT)"
                checked={filtersState.hasIOT}
                onChange={(e) => handleFilterChange(e, 'hasIOT')}
              />
            </div>
          </div>
        </Collapse>

      </div>

      <PaginatedSelectTable
        columns={liquidContainersColumns}
        data={liquidContainersData}
        ref={liquidContainersTableInstance}
        initialState={{ hiddenColumns }}
        customTableClass="asset-table"
        rowProps={() => ({})}
      />
    </>
  );

  return (
    <>
      <div data-cy="liquid-table" className={`d-flex flex-column flex-grow-1 h-100${liquidContainerStatus === 'loading' ? ' creation-loading' : ''}`}>
        {liquidContainersContent}
      </div>
      {liquidContainerStatus === 'failed' && <ErrorContent errorMessage={liquidContainerError} />}
      {liquidContainerStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default LiquidContainersTable;
