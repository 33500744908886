import React, { useEffect, useMemo } from 'react';
import {
  Button, Col, Container, Form,
  Modal, Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { createLiquidContainer } from '../../../store/slices/liquid-containers-slice';
import BasicInput from '../../UI/molecules/BasicInput/basic-input';
import DateInput from '../../UI/molecules/DateInput/date-input';
import {
  liquidContainerManufacturers, liquidContainerMawps, liquidContainerRegulatoryDocuments,
  liquidContainerShieldTypes,
  liquidContainerSpecialPermits,
  liquidContainerStyleTypes,
  liquidContainerTypes,
} from './liquid-container-values';

function CreateLiquidContainerForm({ onHide, liquidContainerStatusIsLoading }) {
  const defaultLiquidContainerFormValues = {
    serialNumber: '',
    ownerCustomer: null,
    // "inServiceDate": null,
    manufacturer: null,
    containerTypeCode: null,
    specialPermit: null,
    regulatoryDocument: null,
    containerMawp: null,
    oneWayTravelTime: null,
    oneWayTravelTimeActual: null,
    hasHeliumShield: false,
    shieldStyle: null,
    containerStyle: null,
    nominalCapacity: null,
    nominalWeight: null,
    tareWeight: null,
    dryTareWeight: null,
    lniWeight: null,
    // "lastWeightVerifiedLocation": null,
    // "lastDateWeightVerified": null,
    // "previousContainerNumbers": null,
    comments: null,
    // "isSkikdaAuthorized": null,
    // "isSkikdaNoFlyList": null,
    // "skikdaAuthorizationDate": null,
    // "skikdaNoFlyListComments": null,
    // "isActive": null,
    // "isPbcRequired": null,
    // "isIgnoreCscDates": null,
    // "hasRemoteReadoutConnections": null,
    // "isDualSlwValves": null,
    // "isLeased": null,
    // "leasedTo": null,
    // "leasedFrom": null,
    cscDate: null,
    lastFiveYearInspectionDate: null,
    // "cscDate": null,
    // "eInspectionDate": null,
    // "vInspectionDate": null,
    // "lastRebuildDate": null
    trickleValve: false,
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultLiquidContainerFormValues,
  });

  const dispatch = useDispatch();
  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  // form will only be used to update b/c we will create a creat specific modal
  const onSubmit = (liquidContainer) => {
    Object.keys(liquidContainer).forEach((key) => {
      if (key.toLowerCase().includes('date') && liquidContainer[key]) {
        liquidContainer[key] = convertDateToUTCDay(liquidContainer[key]);
      }
    });
    dispatch(createLiquidContainer({ ...liquidContainer, assetType: 'LiquidContainer' }));
  };

  // Set shield style to null if helium shield is false
  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'hasHeliumShield' && value.hasHeliumShield === false) {
        setValue('shieldStyle', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const basicInputProps = {
    setValue,
    watch,
    getFieldState,
    register,
    trigger,
  };

  const liquidCustomers = useSelector(((state) => state.legacy?.dropdownValues?.liquidCustomers));
  const activeCustomers = useMemo(() => {
    const sortedCustomers = [...liquidCustomers].sort((a, b) => {
      return a.CustomerName.trim().localeCompare(b.CustomerName.trim());
    });
    return sortedCustomers.filter((cust) => cust.Status === 'Active');
  }, [liquidCustomers]);

  return (
    <>
      <Modal.Body>
        <Form className="bg-white h-100 overflow-auto">
          <Spinner animation="border" variant="primary" className={`create-spinner${liquidContainerStatusIsLoading ? ' visible' : ' invisible'}`} />
          <Container fluid className={` ${liquidContainerStatusIsLoading ? ' creation-loading' : ''}`}>
            <Container className="p-0 m-0">
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Unit Number</Form.Label>
                    <Form.Control
                      size="sm"
                      {...register(
                        'serialNumber',
                        {
                          required: 'Please enter a serial number.',
                          maxLength: { message: 'Unit ID cannot exceed 12 characters', value: 12 },
                        },
                      )}
                      isInvalid={!!errors.serialNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.serialNumber && errors.serialNumber.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Last 2.5 Year Inspection</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="cscDate"
                      rules={{ required: 'Please enter a last test date' }}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Last 5 Year Inspection</Form.Label>
                    <DateInput
                      control={control}
                      formControlName="lastFiveYearInspectionDate"
                      rules={{ required: 'Please enter a last test date' }}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Manufacturer</Form.Label>
                    <Form.Select size="sm" {...register('manufacturer', { required: 'Please select a manufacturer.' })} isInvalid={!!errors.manufacturer}>
                      {liquidContainerManufacturers.map((manufacturer, i) => (
                        <option value={manufacturer} key={manufacturer}>{manufacturer}</option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.manufacturer && errors.manufacturer.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Owner</Form.Label>
                    <Form.Select size="sm" {...register('ownerCustomer', { required: 'Please select an owner.' })} isInvalid={!!errors.ownerCustomer}>
                      <option value={null} key="customer-null" />
                      {activeCustomers.map((customer, index) => (
                        <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.ownerCustomer && errors.ownerCustomer.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Type</Form.Label>
                    <Form.Select size="sm" {...register('containerTypeCode', { required: 'Please select a container type.' })} isInvalid={!!errors.containerTypeCode}>
                      <option value={null} key="type-null" />
                      {liquidContainerTypes.map((type, i) => (
                        <option value={i} key={type}>{type}</option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.containerTypeCode && errors.containerTypeCode.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Style</Form.Label>
                    <Form.Select size="sm" {...register('containerStyle', { required: 'Please select a containerStyle.' })} isInvalid={!!errors.containerStyle}>
                      <option value={null} key="containerStyle-null" />
                      {liquidContainerStyleTypes.map((item, i) => (
                        <option value={i} key={`containerStyle-${i}`}>{item}</option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.containerStyle && errors.containerStyle.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Special Permit</Form.Label>
                    <Form.Select size="sm" {...register('specialPermit', { required: 'Please select a specialPermit.' })} isInvalid={!!errors.specialPermit}>
                      {liquidContainerSpecialPermits.map((permit, i) => (
                        <option value={i} key={permit}>{liquidContainerSpecialPermits[i]}</option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.specialPermit && errors.specialPermit.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Regulatory Document</Form.Label>
                    <Form.Select size="sm" {...register('regulatoryDocument', { required: 'Please select a regulatory document.' })} isInvalid={!!errors.regulatoryDocument}>
                      <option value={null} key="document-null" />
                      {liquidContainerRegulatoryDocuments.map((document, i) => (
                        <option value={i} key={`document-${i}`}>{document}</option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.regulatoryDocument && errors.regulatoryDocument.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>MAWP</Form.Label>
                    <Form.Select size="sm" {...register('containerMawp', { required: 'Please select a MAWP.' })} isInvalid={!!errors.containerMawp}>
                      {liquidContainerMawps.map((mawpValue, i) => (
                        <option value={i} key={`mawpValues-${i}`}>{mawpValue}</option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.containerMawp && errors.containerMawp.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Row>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>One Way Travel Time Design (days)</Form.Label>
                      <Form.Select size="sm" {...register('oneWayTravelTime', { required: 'Please enter a one way travel time in days.' })} isInvalid={!!errors.oneWayTravelTime}>
                        <option key="oneWayTravelTime-1" value={0}>0</option>
                        <option key="oneWayTravelTime-2" value={25}>25</option>
                        <option key="oneWayTravelTime-3" value={30}>30</option>
                        <option key="oneWayTravelTime-4" value={35}>35</option>
                        <option key="oneWayTravelTime-5" value={40}>40</option>
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">
                        {errors.oneWayTravelTime && errors.oneWayTravelTime.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="mb-1">
                      <Form.Label>One Way Travel Time Actual (days)</Form.Label>
                      <Form.Control size="sm" min="0" {...register('oneWayTravelTimeActual', { required: 'Please enter a one way travel time in days.', min: { value: 0, message: 'must be non-negative' } })} isInvalid={!!errors.oneWayTravelTimeActual} />
                      <Form.Control.Feedback type="invalid">
                        {errors.oneWayTravelTimeActual && errors.oneWayTravelTimeActual.message}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </Row>
              <Row>
                <Col>
                  <div style={{ minHeight: 60 }} className="d-flex align-items-center">
                    <Form.Check
                      {...register('hasHeliumShield')}
                      type="switch"
                      inline
                      id="hasHeliumShield-switch"
                      label="He Shield"
                    />
                  </div>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Shield Style</Form.Label>
                    <Form.Select size="sm" disabled={!watch('hasHeliumShield')} {...register('shieldStyle', { required: watch('hasHeliumShield') ? 'You must enter a shield style if container is shielded.' : false })} isInvalid={!!errors.shieldStyle && watch('hasHeliumShield')}>
                      <option value={null} key="shieldStyle-null" />
                      {liquidContainerShieldTypes.map((shieldStyle, i) => (
                        <option value={i} key={shieldStyle}>{shieldStyle}</option>
                      ))}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">
                      {errors.shieldStyle && errors.shieldStyle.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Nominal Capacity</Form.Label>
                    {/* Should this be a basic input from control element? */}
                    <Form.Control
                      type="number"
                      min="0"
                      size="sm"
                      {...register('nominalCapacity', {
                        required: 'Please enter a Nominal Capacity.',
                        min: { value: 35000, message: 'must be between 35000 and 2000000' },
                        max: { value: 2000000, message: 'must be between 35000 and 2000000' },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: 'Value must be a number',
                        },
                      })}
                      isInvalid={!!errors.nominalCapacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.nominalCapacity && errors.nominalCapacity.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Nominal Weight</Form.Label>
                    <BasicInput
                      name="nominalWeight"
                      defaultUnit="lbs"
                      options={['lbs', 'kgs']}
                      isInvalid={!!errors.nominalWeight}
                      {...basicInputProps}
                      createMode
                      registerOptions={{
                        required: 'Please Enter a Nominal Weight',
                        min: { value: 1000, message: 'Value must be between 1000 and 14000 lbs' },
                        max: { value: 14000, message: 'Value must be between 1000 and 14000 lbs' },
                      }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.nominalWeight && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block"
                    >
                      {errors.nominalWeight.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Wet Tare Weight</Form.Label>
                    <BasicInput
                      name="tareWeight"
                      defaultUnit="lbs"
                      options={['lbs', 'kgs']}
                      isInvalid={!!errors.tareWeight}
                      {...basicInputProps}
                      createMode
                      registerOptions={{ required: 'Please enter a Tare Weight', min: { value: 0, message: 'Value cannot be negative' } }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.tareWeight && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.tareWeight.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Dry Tare Weight</Form.Label>
                    <BasicInput
                      name="dryTareWeight"
                      defaultUnit="lbs"
                      options={['lbs', 'kgs']}
                      isInvalid={!!errors.dryTareWeight}
                      createMode
                      {...basicInputProps}
                      registerOptions={{ required: 'Please enter a Dry Tare Weight', min: { value: 0, message: 'Value cannot be negative' } }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.dryTareWeight && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.dryTareWeight.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>LIN Weight</Form.Label>
                    <BasicInput
                      name="lniWeight"
                      defaultUnit="lbs"
                      options={['lbs', 'kgs']}
                      isInvalid={!!errors.lniWeight}
                      createMode
                      {...basicInputProps}
                      registerOptions={{ required: 'Please enter a LIN Weight', min: { value: 0, message: 'Value cannot be negative' } }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.lniWeight && (
                    <Form.Control.Feedback type="invalid" className="d-block">
                      {errors.lniWeight.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>
              <Form.Group className="mb-1">
                <div style={{ minHeight: 60 }} className="d-flex align-items-center">
                  <Form.Check
                    {...register('trickleValve')}
                    inline
                    type="switch"
                    id="trickleValve-switch"
                    label="Trickle Valve"
                  />
                </div>
              </Form.Group>
              <Form.Group className="mb-1">
                <Form.Label>Comments</Form.Label>
                <Form.Control size="sm" as="textarea" rows={3} {...register('comments')} style={{ resize: 'none' }} />
              </Form.Group>
            </Container>

          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={onHide} variant="secondary">Close</Button>
        <Button type="submit" className="mt-3 position-sticky float-end bottom-0" disabled={liquidContainerStatusIsLoading} onClick={handleSubmit(onSubmit)}>Submit</Button>
      </Modal.Footer>
    </>
  );
}

export default CreateLiquidContainerForm;
