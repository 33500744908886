/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo,
  useRef, useState,
} from 'react';
import {
  Button,
  Collapse,
  Form,
  InputGroup,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import {
  BsFillHandThumbsDownFill,
  BsFillHandThumbsUpFill, BsPencilFill, BsXLg,
} from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatCurrency, formatDate } from '../../../services/format';
import { getDisplayNameByOID, sortByBoolean } from '../../../services/table-helpers';
import {
  selectAllActiveUserRepairLocationObjects, selectIsMaintenanceManager, selectLoggedInUserInfo, selectLoggedInUserPreferences,
} from '../../../store/slices/auth-slice';
import { selectMaintenanceRequestDropdownObjects } from '../../../store/slices/maintenance-requests-slice';
import {
  deletePurchaseRequest, selectPurchaseRequestsObjects, updatePurchaseRequestStatus,
} from '../../../store/slices/purchase-request-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import ActiveIcon from '../../UI/atoms/ActiveIcon/active-icon';
import InactiveIcon from '../../UI/atoms/InactiveIcon/inactive-Icon';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';
import RowSelectTable from '../../UI/organisms/Table/row-select-table';
import PurchaseRequestForm from '../Part/purchase-request-form';
import { purchaseRequestPriorities } from '../Part/purchase-requests-values';

const hiddenColumns = [
  'locationTextSearch',
];

function PurchaseRequestsTable(props) {
  const userInfo = useSelector(selectLoggedInUserInfo);

  const filters = useMemo(() => [
    { id: 'locationId', value: userInfo?.locationPreference },
    { id: 'isActive', value: true },
  ], [userInfo?.locationPreference]);

  const [modalShow, setModalShow] = useState(false);
  const [editPurchaseRequest, setEditPurchaseRequest] = useState(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [tableFilterUpdate, setTableFilterUpdate] = useState(false);

  const tableInstance = useRef(null);
  const usersByOid = useSelector(selectUsersByOid);
  const purchaseRequests = useSelector(selectPurchaseRequestsObjects);
  const purchaseRequestsFetchStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsFetch.status);
  const purchaseRequestsFetchError = useSelector((state) => state.purchaseRequests.purchaseRequestsFetch.error);
  const purchaseRequestsDeleteStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsDelete.status);
  const purchaseRequestsDeleteError = useSelector((state) => state.purchaseRequests.purchaseRequestsDelete.error);
  const partsStatus = useSelector((state) => state.parts.partsFetch.status);

  const repairLocations = useSelector(selectAllActiveUserRepairLocationObjects);

  const locations = useMemo(() => Object.values(repairLocations)
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim())), [repairLocations]);

  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);
  const { datePreference } = useSelector(selectLoggedInUserPreferences);
  const { purchaseRequestStatuses } = useSelector(selectMaintenanceRequestDropdownObjects);

  const { confirm } = useConfirm();

  const handleModalShow = (purchaseRequest) => {
    setEditPurchaseRequest(purchaseRequest);
    setModalShow(true);
  };

  const handleModalClose = () => {
    setEditPurchaseRequest(null);
    setModalShow(false);
  };

  const dispatch = useDispatch();

  const handleSelectFilterChange = (event, filterId) => {
    tableInstance.current.setFilter(filterId, event.target.value);
  };

  const handleShowInactiveFilterChange = (event, filterId) => {
    if (event.target.checked) {
      tableInstance.current.setFilter(filterId, '');
    } else {
      tableInstance.current.setFilter(filterId, true);
    }
  };

  const dataByAssetType = useMemo(() => {
    if (purchaseRequests) {
      return Object.values(purchaseRequests).filter((pr) => pr.part?.assetTypeId === props?.assetTypeId);
    }
    return [];
  }, [purchaseRequests, props?.assetTypeId]);

  const determineStatus = (value, row) => {
    if (purchaseRequestStatuses[value]) {
      if (!purchaseRequestStatuses[value].name) {
        return null;
      }
      if (purchaseRequestStatuses[value].name === 'On PO') {
        return (
          <span>
            {purchaseRequestStatuses[value].name}
            { ' '}
            (
            <NavLink to={`/maintenance/purchase-order/${row.original.purchaseOrderId}`}>{row.original.purchaseOrderNumber}</NavLink>
            )
          </span>
        );
      }
      return (purchaseRequestStatuses[value].name);
    }
    return null;
  };

  // Set Default Filterss
  useEffect(() => {
    tableInstance.current.setAllFilters(filters);
  }, [purchaseRequestsFetchStatus, filters]);

  const getDisplayName = useCallback((OID) => {
    return getDisplayNameByOID(OID, usersByOid);
  }, [usersByOid]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => {
          const defaultActionButtons = () => {
            return (
              <div className="d-flex">
                <Button
                  size="xsm"
                  title="Edit"
                  disabled={!cell.row.original.isActive}
                  onClick={() => {
                    handleModalShow(cell.row.original);
                  }}
                >
                  <BsPencilFill />
                </Button>
                  &nbsp;
                <Button
                  size="xsm"
                  variant="danger"
                  disabled={!cell.row.original.isActive}
                  title="Deactivate"
                  onClick={async () => {
                    const isConfirmed = await confirm('Are you sure you want to delete this Purchase Request?');
                    if (isConfirmed) {
                      dispatch(deletePurchaseRequest(cell.row.original.purchaseRequestId));
                    }
                  }}
                >
                  <BsXLg />
                </Button>
              </div>
            );
          };
          const purchaseRequestApproveRejectButtons = () => {
            const status = purchaseRequestStatuses[cell.row.original.purchaseRequestStatusId] ?? '';
            if (status.name === 'New' && isMaintenanceManager) {
              return (
                <div className="d-flex">
                  <Button
                    size="xsm"
                    title="Approve"
                    disabled={!cell.row.original.isActive}
                    onClick={async () => {
                      const isConfirmed = await confirm('Are you sure you want to approve this Purchase Request?');
                      if (isConfirmed) {
                        await dispatch(updatePurchaseRequestStatus({
                          purchaseRequestId: cell.row.original.purchaseRequestId,
                          purchaseRequestStatusId: 5,
                        })).unwrap()
                          .then((originalPromiseResult) => {
                            toast.success('Successfully Approved Purchase Request');
                          }).catch((error) => {
                            console.log(error);
                            toast.success('Failed to Approve Purchase Request');
                          });
                      }
                    }}
                  >
                    <BsFillHandThumbsUpFill />
                  </Button>
                    &nbsp;
                  <Button
                    size="xsm"
                    variant="danger"
                    disabled={!cell.row.original.isActive}
                    title="Reject"
                    onClick={async () => {
                      const isConfirmed = await confirm('Are you sure you want to reject this Purchase Request?');
                      if (isConfirmed) {
                        await dispatch(updatePurchaseRequestStatus({
                          purchaseRequestId: cell.row.original.purchaseRequestId,
                          purchaseRequestStatusId: 2,
                        })).unwrap()
                          .then((originalPromiseResult) => {
                            toast.success('Successfully Rejected Purchase Request');
                          }).catch((error) => {
                            console.log(error);
                            toast.success('Failed to Reject Purchase Request');
                          });
                      }
                    }}
                  >
                    <BsFillHandThumbsDownFill />
                  </Button>
                </div>
              );
            }
            return '';
          };
          return (
            <div className="d-flex">
              {defaultActionButtons()}
              &nbsp;
              {purchaseRequestApproveRejectButtons()}
            </div>
          );
        },
      },
      // {
      //   Header: 'ID',
      //   accessor: 'purchaseRequestId',
      //   disableSortBy: true,
      // },
      {
        Header: 'Part Name',
        accessor: 'part.partName',
      },
      {
        Header: 'Part Id',
        accessor: 'partId',
        Cell: ({ value }) => (<NavLink to={`/maintenance/parts-inventory/${value}`}>{value}</NavLink>),
      },
      {
        Header: 'Status',
        accessor: 'purchaseRequestStatusId',
        // eslint-disable-next-line no-nested-ternary
        Cell: ({ value, row }) => { return determineStatus(value, row); },
      },
      {
        Header: 'Quantity Requested',
        accessor: 'quantityRequested',
      },
      {
        // – From the Last Purchase Order – Original Currency
        Header: 'Last Cost',
        id: 'part.lastCost',
        accessor: ({ part }) => formatCurrency(part?.lastCost),
      },
      {
        Header: 'Requested Date',
        accessor: 'created',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
      {
        Header: 'Requested By',
        id: 'createdBy',
        accessor: ({ createdBy: value }) => (getDisplayName(value)),
      },
      {
        Header: 'Location',
        accessor: 'part.locationId',
        filter: 'exactText',
        id: 'locationId',
        Cell: ({ value }) => repairLocations[value]?.LocationName ?? value,
      },
      {
        filter: 'exactText',
        id: 'locationTextSearch',
        accessor: ({ part: { locationId: value } }) => repairLocations[value]?.LocationName ?? value,
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ value }) => (value == null ? null : purchaseRequestPriorities[value]),
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? <ActiveIcon /> : <InactiveIcon />),
      },
    ],
    [purchaseRequestStatuses, repairLocations, purchaseRequestsFetchStatus],
  );

  const requestsAreLoading = [
    purchaseRequestsFetchStatus,
    purchaseRequestsDeleteStatus,
    partsStatus,
  ].includes('loading');

  const myContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            data-cy={`${props.assetTypeId}-filter-button`}
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={tableInstance} />
           &nbsp;
          <Button variant="primary" size="sm" onClick={() => handleModalShow(null)}>Create Purchase Request</Button>
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select data-cy={`${props.assetTypeId}-location-filter`} value={tableInstance?.current?.state?.filters?.find((filter) => filter.id === 'locationId')?.value} size="sm" onChange={(e) => { handleSelectFilterChange(e, 'locationId'); }}>
                  <option value="" key="customer-null">-- Location --</option>
                  {locations.map((location, index) => (
                    <option
                      value={location.LocationID}
                      key={`location-${index}`}
                    >
                      {location.LocationName}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Show InActive"
                onChange={(e) => handleShowInactiveFilterChange(e, 'isActive')}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <RowSelectTable
        columns={columns}
        data={dataByAssetType}
        ref={tableInstance}
        rowProps={() => ({})}
        triggerExternalRenderUpdate={setTableFilterUpdate}
        initialState={{
          hiddenColumns,
          sortBy: [
            {
              id: 'created',
              desc: true,
            },
          ],
        }}
        noCheck
      />
      <PurchaseRequestForm
        show={modalShow}
        onHide={handleModalClose}
        partId={editPurchaseRequest ? editPurchaseRequest.partId : null}
        assetTypeId={props.assetTypeId}
        purchaseRequest={editPurchaseRequest}
      />
    </>
  );

  return (
    <>
      <div data-cy={`${props.assetTypeId}-purchase-requests`} className={`d-flex flex-column flex-grow-1 h-100${requestsAreLoading === 'loading' ? ' creation-loading' : ''}`}>
        {myContent}
      </div>
      {purchaseRequestsFetchStatus === 'failed' && <ErrorContent errorMessage={purchaseRequestsFetchError} />}
      {requestsAreLoading && <LoadingContent />}
    </>
  );
}

export default PurchaseRequestsTable;
