

import { getDewarInvoices, getDewarPricing, getInvoiceFromOrder, getPendingManualUpload } from "../../../../services/requests/billing/dewar-billing-requests";
import { DataSource } from "../../../UI/view/data-source";

export const dewarInvoiceDataSource = {
  name: 'dewarinvoices',
  pk: 'billingId',
  request: getDewarInvoices,
  serverSideEvents: true,
} as DataSource;

export const dewarPendingDataSource = {
  name: 'dewarpending',
  pk: 'orderNumber',
  request: getPendingManualUpload,
  entityRequest: getInvoiceFromOrder
} as DataSource;

export const dewarPricingDataSource = {
  name: 'dewarpricing',
  pk: 'billingId',
  entityRequest: getDewarPricing,
  entityRequestArgs: ({ getValues }) => {
    const { cst } = getValues();
    return cst;
  }
} as DataSource;