import axios from "axios";
import { handleRequestError, memoizePromiseFn } from "../../requests";

export const InsertBilling = async (billing) => {
  const results = await axios.post('/GasRentalBilling/InsertBilling', billing).catch(handleRequestError);
  return results.data;
}

export const InsertEmptyGasInvoice = async (billing) => {
  const results = await axios.post('/GasRentalBilling/InsertEmptyGasInvoice', billing).catch(handleRequestError);
  return results.data;
}
export const GetGasRentalBillingList = async (queryDefinition) => {
  const results = await axios.post('/GasRentalBilling/GetGasRentalBillingList', queryDefinition).catch(handleRequestError);
  return results.data;
};
export const getGasRental = async (dewarRental) => {
  const results = await axios.get(`/GasRentalBilling/getGasRental/${dewarRental}`).catch(handleRequestError);
  return results.data;
};
export const getGasRentalsUnbilled = async (def) => {
  const results = await axios.get(`/GasRentalBilling/getGasRentalsUnbilled`).catch(handleRequestError);
  return results.data;
};
export const getGasRentalsMTD = async (def) => {
  const results = await axios.get(`/GasRentalBilling/getGasRentalsMTD`).catch(handleRequestError);
  return results.data;
};

export const deleteInvoice = async (invoiceId) => {
  const results = await axios.delete(`/GasRentalBilling/DeleteInvoice/${invoiceId}`).catch(handleRequestError);
  return results.data;
};
// /PreAuthorizeCreditCard
export const PreAuthorizeCreditCard = async (billingId) => {
  const results = await axios.get(`/GasRentalBilling/PreAuthorizeCreditCard/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const getBillingHeader = async (billingId) => {
  const results = await axios.get(`/GasRentalBilling/getBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const reverseBilling = async (reverse) => {
  const results = await axios.post(`/GasRentalBilling/reverseBilling`, reverse).catch(handleRequestError);
  return results.data;
};

export const uploadInvoicesJDEDTO = async (invoice) => {
  const results = await axios.post(`/GasRentalBilling/UploadInvoicesToJDE`, invoice).catch(handleRequestError);
  return results.data;
};

export const SendReport = async (report) => {
  const results = await axios.post('/GasRentalBilling/SendReport', report).catch(handleRequestError);
  return results.data;
};
export const downloadReport = async (report) => {
  const results = await axios.post('/GasRentalBilling/DownloadReport', report, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', report.filename);
  document.body.appendChild(link);
  link.click();
};
export const GetJDEErrors = async (billing) => {
  const results = await axios.get('/GasRentalBilling/GetJDEErrors').catch(handleRequestError);
  return results.data;
}
export const GetUnbilledInvoices = async (billing) => {
  const results = await axios.get('/GasRentalBilling/GetUnbilledInvoices').catch(handleRequestError);
  return results.data;
}

export const UpdateBilling = async (billing) => {
  const results = await axios.put('/GasRentalBilling/UpdateBilling', billing).catch(handleRequestError);
  return results.data;
}

export const InvoiceAllGasRentals = async () => {
  const results = await axios.post('/GasRentalBilling/InvoiceAll').catch(handleRequestError);
  return results.data;
}
//invoiceAllGasRental