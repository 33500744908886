import './table.scss';

import { range } from 'lodash';
import React, { forwardRef, useImperativeHandle } from 'react';
import {
  Button, ButtonGroup, Collapse,
  Form, Table as BootstrapTable,
} from 'react-bootstrap';
import {
  useExpanded, useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable,
} from 'react-table';

// eslint-disable-next-line object-curly-newline
const ExpandablePaginatedSelectTable = forwardRef(({
  columns,
  data,
  rowProps,
  initialState,
  renderRowSubComponent,
}, ref) => {
  const startState = { pageIndex: 0, pageSize: 20, ...initialState };
  const instance = useTable(
    {
      columns, data, initialState: startState, autoResetExpanded: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    visibleColumns,
    state: { pageIndex, pageSize },
  } = instance;

  useImperativeHandle(ref, () => instance);

  const getPageRange = (currentPage, numPages) => {
    if (numPages < 5) {
      return range(1, numPages + 1);
    }
    if (currentPage <= 3) {
      return range(1, 6);
    }
    if (currentPage + 3 > numPages) {
      return range(numPages - 4, numPages + 1);
    }
    return range(currentPage - 2, currentPage + 3);
  };

  return (
  // apply the table props
    <div className="d-flex flex-column overflow-auto flex-grow-1 justify-content-between">
      <div className="flex-grow-1 overflow-auto">
        <BootstrapTable size="sm" hover {...getTableProps()} className="custom-table expandable-table">
          <thead>
            {// Loop over the header rows
                        headerGroups.map((headerGroup) => (
                          // Apply the header row props
                          <tr {...headerGroup.getHeaderGroupProps()}>
                            {// Loop over the headers in each row
                                    headerGroup.headers.map((column) => (
                                      // Apply the header cell props
                                      <th {...column.getHeaderProps({
                                        style: {
                                          minWidth: column.minWidth,
                                          maxWidth: column.maxWidth,
                                          width: column.width,
                                        },
                                      })}
                                      >
                                        {// Render the header
                                                column.render('Header')
                                            }
                                      </th>
                                    ))
                                }
                          </tr>
                        ))
                    }
          </thead>
          <tbody {...getTableBodyProps()}>
            {
              page.map((row) => {
                prepareRow(row);
                return (
                  <React.Fragment key={row.getRowProps().key}>
                    {/* Need to manually add a strip row class to achieve the strip effect
                    since we dont want to include the sub rows in the  */}
                    <tr {...row.getRowProps()} className="outer-row">
                      {
                        row.cells.map((cell) => (
                          <td {...cell.getCellProps([
                            {
                              className: cell.column.className,
                              style: cell.column.style,
                            },
                          ])}
                          >
                            {
                                cell.render('Cell')
                            }
                          </td>
                        ))
                    }
                    </tr>

                    <tr className="m-0 p-0">
                      <td className="m-0 p-0 border-0" colSpan={visibleColumns.length}>
                        <Collapse in={row.isExpanded}>
                          <div>
                            {renderRowSubComponent({ row })}
                          </div>
                        </Collapse>
                      </td>
                    </tr>
                    {/* <tr>
                        <td colSpan={visibleColumns.length}>

                        </td>
                      </tr> */}

                  </React.Fragment>
                );
              })
          }
          </tbody>
        </BootstrapTable>
      </div>
      <div className="w-100 bg-white position-sticky bottom-0 pb-2 pt-2 pr-4 pl-4">
        <div className="pagination d-flex align-items-center justify-content-between">

          {/* <div className="d-flex align-items-center">
            Go to page:
            <Form.Control
              size="sm"
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const pg = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(pg);
              }}
              style={{ width: '100px' }}
            />
          </div> */}
          <Form.Group className="pr-2">
            <Form.Select
              size="sm"
              value={pageSize}
              onChange={(e) => {
                setPageSize(Number(e.target.value));
              }}
            >
              {[25, 50, 75, 100, data.length].map((size, i) => (
                <option key={size} value={size}>
                  Show
                  {' '}
                  {i === 4 ? 'ALL' : size}
                </option>
              ))}
            </Form.Select>
          </Form.Group>

          <ButtonGroup size="sm">
            <Button
              variant="outline-primary"
              onClick={() => gotoPage(0)}
              disabled={!canPreviousPage}
            >
              {'<<'}
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => previousPage()}
              disabled={!canPreviousPage}
            >
              {'<'}
            </Button>
            {getPageRange(pageIndex + 1, pageOptions.length).map((pg) => (
              <Button
                key={`page-button-${pg}`}
                variant="outline-primary"
                onClick={() => gotoPage(pg - 1)}
                active={pageIndex + 1 === pg}
              >
                {pg}
              </Button>
            ))}
            <Button
              variant="outline-primary"
              onClick={() => nextPage()}
              disabled={!canNextPage}
            >
              {'>'}
            </Button>
            <Button
              variant="outline-primary"
              onClick={() => gotoPage(pageCount - 1)}
              disabled={!canNextPage}
            >
              {'>>'}
            </Button>
          </ButtonGroup>
          <div>
            Page
            {' '}
            <strong>
              {pageIndex + 1}
              {' '}
              of
              {' '}
              {pageOptions.length}
            </strong>
            {' '}
          </div>
        </div>
      </div>
    </div>
  );
});

export default ExpandablePaginatedSelectTable;
