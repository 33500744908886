import React from 'react';
import { ListGroup, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectGasCustomerObjects } from '../../../store/slices/legacy-slice';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';

function GasContainerInfo({ currentGasContainer, gasContainerStatusIsLoading, condensed }) {
  const gasCustomers = useSelector(selectGasCustomerObjects);

  const numberOfTubes = gasContainerStatusIsLoading ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> : currentGasContainer.numberOfTubes;
  const ownerCustomer = gasContainerStatusIsLoading ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> : gasCustomers[currentGasContainer.ownerCustomer]?.CustomerName;
  const business = gasContainerStatusIsLoading ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> : currentGasContainer.business;
  const style = gasContainerStatusIsLoading ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> : currentGasContainer.style;
  const tareWeight = gasContainerStatusIsLoading ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> : currentGasContainer.tareWeight;

  return (
    <Callout className={`${gasContainerStatusIsLoading ? ' creation-loading' : ''}`}>
      {!condensed && (
      <>
        <h4>{currentGasContainer ? currentGasContainer.serialNumber : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}</h4>
        <p><i>{currentGasContainer ? currentGasContainer.comments : (<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}</i></p>
      </>
      )}
      <ListGroup variant="unbordered">
        <ListGroup.Item><InfoListItem name="# Tubes" value={(numberOfTubes)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Owner" value={(ownerCustomer)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Business" value={(business)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Style" value={(style)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Weight" value={(tareWeight)} /></ListGroup.Item>
      </ListGroup>
    </Callout>
  );
}

export default GasContainerInfo;
