import { configureStore } from '@reduxjs/toolkit';

import { dewarCustomerDataSource, gasCustomerDataSource, liquidCustomerDataSource } from '../components/pages/Customers/customer-data-source';
import { userDataSource } from '../components/pages/Users/user-data-source';
import { vendorDataSource } from '../components/pages/Vendors/vendor-data-source';
import { worldMapDataSource } from '../components/pages/WorldMap/worldmap-data-source';
import { attachmentDataSource } from '../components/templates/Entity/entity-attachments-data-source';
import { createReduxStore } from '../components/UI/view/data-source';
import adUsersReducer from './slices/ad-users-slice';
import assetLocationsReducer from './slices/asset-locations-slice';
import assetOrderHistoryReducer from './slices/asset-order-history-slice';
import auditReducer from './slices/audit-slice';
import authReducer from './slices/auth-slice';
import chassisReducer from './slices/chassis-slice';
import fileAttachmentReducer from './slices/file-attachment-slice';
import gasContainerReducer from './slices/gas-containers-slice';
import legacyReducer from './slices/legacy-slice';
import liquidAssetsReducer from './slices/liquid-containers-slice';
import maintenanceRequestLineItemReducer from './slices/maintenance-request-line-items-slice';
import mainteanceRequestReducer from './slices/maintenance-requests-slice';
import packagedProductsReducer from './slices/packaged-products-slice';
import partsReducer from './slices/parts-slice';
import permissionsReducer from './slices/permissions-slice';
import purchaseOrderReducer from './slices/purchase-orders-slice';
import purchaseRequestsReducer from './slices/purchase-request-slice';
import roleReducer from './slices/roles-slice';
import userReducer from './slices/users-slice';
import workOrderReducer from './slices/work-orders-slice';
/* import { gasHistoryDataSource } from '../components/pages/GasContainer/gas-history-datasource'; */

export const vendorStore = createReduxStore(vendorDataSource);
export const userStore = createReduxStore(userDataSource);
/* export const worldmapStore = createReduxStore(worldMapDataSource); */
export const dewarCustomerStore = createReduxStore(dewarCustomerDataSource);
export const attachmentsStore = createReduxStore(attachmentDataSource);
export const gasCustomerStore = createReduxStore(gasCustomerDataSource);
export const liquidCustomerStore = createReduxStore(liquidCustomerDataSource);
/* export const gasHistoryStore = createReduxStore(gasHistoryDataSource); */
/* export const liquidHistoryStore = createReduxStore(liquidHistoryDataSource); */

export let reduxReducer = {
  auth: authReducer,
  packagedProduct: packagedProductsReducer,
  liquidContainer: liquidAssetsReducer,
  gasContainer: gasContainerReducer,
  chassis: chassisReducer,
  adUser: adUsersReducer,
  user: userReducer,
  role: roleReducer,
  permission: permissionsReducer,
  legacy: legacyReducer,
  workOrder: workOrderReducer,
  maintenanceRequest: mainteanceRequestReducer,
  assetLocation: assetLocationsReducer,
  audit: auditReducer,
  maintenanceRequestLineItem: maintenanceRequestLineItemReducer,
  attachment: fileAttachmentReducer,
  parts: partsReducer,
  purchaseOrder: purchaseOrderReducer,
  purchaseRequests: purchaseRequestsReducer,
  assetOrderHistory: assetOrderHistoryReducer,
  [vendorDataSource.name]: vendorStore.reducer,
  [userDataSource.name]: userStore.reducer,
/*   [worldMapDataSource.name]: worldmapStore.reducer, */
  [dewarCustomerDataSource.name]: dewarCustomerStore.reducer,
  [gasCustomerDataSource.name]: gasCustomerStore.reducer,
  [liquidCustomerDataSource.name]: liquidCustomerStore.reducer,
  [attachmentDataSource.name]: attachmentsStore.reducer,
/*   [gasHistoryDataSource.name]: gasHistoryStore.reducer, */
/*   [liquidHistoryDataSource.name]: liquidHistoryStore.reducer, */
};

export const setReduxReducer = (val) => reduxReducer = val;


