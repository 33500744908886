import { createListView, FilterType, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { dewarCustomerStore } from "../../../store/store";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createView } from "../../UI/view/view";
import {usaStates} from '../../../data/states';
import { assetRoute } from "../WorldMap/worldmap-list";
import {DewarCustomerList} from './customer-dewar-list';
import {LiquidCustomerList} from './customer-liquid-list';
import {GasCustomerList} from './customer-gas-list';
import { customerPermissions } from "./customer-data-source";

export const userListTabs: FormView = {
  pageHeader: 'Customers',
  breadcrumbs: [
    {
      name: 'Customers',
      icon: 'people',
      to: '/maintenance/customers',
    },
  ],
  permissions: customerPermissions,
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Liquid Customers',
          items: [
            {
              type: FormItemType.customComponent,
              component: LiquidCustomerList
            }
          ]
        },
        {
          label: 'Gas Customers',
          items: [
            {
              type: FormItemType.customComponent,
              component: GasCustomerList
            }
          ]
        },
        {
          label: 'Packaged Customers',
          items: [
            {
              type: FormItemType.customComponent,
              component: DewarCustomerList
            }
          ]
        },
      ]
    }
  ]
};

export const CustomerList = createView({form: userListTabs as any, repository: dewarCustomerStore as any});

