import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, InputGroup, OverlayTrigger, Tooltip,
} from 'react-bootstrap';
import { BsFillPencilFill } from 'react-icons/bs';
// import CardHeader from 'react-bootstrap/esm/CardHeader';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchRoles, selectRoles, selectRolesStatus, setCurrentRoleId,
} from '../../../store/slices/roles-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import RoleCreateModal from './role-create-modal';

function RolesTable(props) {
  const roles = useSelector(selectRoles);
  const rolesStatus = useSelector(selectRolesStatus);
  const rolesError = useSelector((state) => state.role.rolesFetch.error);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (rolesStatus === 'idle' || rolesStatus === 'failed') {
      dispatch(fetchRoles());
    }
  }, [rolesStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const rolesTableInstance = useRef(null);

  const rolesData = useMemo(() => {
    if (roles) {
      return [...Object.values(roles)];
    }

    return [];
  }, [roles]);

  const [modalShow, setModalShow] = useState(false);
  const toggleModal = () => setModalShow(!modalShow);

  const renderTooltip = (info) => (
    <Tooltip id="button-tooltip">
      {info}
    </Tooltip>
  );

  const rolesColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ row, cell }) => (
          <>
            {row.original.name !== 'Application User' ? (
              <Button
                size="xsm"
                key="Edit"
                title="Edit"
                onClick={() => {
                  dispatch(setCurrentRoleId(cell.row.original.roleId));
                  setModalShow(true);
                }}
              >
                <BsFillPencilFill />
              </Button>
            )
              : (
                <OverlayTrigger
                  placement="top"
                  overlay={renderTooltip('Application User is the Base User Role and Cannot be Modified')}
                >
                  <span className="d-inline-block">
                    <Button
                      size="xsm"
                      disabled
                      key="Edit"
                      title="Edit"
                      onClick={() => {

                      }}
                    >

                      <BsFillPencilFill />
                    </Button>
                  </span>
                </OverlayTrigger>
              )}

            {/* &nbsp;
            {row.original.name !== 'Application User' && (
            <Button
              size="xsm"
              variant="danger"
              key="Delete"
              title="Delete"
              onClick={() => {
                dispatch(deleteRole(cell.row.original.roleId));
              }}
            >
              <i className="bi-x-octagon" />
            </Button>
            )} */}
          </>
        ),
      },
      {
        Header: '# Users',
        accessor: (r) => (r?.users ? r.users.length : 0),
      },
      {
        Header: 'Role Name',
        accessor: 'name',
      },
      {
        Header: 'Description',
        accessor: 'description',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [],
  );

  const rolesTable = (
    <PaginatedSelectTable
      columns={rolesColumns}
      data={rolesData}
      ref={rolesTableInstance}
      rowProps={() => ({})}
    />
  );

  let rolesContent;
  if (rolesStatus === 'failed') {
    rolesContent = <ErrorContent errorMessage={rolesError} />;
  } else if (rolesStatus === 'loading') {
    rolesContent = <LoadingContent />;
  }

  return (
    <div className={`d-flex flex-column flex-grow-1 h-100${rolesStatus === 'loading' ? ' creation-loading' : ''}`}>
      <div className="card-tools">
        <InputGroup size="sm">
          <InputGroup.Text><i className="bi bi-search fas" /></InputGroup.Text>
          <TableSearchFilter tableInstance={rolesTableInstance} />
          {/*
            Removing temporarily, roles wont be creatable on initial release
                    &nbsp;
          <Button variant="primary" onClick={
            () => toggleModal()}>Add Role</Button>
            */}
        </InputGroup>

      </div>
      {rolesTable}
      {rolesContent}
      <RoleCreateModal show={modalShow} onHide={() => toggleModal()} />
    </div>

  );
}

export default RolesTable;
