import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { getDropdownValues } from '../../services/requests';

const initialState = {
  dropdownValues: {},
  status: 'idle',
  error: null,
};

export const fetchDropdownValues = createAsyncThunk('legacy/fetchDropdownValues', async () => {
  const response = await getDropdownValues();
  return response;
});

export const legacySlice = createSlice({
  name: 'legacy',
  initialState,
  reducers: {
    setFetchDropdownStatus: (state, action) => {
      state.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchDropdownValues.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchDropdownValues.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.dropdownValues = action.payload;
        state.error = null;
      })
      .addCase(fetchDropdownValues.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setFetchDropdownStatus } = legacySlice.actions;

export const selectPackagedProductTypes = createSelector(
  [((state) => state.legacy?.dropdownValues?.packagedProductTypes)],
  (packagedProductTypes) => packagedProductTypes ?? [],
);

export const selectPackagedProductTypesObject = (state) => (
  (state.legacy?.dropdownValues?.packagedProductTypes ?? []).reduce(
    (current, p) => ({ ...current, [p.id]: p }),
    {},
  )
);

export const selectPackagedProductLocations = createSelector(
  [((state) => state.legacy?.dropdownValues?.locations)],
  (locations) => (locations ?? []).filter((location) => location.Active && location.isTransfill),
);

export const selectAllLocationObjects = createSelector(
  [((state) => state.legacy?.dropdownValues?.locations)],
  (locations) => (locations ?? []).reduce(
    (current, l) => ({ ...current, [l.LocationID]: l }),
    {},
  ),
);

export const selectAllActiveLocations = createSelector(
  [((state) => state.legacy?.dropdownValues?.locations)],
  (locations) => (locations ?? []).filter((location) => location.Active),
);

export const selectAllActiveLocationObjects = createSelector(
  [selectAllActiveLocations],
  (locations) => locations.reduce(
    (current, l) => ({ ...current, [l.LocationID]: l }),
    {},
  ),
);

export const selectGasCustomers = createSelector(
  [((state) => state.legacy?.dropdownValues?.gasCustomers),
    ((state) => state.gasContainer?.gasContainers)],
  (gasCustomers, gasContainers) => {
    const containers = Object.values(gasContainers ?? {});
    return (gasCustomers ?? []).filter((c) => {
      return containers.find((container) => container.ownerCustomer?.toString() === c.CustomerID);
    });
  },
);

export const selectGasCustomerObjects = createSelector(
  [selectGasCustomers],
  (gasCustomers) => gasCustomers.reduce((current, g) => ({ ...current, [g.CustomerID]: g }), {}),
);

export const selectLiquidCustomers = createSelector(
  [((state) => state.legacy?.dropdownValues?.liquidCustomers),
    ((state) => state.liquidContainer?.liquidContainers)],
  (liquidCustomers, liquidContainers) => {
    const containers = Object.values(liquidContainers ?? {});
    return (liquidCustomers ?? []).filter((c) => {
      return containers.find((container) => container.ownerCustomer?.toString() === c.CustomerID);
    });
  },
);

export const selectChassisCustomers = createSelector(
  [((state) => state.legacy?.dropdownValues?.liquidCustomers),
    ((state) => state.chassis?.chassis)],
  (liquidCustomers, chassis) => {
    const containers = Object.values(chassis ?? {});
    return (liquidCustomers ?? []).filter((c) => {
      return containers.find((container) => container.ownerCustomer?.toString() === c.CustomerID);
    });
  },
);

export const selectFileTypes = createSelector(
  [((state) => state.legacy?.dropdownValues?.fileTypes)],
  (fileTypes) => fileTypes ?? [],
);

export const selectLiquidFileTypes = createSelector(
  [selectFileTypes],
  (fileTypes) => fileTypes.filter((fileType) => fileType.FileClass === 'LiquidContainers'),
);

export const selectGasFileTypes = createSelector(
  [selectFileTypes],
  (fileTypes) => fileTypes.filter((fileType) => fileType.FileClass === 'GasContainers'),
);

export const selectLiquidCustomerObjects = createSelector(
  [selectLiquidCustomers],
  (liquidCustomers) => liquidCustomers.reduce(
    (current, c) => ({ ...current, [c.CustomerID]: c }),
    {},
  ),
);

export default legacySlice.reducer;
