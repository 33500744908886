import React, { useEffect, useState } from 'react';
import {
  Card, Col,
  Container, Row, Spinner,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { selectLoggedInUserInfo } from '../../../store/slices/auth-slice';
import {
  selectCurrentMaintenanceRequestTypeForTemplateEditData,
  selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  setCreateMaintenanceRequestLineItemTemplateStatus,
  setRemoveMaintenanceRequestLineItemTemplateStatus,
  setUpdateMaintenanceRequestLineItemTemplateStatus,
} from '../../../store/slices/maintenance-requests-slice';
import CustomModal from '../../UI/molecules/Modal/modal';
import LineItemTemplatesModal from './line-item-templates-modal';
import LineItemTemplatesTable from './line-item-templates-table';
import RequestTypeInfo from './request-type-info';

function RequestTypeTemplateInfo(props) {
  const dispatch = useDispatch();

  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const userInfo = useSelector(selectLoggedInUserInfo);

  let content = null;
  const maintenanceRequestTypeId = useSelector(
    selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  );

  const maintenanceRequestType = useSelector(
    selectCurrentMaintenanceRequestTypeForTemplateEditData,
  );
  if (maintenanceRequestTypeId === null) {
    content = (
      // eslint-disable-next-line max-len
      <div>
        The selected Maintenance Request Type
        does not have an associated Request Type Template

      </div>
    );
  } else if (userInfo?.locationPreference === null) {
    content = (
      // eslint-disable-next-line max-len
      <div className="h-100 w-100 d-flex align-items-center justify-content-center px-3">
        <h2>
          Set a Location Preference
        </h2>

      </div>
    );
  } else if (maintenanceRequestTypeId === undefined) {
    content = (
      // eslint-disable-next-line max-len
      <div className="h-100 w-100 d-flex align-items-center justify-content-center px-3">
        <h2>
          Select a Maintenance Request Type
        </h2>

      </div>
    );
  } else {
    content = (
      <Container fluid className="flex-grow-1 d-flex flex-column">
        <Row>
          <Col>
            <RequestTypeInfo requestType={maintenanceRequestType} />
          </Col>
        </Row>
        <Row className="flex-grow-1">
          <Col>
            <Card className="card-secondary card-outline h-100 d-flex flex-column m-0">
              <Container fluid>
                <Row className="flex-grow-1">
                  <Col>
                    <LineItemTemplatesTable handleModalShow={handleModalShow} />
                  </Col>
                </Row>
              </Container>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  }

  const lineItemTemplateUpdateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateUpdate.status,
  );

  const lineItemTemplateCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateCreate.status,
  );

  const lineItemTemplateDeleteStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateRemove.status,
  );

  const requestTypeTemplateCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypeTemplateCreate.status,
  );

  const requestTypeTemplateFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypeTemplateFetch.status,
  );

  const partsStatus = useSelector((state) => state.parts.partsFetch.status);

  const lineItemTemplateUpdateNotify = () => toast.success('Updated Maintenance Request Line Item Template');
  const lineItemTemplateCreateNotify = () => toast.success('Created Maintenance Request Line Item Template');
  const lineItemTemplateDeleteNotify = () => toast.success('Deleted Maintenance Request Line Item Template');
  const lineItemTemplateUpdateFailedNotify = () => toast.error('Failed updating Maintenance Request Line Item Template');
  const lineItemTemplateCreateFailedNotify = () => toast.error('Failed createing Maintenance Request Line Item Template');
  const lineItemTemplateDeleteFailedNotify = () => toast.error('Failed deleting Maintenance Request Line Item Template');

  useEffect(() => {
    if (lineItemTemplateUpdateStatus === 'succeeded') {
      lineItemTemplateUpdateNotify();
      dispatch(setUpdateMaintenanceRequestLineItemTemplateStatus('idle'));
      handleModalClose();
    }
    if (lineItemTemplateCreateStatus === 'succeeded') {
      lineItemTemplateCreateNotify();
      dispatch(setCreateMaintenanceRequestLineItemTemplateStatus('idle'));
      handleModalClose();
    }
    if (lineItemTemplateDeleteStatus === 'succeeded') {
      lineItemTemplateDeleteNotify();
      dispatch(setRemoveMaintenanceRequestLineItemTemplateStatus('idle'));
    }
    if (lineItemTemplateUpdateStatus === 'failed') {
      lineItemTemplateUpdateFailedNotify();
      dispatch(setUpdateMaintenanceRequestLineItemTemplateStatus('idle'));
    }
    if (lineItemTemplateCreateStatus === 'failed') {
      lineItemTemplateCreateFailedNotify();
      dispatch(setCreateMaintenanceRequestLineItemTemplateStatus('idle'));
    }
    if (lineItemTemplateDeleteStatus === 'failed') {
      lineItemTemplateDeleteFailedNotify();
      dispatch(setRemoveMaintenanceRequestLineItemTemplateStatus('idle'));
    }
  }, [
    lineItemTemplateCreateStatus, lineItemTemplateUpdateStatus,
    lineItemTemplateDeleteStatus,
  ]);

  const lineItemTemplateStatusIsLoading = [
    partsStatus,
    lineItemTemplateUpdateStatus,
    lineItemTemplateCreateStatus,
    lineItemTemplateDeleteStatus,
    requestTypeTemplateCreateStatus,
    requestTypeTemplateFetchStatus].includes('loading');

  return (
    <>
      <Container
        fluid
        className={`h-100 d-flex flex-column${lineItemTemplateStatusIsLoading ? ' creation-loading' : ''}`}
      >
        {lineItemTemplateStatusIsLoading ? (
          <Spinner
            animation="border"
            variant="primary"
            className="create-spinner"
          />
        ) : ''}
        <Card className="card-primary card-outline h-100 d-flex flex-column m-0">
          <div className="card-body px-1 d-flex">
            {content}
          </div>
        </Card>
      </Container>
      <CustomModal
        contentClassName="h-75"
        show={modalShow}
        onHide={handleModalClose}
      >
        <LineItemTemplatesModal
          createMode
          handleModalClose={handleModalClose}
          title="Add New Line Item Template"
          lineItemTemplatesData={null}
        />
      </CustomModal>
    </>
  );
}

export default RequestTypeTemplateInfo;
