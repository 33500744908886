import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { formatDate } from '../../../../services/format';

export function NextDueDate({ dueDate, isOverridden, datePreference }) {
  if (isOverridden) {
    return (
      <OverlayTrigger
        placement="right"
        overlay={<Tooltip id="overlay-example">Planned Due Date Overridden</Tooltip>}
      >
        <div>
          {formatDate(dueDate, datePreference)}
          <sup className="text-danger">*</sup>
        </div>
      </OverlayTrigger>
    );
  }
  return dueDate ? formatDate(dueDate, datePreference) : '';
}
