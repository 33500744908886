import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col,
  Container, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { formatMaintenanceRequestLineItemType } from '../../../services/format';
import { selectLoggedInUserActiveLocation } from '../../../store/slices/auth-slice';
import {
  createMaintenanceRequestLineItemTemplate,
  createMaintenanceRequestTypeTemplate,
  selectCurrentMaintenanceRequestLineItemTemplateId,
  selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  selectCurrentMaintenanceRequestTypeTemplateForActiveLocation,
  selectMaintenanceRequestDropdownValues,
  setCurrentMaintenanceRequestLineItemTemplateId,
  setCurrentMaintenanceRequestTypeTemplate,
  updateMaintenanceRequestLineItemTemplate,
} from '../../../store/slices/maintenance-requests-slice';
import { selectActiveLocationParts } from '../../../store/slices/parts-slice';
import { Typeahead } from '../../UI/molecules/Typeahead';

function LineItemTemplatesModal(props) {
  const currentMaintenanceRequestTypeIdForTemplateEdit = useSelector(
    selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  );

  const currentMaintenanceRequestTypeTemplate = useSelector(
    selectCurrentMaintenanceRequestTypeTemplateForActiveLocation,
  );

  const defaultLineItemTemplateModalValues = {
    maintenanceRequestTypeTemplateId:
      currentMaintenanceRequestTypeTemplate?.maintenanceRequestTypeTemplateId,
    maintenanceRequestLineItemTypeId: '1',
  };
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultLineItemTemplateModalValues,
  });

  const currentMaintenanceRequestLineItemTemplateId = useSelector(
    selectCurrentMaintenanceRequestLineItemTemplateId,
  );

  let currentMaintenanceRequestLineItemTemplate = null;
  if (props.lineItemTemplatesData !== null) {
    currentMaintenanceRequestLineItemTemplate = props.lineItemTemplatesData.find(
      // eslint-disable-next-line max-len
      (lineItemTemplate) => lineItemTemplate.maintenanceRequestLineItemTemplateId === currentMaintenanceRequestLineItemTemplateId,
    );
  }

  const parts = useSelector(selectActiveLocationParts);

  const [selectedPart, setSelectedPart] = useState('');

  const partsList = useMemo(() => {
    if (parts) {
      return Object.values(parts).map((p) => p.partName);
    }
    return [];
  }, [parts]);

  // Populate form with default values
  useEffect(() => {
    if (currentMaintenanceRequestLineItemTemplate !== null
      && currentMaintenanceRequestLineItemTemplate !== undefined) {
      setValue('maintenanceRequestLineItemTemplateId', currentMaintenanceRequestLineItemTemplate.maintenanceRequestLineItemTemplateId);
      setValue('description', currentMaintenanceRequestLineItemTemplate.description);
      setValue('inventoryItemId', currentMaintenanceRequestLineItemTemplate.inventoryItemId);
      if (parts[currentMaintenanceRequestLineItemTemplate.inventoryItemId]) {
        setSelectedPart(parts[currentMaintenanceRequestLineItemTemplate.inventoryItemId].partName);
      }
      setValue('laborBillOutCostPerHour', currentMaintenanceRequestLineItemTemplate.laborBillOutCostPerHour);
      setValue('maintenanceRequestLineItemTypeId', currentMaintenanceRequestLineItemTemplate.maintenanceRequestLineItemTypeId);
      setValue('partsBillOutCost', currentMaintenanceRequestLineItemTemplate.partsBillOutCost);
      setValue('quantity', currentMaintenanceRequestLineItemTemplate.quantity);
    }
  }, [currentMaintenanceRequestLineItemTemplate]);

  const { maintenanceRequestLineItemTypes } = useSelector(selectMaintenanceRequestDropdownValues);

  const dispatch = useDispatch();

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const onSubmit = (lineItemTemplate) => {
    if (currentMaintenanceRequestTypeTemplate === null) {
      dispatch(createMaintenanceRequestTypeTemplate({
        maintenanceRequestTypeId: currentMaintenanceRequestTypeIdForTemplateEdit,
        locationId: currentUserActiveLocation.LocationID,
        isActive: true,
      })).then((r) => {
        dispatch(setCurrentMaintenanceRequestTypeTemplate(r.payload));
        dispatch(createMaintenanceRequestLineItemTemplate({
          ...lineItemTemplate,
          maintenanceRequestTypeTemplateId: r.payload.maintenanceRequestTypeTemplateId,
        }));
      });
    } else {
      dispatch(createMaintenanceRequestLineItemTemplate({ ...lineItemTemplate }));
    }
  };

  const onUpdate = (lineItemTemplate) => {
    dispatch(updateMaintenanceRequestLineItemTemplate({ ...lineItemTemplate }));
  };

  const lineItemTemplateUpdateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateUpdate.status,
  );

  const lineItemTemplateCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateCreate.status,
  );

  const lineItemTemplateDeleteStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateRemove.status,
  );

  const requestTypeTemplateCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypeTemplateCreate.status,
  );

  useEffect(() => {
    if (lineItemTemplateUpdateStatus === 'succeeded') {
      props.handleModalClose();
    }
  }, [
    lineItemTemplateUpdateStatus,

  ]);

  const lineItemTemplateStatusIsLoading = [
    lineItemTemplateUpdateStatus,
    lineItemTemplateCreateStatus,
    lineItemTemplateDeleteStatus,
    requestTypeTemplateCreateStatus].includes('loading');

  const mtts = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypes,
  );

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'maintenanceRequestLineItemTypeId') {
        if (value.maintenanceRequestLineItemTypeId === 1) {
          /* Parts */
          setValue('laborBillOutCostPerHour', null);
        } else if (value.maintenanceRequestLineItemTypeId === 2) {
          /* Labor */
          setValue('partsBillOutCost', null);
          setValue('inventoryItemId', null);
        } else if (value.maintenanceRequestLineItemTypeId === 3) {
          /* Vacuum */
          setValue('partsBillOutCost', null);
          setValue('laborBillOutCostPerHour', null);
          setValue('inventoryItemId', null);
        }
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const templateType = watch('maintenanceRequestLineItemTypeId');

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          {props.title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lineItemTemplateStatusIsLoading ? <Spinner animation="border" variant="primary" className="create-spinner" /> : ''}
        <Form className="bg-white h-100">
          <Container fluid className={`p-0 m-0${lineItemTemplateStatusIsLoading ? ' creation-loading' : ''}`}>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Description</Form.Label>
                  <Form.Control size="sm" {...register('description')} />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Line Item Type</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('maintenanceRequestLineItemTypeId', { required: true })}
                  >
                    {maintenanceRequestLineItemTypes.map((type, i) => (
                      <option value={type.id} key={`line-item-type-${i}`}>{formatMaintenanceRequestLineItemType(type.name)}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            {templateType.toString() === '1' && (
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Part</Form.Label>
                  <Controller
                    control={control}
                    name="inventoryItemId"
                    // defaultValue={selectedPart}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        // value={value}
                        onChange={(e) => {
                          const id = Object.values(
                            parts,
                          ).find((part) => (part.partName === e[0]
                          ))?.partId;
                          onChange(id);
                          if (partsList.includes(e[0])) {
                            setSelectedPart(e[0]);
                          }
                        }}
                        size="sm"
                        placeholder={selectedPart}
                        id="mainteanance-request"
                        options={partsList}
                        isInvalid={!!error}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Parts Bill Out Cost</Form.Label>
                  <Form.Control size="sm" {...register('partsBillOutCost')} />
                </Form.Group>
              </Col>
            </Row>
            )}
            <Row>
              {/* <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Mechanic User Id</Form.Label>
                  <Form.Control size="sm" {...register('mechanicUserId')} />
                </Form.Group>
              </Col> */}
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Labor Bill Out Cost Per Hour</Form.Label>
                  <Form.Control size="sm" {...register('laborBillOutCostPerHour')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Quantity</Form.Label>
                  <Form.Control size="sm" {...register('quantity')} />
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={() => { dispatch(setCurrentMaintenanceRequestLineItemTemplateId(null)); props.handleModalClose(); }} variant="secondary">Close</Button>
        {props.createMode ? <Button type="submit" className="mt-3 position-sticky float-end bottom-0" onClick={handleSubmit(onSubmit)}>Submit</Button>
          : <Button type="update" className="mt-3 position-sticky float-end bottom-0" onClick={handleSubmit(onUpdate)}>Update</Button>}
      </Modal.Footer>
    </>
  );
}

export default LineItemTemplatesModal;
