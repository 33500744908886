export const permissionEntities = [
  'Asset',
];

export const permissionTypes = [
  'Admin',
  'ReadOnly',
];

export const permissionEntityEnum = {
  Asset: 'Asset',
};

export const permissionTypeEnum = {
  Admin: 'Admin',
  ReadOnly: 'ReadOnly',
};

export const hasPermissions = (userPermissions, requiredPermissions) => requiredPermissions.map(
  (permissionRequirement) => userPermissions.map(
    (p) => p.entity in permissionRequirement && permissionRequirement[p.entity].includes(p.type),
  ).some((v) => v),
).every((v) => v);

export const isProduction = () => {
  return window.location.host === 'he3.linde.com';
};
