import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setGasContainerCreateStatus } from '../../../store/slices/gas-containers-slice';
import CreateGasContainerForm from './create-gas-container-form';

function CreateGasContainer(props) {
  const gasContainerCreateStatus = useSelector(
    (state) => state.gasContainer.gasContainerCreate.status,
  );

  const gasContainerCreateError = useSelector(
    (state) => state.gasContainer.gasContainerCreate.status,
  );

  const currentGasContainerId = useSelector(
    (state) => state.gasContainer.currentGasContainer.gasContainerId,
  );

  const gasContainerCreateSuccessNotify = () => toast.success('Successfully created Gas Container');
  const gasContainerCreateFailedNotify = (error) => toast.error(`Failed to create Gas Container\n${error}`);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (gasContainerCreateStatus === 'succeeded') {
      gasContainerCreateSuccessNotify();
      dispatch(setGasContainerCreateStatus('idle'));
      navigate(`/assets/gas-container/${currentGasContainerId}`);
    }
    if (gasContainerCreateStatus === 'failed') {
      gasContainerCreateFailedNotify(gasContainerCreateError);
      dispatch(setGasContainerCreateStatus('idle'));
    }
  }, [gasContainerCreateStatus]);

  const gasContainerStatusIsLoading = gasContainerCreateStatus === 'loading';

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          Add New Gas Container
        </Modal.Title>
      </Modal.Header>
      <CreateGasContainerForm
        onHide={props.onHide}
        gasContainerStatusIsLoading={gasContainerStatusIsLoading}
      />
    </>

  );
}

export default CreateGasContainer;
