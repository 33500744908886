import React from 'react';
import { ListGroup, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { formatDate } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectCurrentPurchaseOrder } from '../../../store/slices/purchase-orders-slice';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';

function PurchaseOrderInfo({ purchaseOrderStatusIsLoading }) {
  const currentPurchaseOrder = useSelector(selectCurrentPurchaseOrder);
  const { datePreference } = useSelector(selectLoggedInUserPreferences);
  let assetTypeName = '';
  let assetLinkPrefix = '';

  if (currentPurchaseOrder) {
    if (currentPurchaseOrder?.assetTypeId === 1) {
      assetTypeName = 'Gas Container';
      assetLinkPrefix = '/assets/gas-container/';
    }
    if (currentPurchaseOrder?.assetTypeId === 2) {
      assetTypeName = 'Liquid Container';
      assetLinkPrefix = '/assets/liquid-container/';
    }
    if (currentPurchaseOrder?.assetTypeId === 4) {
      assetTypeName = 'Chassis';
      assetLinkPrefix = '/assets/chassis/';
    }
  }

  /* eslint-disable */
  const type = purchaseOrderStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : assetTypeName;
  const serialNumber = purchaseOrderStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : currentPurchaseOrder.assetSerialNumber;
  const orderType = purchaseOrderStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : currentPurchaseOrder.isStockTransferOrder ? "Stock Transfer Order" : "Purchase Order";
  const createdOn = purchaseOrderStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : formatDate(currentPurchaseOrder.created, datePreference);
  const modifiedOn = purchaseOrderStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : formatDate(currentPurchaseOrder.modified, datePreference);

  return (
    <Callout>
      <h4>
        {currentPurchaseOrder ? `Purchase Order #: ${currentPurchaseOrder.purchaseOrderNumber}`
          : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}
      </h4>
      <ListGroup variant="unbordered">
        {/* <ListGroup.Item><InfoListItem name="Asset Type" value={(type)} /></ListGroup.Item>
        <ListGroup.Item>
          <InfoListItem
            name="Asset Unit Number"
            value={(<NavLink to={`${assetLinkPrefix}${serialNumber}`}>{serialNumber}</NavLink>)}
          />
        </ListGroup.Item> */}
        <ListGroup.Item><InfoListItem name="Order Type" value={(orderType)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Added On" value={(createdOn)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Last Modified On" value={(modifiedOn)} /></ListGroup.Item>
      </ListGroup>
    </Callout>
  );
}

export default PurchaseOrderInfo;