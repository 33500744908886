import React from 'react';
import {
  Card, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';

import { ChassisChecklistForm, ContainerChecklistForm } from './work-order-c2c';

function WorkOrderDetailsTabs(props) {
  const {
    generalInfoContents, maintenanceRequestContents,
    outstandingRequestContents, auditLogContent, partsConsumedContent,
  } = props;

  return (
    <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
      <TabContainer defaultActiveKey="Requests on Work Order" activeKey={props.activeTab} onSelect={(tab) => props.setActiveTab(tab)}>
        <div className="card-header p-0 pt-1">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="General Info">General Info</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Requests on Work Order">Requests on Work Order</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Outstanding Requests">Outstanding Requests</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Audit Logs">Audit Logs</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Parts Consumed">Parts Consumed</Nav.Link>
            </Nav.Item>
            {props.hasC2CInspection && (
            <Nav.Item>
              <Nav.Link eventKey="Checklist">C2C Checklist</Nav.Link>
            </Nav.Item>
            )}
          </Nav>
        </div>
        <div className="card-body d-flex p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
          <TabContent className="d-flex flex-grow-1">
            <TabPane eventKey="General Info" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {generalInfoContents}
              </div>
            </TabPane>
            <TabPane eventKey="Requests on Work Order" className="flex-grow-1 w-100" mountOnEnter unmountOnExit>
              <div className="d-flex flex-grow-1 flex-column h-100">
                {maintenanceRequestContents}
              </div>
            </TabPane>
            <TabPane eventKey="Outstanding Requests" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {outstandingRequestContents}
              </div>
            </TabPane>
            <TabPane eventKey="Audit Logs" className="flex-grow-1 w-100" mountOnEnter unmountOnExit>
              <div className="d-flex flex-grow-1 flex-column h-100">
                {auditLogContent}
              </div>
            </TabPane>
            <TabPane eventKey="Parts Consumed" className="flex-grow-1 w-100" mountOnEnter unmountOnExit>
              <div className="d-flex flex-grow-1 flex-column h-100">
                {partsConsumedContent}
              </div>
            </TabPane>
            {props.hasC2CInspection && (
            <TabPane eventKey="Checklist" className="flex-grow-1 w-100 h-100" mountOnEnter={false} style={{ paddingTop: 6 }}>
              {props.assetTypeId === 4 ? <ChassisChecklistForm entityId={props.workOrderId} /> : <ContainerChecklistForm entityId={props.workOrderId} />}
            </TabPane>
            )}
          </TabContent>
        </div>
      </TabContainer>
    </Card>
  );
}

export default WorkOrderDetailsTabs;
