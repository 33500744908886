import { Chart } from "../../UI/view/FormItems/Chart";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createConnectedView } from "../../UI/view/view";
import { rtuChart } from "./rtu-chart";
import { rtuDataSource, rtuPermissions } from "./rtu-data-source";
import { RTUDetailsListPage, RTUReadingsListComponent } from "./rtu-detail-list";

export const rtuEdit = {
  pageHeader: 'RTU Details',
  editMode: true,
  permissions: rtuPermissions,
  breadcrumbs: [
    {
      name: 'Telemetry',
      icon: 'router',
      to: '/telemetry',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          type: FormItemType.infoPane, flexHeight: true, infoListItems: [
            {
              name: 'praxairNumber',
              label: 'Px Number',
            },
            {
              name: 'locationName',
              label: 'Name',
            },
            {
              name: 'locationInfo',
              label: 'Location Info',
            },
            {
              name: 'rtuSerialNumber',
              label: 'S/N',
            },
            {
              name: 'channelId',
              label: 'Channel',
            },
            {
              name: 'readingTimestamp',
              label: 'Last Reading Date',
            },
            {
              name: 'avgUsage',
              label: 'Avg psi/day',
            },
            {
              name: 'currentUsage',
              label: 'psi/day',
            },

            {
              name: 'fullPoint',
              label: 'Full',
            },
            {
              name: 'orderPoint',
              label: 'Order',
            },
            {
              name: 'reservePoint',
              label: 'Reserve',
            },
            {
              name: 'outagePoint',
              label: 'Outage',
            },
            {
              name: 'dayOfReservePointMinus3',
              label: 'Est. Order',
            },
            {
              name: 'dayOfReservePoint',
              label: 'Est. Delivery',
            },
            {
              name: 'dayOfOutagePoint',
              label: 'Outage Date',
            },

            {
              name: 'rtuId',
              label: 'RTU Id',
            },

          ], header: (state: any) => state.rtu?.current?.data?.rtuSerialNumber
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'General',
              items: [
                {
                  type: FormItemType.row,
                  items: [
                    {
                      type: FormItemType.fieldset,
                      label: 'Details',
                      items: [
                        {
                          type: FormItemType.textarea,
                          name: 'comment',
                          label: 'Comment'
                        },
                        {
                          name: 'fullPoint',
                          label: 'Full',
                          number: true,
                          permissions: { write: [1, 13] }
                        },
                        {
                          name: 'orderPoint',
                          label: 'Order',
                          number: true,
                          permissions: { write: [1, 13] }
                        },
                        {
                          name: 'reservePoint',
                          label: 'Reserve',
                          number: true,
                          permissions: { write: [1, 13] }
                        },
                        {
                          name: 'outagePoint',
                          label: 'Outage',
                          number: true,
                          permissions: { write: [1, 13] }
                        },
                        {
                          name: 'bypassSetpointAlerts',
                          label: 'Bypass Alerts',
                          type: FormItemType.checkboxField,
                          permissions: { write: [1, 13] }
                        },
                      ]
                    },
                    {
                      type: FormItemType.fieldset,
                      label: 'Readings',
                      items: [
                        {
                          type: FormItemType.customComponent,
                          component: Chart,
                          componentArgs: rtuChart
                        },
                      ]
                    },
                  ]
                }

              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                }
              ]
            },
            {
              label: 'Readings List',
              items: [
                {
                  type: FormItemType.customComponent,
                  component: RTUDetailsListPage
                }
              ]
            },
            {
              label: 'Audit Logs',
              unMountOnExit: true,
              items: [
                {
                  type: FormItemType.auditLogs,
                  name: 'RTU',
                }
              ]
            },
          ]
        }
      ]
    }
  ],

} as FormView;

export const RTUFormView = createConnectedView({ form: rtuEdit as any, dataSource: rtuDataSource as any });