import React from 'react';
import { ListGroup, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { formatDate } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectPackagedProductTypesObject } from '../../../store/slices/legacy-slice';
import { selectCurrentPackagedProduct } from '../../../store/slices/packaged-products-slice';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';

function PackagedProductInfo({ packagedProductStatusIsLoading }) {
  const packagedProductTypes = useSelector(selectPackagedProductTypesObject) ?? {};

  const currentPackagedProduct = useSelector(selectCurrentPackagedProduct);
  const { datePreference } = useSelector(selectLoggedInUserPreferences);
  /* eslint-disable */
  const type = packagedProductStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : packagedProductTypes ? (packagedProductTypes[currentPackagedProduct.packagedProductType]?.ProductNumber ?? '') : '';
  const id = packagedProductStatusIsLoading ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder> : currentPackagedProduct.id;
  const createdOn = packagedProductStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : formatDate(currentPackagedProduct.created, datePreference);
  const modifiedOn = packagedProductStatusIsLoading
    ? <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>
    : formatDate(currentPackagedProduct.modified, datePreference);

  return (
    <Callout className={`${packagedProductStatusIsLoading ? ' creation-loading' : ''}`}>
      <h4>
        {currentPackagedProduct ? currentPackagedProduct.serialNumber
          : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}
      </h4>
      <p>
        <i>
          {currentPackagedProduct ? currentPackagedProduct.comments : (
            <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}
        </i>
      </p>
      <ListGroup variant="unbordered">
        <ListGroup.Item><InfoListItem name="Type" value={(type)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="ID" value={(id)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Added On" value={(createdOn)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Last Modified On" value={(modifiedOn)} /></ListGroup.Item>
      </ListGroup>
    </Callout>
  );
}

export default PackagedProductInfo;
