import React, { useCallback, useMemo, useRef } from 'react';
import { useSelector } from 'react-redux';
import ReactTimeAgo from 'react-time-ago';

import { getDisplayNameByOID } from '../../../services/table-helpers';
import { selectEntityAuditLogs } from '../../../store/slices/audit-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import ChangesPopover from '../../UI/molecules/ChangesPopover/changes-popover';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import { actions, entities } from './log-table-values';

function EntityAuditLogsTable(props) {
  const auditLogs = useSelector(selectEntityAuditLogs);
  const usersByOid = useSelector(selectUsersByOid);

  const getDisplayName = useCallback((OID) => {
    return getDisplayNameByOID(OID, usersByOid);
  }, [usersByOid]);

  const auditLogsFetchStatus = useSelector(
    (state) => state.audit.entityLogsFetch.status,
  );

  const auditLogsColumns = useMemo(() => [
    {
      Header: 'Action',
      id: 'auditAction',
      accessor: (row) => (`${actions[row.auditAction]} ${entities[row.tableName] || row.tableName}`),
    },
    {
      Header: 'Date',
      accessor: 'auditDate',
      Cell: ({ value }) => <ReactTimeAgo date={Date.parse(`${value}Z`)} locale="en-US" />,
    },
    {
      Header: 'User',
      id: 'auditUser',
      accessor: (row) => (getDisplayName(row.auditUser)),
    },
    {
      Header: 'Changes',
      id: 'changes',
      Cell: ({ value, row }) => (
        <ChangesPopover row={row} />
      ),
    },
  ], []);

  const tableInstance = useRef(null);

  const auditLogsData = useMemo(() => {
    if (auditLogs) {
      const activity = Object.values(auditLogs).sort(
        (a, b) => Date.parse(b.auditDate) - Date.parse(a.auditDate),
      ).filter((row) => JSON.parse(row?.changedColumns)?.length);
      return activity;
    }
    return [];
  }, [auditLogs, auditLogsFetchStatus]);

  const auditLogsContent = auditLogsData.length > 0 ? (
    <PaginatedSelectTable
      columns={auditLogsColumns}
      data={auditLogsData}
      ref={tableInstance}
      rowProps={() => ({})}
    />
  ) : <p className="m-3">There are no audit logs to display.</p>;

  return (
    <>
      <div className={`d-flex flexbox flex-column overflow-auto flex-grow-1 h-100${auditLogsFetchStatus === 'loading' ? ' creation-loading' : ''}`}>
        {auditLogsFetchStatus === 'loading' ? <p className="m-3">Loading...</p> : auditLogsContent}
      </div>
      {auditLogsFetchStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default EntityAuditLogsTable;
