import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  addRolePermission,
  addUserLocation,
  addUserRole,
  createRoleRequest,
  deleteRoleRequest,
  getRole,
  getRoles,
  getUserLocations,
  removeRolePermission,
  RemoveUserLocation,
  removeUserRole,
  updateRoleRequest,
} from '../../services/requests';
import { selectAllLocationObjects } from './legacy-slice';
import { selectPermissions } from './permissions-slice';

const initialState = {
  roles: {},
  userLocations: {},
  currentRole: {
    roleId: null,
    status: 'idle',
    error: null,
  },
  currentUserLocation: {
    locationId: null,
    status: 'idle',
    error: null,
  },
  userLocationsFetch: {
    status: 'idle',
    error: null,
  },
  rolesFetch: {
    status: 'idle',
    error: null,
  },
  roleUpdate: {
    status: 'idle',
    error: null,
  },
  roleCreate: {
    status: 'idle',
    error: null,
  },
};

export const fetchRoles = createAsyncThunk('role/fetchRoles', async () => {
  const response = await getRoles();
  return response;
});

export const createRole = createAsyncThunk('role/createRole', async (role) => {
  const response = await createRoleRequest(role);
  return response;
});

export const getCurrentRole = createAsyncThunk('role/getCurrentRole', async (roleId) => {
  const response = await getRole(roleId);
  return response;
});

export const updateRole = createAsyncThunk('role/updateRole', async ({ userId, user }) => {
  const response = await updateRoleRequest(userId, user);
  return response;
});

export const deleteRole = createAsyncThunk('role/deleteRole', async (userId) => {
  const response = await deleteRoleRequest(userId);
  return response;
});

export const updateCurrentRoleUser = createAsyncThunk('role/updateCurrentRoleUser', async ({ userId, action }, { getState }) => {
  const state = getState();
  let response;
  if (action === 'add') {
    response = await addUserRole(userId, state.role.currentRole.roleId);
  } else {
    response = await removeUserRole(userId, state.role.currentRole.roleId);
  }
  return response;
});

export const updateCurrentRolePermission = createAsyncThunk('role/updateCurrentRolePermission', async ({ permissionId, action }, { getState }) => {
  const state = getState();
  let response;
  if (action === 'add') {
    response = await addRolePermission(state.role.currentRole.roleId, permissionId);
  } else {
    response = await removeRolePermission(state.role.currentRole.roleId, permissionId);
  }
  return response;
});

export const fetchUserLocations = createAsyncThunk('role/fetchUserLocations', async () => {
  const response = await getUserLocations();
  return response;
});

export const updateCurrentUserLocation = createAsyncThunk('role/updateCurrentUserLocation', async ({ userId, action }, { getState }) => {
  const state = getState();
  let response;
  if (action === 'add') {
    response = await addUserLocation(state.role.currentUserLocation.locationId, userId);
  } else {
    response = await RemoveUserLocation(state.role.currentUserLocation.locationId, userId);
  }
  return response;
});

export const rolesSlice = createSlice({
  name: 'role',
  initialState,
  reducers: {
    setRoles: (state, action) => {
      state.roles = action.payload.reduce((current, role) => ({
        ...current,
        [role.roleId]: role,
      }), {});
    },
    setCurrentRoleId: (state, action) => {
      state.currentRole.roleId = action.payload;
    },
    setCurrentUserLocationId: (state, action) => {
      state.currentUserLocation.locationId = action.payload;
    },
    setCurrentUserLocationStatus: (state, action) => {
      state.currentUserLocation.status = action.payload;
    },
    setCurrentRoleStatus: (state, action) => {
      state.currentRole.status = action.payload;
    },
    setCreateRoleStatus: (state, action) => {
      state.roleCreate.status = action.payload;
    },
    setUpdateRoleStatus: (state, action) => {
      state.roleUpdate.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchRoles.pending, (state, action) => {
        state.rolesFetch.status = 'loading';
      })
      .addCase(fetchRoles.fulfilled, (state, action) => {
        state.rolesFetch.status = 'succeeded';
        state.roles = action.payload.reduce((current, role) => ({
          ...current,
          [role.roleId]: role,
        }), {});
        state.rolesFetch.error = null;
      })
      .addCase(fetchRoles.rejected, (state, action) => {
        state.rolesFetch.status = 'failed';
        state.rolesFetch.error = action.error.message;
      });
    builder
      .addCase(createRole.pending, (state, action) => {
        state.roleCreate.status = 'loading';
      })
      .addCase(createRole.fulfilled, (state, action) => {
        state.roleCreate.status = 'succeeded';
        state.roles[action.payload.roleId] = action.payload;
        state.roleCreate.error = null;
      })
      .addCase(createRole.rejected, (state, action) => {
        state.roleCreate.status = 'failed';
        state.roleCreate.error = action.error.message;
      });

    builder
      .addCase(getCurrentRole.pending, (state, action) => {
        state.currentRole.status = 'loading';
      })
      .addCase(getCurrentRole.fulfilled, (state, action) => {
        state.currentRole.status = 'succeeded';
        state.roles.roles[action.payload.roleId] = action.payload;
        state.currentRole.error = null;
      })
      .addCase(getCurrentRole.rejected, (state, action) => {
        state.currentRole.status = 'failed';
        state.currentRole.error = action.error.message;
      });

    builder
      .addCase(updateCurrentRolePermission.pending, (state, action) => {
        state.currentRole.status = 'loading';
      })
      .addCase(updateCurrentRolePermission.fulfilled, (state, action) => {
        state.currentRole.status = 'succeeded';
        state.roles[action.payload.roleId] = action.payload;
        state.currentRole.error = null;
      })
      .addCase(updateCurrentRolePermission.rejected, (state, action) => {
        state.currentRole.status = 'failed';
        state.currentRole.error = action.error.message;
      });

    builder
      .addCase(updateRole.pending, (state, action) => {
        state.roleUpdate.status = 'loading';
      })
      .addCase(updateRole.fulfilled, (state, action) => {
        state.roleUpdate.status = 'succeeded';
        state.roles[action.payload.roleId] = action.payload;
        state.roleUpdate.error = null;
      })
      .addCase(updateRole.rejected, (state, action) => {
        state.roleUpdate.status = 'failed';
        state.roleUpdate.error = action.error.message;
      });

    builder
      .addCase(deleteRole.pending, (state, action) => {
        state.rolesFetch.status = 'loading';
      })
      .addCase(deleteRole.fulfilled, (state, action) => {
        state.rolesFetch.status = 'succeeded';
        delete state.roles[action.payload];
        state.rolesFetch.error = null;
      })
      .addCase(deleteRole.rejected, (state, action) => {
        state.rolesFetch.status = 'failed';
        state.rolesFetch.error = action.error.message;
      });

    builder
      .addCase(updateCurrentRoleUser.pending, (state, action) => {
        state.currentRole.status = 'loading';
      })
      .addCase(updateCurrentRoleUser.fulfilled, (state, action) => {
        state.currentRole.status = 'succeeded';
        state.roles[action.payload.roleId] = action.payload;
        state.currentRole.error = null;
      })
      .addCase(updateCurrentRoleUser.rejected, (state, action) => {
        state.currentRole.status = 'failed';
        state.currentRole.error = action.error.message;
      });

    builder
      .addCase(fetchUserLocations.pending, (state, action) => {
        state.userLocationsFetch.status = 'loading';
      })
      .addCase(fetchUserLocations.fulfilled, (state, action) => {
        state.userLocationsFetch.status = 'succeeded';
        state.userLocations = action.payload.reduce((current, userLocation) => ({
          ...current,
          [userLocation.locationId]: userLocation.userIds,
        }), {});
        state.userLocationsFetch.error = null;
      })
      .addCase(fetchUserLocations.rejected, (state, action) => {
        state.userLocationsFetch.status = 'failed';
        state.userLocationsFetch.error = action.error.message;
      });

    builder
      .addCase(updateCurrentUserLocation.pending, (state, action) => {
        state.currentUserLocation.status = 'loading';
      })
      .addCase(updateCurrentUserLocation.fulfilled, (state, action) => {
        state.currentUserLocation.status = 'succeeded';
        state.userLocations[state.currentUserLocation.locationId] = action.payload;
        state.currentUserLocation.error = null;
      })
      .addCase(updateCurrentUserLocation.rejected, (state, action) => {
        state.currentUserLocation.status = 'failed';
        state.currentUserLocation.error = action.error.message;
      });
  },
});

export const {
  setRoles, setCurrentRoleId, setCreateRoleStatus,
  setCurrentRoleStatus,
  setCurrentUserLocationId,
  setCurrentUserLocationStatus,
  setUpdateRoleStatus,
} = rolesSlice.actions;

export const selectRoles = (state) => state.role.roles;
export const selectRolesStatus = (state) => state.role.rolesFetch.status;

export const selectCreateRoleStatus = (state) => state.role.roleCreate.status;

export const selectUserLocations = (state) => state.role.userLocations;

export const selectCurrentUserLocationStatus = (state) => state.role.currentUserLocation.status;
export const selectCurrentUserLocationId = (state) => state.role.currentUserLocation.locationId;

export const selectCurrentUserLocation = createSelector(
  [selectCurrentUserLocationId, selectAllLocationObjects, selectUserLocations],
  (currentUserLocationId, locations, userLocations) => {
    if (currentUserLocationId in locations) {
      return {
        ...locations[currentUserLocationId],
        userIds: userLocations[currentUserLocationId],
      };
    }
    return {};
  },
);

export const selectAllLocationObjectsWithUsers = createSelector(
  [selectAllLocationObjects, selectUserLocations],
  (locations, userLocations) => {
    return Object.keys(locations).reduce((current, locationId) => {
      if (locationId in userLocations) {
        return {
          ...current,
          [locationId]: {
            ...locations[locationId],
            userIds: userLocations[locationId],
          },
        };
      }
      return {
        ...current,
        [locationId]: locations[locationId],
      };
    }, {});
  },
);

export const selectCurrentRoleId = (state) => state.role.currentRole.roleId;

export const selectCurrentRoleData = createSelector(
  [selectRoles, selectCurrentRoleId],
  (roles, currentRoleId) => {
    if (currentRoleId == null) {
      return {};
    }
    return roles[currentRoleId];
  },
);

export const selectRolePermissionsNotInRole = createSelector(
  [selectCurrentRoleData, selectPermissions],
  (role, permissions) => {
    const currentPermissionIds = (role?.permissions ?? []).map((p) => p.permissionId);
    return [...permissions.filter((p) => !currentPermissionIds.includes(p.permissionId))];
  },
);

export default rolesSlice.reducer;
