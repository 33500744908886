import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { setEntityLogsFetchStatus } from '../../../store/slices/audit-slice';
import {
  fetchOneChassis, selectCurrentChassis, setChassisUpdateStatus,
  setCreateAttachmentStatus, setCurrentChassisId, setRemoveAttachmentStatus,
} from '../../../store/slices/chassis-slice';
import { fetchAssetMaintenanceRequests, setFetchMaintenanceRequestStatus } from '../../../store/slices/maintenance-requests-slice';
import { setFetchWorkOrderStatus } from '../../../store/slices/work-orders-slice';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import AssetDetailsTabs from '../../UI/organisms/AssetDetailsTabs/asset-details-tabs';
import ChassisHistory from '../../UI/organisms/AssetHistory/chassis-history';
import AssetFileAttachment from '../../UI/organisms/FileAttachment/asset-file-attachment';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import AssetMaintenanceRequests from '../MaintenanceDashboard/asset-maintenance-requests';
import AssetWorkOrders from '../WorkOrders/asset-work-orders';
import ChassisForm from './chassis-form';
import ChassisInfo from './chassis-info';
import { SensorInfo } from './chassis-sensor-info-tab';

function ChassisDetails(props) {
  const { chassisId } = useParams();

  const chassisFetchSingleStatus = useSelector(
    (state) => state.chassis.chassisFetchSingle.status,
  );

  const chassisUpdateStatus = useSelector((state) => state.chassis.chassisUpdate.status);

  const chassisAttachmentCreateStatus = useSelector(
    (state) => state.chassis.chassisAttachmentCreate.status,
  );

  const chassisAttachmentRemoveStatus = useSelector(
    (state) => state.chassis.chassisAttachmentRemove.status,
  );

  const chassisUpdateNotify = () => toast.success('Successfully Updated Chassis');
  const chassisAttachmentCreateNotify = () => toast.success('Successfully Uploaded attachment');
  const chassisAttachmentRemoveNotify = () => toast.success('Successfully Deleted attachment');

  const currentChassis = useSelector(selectCurrentChassis);

  const breadcrumbs = useMemo(() => [
    {
      name: 'Assets',
      icon: 'boxes',
      to: '/assets',

    },
    {
      name: chassisId,
      icon: 'box',
      to: '',
    },
  ], [chassisId]);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (chassisFetchSingleStatus === 'idle' || chassisFetchSingleStatus === 'failed') {
      dispatch(fetchOneChassis(chassisId));
    }
    dispatch(setCurrentChassisId(chassisId));
  }, [chassisFetchSingleStatus, chassisId, dispatch]);

  const loadMaintenanceRequestData = useCallback(async () => {
    await dispatch(fetchAssetMaintenanceRequests({
      assetType: 4,
      assetSerialNumber: chassisId,
    })).unwrap();
    dispatch(setFetchMaintenanceRequestStatus('idle'));
  }, [chassisId]);

  useEffect(() => {
    if (chassisUpdateStatus === 'succeeded') {
      chassisUpdateNotify();
      dispatch(setChassisUpdateStatus('idle'));
      loadMaintenanceRequestData();
    }
    if (chassisAttachmentCreateStatus === 'succeeded') {
      chassisAttachmentCreateNotify();
      dispatch(setCreateAttachmentStatus('idle'));
      dispatch(setEntityLogsFetchStatus('idle'));
    }
    if (chassisAttachmentRemoveStatus === 'succeeded') {
      chassisAttachmentRemoveNotify();
      dispatch(setRemoveAttachmentStatus('idle'));
    }
  }, [chassisUpdateStatus, chassisAttachmentCreateStatus, chassisAttachmentRemoveStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, [currentChassis]);

  useEffect(() => {
    dispatch(setFetchMaintenanceRequestStatus('idle'));
    dispatch(setFetchWorkOrderStatus('idle'));
    dispatch(fetchOneChassis(chassisId));
  }, []);

  const list = useMemo(
    () => currentChassis?.attachmentList ?? [],
    [currentChassis],
  );

  const chassisStatusIsLoading = [
    chassisFetchSingleStatus,
    chassisUpdateStatus,
  ].includes('loading');

  return (
    <Container
      fluid
      className="h-100 d-flex flex-column"
    >
      <PageHeader
        header="Chassis"
        breadcrumbs={breadcrumbs}
      />
      <Row className="h-100 flex-grow-1 overflow-auto">
        <Col lg={3} className="h-100 fluid flex-column overflow-auto">
          <ChassisInfo
            chassisStatusIsLoading={chassisStatusIsLoading}
            currentChassis={currentChassis}
          />
          <AssetFileAttachment moduleId={chassisId} moduleName="Chassis" attachmentList={list} />
        </Col>
        <Col className="h-100 d-flex fluid">
          <AssetDetailsTabs
            generalInfoContents={(
              <ChassisForm
                currentChassis={currentChassis}
                chassisStatusIsLoading={chassisStatusIsLoading}
              />
            )}
            workOrderContent={(
              <AssetWorkOrders
                assetType="Chassis"
                assetTypeId={4}
                assetSerialNumber={chassisId}
              />
            )}
            maintenanceRequestContent={(
              <AssetMaintenanceRequests
                assetTypeId={4}
                assetSerialNumber={chassisId}
                asset={currentChassis}
              />
            )}
            auditLogContent={(
              <EntityAuditLogs
                entityId={4}
                tablePK={chassisId}
              />
            )}
            historyContent={(
              <ChassisHistory
                assetType="Chassis"
                serialNumber={chassisId}
              />
            )}
            sensorContent={(
              <SensorInfo />
            )}
          />

        </Col>
      </Row>
    </Container>
  );
}

export default ChassisDetails;
