import './table.scss';

import { range } from 'lodash';
import React, { forwardRef, useEffect, useImperativeHandle } from 'react';
import {
  Button, ButtonGroup, Form, Table as BootstrapTable,
} from 'react-bootstrap';
import { BsArrowDownUp, BsSortDown, BsSortUp } from 'react-icons/bs';
import {
  useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable,
} from 'react-table';

// eslint-disable-next-line object-curly-newline
const EditablePaginatedSelectTable = forwardRef(({
  columns, data, rowProps, initialState, setTableFilterUpdate, updateData,
}, ref) => {
  const startState = { pageIndex: 0, pageSize: 25, ...initialState };

  function EditableCell({
    value: initialValue,
    row: { index, original },
    column: { id, type, max },
  }) {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(initialValue);

    let onChange = (e) => {
      setValue(e.target.value);
    };

    if (id === 'partName' || id === 'partId') {
      return (initialValue);
    }

    // We'll only update the external data when the input is blurred
    const onBlur = () => {
      updateData(index, id, value);
    };

    // If the initialValue is changed external, sync it up with our state
    React.useEffect(() => {
      setValue(initialValue);
    }, [initialValue]);

    if (id === 'priority') {
      return (
        <select value={value} name="priority-dropdown" id="priorities" onChange={onChange} onBlur={onBlur}>
          <option value="0"> Red </option>
          <option value="1"> Yellow </option>
          <option value="2"> Green </option>
        </select>
      );
    }

    if (original?.max && max) {
      onChange = (e) => {
        if (e.target.value <= original.max) {
          setValue(e.target.value);
        }
      };
    }

    let inputType = 'number';

    if (type) {
      inputType = type;
    }

    return (
      <input
        type={inputType}
        value={value}
        size={12}
        onChange={onChange}
        onBlur={onBlur}
      />
    );
  }

  // Set our editable cell renderer as the default Cell renderer
  const defaultColumn = {
    Cell: EditableCell,
  };
  const instance = useTable(
    {
      columns, data, defaultColumn, initialState: startState, autoResetGlobalFilter: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = instance;

  useImperativeHandle(ref, () => instance);

  const getPageRange = (currentPage, numPages) => {
    if (numPages < 5) {
      return range(1, numPages + 1);
    }
    if (currentPage <= 3) {
      return range(1, 6);
    }
    if (currentPage + 3 > numPages) {
      return range(numPages - 4, numPages + 1);
    }
    return range(currentPage - 2, currentPage + 3);
  };

  useEffect(() => {
    if (setTableFilterUpdate) {
      setTableFilterUpdate(rows.map((row) => row.original));
    }
  }, [setTableFilterUpdate, rows]);

  return (
    // apply the table props
    <div className="d-flex flex-column overflow-auto flex-grow-1 justify-content-between">
      <div className="flex-grow-1 overflow-auto">
        <BootstrapTable size="sm" striped hover {...getTableProps()} className="custom-table">
          <thead>
            {// Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                      ...column.getSortByToggleProps(),
                    })}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          {// Render the header
                            column.render('Header')
                          }
                        </div>
                        <div>
                          {column.canSort && (
                            <span>
                              {
                                /* eslint-disable */
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? <BsSortDown />
                                    : <BsSortUp />
                                  : <BsArrowDownUp color="gray" />}
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            page.map((row) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr {...row.getRowProps(rowProps(row))} >
                  {// Loop over the rows cells
                    row.cells.map((cell) => (
                      <td {...cell.getCellProps()}>
                        {// Render the cell contents
                          cell.render('Cell') 
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
        </tbody>
      </BootstrapTable>
      </div>
      {data.length > 25 &&
        <div className="w-100 bg-white bottom-0 pb-2 pt-2 pr-4 pl-4">
          <div className="pagination d-flex align-items-center justify-content-between">

            <Form.Group className="pr-2">
              <Form.Select
                size="sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[25, 50, 75, 100, data.length].map((size, i) => (
                  <option key={size} value={size}>
                    Show
                    {' '}
                    {i === 4 ? 'ALL' : size}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <ButtonGroup size="sm">
              <Button
                variant="outline-primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {'<'}
              </Button>
              {getPageRange(pageIndex + 1, pageOptions.length).map((pg) => (
                <Button
                  key={`button-pg-${pg}`}
                  variant="outline-primary"
                  onClick={() => gotoPage(pg - 1)}
                  active={pageIndex + 1 === pg}
                >
                  {pg}
                </Button>
              ))}
              <Button
                variant="outline-primary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'>'}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </Button>
            </ButtonGroup>
            <div>
              Page
              {' '}
              <strong>
                {pageIndex + 1}
                {' '}
                of
                {' '}
                {pageOptions.length}
              </strong>
              {' '}
            </div>
          </div>
        </div>
      }
    </div>
  );
});

export default EditablePaginatedSelectTable;