import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import { Button, InputGroup } from 'react-bootstrap';
import { BsPencilFill, BsXLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency, formatMaintenanceRequestLineItemType } from '../../../services/format';
import { selectLoggedInUserActiveLocation } from '../../../store/slices/auth-slice';
import {
  fetchCurrentMaintenanceRequestTypeTemplate,
  removeMaintenanceRequestLineItemTemplate,
  selectCurrentMaintenanceRequestTypeForTemplateEditData,
  selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  selectCurrentMaintenanceRequestTypeTemplateForActiveLocation,
  setCurrentMaintenanceRequestLineItemTemplateId,
  setFetchMaintenanceRequestTypeTemplateStatus,
} from '../../../store/slices/maintenance-requests-slice';
import { selectActiveLocationParts } from '../../../store/slices/parts-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import CustomModal from '../../UI/molecules/Modal/modal';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import LineItemTemplatesModal from './line-item-templates-modal';

function LineItemTemplatesTable(props) {
  const dispatch = useDispatch();
  const maintenanceRequestStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypeTemplateFetch.status,
  );

  const tableInstance = useRef(null);

  const [modalShow, setModalShow] = useState(false);
  const handleModalShow = () => setModalShow(true);
  const handleModalClose = () => {
    setModalShow(false);
    dispatch(setCurrentMaintenanceRequestLineItemTemplateId(null));
  };

  // const maintenanceRequestTypeTemplateData = useSelector(
  //   selectCurrentMaintenanceRequestTypeTemplateData,
  // );

  const maintenanceRequestTypeId = useSelector(
    selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  );

  const maintenanceRequestType = useSelector(
    selectCurrentMaintenanceRequestTypeForTemplateEditData,
  );

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const currentMaintenanceRequestTypeTemplate = useSelector(
    selectCurrentMaintenanceRequestTypeTemplateForActiveLocation,
  );

  const currentMaintenanceRequestTypeTemplateId = currentMaintenanceRequestTypeTemplate
    ? currentMaintenanceRequestTypeTemplate.maintenanceRequestTypeTemplateId : null;

  const lineItemTemplateUpdateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateUpdate.status,
  );

  const lineItemTemplateCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateCreate.status,
  );

  const lineItemTemplateDeleteStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestLineItemTemplateRemove.status,
  );

  const lineItemTemplatesData = useMemo(() => {
    if (currentMaintenanceRequestTypeTemplate !== null
      && currentMaintenanceRequestTypeTemplate.maintenanceRequestLineItemTemplates !== null) {
      return Object.values(
        currentMaintenanceRequestTypeTemplate.maintenanceRequestLineItemTemplates,
      );
    }
    return [];
  }, [
    currentMaintenanceRequestTypeTemplate,
  ]);

  useEffect(() => {
    setFetchMaintenanceRequestTypeTemplateStatus('idle');
  }, [currentMaintenanceRequestTypeTemplateId]);

  useEffect(() => {
    if (
      (maintenanceRequestStatus === 'idle' || maintenanceRequestStatus === 'failed')
      && currentMaintenanceRequestTypeTemplateId !== null
    ) {
      dispatch(fetchCurrentMaintenanceRequestTypeTemplate(currentMaintenanceRequestTypeTemplateId));
    }
  }, [maintenanceRequestStatus, currentMaintenanceRequestTypeTemplateId, dispatch]);

  // useEffect(() => {
  //   loadData();
  // }, []);

  const parts = useSelector(selectActiveLocationParts);

  const { confirm } = useConfirm();

  const lineItemTemplatesColumns = useMemo(() => [
    {
      Header: '',
      id: 'actionColumn',
      Cell: ({ cell }) => (
        <div className="d-flex">
          <Button
            size="xsm"
            variant="danger"
            title="Delete"
            className="mr-1"
            onClick={async () => {
              const isConfirmed = await confirm('Are you sure you want to delete this line item template?');
              if (isConfirmed) {
                dispatch(
                  removeMaintenanceRequestLineItemTemplate(
                    cell.row.original.maintenanceRequestLineItemTemplateId,
                  ),
                );
              }
            }}
          >
            <BsXLg />
          </Button>
          <Button
            size="xsm"
            title="Edit"
            onClick={() => {
              dispatch(setCurrentMaintenanceRequestLineItemTemplateId(
                cell.row.original.maintenanceRequestLineItemTemplateId,
              ));
              handleModalShow();
            }}
          >
            <BsPencilFill />
          </Button>
        </div>
      ),
    },
    {
      Header: 'Info',
      accessor: 'description',
    },
    {
      Header: 'Type',
      accessor: 'maintenanceRequestLineItemTypeId',
      Cell: ({ value }) => formatMaintenanceRequestLineItemType(value),
    },
    {
      Header: 'Part',
      accessor: 'inventoryItemId',
      Cell: ({ value }) => (value in parts ? parts[value].partName : `Part ID: ${value}`),
    },
    {
      Header: 'Labor Cost/Hr',
      id: 'laborBillOutCostPerHour',
      accessor: ({ laborBillOutCostPerHour }) => formatCurrency(laborBillOutCostPerHour),
    },
    {
      Header: 'Parts Cost',
      id: 'partsBillOutCost',
      accessor: ({ partsBillOutCost }) => formatCurrency(partsBillOutCost),
    },
    {
      Header: 'Quantity',
      accessor: 'quantity',
    },
  ], [lineItemTemplatesData, parts]);

  return (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <TableSearchFilter tableInstance={tableInstance} />
           &nbsp;
          <Button
            variant="primary"
            size="sm"
            onClick={props.handleModalShow}
          >
            Add Line Item Template

          </Button>
        </InputGroup>

      </div>
      <PaginatedSelectTable
        columns={lineItemTemplatesColumns}
        data={lineItemTemplatesData}
        ref={tableInstance}
        rowProps={() => ({})}
      />
      <CustomModal
        contentClassName="h-75"
        show={modalShow}
        onHide={handleModalClose}
      >
        <LineItemTemplatesModal
          handleModalClose={handleModalClose}
          title="Maintenance Request Line Item Template Details"
          lineItemTemplatesData={lineItemTemplatesData}
        />
      </CustomModal>
    </>
  );
}

export default LineItemTemplatesTable;
