export const measuementSystemPreference = ['USA', 'EMEA', 'APAC'];

export const formatNumber = (ms, number) => {
  switch (ms) {
    case 1:
      return number.toLocaleString('de-DE');
    case 2:
      return number.toLocaleString('fr');
    case 0:
    default:
      return number.toLocaleString('en-US');
  }
};

export const getDateFormat = (ms, number) => {
  switch (ms) {
    case 1:
      return 'dd/MM/yyyy';
    case 2:
      return 'yyyy/MM/dd';
    case 0:
    default:
      return 'MM/dd/yyyy';
  }
};

export const dateFormatForApi = (dateValue, currentFormat) => {
  let dd; let mm; let
    yyyy;
  if (currentFormat === 1) {
    [dd, mm, yyyy] = dateValue.split('/');
  } else if (currentFormat === 2) {
    [yyyy, mm, dd] = dateValue.split('/');
  } else {
    [mm, dd, yyyy] = dateValue.split('/');
  }
  return `${mm}/${dd}/${yyyy}`;
};

export const unitPreferences = {
  'Cubic Feet': {
    USA: 'Cubic Feet',
    EMEA: 'Cubic Meters',
  },
  lbs: {
    USA: 'lbs',
    EMEA: 'kgs',
  },
  psig: {
    USA: 'psig',
    EMEA: 'Pa',
  },
  Fahrenheit: {
    USA: 'Fahrenheit',
    EMEA: 'Celsius',
  },
  Miles: {
    USA: 'Miles',
    EMEA: 'Kilometers',
  },
};

export const roundNumber = (num, scale) => {
  if (!(`${num}`).includes('e')) {
    return +(`${Math.round(`${num}e+${scale}`)}e-${scale}`);
  }
  const arr = (`${num}`).split('e');
  let sig = '';
  if (+arr[1] + scale > 0) {
    sig = '+';
  }
  return +(`${Math.round(`${+arr[0]}e${sig}${+arr[1] + scale}`)}e-${scale}`);
};

export const unitConversions = {
  'Cubic Feet': {
    'Cubic Meters': {
      convertToBase: (value) => value * 35.315,
      convertFromBase: (value) => value / 35.315,
    },
    'Cubic Feet': {
      convertToBase: (value) => value,
      convertFromBase: (value) => value,
    },
    Liters: {
      convertToBase: (value) => value / 28.317,
      convertFromBase: (value) => value * 28.317,
    },
  },
  lbs: {
    lbs: {
      convertToBase: (value) => value,
      convertFromBase: (value) => value,
    },
    kgs: {
      convertToBase: (value) => value / 0.45359237,
      convertFromBase: (value) => value * 0.45359237,
    },
  },
  psig: {
    Pa: {
      convertToBase: (value) => value / 6894.76,
      convertFromBase: (value) => value * 6894.76,
    },
    psig: {
      convertToBase: (value) => value,
      convertFromBase: (value) => value,
    },
    // barg: {
    //   convertToBase: (value) => value * 100000,
    //   convertFromBase: (value) => value / 100000,
    // },
    // kPag: {
    //   convertToBase: (value) => value * 1000,
    //   convertFromBase: (value) => value / 1000,
    // },
    // 'in H2O': {
    //   convertToBase: (value) => value * 248.84,
    //   convertFromBase: (value) => value / 248.84,
    // },
    // 'cm H2O': {
    //   convertToBase: (value) => value * 98.0665,
    //   convertFromBase: (value) => value / 98.0665,
    // },
    // 'kg/cm2g': {
    //   convertToBase: (value) => value * 98066.5,
    //   convertFromBase: (value) => value / 98066.5,
    // },
  },
  Fahrenheit: {
    Fahrenheit: {
      convertToBase: (value) => value,
      convertFromBase: (value) => value,
    },
    Celsius: {
      convertToBase: (value) => (value * (9 / 5)) + 32,
      convertFromBase: (value) => (value - 32) * (5 / 9),
    },
    Kelvin: {
      convertToBase: (value) => (value - 273.15) * (9 / 5) + 32,
      convertFromBase: (value) => (value - 32) * (5 / 9) + 273.15,
    },
  },
  Miles: {
    Miles: {
      convertToBase: (value) => value,
      convertFromBase: (value) => value,
    },
    Kilometers: {
      convertToBase: (value) => value * 0.6214,
      convertFromBase: (value) => value / 0.6214,
    },
  },
};

export const convertToPreferred = (baseUnit, systemPreference, value) => {
  const pref = measuementSystemPreference[systemPreference];
  const unit = unitConversions[baseUnit][unitPreferences[baseUnit][pref]];
  const prefValue = unit.convertFromBase(value);
  return Number(Number.parseFloat(prefValue).toFixed(4));
};

export const getPreferredUnit = (baseUnit, systemPreference) => {
  const pref = measuementSystemPreference[systemPreference];
  return unitPreferences[baseUnit][pref];
};
