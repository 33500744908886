import { createUserRequest, getUsers, GetVendorList, updateUserRequestObj, UpdateVendor } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";
import { CreateVendor } from "../../../services/requests";

export const userDataSource = {
  name: 'users',
  pk: 'userId',
  request: getUsers,
  createRequest: createUserRequest,
  updateRequest: updateUserRequestObj
} as DataSource