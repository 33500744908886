import React, { useMemo } from 'react';
import { BsStack } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatCurrency, formatDate } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectCurrentWorkOrderPartsConsumed } from '../../../store/slices/maintenance-requests-slice';
import Callout from '../../UI/molecules/Callout/callout';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

function PartsConsumed() {
  const partsConsumed = useSelector(selectCurrentWorkOrderPartsConsumed);

  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);
  const partsConsumedColumns = [
    {
      Header: 'Part ID',
      accessor: 'partId',
      Cell: ({ value }) => (
        <NavLink to={`/maintenance/parts-inventory/${value}`}>
          {value}
        </NavLink>
      ),
    },
    {
      Header: 'Bill of Entry',
      accessor: 'billOfEntry',
    },
    {
      Header: 'Part Cost',
      id: 'unitCost',
      accessor: ({ unitCost }) => formatCurrency(unitCost),
    },
    {
      Header: 'Consumed Date',
      id: 'consumedDate',
      accessor: ({ consumedDate }) => formatDate(consumedDate, datePreference),
    },
  ];

  const partsConsumedTableInstance = React.useRef(null);

  const totalPartCost = partsConsumed.reduce((acc, part) => {
    // bug fix for 500 error "cannot read unitCost of null"
    if (part === null) {
      return acc;
    }
    return acc + part.unitCost;
  }, 0);

  const calloutContents = (
    <span className="info-box-text text-sm dg-draggable">
      <b> Total Cost: </b>
      {' '}
      {formatCurrency(totalPartCost)}
    </span>
  );

  const partsConsumedData = useMemo(() => {
    if (partsConsumed) {
      return partsConsumed;
    }
    return [];
  }, [partsConsumed]);
  return (
    <>
      <div className="card-tools">
        <Callout icon={<BsStack />} content={calloutContents} />
      </div>
      <PaginatedSelectTable
        columns={partsConsumedColumns}
        data={partsConsumedData}
        ref={partsConsumedTableInstance}
        rowProps={(() => {})}
      />
    </>
  );
}

export default PartsConsumed;
