/* eslint-disable max-len */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  assignMaintenanceRequestToWorkOrder,
  deleteMaintenanceRequestLineItemTemplate,
  deleteMaintenanceRequestType,
  deleteMaintenanceRequestTypeTemplate,
  getMaintenaceDropdownValues,
  getMaintenanceRequests,
  getMaintenanceRequestsForAsset,
  getMaintenanceRequestsForAssetList,
  getMaintenanceRequestTypes,
  getMaintenanceRequestTypeTemplate,
  getPendingMaintenanceRequests,
  postBulkMaintenanceRequest,
  postMaintenanceRequest,
  postMaintenanceRequestLineItemTemplate,
  postMaintenanceRequestType,
  postMaintenanceRequestTypeTemplate,
  postMaintenanceRequestWithWorkOrder,
  putMaintenanceRequest,
  putMaintenanceRequestLineItemTemplate,
  putMaintenanceRequestType,
  putMaintenanceRequestTypeTemplate,
  unassignMaintenanceRequestFromWorkOrder,
  workOrderAssignMaintenanceRequest,
} from '../../services/requests';
import {
  selectChassisLocationObjects,
  selectGasLocationObjects,
  selectLiquidLocationObjects,
} from './asset-locations-slice';
import { selectLoggedInUserActiveLocation } from './auth-slice';
import { selectChassis } from './chassis-slice';
import { selectGasContainers } from './gas-containers-slice';
import { selectLiquidContainers } from './liquid-containers-slice';
import {
  bulkUpdateAndCompleteMaintenanceRequestLineItems,
  bulkUpdateMainteanceRequestLineItems,
  createMaintenanceRequestLineItem,
  createWorkOrderLineItem,
  deleteMaintenanceRequestLineItem,
  undoMaintenanceRequestLineItem,
  updateMaintenanceRequestLineItemStatus,
} from './maintenance-request-line-items-slice';
import { selectCurrentWorkOrder, selectCurrentWorkOrderId } from './work-orders-slice';

const initialState = {
  maintenanceRequests: {},
  maintenanceRequestTypes: {},
  maintenanceDropdownValues: {},
  currentMaintenanceRequestTypeIdForTemplateEdit: undefined,
  currentMaintenanceRequestType: {
    maintenanceRequestTypeId: null,
    status: 'idle',
    error: null,
  },
  currentMaintenanceRequestLineItemTemplate: {
    maintenanceRequestLineItemTemplateId: null,
    status: 'idle',
    error: null,
  },
  maintenanceRequestsFetch: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestsForAssetListFetch: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestsPendingFetch: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestCreate: {
    status: 'idle',
    error: null,
  },
  maintenanceBulkRequestCreate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestUpdate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestAssign: {
    status: 'idle',
    error: null,
  },
  workOrderCreateAssignMaintenanceRequest: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestUnassign: {
    status: 'idle',
    error: null,
  },
  currentMaintenanceRequest: {
    maintenanceRequestId: null,
  },
  maintenanceRequestTypesFetch: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestTypeCreate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestTypeUpdate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestTypeRemove: {
    status: 'idle',
    error: null,
  },
  currentMaintenanceRequestTypeTemplate: {},
  maintenanceRequestTypeTemplateFetch: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestTypeTemplateCreate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestTypeTemplateUpdate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestTypeTemplateRemove: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestLineItemTemplateCreate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestLineItemTemplateUpdate: {
    status: 'idle',
    error: null,
  },
  maintenanceRequestLineItemTemplateRemove: {
    status: 'idle',
    error: null,
  },
  maintenanceDropdownValuesFetch: {
    status: 'idle',
    error: null,
  },
};

export const fetchMaintenanceRequests = createAsyncThunk(
  'maintenanceRequest/fetchMaintenanceRequests',
  async () => {
    const response = await getMaintenanceRequests();
    return response;
  },
);

export const fetchPendingMaintenanceRequests = createAsyncThunk(
  'maintenanceRequest/fetchPendingMaintenanceRequests',
  async () => {
    const response = await getPendingMaintenanceRequests();
    return response;
  },
);

export const fetchMaintenanceRequestsForAssetList = createAsyncThunk(
  'maintenanceRequest/fetchMaintenanceRequestsForAssetList',
  async ({ assetTypeId, assetIds }) => {
    const response = await getMaintenanceRequestsForAssetList(assetTypeId, assetIds);
    return response;
  },
);

export const fetchAssetMaintenanceRequests = createAsyncThunk(
  'maintenanceRequest/fetchAssetMaintenanceRequests',
  async (data) => {
    const response = await getMaintenanceRequestsForAsset(data);
    return response;
  },
);

export const createMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/createMaintenanceRequest',
  async (data) => {
    const response = await postMaintenanceRequest(data);
    return response;
  },
);

export const createMaintenanceRequestWithWorkOrder = createAsyncThunk(
  'maintenanceRequest/createMaintenanceRequestWithWorkOrder',
  async (data) => {
    const response = await postMaintenanceRequestWithWorkOrder(data);
    return response;
  },
);

export const createBulkMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/createBulkMaintenanceRequest',
  async (data) => {
    const response = await postBulkMaintenanceRequest(data);
    return response;
  },
);

export const updateMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/updateMaintenanceRequest',
  async (data) => {
    const response = await putMaintenanceRequest(data);
    return response;
  },
);

export const assignMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/assignMaintenanceRequest',
  async (data) => {
    const response = await assignMaintenanceRequestToWorkOrder(data);
    return response;
  },
);

export const createWorkOrderAssignMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/createWorkOrderAssignMaintenanceRequest',
  async (data) => {
    const response = await workOrderAssignMaintenanceRequest(data);
    return response;
  },
);

export const unassignMaintenanceRequest = createAsyncThunk(
  'maintenanceRequest/unassignMaintenanceRequest',
  async (data) => {
    const response = await unassignMaintenanceRequestFromWorkOrder(data);
    return response;
  },
);

export const fetchMaintenanceRequestTypes = createAsyncThunk(
  'maintenanceRequest/fetchMaintenanceRequestTypes',
  async () => {
    const response = await getMaintenanceRequestTypes();
    return response;
  },
);

export const createMaintenanceRequestType = createAsyncThunk(
  'maintenanceRequest/createMaintenanceRequestType',
  async (data) => {
    const response = await postMaintenanceRequestType(data);
    return response;
  },
);

export const updateMaintenanceRequestType = createAsyncThunk(
  'maintenanceRequest/updateMaintenanceRequestType',
  async (data) => {
    const response = await putMaintenanceRequestType(data);
    return response;
  },
);

export const removeMaintenanceRequestType = createAsyncThunk(
  'maintenanceRequest/deleteMaintenanceRequestType',
  async (data) => {
    const response = await deleteMaintenanceRequestType(data);
    return response;
  },
);

export const fetchCurrentMaintenanceRequestTypeTemplate = createAsyncThunk(
  'maintenanceRequest/fetchMaintenanceRequestTypeTemplate',
  async (data) => {
    const response = await getMaintenanceRequestTypeTemplate(data);
    return response;
  },
);

export const createMaintenanceRequestTypeTemplate = createAsyncThunk(
  'maintenanceRequest/createMaintenanceRequestTypeTemplate',
  async (data) => {
    const response = await postMaintenanceRequestTypeTemplate(data);
    return response;
  },
);

export const updateMaintenanceRequestTypeTemplate = createAsyncThunk(
  'maintenanceRequest/updateMaintenanceRequestTypeTemplate',
  async (data) => {
    const response = await putMaintenanceRequestTypeTemplate(data);
    return response;
  },
);

export const removeMaintenanceRequestTypeTemplate = createAsyncThunk(
  'maintenanceRequest/deleteMaintenanceRequestTypeTemplate',
  async (data) => {
    const response = await deleteMaintenanceRequestTypeTemplate(data);
    return response;
  },
);

export const createMaintenanceRequestLineItemTemplate = createAsyncThunk(
  'maintenanceRequest/createMaintenanceRequestLineItemTemplate',
  async (data) => {
    const response = await postMaintenanceRequestLineItemTemplate(data);
    return response;
  },
);

export const updateMaintenanceRequestLineItemTemplate = createAsyncThunk(
  'maintenanceRequest/updateMaintenanceRequestLineItemTemplate',
  async (data) => {
    const response = await putMaintenanceRequestLineItemTemplate(data);
    return response;
  },
);

export const removeMaintenanceRequestLineItemTemplate = createAsyncThunk(
  'maintenanceRequest/deleteMaintenanceRequestLineItemTemplate',
  async (data) => {
    const response = await deleteMaintenanceRequestLineItemTemplate(data);
    return response;
  },
);

export const fetchMaintenanceDropdownValues = createAsyncThunk(
  'maintenanceRequest/fetchMaintenanceDropdownValues',
  async () => {
    const response = await getMaintenaceDropdownValues();
    return response;
  },
);

export const maintenanceRequestsSlice = createSlice({
  name: 'maintenanceRequest',
  initialState,
  reducers: {
    setCurrentMaintenanceRequestId: (state, action) => {
      state.currentMaintenanceRequest.maintenanceRequestId = action.payload;
    },
    setFetchMaintenanceRequestStatus: (state, action) => {
      state.maintenanceRequestsFetch.status = action.payload;
    },
    setUpdateMaintenanceRequestStatus: (state, action) => {
      state.maintenanceRequestUpdate.status = action.payload;
    },
    setAssignMaintenanceRequestStatus: (state, action) => {
      state.maintenanceRequestAssign.status = action.payload;
    },
    setCreateWorkOrderAssignMaintenanceRequest: (state, action) => {
      state.workOrderCreateAssignMaintenanceRequest.status = action.payload;
    },
    setCreateMaintenanceRequestStatus: (state, action) => {
      state.maintenanceRequestCreate.status = action.payload;
    },
    setCreateBulkMaintenanceRequestStatus: (state, action) => {
      state.maintenanceBulkRequestCreate.status = action.payload;
    },
    setCurrentMaintenanceRequestTypeId: (state, action) => {
      state.currentMaintenanceRequestType.maintenanceRequestTypeId = action.payload;
    },
    setCreateMaintenanceRequestTypeStatus: (state, action) => {
      state.maintenanceRequestTypeCreate.status = action.payload;
    },
    setUpdateMaintenanceRequestTypeStatus: (state, action) => {
      state.maintenanceRequestTypeUpdate.status = action.payload;
    },
    setRemoveMaintenanceRequestTypeStatus: (state, action) => {
      state.maintenanceRequestTypeRemove.status = action.payload;
    },
    setCurrentMaintenanceRequestTypeIdForTemplateEdit: (state, action) => {
      state.currentMaintenanceRequestTypeIdForTemplateEdit = action.payload;
    },
    setFetchMaintenanceRequestTypeTemplateStatus: (state, action) => {
      state.maintenanceRequestTypeTemplateFetch.status = action.payload;
    },
    setCreateMaintenanceRequestTypeTemplateStatus: (state, action) => {
      state.maintenanceRequestTypeTemplateCreate.status = action.payload;
    },
    setCurrentMaintenanceRequestTypeTemplate: (state, action) => {
      state.currentMaintenanceRequestTypeTemplate = action.payload;
    },
    setUpdateMaintenanceRequestTypeTemplateStatus: (state, action) => {
      state.maintenanceRequestTypeTemplateUpdate.status = action.payload;
    },
    setRemoveMaintenanceRequestTypeTemplateStatus: (state, action) => {
      state.maintenanceRequestTypeTemplateRemove.status = action.payload;
    },
    setCurrentMaintenanceRequestLineItemTemplateId: (state, action) => {
      // eslint-disable-next-line max-len
      state.currentMaintenanceRequestLineItemTemplate.maintenanceRequestLineItemTemplateId = action.payload;
    },
    setCreateMaintenanceRequestLineItemTemplateStatus: (state, action) => {
      state.maintenanceRequestLineItemTemplateCreate.status = action.payload;
    },
    setUpdateMaintenanceRequestLineItemTemplateStatus: (state, action) => {
      state.maintenanceRequestLineItemTemplateUpdate.status = action.payload;
    },
    setRemoveMaintenanceRequestLineItemTemplateStatus: (state, action) => {
      state.maintenanceRequestLineItemTemplateRemove.status = action.payload;
    },
    setUnassignMaintenanceRequestStatus: (state, action) => {
      state.maintenanceRequestUnassign.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchMaintenanceRequests.pending, (state, action) => {
        state.maintenanceRequestsFetch.status = 'loading';
      })
      .addCase(fetchMaintenanceRequests.fulfilled, (state, action) => {
        state.maintenanceRequestsFetch.status = 'succeeded';
        state.maintenanceRequests = action.payload.reduce(
          (current, w) => ({
            ...current,
            [w.maintenanceRequestId]: w,
          }),
          {},
        );
        state.maintenanceRequestsFetch.error = null;
      })
      .addCase(fetchMaintenanceRequests.rejected, (state, action) => {
        state.maintenanceRequestsFetch.status = 'failed';
        state.maintenanceRequestsFetch.error = action.error.message;
      });

    builder
      .addCase(fetchMaintenanceRequestsForAssetList.pending, (state, action) => {
        state.maintenanceRequestsForAssetListFetch.status = 'loading';
      })
      .addCase(fetchMaintenanceRequestsForAssetList.fulfilled, (state, action) => {
        state.maintenanceRequestsForAssetListFetch.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          ...action.payload.reduce(
            (current, w) => ({
              ...current,
              [w.maintenanceRequestId]: w,
            }),
            {},
          ),
        };
        state.maintenanceRequestsForAssetListFetch.error = null;
      })
      .addCase(fetchMaintenanceRequestsForAssetList.rejected, (state, action) => {
        state.maintenanceRequestsForAssetListFetch.status = 'failed';
        state.maintenanceRequestsForAssetListFetch.error = action.error.message;
      });

    builder
      .addCase(fetchPendingMaintenanceRequests.pending, (state, action) => {
        state.maintenanceRequestsPendingFetch.status = 'loading';
      })
      .addCase(fetchPendingMaintenanceRequests.fulfilled, (state, action) => {
        state.maintenanceRequestsPendingFetch.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          ...action.payload.reduce(
            (current, w) => ({
              ...current,
              [w.maintenanceRequestId]: w,
            }),
            {},
          ),
        };
        state.maintenanceRequestsPendingFetch.error = null;
      })
      .addCase(fetchPendingMaintenanceRequests.rejected, (state, action) => {
        state.maintenanceRequestsPendingFetch.status = 'failed';
        state.maintenanceRequestsPendingFetch.error = action.error.message;
      });

    builder
      .addCase(fetchAssetMaintenanceRequests.pending, (state, action) => {
        state.maintenanceRequestsFetch.status = 'loading';
      })
      .addCase(fetchAssetMaintenanceRequests.fulfilled, (state, action) => {
        state.maintenanceRequestsFetch.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          ...action.payload.reduce(
            (current, w) => ({
              ...current,
              [w.maintenanceRequestId]: w,
            }),
            {},
          ),

        };
        state.maintenanceRequestsFetch.error = null;
      })
      .addCase(fetchAssetMaintenanceRequests.rejected, (state, action) => {
        state.maintenanceRequestsFetch.status = 'failed';
        state.maintenanceRequestsFetch.error = action.error.message;
      });

    builder
      .addCase(createMaintenanceRequest.pending, (state, action) => {
        state.maintenanceRequestCreate.status = 'loading';
      })
      .addCase(createMaintenanceRequest.fulfilled, (state, action) => {
        state.maintenanceRequestCreate.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          [action.payload.maintenanceRequestId]: action.payload,
        };
        state.currentMaintenanceRequest.maintenanceRequestId = action.payload.maintenanceRequestId;
        state.maintenanceRequestCreate.error = null;
      })
      .addCase(createMaintenanceRequest.rejected, (state, action) => {
        state.maintenanceRequestCreate.status = 'failed';
        state.maintenanceRequestCreate.error = action.error.message;
      });

    builder
      .addCase(updateMaintenanceRequest.pending, (state, action) => {
        state.maintenanceRequestUpdate.status = 'loading';
      })
      .addCase(updateMaintenanceRequest.fulfilled, (state, action) => {
        state.maintenanceRequestUpdate.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          [action.payload.maintenanceRequestId]: action.payload,
        };
        state.maintenanceRequestUpdate.error = null;
      })
      .addCase(updateMaintenanceRequest.rejected, (state, action) => {
        state.maintenanceRequestUpdate.status = 'failed';
        state.maintenanceRequestUpdate.error = action.error.message;
      });

    builder
      .addCase(assignMaintenanceRequest.pending, (state, action) => {
        state.maintenanceRequestAssign.status = 'loading';
      })
      .addCase(assignMaintenanceRequest.fulfilled, (state, action) => {
        state.maintenanceRequestAssign.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          [action.payload.maintenanceRequestId]: action.payload,
        };
        state.maintenanceRequestAssign.error = null;
      })
      .addCase(assignMaintenanceRequest.rejected, (state, action) => {
        state.maintenanceRequestAssign.status = 'failed';
        state.maintenanceRequestAssign.error = action.error.message;
      });

    builder
      .addCase(createWorkOrderAssignMaintenanceRequest.pending, (state, action) => {
        state.workOrderCreateAssignMaintenanceRequest.status = 'loading';
      })
      .addCase(createWorkOrderAssignMaintenanceRequest.fulfilled, (state, action) => {
        state.workOrderCreateAssignMaintenanceRequest.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          [action.payload.maintenanceRequest.maintenanceRequestId]: action.payload.maintenanceRequest,
        };
        state.maintenanceRequestAssign.error = null;
      })
      .addCase(createWorkOrderAssignMaintenanceRequest.rejected, (state, action) => {
        state.workOrderCreateAssignMaintenanceRequest.status = 'failed';
        state.workOrderCreateAssignMaintenanceRequest.error = action.error.message;
      });

    builder
      .addCase(unassignMaintenanceRequest.pending, (state, action) => {
        state.maintenanceRequestUnassign.status = 'loading';
      })
      .addCase(unassignMaintenanceRequest.fulfilled, (state, action) => {
        state.maintenanceRequestUnassign.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          [action.payload.maintenanceRequestId]: action.payload,
        };
        state.maintenanceRequestUnassign.error = null;
      })
      .addCase(unassignMaintenanceRequest.rejected, (state, action) => {
        state.maintenanceRequestUnassign.status = 'failed';
        state.maintenanceRequestUnassign.error = action.error.message;
      });

    builder
      .addCase(fetchMaintenanceRequestTypes.pending, (state, action) => {
        state.maintenanceRequestTypesFetch.status = 'loading';
      })
      .addCase(fetchMaintenanceRequestTypes.fulfilled, (state, action) => {
        state.maintenanceRequestTypesFetch.status = 'succeeded';
        state.maintenanceRequestTypes = action.payload.reduce(
          (current, t) => ({
            ...current,
            [t.maintenanceRequestTypeId]: t,
          }),
          {},
        );
        state.maintenanceRequestTypesFetch.error = null;
      })
      .addCase(fetchMaintenanceRequestTypes.rejected, (state, action) => {
        state.maintenanceRequestTypesFetch.status = 'failed';
        state.maintenanceRequestTypesFetch.error = action.error.message;
      });

    builder
      .addCase(createMaintenanceRequestType.pending, (state, action) => {
        state.maintenanceRequestTypeCreate.status = 'loading';
      })
      .addCase(createMaintenanceRequestType.fulfilled, (state, action) => {
        state.maintenanceRequestTypeCreate.status = 'succeeded';
        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeId]: action.payload,
        };
        state.maintenanceRequestTypeCreate.error = null;
      })
      .addCase(createMaintenanceRequestType.rejected, (state, action) => {
        state.maintenanceRequestTypeCreate.status = 'failed';
        state.maintenanceRequestTypeCreate.error = action.error.message;
      });

    builder
      .addCase(updateMaintenanceRequestType.pending, (state, action) => {
        state.maintenanceRequestTypeUpdate.status = 'loading';
      })
      .addCase(updateMaintenanceRequestType.fulfilled, (state, action) => {
        state.maintenanceRequestTypeUpdate.status = 'succeeded';
        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeId]: action.payload,
        };
        state.maintenanceRequestUpdate.error = null;
      })
      .addCase(updateMaintenanceRequestType.rejected, (state, action) => {
        state.maintenanceRequestTypeUpdate.status = 'failed';
        state.maintenanceRequestTypeUpdate.error = action.error.message;
      });

    builder
      .addCase(removeMaintenanceRequestType.pending, (state, action) => {
        state.maintenanceRequestTypeRemove.status = 'loading';
      })
      .addCase(removeMaintenanceRequestType.fulfilled, (state, action) => {
        state.maintenanceRequestTypeRemove.status = 'succeeded';
        delete state.maintenanceRequestTypes[action.payload.maintenanceRequestTypeId];
        state.maintenanceRequestTypeRemove.error = null;
      })
      .addCase(removeMaintenanceRequestType.rejected, (state, action) => {
        state.maintenanceRequestTypeRemove.status = 'failed';
        state.maintenanceRequestTypeRemove.status = action.error.message;
      });

    builder
      .addCase(fetchCurrentMaintenanceRequestTypeTemplate.pending, (state, action) => {
        state.maintenanceRequestTypeTemplateFetch.status = 'loading';
      })
      .addCase(fetchCurrentMaintenanceRequestTypeTemplate.fulfilled, (state, action) => {
        state.maintenanceRequestTypeTemplateFetch.status = 'succeeded';

        const requestTypeTemplates = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.filter(
          (rtt) => rtt.locationId !== action.payload.locationId,
        );

        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeId]: {
            ...state.maintenanceRequestTypes[action.payload.maintenanceRequestTypeId],
            maintenanceRequestTypeTemplates: [...requestTypeTemplates, action.payload],
          },
        };
        state.currentMaintenanceRequestTypeTemplate = action.payload;
        state.maintenanceRequestTypeTemplateFetch.error = null;
      })
      .addCase(fetchCurrentMaintenanceRequestTypeTemplate.rejected, (state, action) => {
        state.maintenanceRequestTypeTemplateFetch.status = 'failed';
        state.maintenanceRequestTypeTemplateFetch.error = action.error.message;
      });

    builder
      .addCase(createMaintenanceRequestTypeTemplate.pending, (state, action) => {
        state.maintenanceRequestTypeTemplateCreate.status = 'loading';
      })
      .addCase(createMaintenanceRequestTypeTemplate.fulfilled, (state, action) => {
        state.maintenanceRequestTypeTemplateCreate.status = 'succeeded';
        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeId]: {
            ...state.maintenanceRequestTypes[action.payload.maintenanceRequestTypeId],
            maintenanceRequestTypeTemplates: [
              ...state.maintenanceRequestTypes[action.payload.maintenanceRequestTypeId]
                .maintenanceRequestTypeTemplates,
              action.payload,
            ],
          },
        };
        state.maintenanceRequestTypeTemplateCreate.error = null;
      })
      .addCase(createMaintenanceRequestTypeTemplate.rejected, (state, action) => {
        state.maintenanceRequestTypeTemplateCreate.status = 'failed';
        state.maintenanceRequestTypeTemplateCreate.error = action.error.message;
      });

    builder
      .addCase(updateMaintenanceRequestTypeTemplate.pending, (state, action) => {
        state.maintenanceRequestTypeTemplateUpdate.status = 'loading';
      })
      .addCase(updateMaintenanceRequestTypeTemplate.fulfilled, (state, action) => {
        state.maintenanceRequestTypeTemplateUpdate.status = 'succeeded';
        const requestTypeTemplates = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.filter(
          (rtt) => rtt.locationId !== action.payload.locationId,
        );

        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeId]: {
            ...state.maintenanceRequestTypes[action.payload.maintenanceRequestTypeId],
            maintenanceRequestTypeTemplates: [...requestTypeTemplates, action.payload],
          },
        };
        state.maintenanceRequestTypeTemplateUpdate.error = null;
      })
      .addCase(updateMaintenanceRequestTypeTemplate.rejected, (state, action) => {
        state.maintenanceRequestTypeTemplateUpdate.status = 'failed';
        state.maintenanceRequestTypeTemplateUpdate.error = action.error.message;
      });

    builder
      .addCase(removeMaintenanceRequestTypeTemplate.pending, (state, action) => {
        state.maintenanceRequestTypeTemplateRemove.status = 'loading';
      })
      .addCase(removeMaintenanceRequestTypeTemplate.fulfilled, (state, action) => {
        state.maintenanceRequestTypeTemplateRemove.status = 'succeeded';
        // eslint-disable-next-line max-len
        state.maintenanceRequestTypeTemplates[action.payload.maintenanceRequestTypeTemplateId] = action.payload;
        state.maintenanceRequestTypeRemove.error = null;
      })
      .addCase(removeMaintenanceRequestTypeTemplate.rejected, (state, action) => {
        state.maintenanceRequestTypeTemplateRemove.status = 'failed';
        state.maintenanceRequestTypeTemplateRemove.status = action.error.message;
      });

    builder
      .addCase(createMaintenanceRequestLineItemTemplate.pending, (state, action) => {
        state.maintenanceRequestLineItemTemplateCreate.status = 'loading';
      })
      .addCase(createMaintenanceRequestLineItemTemplate.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemTemplateCreate.status = 'succeeded';

        const requestTypeTemplates = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.filter(
          (rtt) => rtt.maintenanceRequestTypeTemplateId
          !== action.payload.maintenanceRequestTypeTemplateId,
        );

        const updatedRequestTypeTemplate = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.find(
          (rtt) => rtt.maintenanceRequestTypeTemplateId
           === action.payload.maintenanceRequestTypeTemplateId,
        );

        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId]: {
            ...state.maintenanceRequestTypes[
              action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId],
            maintenanceRequestTypeTemplates: [...requestTypeTemplates, {
              ...updatedRequestTypeTemplate,
              maintenanceRequestLineItemTemplates: [
                ...updatedRequestTypeTemplate.maintenanceRequestLineItemTemplates,
                action.payload,
              ],
            }],
          },
        };

        state.maintenanceRequestLineItemTemplateCreate.error = null;
      })
      .addCase(createMaintenanceRequestLineItemTemplate.rejected, (state, action) => {
        state.maintenanceRequestLineItemTemplateCreate.status = 'failed';
        state.maintenanceRequestLineItemTemplateCreate.error = action.error.message;
      });

    builder
      .addCase(updateMaintenanceRequestLineItemTemplate.pending, (state, action) => {
        state.maintenanceRequestLineItemTemplateUpdate.status = 'loading';
      })
      .addCase(updateMaintenanceRequestLineItemTemplate.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemTemplateUpdate.status = 'succeeded';

        const requestTypeTemplates = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.filter(
          (rtt) => rtt.maintenanceRequestTypeTemplateId
          !== action.payload.maintenanceRequestTypeTemplateId,
        );

        const updatedRequestTypeTemplate = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.find(
          (rtt) => rtt.maintenanceRequestTypeTemplateId
           === action.payload.maintenanceRequestTypeTemplateId,
        );

        const updatedLineItemTemplates = updatedRequestTypeTemplate
          .maintenanceRequestLineItemTemplates;

        const updatedIndex = updatedLineItemTemplates.findIndex(
          (lit) => lit.maintenanceRequestLineItemTemplateId === action.payload
            .maintenanceRequestLineItemTemplateId,
        );

        updatedLineItemTemplates[updatedIndex] = { ...action.payload };

        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId]: {
            ...state.maintenanceRequestTypes[
              action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId],
            maintenanceRequestTypeTemplates: [...requestTypeTemplates, {
              ...updatedRequestTypeTemplate,
              maintenanceRequestLineItemTemplates: [
                ...updatedLineItemTemplates,
              ],
            }],
          },
        };
        state.maintenanceRequestLineItemTemplateUpdate.error = null;
      })
      .addCase(updateMaintenanceRequestLineItemTemplate.rejected, (state, action) => {
        state.maintenanceRequestLineItemTemplateUpdate.status = 'failed';
        state.maintenanceRequestLineItemTemplateUpdate.error = action.error.message;
      });

    builder
      .addCase(removeMaintenanceRequestLineItemTemplate.pending, (state, action) => {
        state.maintenanceRequestLineItemTemplateRemove.status = 'loading';
      })
      .addCase(removeMaintenanceRequestLineItemTemplate.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemTemplateRemove.status = 'succeeded';

        const requestTypeTemplates = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.filter(
          (rtt) => rtt.maintenanceRequestTypeTemplateId
          !== action.payload.maintenanceRequestTypeTemplateId,
        );

        const updatedRequestTypeTemplate = state.maintenanceRequestTypes[
          action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId
        ].maintenanceRequestTypeTemplates.find(
          (rtt) => rtt.maintenanceRequestTypeTemplateId
           === action.payload.maintenanceRequestTypeTemplateId,
        );

        const filteredLineItemTemplates = updatedRequestTypeTemplate
          .maintenanceRequestLineItemTemplates.filter(
            (lit) => lit.maintenanceRequestLineItemTemplateId !== action.payload
              .maintenanceRequestLineItemTemplateId,
          );

        state.maintenanceRequestTypes = {
          ...state.maintenanceRequestTypes,
          [action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId]: {
            ...state.maintenanceRequestTypes[
              action.payload.maintenanceRequestTypeTemplate.maintenanceRequestTypeId],
            maintenanceRequestTypeTemplates: [...requestTypeTemplates, {
              ...updatedRequestTypeTemplate,
              maintenanceRequestLineItemTemplates: [
                ...filteredLineItemTemplates,
              ],
            }],
          },
        };

        state.maintenanceRequestLineItemTemplateRemove.error = null;
      })
      .addCase(removeMaintenanceRequestLineItemTemplate.rejected, (state, action) => {
        state.maintenanceRequestLineItemTemplateRemove.status = 'failed';
        state.maintenanceRequestLineItemTemplateRemove.status = action.error.message;
      });

    builder
      .addCase(fetchMaintenanceDropdownValues.pending, (state, action) => {
        state.maintenanceDropdownValuesFetch.status = 'loading';
      })
      .addCase(fetchMaintenanceDropdownValues.fulfilled, (state, action) => {
        state.maintenanceDropdownValuesFetch.status = 'succeeded';
        state.maintenanceDropdownValues = action.payload;
        state.maintenanceDropdownValuesFetch.error = null;
      })
      .addCase(fetchMaintenanceDropdownValues.rejected, (state, action) => {
        state.maintenanceDropdownValuesFetch.status = 'failed';
        state.maintenanceDropdownValuesFetch.error = action.error.message;
      });

    builder.addCase(createMaintenanceRequestLineItem.fulfilled, (state, action) => {
      state.maintenanceRequests = {
        ...state.maintenanceRequests,
        [action.payload.maintenanceRequestId]: {
          ...state.maintenanceRequests[action.payload.maintenanceRequestId],
          maintenanceRequestLineItems: [
            ...state.maintenanceRequests[action.payload.maintenanceRequestId]
              .maintenanceRequestLineItems,
            action.payload,
          ],
        },
      };
    });

    builder.addCase(createWorkOrderLineItem.fulfilled, (state, action) => {
      state.maintenanceRequests = {
        ...state.maintenanceRequests,
        [action.payload.maintenanceRequestId]: action.payload,
      };
    });

    builder.addCase(deleteMaintenanceRequestLineItem.fulfilled, (state, action) => {
      state.maintenanceRequests = {
        ...state.maintenanceRequests,
        [action.payload.maintenanceRequestId]: {
          ...state.maintenanceRequests[action.payload.maintenanceRequestId],
          maintenanceRequestLineItems: state.maintenanceRequests[
            action.payload.maintenanceRequestId
          ].maintenanceRequestLineItems.map(
            (lineItem) => (
              lineItem.maintenanceRequestLineItemId === action.payload.maintenanceRequestLineItemId
                ? action.payload
                : lineItem),
          ),
        },
      };
    });

    builder.addCase(bulkUpdateAndCompleteMaintenanceRequestLineItems.fulfilled, (state, action) => {
      if (
        action.payload?.length
      ) {
        const { maintenanceRequestId } = action.payload[0];
        const updated = action.payload?.reduce((obj, item) => ({ ...obj, [item.maintenanceRequestLineItemId]: item }), {});
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          [maintenanceRequestId]: {
            ...state.maintenanceRequests[maintenanceRequestId],
            maintenanceRequestLineItems: state.maintenanceRequests[
              maintenanceRequestId
            ].maintenanceRequestLineItems.map(
              (lineItem) => (
                updated[lineItem.maintenanceRequestLineItemId]
                  ? updated[lineItem.maintenanceRequestLineItemId]
                  : lineItem),
            ),
          },
        };
      }
    });

    builder.addCase(updateMaintenanceRequestLineItemStatus.fulfilled, (state, action) => {
      state.maintenanceRequests = {
        ...state.maintenanceRequests,
        [action.payload.maintenanceRequestId]: {
          ...state.maintenanceRequests[action.payload.maintenanceRequestId],
          maintenanceRequestLineItems: state.maintenanceRequests[
            action.payload.maintenanceRequestId
          ].maintenanceRequestLineItems.map(
            (lineItem) => (
              lineItem.maintenanceRequestLineItemId === action.payload.maintenanceRequestLineItemId
                ? action.payload
                : lineItem),
          ),
        },
      };
    });

    builder.addCase(undoMaintenanceRequestLineItem.fulfilled, (state, action) => {
      state.maintenanceRequests = {
        ...state.maintenanceRequests,
        [action.payload.maintenanceRequestId]: {
          ...state.maintenanceRequests[action.payload.maintenanceRequestId],
          maintenanceRequestLineItems: state.maintenanceRequests[
            action.payload.maintenanceRequestId
          ].maintenanceRequestLineItems.map(
            (lineItem) => (
              lineItem.maintenanceRequestLineItemId === action.payload.maintenanceRequestLineItemId
                ? action.payload
                : lineItem),
          ),
        },
      };
    });

    builder
      .addCase(createMaintenanceRequestWithWorkOrder.pending, (state, action) => {
        state.maintenanceRequestCreate.status = 'loading';
      })
      .addCase(createMaintenanceRequestWithWorkOrder.fulfilled, (state, action) => {
        const resultMr = action.payload.maintenanceRequest;

        state.maintenanceRequestCreate.status = 'succeeded';
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          [resultMr.maintenanceRequestId]: resultMr,
        };
        state.currentMaintenanceRequest.maintenanceRequestId = resultMr.maintenanceRequestId;
        state.maintenanceRequestCreate.error = null;
      })
      .addCase(createMaintenanceRequestWithWorkOrder.rejected, (state, action) => {
        state.maintenanceRequestCreate.status = 'failed';
        state.maintenanceRequestCreate.error = action.error.message;
      });

    builder
      .addCase(createBulkMaintenanceRequest.pending, (state, action) => {
        state.maintenanceBulkRequestCreate.status = 'loading';
      })
      .addCase(createBulkMaintenanceRequest.fulfilled, (state, action) => {
        state.maintenanceBulkRequestCreate.status = 'succeeded';

        const bulkCreatedRequests = action.payload.reduce(
          (current, w) => ({
            ...current,
            [w.value.maintenanceRequestId]: w.value,
          }),
          {},
        );
        state.maintenanceRequests = {
          ...state.maintenanceRequests,
          ...bulkCreatedRequests,
        };
        state.maintenanceBulkRequestCreate.error = null;
      })
      .addCase(createBulkMaintenanceRequest.rejected, (state, action) => {
        state.maintenanceBulkRequestCreate.status = 'failed';
        state.maintenanceBulkRequestCreate.error = action.error.message;
      });

    builder.addCase(bulkUpdateMainteanceRequestLineItems.fulfilled, (state, action) => {
      state.maintenanceRequests = {
        ...state.maintenanceRequests,
        [action.meta.arg.maintenanceRequestId]: {
          ...state.maintenanceRequests[action.meta.arg.maintenanceRequestId],
          maintenanceRequestLineItems: action.payload,
        },
      };
    });
  },
});

export const {
  setCurrentMaintenanceRequestId,
  setFetchMaintenanceRequestStatus,
  setUpdateMaintenanceRequestStatus,
  setAssignMaintenanceRequestStatus,
  setCreateBulkMaintenanceRequestStatus,
  setCreateMaintenanceRequestStatus,
  setCreateMaintenanceRequestTypeStatus,
  setUpdateMaintenanceRequestTypeStatus,
  setRemoveMaintenanceRequestTypeStatus,
  setCurrentMaintenanceRequestTypeId,
  setCurrentMaintenanceRequestTypeTemplate,
  setCurrentMaintenanceRequestTypeIdForTemplateEdit,
  setFetchMaintenanceRequestTypeTemplateStatus,
  setCurrentMaintenanceRequestLineItemTemplateId,
  setCreateMaintenanceRequestLineItemTemplateStatus,
  setRemoveMaintenanceRequestLineItemTemplateStatus,
  setUpdateMaintenanceRequestLineItemTemplateStatus,
  setCreateWorkOrderAssignMaintenanceRequest,
  setUnassignMaintenanceRequestStatus,
} = maintenanceRequestsSlice.actions;

export const selectMaintenanceRequests = (state) => state.maintenanceRequest.maintenanceRequests;
export const selectCurrentMaintenanceRequestId = (
  state,
) => state.maintenanceRequest.currentMaintenanceRequest.maintenanceRequestId;

export const selectMaintenanceRequestTypes = (
  state,
) => state.maintenanceRequest.maintenanceRequestTypes;

export const selectCurrentMaintenanceRequestTypeIdForTemplateEdit = (
  state,
) => state.maintenanceRequest.currentMaintenanceRequestTypeIdForTemplateEdit;

export const selectCurrentMaintenanceRequestTypeTemplate = (
  state,
) => state.maintenanceRequest.currentMaintenanceRequestTypeTemplate;

export const selectCurrentMaintenanceRequestTypeId = (
  state,
) => state.maintenanceRequest.currentMaintenanceRequestType.maintenanceRequestTypeId;

export const selectMaintenanceRequestLineItemTemplates = (
  state,
) => state.maintenanceRequest.maintenanceRequestLineItemTemplates;

export const selectCurrentMaintenanceRequestLineItemTemplateId = (
  state,
) => state.maintenanceRequest.currentMaintenanceRequestLineItemTemplate
  .maintenanceRequestLineItemTemplateId;

export const selectCurrentMaintenanceRequestTypeTemplateData = createSelector(
  [
    selectCurrentMaintenanceRequestTypeTemplate,
    selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  ],
  (template, currentId) => {
    // if (currentId == null) {
    //   return {};
    // }
    // return maintenanceRequestTypes[currentId];
    return template;
  },
);

// Remove "General (Legacy Migration)" & "Miscellanious"
export const selectReleventMaintenanceRequestTypes = createSelector(
  [selectMaintenanceRequestTypes],
  (maintenanceRequestTypes) => Object.keys(maintenanceRequestTypes).reduce((current, w) => {
    const mrIdsToIgnore = [10013, 10008, 10013];
    const currentType = maintenanceRequestTypes[w];
    if (!mrIdsToIgnore.includes(currentType.maintenanceRequestTypeId) && currentType.isMiscellanious !== true) {
      return {
        ...current,
        [w]: {
          ...currentType,
        },
      };
    }
    return current;
  }, {}),
);

export const selectCurrentMaintenanceRequestTypeData = createSelector(
  [selectMaintenanceRequestTypes, selectCurrentMaintenanceRequestTypeId],
  (maintenanceRequestTypes, currentId) => {
    if (currentId == null) {
      return {};
    }
    return maintenanceRequestTypes[currentId];
  },
);

export const selectCurrentMaintenanceRequestTypeForTemplateEditData = createSelector(
  [selectMaintenanceRequestTypes, selectCurrentMaintenanceRequestTypeIdForTemplateEdit],
  (maintenanceRequestTypes, currentId) => {
    if (currentId == null) {
      return {};
    }
    return maintenanceRequestTypes[currentId];
  },
);

export const selectCurrentMaintenanceRequestTypeTemplateForActiveLocation = createSelector(
  [selectCurrentMaintenanceRequestTypeForTemplateEditData, selectLoggedInUserActiveLocation],
  (maintenanceRequestType, activeLocation) => {
    const requestTypeTemplates = maintenanceRequestType?.maintenanceRequestTypeTemplates ?? [];

    return (
      requestTypeTemplates.find((template) => template.locationId === activeLocation?.LocationID)
      ?? null
    );
  },
);

export const selectPlannedLiquidMaintenanceRequestTypes = createSelector(
  [selectMaintenanceRequestTypes],
  (maintenanceRequestTypes) => Object.keys(maintenanceRequestTypes).reduce((current, w) => {
    const currentType = maintenanceRequestTypes[w];
    if (currentType.assetTypeId === 2 && currentType.isPlanned === true) {
      return {
        ...current,
        [w]: {
          ...currentType,
        },
      };
    }
    return current;
  }, {}),
);

export const selectUnplannedLiquidMaintenanceRequestTypes = createSelector(
  [selectMaintenanceRequestTypes],
  (maintenanceRequestTypes) => Object.keys(maintenanceRequestTypes).reduce((current, w) => {
    const currentType = maintenanceRequestTypes[w];
    if (currentType.assetTypeId === 2 && currentType.isPlanned === false) {
      return {
        ...current,
        [w]: {
          ...currentType,
        },
      };
    }
    return current;
  }, {}),
);

export const selectPlannedGasMaintenanceRequestTypes = createSelector(
  [selectMaintenanceRequestTypes],
  (maintenanceRequestTypes) => Object.keys(maintenanceRequestTypes).reduce((current, w) => {
    const currentType = maintenanceRequestTypes[w];
    if (currentType.assetTypeId === 1 && currentType.isPlanned === true) {
      return {
        ...current,
        [w]: {
          ...currentType,
        },
      };
    }
    return current;
  }, {}),
);

export const selectUnplannedGasMaintenanceRequestTypes = createSelector(
  [selectMaintenanceRequestTypes],
  (maintenanceRequestTypes) => Object.keys(maintenanceRequestTypes).reduce((current, w) => {
    const currentType = maintenanceRequestTypes[w];
    if (currentType.assetTypeId === 1 && currentType.isPlanned === false) {
      return {
        ...current,
        [w]: {
          ...currentType,
        },
      };
    }
    return current;
  }, {}),
);

export const selectPlannedChassisMaintenanceRequestTypes = createSelector(
  [selectMaintenanceRequestTypes],
  (maintenanceRequestTypes) => Object.keys(maintenanceRequestTypes).reduce((current, w) => {
    const currentType = maintenanceRequestTypes[w];
    if (currentType.assetTypeId === 4 && currentType.isPlanned === true) {
      return {
        ...current,
        [w]: {
          ...currentType,
        },
      };
    }
    return current;
  }, {}),
);

export const selectUnplannedChassisMaintenanceRequestTypes = createSelector(
  [selectMaintenanceRequestTypes],
  (maintenanceRequestTypes) => Object.keys(maintenanceRequestTypes).reduce((current, w) => {
    const currentType = maintenanceRequestTypes[w];
    if (currentType.assetTypeId === 4 && currentType.isPlanned === false) {
      return {
        ...current,
        [w]: {
          ...currentType,
        },
      };
    }
    return current;
  }, {}),
);

export const selectCurrentMaintenanceRequest = createSelector(
  [
    selectMaintenanceRequests,
    selectCurrentMaintenanceRequestId,
    selectLiquidContainers,
    selectGasContainers,
    selectChassis,
  ],
  (maintenanceRequests, currentMaintenanceRequestId, liquidContainers, gasContainers, chassis) => {
    if (currentMaintenanceRequestId == null) {
      return {};
    }
    let assetDetails;

    const currentMaintenanceRequest = maintenanceRequests[currentMaintenanceRequestId];
    if (currentMaintenanceRequest.assetTypeId === 2) {
      assetDetails = liquidContainers[currentMaintenanceRequest.assetSerialNumber];
    } else if (currentMaintenanceRequest.assetTypeId === 1) {
      assetDetails = gasContainers[currentMaintenanceRequest.assetSerialNumber];
    } else if (currentMaintenanceRequest.assetTypeId === 4) {
      assetDetails = chassis[currentMaintenanceRequest.assetSerialNumber];
    }

    return { ...currentMaintenanceRequest, assetDetails };
  },
);

export const selectLiquidContainerMaintenanceRequests = createSelector(
  [selectMaintenanceRequests, selectLiquidContainers, selectMaintenanceRequestTypes],
  (maintenanceRequests, liquidContainers, maintenanceRequestTypes) => Object.keys(
    maintenanceRequests,
  ).reduce((current, w) => {
    const currentRequest = maintenanceRequests[w];
    if (maintenanceRequestTypes[currentRequest.maintenanceRequestTypeId]?.assetTypeId === 2) {
      return {
        ...current,
        [w]: {
          ...currentRequest,
          assetDetails: liquidContainers[currentRequest.assetSerialNumber],
        },
      };
    }
    return current;
  }, {}),
);

export const selectGasContainerMaintenanceRequests = createSelector(
  [selectMaintenanceRequests, selectGasContainers, selectMaintenanceRequestTypes],
  (
    maintenanceRequests,
    gasContainers,
    maintenanceRequestTypes,
  ) => Object.keys(maintenanceRequests).reduce((current, w) => {
    const currentRequest = maintenanceRequests[w];
    if (maintenanceRequestTypes[currentRequest.maintenanceRequestTypeId]?.assetTypeId === 1) {
      return {
        ...current,
        [w]: { ...currentRequest, assetDetails: gasContainers[currentRequest.assetSerialNumber] },
      };
    }
    return current;
  }, {}),
);

export const selectChassisMaintenanceRequests = createSelector(
  [selectMaintenanceRequests, selectChassis, selectMaintenanceRequestTypes],
  (
    maintenanceRequests,
    chassis,
    maintenanceRequestTypes,
  ) => Object.keys(maintenanceRequests).reduce((current, w) => {
    const currentRequest = maintenanceRequests[w];
    if (maintenanceRequestTypes[currentRequest.maintenanceRequestTypeId]?.assetTypeId === 4) {
      return {
        ...current,
        [w]: { ...currentRequest, assetDetails: chassis[currentRequest.assetSerialNumber] },
      };
    }
    return current;
  }, {}),
);

export const selectPendingMaintenanceRequests = createSelector(
  [selectMaintenanceRequests],
  (
    maintenanceRequests,
  ) => {
    const pendingRequests = Object.values(maintenanceRequests).filter((mr) => {
      return mr.maintenanceRequestStatusId === 1;
    });
    return pendingRequests;
  },
);

export const selectPendingMaintenanceRequestsCount = createSelector(
  [selectPendingMaintenanceRequests],
  (
    pendingMaintenanceRequests,
  ) => {
    return pendingMaintenanceRequests?.length;
  },
);

const selectDropdownValues = (state) => state.maintenanceRequest?.maintenanceDropdownValues ?? {};

export const selectMaintenanceRequestDropdownValues = createSelector(
  [selectDropdownValues],
  (dropdowns) => ({
    assetTypes: dropdowns?.assetTypes ?? [],
    workOrderStatuses: dropdowns?.workOrderStatuses ?? [],
    maintenanceRequestLineItemTypes: dropdowns?.maintenanceRequestLineItemTypes ?? [],
    maintenanceRequestStatuses: dropdowns?.maintenanceRequestStatuses ?? [],
    purchaseRequestStatuses: dropdowns?.purchaseRequestStatuses ?? [],
    purchaseOrderStatuses: dropdowns?.purchaseOrderStatuses ?? [],
    complaintOrigins: dropdowns?.complaintOrigins ?? [],
  }),
);

export const selectMaintenanceRequestDropdownObjects = createSelector(
  [selectMaintenanceRequestDropdownValues],
  (dropdownValues) => ({
    assetTypes: dropdownValues.assetTypes.reduce(
      (current, t) => ({
        ...current,
        [t.id]: t,
      }),
      {},
    ),
    workOrderStatuses: dropdownValues.workOrderStatuses.reduce(
      (current, t) => ({
        ...current,
        [t.id]: t,
      }),
      {},
    ),
    maintenanceRequestLineItemTypes: dropdownValues.maintenanceRequestLineItemTypes.reduce(
      (current, t) => ({
        ...current,
        [t.id]: t,
      }),
      {},
    ),
    maintenanceRequestStatuses: dropdownValues.maintenanceRequestStatuses.reduce(
      (current, t) => ({
        ...current,
        [t.id]: t,
      }),
      {},
    ),
    purchaseRequestStatuses: dropdownValues.purchaseRequestStatuses.reduce(
      (current, t) => ({
        ...current,
        [t.id]: t,
      }),
      {},
    ),
    purchaseOrderStatuses: dropdownValues.purchaseOrderStatuses.reduce(
      (current, t) => ({
        ...current,
        [t.id]: t,
      }),
      {},
    ),
    complaintOrigins: dropdownValues.complaintOrigins.reduce(
      (current, t) => ({
        ...current,
        [t.id]: t,
      }),
      {},
    ),
  }),
);

export const selectLiquidMaintenanceRequestsByAssetId = createSelector(
  [selectMaintenanceRequests, selectMaintenanceRequestTypes],
  (
    maintenanceRequests,
    maintenanceRequestTypes,
  ) => Object.keys(maintenanceRequests).reduce((current, requestId) => {
    const currentRequest = maintenanceRequests[requestId];
    if (maintenanceRequestTypes[currentRequest.maintenanceRequestTypeId]?.assetTypeId === 2) {
      if (currentRequest.assetSerialNumber in current) {
        return {
          ...current,
          [currentRequest.assetSerialNumber]: [
            ...current[currentRequest.assetSerialNumber],
            currentRequest,
          ],
        };
      }
      return {
        ...current,
        [currentRequest.assetSerialNumber]: [currentRequest],
      };
    }
    return current;
  }, {}),
);

export const selectChassisMaintenanceRequestsByAssetId = createSelector(
  [selectMaintenanceRequests, selectMaintenanceRequestTypes],
  (
    maintenanceRequests,
    maintenanceRequestTypes,
  ) => Object.keys(maintenanceRequests).reduce((current, requestId) => {
    const currentRequest = maintenanceRequests[requestId];
    if (maintenanceRequestTypes[currentRequest.maintenanceRequestTypeId]?.assetTypeId === 4) {
      if (currentRequest.assetSerialNumber in current) {
        return {
          ...current,
          [currentRequest.assetSerialNumber]: [
            ...current[currentRequest.assetSerialNumber],
            currentRequest,
          ],
        };
      }
      return {
        ...current,
        [currentRequest.assetSerialNumber]: [currentRequest],
      };
    }
    return current;
  }, {}),
);

export const selectGasMaintenanceRequestsByAssetId = createSelector(
  [selectMaintenanceRequests, selectMaintenanceRequestTypes],
  (
    maintenanceRequests,
    maintenanceRequestTypes,
  ) => Object.keys(maintenanceRequests).reduce((current, requestId) => {
    const currentRequest = maintenanceRequests[requestId];
    if (maintenanceRequestTypes[currentRequest.maintenanceRequestTypeId]?.assetTypeId === 1) {
      if (currentRequest.assetSerialNumber in current) {
        return {
          ...current,
          [currentRequest.assetSerialNumber]: [
            ...current[currentRequest.assetSerialNumber],
            currentRequest,
          ],
        };
      }
      return {
        ...current,
        [currentRequest.assetSerialNumber]: [currentRequest],
      };
    }
    return current;
  }, {}),
);

export const selectChassisWithLocationInfo = createSelector(
  [selectChassisLocationObjects, selectChassis],
  (currentChassisLocations, chassis) => Object.keys(
    chassis,
  ).reduce(
    (current, id) => ({
      ...current,
      [id]: {
        ...chassis[id],
        currentLocationInfo: id in currentChassisLocations ? currentChassisLocations[id] : null,
      },
    }),
    {},
  ),
);

export const selectChassisWithMaintenanceInfo = createSelector(
  [selectChassisLocationObjects, selectChassisMaintenanceRequestsByAssetId, selectChassis],
  (currentChassisLocations, chassisRequests, chassis) => Object.keys(chassis).reduce(
    (current, id) => ({
      ...current,
      [id]: {
        ...chassis[id],
        currentLocationInfo: id in currentChassisLocations ? currentChassisLocations[id] : null,
        maintenanceRequests: id in chassisRequests ? chassisRequests[id] : [],
      },
    }),
    {},
  ),
);

export const selectLiquidContainersWithLocationInfo = createSelector(
  [selectLiquidLocationObjects, selectLiquidContainers],
  (currentLiquidLocations, liquidContainers) => Object.keys(
    liquidContainers,
  ).reduce(
    (current, id) => ({
      ...current,
      [id]: {
        ...liquidContainers[id],
        currentLocationInfo: id in currentLiquidLocations ? currentLiquidLocations[id] : null,
      },
    }),
    {},
  ),
);

export const selectLiquidContainersWithMaintenanceInfo = createSelector(
  [selectLiquidLocationObjects, selectLiquidMaintenanceRequestsByAssetId, selectLiquidContainers],
  (currentLiquidLocations, liquidRequests, liquidContainers) => Object.keys(
    liquidContainers,
  ).reduce(
    (current, id) => ({
      ...current,
      [id]: {
        ...liquidContainers[id],
        currentLocationInfo: id in currentLiquidLocations ? currentLiquidLocations[id] : null,
        maintenanceRequests: id in liquidRequests ? liquidRequests[id] : [],
      },
    }),
    {},
  ),
);

export const selectGasContainersWithLocationInfo = createSelector(
  [selectGasLocationObjects, selectGasContainers],
  (currentGasLocations, gasContainers) => Object.keys(
    gasContainers,
  ).reduce(
    (current, id) => ({
      ...current,
      [id]: {
        ...gasContainers[id],
        currentLocationInfo: id in currentGasLocations ? currentGasLocations[id] : null,
      },
    }),
    {},
  ),
);

export const selectGasContainersWithMaintenanceInfo = createSelector(
  [selectGasLocationObjects, selectGasMaintenanceRequestsByAssetId, selectGasContainers],
  (currentGasLocations, gasRequests, gasContainers) => Object.keys(gasContainers).reduce(
    (current, id) => ({
      ...current,
      [id]: {
        ...gasContainers[id],
        currentLocationInfo: id in currentGasLocations ? currentGasLocations[id] : null,
        maintenanceRequests: id in gasRequests ? gasRequests[id] : [],
      },
    }),
    {},
  ),
);

export const selectMaintenanceRequestsByWorkOrderId = createSelector(
  [selectMaintenanceRequests],
  (maintenanceRequests) => Object.keys(maintenanceRequests).reduce((current, requestId) => {
    const currentRequest = maintenanceRequests[requestId];
    if (currentRequest.workOrderId) {
      if (currentRequest.workOrderId in current) {
        return {
          ...current,
          [currentRequest.workOrderId]: [...current[currentRequest.workOrderId], currentRequest],
        };
      }
      return {
        ...current,
        [currentRequest.workOrderId]: [currentRequest],
      };
    }
    return current;
  }, {}),
);

export const selectCurrentWorkOrderMaintenanceRequests = createSelector(
  [selectCurrentWorkOrderId, selectMaintenanceRequestsByWorkOrderId],
  (currentWorkOrderId, requestsByWorkOrderId) => {
    if (currentWorkOrderId in requestsByWorkOrderId) {
      return requestsByWorkOrderId[currentWorkOrderId];
    }
    return [];
  },
);

export const selectActiveLineItemsByMaintenanceRequestId = createSelector(
  [selectMaintenanceRequests, (state, maintenanceRequestId) => maintenanceRequestId],
  (maintenanceRequests, maintenanceRequestId) => {
    if (maintenanceRequestId in maintenanceRequests) {
      return (maintenanceRequests[maintenanceRequestId].maintenanceRequestLineItems ?? []).filter(
        (li) => li.isActive,
      );
    }
    return [];
  },
);

export const selectOutstandingRequests = createSelector(
  [
    selectCurrentWorkOrder,
    selectLiquidMaintenanceRequestsByAssetId,
    selectChassisMaintenanceRequestsByAssetId,
    selectGasMaintenanceRequestsByAssetId,
    (state, assetTypeId) => assetTypeId,
  ],
  (
    workOrder,
    liquidMaintenanceRequests,
    chassisMaintenanceRequests,
    gasMaintenanceRequests,
    assetTypeId,
  ) => {
    let outstandingRequests = [];
    if (workOrder?.assetSerialNumber) {
      const currentWorkOrderId = workOrder.workOrderId;
      if (assetTypeId === 1) {
        const requestsOnAsset = gasMaintenanceRequests[workOrder?.assetSerialNumber] ?? [];
        outstandingRequests = Object.values(requestsOnAsset).filter(
          (
            value,
          ) => value.workOrderId !== currentWorkOrderId && value.maintenanceRequestTypeId !== 3
          && value.workOrderStatus !== 'Completed' && value.workOrderStatus !== 'Closed',
        );
      }
      if (assetTypeId === 2) {
        const requestsOnAsset = liquidMaintenanceRequests[workOrder?.assetSerialNumber] ?? [];
        outstandingRequests = Object.values(requestsOnAsset).filter(
          (
            value,
          ) => value.workOrderId !== currentWorkOrderId && value.maintenanceRequestTypeId !== 3
          && value.workOrderStatus !== 'Completed' && value.workOrderStatus !== 'Closed',
        );
      }
      if (assetTypeId === 4) {
        const requestsOnAsset = chassisMaintenanceRequests[workOrder?.assetSerialNumber] ?? [];
        outstandingRequests = Object.values(requestsOnAsset).filter(
          (
            value,
          ) => value.workOrderId !== currentWorkOrderId && value.maintenanceRequestTypeId !== 3
          && value.workOrderStatus !== 'Completed' && value.workOrderStatus !== 'Closed',
        );
      }
    }
    return outstandingRequests;
  },
  {},
);

export const selectCurrentWorkOrderPartsConsumed = createSelector(
  [selectCurrentWorkOrderMaintenanceRequests],
  (maintenanceRequests) => {
    const lineItems = maintenanceRequests.map((mr) => mr.maintenanceRequestLineItems).flat();
    const partsPurchased = lineItems.filter((li) => li && li.partsPurchased !== null && li.isActive).map((li) => li?.partsPurchased).flat();
    return partsPurchased;
  },
);

export default maintenanceRequestsSlice.reducer;
