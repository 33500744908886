//@ts-nocheck
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Form } from "react-bootstrap";
import { isAsync } from "../../../../services/table-helpers";
import { useSelector } from "react-redux";
import { Controller } from "react-hook-form";
import { Typeahead } from 'react-bootstrap-typeahead';

export const TypeaheadField = ({field, control, data, getValues, editTableProps }) => {
  const [result, setResult] = useState([] as any[]);
  const isOptionsAsync = typeof field.options === 'function' && isAsync(field.options);
  const optionSelector = useSelector(typeof field.options === 'function' && !isAsync(field.options) ? field.options : () => null);

  useEffect(() => {
    let active = true
    load()
    return () => { active = false }

    async function load() {
      if (isOptionsAsync) {
        const res = await (field.options as ((data: any, getValues?: any, editTableProps?: any) => Promise<any[]>))(data, getValues, editTableProps);
        if (!active) { return }
        setResult(res)
      }
    }
  }, [data, field.options]);

  return (
    <Form.Group className="mb-1">
      <Form.Label>{field.label}</Form.Label>
      <Controller
        control={control}
        name={field.name}
        defaultValue={null}
        render={({
          field: {
            onChange, value, ref,
          },
          fieldState: {
            error,
          },
        }) => {
          const options = isOptionsAsync ? result : (typeof field.options === 'object' ? field.options : optionSelector) || [];
          const valueBased = options.length ? typeof options[0] === "string" : true;
          const pp = {
            size: "sm",
            flip: true,
            ref: ref,
            onChange: (e) => {
              const sel = e[0];
              if (valueBased) {
                onChange(sel?.label ? sel.label : sel)
              } else if (typeof sel === "object") {
                onChange(sel?.id);
              }
            },
            id: field.name,
            placeholder: valueBased ? value : options?.find(o => o.id === value)?.label,
            options: isOptionsAsync ? result : (typeof field.options === 'object' ? field.options : optionSelector) || [],
            isInvalid: !!error,
            allowNew: field.freeform
          }
          return (
            <Typeahead
              {
              ...pp
              }
            />
          );
        }}
      />
    </Form.Group>
  )
}