import { createListView, FilterType, ListView, ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";
import { liquidCustomerStore } from "../../../store/store";
import { customerLiquidCreate } from "./customer-liquid-create";
import { customerPermissions } from "./customer-data-source";

export const liquidCustomerList: ListView = {
  create: customerLiquidCreate,
  enableExport: true,
  permissions: customerPermissions,
  filters: [
    {
      type: FilterType.combo,
      label: 'State',
      fieldName: 'state',
    },
    {
      type: FilterType.simple,
      label: 'Active',
      fieldName: 'active',
      filterValue: true
    },
  ],
  viewOptions: [
    {
      type: FilterType.simple,
      optionName: 'showPricing',
      label: 'Pricing View',
      defaultValue: false
    }
  ],
  columns: [
    {
      Header: 'Customer #',
      accessor: 'customerId',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ value, row }) => `/customers/edit/liquid/${value}`
    },
    {
      Header: 'Name',
      accessor: 'customerName',
      defaultSort: true,
    },
    {
      Header: 'Region',
      accessor: 'region',
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'Shipping Address',
      accessor: 'shippingAddress',
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'City',
      accessor: 'city',
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'State',
      accessor: 'state',
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'Zip',
      accessor: 'zip',
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'Billing Status',
      id: 'billingStatus',
      accessor: ({ bypassLiquidBilling }) => bypassLiquidBilling === true ? "Bypassed" : "Enabled",
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'NALS Billed',
      id: 'isNALSCustomer',
      accessor: ({ isNALSCustomer }) => isNALSCustomer === true ? "Yes" : "No",
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'Status',
      accessor: 'status',
      show: (viewOptions) => !viewOptions['showPricing']
    },
    {
      Header: 'Active',
      accessor: 'active',
      hidden: true
    },
    {
      Header: '$Rent/Day', accessor: 'rentalFeePerDay', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
        const { rentalFeePerDayEffectiveDate: effectiveDate, rentalFeePerDayExpirationDate: expirationDate } = row.original;
        return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
      }
    },
    { Header: '$Rent/Month', accessor: 'rentalFeePerMonth', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { rentalFeePerMonthEffectiveDate: effectiveDate, rentalFeePerMonthExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Helium Price', accessor: 'liquidHeliumPrice', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { liquidHeliumPriceEffectiveDate: effectiveDate, liquidHeliumPriceExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'N2 Price', accessor: 'nitrogenPrice', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { nitrogenPriceEffectiveDate: effectiveDate, nitrogenPriceExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Flat N2 Price', accessor: 'flatNitrogenPrice', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { flatNitrogenPriceEffectiveDate: effectiveDate, flatNitrogenPriceExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Transport Charge', accessor: 'transportationCharge', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { transportationChargeEffectiveDate: effectiveDate, transportationChargeExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Depress Charge', accessor: 'depressurizationCharge', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { depressurizationChargeEffectiveDate: effectiveDate, depressurizationChargeExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Analytics Charge', accessor: 'analyticalCharge', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { analyticalChargeEffectiveDate: effectiveDate, analyticalChargeExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Repair CM', accessor: 'repairChargeCityMachine', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { repairChargeCityMachineEffectiveDate: effectiveDate, repairChargeCityMachineExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Repair W', accessor: 'repairChargeGardnerW', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { repairChargeGardnerWEffectiveDate: effectiveDate, repairChargeGardnerWExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Repair E', accessor: 'repairChargeGardnerE', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { repairChargeGardnerEEffectiveDate: effectiveDate, repairChargeGardnerEExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Repair S', accessor: 'repairChargeGardnerS', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { repairChargeGardnerSEffectiveDate: effectiveDate, repairChargeGardnerSExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Service Charge', accessor: 'serviceCharge', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { serviceChargeEffectiveDate: effectiveDate, serviceChargeExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Cooldown Tier1', accessor: 'cooldownTier1', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { cooldownTier1EffectiveDate: effectiveDate, cooldownTier1ExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Cooldown Tier2', accessor: 'cooldownTier2', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { cooldownTier2EffectiveDate: effectiveDate, cooldownTier2ExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Cooldown Tier3', accessor: 'cooldownTier3', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { cooldownTier3EffectiveDate: effectiveDate, cooldownTier3ExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Cooldown Charge', accessor: 'cooldownCharge', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { cooldownChargeEffectiveDate: effectiveDate, cooldownChargeExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Residual Price', accessor: 'residualPrice', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { residualPriceEffectiveDate: effectiveDate, residualPriceExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Residual Price High', accessor: 'residualPriceHigh', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { residualPriceHighEffectiveDate: effectiveDate, residualPriceHighExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Residual Price Low', accessor: 'residualPriceLow', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { residualPriceLowEffectiveDate: effectiveDate, residualPriceLowExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Residual Threshold High', accessor: 'residualThresholdHigh', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { residualThresholdHighEffectiveDate: effectiveDate, residualThresholdHighExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Residual Threshold Low', accessor: 'residualThresholdLow', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { residualThresholdLowEffectiveDate: effectiveDate, residualThresholdLowExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Export Insp.', accessor: 'exportInspection', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { exportInspectionEffectiveDate: effectiveDate, exportInspectionExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Free Rent Days', accessor: 'freeRentDays', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { freeRentDaysEffectiveDate: effectiveDate, freeRentDaysExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Chassis Rental Fee Per Day', accessor: 'chassisRentalFeePerDay', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { chassisRentalFeePerDayEffectiveDate: effectiveDate, chassisRentalFeePerDayExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Chassis Rental Fee Per Month', accessor: 'chassisRentalFeePerMonth', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { chassisRentalFeePerMonthEffectiveDate: effectiveDate, chassisRentalFeePerMonthExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Export Fee Price', accessor: 'exportFeePrice', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { exportFeePriceEffectiveDate: effectiveDate, exportFeePriceExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Customs Fee Price', accessor: 'customsFeePrice', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { customsFeePriceEffectiveDate: effectiveDate, customsFeePriceExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Forwarding Fee Price', accessor: 'forwardingFeePrice', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { forwardingFeePriceEffectiveDate: effectiveDate, forwardingFeePriceExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },
    { Header: 'Tolling Service Charge', accessor: 'tollingServiceCharge', show: (viewOptions) => viewOptions['showPricing'], title: (column, row, dateFormatter) => {
      const { tollingServiceChargeEffectiveDate: effectiveDate, tollingServiceChargeExpirationDate: expirationDate } = row.original;
      return effectiveDate && expirationDate ? `Effective: ${dateFormatter(effectiveDate)} Expiration: ${dateFormatter(expirationDate)}` : null;
    } },

  ] as ListViewColumn[],
  /*   pageHeader: 'Dewar Customers', */
  breadcrumbs: [
    {
      name: 'Customers',
      icon: 'people',
      to: '/customers',
    },
  ],

};

export const LiquidCustomerList = createListView(liquidCustomerList, liquidCustomerStore as any);


export const LiquidCustomerSelectList = createListView({
  ...liquidCustomerList,
  toolbar: undefined,
  noGlobalFilterPadding: true,
  enableExport: false,
  create: undefined,
  viewOptions: undefined,
  selectionMode: 'single',
  columns: [
    {
      type: ListViewColumnType.radio,
      accessor: 'checked',
      excludeGlobalFilter: true,
    },
    ...liquidCustomerList.columns
  ]
}, liquidCustomerStore as any);