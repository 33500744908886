import React, {
  useEffect, useMemo, useReducer,
  useRef,
  useState,
} from 'react';
import {
  Button, Collapse, Form, InputGroup,
} from 'react-bootstrap';
import {
  BsFillCheckCircleFill, BsPencilFill, BsXCircleFill, BsXLg,
} from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { formatAssetType, formatDate } from '../../../services/format';
import { sortByBoolean } from '../../../services/table-helpers';
import { selectLoggedInUserInfo, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectAllLocationObjects } from '../../../store/slices/legacy-slice';
import { removePart, selectAllParts, selectReorderParts } from '../../../store/slices/parts-slice';
import { fetchPurchaseOrders, selectPurchaseOrders } from '../../../store/slices/purchase-orders-slice';
import { fetchPurchaseRequests, selectPurchaseRequestsObjects } from '../../../store/slices/purchase-request-slice';
import ActiveIcon from '../../UI/atoms/ActiveIcon/active-icon';
import InactiveIcon from '../../UI/atoms/InactiveIcon/inactive-Icon';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';
import RowSelectTable from '../../UI/organisms/Table/row-select-table';
import CreatePartModal from './create-part-modal';
import PurchaseRequestModal from './purchase-request-modal';

const initFilterState = {
  filterOpen: false,
  isActive: true,
};

// Define reducer for controlling the packaged assets filter state
function filtersReducer(state, action) {
  switch (action.type) {
    case 'filterOpen':
      return { ...state, filterOpen: !state.filterOpen };
    case 'isActive':
      return { ...state, isActive: !state.isActive };
    default:
      throw new Error('invalid case');
  }
}

function PartsListTable(props) {
  const dispatch = useDispatch();

  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);
  const purchaseOrders = useSelector(selectPurchaseOrders);
  const purchaseRequests = useSelector(selectPurchaseRequestsObjects);
  const purchaseOrdersStatus = useSelector((state) => state.purchaseOrder.purchaseOrdersFetch?.status);
  const purchaseRequestsStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsFetch?.status);
  const poLoading = purchaseOrdersStatus === 'loading';
  const prLoading = purchaseRequestsStatus === 'loading';
  const partsStatus = useSelector((state) => state.parts.partsFetch.status);
  const partsStatusError = useSelector((state) => state.parts.partsFetch.error);

  const unsortedLocations = useSelector(selectAllLocationObjects);

  const locations = Object.values(unsortedLocations)
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim()));

  useEffect(() => {
    if (props.type === 'Reorder') {
      if (purchaseRequestsStatus === 'idle' || purchaseRequestsStatus === 'failed') {
        dispatch(fetchPurchaseRequests());
      }
      if (purchaseOrdersStatus === 'idle' || purchaseOrdersStatus === 'failed') {
        dispatch(fetchPurchaseOrders());
      }
    }
  }, []);

  // const currLocation = useSelector(selectCurrentUserLocationId);

  const userInfo = useSelector(selectLoggedInUserInfo);

  const allParts = useSelector(
    selectAllParts,
  );

  const reorderData = useSelector(
    selectReorderParts,
  );

  const partsListTableInstance = useRef(null);

  const handleSelectFilterChange = (event, filterId) => {
    if (filterId === 'locationId') {
      props.setPartLocationFilter(event.target.value);
    }
    partsListTableInstance.current.setFilter(filterId, event.target.value);
  };

  const [filtersState, dispatchFilters] = useReducer(filtersReducer, initFilterState);

  const [modalShowCreate, setModalShowCreate] = useState(false);
  const toggleModalCreate = () => setModalShowCreate(!modalShowCreate);
  const hideCreateModal = () => setModalShowCreate(false);

  useEffect(() => {
    if (props.partCreateStatus === 'succeeded' || props.partCreateStatus === 'failed') {
      hideCreateModal();
    }
  }, [props.partCreateStatus]);

  const handleFilterChange = (event, filterId) => {
    partsListTableInstance.current.setFilter(filterId, !filtersState[filterId]);
    dispatchFilters({ type: filterId });
  };

  const setDefaultFilters = () => {
    Object.keys(filtersState).forEach((filterId) => {
      if (filterId !== 'filterOpen' && filtersState[filterId] === true) {
        partsListTableInstance.current.setFilter(filterId, true);
      }
    });
    if (props.partLocationFilter) {
      partsListTableInstance.current.setFilter('locationId', props.partLocationFilter);
    }
  };

  const [selected, setSelectedRows] = useState([]);

  const partsListData = useMemo(() => {
    if (props.type === 'Reorder') {
      return Object.values(reorderData);
    }
    if (props.type === 'All') {
      return [...Object.values(allParts)];
    }
    return null;
  }, [allParts, reorderData]);

  useEffect(() => {
    setDefaultFilters();
  }, []);

  useEffect(() => {
    partsListTableInstance.current.setFilter('locationId', props.partLocationFilter);
  }, [props.partLocationFilter]);

  const { confirm } = useConfirm();

  const navigate = useNavigate();

  const partsListColumns = useMemo(() => {
    const cols = [
      {
        Header: '',
        id: 'actionColumn',
        Cell: (cell) => (
          <div className="d-flex">
            &nbsp;
            <Button
              size="xsm"
              title="Edit"
              disabled={!cell.row.original.isActive}
              onClick={() => {
                navigate(`/maintenance/parts-inventory/${cell.row.original.partId}`);
              }}
            >
              <BsPencilFill />
            </Button>
            &nbsp;
            <Button
              size="xsm"
              disabled={!cell.row.original.isActive}
              variant="danger"
              title="Deactivate"
              onClick={async () => {
                const isConfirmed = await confirm('Are you sure you want to delete this part?');
                if (isConfirmed) {
                  (dispatch(removePart(cell.row.original.partId)));
                }
              }}
            >
              <BsXLg />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Part Name',
        accessor: 'partName',
        filter: 'exactText',
      },
      {
        Header: 'Part Id',
        accessor: 'partId',
        filter: 'exactText',
        Cell: ({ value }) => (<NavLink to={`/maintenance/parts-inventory/${value}`}>{value}</NavLink>),
      },
      {
        Header: 'Global Part Code',
        accessor: 'partCodeGlobal',
        filter: 'exactText',
      },
      {
        Header: 'Local Part Code',
        accessor: 'partCodeLocal',
        filter: 'exactText',
      },
      {
        Header: 'Asset Type',
        id: 'assetTypeId',
        accessor: ({ assetTypeId }) => formatAssetType(assetTypeId),
      },
      {
        Header: 'Location',
        accessor: 'locationId',
        Cell: ({ value }) => unsortedLocations[value]?.LocationName ?? value,
      },
      {
        Header: 'Non-Stock Item',
        accessor: 'isNonStockItem',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? <BsFillCheckCircleFill className="text-success" /> : <BsXCircleFill className="text-danger" />),
      },
      {
        Header: 'Reorder Level',
        accessor: 'reorderLevel',
      },
      {
        Header: 'Minimum Quantity',
        accessor: 'minQuantity',
      },
      {
        Header: 'Maximum Quantity',
        accessor: 'maxQuantity',
      },
      {
        Header: 'Part Description',
        accessor: 'partDescription',
        filter: 'exactText',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
      {
        Header: 'Current Inventory Count',
        accessor: 'quantityOnHand',
      },
      {
        Header: 'Bin Number',
        accessor: 'binNumber',
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? <ActiveIcon /> : <InactiveIcon />),
      },
    ];
    if (props.type === 'Reorder') {
      cols.push({
        Header: 'Status',
        id: 'status',
        accessor: ({ partId }) => {
          if (poLoading || prLoading) return '';
          const reqs = Object.values(purchaseRequests)?.filter((pr) => pr.partId === partId && ![1, 2].includes(pr.purchaseRequestStatusId));
          const orderIds = reqs?.map((r) => r.purchaseOrderId);
          const orders = orderIds?.filter((id) => purchaseOrders[id]).map((id) => purchaseOrders[id]).filter((order) => ![5, 6].includes(order.purchaseOrderStatusId));
          if (orders?.length > 0) {
            return 'On PO';
          }
          if (reqs?.length > 0) {
            return 'On PR';
          }
          return 'New';
        },
        Cell: ({ value, row }) => {
          if (poLoading || prLoading) return 'loading...';
          const { partId } = row.original;
          const reqs = Object.values(purchaseRequests)?.filter((pr) => pr.partId === partId && ![1, 2].includes(pr.purchaseRequestStatusId));
          if (value === 'On PR') {
            return (<NavLink to={`/maintenance/parts-inventory/${row.original.partId}#Request History`}>{value}</NavLink>);
          }
          if (value === 'On PO') {
            const orderIds = reqs?.map((r) => r.purchaseOrderId);
            const orders = orderIds?.filter((id) => purchaseOrders[id]).map((id) => purchaseOrders[id]).filter((order) => ![5, 6].includes(order.purchaseOrderStatusId));
            return (
              <NavLink to={`/maintenance/purchase-order/${orders[0].purchaseOrderId}`}>
                {value}
                {' '}
                {orders[0].estimatedDeliveryDate ? formatDate(orders[0].estimatedDeliveryDate, datePreference) : 'No ETA'}
              </NavLink>
            );
          }
          if (value === '') {
            return 'loading...';
          }
          return 'New';
        },
      });
    }
    return cols;
  }, [purchaseOrders, purchaseRequests, poLoading, prLoading]);

  const requestsAreLoading = [
    partsStatus,
    props.partCreateStatus,
  ].includes('loading');

  const createPurchaseRequestEnabled = selected.length < 1;

  const partsContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            data-cy={`${props.type}-filter-button`}
            variant={filtersState.filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => dispatchFilters({ type: 'filterOpen' })}
          >
            <i className={filtersState.filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={partsListTableInstance} />
          &nbsp;
          <Button
            variant="primary"
            size="sm"
            disabled={createPurchaseRequestEnabled}
            onClick={() => {
              props.setPurchaseRequestModalShow(true);
            }}
          >
            Create Purchase Request

          </Button>
          &nbsp;
          <Button
            variant="primary"
            size="sm"
            onClick={() => setModalShowCreate(true)}
          >
            Create Part

          </Button>
        </InputGroup>
        <Collapse in={filtersState.filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select data-cy={`${props.type}-location-filter`} value={props.partLocationFilter} size="sm" onChange={(e) => { handleSelectFilterChange(e, 'locationId'); }}>
                  <option value="" key="customer-null">-- Location --</option>
                  {locations.map((location, index) => (
                    <option
                      value={location.LocationID}
                      key={`location-${index}`}
                    >
                      {location.LocationName}

                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Show InActive"
                checked={!filtersState.isActive}
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <RowSelectTable
        columns={partsListColumns}
        data={partsListData}
        ref={partsListTableInstance}
        onRowSelect={(rows) => setSelectedRows(rows)}
        rowProps={() => { }}
        initialState={{ hiddenColumns: ['partCodeLocal'] }}
      />
      {partsStatus === 'failed' && <ErrorContent errorMessage={partsStatusError} />}
      {requestsAreLoading && <LoadingContent />}
    </>
  );

  return (
    <>
      <div data-cy={`${props.type}-parts-table`} className={`d-flex flex-column flex-grow-1 h-100${requestsAreLoading ? ' creation-loading' : ''}`}>
        {partsContent}
      </div>
      <PurchaseRequestModal
        show={props.tabIsActive && props.purchaseRequestModalShow}
        onHide={() => props.setPurchaseRequestModalShow(false)}
        data={selected.map((item) => item.original)}
        purchaseRequestCreateStatus={props.purchaseRequestCreateStatus}
      />
      <CreatePartModal
        show={props.tabIsActive && modalShowCreate}
        onHide={() => hideCreateModal()}
        partCreateIsLoading={props.partCreateIsLoading}
      />
    </>
  );
}

export default PartsListTable;
