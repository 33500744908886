import { BsWrench, BsPencil, BsFillPencilFill } from "react-icons/bs";
import { addDaysToDate, formatDate } from "../../../../services/format";
import { LogicalConnector } from "../../../UI/view/data-source";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../../UI/view/list-view";
import { assetRoute } from "../../WorldMap/worldmap-list";
import { lateReadingsDataSource } from "./late-readings-ds";
import { getMaintenanceRequestTypesMemoized, postMaintenanceRequest } from "../../../../services/requests";
import { CreateMRRequestForm, CreateMRRequestFormRepo, EditMRRequestForm, EditMRRequestFormRepo, creatMRRequest } from "./late-readings-mr-create";


//"accessor": "ReadingDate < \"1/1/2024\"",
//containerid, lastreadingdate, assettyp
export const LateReadingsListView = {
  permissions: {
    read: [1, 19],
    write: [1, 19],
    admin: [1, 19],
  },
  additionalModals: [
    {
      form: CreateMRRequestForm,
      repository: CreateMRRequestFormRepo,
      name: 'createMR',
      title: 'Add Maintenance Request',
      defaults: async (row) => {
        const types = await getMaintenanceRequestTypesMemoized();
        const assetType = row.original.assetType;
        const assetTypeId = assetType == 'Gas' ? 1 : assetType == 'Liquid' ? 2 : 4;
        const theType = types.find(t => t.assetTypeId == assetTypeId && t.name == 'Tracker Issue');
        return ({
          assetSerialNumber: row.original.containerId,
          assetType: row.original.assetType,
          assetTypeId: assetTypeId,
          requestType: 'Tracker Issue',
          maintenanceRequestTypeId: theType.maintenanceRequestTypeId
        });
      }
    },
    {
      form: EditMRRequestForm,
      repository: EditMRRequestFormRepo,
      name: 'editMR',
      title: 'Edit Maintenance Request',
      defaults: async (row) => {
        return {
          ...row.original.maintenanceRequest,
          assetType: row.original.assetType,
          requestType: 'Tracker Issue',
        }
      }
    }
  ],
  filters: [
    {
      type: FilterType.number,
      fieldName: 'lastReadingDate',
      label: 'Days Since Last Reading',
      filterValue: 45,
      maxValue: 180,
      minValue: 1,
      serverExpression: '(readingDate == null || readingDate < @val)',
      valExpression: (val) => {
        return '"' + formatDate(addDaysToDate(new Date(), -val)) + '"';
      },
      logicalConnector: LogicalConnector.And
    },
    {
      type: FilterType.multiselect,
      label: 'Asset Type',
      fieldName: 'assetType',
      options: (state) => ['Liquid', 'Gas', 'Chassis'],
      filterValue: { 'Liquid': true, 'Gas': true, 'Chassis': true },
      logicalConnector: LogicalConnector.And
    },
    {
      type: FilterType.simple,
      fieldName: 'hasMr',
      label: 'Hide MR Created',
      filterValue: true,
      serverExpression: 'maintenanceRequestId == null',
      logicalConnector: LogicalConnector.And
    },
  ],
  columns: [
    {
      type: ListViewColumnType.button,
      accessor: 'newMR',
/*       disabled: (row, { maintenanceInfo }) => {
        if (!maintenanceInfo) return true;
        const metrics = maintenanceInfo[row.original.containerId];
        if (!metrics) return false;
        return metrics.maintenanceRequestId;
      }, */
      excludeGlobalFilter: true,
      icon: (row) => {
        const mr = row.original.maintenanceRequestId;
        const wo = row.original.workOrderId;
        if (wo) {
          return BsFillPencilFill;
        } else if (mr) {
          return BsFillPencilFill;
        } else {
          return BsWrench;
        }
      },
      variant: (row) => {
        const mr = row.original.maintenanceRequestId;
        const wo = row.original.workOrderId;
        if (wo) {
          return 'primary';
        } else if (mr) {
          return 'primary';
        } else {
          return 'danger';
        }
      },
      tooltip: 'Maintenance Request',
      modal: (row) => {
        const mr = row.original.maintenanceRequestId;
        const wo = row.original.workOrderId;
        if (wo) {
          return 'editMR';
        } else if (mr) {
          return 'editMR';
        } else {
          return 'createMR';
        }
      }
    },
    {
      accessor: 'containerId',
      Header: 'Unit Number',
      type: ListViewColumnType.link,
      linkPath: ({ val, row }) => `/assets/${assetRoute(row.original.assetType)}/${row.original.containerId}`
    },
    {
      accessor: 'iotId',
      Header: 'IOT Id',
    },
    {
      accessor: 'workOrderId',
      Header: 'Work Order',

      type: ListViewColumnType.link,
      linkPath: ({ val, row }) => {
        return `/maintenance/work-orders/${row.original.workOrderId}`
      },
    },
    {
      accessor: 'maintenanceRequestId',

      Header: 'Maintenance Request',
    },
    {
      accessor: 'assetType',
      Header: 'Asset Type'
    },
    {
      accessor: 'readingDate',
      Header: 'Last Reading Date',
      excludeGlobalFilter: true,
      defaultSort: true,
      defaultSortDirection: 'asc',
      type: ListViewColumnType.datetime
    },
  ]
} as ListView;

export const LateReadingsListComponent = createConnectedListView(LateReadingsListView, lateReadingsDataSource);