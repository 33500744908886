/* eslint-disable max-len */
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Collapse,
  Form,
  InputGroup,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { assetSort, exportData, sortByBoolean } from '../../../services/table-helpers';
import { convertToPreferred, getPreferredUnit } from '../../../services/user-preferences';
import { selectIsAssetAdmin, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectPackagedProductTypes, selectPackagedProductTypesObject } from '../../../store/slices/legacy-slice';
import { fetchPackagedProducts, selectPackagedProducts } from '../../../store/slices/packaged-products-slice';
import ActiveIcon from '../../UI/atoms/ActiveIcon/active-icon';
import InactiveIcon from '../../UI/atoms/InactiveIcon/inactive-Icon';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

const hiddenColumns = [
  'packagedProductTypTextSearch',
];

function PackagedProductsTable(props) {
  const packagedProducts = useSelector(selectPackagedProducts);
  const packagedProductStatus = useSelector(
    (state) => state.packagedProduct.packagedProductsFetch.status,
  );
  const packagedProductError = useSelector(
    (state) => state.packagedProduct.packagedProductsFetch.error,
  );

  const packagedProductTypesArr = useSelector(selectPackagedProductTypes);
  const packagedProductTypes = useSelector(selectPackagedProductTypesObject);
  const { measurementPreference } = useSelector(selectLoggedInUserPreferences);
  // Configure Table References
  const packagedProductsTableInstance = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleFilterChange = (event, filterId) => {
    packagedProductsTableInstance.current.setFilter(filterId, event.target.checked);
  };

  const handleSelectFilterChange = (event, filterId) => {
    packagedProductsTableInstance.current.setFilter(filterId, event.target.value);
  };

  const packagedProductsColumns = useMemo(
    () => [
      {
        Header: 'Unit Number',
        accessor: 'serialNumber',
        minWidth: 100,
        width: 100,
        maxWidth: 100,
        Cell: ({ value, row }) => (<NavLink to={`/assets/packaged-product/${row.original.id}`}>{value}</NavLink>),

      },
      {
        Header: 'Type',
        id: 'packagedProductType',
        filter: 'exactText',
        accessor: ({ packagedProductType: value }) => {
          const notNull = !(value == null);
          if (notNull && packagedProductTypes[value.toString()]?.ProductNumber) {
            return packagedProductTypes[value.toString()]?.ProductNumber;
          }
          if (notNull) {
            return `Unknown ID: ${value}`;
          }
          return null;
        },
      },
      {
        Header: `Tare Weight (${getPreferredUnit('lbs', measurementPreference)})`,
        accessor: 'tareWeight',
        Cell: ({ value }) => (value == null ? null : `${convertToPreferred('lbs', measurementPreference, value)}`),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        disableSortBy: true,
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
      {
        Header: 'C/O',
        accessor: 'isCustomerOwned',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? <ActiveIcon /> : <InactiveIcon />),
      },
      {
        Header: 'Lost/Destroyed',
        accessor: 'isLostOrDestroyed',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
    ],
    [packagedProductTypes, measurementPreference],
  );

  const packagedProductsData = useMemo(() => {
    if (packagedProducts) {
      return Object.values(packagedProducts).sort(assetSort);
    }

    return [];
  }, [packagedProducts]);

  const loadData = useCallback(async () => {
    if (packagedProductStatus === 'idle' || packagedProductStatus === 'failed') {
      dispatch(fetchPackagedProducts());
    }
  }, [packagedProductStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const handleRowClick = (packagedProductId) => {
    // Leveraging two methods for passing data to a new page for examples:
    // 1. State / useLocation
    // 2. uri path / useParam
    navigate(`/assets/packaged-product/${packagedProductId}`);
  };

  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  const onExport = () => {
    exportData('packagedproduct', packagedProductsData, packagedProductsColumns);
  };

  const packagedProductsContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={packagedProductsTableInstance} />
          <Button variant="primary" size="sm" onClick={onExport}><i className="bi bi-download" /></Button>
          {
            isAssetAdmin
            && (
            <>
             &nbsp;
              <Button variant="primary" size="sm" onClick={props.handleModalShow}>Add Packaged Product</Button>
            </>
            )
          }

        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'packagedProductType'); }}>
                  <option value="">-- Type --</option>
                  {packagedProductTypesArr.map((type, i) => (
                    <option key={`packagedProductType-${type.id}`}>{type.ProductNumber}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Check
                className="mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Active"
                defaultChecked
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
              <Form.Check
                type="switch"
                className="ms-1 mb-1"
                inline
                id="isCustomerOwned-switch"
                label="C/O"
                onChange={(e) => handleFilterChange(e, 'isCustomerOwned')}
              />
              <Form.Check
                type="switch"
                className="ms-1 mb-1"
                inline
                id="isLostOrDestroyed-switch"
                label="Lost/Dest."
                onChange={(e) => handleFilterChange(e, 'isLostOrDestroyed')}
              />
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={packagedProductsColumns}
        data={packagedProductsData}
        ref={packagedProductsTableInstance}
        initialState={{ hiddenColumns, filters: [{ id: 'isActive', value: true }] }}
        rowProps={() => ({})}
      />
    </>
  );

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${packagedProductStatus === 'loading' ? ' creation-loading' : ''}`}>
        {packagedProductsContent}
        {packagedProductStatus === 'failed' && <ErrorContent errorMessage={packagedProductError} />}
      </div>
      {packagedProductStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default PackagedProductsTable;
