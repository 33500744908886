import { FormItemType } from "../../UI/view/component-map";
import { FormView } from "../../UI/view/form-view";
import { createConnectedListView2, FilterType, ListView, ListViewColumnType } from "../../UI/view/list-view";
import { createConnectedView, createConnectedView2 } from "../../UI/view/view";
import { alarmConfigDatasource } from "./alarm-config-datasource";
import { alarmPermissions } from "./alarm-permissions";




export const alarmConfigView = {
  permissions: alarmPermissions,
  activeFlag: 'isActive',
/*   filters: [
    {
      type: FilterType.combo,
      label: 'Alarm Type',
      fieldName: 'ruleTypeName',
      filterValue: 'Level',
      options: () => ['Level', 'Time', 'Geofence']
    },

  ], */
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      accessor: 'dAlarmLevelDeviceType.name',
      Header: 'Device Type',
    },
    {
      accessor: 'rule',
      Header: 'Rule',
      defaultSort: true,
    },
    {
      accessor: 'frequency',
      Header: 'Notify Frequency',

    },
    {
      accessor: 'acknowledgable',
      Header: 'Acknowledgable',
      type: ListViewColumnType.yesno,
    },
    {
      accessor: 'ruleTypeName',
      hidden: true,
    }
  ],

} as ListView;

export const [AlarmConfigView, AlarmConfigViewRepo] = createConnectedListView2(alarmConfigView, alarmConfigDatasource, state => state.alarmConfig.data.filter(x => x.ruleTypeName == 'Level'));


export const alarmRuleTimeView = {
  permissions: alarmPermissions,
  activeFlag: 'isActive',
/*   filters: [
    {
      type: FilterType.combo,
      label: 'Alarm Type',
      fieldName: 'ruleTypeName',
      filterValue: 'Time',
      options: () => ['Level', 'Time', 'Geofence']
    },

  ], */
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      accessor: 'dAlarmTimeDeviceType.name',
      Header: 'Device Type',
    },
    {
      accessor: 'dAlarmTimeType.name',
      Header: 'Type',
    },
    {
      Header: 'Duration',
      accessor: 'dAlarmRuleDuration.description',
    },
    {
      Header: 'Frequency',
      accessor: 'dAlarmRuleFrequency.description',
    },
    {
      Header: 'Acknowledgeable',
      accessor: 'isAcknowledge',
      type: ListViewColumnType.yesno,
    },
/*     {
      Header: 'Notififcations',
      accessor: 'notifyFrequency
    } */


    {
      accessor: 'ruleTypeName',
      hidden: true,
    }

  ],

} as ListView;

export const [AlarmRuleTimeView, AlarmRuleTimeViewRepo] = createConnectedListView2(alarmRuleTimeView, alarmConfigDatasource, state => state.alarmConfig.data.filter(x => x.ruleTypeName == 'Time'));



export const alarmRuleGeofenceView = {
  permissions: alarmPermissions,
  activeFlag: 'isActive',
/*   filters: [
    {
      type: FilterType.combo,
      label: 'Alarm Type',
      fieldName: 'ruleTypeName',
      filterValue: 'Geofence',
      options: () => ['Level', 'Time', 'Geofence']
    },

  ], */
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      accessor: 'dAlarmGeofenceFeatureType.name',
      Header: 'Feature',
    },
    {
      accessor: 'dAlarmGeofenceType.name',
      Header: 'Type',
    },
    {
      Header: 'Duration',
      accessor: ({ geofenceDuration }) => geofenceDuration ? (geofenceDuration / (60 * 60 * 24)) : null,
    },
    {
      Header: 'Frequency',
      accessor: 'dAlarmRuleFrequency.description',
    },
    {
      Header: 'Acknowledgeable',
      accessor: 'isAcknowledge',
      type: ListViewColumnType.yesno,
    },




    {
      accessor: 'ruleTypeName',
      hidden: true,
    }

  ],

} as ListView;

export const [AlarmRuleGeofenceView, AlarmRuleGeofenceViewRepo] = createConnectedListView2(alarmRuleGeofenceView, alarmConfigDatasource, state => state.alarmConfig.data.filter(x => x.ruleTypeName == 'Geofence'));


export const alarmConfigMain = {
  editMode: false,
  pageHeader: 'Alarm Configuration',
  permissions: alarmPermissions,
  breadcrumbs: [
    {
      name: 'Alarm Configuration',
      icon: 'alarm',
      to: '/billing/dewarproduct',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Level Rules',
          items: [
            {
              type: FormItemType.customComponent,
              component: AlarmConfigView,
            }
          ]
        },
        {
          label: 'Time Rules',
          items: [
            {
              type: FormItemType.customComponent,
              component: AlarmRuleTimeView,
            }
          ]
        },
        {
          label: 'Geofence Rules',
          items: [
            {
              type: FormItemType.customComponent,
              component: AlarmRuleGeofenceView,
            }
          ]
        },

      ]
    }
  ]
} as FormView;

export const [AlarmConfigMain, AlarmConfigMainRepo] = createConnectedView2({ form: alarmConfigMain, dataSource: alarmConfigDatasource });