import React from 'react';

function Footer(props) {
  const year = new Date().getFullYear();
  return (
    <footer className="main-footer">
      <strong>
        &copy;
        {year}
        {' '}
        - Linde
        <sup>3</sup>
        He
      </strong>
      {' '}
      All rights reserved.
      <div className="float-right d-none d-sm-inline-block BulinEasterEgg">
        <b>Thomas Bulin Memorial</b>
      </div>
    </footer>
  );
}

export default Footer;
