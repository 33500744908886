import React, { useRef, useState } from 'react';
import {
  Badge,
  Button,
  Col,
  Container,
  OverlayTrigger,
  Popover,
  Row,
} from 'react-bootstrap';
import { BsFillInfoCircleFill } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import { datePreferenceOptions, formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import { selectRoles } from '../../../../store/slices/roles-slice';
import { selectUsers, selectUsersByOid } from '../../../../store/slices/users-slice';
import { chassisLength, chassisTypes } from '../../../pages/Chassis/chassis-values';
import {
  liquidContainerMawps, liquidContainerRegulatoryDocuments,
  liquidContainerShieldTypes,
  liquidContainerSpecialPermits,
  liquidContainerStyleTypes, liquidContainerTypes,
} from '../../../pages/LiquidContainer/liquid-container-values';

function ChangesPopover(props) {
  const target = useRef(null);

  const [active, setActive] = useState(false);

  const tooltipExclues = [
    'DateLastEdited',
    'Modified',
    'ModifiedOn',
    'ModifiedBy',
    'Created',
    'CreatedBy',
    'UserIDInserted',
    'UserIDlastEdited',
    'UserIDinserted',
    'UserIDLastEdited',
    'DateLastEdited',
  ];

  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);

  const roles = useSelector(selectRoles);
  const users = useSelector(selectUsers);
  const usersByOid = useSelector(selectUsersByOid);

  const displayValue = (value, colName, tableName) => {
    try {
      if (colName === 'RoleId') {
        return roles[value].name;
      }
      if (colName === 'UserId') {
        return users[value].displayName;
      }
      if (colName === 'DatePreference') {
        return datePreferenceOptions[value];
      }
      if (colName === 'ShieldStyle') {
        return liquidContainerShieldTypes[value];
      }
      if (colName === 'ContainerMAWP') {
        return liquidContainerMawps[value];
      }
      if (colName === 'ContainerStyle') {
        return liquidContainerStyleTypes[value];
      }
      if (colName === 'ContainerTypeCode') {
        return liquidContainerTypes[value];
      }
      if (colName === 'ContainerType') {
        return liquidContainerRegulatoryDocuments[value];
      }
      if (colName === 'ExemptNumber') {
        return liquidContainerSpecialPermits[value];
      }
      if (colName === 'Style') {
        return value;
      }
      if (tableName === 'LiquidChassis') {
        if (colName === 'Length') {
          return chassisLength[value];
        }
        if (colName === 'Type') {
          return chassisTypes[value];
        }
      }

      if (colName.toLowerCase().includes('date') && colName.toLowerCase() !== 'isignorecscdates') {
        return formatDate(value.toString(), datePreference);
      }
      if (typeof value === 'object' && !Array.isArray(value) && value !== null) {
        try {
          return Object.keys(value).map((key, index) => {
            return <p key={`key-${key}-${index}`} className="m-0 p-0 text-start">{`${key}: ${value[key]}`}</p>;
          });
        } catch (err) {
          return value.toString();
        }
      }
      return value.toString();
    } catch (e) {
      console.error(e.toString());
      return 'Error displaying value';
    }
  };

  const unset = (<p className="font-italic m-0">Unset</p>);

  const renderTooltip = (row) => {
    let {
      oldValues,
      newValues,
      changedColumns,
    } = row.original;

    oldValues = JSON.parse(oldValues ?? '{}');
    newValues = JSON.parse(newValues ?? '{}');
    changedColumns = JSON.parse(changedColumns);

    const filterdColumns = changedColumns.filter((colName) => !tooltipExclues.includes(colName));
    const filteredContent = filterdColumns.map((colName) => (
      <React.Fragment
        key={`audit-${row.original.auditId}-${colName}`}
      >
        <Row className="fw-bold"><Col>{colName}</Col></Row>
        <Row className="d-flex justify-content-between mb-1">
          <Col className="d-flex justify-content-start">
            <div>
              <Badge bg="danger">
                { oldValues[colName] == null ? unset
                  : displayValue(oldValues[colName], colName, row.original.tableName) }
              </Badge>
            </div>
          </Col>
          <Col className="d-flex justify-content-center">
            <i className="bi-arrow-right" />
          </Col>
          <Col className="d-flex justify-content-end">
            <div>
              <Badge bg="success">
                { newValues[colName] == null ? unset
                  : displayValue(newValues[colName], colName, row.original.tableName)}
              </Badge>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    ));

    const changedContent = filterdColumns.length > 0 ? filteredContent : (
      <p className="m-0 p-0">Nothing to display</p>);

    return (
      <Popover>
        <Popover.Header>
          Changes
        </Popover.Header>
        <Popover.Body className="overflow-auto" style={{ maxHeight: 250 }}>
          <Container fluid>
            {changedContent}
          </Container>
        </Popover.Body>
      </Popover>
    );
  };
  return (
    <OverlayTrigger
      trigger="click"
      placement="left"
      rootClose
      onExit={() => setActive(false)}
      onEnter={() => setActive(true)}
      overlay={renderTooltip(props.row)}
    >
      <Button
        size="xsm"
        variant={active ? 'secondary' : 'outline-secondary'}
        ref={target}
      >
        <BsFillInfoCircleFill />
      </Button>
    </OverlayTrigger>
  );
}

export default ChangesPopover;
