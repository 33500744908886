import React, { useMemo } from 'react';
import {
  Button, Col, Container, Form, Modal,
  Row, Spinner,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { createGasContainer } from '../../../store/slices/gas-containers-slice';
import BasicInput from '../../UI/molecules/BasicInput/basic-input';
import { gasContainerBusiness, gasContainerStyles, gasContainerTubeTypes } from './gas-container-values';

function CreateGasContainerForm({ onHide, gasContainerStatusIsLoading }) {
  const defaultGasContainerFormValues = {
    AssetType: 'GasContainer',
    serialNumber: '',
    business: null,
    style: null,
    lastTestDate: null,
    tareWeight: null,
    numberOfTubes: null,
    ownerCustomer: null,
    comments: null,
    waterVolume: null,
    nominalCapacity: null,
    press110: null,
    press70: null,
    length: null,
    isIsoFrame: false,
    isActive: null,
    tubeType: null,
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    setError,
    clearErrors,
    formState: { errors },
    trigger,
    getFieldState,
  } = useForm({
    defaultValues: defaultGasContainerFormValues,
  });

  const dispatch = useDispatch();

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const onSubmit = (gasContainer) => {
    Object.keys(gasContainer).forEach((key) => {
      if (key.toLowerCase().includes('date') && gasContainer[key]) {
        gasContainer[key] = convertDateToUTCDay(gasContainer[key]);
      }
    });
    dispatch(createGasContainer({ ...gasContainer, assetType: 'GasContainer' }));
  };

  const basicInputProps = {
    setValue,
    watch,
    getFieldState,
    register,
    trigger,
  };

  const gasCustomers = useSelector(((state) => state.legacy?.dropdownValues?.gasCustomers));
  const activeCustomers = useMemo(() => {
    return [...gasCustomers]
      .sort((a, b) => a.CustomerName.trim().localeCompare(b.CustomerName.trim()))
      .filter((cust) => cust.Status === 'Active');
  }, [gasCustomers]);

  return (
    <>
      <Modal.Body>
        <Form className="bg-white h-100 overflow-auto">
          <Spinner
            animation="border"
            variant="primary"
            className={`create-spinner${gasContainerStatusIsLoading ? ' visible' : ' invisible'}`}
          />
          <Container
            fluid
            className={`${gasContainerStatusIsLoading ? ' creation-loading' : ''}`}
          >
            <Container className="p-0 m-0">
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Unit Number</Form.Label>
                    <Form.Control
                      size="sm"
                      {...register('serialNumber', { required: 'Please enter a serial number.', maxLength: { message: 'Unit ID cannot exceed 12 characters', value: 12 } })}
                      isInvalid={!!errors.serialNumber}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.serialNumber && errors.serialNumber.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Owner</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('ownerCustomer', { required: 'Please select an owner.' })}
                      isInvalid={!!errors.ownerCustomer}
                    >
                      <option
                        value={null}
                        key="customer-null"
                      />
                      {activeCustomers
                        .map((customer, index) => (
                          <option value={customer.CustomerID} key={`customer-${index}`}>{customer.CustomerName}</option>
                        ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.ownerCustomer && errors.ownerCustomer.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Business</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('business', { required: 'Please select a business.' })}
                      isInvalid={!!errors.business}
                    >
                      {gasContainerBusiness.map((style, i) => (
                        <option
                          value={style}
                          key={style}
                        >
                          {style}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.business && errors.business.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Style</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('style', { required: 'Please select a style.' })}
                      isInvalid={!!errors.style}
                    >
                      {gasContainerStyles.map((style, i) => (
                        <option
                          value={style}
                          key={style}
                        >
                          {style}
                        </option>
                      ))}
                    </Form.Select>
                    {/* <Form.Control.Feedback type="invalid">
                      {errors.style && errors.style.message}
                    </Form.Control.Feedback> */}
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Tube Type</Form.Label>
                    <Form.Select
                      size="sm"
                      {...register('tubeType')}
                      isInvalid={!!errors.tubeType}
                    >
                      {gasContainerTubeTypes.map((tubeType, i) => (
                        <option
                          value={i}
                          key={tubeType}
                        >
                          {tubeType}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Number of Tubes</Form.Label>
                    <Form.Control
                      size="sm"
                      {...register(
                        'numberOfTubes',
                        {
                          required: 'Please select number of tubes.',
                          minLength: { value: 1, message: 'Enter tubes' },
                          min: { value: 4, message: 'must be between 4 and 100' },
                          max: { value: 100, message: 'must be between 4 and 100' },
                        },
                      )}
                      isInvalid={!!errors.numberOfTubes}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.numberOfTubes && errors.numberOfTubes.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Length</Form.Label>
                    <Form.Control
                      size="sm"
                      {...register('length')}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Tare Weight</Form.Label>
                    <BasicInput
                      name="tareWeight"
                      defaultUnit="lbs"
                      options={['lbs', 'kgs']}
                      isInvalid={!!errors.tareWeight}
                      createMode
                      {...basicInputProps}
                      valueIsLoaded
                      initialValue={null}
                      registerOptions={{ min: { value: 0, message: 'must be greater than zero' } }}
                    />
                    {errors.tareWeight && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block"
                    >
                      {errors.tareWeight.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <div
                    style={{ minHeight: 80 }}
                    className="d-flex align-items-center"
                  >
                    <Form.Group className="mb-1">
                      <Form.Check
                        {...register('isIsoFrame')}
                        type="switch"
                        inline
                        id="isIsoFrame-switch"
                        label="ISO Frame"
                      />
                    </Form.Group>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Water Volume</Form.Label>
                    <BasicInput
                      name="waterVolume"
                      defaultUnit="Cubic Feet"
                      options={['Cubic Feet', 'Cubic Meters', 'Liters']}
                      isInvalid={!!errors.waterVolume}
                      createMode
                      {...basicInputProps}
                      registerOptions={{
                        required: 'Please enter a valid water volume',
                        min: { value: 200, message: 'must be between 200 and 4000 Cubic Meters' },
                        max: { value: 4000, message: 'must be between 200 and 4000 Cubic Meter' },
                      }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.waterVolume && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block"
                    >
                      {errors.waterVolume.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Nominal Capacity</Form.Label>
                    {/* Should this be a basic input from control element? */}
                    <Form.Control
                      size="sm"
                      type="number"
                      min="0"
                      {...register(
                        'nominalCapacity',
                        {
                          required: 'Please enter a Nominal Capacity',
                          min: { value: 35000, message: 'must be between 35000 and 700000' },
                          max: { value: 700000, message: 'must be between 35000 and 700000' },
                          pattern: {
                            value: /^[0-9]*$/,
                            message: 'Value must be a number',
                          },
                        },
                      )}
                      isInvalid={!!errors.nominalCapacity}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.nominalCapacity && errors.nominalCapacity.message}
                    </Form.Control.Feedback>
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-1">
                    {/* Saved as an int in the DB so value
                    will change depending on users settings */}
                    <Form.Label>Pressure 70</Form.Label>
                    <BasicInput
                      name="press70"
                      defaultUnit="psig"
                      options={['psig', 'Pa']}
                      isInvalid={!!errors.press70}
                      {...basicInputProps}
                      createMode
                      registerOptions={{
                        required: 'Please enter a Pressure 70',
                        min: { value: 2000, message: 'must be between 2000 and 5000 psig' },
                        max: { value: 5000, message: 'must be between 2000 and 5000 psig' },
                      }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.press70 && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block"
                    >
                      {errors.press70.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-1">
                    <Form.Label>Pressure 110</Form.Label>
                    {/* Saved as an int in the DB so value
                    will change depending on users settings */}
                    <BasicInput
                      name="press110"
                      defaultUnit="psig"
                      options={['psig', 'Pa']}
                      isInvalid={!!errors.press110}
                      createMode
                      {...basicInputProps}
                      registerOptions={{
                        required: 'Please enter a Pressure 110',
                        min: { value: 2000, message: 'must be between 2000 and 5000 psig' },
                        max: { value: 5000, message: 'must be between 2000 and 5000 psig' },
                      }}
                      valueIsLoaded
                      initialValue={null}
                    />
                    {errors.press110 && (
                    <Form.Control.Feedback
                      type="invalid"
                      className="d-block"
                    >
                      {errors.press110.message}
                    </Form.Control.Feedback>
                    )}
                  </Form.Group>
                </Col>
              </Row>

              <Form.Group>
                <Form.Label>Comments</Form.Label>
                <Form.Control
                  size="sm"
                  as="textarea"
                  rows={3}
                  {...register('comments')}
                  style={{ resize: 'none' }}
                />
              </Form.Group>

            </Container>

          </Container>

        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={onHide} variant="secondary">Close</Button>
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
          disabled={gasContainerStatusIsLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Submit

        </Button>
      </Modal.Footer>
    </>
  );
}

export default CreateGasContainerForm;
