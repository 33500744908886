import React from "react"
import { Form, Row, Col } from "react-bootstrap";
import { useSelector } from "react-redux";

export const MultiSelectFilter = ({ handleFilterChange, selector, filter, filtersState }) => {

  const options = useSelector(selector) as any[];
  const selected = filtersState[filter.fieldName] ?? options;

  const getNewCollection = (option) => {
    return {...selected, [option]: !selected[option]};
/*     if(selected[option]) {
      selected.filter(o => o !== option);
    }
    return [...selected, option]; */
  }

  return (
    <div style={{display: 'flex'}}>
      {
        options.map((option, key) => (
          <Form.Check
            key={key}
            type="switch"
            inline
            label={filter.labels ? filter.labels[option] : option}
            checked={selected[option]}
            onChange={(e) => handleFilterChange(getNewCollection(option), filter.fieldName)}
          />
        ))
      }
    </div>
  )
}