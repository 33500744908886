import { getLocations, insertLocation, updateLocation } from "../../../services/requests";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { locationsCreate } from "./locations-create";
import { locationsDataSource, locationsPermissions } from "./locations-data-source";

export const locationsList = {
  enableExport: true,
  pageHeader: 'Locations',
  create: locationsCreate,
  variant: 'card',
  permissions: locationsPermissions,
  filters: [
    {
      type: FilterType.simple,
      label: 'Active',
      fieldName: 'active',
      filterValue: true
    },
  ],
  breadcrumbs: [
    {
      name: 'Locations',
      icon: 'building',
      to: '/locations',
    },
  ],
  columns: [
    {
      accessor: 'locationId',
      Header: 'Location ID',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ value, row }) => `/locations/edit/${value}`
    },
    {
      accessor: 'locationName',
      Header: 'Name',
      defaultSort: true,
    },
    {
      accessor: 'addressName',
      Header: 'Address Name',
    },
    {
      accessor: ({ isLiquidLocation }) => isLiquidLocation ? 'Yes' : 'No',
      id: 'isLiquidLocation',
      Header: 'Liquid Location',
    },
    {
      Header: 'Gas Location',
      accessor: ({ isGasLocation }) => isGasLocation ? 'Yes' : 'No',
    },
    {
      accessor: 'active',
      Header: 'active',
      hidden: true,
    },
  ]

} as ListView

export const LocationListView = createConnectedListView(locationsList, locationsDataSource);