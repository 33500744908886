// @ts-ignore
export const sum = function(list, prop?: string) {
  const s = list.reduce((acc, current) => {
    let curVal = (prop ? current[prop] : current);
    let v = isNaN((curVal ?? 0)) ? 0 : (curVal ?? 0);
    return acc + v;
  }, 0);
  return s;
};
// @ts-ignore
export const average = function(list, prop) {
  const sum = list.reduce((acc, current) => acc + current[prop], 0)
  const avg = sum / list.length;
  return avg;
};

const isPropValuesEqual = (subject, target, propNames) =>
  propNames.every(propName => subject[propName] === target[propName]);

export const getUniqueItemsByProperties = (items, propNames) => 
  items.filter((item, index, array) =>
    index === array.findIndex(foundItem => isPropValuesEqual(foundItem, item, propNames))
  );
/* const average = array => array.reduce((a, b) => a + b) / array.length; */

export function sortArray({ data, key, sortingOrder }) {
  return data.sort((a, b) => {
    const firstValue = typeof a[key] === 'string' ? a[key]?.toLowerCase() : a[key];
    const secondValue = typeof b[key] === 'string' ? b[key]?.toLowerCase() : b[key];

    if (firstValue < secondValue) {
      return sortingOrder === 'asc' ? -1 : 1;
    }
    if (firstValue > secondValue) {
      return sortingOrder === 'asc' ? 1 : -1;
    }
    return 0;
  });
}
// @ts-ignore
Array.prototype.orderBy = function(primary, secondary) {
  const compare = (a, b, selector, direction) => {
    const valueA = selector(a);
    const valueB = selector(b);
    const compareResult = (typeof valueA === 'string' && typeof valueB === 'string')
      ? valueA.toLowerCase().localeCompare(valueB.toLowerCase())
      : valueA > valueB ? 1 : valueA < valueB ? -1 : 0;
    return direction === 'desc' ? -compareResult : compareResult;
  };

  return this.sort((a, b) => {
    const primaryComparison = compare(a, b, primary.selector, primary.direction || 'asc');
    if (primaryComparison !== 0) return primaryComparison;
    if (secondary) {
      return compare(a, b, secondary.selector, secondary.direction || 'asc');
    }
    return 0;
  });
};

