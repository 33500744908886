import React, { useState } from 'react';
import {
  Card, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';

function PurchaseOrderDetailsTabs(props) {
  const [activeTab, setActiveTab] = useState('General Info');

  const {
    generalInfoContents, maintenanceRequestContents,
    outstandingRequestContents, auditLogContent, shipmentsContent,
  } = props;

  return (
    <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
      <TabContainer defaultActiveKey="General Info" onSelect={(tab) => setActiveTab(tab)}>
        <div className="card-header p-0 pt-1">
          <Nav variant="tabs">
            <Nav.Item>
              <Nav.Link eventKey="General Info">General Info</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Requests on Purchase Order">Requests on Purchase Order</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Outstanding Requests">Outstanding Requests</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Shipments">Shipments</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="Audit Logs">Audit Logs</Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <div className="card-body d-flex p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
          <TabContent className="d-flex flex-grow-1">
            <TabPane eventKey="General Info" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {generalInfoContents}
              </div>
            </TabPane>
            <TabPane eventKey="Requests on Purchase Order" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {maintenanceRequestContents}
              </div>
            </TabPane>
            <TabPane eventKey="Outstanding Requests" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {outstandingRequestContents}
              </div>
            </TabPane>
            <TabPane eventKey="Shipments" className="flex-grow-1 w-100">
              <div className="d-flex flex-grow-1 flex-column h-100">
                {shipmentsContent}
              </div>
            </TabPane>
            <TabPane eventKey="Audit Logs" className="flex-grow-1 w-100" mountOnEnter unmountOnExit>
              <div className="d-flex flex-grow-1 flex-column h-100">
                {auditLogContent}
              </div>
            </TabPane>
          </TabContent>
        </div>
      </TabContainer>
    </Card>
  );
}

export default PurchaseOrderDetailsTabs;
