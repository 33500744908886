import React, {
  useCallback, useEffect, useMemo, useRef,
  useState,
} from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BsPencilFill, BsXLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../services/format';
import { plannedMaintenanceRequestFrequency } from '../../../services/maintenance-helpers';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  fetchMaintenanceRequestTypes,
  removeMaintenanceRequestType,
  selectCurrentMaintenanceRequestTypeData,
  selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  selectPlannedChassisMaintenanceRequestTypes, selectPlannedGasMaintenanceRequestTypes,
  selectPlannedLiquidMaintenanceRequestTypes, selectUnplannedChassisMaintenanceRequestTypes,
  selectUnplannedGasMaintenanceRequestTypes, selectUnplannedLiquidMaintenanceRequestTypes,
  setCurrentMaintenanceRequestTypeId,
  setCurrentMaintenanceRequestTypeIdForTemplateEdit,
  setFetchMaintenanceRequestTypeTemplateStatus,
} from '../../../store/slices/maintenance-requests-slice';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import CustomModal from '../../UI/molecules/Modal/modal';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import RequestTypeModal from './requests-type-modal';

function RequestsListTable(props) {
  const dispatch = useDispatch();
  const maintenanceRequestStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestTypesFetch.status,
  );

  const tableInstance = useRef(null);

  const [modalShowPlanned, setModalShowPlanned] = useState(false);
  const handleModalClosePlanned = () => {
    setModalShowPlanned(false);
    dispatch(setCurrentMaintenanceRequestTypeId(null));
  };
  const handleModalShowPlanned = () => setModalShowPlanned(true);

  const currentMaintenanceRequestType = useSelector(selectCurrentMaintenanceRequestTypeData);

  const currentMaintenanceRequestTypeIdForTemplateEdit = useSelector(
    selectCurrentMaintenanceRequestTypeIdForTemplateEdit,
  );

  const [modalShowUnplanned, setModalShowUnplanned] = useState(false);
  const handleModalCloseUnplanned = () => {
    setModalShowUnplanned(false);
    dispatch(setCurrentMaintenanceRequestTypeId(null));
  };
  const handleModalShowUnplanned = () => setModalShowUnplanned(true);

  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);

  const requestTypeSelector = useMemo(() => {
    if (props.assetType === 'LiquidContainer' && props.planned) {
      return selectPlannedLiquidMaintenanceRequestTypes;
    } if (props.assetType === 'LiquidContainer' && !props.planned) {
      return selectUnplannedLiquidMaintenanceRequestTypes;
    } if (props.assetType === 'GasContainer' && props.planned) {
      return selectPlannedGasMaintenanceRequestTypes;
    } if (props.assetType === 'GasContainer' && !props.planned) {
      return selectUnplannedGasMaintenanceRequestTypes;
    } if (props.assetType === 'Chassis' && props.planned) {
      return selectPlannedChassisMaintenanceRequestTypes;
    } if (props.assetType === 'Chassis' && !props.planned) {
      return selectUnplannedChassisMaintenanceRequestTypes;
    }
    return () => ({});
  }, [props.assetType, props.planned]);

  const maintenanceRequestTypes = useSelector(
    requestTypeSelector,
  );
  // const unplannedLiquidMaintenanceRequestTypes = useSelector(
  //   selectUnplannedLiquidMaintenanceRequestTypes,
  // );
  // const plannedGasMaintenanceRequestTypes = useSelector(
  //   selectPlannedGasMaintenanceRequestTypes,
  // );
  // const unplannedGasMaintenanceRequestTypes = useSelector(
  //   selectUnplannedGasMaintenanceRequestTypes,
  // );
  // const plannedChassisMaintenanceRequestTypes = useSelector(
  //   selectPlannedChassisMaintenanceRequestTypes,
  // );
  // const unplannedChassisMaintenanceRequestTypes = useSelector(
  //   selectUnplannedChassisMaintenanceRequestTypes,
  // );

  // becasue of these dependecies. the inital load may happen mutiple times. and data may be
  // loaded into the table over and over and over. may want to wain until the data is set for
  // all of these to render the table.
  const requestsListData = useMemo(() => {
    return Object.values(maintenanceRequestTypes ?? {}).filter((mrt) => !mrt?.isLegacy && !(mrt?.name === 'Generic'));
  }, [maintenanceRequestTypes]);

  const loadData = useCallback(async () => {
    if (maintenanceRequestStatus === 'idle' || maintenanceRequestStatus === 'failed') {
      dispatch(fetchMaintenanceRequestTypes());
    }
  }, [maintenanceRequestStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const { confirm } = useConfirm();

  let requestsListColumns = useMemo(
    () => [
      // { Commenting out - I dont think we want to edit planned request types
      //   Header: '',
      //   id: 'actionColumn',
      //   Cell: ({ cell }) => (
      //     <Button
      //       size="xsm"
      //       title="Edit"
      //       onClick={() => {
      //         dispatch(setCurrentMaintenanceRequestTypeId(
      //           cell.row.original.maintenanceRequestTypeId,
      //         ));
      //         handleModalShowUnplanned();
      //       }}
      //     >
      //       <BsPencilFill />
      //     </Button>
      //   ),
      // },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Frequency',
        Cell: (({ row }) => (plannedMaintenanceRequestFrequency[row.original.maintenanceRequestTypeId] ?? '')),
      },
    ],
  );
  if (!props.planned) {
    requestsListColumns = useMemo(() => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => (
          <div className="d-flex">
            <Button
              size="xsm"
              variant="danger"
              title="Delete"
              className="mr-1"
              onClick={async () => {
                const isConfirmed = await confirm('Are you sure you want to delete this maintenance request type?');
                if (isConfirmed) {
                  dispatch(setCurrentMaintenanceRequestTypeIdForTemplateEdit(undefined));
                  dispatch(setCurrentMaintenanceRequestTypeId(null));
                  await dispatch(
                    removeMaintenanceRequestType(cell.row.original.maintenanceRequestTypeId),
                  ).unwrap()
                    .then((result) => {
                      toast.success('Deleted Maintenance Request Type');
                    }).catch((error) => {
                      toast.error(`Failed to delete Maintenance Request Type: ${error.message}`);
                    });
                }
              }}
            >
              <BsXLg />
            </Button>
            <Button
              size="xsm"
              title="Edit"
              onClick={() => {
                dispatch(setCurrentMaintenanceRequestTypeId(
                  cell.row.original.maintenanceRequestTypeId,
                ));
                handleModalShowUnplanned();
              }}
            >
              <BsPencilFill />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Name',
        accessor: 'name',
      },
      {
        Header: 'Modified',
        accessor: 'modified',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
    ], [datePreference]);
  }

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${maintenanceRequestStatus === 'loading' ? ' creation-loading' : ''}`}>
        <PaginatedSelectTable
          columns={requestsListColumns}
          data={requestsListData}
          ref={tableInstance}
          forcePaginationControl
          rowProps={(row) => ({
            onClick: () => {
              dispatch(setCurrentMaintenanceRequestTypeIdForTemplateEdit(
                row.original.maintenanceRequestTypeId,
              ));
              dispatch(setFetchMaintenanceRequestTypeTemplateStatus('idle'));
            },
            style: {
              cursor: 'pointer',
              backgroundColor: row.original.maintenanceRequestTypeId === currentMaintenanceRequestTypeIdForTemplateEdit ? 'lightgreen' : '',
            },
          })}
        />
      </div>
      {maintenanceRequestStatus === 'loading' && <LoadingContent />}
      <CustomModal show={modalShowPlanned} onHide={handleModalClosePlanned}>
        <RequestTypeModal handleModalClose={handleModalClosePlanned} title="Planned Maintenance Request Details" planned />
      </CustomModal>
      <CustomModal show={modalShowUnplanned} onHide={handleModalCloseUnplanned}>
        <RequestTypeModal handleModalClose={handleModalCloseUnplanned} title="Unplanned Maintenance Request Details" />
      </CustomModal>
    </>
  );
}

export default RequestsListTable;
