import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  bulkUpdateAndCompleteMaintenanceRequestLineItemsRequest,
  bulkUpdateMaintenanceRequestLineItemsRequest,
  completeMaintenanceRequestLineItemRequest,
  deleteMaintenanceRequestLineItemRequest,
  postMaintenanceRequestLineItem,
  postWorkOrderLineItem,
  undoMaintenanceRequestLineItemRequest,
  updateMaintenanceRequestLineItemRequest,
} from '../../services/requests';

const initialState = {
  maintenanceRequestLineItemCreate: {
    currentMaintenanceRequestId: null,
    status: 'idle',
    error: null,
  },
  maintenanceRequestLineItemDelete: {
    currentMaintenanceRequestId: null,
    status: 'idle',
    error: null,
  },
  maintenanceRequestLineItemsBulkUpdate: {
    currentMaintenanceRequestId: null,
    status: 'idle',
    error: null,
  },
  maintenanceRequestLineItemStatusUpdate: {
    currentMaintenanceRequestId: null,
    status: 'idle',
    error: null,
  },
  maintenanceRequestLineItemUndo: {
    currentMaintenanceRequestId: null,
    status: 'idle',
    error: null,
  },
  maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete: {
    currentMaintenanceRequestId: null,
    status: 'idle',
    error: null,
  },
  workOrderLineItemCreateLoading: false,
};

export const createMaintenanceRequestLineItem = createAsyncThunk('maintenanceRequestLineItem/createMaintenanceRequestLineItem', async ({ maintenanceRequestId, lineItem }) => {
  const response = await postMaintenanceRequestLineItem(maintenanceRequestId, lineItem);
  return response;
});

export const createWorkOrderLineItem = createAsyncThunk('maintenanceRequestLineItem/createWorkOrderLineItem', async ({ workOrderId, lineItem }) => {
  const response = await postWorkOrderLineItem(workOrderId, lineItem);
  return response;
});

export const deleteMaintenanceRequestLineItem = createAsyncThunk('maintenanceRequestLineItem/deleteMaintenanceRequestLineItem', async ({ maintenanceRequestId, lineItemId }) => {
  const response = await deleteMaintenanceRequestLineItemRequest(maintenanceRequestId, lineItemId);
  return response;
});

export const bulkUpdateAndCompleteMaintenanceRequestLineItems = createAsyncThunk('maintenanceRequestLineItem/bulkUpdateAndCompleteMaintenanceRequestLineItemsRequest', async ({ maintenanceRequestId, lineItems }) => {
  const response = await bulkUpdateAndCompleteMaintenanceRequestLineItemsRequest(
    maintenanceRequestId,
    lineItems,
  );
  return response;
});

export const updateMaintenanceRequestLineItem = createAsyncThunk('maintenanceRequestLineItem/updateMaintenanceRequestLineItem', async ({ lineItem, maintenanceRequestId }) => {
  const response = await updateMaintenanceRequestLineItemRequest(
    maintenanceRequestId,
    lineItem,
  );
  return response;
});
export const updateMaintenanceRequestLineItemStatus = createAsyncThunk('maintenanceRequestLineItem/updateMaintenanceRequestLineItemStatus', async ({ maintenanceRequestId, lineItem }) => {
  const response = await completeMaintenanceRequestLineItemRequest(
    maintenanceRequestId,
    lineItem,
  );
  return response;
});

export const undoMaintenanceRequestLineItem = createAsyncThunk('maintenanceRequestLineItem/undoMaintenanceRequestLineItem', async ({ maintenanceRequestId, lineItem }) => {
  const response = await undoMaintenanceRequestLineItemRequest(
    maintenanceRequestId,
    lineItem,
  );
  return response;
});

export const bulkUpdateMainteanceRequestLineItems = createAsyncThunk('maintenanceRequestLineItem/bulkUpdateMainteanceRequestLineItems', async ({ maintenanceRequestId, lineItems }) => {
  const response = await bulkUpdateMaintenanceRequestLineItemsRequest(
    maintenanceRequestId,
    lineItems,
  );
  return response;
});

export const maintenanceRequestLineItemSlice = createSlice({
  name: 'maintenanceRequestLineItem',
  initialState,
  reducers: {
    setMaintenanceRequestLineItemCreateStatus: (state, action) => {
      state.maintenanceRequestLineItemCreate.status = action.payload;
    },
    setMaintenanceRequestLineItemDeleteStatus: (state, action) => {
      state.maintenanceRequestLineItemDelete.status = action.payload;
    },
    setMaintenanceRequestLineItemsBulkUpdateStatus: (state, action) => {
      state.maintenanceRequestLineItemsBulkUpdate.status = action.payload;
    },
    setMaintenanceRequestLineItemStatusUpdateStatus: (state, action) => {
      state.maintenanceRequestLineItemStatusUpdate.status = action.payload;
    },
    setMaintenanceRequestLineItemUndoStatus: (state, action) => {
      state.maintenanceRequestLineItemUndo.status = action.payload;
    },
    setBulkUpdateAndCompleteMaintenanceRequestLineItemsStatus: (state, action) => {
      state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createMaintenanceRequestLineItem.pending, (state, action) => {
        state.maintenanceRequestLineItemCreate
          .currentMaintenanceRequestId = action.meta.arg.maintenanceRequestId;
        state.maintenanceRequestLineItemCreate.status = 'loading';
      })
      .addCase(createMaintenanceRequestLineItem.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemCreate.status = 'succeeded';
        state.maintenanceRequestLineItemCreate.error = null;
        state.maintenanceRequestLineItemCreate.currentMaintenanceRequestId = null;
      })
      .addCase(createMaintenanceRequestLineItem.rejected, (state, action) => {
        state.maintenanceRequestLineItemCreate.status = 'failed';
        state.maintenanceRequestLineItemCreate.error = action.error.message;
        state.maintenanceRequestLineItemCreate.currentMaintenanceRequestId = null;
      });

    builder
      .addCase(createWorkOrderLineItem.pending, (state, action) => {
        state.workOrderLineItemCreateLoading = true;
        state.maintenanceRequestLineItemCreate.status = 'loading';
      })
      .addCase(createWorkOrderLineItem.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemCreate.status = 'succeeded';
        state.maintenanceRequestLineItemCreate.error = null;
        state.workOrderLineItemCreateLoading = false;
      })
      .addCase(createWorkOrderLineItem.rejected, (state, action) => {
        state.maintenanceRequestLineItemCreate.status = 'failed';
        state.maintenanceRequestLineItemCreate.error = action.error.message;
        state.workOrderLineItemCreateLoading = false;
      });

    builder
      .addCase(deleteMaintenanceRequestLineItem.pending, (state, action) => {
        state.maintenanceRequestLineItemDelete
          .currentMaintenanceRequestId = action.meta.arg.maintenanceRequestId;
        state.maintenanceRequestLineItemDelete.status = 'loading';
      })
      .addCase(deleteMaintenanceRequestLineItem.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemDelete.status = 'succeeded';
        state.maintenanceRequestLineItemDelete.error = null;
        state.maintenanceRequestLineItemDelete.currentMaintenanceRequestId = null;
      })
      .addCase(deleteMaintenanceRequestLineItem.rejected, (state, action) => {
        state.maintenanceRequestLineItemDelete.status = 'failed';
        state.maintenanceRequestLineItemDelete.error = action.error.message;
        state.maintenanceRequestLineItemDelete.currentMaintenanceRequestId = null;
      });

    builder
      .addCase(updateMaintenanceRequestLineItemStatus.pending, (state, action) => {
        state.maintenanceRequestLineItemStatusUpdate.status = 'loading';
        state.maintenanceRequestLineItemStatusUpdate
          .currentMaintenanceRequestId = action.meta.arg.maintenanceRequestId;
      })
      .addCase(updateMaintenanceRequestLineItemStatus.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemStatusUpdate.status = 'succeeded';
        state.maintenanceRequestLineItemStatusUpdate.error = null;
        state.maintenanceRequestLineItemStatusUpdate.currentMaintenanceRequestId = null;
      })
      .addCase(updateMaintenanceRequestLineItemStatus.rejected, (state, action) => {
        state.maintenanceRequestLineItemStatusUpdate.status = 'failed';
        state.maintenanceRequestLineItemStatusUpdate.error = action.error.message;
        state.maintenanceRequestLineItemStatusUpdate.currentMaintenanceRequestId = null;
      });

    builder
      .addCase(undoMaintenanceRequestLineItem.pending, (state, action) => {
        state.maintenanceRequestLineItemUndo.status = 'loading';
        state.maintenanceRequestLineItemUndo
          .currentMaintenanceRequestId = action.meta.arg.maintenanceRequestId;
      })
      .addCase(undoMaintenanceRequestLineItem.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemUndo.status = 'succeeded';
        state.maintenanceRequestLineItemUndo.error = null;
        state.maintenanceRequestLineItemUndo.currentMaintenanceRequestId = null;
      })
      .addCase(undoMaintenanceRequestLineItem.rejected, (state, action) => {
        state.maintenanceRequestLineItemUndo.status = 'failed';
        state.maintenanceRequestLineItemUndo.error = action.error.message;
        state.maintenanceRequestLineItemUndo.currentMaintenanceRequestId = null;
      });

    builder
      .addCase(bulkUpdateMainteanceRequestLineItems.pending, (state, action) => {
        state.maintenanceRequestLineItemsBulkUpdate
          .currentMaintenanceRequestId = action.meta.arg.maintenanceRequestId;
        state.maintenanceRequestLineItemsBulkUpdate.status = 'loading';
      })
      .addCase(bulkUpdateMainteanceRequestLineItems.fulfilled, (state, action) => {
        state.maintenanceRequestLineItemsBulkUpdate.status = 'succeeded';
        state.maintenanceRequestLineItemsBulkUpdate.error = null;
        state.maintenanceRequestLineItemsBulkUpdate.currentMaintenanceRequestId = null;
      })
      .addCase(bulkUpdateMainteanceRequestLineItems.rejected, (state, action) => {
        state.maintenanceRequestLineItemsBulkUpdate.status = 'failed';
        state.maintenanceRequestLineItemsBulkUpdate.error = action.error.message;
        state.maintenanceRequestLineItemsBulkUpdate.currentMaintenanceRequestId = null;
      });

    builder
      .addCase(bulkUpdateAndCompleteMaintenanceRequestLineItems.pending, (state, action) => {
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete
          .currentMaintenanceRequestId = action.meta.arg.maintenanceRequestId;
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.status = 'loading';
      })
      .addCase(bulkUpdateAndCompleteMaintenanceRequestLineItems.fulfilled, (state, action) => {
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.status = 'succeeded';
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.error = null;
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.currentMaintenanceRequestId = null;
      })
      .addCase(bulkUpdateAndCompleteMaintenanceRequestLineItems.rejected, (state, action) => {
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.status = 'failed';
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.error = action.error.message;
        state.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.currentMaintenanceRequestId = null;
      });
  },
});

export const {
  setMaintenanceRequestLineItemCreateStatus,
  setMaintenanceRequestLineItemDeleteStatus,
  setMaintenanceRequestLineItemsBulkUpdateStatus,
  setMaintenanceRequestLineItemStatusUpdateStatus,
  setMaintenanceRequestLineItemUndoStatus,
  setBulkUpdateAndCompleteMaintenanceRequestLineItemsStatus,
} = maintenanceRequestLineItemSlice.actions;

export default maintenanceRequestLineItemSlice.reducer;
