import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { splitStringOnUppercase } from '../../../services/format';
import { selectLoggedInUserActiveLocation } from '../../../store/slices/auth-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';

function RequestTypeInfo({ requestType, condensed }) {
  const { assetTypes } = useSelector(selectMaintenanceRequestDropdownValues);

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  return (
    <Callout>
      {/* {JSON.stringify(requestType)} */}
      <ListGroup variant="unbordered">
        <ListGroup.Item>
          <InfoListItem
            name="ID"
            value={requestType.maintenanceRequestTypeId}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <InfoListItem
            name="Name"
            value={requestType.name}
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <InfoListItem
            name="Asset Type"
            value={
                splitStringOnUppercase(
                  assetTypes.find((at) => at.id === requestType.assetTypeId.toString()).name,
                )
            }
          />
        </ListGroup.Item>
        <ListGroup.Item>
          <InfoListItem
            name="Location"
            value={currentUserActiveLocation?.LocationName ?? ''}
          />
        </ListGroup.Item>
      </ListGroup>
    </Callout>
  );
}

export default RequestTypeInfo;
