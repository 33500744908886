import './log-shipments-modal.scss';

import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Col,
  Container, Form, Modal, OverlayTrigger,
  Row, Spinner,
  Tooltip,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { BsInfoCircle } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay } from '../../../services/format';
import {
  logShipmentRequest, selectCurrentPurchaseOrderId,
  selectCurrentPurchaseOrderPartDeliveriesByPurchaseRequestId,
  selectCurrentPurchaseOrderPartsPurchasedByPurchaseRequestId,
} from '../../../store/slices/purchase-orders-slice';
import DateInput from '../../UI/molecules/DateInput/date-input';
import EditablePaginatedSelectTable from '../../UI/organisms/Table/editable-paginated-select-table';

function LogShipmentsModal({
  show, onHide, selectedRequests, logShipmentRequestStatus,
}) {
  const defaultShipmentValues = {
    shipmentDate: null,
    billOfEntry: null,
    shipmentCost: 0,
  };
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    trigger,
    reset,
    setError,
    clearErrors,
    formState: { errors, isDirty, dirtyFields },
    getFieldState,
  } = useForm({
    defaultValues: defaultShipmentValues,
    mode: 'onChange',
  });

  const quantityDeliveredTooltip = (
    <OverlayTrigger
      placement="top"
      overlay={(
        <Tooltip id="button-tooltip">
          Quantity Accepted in this shipment should not exceed
          {' '}
          (Quantity Requested - Quantity Already Delivered)
        </Tooltip>
      )}
    >
      <span>
        <BsInfoCircle />
      </span>
    </OverlayTrigger>
  );

  const currentDeliveriesByPurchaseRequestId = useSelector(
    selectCurrentPurchaseOrderPartDeliveriesByPurchaseRequestId,
  );

  const requestModalColumns = useMemo(() => [
    // {
    //   Header: 'Purchase Request',
    //   accessor: 'purchaseRequestId',
    //   Cell: ({ value }) => value,
    // },
    {
      Header: 'Part Name',
      accessor: 'partName',
      Cell: ({ value }) => value,
    },
    {
      Header: 'Quantity Requested',
      accessor: 'quantityRequested',
      Cell: ({ value }) => value,
    },
    {
      Header: 'Already Delivered',
      accessor: 'purchaseRequestId',
      Cell: ({ value }) => (currentDeliveriesByPurchaseRequestId[value]?.reduce((acc, delivery) => {
        return acc + delivery.quantityAccepted;
      }, 0) ?? 0),
    },
    {
      Header: () => (
        <div className="d-inline-flex">
          Quantity Accepted &nbsp;&nbsp;
          {quantityDeliveredTooltip}
        </div>
      ),
      max: true,
      accessor: 'quantityDelivered',
    },
    {
      Header: 'Quantity Rejected',
      accessor: 'quantityRejected',
    },
    {
      Header: 'HSCode',
      type: 'text',
      accessor: 'hsCode',
    },
    // {
    //   Header: 'Quantity Rejected',
    //   accessor: 'quantityRejected',
    // },
  ], [currentDeliveriesByPurchaseRequestId]);

  const requestTableInstance = useRef(null);
  const currentPurchaseOrderPartsPurchasedByPurchaseRequestId = useSelector(
    selectCurrentPurchaseOrderPartsPurchasedByPurchaseRequestId,
  );

  const transformedData = [...selectedRequests.map((element) => {
    const quantityAlreadyDelivered = currentPurchaseOrderPartsPurchasedByPurchaseRequestId[
      element?.original?.purchaseRequestId]?.count ?? 0;

    const maxAllowed = element.original.quantityRequested
      - (quantityAlreadyDelivered) ?? 0;
    return {
      purchaseRequestId: element?.original?.purchaseRequestId.toString(),
      partName: element?.original?.part?.partName,
      partId: element?.original?.partId,
      purchaseOrderId: element?.original?.purchaseOrderId.toString(),
      quantityRequested: element?.original?.quantityRequested,
      quantityAlreadyDelivered,
      max: maxAllowed,
      hsCode: '',
      quantityDelivered: '',
      quantityRejected: '',
      newCost: element?.original?.newCost,
    };
  })];
  const [dataTable, setData] = useState(transformedData);

  const onModalHide = () => {
    reset();
    setData(transformedData);
    onHide();
  };

  useEffect(() => {
    if (logShipmentRequestStatus === 'idle') {
      onModalHide();
    }
  }, [logShipmentRequestStatus]);

  const currentPurchaseOrderId = useSelector(selectCurrentPurchaseOrderId);

  useEffect(() => {
    setData(selectedRequests.map((element) => {
      const quantityAlreadyDelivered = currentPurchaseOrderPartsPurchasedByPurchaseRequestId[
        element?.original?.purchaseRequestId]?.count ?? 0;
      const maxAllowed = element.original.quantityRequested
      - quantityAlreadyDelivered ?? 0;
      return {
        purchaseRequestId: element?.original?.purchaseRequestId.toString(),
        purchaseOrderId: element?.original?.purchaseOrderId.toString(),
        partName: element?.original?.part?.partName,
        partId: element?.original?.partId,
        quantityRequested: element?.original?.quantityRequested,
        quantityAlreadyDelivered,
        max: maxAllowed,
        hsCode: '',
        quantityDelivered: '',
        quantityRejected: '',
        newCost: element?.original?.newCost,
      };
    }));
  }, [selectedRequests, currentPurchaseOrderPartsPurchasedByPurchaseRequestId]);

  const [originalData] = useState(transformedData);
  const [skipPageReset, setSkipPageReset] = useState(false);
  const updateData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
    setSkipPageReset(true);
    setData((old) => old.map((row, index) => {
      if (index === rowIndex) {
        return {
          ...old[rowIndex],
          [columnId]: value,
        };
      }
      return row;
    }));
  };

  useEffect(() => {
    if (show) {
      setValue('shipmentDate', new Date());
    }
  }, [show]);

  useEffect(() => {
    setSkipPageReset(false);
  }, [dataTable]);
  // probably need to add here somewhere
  const onSubmit = (shipment) => {
    dispatch(logShipmentRequest({
      purchaseOrderId: currentPurchaseOrderId,
      logShipmentRequestBody: {
        BOE: shipment.billOfEntry,
        MRN: shipment.MRN,
        shipmentCost: shipment.shipmentCost,
        shipmentDate: convertDateToUTCDay(shipment.shipmentDate),
        purchaseOrderId: currentPurchaseOrderId,
        partDeliveries: dataTable.map((row) => ({
          purchaseRequestId: row.purchaseRequestId,
          quantityAccepted: row.quantityDelivered,
          quantityRejected: row.quantityRejected,
          partId: row.partId,
          hsCode: row.hsCode,
          purchaseOrderId: row.purchaseOrderId,
          newCost: row.newCost,
        })),
      },
    }));
  };

  const partsTable = (
    <EditablePaginatedSelectTable
      columns={requestModalColumns}
      data={dataTable || []}
      ref={requestTableInstance}
      rowProps={(row) => ({})}
      updateData={updateData}
    />
  );

  const requestIsLoading = [
    logShipmentRequestStatus,
  ].includes('loading');

  return (
    <Modal
      show={show}
      onHide={() => onModalHide()}
      size="lg"
      centered
      scrollable
      dialogClassName="modal-75w"
      contentClassName="h-100 w-100 mw-100"
      className="min-width: 1000px"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          Create MRN
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column h-100">
        <Spinner animation="border" variant="primary" className={`create-spinner${requestIsLoading ? ' visible' : ' invisible'}`} />
        <Form>
          <Container fluid className={` ${requestIsLoading ? ' creation-loading' : ''}`}>
            <Row>
              <Col>
                <Form.Group className="mb-1" md={4}>
                  <Form.Label>MRN Date</Form.Label>
                  <DateInput
                    control={control}
                    formControlName="shipmentDate"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" md={4}>
                  <Form.Label>MRN Cost</Form.Label>
                  <Form.Control
                    size="sm"
                    isInvalid={!!errors.MRN}
                    type="number"
                    {...register('shipmentCost')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.MRN && errors.MRN.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1" md={4}>
                  <Form.Label>Bill of Entry</Form.Label>
                  <Form.Control
                    size="sm"
                    isInvalid={!!errors.billOfEntry}
                    {...register('billOfEntry')}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.billOfEntry && errors.billOfEntry.message}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {partsTable}
          </Container>
        </Form>

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={() => onModalHide()} variant="secondary" className="mt-3 position-sticky float-end bottom-0">Close</Button>
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
          disabled={requestIsLoading}
          onClick={handleSubmit(onSubmit)}
        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default LogShipmentsModal;
