import React, { useEffect, useMemo, useRef, useState } from "react";
import { guid } from "../../../../services/form-helpers";
import { Button } from "react-bootstrap";
import { ListViewColumnType } from "../list-view";
import { BsXLg } from "react-icons/bs";
import "./EditTable.scss";
import { FormItemType } from "../component-map";

export const EditTableRow = ({writeAccess, pk, columnsAdapted, row, removeRow, renderFormField, tableName, getRowEntity, editTableProps, i, methods, partitions, partition }) => {
  const [rowKey, setRowKey] = useState(guid());
  const myRef = useRef();
  const refreshRow = () => setRowKey(guid());
  const rowEntity = useMemo(() => {
    const collection = methods.getValues(tableName);
    return { ...collection[i] };
  }, [tableName, i, methods.getValues()]);

  useEffect(() => {
    (myRef?.current as any)?.scrollIntoView();
  },[])

  const savedRecord = typeof pk != 'undefined' && typeof rowEntity[pk] != 'undefined' && rowEntity[pk] != null && rowEntity[pk] != 0;

  return (
    <tr {...row.getRowProps()} key={rowKey} ref={myRef} className={savedRecord ? 'saved-row' : undefined}>
      {row.cells.map((cell, j) => {
        return (
          <td {...cell.getCellProps()} style={{ ...columnsAdapted[j].style, verticalAlign: 'top', paddingLeft:  columnsAdapted[j].formItemProps?.type == FormItemType.checkbox ? 6 : undefined}}>
            {
              columnsAdapted[j].id == 'selection' ? cell.render('Cell') :
                columnsAdapted[j].type == ListViewColumnType.delete ?
                  (
                    <Button
                      // @ts-ignore
                      size="xsm"
                      variant="danger"
                      title="Delete"
                      onClick={() => removeRow(i)}
                      style={{marginTop: 4}}
                      disabled={!writeAccess}
                    >
                      <BsXLg />
                    </Button>
                  ) :
                  renderFormField({
                    ...columnsAdapted[j].formItemProps,
                    name: `${tableName}[${i}][${columnsAdapted[j]["accessor"]}]`,
                  }, i, { ...editTableProps, rowEntity: rowEntity, rowIdentity: i, refreshRow, partitions, partition })

            }
          </td>
        );
      })}
    </tr>
  )
}