import React, { useEffect } from "react";
import { Form } from "react-bootstrap"

export const ClassicCheckField = ({ format, setValue, watch, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, errors, formState, editTableProps }) => {

  const hide = field.hideIf ? field.hideIf({ editTableProps, rowIdentity, watch }) : false;
  return !hide && (
    <input
      disabled={field.disabled ? field.disabled({ watch, editTableProps }) : undefined}
      aria-label={field.label}
      /*       style={{ position: 'relative !important' }} */
      {...register(field.name)}
      type="checkbox"
      formControlName={field.name}
      label={""}
    />
  );
}