import React from 'react';
import { Button, Modal } from 'react-bootstrap';

import useConfirm from './use-confirm';

function ConfirmDialog() {
  const {
    onConfirm, onCancel, confirmState,
  } = useConfirm();

  const alertOnly = confirmState?.alertOnly;

  return (
    <Modal
      show={confirmState.show}
      onHide={onCancel}
      centered
    >
      <Modal.Body>
        <p>{confirmState?.text && confirmState.text}</p>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        {!alertOnly && <Button onClick={onCancel} variant="secondary">Cancel</Button>}
        {!alertOnly && <Button onClick={onConfirm}>Confirm</Button>}
        {alertOnly && <div />}
        {alertOnly && <Button onClick={onConfirm}>Ok</Button>}
      </Modal.Footer>
    </Modal>
  );

  //   return createPortal(component, portalElement);
}
export default ConfirmDialog;
