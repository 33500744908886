export const actions = {
  Insert: 'Created',
  Update: 'Edited',
  Delete: 'Deleted',
};

export const entities = {
  GasContainers: 'Gas Container',
  LiquidContainers: 'Liquid Container',
  PackagedProducts: 'Packaged Product',
  DewarContainers: 'Packaged Product',
  LiquidChassis: 'Chassis',
  GlobalAttachments: 'Attachment',
  Attachments: 'Attachment',
  MaintenanceRequests: 'Maintenance Request',
  MaintenanceRequestTypes: 'Maintenance Request Type',
  MaintenanceRequestTypeTemplates: 'Maintenance Request Type Template',
  MaintenanceRequestLineItems: 'Line Item',
  MaintenanceRequestLineItemTemplates: 'Line Item Template',
  Parts: 'Part',
  PartsPurchased: 'Part in Queue',
  Shipment: 'Shipment',
  PartDelivery: 'Delivery',
  PurchaseRequests: 'Purchase Request(s)',
  Roles: 'Role',
  WorkOrders: 'Work Order',
  Users: 'Users',
  UserRole: 'Role',
  UserLocations: 'User Location',
  PurchaseOrders: 'Purchase Order',
  DewarCustomers: 'Dewar Customers',
  LiquidCustomers: 'Liquid Customers',
  GasCustomers: 'Gas Customers',
  LiquidCustomerPricing: 'Liquid Customer Pricing',
  GasCustomerPurityRequirements: 'Gas Customer Purity Req',
  Locations: 'Locations',
  LocationLiquidPurityRequirements: 'LocationLiquidPurityRequirements',
  LocationGasPurityRequirements: 'LocationGasPurityRequirements',
  GasReceivers: 'GasReceivers',
  LocationAnalyzers: 'LocationAnalyzers',
  NitrogenTanks: 'NitrogenTanks',
  FillSlots: 'FillSlots',
  RTU: 'RTU',
};

export const maintenanceEntities = [
  'MaintenanceRequests',
  'MaintenanceRequestTypes',
  'MaintenanceRequestTypeTemplates',
  'MaintenanceRequestLineItems',
  'MaintenanceRequestLineItemTemplates',
  'WorkOrders',
];

export const links = {
  GasContainers: '/assets/gas-container/',
  LiquidContainers: '/assets/liquid-container/',
  PackagedProducts: '/assets/packaged-product/',
  DewarContainers: '/assets/packaged-product/',
  LiquidChassis: '/assets/chassis/',
  WorkOrders: '/maintenance/work-orders/',
  Users: '/profile?',
  UserRole: '/admin/roles?',
};
