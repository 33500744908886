/* eslint-disable max-len */
import React, { useEffect, useMemo } from 'react';
import {
  Button, Col, Container, Form, Modal, Row, Spinner, ToggleButton, ToggleButtonGroup,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay } from '../../../services/format';
import { selectLoggedInUserActiveLocation, selectLoggedInUserLocations } from '../../../store/slices/auth-slice';
import { selectAllActiveLocations } from '../../../store/slices/legacy-slice';
import { createPurchaseOrder } from '../../../store/slices/purchase-orders-slice';
import DateInput from '../../UI/molecules/DateInput/date-input';
import CustomModal from '../../UI/molecules/Modal/modal';
import { Typeahead } from '../../UI/molecules/Typeahead';

function CreatePurchaseOrderModal(props) {
  const defaultFormValues = {
    purchaseOrderNumber: null,
    estimatedDeliveryDate: null,
    locationId: null,
    sourceLocationId: null,
    isStockTransferOrder: false,
    systemGeneratedPO: true,
  };

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultFormValues,
  });

  const purchaseOrderCreateStatus = useSelector(
    (state) => state.purchaseOrder.purchaseOrderCreate.status,
  );

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const initialPurchaseOrderLocation = currentUserActiveLocation?.LocationName;

  const dispatch = useDispatch();

  const handleChange = (e) => {
    if (e.target.checked) {
      setValue('purchaseOrderNumber', null);
    }
    setValue('systemGeneratedPO', e.target.checked);
  };

  // locations for purchase order location ID dropdown
  const unsortedLocations = useSelector(selectLoggedInUserLocations);

  const locations = [...unsortedLocations]
    .sort((a, b) => a.LocationName.trim().localeCompare(b.LocationName.trim()));

  const locationsList = useMemo(() => {
    return Object.values(locations ?? {});
  }, [locations]);

  // locations for purchase order source location ID dropdown
  const sourcelocations = useSelector(selectAllActiveLocations);

  const sourceLocationsList = useMemo(() => {
    return Object.values(sourcelocations ?? {});
  }, [sourcelocations]);

  const setOrderType = (value) => {
    setValue('isStockTransferOrder', value);
  };

  const onSubmit = (purchaseOrder) => {
    Object.keys(purchaseOrder).forEach((key) => {
      if (key.toLowerCase().includes('date') && purchaseOrder[key]) {
        purchaseOrder[key] = convertDateToUTCDay(purchaseOrder[key]);
      }
    });
    dispatch(createPurchaseOrder(purchaseOrder));
  };

  useEffect(() => {
    const initialLocationId = locationsList?.find(
      (l) => l?.LocationName === initialPurchaseOrderLocation,
    )?.LocationID;
    setValue('locationId', initialLocationId);
  }, [watch, initialPurchaseOrderLocation, locationsList]);

  useEffect(() => {
    if (purchaseOrderCreateStatus === 'succeeded') {
      reset();
    }
  }, [purchaseOrderCreateStatus]);
  const isLoading = purchaseOrderCreateStatus === 'loading';

  const onClose = () => {
    reset();
    props.onHide();
  };
  return (
    <CustomModal
      contentClassName="h-75"
      show={props.show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          Create Purchase Order
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${isLoading ? ' visible' : ' invisible'}`}
        />
        <Form className={`${isLoading ? ' creation-loading' : ''}`}>
          <Container>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Order Type</Form.Label>
                  <br />
                  <ToggleButtonGroup size="sm" type="radio" name="order-type" value={watch('isStockTransferOrder')} onChange={setOrderType}>
                    <ToggleButton
                      id="po-radio"
                      type="radio"
                      variant={watch('isStockTransferOrder') ? 'outline-primary' : 'primary'}
                      value={false}
                    >
                      Purchase Order
                    </ToggleButton>
                    <ToggleButton
                      id="sto-radio"
                      type="radio"
                      variant={!watch('isStockTransferOrder') ? 'outline-primary' : 'primary'}
                      // eslint-disable-next-line react/jsx-boolean-value
                      value={true}
                    >
                      Stock Transfer Order
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Form.Group>
              </Col>
              <Col className={watch('isStockTransferOrder') ? '' : 'd-none'}>
                <Form.Group className="mb-1">
                  <Form.Label>Source Location</Form.Label>
                  <Controller
                    control={control}
                    name="sourceLocationId"
                    defaultValue={null}
                    rules={{
                      required: watch('isStockTransferOrder'),
                      validate: () => {
                        const isStockTransferOrder = getValues('isStockTransferOrder');
                        const locationId = getValues('locationId');
                        const sourceLocationId = getValues('sourceLocationId');
                        return !isStockTransferOrder || locationId !== sourceLocationId;
                      },
                    }}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        value={value}
                        onChange={(e) => {
                          onChange(sourceLocationsList?.find(
                            (l) => l?.LocationName === e[0],
                          )?.LocationID);
                        }}
                        size="sm"
                        id="sourceLocationId"
                        options={sourceLocationsList.map((l) => l.LocationName)}
                        isInvalid={!!error}
                      />
                    )}
                  />
                  {errors.sourceLocationId && errors.sourceLocationId.type === 'validate' && (
                    <div className="error">
                      Location cannot be the same as the source
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Purchase Order Number</Form.Label>
                  <Form.Check
                    {...register('systemGeneratedPO')}
                    type="switch"
                    inline
                    id="systemGeneratedPO-switch"
                    label="Use System Generated PO Number"
                    style={{ marginLeft: '10px' }}
                    onChange={(e) => handleChange(e)}
                  />
                  {!watch('systemGeneratedPO') ? (
                    <Form.Control
                      size="sm"
                      {...register('purchaseOrderNumber', { required: !watch('systemGeneratedPO') ? 'Please enter a purchase order number' : false })}
                      isInvalid={(!!errors.purchaseOrderNumber || props.showErrorText) && !watch('systemGeneratedPO')}
                    />
                  ) : ''}
                  <Form.Control.Feedback type="invalid">
                    Please provide a unique purchase order number or toggle switch to use a system generated PO Number
                  </Form.Control.Feedback>

                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Estimated Delivery Date</Form.Label>
                  <DateInput
                    control={control}
                    formControlName="estimatedDeliveryDate"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Location</Form.Label>
                  <Controller
                    control={control}
                    name="locationId"
                    defaultValue={null}
                    rules={{
                      required: true,
                      validate: () => {
                        const isStockTransferOrder = getValues('isStockTransferOrder');
                        const locationId = getValues('locationId');
                        const sourceLocationId = getValues('sourceLocationId');
                        return !isStockTransferOrder || locationId !== sourceLocationId;
                      },
                    }}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        value={value}
                        onChange={(e) => {
                          onChange(locationsList?.find(
                            (l) => l?.LocationName === e[0],
                          )?.LocationID);
                        }}
                        size="sm"
                        id="locationId"
                        options={locationsList.map((l) => l.LocationName)}
                        defaultSelected={initialPurchaseOrderLocation ? [initialPurchaseOrderLocation] : ['']}
                        isInvalid={!!error}
                      />
                    )}
                  />
                  {errors.locationId && errors.locationId.type === 'validate' && (
                    <div className="error">
                      Location is required and cannot be the same as the source
                    </div>
                  )}
                </Form.Group>
              </Col>
            </Row>
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={onClose} variant="secondary">Close</Button>
        <Button
          type="submit"
          className="position-sticky float-end bottom-0"
          onClick={handleSubmit(onSubmit)}
        >
          Submit

        </Button>
      </Modal.Footer>
    </CustomModal>

  );
}

export default CreatePurchaseOrderModal;
