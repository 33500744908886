import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button, Collapse,
  Form, InputGroup, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import {
  getNextDueDate,
  maintenanceStatusValues,
} from '../../../services/maintenance-helpers';
import {
  assetSort, exportData,
  sortByBasic,
  sortByBoolean, sortByDateCustom, sortByDisplayValue,
} from '../../../services/table-helpers';
import { convertToPreferred, getPreferredUnit } from '../../../services/user-preferences';
import { selectIsAssetAdmin, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  fetchGasContainers,
  fetchMaintenanceStatusForGasContainers,
  selectGasContainers,
  selectGasContainersMaintenanceStatuses,
} from '../../../store/slices/gas-containers-slice';
import { selectGasCustomerObjects, selectGasCustomers } from '../../../store/slices/legacy-slice';
import { useTablePageRef } from '../../hooks/useStateRef';
import ActiveIcon from '../../UI/atoms/ActiveIcon/active-icon';
import { YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';
import InactiveIcon from '../../UI/atoms/InactiveIcon/inactive-Icon';
import RedFlagAlert from '../../UI/atoms/RedFlagAlert/red-flag-alert';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { NextDueDate } from '../../UI/molecules/NextDueDate/next-due-date';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import { gasContainerStyles, getGasOwnerCustomer } from './gas-container-values';

function GasContainersTable(props) {
  const gasContainers = useSelector(selectGasContainers);
  const gasContainerStatus = useSelector((state) => state.gasContainer.gasContainersFetch.status);
  const gasContainerError = useSelector((state) => state.gasContainer.gasContainersFetch.error);

  const gasContainersMaintenanceStatuses = useSelector(
    selectGasContainersMaintenanceStatuses,
  );

  const gasContainerMaintenanceFetchStatus = useSelector(
    (state) => state.gasContainer.gasContainersMaintenanceStatusFetch.status,
  );

  const gasCustomersArr = useSelector(selectGasCustomers);
  const gasCustomers = useSelector(selectGasCustomerObjects);
  const { measurementPreference, datePreference } = useSelector(selectLoggedInUserPreferences);

  const sortByOwnerCustomerDisplay = (row1, row2, columnId) => {
    const customers = gasCustomers;
    try {
      console.log('Calling Customer Display from Gas Table');
      return sortByDisplayValue(row1, row2, columnId, customers, 'CustomerName');
    } catch (error) {
      return console.log(`There was an error" ${error}`);
    }
  };

  // Configure Table References

  const [currentPage, gasTable, gasContainersTableInstance] = useTablePageRef();

  const mappedPage = currentPage?.map((r) => r.original.serialNumber);

  // const gasContainersTableInstance = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (gasContainers) {
      dispatch(fetchMaintenanceStatusForGasContainers(
        { assetTypeId: 1, assetIds: Object.keys(gasContainers) },
      ));
    }
  }, [JSON.stringify(gasContainers)]);

  const handleFilterChange = (event, filterId) => {
    gasTable.setFilter(filterId, event.target.checked);
  };

  const handleSelectFilterChange = (event, filterId) => {
    gasTable.setFilter(filterId, event.target.value);
  };

  const gasContainersColumns = useMemo(
    () => [
      {
        Header: 'Unit Number',
        accessor: 'serialNumber',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        Cell: ({ value }) => (<NavLink to={`/assets/gas-container/${value}`}>{value}</NavLink>),
      },
      {
        Header: '',
        id: 'maintenanceStatus',
        filter: 'exactText',
        sortType: (a, b) => {
          const aStatus = gasContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceStatus ?? '';
          const bStatus = gasContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceStatus ?? '';

          const aValue = maintenanceStatusValues[aStatus];
          const bValue = maintenanceStatusValues[bStatus];
          if (gasContainerMaintenanceFetchStatus === 'loading') {
            return 0;
          }
          return sortByBasic(aValue, bValue);
        },
        accessor: (row) => {
          const status = gasContainersMaintenanceStatuses[row.serialNumber]?.maintenanceStatus ?? '';
          return status;
        },
        Cell: ({ value, row }) => {
          const status = gasContainersMaintenanceStatuses[row.original.serialNumber]?.maintenanceStatus ?? '';
          if (status === 'Red') {
            return <RedFlagAlert redReasons={gasContainersMaintenanceStatuses[row.values.serialNumber]?.redReason} wos={gasContainersMaintenanceStatuses[row.values.serialNumber]?.wo} value={row.values} />;
          }
          if (status === 'Yellow') {
            return <YelloIcon />;
          }
          if (gasContainerMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return '';
        },
      },
      {
        Header: `Press 70 (${getPreferredUnit('psig', measurementPreference)})`,
        id: 'press70',
        accessor: (row) => (row.press70 == null ? null : `${convertToPreferred('psig', measurementPreference, row.press70)}`),
      },
      {
        Header: `Press 110 (${getPreferredUnit('psig', measurementPreference)})`,
        id: 'press110',
        accessor: (row) => (row.press110 == null ? null : `${convertToPreferred('psig', measurementPreference, row.press110)}`),
      },
      {
        Header: 'Next AE Inspection',
        id: 'aEInspectionDate',
        /*         accessor: 'serialNumber', */
        sortType: (a, b) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['AE Inspection'] ?? '';
          const maintenanceDate2 = gasContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['AE Inspection'] ?? '';
          const nextA = getNextDueDate(maintenanceDate?.dueDate, a.original.aeInspectionDate, 60);
          const nextB = getNextDueDate(maintenanceDate2.dueDate, b.original.aeInspectionDate, 60);
          return sortByDateCustom(nextA, nextB);
        },
        accessor: (row) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[row.serialNumber]?.maintenanceDates?.['AE Inspection'] ?? '';
          return formatDate(getNextDueDate(maintenanceDate?.dueDate, row.aeInspectionDate, 60), datePreference);
        },
        Cell: ({ value, row }) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[row.original.serialNumber]?.maintenanceDates?.['AE Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={getNextDueDate(maintenanceDate?.dueDate, row.original.aeInspectionDate, 60)}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Next Neck Th Insp',
        id: 'neckThreadDate',
        sortType: (a, b) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[a.values.serialNumber]?.maintenanceDates?.['Neck Thread Inspection'] ?? '';
          const maintenanceDate2 = gasContainersMaintenanceStatuses[b.values.serialNumber]?.maintenanceDates?.['Neck Thread Inspection'] ?? '';
          const nextA = getNextDueDate(maintenanceDate?.dueDate, a.original.neckThreadDate, 120);
          const nextB = getNextDueDate(maintenanceDate2.dueDate, b.original.neckThreadDate, 120);
          return sortByDateCustom(nextA, nextB);
        },
        accessor: (row) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[row.serialNumber]?.maintenanceDates?.['Neck Thread Inspection'] ?? '';
          return formatDate(getNextDueDate(maintenanceDate?.dueDate, row.neckThreadDate, 120), datePreference);
        },
        Cell: ({ value, row }) => {
          const maintenanceDate = gasContainersMaintenanceStatuses[row.original.serialNumber]?.maintenanceDates?.['Neck Thread Inspection'] ?? '';
          return (
            <NextDueDate
              dueDate={getNextDueDate(maintenanceDate?.dueDate, row.original.neckThreadDate, 120)}
              isOverridden={maintenanceDate?.isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: `Water Vol (${getPreferredUnit('Cubic Feet', measurementPreference)})`,
        id: 'waterVolume',
        accessor: (row) => (row.waterVolume == null ? null : `${convertToPreferred('Cubic Feet', measurementPreference, row.waterVolume)}`),
      },
      {
        Header: 'Nominal Capacity',
        accessor: 'nominalCapacity',
      },
      {
        Header: '# Tubes',
        accessor: 'numberOfTubes',
      },
      {
        Header: 'Length',
        accessor: 'length',
      },
      {
        Header: 'Owner',
        id: 'ownerCustomer',
        filter: 'exactText',
        accessor: (row) => getGasOwnerCustomer(gasCustomers, row.ownerCustomer),
      },
      {
        Header: 'Style',
        id: 'style',
        filter: 'exactText',
        accessor: (row) => (row.style == null ? null : row.style),
      },
      {
        Header: 'Comments',
        accessor: 'comments',
        disableSortBy: true,
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
      {
        Header: 'ISO',
        accessor: 'isIsoFrame',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Leased',
        accessor: 'isLeased',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? <ActiveIcon /> : <InactiveIcon />),
      },
    ],
    [gasContainerMaintenanceFetchStatus, gasCustomers],
  );

  const gasContainersData = useMemo(() => {
    if (gasContainers) {
      return Object.values(gasContainers).sort(assetSort);
    }

    return [];
  }, [gasContainers]);

  const loadData = useCallback(async () => {
    if (gasContainerStatus === 'idle' || gasContainerStatus === 'failed') {
      dispatch(fetchGasContainers());
    }
  }, [gasContainerStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const handleRowClick = (gasContainerId) => {
    // Leveraging two methods for passing data to a new page for examples:
    // 1. State / useLocation
    // 2. uri path / useParam
    navigate(`/assets/gas-container/${gasContainerId}`);
  };

  const onExport = () => {
    exportData('gascontainers', gasContainersData, gasContainersColumns);
  };
  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  const gasContainersContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={gasTable} hookInstance />
          <Button variant="primary" size="sm" onClick={onExport}><i className="bi bi-download" /></Button>
          {isAssetAdmin
            && (
              <>
                &nbsp;
                <Button variant="primary" size="sm" onClick={props.handleModalShow}>Add Gas Container</Button>
              </>
            )}

        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'ownerCustomer'); }}>
                  <option value="" key="customer-null">-- Owner --</option>
                  {gasCustomersArr.map((customer, index) => (
                    <option key={`customer-${index}`}>{customer.CustomerName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'style'); }}>
                  <option value="" key="style-null">-- Style --</option>
                  {gasContainerStyles.map((style, index) => (
                    <option key={`style-${index}`}>{style}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Check
                type="switch"
                className="mb-1"
                inline
                id="isLeased-switch"
                label="Leased"
                onChange={(e) => handleFilterChange(e, 'isLeased')}
              />
              <Form.Check
                type="switch"
                className="ms-1 mb-1"
                inline
                id="isIsoFrame-switch"
                label="ISO"
                onChange={(e) => handleFilterChange(e, 'isIsoFrame')}
              />
              <Form.Check
                className="mb-1 ms-1"
                type="switch"
                inline
                id="active-switch"
                label="Active"
                defaultChecked
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
              {/* IoT - this filter is for a field that we are not currently leveraging */}
              {/* <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="telemetryUnitId-select"
                label="Remote Readout Conn. (IoT)"
                onChange={(e) => handleFilterChange(e, 'telemetryUnitId')}
              /> */}
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={gasContainersColumns}
        data={gasContainersData}
        ref={gasContainersTableInstance}
        initialState={{ filters: [{ id: 'isActive', value: true }] }}
        rowProps={() => ({})}
      />
    </>
  );

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${gasContainerStatus === 'loading' ? ' creation-loading' : ''}`}>
        {gasContainersContent}
        {gasContainerStatus === 'failed' && <ErrorContent errorMessage={gasContainerError} />}
      </div>
      {gasContainerStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default GasContainersTable;
