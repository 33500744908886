import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { createPermissionRequest, getPermissions } from '../../services/requests';

const initialState = {
  permissions: [],
  permissionsFetch: {
    status: 'idle',
    error: null,
  },
  permissionCreate: {
    status: 'idle',
    error: null,
  },
};

export const fetchPermissions = createAsyncThunk('permission/fetchPermissions', async () => {
  const response = await getPermissions();
  return response;
});

export const createPermission = createAsyncThunk('permission/createPermission', async (permission) => {
  const response = await createPermissionRequest(permission);
  return response;
});

export const permissionsSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPermissions: (state, action) => {
      state.permissions = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPermissions.pending, (state, action) => {
        state.permissionsFetch.status = 'loading';
      })
      .addCase(fetchPermissions.fulfilled, (state, action) => {
        state.permissionsFetch.status = 'succeeded';
        state.permissions = action.payload;
        state.permissionsFetch.error = null;
      })
      .addCase(fetchPermissions.rejected, (state, action) => {
        state.permissionsFetch.status = 'failed';
        state.permissionsFetch.error = action.error.message;
      });
    builder
      .addCase(createPermission.pending, (state, action) => {
        state.permissionCreate.status = 'loading';
      })
      .addCase(createPermission.fulfilled, (state, action) => {
        state.permissionCreate.status = 'succeeded';
        state.permissionCreate.error = null;
      })
      .addCase(createPermission.rejected, (state, action) => {
        state.permissionCreate.status = 'failed';
        state.permissionCreate.error = action.error.message;
      });
  },
});

export const { setPermissions } = permissionsSlice.actions;

export const selectPermissions = (state) => state.permission.permissions;
export const selectPermissionsStatus = (state) => state.permission.permissionsFetch.status;

export const selectCreatePermissionStatus = (state) => state.permission.permissionCreate.status;

export default permissionsSlice.reducer;
