/* eslint-disable indent */
import 'chartjs-adapter-date-fns';

import React, { useMemo, useRef } from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { useSelector } from 'react-redux';

// eslint-disable-next-line unused-imports/no-unused-imports
import { formatDate } from '../../../services/format';
import { convertToPreferred, getPreferredUnit } from '../../../services/user-preferences';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectCurrentLiquidContainer, selectCurrentLiquidContainerReadings } from '../../../store/slices/liquid-containers-slice';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

export function SummaryTable() {
  const tableInstance = useRef(null);
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);
  const currentLiquidContainer = useSelector(selectCurrentLiquidContainer);
  const { summary: summaryData, data: liquidContainerReadings } = useSelector(selectCurrentLiquidContainerReadings);
  const tempAbbr = getPreferredUnit('Fahrenheit', measurementPreference).substr(0, 1);

  const summaryColumns = useMemo(
    () => [
      {
        Header: 'N2 Level',
        accessor: 'n2Level',
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.N2LevelTS, datePreference)}`}>{value}</span>,
      },
      {
        Header: 'N2 Pressure',
        id: 'n2Pressure',
        disableSortBy: true,
        accessor: ({ n2Pressure }) => {
          return n2Pressure;
        },
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.N2PressureTS, datePreference)}`}>{value}</span>,
      },
      {
        Header: 'He Level',
        accessor: 'heLevel',
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.HeLevelTS, datePreference)}`}>{value}</span>,
      },
      {
        Header: 'He Pressure',
        id: 'hePressure',
        accessor: ({ hePressure }) => {
          return hePressure;
        },
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.HePressureTS, datePreference)}`}>{value}</span>,
      },
      {
        Header: 'Battery Voltage',
        accessor: 'batteryLevel',
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.BatteryLevelTS, datePreference)}`}>{value}</span>,
      },
      {
        Header: `Ambient Temperature (${tempAbbr})`,
        id: 'ambientTemperature',
        accessor: ({ ambientTemperature }) => {
          const converted = convertToPreferred('Fahrenheit', measurementPreference, ambientTemperature);
          return Math.floor(converted);
        },
        disableSortBy: true,
        Cell: ({ value, row }) => <span title={`Value from: ${formatDate(row.original.AmbientTemperatureTS, datePreference)}`}>{value}</span>,
      },
      {
        Header: 'GPS',
        accessor: 'gps',
        disableSortBy: true,
        Cell: ({ value, row }) => (
          <a target="_blank" href={`https://www.google.com/maps/search/?api=1&query=${row.original.Latitude},${row.original.Longitude}`} rel="noreferrer">
            <FaMapMarkerAlt />
          </a>
        ),
      },
    ],
    [],
  );

  return (
    <PaginatedSelectTable
      columns={summaryColumns}
      data={summaryData ? [summaryData] : []}
      ref={tableInstance}
      rowProps={(row) => ({
        onClick: () => {
          // handleRowClick(row.original.serialNumber);
        },
      })}
    />
  );
}
