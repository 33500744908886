const isDate = (str) => String(new Date(str)) !== 'Invalid Date';

export const formatDate = (unformattedDate, dateFormat = null) => {
  if (unformattedDate === null || !isDate(unformattedDate)) {
    return '';
  }
  const date = new Date(unformattedDate);

  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();

  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;

  // return yyyy + '-' + mm + '-' + dd;

  switch (dateFormat) {
    case 1:
      return `${dd}/${mm}/${yyyy}`;
    case 2:
      return `${yyyy}/${mm}/${dd}`;
    case 0:
    default:
      return `${mm}/${dd}/${yyyy}`;
  }
};
export const formatDateTime = (unformattedDate, dateFormat = null) => {
  if (unformattedDate === null || !isDate(unformattedDate)) {
    return '';
  }
  const utc = new Date(unformattedDate);
  const offset = (new Date()).getTimezoneOffset();
  const date = new Date(utc.getTime() - offset * 60000);

  const yyyy = date.getFullYear();
  let mm = date.getMonth() + 1; // Months start at 0!
  let dd = date.getDate();
  let hh = date.getHours();
  let min = date.getMinutes();

  if (dd < 10) dd = `0${dd}`;
  if (mm < 10) mm = `0${mm}`;

  if (min < 10) min = `0${min}`;

  const am = hh < 12 ? 'am' : 'pm';

  if (hh > 12) hh -= 12;

  if (hh < 10) hh = `0${hh}`;

  switch (dateFormat) {
    case 1:
      return `${dd}/${mm}/${yyyy} ${hh}:${min} ${am}`;
    case 2:
      return `${yyyy}/${mm}/${dd} ${hh}:${min} ${am}`;
    case 0:
    default:
      return `${mm}/${dd}/${yyyy} ${hh}:${min} ${am}`;
  }
};

export const addMonthsToDate = (unformattedDate, months, dateFormat = null) => {
  if (!unformattedDate) {
    return '';
  }

  const dateObj = new Date(unformattedDate.replace('Z', ''));

  dateObj.setMonth(dateObj.getMonth() + months);

  return formatDate(dateObj, dateFormat);
};

export const addDaysToDate = (dateObj, days) => {
  return dateObj.setDate(dateObj.getDate() + days);
};

export const splitStringOnUppercase = (s) => {
  const lower = s.toLowerCase();
  let result = '';
  for (let i = 0; i < s.length; i += 1) {
    if (s[i] !== lower[i]) result = `${result} ${s[i]}`;
    else result += lower[i];
  }
  return result;
};

export const formatAssetType = (assetTypeValue) => {
  switch (assetTypeValue) {
    case 'GasContainer':
    case 1:
      return 'Gas Container';
    case 'LiquidContainer':
    case 2:
      return 'Liquid Container';
    case 3:
    case 'PackagedProduct':
      return 'Packaged Product';
    case 4:
    case 'Chassis':
      return 'Chassis';
    default:
      if (assetTypeValue != null) {
        return `Unknown Asset Type: ${assetTypeValue}`;
      }
      return 'Unknown Asset Type';
  }
};

export const getAssetNavigationPath = (assetTypeValue) => {
  switch (assetTypeValue) {
    case 'GasContainer':
    case 1:
      return 'gas-container';
    case 'LiquidContainer':
    case 2:
      return 'liquid-container';
    case 3:
    case 'PackagedProduct':
      return 'packaged-product';
    case 4:
    case 'Chassis':
      return 'chassis';
    default:
      console.error(`Invalid asset type passed: ${assetTypeValue}`);
      return 'liquid-container';
  }
};

export const formatWorkOrderStatus = (value) => {
  switch (value) {
    case 'Open':
    case 1:
    case '1':
      return 'Open';
    case 'Scheduled':
    case 2:
    case '2':
      return 'Scheduled';
    case 3:
    case '3':
    case 'InProgress':
      return 'In Progress';
    case 4:
    case '4':
    case 'Completed':
      return 'Completed';
    case 5:
    case '5':
    case 'Closed':
      return 'Closed';
    case 6:
    case '6':
    case 'Cancelled':
      return 'Cancelled';
    default:
      if (value != null) {
        return `Unknown Asset Type: ${value}`;
      }
      return 'Unknown Asset Type';
  }
};

export const formatPurchaseOrderStatus = (value) => {
  switch (value) {
    case 'Open':
    case 1:
    case '1':
      return 'Open';
    case 'Approved':
    case 2:
    case '2':
      return 'Approved';
    case 3:
    case '3':
    case 'Submitted':
      return 'Submitted';
    case 4:
    case '4':
    case 'Received':
      return 'Received';
    case 5:
    case '5':
    case 'Rejected':
      return 'Rejected';
    case 6:
    case '6':
    case 'Closed':
      return 'Closed';
    default:
      if (value != null) {
        return `Unknown Asset Type: ${value}`;
      }
      return 'Unknown Asset Type';
  }
};

export const formatMaintenanceRequestLineItemType = (lineItemTypeValue) => {
  switch (lineItemTypeValue) {
    case 'Parts':
    case 1:
      return 'Parts';
    case 'Labor':
    case 2:
      return 'Labor';
    case 3:
    case 'Vacuum':
      return 'Vacuum';
    default:
      if (lineItemTypeValue != null) {
        return `Unknown Line Item Type: ${lineItemTypeValue}`;
      }
      return 'Unknown Line Item Type';
  }
};

export const convertDateToUTCDay = (date) => {
  if (date) {
    let day = date.getDate();
    if (day < 10) day = `0${day}`;
    let month = date.getMonth() + 1; // Months start at 0!
    if (month < 10) month = `0${month}`;
    const utcValue = `${date.getFullYear()}-${month}-${day}T00:00:00.000Z`;
    return new Date(utcValue);
  }
  return null;
};

export const truncateString = (string, maxLength) => (string.length > maxLength
  ? `${string.substring(0, maxLength)}…`
  : string);

export const datePreferenceOptions = ['mm/dd/yyyy', 'dd/mm/yyyy', 'yyyy/dd/mm']; // should the last one be yyyy/mm/dd?

export const formatCurrency = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return null;
  }
  return new Intl.NumberFormat('en-US', {
    style: 'currency', currency: 'USD', currencyDisplay: 'narrowSymbol', currencySign: 'accounting',
  }).format(value);
};
export const formatNumber = (value) => {
  if (typeof value === 'undefined' || value === null) {
    return null;
  }
  return new Intl.NumberFormat('en-US', {

  }).format(value);
};

export const formatNumberPrecision = (value, precision) => {
  if (typeof value === 'undefined' || value === null) {
    return null;
  }
  return new Intl.NumberFormat('en-US', {
    maximumFractionDigits: precision,
  }).format(value);
};

export const formatFormValue = (format, value) => {
  if (!format) return value;
  if (format === 'currency') return formatCurrency(value);
  if (format === 'number') return formatNumber(value);
  return value;
};

export const capitalize = (str) => {
  const newString = str[0].toUpperCase() + str.slice(1);
  return newString;
};

export const setDateAsUTC = (d) => {
  const date = new Date(d);
  return new Date(
    Date.UTC(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
      date.getHours(),
      date.getMinutes(),
      date.getSeconds(),
    ),
  );
};
