import React, {
  useCallback, useMemo, useRef, useState,
} from 'react';
import {
  Button, Collapse,
  Form, InputGroup,
} from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import { getDateRangeFilterValue, getDisplayNameByOID, sortByBasic } from '../../../services/table-helpers';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectCurrentChassisId } from '../../../store/slices/chassis-slice';
import { selectCurrentGasContainerId } from '../../../store/slices/gas-containers-slice';
import { selectAllActiveLocationObjects } from '../../../store/slices/legacy-slice';
import { selectCurrentLiquidContainerId } from '../../../store/slices/liquid-containers-slice';
import { selectMaintenanceRequestDropdownObjects } from '../../../store/slices/maintenance-requests-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import { selectChassisWorkOrders, selectGasContainerWorkOrders, selectLiquidContainerWorkOrders } from '../../../store/slices/work-orders-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import StyledDatePicker from '../../UI/molecules/DateInput/styled-date-picker';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

const hiddenColumns = [
  'dateFilterColumn',
];

function AssetWorkOrdersTable(props) {
  const [filterOpen, setFilterOpen] = useState(false);
  const workOrdersFetchStatus = useSelector((state) => state.workOrder.workOrdersFetch.status);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const usersByOid = useSelector(selectUsersByOid);
  let assetWorkOrders = null;
  let assetId = null;
  if (props.assetTypeId === 2) {
    assetWorkOrders = useSelector(selectLiquidContainerWorkOrders);
    assetId = useSelector(selectCurrentLiquidContainerId);
  } else if (props.assetTypeId === 1) {
    assetWorkOrders = useSelector(selectGasContainerWorkOrders);
    assetId = useSelector(selectCurrentGasContainerId);
  } else if (props.assetTypeId === 4) {
    assetWorkOrders = useSelector(selectChassisWorkOrders);
    assetId = useSelector(selectCurrentChassisId);
  }

  const workOrdersFetchError = useSelector((state) => state.workOrder.workOrdersFetch.error);

  const { workOrderStatuses } = useSelector(
    selectMaintenanceRequestDropdownObjects,
  );

  const workOrdersData = useMemo(() => {
    if (assetWorkOrders) {
      if (props.asset) {
        return Object.values(assetWorkOrders).filter(
          (row) => row.assetSerialNumber === assetId,
        ).sort((a, b) => sortByBasic(new Date(a.created), new Date(b.created))).reverse();
      }
      return Object.values(assetWorkOrders);
    }
    return [];
  }, [assetWorkOrders, workOrderStatuses]);

  const getDisplayName = useCallback((OID) => {
    return getDisplayNameByOID(OID, usersByOid);
  }, [usersByOid]);

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const filterDatesByRange = (rows, ids, filterValue) => {
    let { start, end } = filterValue;

    // check that values are not NaN
    start = Number.isNaN(start) ? null : start;
    end = Number.isNaN(end) ? null : end;

    // Set proper min and max bounds
    let min = new Date(start).getTime();
    let max;
    if (end == null || end === '') {
      max = Infinity;
    } else {
      max = new Date(end).getTime();
    }

    // Swap is min is less tahn max
    if (min > max) {
      const temp = min;
      min = max;
      max = temp;
    }

    ids = ['estimatedStartDate', 'estimatedEndDate'];

    return rows.filter((row) => ids.every((id) => {
      const rowValue = row.values[id];
      // do not filter out if a date is null. (unless its the start date)
      if (id !== 'estimatedStartDate' && row.values[id] == null) {
        return true;
      }

      const rowTimeStamp = new Date(rowValue).getTime();
      return rowTimeStamp >= min && rowTimeStamp <= max;
    }));
  };

  const navigate = useNavigate();

  const locations = useSelector(selectAllActiveLocationObjects);

  const workOrdersColumns = useMemo(
    () => [
      {
        Header: 'ID',
        accessor: 'workOrderId',
        Cell: ({ value }) => (<NavLink to={`/maintenance/work-orders/${value}`}>{value}</NavLink>),
      },
      {
        Header: 'Work Order Status',
        accessor: 'workOrderStatusId',
        Cell: ({ value }) => (value in workOrderStatuses ? workOrderStatuses[value].name : '?'),
      },
      {
        Header: 'Location',
        id: 'locationId',
        accessor: ({ locationId: value }) => locations[value]?.LocationName ?? value,
      },
      {
        Header: 'Approved By',
        accessor: 'approvedBy',
      },
      {
        Header: 'Completed Date',
        accessor: 'completedDate',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
      // {
      //   Header: 'Estimated End Date',
      //   accessor: 'estimatedEndDate',
      //   Cell: ({ value }) => (value == null ? null : formatDate(value, datePreference)),
      // },
      // {
      //   Header: 'Estimated Duration',
      //   accessor: 'estimatedDuration',
      // },
      // {
      //   Header: 'Completed Date',
      //   accessor: 'TODO: add completed date',
      // },
      {
        Header: 'Filter',
        id: 'dateFilterColumn',
        filter: filterDatesByRange,
      },
      {
        Header: 'Description',
        accessor: 'comments',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [workOrderStatuses, locations],
  );

  const tableInstance = useRef(null);

  const handleSelectFilterChange = (event, filterId) => {
    tableInstance.current.setFilter(filterId, event.target.value);
  };

  const handleRangeDateFilterChange = (timeObj, dateChangeId) => {
    const filterValue = getDateRangeFilterValue(
      timeObj,
      dateChangeId,
      setStartDateFilter,
      setEndDateFilter,
      startDateFilter,
      endDateFilter,
    );

    tableInstance.current.setFilter('dateFilterColumn', filterValue);
  };

  const workOrdersContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={tableInstance} />
          <Button variant="primary" size="sm" onClick={props.handleModalShow}>Add Work Order</Button>
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'workOrderStatusId'); }}>
                  <option value="" key="workOrderStatusId-null">-- Status --</option>
                  {Object.keys(workOrderStatuses).map((obj, index) => (
                    <option value={workOrderStatuses[obj].id} key={`workOrderStatusId-${index}`}>{workOrderStatuses[obj].name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'startDate'); }}
                  value={startDateFilter}
                />
                <Form.Text className="text-muted">Start Date</Form.Text>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'endDate'); }}
                  value={endDateFilter}
                />
                <Form.Text className="text-muted">End Date</Form.Text>
              </Form.Group>
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={workOrdersColumns}
        data={workOrdersData}
        ref={tableInstance}
        initialState={{ hiddenColumns }}
        rowProps={(row) => ({
          onClick: () => {
            // handleRowClick(row.original.serialNumber);
          },
        })}
      />
    </>
  );

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${workOrdersFetchStatus === 'loading' ? ' creation-loading' : ''}`}>
        {workOrdersContent}
      </div>
      {workOrdersFetchStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default AssetWorkOrdersTable;
