import React from 'react';
import { NavLink } from 'react-router-dom';

function SideBarButton(props) {
  let icon = null;
  if (props.icon) {
    icon = (<i className={`bi-${props.icon} nav-icon fas ml-1`} />);
  }
  return (
    <NavLink
      end
      to={props.to}
      onClick={props.onClick}
      className={({ isActive }) => `nav-link pt-2 pb-2 pr-3 pl-3 ${isActive && props.to !== '#' ? 'active' : 'notactive'}`}
    >
      {icon}
      {' '}
      <p className="text-nowrap">
        {props.text}
        {props.hasSubMenu && <i className="bi-caret-left fas right" />}
      </p>
    </NavLink>
  );
}

export default SideBarButton;
