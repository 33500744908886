import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BsFillCheckCircleFill } from 'react-icons/bs';

function ActiveIcon() {
  const renderTooltip = (props) => (
    <Tooltip id="tooltip-top" {...props}>
      Active
    </Tooltip>
  );

  return (
    <OverlayTrigger
      placement="left"
      overlay={renderTooltip}
    >
      <div>
        <BsFillCheckCircleFill data-cy="isActive-icon" className="text-success" />
      </div>
    </OverlayTrigger>
  );
}

export default ActiveIcon;
