import { average } from "../../../services/array-helpers";
import { exportDemandForecasting, getRTUDetails } from "../../../services/requests";
import { ChartProps } from "../../UI/view/FormItems/Chart";
import { FormItemType } from "../../UI/view/component-map";
import { FilterType } from "../../UI/view/list-view";
import { rtuExport } from "./rtu-export";

export function generateNumbers(n) {
  return Array(n).fill(1).map((e,i)=>e+(i*1));
}

export const rtuChart = {
  
  customExport: rtuExport,
  enableExport: true,
  dataSource: getRTUDetails,
  dataSourceArgs: (entity, filters) => {
    const {rtuId, channelId} = entity;
    const {days, viewAllReadings} = filters;
    return [
      rtuId,
      channelId,
      days,
      viewAllReadings
    ]
  },
  filters: [
    {
      fieldName: 'days',
      type: FilterType.combo,
      number: true,
      label: 'Days',
      filterValue: 30,
      options: () => {
        return generateNumbers(30).map(x => {
          return { value: 31 - x, label: `${31 - x} Days` }     
        });
      }
    },
    {
      fieldName: 'viewAllReadings',
      type: FilterType.simple,
      label: 'View All Readings',
      filterValue: false
    }
  ],
  metrics: [
    {
      label: ({days}) => {
        return `Usage Delta (over last ${days} days)`;
      },
      units: 'psi/day',
      value: (data) => {
        const adapted = data.map((d,idx) => ({
          ...d,
          previous: idx == 0 ? null : data[idx - 1].readingValue,
        }));
        const filtered = adapted.map(d => ({
          ...d,
          delta: d.previous != null ? (d.readingValue - d.previous) : null
        })).filter(d => d.previous != null && d.delta > -100);

        var result = Math.round(average(filtered, 'delta'));
        return result;
      }
    }
  ],
  series: [
    {
      name: 'He Pressure',
      type: 'line',
      x: 'readingTimestamp',
      y: 'readingValue'
    },
    {
      name: 'Full Point',
      type: 'line',
      x: 'readingTimestamp',
      y: 'fullPoint'
    },
    {
      name: 'Order Point',
      type: 'line',
      x: 'readingTimestamp',
      y: 'orderPoint'
    },
    {
      name: 'Reserve Point',
      type: 'line',
      x: 'readingTimestamp',
      y: 'reservePoint'
    },
    {
      name: 'Outage Point',
      type: 'line',
      x: 'readingTimestamp',
      y: 'outagePoint'
    },
  ]

} as ChartProps