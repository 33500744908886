/* eslint-disable max-len */
import React, { useEffect, useMemo, useState } from 'react';
import { Container } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { setCreatePurchaseOrderStatus } from '../../../store/slices/purchase-orders-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import CreatePurchaseOrderModal from './create-purchase-order-modal';
import PurchaseOrdersTable from './purchase-orders-table';

function PurchaseOrders() {
  usePageTitle('Purchase Order Management');

  const purchaseOrderCreateStatus = useSelector(
    (state) => state.purchaseOrder.purchaseOrderCreate.status,
  );

  const purchaseOrderCreateError = useSelector(
    (state) => state.purchaseOrder.purchaseOrderCreate.error,
  );

  const [modalShow, setModalShow] = useState(false);

  const [showErrorText, setShowErrorText] = useState(false);

  const dispatch = useDispatch();

  const purchaseOrderCreateNotify = () => toast.success('Successfully Created Purchase Order');
  const purchaseOrderFailNotify = (error) => toast.error(`Failed to create Purchase Order \n${error}`);

  const resetModal = () => {
    setModalShow(false);
    setShowErrorText(false);
  };

  useEffect(() => {
    if (purchaseOrderCreateStatus === 'succeeded') {
      setModalShow(false);
      setShowErrorText(false);
      purchaseOrderCreateNotify();
      dispatch(setCreatePurchaseOrderStatus('idle'));
    } else if (purchaseOrderCreateStatus === 'failed') {
      if (purchaseOrderCreateError.includes('duplicate')) {
        setShowErrorText(true);
        purchaseOrderFailNotify('Duplicate Purchase Order Number');
      } else {
        purchaseOrderFailNotify(purchaseOrderCreateError);
        setModalShow(false);
      }
      dispatch(setCreatePurchaseOrderStatus('idle'));
    }
  }, [purchaseOrderCreateStatus]);

  const breadcrumbs = useMemo(() => [
    {
      name: 'Purchase Order Management',
      icon: 'receipt-cutoff',
      to: 'maintenance/purchase-order',
    },
  ], []);

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column mb-2">
      <PageHeader header="Purchase Order Management" breadcrumbs={breadcrumbs} />
      <PurchaseOrdersTable setModalShow={setModalShow} />
      <CreatePurchaseOrderModal show={modalShow} onHide={() => resetModal()} showErrorText={showErrorText} />
    </Container>
  );
}

export default PurchaseOrders;
