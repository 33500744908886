import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchLiquidContainer, selectCurrentLiquidContainer, selectLiquidContainers,
  setCreateAttachmentStatus, setCurrentLiquidContainerId, setLiquidContainerUpdateStatus,
  setRemoveAttachmentStatus,
} from '../../../store/slices/liquid-containers-slice';
import { fetchAssetMaintenanceRequests, setFetchMaintenanceRequestStatus } from '../../../store/slices/maintenance-requests-slice';
import { setFetchWorkOrderStatus } from '../../../store/slices/work-orders-slice';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import AssetDetailsTabs from '../../UI/organisms/AssetDetailsTabs/asset-details-tabs';
import AssetFileAttachment from '../../UI/organisms/FileAttachment/asset-file-attachment';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import AssetMaintenanceRequests from '../MaintenanceDashboard/asset-maintenance-requests';
import AssetWorkOrders from '../WorkOrders/asset-work-orders';
import LiquidContainerForm from './liquid-container-form';
import LiquidContainerInfo from './liquid-container-info';
import { LiquidHistoryListViewComponent } from './liquid-history-list';
import { SensorInfo } from './liquid-sensor-info-tab';

function LiquidContainerDetails(props) {
  const { liquidContainerId } = useParams();

  const liquidContainerFetchSingleStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerFetchSingle.status,
  );

  const liquidContainersUpdateStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerUpdate.status,
  );

  const liquidContainerAttachmentCreateStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerAttachmentCreate.status,
  );

  const liquidContainerAttachmentRemoveStatus = useSelector(
    (state) => state.liquidContainer.liquidContainerAttachmentRemove.status,
  );

  const liquidContainerUpdateNotify = () => toast.success('Successfully Updated Liquid Container');
  const liquidContainerAttachmentCreateNotify = () => toast.success('Successfully Uploaded attachment');
  const liquidContainerAttachmentRemoveNotify = () => toast.success('Successfully Deleted attachment');

  const currentLiquidContainer = useSelector(selectCurrentLiquidContainer);
  const liquidContainers = useSelector(selectLiquidContainers);

  const breadcrumbs = useMemo(() => [
    {
      name: 'Assets',
      icon: 'boxes',
      to: '/assets',
    },
    {
      name: liquidContainerId,
      icon: 'box',
      to: '',
    },
  ], [liquidContainerId]);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (liquidContainerFetchSingleStatus === 'idle' || liquidContainerFetchSingleStatus === 'failed') {
      dispatch(fetchLiquidContainer(liquidContainerId));
    }

    dispatch(setCurrentLiquidContainerId(liquidContainerId));
  }, [liquidContainerFetchSingleStatus, liquidContainerId, dispatch]);

  const loadMaintenanceRequestData = useCallback(async () => {
    await dispatch(fetchAssetMaintenanceRequests({
      assetType: 2,
      assetSerialNumber: liquidContainerId,
    })).unwrap();
    dispatch(setFetchMaintenanceRequestStatus('idle'));
  }, [liquidContainerId]);

  useEffect(() => {
    if (liquidContainersUpdateStatus === 'succeeded') {
      liquidContainerUpdateNotify();
      dispatch(setLiquidContainerUpdateStatus('idle'));
      loadMaintenanceRequestData();
    }
    if (liquidContainerAttachmentCreateStatus === 'succeeded') {
      liquidContainerAttachmentCreateNotify();
      dispatch(setCreateAttachmentStatus('idle'));
    }
    if (liquidContainerAttachmentRemoveStatus === 'succeeded') {
      liquidContainerAttachmentRemoveNotify();
      dispatch(setRemoveAttachmentStatus('idle'));
    }
  }, [liquidContainersUpdateStatus,
    liquidContainerAttachmentCreateStatus,
    liquidContainerAttachmentRemoveStatus,
    loadMaintenanceRequestData]);

  useEffect(() => {
    loadData();
  }, [currentLiquidContainer]);

  useEffect(() => {
    dispatch(setFetchMaintenanceRequestStatus('idle'));
    dispatch(setFetchWorkOrderStatus('idle'));
    dispatch(fetchLiquidContainer(liquidContainerId));
  }, []);

  const list = useMemo(
    () => currentLiquidContainer?.attachmentList ?? [],
    [liquidContainers],
  );

  const liquidContainerStatusIsLoading = [
    liquidContainerFetchSingleStatus,
    liquidContainersUpdateStatus,
  ].includes('loading');

  return (
    <Container
      fluid
      className="h-100 d-flex flex-column"
    >
      <PageHeader
        header="Liquid"
        breadcrumbs={breadcrumbs}
      />
      <Row className="h-100 flex-grow-1 overflow-auto">
        <Col lg={3} className="h-100 fluid flex-column  overflow-auto">
          <LiquidContainerInfo
            liquidContainerStatusIsLoading={liquidContainerStatusIsLoading}
            currentLiquidContainer={currentLiquidContainer}
          />
          <AssetFileAttachment moduleId={liquidContainerId} moduleName="LiquidContainers" attachmentList={list} />
        </Col>
        <Col className="h-100 d-flex fluid">
          <AssetDetailsTabs
            generalInfoContents={(
              <LiquidContainerForm
                currentLiquidContainer={currentLiquidContainer}
                liquidContainerStatusIsLoading={liquidContainerStatusIsLoading}
              />
            )}
            workOrderContent={(
              <AssetWorkOrders
                assetType="Liquid Container"
                assetTypeId={2}
                assetSerialNumber={liquidContainerId}
              />
            )}
            maintenanceRequestContent={(
              <AssetMaintenanceRequests
                asset={currentLiquidContainer}
                assetTypeId={2}
                assetSerialNumber={liquidContainerId}
              />
            )}
            auditLogContent={(
              <EntityAuditLogs
                entityId={2}
                tablePK={liquidContainerId}
              />
            )}
            sensorContent={(
              <SensorInfo
                assetTypeId={2}
              />
            )}
            historyContent={(<LiquidHistoryListViewComponent />)}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default LiquidContainerDetails;
