import { GetLiquidCustomers } from "../../../services/requests";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createConnectedView } from "../../UI/view/view";
import { DewarCustomerList, DewarCustomerSelectList } from "../Customers/customer-dewar-list";
import { GasCustomerSelectList } from "../Customers/customer-gas-list";
import { LiquidCustomerSelectList } from "../Customers/customer-liquid-list";

export const createInvoice = {
  modalSize: "xl",
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Add Invoice',
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.select,
              required: true,
              label: 'Product Type',
              name: 'productType',
              defaultValue: null,
              fitToContent: true,
              width: 200,
              options: [
                {
                  id: null,
                  text: ''
                },
                {
                  id: 'Liquid',
                  text: 'Liquid'
                },
                {
                  id: 'Gas',
                  text: 'Gas'
                },
                {
                  id: 'Dewar',
                  text: 'Packaged Product'
                },
              ]
            },
          ]
        },
        {
          type: FormItemType.boundingBox,
          height: 500,
          items: [
            {
              type: FormItemType.label,
              hideIf: (myThing) => myThing.watch('productType'),
              label: 'Please first pick a product type'
            },
            {
              type: FormItemType.customField,
              style: {height: '100%', paddingBottom: 20},
              required: true,
              name: 'dewarCustomerId',
              label: 'Packaged Product Customer',
              component: DewarCustomerSelectList,
              hideIf: (myThing) => {
                return myThing.watch('productType') != 'Dewar'
              },
            },
            {
              type: FormItemType.customField,
              style: {height: '100%', paddingBottom: 20},
              required: true,
              name: 'liquidCustomerId',
              label: 'Liquid Customer',
              component: LiquidCustomerSelectList,
              hideIf: (myThing) => {
                return myThing.watch('productType') != 'Liquid'
              },
            },
            {
              type: FormItemType.customField,
              style: {height: '100%', paddingBottom: 20},
              required: true,
              name: 'gasCustomerId',
              label: 'Gas Customer',
              component: GasCustomerSelectList,
              hideIf: (myThing) => {
                return myThing.watch('productType') != 'Gas'
              },
            },
          ]
        },
        {
          name: 'billingType',
          defaultValue: 'MISC',
          hideIf: () => true
        }
      ]
    }
  ]
} as FormView;
