import React from 'react';
import { ListGroup, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { selectLiquidCustomerObjects } from '../../../store/slices/legacy-slice';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';
import { chassisLength, chassisTypes } from './chassis-values';

function ChassisInfo({ chassisStatusIsLoading, currentChassis, condensed }) {
  const chassisCustomerObjects = useSelector(selectLiquidCustomerObjects);

  return (
    <Callout className={`${chassisStatusIsLoading ? ' creation-loading' : ''}`}>
      {!condensed && (
      <>
        <h4>{currentChassis ? currentChassis.serialNumber : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}</h4>
        <p><i>{currentChassis ? currentChassis.comments : (<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}</i></p>
      </>
      )}
      {currentChassis
        ? (
          <ListGroup variant="unbordered">
            <ListGroup.Item>
              <InfoListItem
                name="Type"
                value={currentChassis.type !== null ? chassisTypes[currentChassis.type] : 'N/A'}
              />
            </ListGroup.Item>
            <ListGroup.Item>
              <InfoListItem
                name="Length"
                value={currentChassis.length !== null ? chassisLength[currentChassis.length] : 'N/A'}
              />
            </ListGroup.Item>
            <ListGroup.Item>
              <InfoListItem
                name="Owner"
                value={currentChassis.ownerCustomer && chassisCustomerObjects[currentChassis.ownerCustomer] ? chassisCustomerObjects[currentChassis.ownerCustomer].CustomerName : 'N/A'}
              />
            </ListGroup.Item>
          </ListGroup>
        )
        : (
          <ListGroup variant="flush">
            <ListGroup.Item>
              <InfoListItem
                name="Type"
                value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}
              />
            </ListGroup.Item>
            <ListGroup.Item>
              <InfoListItem
                name="Length"
                value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}
              />
            </ListGroup.Item>
            <ListGroup.Item>
              <InfoListItem
                name="Owner"
                value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}
              />
            </ListGroup.Item>
          </ListGroup>
        )}
    </Callout>
  );
}

export default ChassisInfo;
