import axios from "axios";
import { handleRequestError } from "../requests";


export const getRareGasReadings = async (serialNumber, range) => {
  if (!range) {
    const d = new Date();
    d.setMonth(d.getMonth() - 6);
    range = {
      start: d.toJSON(),
      end: (new Date()).toJSON(),
    };
  }
  const results = await axios.get(`/AssetManagement/ReadingDataRareGas/${serialNumber}/${range.start}/${range.end}`).catch(handleRequestError);
  return results.data;
};
export const getRareGasReadingsObj = async (argString) => {
  const args = argString.split('_')
  let range = null;
  if (args.length == 3) {
    range = {
      start: args[1],
      end: args[2]
    }
  }
  return await getRareGasReadings(args[0], range);
};


export const getRareGasModules = async () => {
  const results = await axios.get(`/RareGas/GetRareGasModules`).catch(handleRequestError);
  return results.data;
};
export const updateRareGasModule = async (asset) => {
  const results = await axios.put(`/RareGas/UpdateRareGasModule`, asset).catch(handleRequestError);
  return results.data;
};
export const insertRareGasModule = async (asset) => {
  const results = await axios.post(`/RareGas/InsertRareGasModule`, asset).catch(handleRequestError);
  return results.data;
};