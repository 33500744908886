import React from "react";
import { Button, Container, Modal, Spinner } from "react-bootstrap";
import { FrameWorkView } from "../view";


export interface FormModalProps {
  form, repository, title, onModalHide, show, size?: "sm" | "lg" | "xl"
};

export const FormModal = ({ form, repository, title, onModalHide, show, size} : FormModalProps) => {
  
  const TheForm = form;
  return (
    <Modal
      dialogClassName="rbac-add-modal"
      show={show}
      onHide={() => onModalHide()}
      size={size || "lg"}
      centered
      scrollable={false}
      backdrop={true}
    >
      <Modal.Header>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }}>
          <TheForm modalContext={{
            setModalClose: (doReload) => onModalHide(doReload)
          }} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}