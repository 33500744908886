import React from "react";
import useWindowSize from "use-window-size-v2";

const breakWidth = 1300;
export const MasterDetailLayout = ({field, renderFormItem}) => {
  const {width} = useWindowSize();
  if (field.items?.length != 2) {
    throw 'master detail layouts must have exactly 2 children in their items list!';
  }
  return (
    <div style={{ display: width > breakWidth  ? 'inline-flex' : 'flex', height: 'calc(100% - 60px)', width: '100%' }}>
      {width > breakWidth && <div style={{ width: '25%', minWidth: 300, height: '100%', paddingRight: 7.5, overflow: 'auto' }}>
        {renderFormItem({ item: field.items[0] })}
      </div>}
      <div style={{ width: width > breakWidth ?'75%' : '100%', height: '100%', paddingLeft: 7.5, paddingBottom: 12 }}>
        {renderFormItem({ item: field.items[1] })}
      </div>
    </div>
  );
}