import React, { useEffect } from 'react';
import {
  Col, Container, Form, Modal, Row,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../services/format';
import { setEntityAttachmentsFetchStatus } from '../../../store/slices/file-attachment-slice';
import {
  selectCurrentMaintenanceRequest,
  selectMaintenanceRequestDropdownValues,
  setUpdateMaintenanceRequestStatus,
  updateMaintenanceRequest,
} from '../../../store/slices/maintenance-requests-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import CustomModal from '../../UI/molecules/Modal/modal';
import FileAttachments from '../../UI/organisms/FileAttachment/file-attachment';

function MaintenanceRequestAttachments(props) {
  const defaultRequestFormValues = {
    complaintOrigin: null,
    complaintDate: null,
    maintenanceRequestId: null,
    maintenanceRequestStatusId: null,
    maintenanceRequestTypeId: null,
    maintenanceRequestType: null,
    assetTypeId: null,
    estimatedEffort: null,
    created: null,
    createdBy: null,
    details: null,
    plannedDueDate: null,
    returnToFacility: null,
    containerStatus: null,
    assetSerialNumber: null,
    workOrderId: null,
  };

  const dispatch = useDispatch();

  const maintenanceRequestUpdateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestUpdate.status,
  );
  const maintenanceRequestUpdateError = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestUpdate.error,
  );

  const usersByOid = useSelector(selectUsersByOid);

  const maintenanceRequestUpdateSuccessNotify = () => toast.success('Successfully updated Maintenance Request');
  const maintenanceRequestUpdateFailedNotify = (error) => toast.error(`Failed to update Maintenance Request\n${error}`);

  const gasContainerStatus = useSelector((state) => state.gasContainer.gasContainersFetch.status);
  const liquidContainerStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.status,
  );
  const chassisStatus = useSelector((state) => state.chassis.chassisFetch.status);

  const dropdownValues = useSelector((state) => state.attachment.dropdownValues);

  const {
    assetTypes,
    maintenanceRequestStatuses,
  } = useSelector(selectMaintenanceRequestDropdownValues);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultRequestFormValues,
  });

  const onClose = () => {
    dispatch(setEntityAttachmentsFetchStatus('idle'));
    reset();
    props.onHide();
  };

  useEffect(() => {
    if (maintenanceRequestUpdateStatus === 'succeeded' && props.show) {
      maintenanceRequestUpdateSuccessNotify();
      dispatch(setUpdateMaintenanceRequestStatus('idle'));
      onClose();
    }
    if (maintenanceRequestUpdateStatus === 'failed' && props.show) {
      maintenanceRequestUpdateFailedNotify(maintenanceRequestUpdateError);
      dispatch(setUpdateMaintenanceRequestStatus('idle'));
      onClose();
    }
  }, [maintenanceRequestUpdateStatus, props.show]);

  const maintenanceRequestStatusIsLoading = [
    maintenanceRequestUpdateStatus,
    gasContainerStatus,
    liquidContainerStatus,
    chassisStatus,
  ].includes('loading');

  const currentMaintenanceRequest = useSelector(selectCurrentMaintenanceRequest);

  const onApprove = (maintenanceRequest) => {
    dispatch(updateMaintenanceRequest({
      ...maintenanceRequest,
      approvedDate: formatDate(Date.now()),
      maintenanceRequestStatusId: 2,
    }));
  };

  const onReject = (maintenanceRequest) => {
    dispatch(updateMaintenanceRequest({ ...maintenanceRequest, maintenanceRequestStatusId: 3 }));
  };

  const onUpdate = (maintenanceRequest) => {
    dispatch(updateMaintenanceRequest({ ...maintenanceRequest }));
  };

  useEffect(() => {
    if (currentMaintenanceRequest) {
      Object.keys(defaultRequestFormValues).forEach((key) => {
        if ((
          key.toLowerCase().includes('date')
          || key === 'created'
          || key === ['modified']
        )
          && currentMaintenanceRequest[key]) {
          const dateObj = new Date(currentMaintenanceRequest[key]);
          if (Number.isNaN(dateObj.getTime())) {
            setValue(key, null);
          } else {
            setValue(key, dateObj);
          }
        } else {
          setValue(key, currentMaintenanceRequest[key]);
        }
      });
    }
  }, [currentMaintenanceRequest, props.show]);

  return (
    <CustomModal
      show={props.show}
      onHide={onClose}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          Maintenance Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container className="p-0 m-0">
          <Row className="mb-2">
            <Col>
              <Form.Group>
                <Form.Label>Request ID</Form.Label>
                <Form.Control disabled size="sm" value={currentMaintenanceRequest?.maintenanceRequestId ?? ''} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>Request Type</Form.Label>
                <Form.Control disabled size="sm" value={currentMaintenanceRequest?.maintenanceRequestType ?? ''} />
              </Form.Group>
            </Col>
          </Row>
        </Container>
        <FileAttachments
          entityName="Maintenance Request"
          entityPK={currentMaintenanceRequest.maintenanceRequestId}
        />
      </Modal.Body>

    </CustomModal>
  );
}

export default MaintenanceRequestAttachments;
