import React, { useEffect } from "react";
import { Form } from "react-bootstrap"

export const CheckField = ({ format, setValue, watch, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, errors, formState, editTableProps }) => {
  
  const hide = field.hideIf ? field.hideIf({editTableProps, rowIdentity, watch}) : false;
  return !hide && (
    <Form.Group className="mb-1" title={field.tooltip}>
      {field.label && <Form.Label>{field.label}</Form.Label>}
      <Form.Check
        disabled={field.disabled ? field.disabled({ watch, editTableProps }) : undefined}
        aria-label={field.label}
        style={{ position: 'relative !important' }}
        className="checkbox-field-custom"
        {...register(field.name)}
        type="switch"
        formControlName={field.name}
        label={""}
      />
    </Form.Group>
  );
}