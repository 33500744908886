
import { getLiquidBillingHeader, getLiquidBillingInvoices } from "../../../../services/requests/billing/liquid-billing-requests";
import { DataSource } from "../../../UI/view/data-source";

export const liquidMainDataSource = {
  request: getLiquidBillingInvoices,
  entityRequest: getLiquidBillingHeader,
  pk: 'billingId',
  name: 'liquidbilling',
} as DataSource;


