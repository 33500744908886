import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Col, Container, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentMaintenanceRequestTypeIdForTemplateEdit } from '../../../store/slices/maintenance-requests-slice';
import { fetchParts } from '../../../store/slices/parts-slice';
import { usePageTitle } from '../../hooks/usePageTitle';
import CustomModal from '../../UI/molecules/Modal/modal';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import RequestTypeTemplateInfo from './request-type-template-info';
import RequestsTabs from './requests-tabs';
import RequestTypeModal from './requests-type-modal';

function MaintenanceRequestTypeManagement(props) {
  usePageTitle('Maintenance Request Type Management');
  const breadcrumbs = useMemo(() => [
    {
      name: 'Maintenance',
      icon: 'wrench-adjustable',
      to: '/maintenance',
    },
    {
      name: 'Maintenance Request Type Management',
      icon: 'clipboard2-plus',
      to: '/management',
    },
  ], []);

  const dispatch = useDispatch();

  const partsStatus = useSelector(
    (state) => state.parts.partsFetch.status,
  );

  const loadData = useCallback(async () => {
    if (partsStatus === 'idle' || partsStatus === 'failed') {
      dispatch(fetchParts());
    }
  }, [partsStatus, dispatch]);
  useEffect(() => {
    dispatch(setCurrentMaintenanceRequestTypeIdForTemplateEdit(undefined));
    loadData();
  }, []);

  const [modalShow, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  return (
    <>
      <Container fluid className="h-100 m-0 p-0 d-flex flex-column mb-2">
        <PageHeader header="Maintenance Request Type Management" breadcrumbs={breadcrumbs} />
        <Row className="flex-grow-1 pb-2 overflow-auto">
          <Col className="h-100">
            <Row className="flex-grow-1 pb-2 overflow-auto pl-2 h-50 d-flex">
              <RequestsTabs
                header={<h5>Planned Request Types</h5>}
                planned
              />
            </Row>
            <Row className="flex-grow-1 pb-2 pl-2 overflow-auto h-50 d-flex">
              <RequestsTabs
                header={(
                  <div className="d-flex justify-content-between mb-1">
                    <h5>Unplanned Request Types</h5>
                    <Button
                      className="ml-5"
                      variant="primary"
                      onClick={handleModalShow}
                      size="sm"
                      title="Add Unplanned Maintenenace Request Type"
                    >
                      Add Unplanned Request Type
                    </Button>
                  </div>
                )}
                title="Unplanned Request Types"
              />
            </Row>
          </Col>
          <Col className="h-100">
            <Row className="flex-grow-1 pb-2 overflow-auto pl-2 h-100 d-flex">
              <RequestTypeTemplateInfo />
            </Row>
          </Col>
        </Row>
      </Container>
      <CustomModal show={modalShow} onHide={handleModalClose}>
        <RequestTypeModal
          createMode
          handleModalClose={handleModalClose}
          title="Add New Unplanned Maintenance Request Type"
        />
      </CustomModal>
    </>
  );
}

export default MaintenanceRequestTypeManagement;
