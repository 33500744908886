import { formatDate } from './format';

const numberStringCheck = (value1, value2) => {
  try {
    const stringValue1 = typeof (value1) === 'string' ? value1 : value1.toString();
    const stringValue2 = typeof (value2) === 'string' ? value2 : value2.toString();
    return stringValue1 === stringValue2;
  } catch {
    return false;
  }
};

export const checkFormIsDirty = (formValues, dataValues) => {
  let formDirty = false;
  if (dataValues) {
    Object.keys(formValues).forEach((field) => {
      if (field.toLowerCase().includes('date')) {
        const formDate = formatDate(formValues[field]);
        const dataDate = formatDate(dataValues[field]);
        if (formDate !== dataDate) {
          formDirty = true;
        }
      } else {
        const formValue = formValues[field];
        const dataValue = dataValues[field];
        if (formValue !== dataValue && !numberStringCheck(formValue, dataValue)) {
          formDirty = true;
        }
      }
    });
  }
  return formDirty;
};

export const unique = (data, selector) => [...new Set(data.filter((item) => selector(item)).map((item) => selector(item)))];
