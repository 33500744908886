// Remove? now grabbing from backend
export const assetTypes = [
  {
    AssetTypeId: 2,
    Name: 'LiquidContainer',
    DisplayName: 'Liquid Container',
  },
  {
    AssetTypeId: 1,
    Name: 'GasContainer',
    DisplayName: 'Gas Container',
  },
  {
    AssetTypeId: 3,
    Name: 'PackagedProduct',
    DisplayName: 'Packaged Product',
  },
  {
    AssetTypeId: 4,
    Name: 'Chassis',
    DisplayName: 'Chassis',
  },
];

// Remove? now using redux values
export const workOrderLineItemTypes = [
  {
    WorkOrderLineItemTypeId: 1,
    Name: 'Parts',
  },
  {
    WorkOrderLineItemTypeId: 2,
    Name: 'Labor',
  },
  {
    WorkOrderLineItemTypeId: 3,
    Name: 'Vacuum',
  },
];

// Remove? now using redux values
export const maintenanceRequestStatuses = [{
  MaintenanceRequestStatusId: 1,
  Name: 'New',
},
{
  MaintenanceRequestStatusId: 2,
  Name: 'Approved',
},
{
  MaintenanceRequestStatusId: 3,
  Name: 'Rejected',
},
];

export const containerStatus = [
  'Red (Out of Service)',
  'Yellow',
  'Green',
];

export const maintenanceRequestLineItemBody = {
  maintenanceRequestLineItemTypeId: 0,
};
