/* eslint-disable jsx-a11y/anchor-is-valid */
import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';
import toast from 'react-hot-toast';

import { loginRequest } from '../../../authConfig';
import Balloons from '../../UI/atoms/Balloons/balloons';

// redirect
function handleLogin(instance) {
  instance.loginRedirect(loginRequest).catch((e) => {
    console.error(e);
    toast.error(`Authentication Error Occurred\n${e}`);
  });
}

function LoginLoading(props) {
  const { instance } = useMsal();

  useEffect(() => {
    handleLogin(instance);
  }, []);

  return (
    <div className="auth-loading-container">
      <Balloons />
      <h4 className="text-light mt-3">Redirecting to Authentication Provider</h4>
      <p className="text-light my-1">
        Click
        {' '}
        <a className="btn-link text-light text-decoration-underline" onClick={() => handleLogin(instance)}><b>here</b></a>
        {' '}
        if you are not automatically redirected
      </p>
    </div>
  );
}

export default LoginLoading;
