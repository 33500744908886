import { formatCurrency, formatDate } from "../../../../services/format";
import { getLiquidContainers, getLiquidContainersMemoize } from "../../../../services/requests";
import { GetLiquidRentalBillingList, PreAuthorizeCreditCard, SendReport, UpdateBilling, deleteInvoice, downloadReport, getBillingHeader, reverseBilling } from "../../../../services/requests/billing/liquid-rental-billing-requests";

import { FormItemType } from "../../../UI/view/component-map";
import { DataSource } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { ListViewColumnType } from "../../../UI/view/list-view";
import { createConnectedView } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { liquidRentalReportLink, footerItems, gasRentalReportLink } from "../billing-invoice-edit";
import '../../../../services/array-helpers';
import { sum } from "../../../../services/array-helpers";

export const liquidrentalinvoicesDS = {
  request: GetLiquidRentalBillingList,
  pk: 'billingId',
  name: 'liquidrentalinvoice',
  updateRequest: UpdateBilling,
  entityRequest: getBillingHeader,
  /*   serverSideEvents: true */
} as DataSource;

export const liquidRentalsEdit = {
  editMode: true,
  pageHeader: 'Liquid Rentals Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Liquid Rentals Billing',
      icon: 'currency-dollar',
      to: '/billing/liquid#Invoices',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              header: ({ liquidrentalbill }) => liquidrentalbill?.current?.data?.customerName,
              infoListItems: [
    
                {
                  label: 'Customer',
                  name: 'customerName'
                },
                {
                  label: 'Customer Id',
                  name: 'customerId'
                },
                {
                  label: 'Invoice Number',
                  name: 'billingId'
                },
                {
                  label: 'JDE Batch Number',
                  name: 'jdebatchNumber'
                },
                {
                  label: 'Date Invoiced',
                  name: 'dateInvoiced',
                  value: ({ dateInvoiced }) => formatDate(dateInvoiced)
                },
                {
                  label: 'Billing Period',
                  name: 'billingPeriod',
                  value: ({ invoiceMonth, invoiceYear }) => `${invoiceMonth}/${invoiceYear}`
                },
                {
                  label: 'Free Rent Days Per Trip',
                  name: 'freeDays'
                },
                {
                  label: 'Rent Fee Per Day',
                  name: 'rentalFeePerDay',
                  value: ({rentalFeePerDay}) => formatCurrency(rentalFeePerDay)
                },
                {
                  label: 'Total',
                  value: (a, watch) => {
                    const vals = watch('liquidRentalBillingDetails');
                    if (!vals) return '';
                    return formatCurrency(sum(vals.map((v) => v.value)));
                  }
                },
    
              ]
            },
            {
              type: FormItemType.fileAttachments,
              entityTypeId: 13,
              entityName: 'Billing Invoice',
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Liquid Rentals',
              footerItems: footerItems('Liquid_Rental_Billing_Invoice', liquidRentalReportLink, PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, SendReport).filter(x => x.label != 'PreAuthorize Credit Card'),
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Rental Billing',
                  items: [
                    {
                      name: 'billingComments',
                      type: FormItemType.labelField,
                      style: {color: 'red'},
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          name: 'ponumber',
                          label: 'PO Number',
                          minWidth: 200,
                          fitToContent: true,
                          defaultValue: ''
                        },
                      ]
                    },
                    {
                      label: '$/Day',
                      name: 'rentalFeePerDay',
                      disabled: () => true
                    },
                    {
                      name: 'invoiceComments',
                      label: 'Invoice Notes',
                      type: FormItemType.textarea,
                      defaultValue: ''
                    },
                    {
                      label: 'Bypass Invoice',
                      name: 'jdebypassed',
                      type: FormItemType.checkboxField,
                      width: 140,
                      fitToContent: true,
                      hideIf: ({ watch }) => watch('jdebatchNumber')
                    },
                  ]
                }
              ],
            },
            {
              label: 'Invoice Contents', 
              footerItems: footerItems('Liquid_Rental_Billing_Invoice', liquidRentalReportLink, PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, SendReport).filter(x => x.label != 'PreAuthorize Credit Card'),
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Contents',
                  footerItems: [
                    {
                      type: FormItemType.submitButton,
                      label: 'Update Invoice',
                      variant: 'secondary',
                    }
                  ],
                  items: [
                    {
                      type: FormItemType.editTable,
                      name: 'liquidRentalBillingDetails',
                      variant: 'tabular',
                      pk: 'id',
                      toolbar: [
                        {
                          type: FormItemType.checkboxField,
                          inline: true,
                          label: 'Edit PO Number',
                          name: 'Postyle',
                          width: 130,
                          fitToContent: true,
                        },
                      ],
        /*               hideAddButton: true, */
                      columns: [
                        {
                          type: ListViewColumnType.delete,
                          id: 'deleteme'
                        },
                        {
                          accessor: 'ponumber',
                          Header: 'PO Number',
                          formItemProps: {
                            disabled: ({ watch }) => !watch('Postyle'),
                            name: 'ponumber',
                            defaultValue: '',
                            maxLength: 50,
                          }
                        },
                        {
                          Header: 'Liquid S/N',
                          accessor: 'containerId',
                          formItemProps: {
                            name: 'containerId',
                            className: 'readonly',
                            type: FormItemType.select,
                            required: true,
                            options: async () => {
                              const containers = await getLiquidContainersMemoize();
                              return [{ text: '', id: null }, ...containers.orderBy({selector: x => x.isActive, direction: 'desc'}, {selector: x => x.serialNumber, direction: 'asc'}).map(x => ({ text: x.serialNumber, id: x.serialNumber }))];
                            }
                          }
                        },
                        {
                          Header: 'Booking Id',
                          accessor: 'bookingId',
                          formItemProps: {
                            name: 'bookingId',
                          }
                        },
                        {
                          Header: 'Port Arrival Date',
                          accessor: 'startDate',
                          formItemProps: {
                            name: 'startDate',
                            type: FormItemType.date,
                          }
                        },
                        {
                          Header: 'Port Release Date',
                          accessor: 'endDate',
                          formItemProps: {
                            name: 'endDate',
                            type: FormItemType.date,
                          }
                        },
                        {
                          Header: 'Days Billable',
                          accessor: 'daysBilled',
                          formItemProps: {
                            name: 'daysBilled',
                            required: true,
                            onChange: (v, getValues, setValue, editTableProps) => {
                              const { rowIdentity } = editTableProps;
                              const {liquidRentalBillingDetails, rentalFeePerDay} = getValues();
                              const rowEntity = liquidRentalBillingDetails[rowIdentity];
  
                              const daysBilled = Number(v ?? 0);
                              const rentalFeePerDayNum = Number(rentalFeePerDay ?? 0);
                              setValue(`liquidRentalBillingDetails[${rowIdentity}].value`, (daysBilled ?? 0) * (rentalFeePerDayNum ?? 0))
                            },
                          }
                        },
/*                         {
                          Header: '$/Day',
                          accessor: 'rentalFeePerDay',
                          formItemProps: {
                            name: 'rentalFeePerDay',
                            className: 'readonly'
                          }
                        }, */
                        {
                          Header: 'Total',
                          accessor: 'value',
                          formItemProps: {
                            name: 'value',
                            number: true,
                            required: true
                          }
                        },
                      ]

                    },
                  ]
                }

              ]
            }
          ]
        }
      ]
    }
  ]
} as FormView;


export const LiquidRentalEditInvoice = createConnectedView({ form: liquidRentalsEdit, dataSource: liquidrentalinvoicesDS })


