import React from "react"
import { FormItemType } from "../component-map"

export const Container = ({field, renderItem, rowIdentity, editTableProps, watch}) => {
  const myItems = field.items.filter(x => x.type != FormItemType.footer);
  const myFooter = field.items.find(x => x.type == FormItemType.footer);
  return (
    <div style={{
      width: '100%',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'nowrap'
    }}>
      <div style={{
        flexGrow: 1,
        overflowY: 'auto',
        overflowX: 'hidden',
        minHeight: '2em',

        width: '100%',
        height: '100%',
        display: 'flex',
        flexFlow: 'column'
      }}>
        {myItems?.map((x: any) => renderItem(x))}
      </div>

      {myFooter && (
        // @ts-ignore
        <div style={{ flexShrink: 0, marginLeft: 'auto', padding: 8, borderTop: '1px solid #dee2e6', width: '100%', display: 'inline-flex' }}>
          {myFooter.items?.map((x: any) => renderItem(x))}
        </div>
      )}
    </div>
  )
}