import { getDropdownValuesMemoized, getMaintenaceDropdownValuesMemoized } from "../../../../services/requests";
import { FormItemType } from "../../../UI/view/component-map";
import { Repository } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";
import { containerStatus } from "../maintenance-request-values";
import { maintenanceRequestDataSource } from "./late-readings-ds";

export const creatMRRequest = {
  editMode: false,
  permissions: {
    read: [1, 19],
    write: [1, 19],
    admin: [1, 19],
  },
  items: [
    {
      type: FormItemType.row,
      items: [
        {
          name: 'assetType',
          label: 'Asset Type',
          disabled: () => true,
        },
        {
          name: 'assetSerialNumber',
          label: 'Unit Number',
          disabled: () => true,
        }
      ]
    },
    {
      name: 'requestType',
      disabled: () => true,
      label: 'Request Type'
    },
    {
      type: FormItemType.row,
      items: [
        {
          name: 'complaintOrigin',
          label: 'Complaint Origin',
          type: FormItemType.typeahead,
          options: async () => {
            const dropdownValues = await getMaintenaceDropdownValuesMemoized();
            return dropdownValues.complaintOrigins.map(c => ({
              id: c.id,
              label: c.name
            }));
          }
        },
        {
          name: 'complaintDate',
          label: 'Complaint Date',
          type: FormItemType.date,
          defaultValue: new Date()
        },
      ]
    },
    {
      name: 'details',
      label: 'Details',
      type: FormItemType.textarea
    },
    {
      type: FormItemType.row,
      items: [
        {
          label: 'Estimated Effort (Man Hrs.)',
          name: 'estimatedEffort',
          number: true
        },
        {
          label: 'Container Status',
          type: FormItemType.select,
          name: 'containerStatus',
          defaultValue: null,
          required: true,
          options: async () => {
            return [{ id: null, text: '' }, ...containerStatus.map((l, idx) => ({ id: idx, text: l }))]
          }
        }

      ]
    },
    {
      type: FormItemType.row,
      items: [
        {
          label: 'Return To Facility',
          name: 'returnToFacility',
          type: FormItemType.typeahead,
          options: async () => {
            const vals = await getDropdownValuesMemoized();
            const locations = vals.locations;
            return locations.map((l, idx) => ({ id: l.LocationID, label: l.LocationName }));
          }
        },
        {
          label: 'Planned Due Date',
          name: 'plannedDueDate',
          type: FormItemType.date,
        }
      ]
    },
    {
      name: 'assetTypeId',
      hideIf: () => true
    },
    {
      name: 'maintenanceRequestTypeId',
      hideIf: () => true
    },

    {
      name: 'MaintenanceRequestStatusId',
      hideIf: () => true,
      defaultValue: 1
    },
    {
      type: FormItemType.footer,
      items: [
        {
          type: FormItemType.button,
          label: 'Close',
          variant: 'secondary',
          size: '',
          handler: ({ modalContext }) => {
            modalContext.setModalClose()
          }
        },
        {
          type: FormItemType.button,
          label: 'Submit',
          variant: 'primary',
          size: '',
          style: {marginLeft: 'auto'},
          handler: async ({ handleSubmit, onSubmit }) => {
            try {
              await handleSubmit(onSubmit)();
            } catch {

            }
          }
        },
      ]
    }



  ]
} as FormView;

export const [CreateMRRequestForm, CreateMRRequestFormRepo] = createConnectedView2({ form: creatMRRequest, dataSource: maintenanceRequestDataSource });

export const [EditMRRequestForm, EditMRRequestFormRepo] = createConnectedView2({ form: {...creatMRRequest, editMode: true}, existingRepo: CreateMRRequestFormRepo as Repository });