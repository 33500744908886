import React from "react";
import { Col, Row } from "react-bootstrap";

export const FormRow = ({field, renderItem, rowIdentity, editTableProps, watch}) => {
  const hide = field.hideIf ? field.hideIf({editTableProps, rowIdentity, watch}) : false;
  return (
    !hide && <Row className="uiRow" style={field.style}>
      {field.items.map((item, key) => {
        const hidden = item?.hide ? item.hide({ watch }) : false;
        return (
          <Col key={key} style={{
            display: hidden ? 'none' : undefined,
            width: item.width ? item.width : undefined,
            maxWidth: item.width ? item.width : undefined,
          }} className={item.fitToContent ? "col-md-auto" : undefined}>
            {renderItem(item, rowIdentity, editTableProps)}
          </Col>
        )
      })}
    </Row>
  );
}