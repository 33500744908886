import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { getAdUsers } from '../../services/requests';
import { selectUsers } from './users-slice';

const initialState = {
  adUsers: [],
  status: 'idle',
  error: null,
};

export const fetchAdUsers = createAsyncThunk('adUser/fetchAdUsers', async (searchValue) => {
  const response = await getAdUsers(searchValue);
  return response;
});

export const adUsersSlice = createSlice({
  name: 'adUser',
  initialState,
  reducers: {
    setAdUsers: (state, action) => {
      state.adUsers = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAdUsers.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAdUsers.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.adUsers = action.payload;
      })
      .addCase(fetchAdUsers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const { setAdUsers } = adUsersSlice.actions;

export const selectAdUsers = (state) => state.adUser.adUsers;

export const selectAdUsersWithoutProfiles = createSelector(
  [selectAdUsers, selectUsers],
  (adUsers, users) => {
    const userIds = Object.values(users).map((u) => u.activeDirectoryId);
    const rval = [...adUsers.filter((adu) => !userIds.includes(adu.id))];
    return rval;
  },
);

export const selectAdUsersStatus = (state) => state.adUser.status;

export default adUsersSlice.reducer;
