/* eslint-disable import/no-named-as-default */
/* eslint-disable indent */
import 'chartjs-adapter-date-fns';

import React from 'react';
import {
  Card, Col, Row, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchGasAndChassisReadings, selectCurrentChassis, selectCurrentChassisReadings } from '../../../store/slices/chassis-slice';
// eslint-disable-next-line import/no-named-as-default-member
import DateRangePicker from '../../UI/molecules/DateInput/range-picker';
import { LocationMap } from '../../UI/organisms/Sensors/location-map';
import { SensorChart } from '../../UI/organisms/Sensors/sensor-chart';
import { SummaryTable } from './chassis-summary-table';

export const getDefaultDateRange = () => {
  const d = new Date();
  d.setMonth(d.getMonth() - 3);

  return {
    start: d,
    end: new Date(),
  };
};

export function SensorInfo() {
  const fetchStatus = useSelector(
    (state) => state.chassis.fetchGasAndChassisReadings.status,
  );
  const dispatch = useDispatch();
  const currentLiquidContainer = useSelector(selectCurrentChassis);

  React.useEffect(() => {
    if (currentLiquidContainer?.serialNumber) {
      const range = getDefaultDateRange();
      const arg = {
        serialNumber: currentLiquidContainer.serialNumber,
        range: { start: range.start.toJSON(), end: range.end.toJSON() },
        assetType: 'Chassis',
      };
      // eslint-disable-next-line spaced-comment
      dispatch(fetchGasAndChassisReadings(arg));
    }
  }, [currentLiquidContainer]);

  const {
    myTemp,
    myBattV,
    myLoc,
    myReadings,
} = useSelector(selectCurrentChassisReadings);

  const getTimestamp = (dateString) => {
    const dt = new Date(dateString);
    return dt.getTime();
  };
  const dp = (val, element) => {
    return {
      x: getTimestamp(element.readingTime),
      y: val,
    };
  };
  const uniqBy = (a, key) => {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      // eslint-disable-next-line no-return-assign, no-prototype-builtins
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  };

  return (
    <div className="container-fluid">
      <Row>
        <Col>
          <Card className="card-primary card-outline" style={{ width: '100%', marginTop: 6 }}>
            <Card.Header>
              <h3 className="card-title">Latest Readings</h3>
            </Card.Header>
            <Card.Body style={{ paddingBottom: '0px !important' }}>
              <SummaryTable />
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <DateRangePicker
        defaultValue={getDefaultDateRange()}
        onRangeChanged={(r) => {
          const arg = {
            serialNumber: currentLiquidContainer.serialNumber,
            range: {
              start: r.start.toJSON(),
              end: r.end.toJSON(),
            },
            assetType: 'Chassis',
          };
          dispatch(fetchGasAndChassisReadings(arg));
        }}
      />
      {fetchStatus === 'loading' && (
        <Spinner
          animation="border"
          variant="primary"
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate (-50%, -50%)',
          }}
        />
      )}
      {fetchStatus !== 'loading' && (
      <Row>
        <Col>
          <Card className="card-primary card-outline" style={{ width: '100%', height: '100%' }}>
            <Card.Header>
              <h3 className="card-title">Device Sensors</h3>
            </Card.Header>
            <Card.Body>
              <SensorChart
                containerReadings={[
                  {
                    label: 'Battery Voltage (V)',
                    data: uniqBy(myReadings.filter((x) => x.BatteryVoltage !== 'NaN' && !Number.isNaN(x.BatteryVoltage)).map((x) => dp((!Number.isNaN(x.BatteryVoltage) ? x.BatteryVoltage : ''), x)), JSON.stringify),
                  },
                  {
                    label: 'Temperature (F)',
                    data: uniqBy(myReadings.filter((x) => x.AmbientTemperature !== 'NaN' && !Number.isNaN(x.AmbientTemperature)).map((x) => dp((!Number.isNaN(x.AmbientTemperature) ? x.AmbientTemperature : ''), x)), JSON.stringify),
                  },
                ]}
              />
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="card-primary card-outline" style={{ width: '100%', height: '100%' }}>
            <Card.Header>
              <h3 className="card-title">Map</h3>
            </Card.Header>
            <Card.Body>
              <LocationMap
                assetType="chassis"
                readings={{
                  summary: myLoc,
                  breadcrumbs: myReadings,
                }}
              />
            </Card.Body>
          </Card>
        </Col>
      </Row>
)}
    </div>

  );
}
