import { AddGasCustomerToRTU } from "../../../services/requests";
import { FormItemType } from "../../UI/view/component-map";
import { DataSource } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView, createConnectedView2 } from "../../UI/view/view";
import { GasCustomerSelectList } from "../Customers/customer-gas-list";


export const addRTUDataSource = {
  createRequest: AddGasCustomerToRTU,
  name: 'addRTU',
  pk: 'customerId',
} as DataSource;

export const creatRTURequest = {
  editMode: false,
  items: [
    {
      type: FormItemType.boundingBox,
      height: 500,
      items: [
        {
          type: FormItemType.customField,
          style: { height: '100%', paddingBottom: 20 },
          required: true,
          name: 'customerId',
          label: 'Gas Customer',
          component: GasCustomerSelectList,
        },
      ]
    },

    {
      type: FormItemType.footer,
      items: [
        {
          type: FormItemType.button,
          label: 'Close',
          variant: 'secondary',
          size: '',
          handler: ({ modalContext }) => {
            modalContext.setModalClose()
          }
        },
        {
          type: FormItemType.button,
          label: 'Submit',
          variant: 'primary',
          size: '',
          style: { marginLeft: 'auto' },
          handler: async ({ handleSubmit, onSubmit }) => {
            try {
              await handleSubmit(onSubmit)();
            } catch {

            }
          }
        },
      ]
    }



  ]
} as FormView;

export const [CreateRTU, CreateRTURepo] = createConnectedView2({form: creatRTURequest, dataSource: addRTUDataSource});