/* eslint-disable max-len */
import './request-details-form.scss';

import React, { useEffect, useMemo, useState } from 'react';
import {
  Accordion,
  Button, Col, Container, Form, Modal, Row, Spinner,
} from 'react-bootstrap';
import { Typeahead } from 'react-bootstrap-typeahead';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay, formatAssetType } from '../../../services/format';
import { selectIsAdmin, selectIsMaintenanceManager } from '../../../store/slices/auth-slice';
import { selectAllActiveLocationObjects } from '../../../store/slices/legacy-slice';
import {
  selectCurrentMaintenanceRequest,
  selectMaintenanceRequestDropdownValues,
  selectMaintenanceRequestTypes,
  setUpdateMaintenanceRequestStatus,
  updateMaintenanceRequest,
} from '../../../store/slices/maintenance-requests-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import DateInput from '../../UI/molecules/DateInput/date-input';
import CustomModal from '../../UI/molecules/Modal/modal';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import { containerStatus } from './maintenance-request-values';

function RequestDetailsForm(props) {
  const defaultRequestFormValues = {
    complaintOrigin: null,
    complaintDate: null,
    maintenanceRequestId: null,
    maintenanceRequestStatusId: null,
    maintenanceRequestTypeId: null,
    maintenanceRequestType: null,
    assetTypeId: null,
    estimatedEffort: null,
    created: null,
    createdBy: null,
    details: null,
    plannedDueDate: null,
    overridePlanned: false, // Not stored in db only for from State.
    overriddenDueDate: null,
    returnToFacility: null,
    containerStatus: null,
    assetSerialNumber: null,
    workOrderId: null,
  };

  const dispatch = useDispatch();

  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);

  const isAdmin = useSelector(selectIsAdmin);

  const maintenanceRequestUpdateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestUpdate.status,
  );
  const maintenanceRequestUpdateError = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestUpdate.error,
  );

  const usersByOid = useSelector(selectUsersByOid);

  const maintenanceRequestUpdateSuccessNotify = () => toast.success('Successfully updated Maintenance Request');
  const maintenanceRequestUpdateFailedNotify = (error) => toast.error(`Failed to update Maintenance Request\n${error}`);

  const gasContainerStatus = useSelector((state) => state.gasContainer.gasContainersFetch.status);
  const liquidContainerStatus = useSelector(
    (state) => state.liquidContainer.liquidContainersFetch.status,
  );
  const chassisStatus = useSelector((state) => state.chassis.chassisFetch.status);

  const {
    assetTypes,
    maintenanceRequestStatuses,
  } = useSelector(selectMaintenanceRequestDropdownValues);

  const locations = useSelector(selectAllActiveLocationObjects);

  const locationsList = useMemo(() => {
    return Object.values(locations ?? {});
  }, [locations]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultRequestFormValues,
  });

  const onClose = () => {
    reset();
    props.onHide();
  };

  useEffect(() => {
    if (maintenanceRequestUpdateStatus === 'succeeded' && props.show) {
      maintenanceRequestUpdateSuccessNotify();
      dispatch(setUpdateMaintenanceRequestStatus('idle'));
      onClose();
    }
    if (maintenanceRequestUpdateStatus === 'failed' && props.show) {
      maintenanceRequestUpdateFailedNotify(maintenanceRequestUpdateError);
      dispatch(setUpdateMaintenanceRequestStatus('idle'));
      onClose();
    }
  }, [maintenanceRequestUpdateStatus, props.show]);

  const maintenanceRequestStatusIsLoading = [
    maintenanceRequestUpdateStatus,
    gasContainerStatus,
    liquidContainerStatus,
    chassisStatus,
  ].includes('loading');

  const [unplannedMaintenanceTypes, setUnplannedMaintenanceTypes] = useState([]);

  const currentMaintenanceRequest = useSelector(selectCurrentMaintenanceRequest);
  const maintenanceRequestTypes = useSelector(selectMaintenanceRequestTypes);
  const isPlannedMaintenanceRequestType = useMemo(() => {
    return maintenanceRequestTypes[currentMaintenanceRequest.maintenanceRequestTypeId]?.isPlanned;
  }, [maintenanceRequestTypes, currentMaintenanceRequest]);

  const unplannedChassisRequestTypes = useMemo(() => {
    if (maintenanceRequestTypes) {
      return Object.values(maintenanceRequestTypes).filter(
        (type) => !type.isPlanned && type.assetTypeId === 4,
      ).map((type) => type.name);
    }
    return [];
  }, [maintenanceRequestTypes]);

  const unplannedLiquidRequestTypes = useMemo(() => {
    if (maintenanceRequestTypes) {
      return Object.values(maintenanceRequestTypes).filter(
        (type) => !type.isPlanned && type.assetTypeId === 2,
      ).map((type) => type.name);
    }
    return [];
  }, [maintenanceRequestTypes]);

  const unplannedGasRequestTypes = useMemo(() => {
    if (maintenanceRequestTypes) {
      return Object.values(maintenanceRequestTypes).filter(
        (type) => !type.isPlanned && type.assetTypeId === 1,
      ).map((type) => type.name);
    }
    return [];
  }, [maintenanceRequestTypes]);

  const onApprove = (maintenanceRequest) => {
    Object.keys(maintenanceRequest).forEach((key) => {
      if (key.toLowerCase().includes('date') && maintenanceRequest[key]) {
        maintenanceRequest[key] = convertDateToUTCDay(maintenanceRequest[key]);
      }
    });
    dispatch(updateMaintenanceRequest({
      ...maintenanceRequest,
      maintenanceRequestStatusId: 2,
    }));
  };

  const onReject = (maintenanceRequest) => {
    Object.keys(maintenanceRequest).forEach((key) => {
      if (key.toLowerCase().includes('date') && maintenanceRequest[key]) {
        maintenanceRequest[key] = convertDateToUTCDay(maintenanceRequest[key]);
      }
    });
    dispatch(updateMaintenanceRequest({ ...maintenanceRequest, maintenanceRequestStatusId: 3 }));
  };

  const onUpdate = (maintenanceRequest) => {
    Object.keys(maintenanceRequest).forEach((key) => {
      if (key.toLowerCase().includes('date') && maintenanceRequest[key]) {
        maintenanceRequest[key] = convertDateToUTCDay(maintenanceRequest[key]);
      }
    });
    dispatch(updateMaintenanceRequest({ ...maintenanceRequest }));
  };

  const [returnToFacility, setReturnToFacility] = useState([]);

  useEffect(() => {
    if (currentMaintenanceRequest) {
      Object.keys(defaultRequestFormValues).forEach((key) => {
        if ((
          key.toLowerCase().includes('date')
          || key === 'created'
         || key === ['modified']
        )
        && currentMaintenanceRequest[key]) {
          const dateObj = new Date(currentMaintenanceRequest[key]);
          if (Number.isNaN(dateObj.getTime())) {
            setValue(key, null);
          } else {
            setValue(key, dateObj);
          }
          if (key === 'overriddenDueDate') {
            setValue('overridePlanned', !!currentMaintenanceRequest[key]);
          }
        } else if (key === 'returnToFacility') {
          setValue(key, currentMaintenanceRequest[key]);
        } else if (key === 'assetTypeId') {
          if (currentMaintenanceRequest[key] === 1) {
            setUnplannedMaintenanceTypes(unplannedGasRequestTypes);
          } else if (currentMaintenanceRequest[key] === 2) {
            setUnplannedMaintenanceTypes(unplannedLiquidRequestTypes);
          } else if (currentMaintenanceRequest[key] === 4) {
            setUnplannedMaintenanceTypes(unplannedChassisRequestTypes);
          }
          setValue(key, currentMaintenanceRequest[key]);
        } else {
          setValue(key, currentMaintenanceRequest[key]);
        }
      });
    }
  }, [currentMaintenanceRequest, locations, props.show]);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'overridePlanned' && value.overridePlanned === false) {
        setValue('overriddenDueDate', null);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch]);

  const initialReturnToFacility = (locations ?? {})[currentMaintenanceRequest?.returnToFacility ?? '']?.LocationName ?? '';
  return (
    <CustomModal
      show={props.show}
      onHide={onClose}
    >
      <Modal.Header>
        <Modal.Title>
          Maintenance Request
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${maintenanceRequestStatusIsLoading ? ' visible' : ' invisible'}`}
        />
        <Form className={`${maintenanceRequestStatusIsLoading ? ' creation-loading' : ''}`}>
          <Container className="p-0 m-0">
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Request ID</Form.Label>
                  <Form.Control disabled size="sm" {...register('maintenanceRequestId')} />
                </Form.Group>
              </Col>

            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Asset Type</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('assetTypeId', { required: true })}
                    disabled
                    isInvalid={!!errors.assetTypeId}
                  >
                    {assetTypes.filter((type) => type.name !== 'PackagedProduct').map((type, i) => (
                      <option value={type.id} key={`asset-type-${type.id}`}>{formatAssetType(type.name)}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Asset Unit Number</Form.Label>
                  <Form.Control disabled size="sm" {...register('assetSerialNumber')} />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Created</Form.Label>
                  <DateInput
                    control={control}
                    disabled
                    formControlName="created"
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Created By</Form.Label>
                  <Form.Control
                    size="sm"
                    disabled
                    value={usersByOid[watch('createdBy')]?.displayName ?? ''}
                  />

                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Status</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('maintenanceRequestStatusId', { required: true })}
                    disabled
                    isInvalid={!!errors.maintenanceRequestStatusId}
                  >
                    {maintenanceRequestStatuses.map((type, i) => (
                      <option
                        value={type.id}
                        key={`status-${type.id}`}
                      >
                        {type.name}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Complaint Origin</Form.Label>
                  <Form.Control size="sm" {...register('complaintOrigin')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Complaint Date</Form.Label>
                  <DateInput
                    control={control}
                    disabled
                    formControlName="complaintDate"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Request Type</Form.Label>
                  {(isMaintenanceManager || isAdmin) ? (
                    <Controller
                      control={control}
                      name="maintenanceRequestTypeId"
                      defaultValue={null}
                      rules={{ required: true }}
                      render={({
                        field: {
                          onChange, value, ref,
                        },
                        fieldState: {
                          invalid, isTouched, isDirty, error,
                        },
                        formState,
                      }) => (
                        <Typeahead
                          ref={ref}
                          positionFixed
                          disabled={currentMaintenanceRequest.maintenanceRequestStatusId !== 1}
                          value={value}
                          {...register('maintenanceRequestTypeId')}
                          onChange={(e) => {
                            const id = Object.values(
                              maintenanceRequestTypes,
                            ).find((m) => (m.name === e[0]
                            && m.assetTypeId === currentMaintenanceRequest.assetTypeId
                            ))?.maintenanceRequestTypeId;
                            onChange(id);
                          }}
                          size="sm"
                          id="maintenanceRequestTypeId"
                          defaultSelected={[currentMaintenanceRequest?.maintenanceRequestType]}
                          options={unplannedMaintenanceTypes}
                          isInvalid={!!error}
                        />
                      )}
                    />
                  ) : (<Form.Control disabled size="sm" {...register('maintenanceRequestType')} />)}

                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Details</Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register('details')}
                    style={{ resize: 'none' }}
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row className="mt-1">
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Estimated Effort (Man Hrs.)</Form.Label>
                  <Form.Control size="sm" {...register('estimatedEffort')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Container Status</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('containerStatus')}
                  >
                    {containerStatus.map((status, index) => (
                      <option
                        value={index}
                        key={`customer-${index}`}
                      >
                        {status}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Return to Facility</Form.Label>
                  {/* {initialReturnToFacility
                  && ( */}
                  <Controller
                    control={control}
                    name="returnToFacility"
                    defaultValue={null}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        // value={value}
                        flip
                        onChange={(e) => {
                          onChange(locationsList?.find(
                            (l) => l?.LocationName === e[0],
                          )?.LocationID);
                        }}
                        size="sm"
                        id="returnToFacility"
                        options={locationsList.map((l) => l.LocationName)}
                        defaultSelected={[initialReturnToFacility]}
                        positionFixed
                        isInvalid={!!error}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Planned Due Date</Form.Label>
                  <DateInput
                    control={control}
                    formControlName="plannedDueDate"
                    disabled={(!!watch('overridePlanned') || isPlannedMaintenanceRequestType)}
                  />
                  <Form.Check
                    {...register('overridePlanned')}
                    type="switch"
                    inline
                    id="overridePlanned-switch"
                    label="Override"
                    className={`mt-1 ${isPlannedMaintenanceRequestType ? 'visible' : 'invisible'}`}
                  />
                </Form.Group>
              </Col>
              <Col className={watch('overridePlanned', false) ? 'visible' : 'invisible'}>
                <Form.Group className="mb-1">
                  <Form.Label>Overridden Due Date</Form.Label>
                  <DateInput
                    disabled={!watch('overridePlanned')}
                    control={control}
                    formControlName="overriddenDueDate"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Accordion>
                <Accordion.Item eventKey="0">
                  <Accordion.Header><Form.Label style={{ padding: '10px' }}>Audit Log</Form.Label></Accordion.Header>
                  <Accordion.Body className="overflow-auto" style={{ maxHeight: '200px' }}>
                    <EntityAuditLogs
                      entityId={6}
                      tableSchema="Maintenance"
                      tablePK={currentMaintenanceRequest.maintenanceRequestId}
                    />
                  </Accordion.Body>
                </Accordion.Item>
              </Accordion>
            </Row>

          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={props.onHide} variant="secondary">Close</Button>
        <div className="">
          <Button type="submit" onClick={handleSubmit(onUpdate)}>Update</Button>
          {currentMaintenanceRequest?.maintenanceRequestStatusId < 4
          && (
          <>
            &nbsp;&nbsp;&nbsp;
            <Button variant="danger" type="submit" onClick={handleSubmit(onReject)}>Reject</Button>
          &nbsp;
            <Button variant="success" type="submit" onClick={handleSubmit(onApprove)}>Approve</Button>
          </>
          )}

        </div>
      </Modal.Footer>
    </CustomModal>
  );
}

export default RequestDetailsForm;
