import React, { useCallback, useEffect, useMemo } from 'react';
import {
  Col, Container, Row,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchPackagedProduct,
  selectCurrentPackagedProduct,
  selectPackagedProducts,
  setCreateAttachmentStatus,
  setCurrentPackagedProductId,
  setPackagedProductUpdateStatus,
  setRemoveAttachmentStatus,
} from '../../../store/slices/packaged-products-slice';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import AssetDetailsTabs from '../../UI/organisms/AssetDetailsTabs/asset-details-tabs';
import PackagedProductHistory from '../../UI/organisms/AssetHistory/packaged-product-history';
import AssetFileAttachment from '../../UI/organisms/FileAttachment/asset-file-attachment';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import PackagedProductForm from './packaged-product-form';
import PackagedProductInfo from './packaged-product-info';

function PackagedProductDetails(props) {
  const { packagedProductId } = useParams();

  const packagedProducts = useSelector(selectPackagedProducts);
  const packagedProductError = useSelector(
    (state) => state.packagedProduct.packagedProductsFetch.error,
  );

  const packagedProductsFetchSingleStatus = useSelector(
    (state) => state.packagedProduct.packagedProductsFetchSingle.status,
  );

  const packagedProductsUpdateStatus = useSelector(
    (state) => state.packagedProduct.packagedProductUpdate.status,
  );

  const packagedProductAttachmentCreateStatus = useSelector(
    (state) => state.packagedProduct.packagedProductAttachmentCreate.status,
  );

  const packagedProductAttachmentRemoveStatus = useSelector(
    (state) => state.packagedProduct.packagedProductAttachmentRemove.status,
  );

  const packagedProductUpdateSuccessNotify = () => toast.success('Successfully Updated Packaged Product');
  const packagedProductAttachmentCreateNotify = () => toast.success('Successfully Uploaded attachment');
  const packagedProductAttachmentRemoveNotify = () => toast.success('Successfully Deleted attachment');

  const currentPackagedProduct = useSelector(selectCurrentPackagedProduct);

  const breadcrumbs = useMemo(() => [
    {
      name: 'Assets',
      icon: 'boxes',
      to: '/assets',

    },
    {
      name: packagedProductId,
      icon: 'box',
      to: '',
    },
  ], [packagedProductId]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (packagedProductsUpdateStatus === 'succeeded') {
      packagedProductUpdateSuccessNotify();
      dispatch(setPackagedProductUpdateStatus('idle'));
    }
    if (packagedProductAttachmentCreateStatus === 'succeeded') {
      packagedProductAttachmentCreateNotify();
      dispatch(setCreateAttachmentStatus('idle'));
    }
    if (packagedProductAttachmentRemoveStatus === 'succeeded') {
      packagedProductAttachmentRemoveNotify();
      dispatch(setRemoveAttachmentStatus('idle'));
    }
  }, [packagedProductsUpdateStatus,
    packagedProductAttachmentCreateStatus, packagedProductAttachmentRemoveStatus]);

  const loadData = useCallback(async () => {
    if (packagedProductsFetchSingleStatus === 'idle' || packagedProductsFetchSingleStatus === 'failed') {
      dispatch(fetchPackagedProduct(packagedProductId));
    }

    dispatch(setCurrentPackagedProductId(packagedProductId));
  }, [packagedProductsFetchSingleStatus, packagedProductId, dispatch]);

  useEffect(() => {
    loadData();
  }, [currentPackagedProduct]);

  useEffect(() => {
    dispatch(fetchPackagedProduct(packagedProductId));
  }, []);

  const list = useMemo(
    () => currentPackagedProduct?.attachmentList ?? [],
    [packagedProducts, currentPackagedProduct],
  );

  const packagedProductStatusIsLoading = packagedProductsFetchSingleStatus === 'loading' || packagedProductsUpdateStatus === 'loading';
  return (
    <Container
      fluid
      className="h-100 d-flex flex-column"
    >
      <PageHeader
        header="Packaged Product"
        breadcrumbs={breadcrumbs}
      />
      <Row className="h-100 flex-grow-1 overflow-auto">
        <Col lg={3} className="h-100 fluid flex-column overflow-auto">
          <PackagedProductInfo packagedProductStatusIsLoading={packagedProductStatusIsLoading} />
          <AssetFileAttachment moduleId={packagedProductId} moduleName="PackagedProducts" attachmentList={list} />
        </Col>
        <Col className="h-100 d-flex fluid">
          <AssetDetailsTabs
            generalInfoContents={(
              <PackagedProductForm
                currentPackagedProduct={currentPackagedProduct}
                packagedProductStatusIsLoading={packagedProductStatusIsLoading}
              />
            )}
            auditLogContent={(
              <EntityAuditLogs
                entityId={3}
                tablePK={packagedProductId}
              />
            )}
            historyContent={(
              <PackagedProductHistory
                assetType="PackagedProduct"
                serialNumber={packagedProductId}
              />
            )}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default PackagedProductDetails;
