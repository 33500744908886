import { useContext } from 'react';

import ConfirmContext from './confirm-context';
import { HIDE_CONFIRM, SHOW_CONFIRM } from './confirm-reducer';

let resolveCallback;
function useConfirm() {
  const [confirmState, dispatch] = useContext(ConfirmContext);

  const closeConfirm = () => {
    dispatch({
      type: HIDE_CONFIRM,
    });
  };

  const onConfirm = () => {
    closeConfirm();
    resolveCallback(true);
  };

  const onCancel = () => {
    closeConfirm();
    resolveCallback(false);
  };
  const confirm = (text, alertOnly) => {
    dispatch({
      type: SHOW_CONFIRM,
      payload: {
        text,
        alertOnly,
      },
    });
    return new Promise((res, rej) => {
      resolveCallback = res;
    });
  };

  return {
    confirm, onConfirm, onCancel, confirmState,
  };
}

export default useConfirm;
