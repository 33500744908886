import { GetLiquidCustomers, getCoordinates } from "../../../services/requests";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createConnectedView } from "../../UI/view/view";
import { analyzersEditTable } from "./locations-analyzers";
import { locationsDataSource, locationsPermissions } from "./locations-data-source";
import { fillSlotsEditTable } from "./locations-fill-slots";
import { gasPurity } from "./locations-gas-purity";
import { gasReceiversEditTable } from "./locations-gas-receivers";
import { liquidPurity } from "./locations-liquid-purity";
import { nitrogenTanksEditTable } from "./locations-nitrogen-tanks";

export const locationsEdit = {
  pageHeader: 'Location Update',
  editMode: true,
  permissions: locationsPermissions,
  breadcrumbs: [
    {
      name: 'Locations',
      icon: 'building',
      to: '/locations',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            { type: FormItemType.infoPane, infoListItems: [{ name: 'locationId', label: 'Location Id' }], header: (state: any) => state.locations?.current?.data?.locationName },
            { type: FormItemType.fileAttachments, entityTypeId: 10, entityName: 'Locations', permissions: locationsPermissions},
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'General Info',
              footerItems: [
                { type: FormItemType.submitButton, permissions: locationsPermissions, }
              ],
              items: [
                {
                  type: FormItemType.row,
                  items: [
                    {
                      type: FormItemType.fieldset,
                      label: 'Details',
                      items: [
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'locationId',
                              label: 'Location Id',
                              disabled: () => true,
                            },
                            {
                              name: 'locationName',
                              label: 'Location Name',
                              required: true,
                              maxLength: 50
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'addressName',
                              label: 'Address Name Line',
                              defaultValue: '',
                              maxLength: 255
                            },
                            {
                              name: 'addressLine1',
                              label: 'Address',
                              maxLength: 500,
                              defaultValue: '',
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'addressLine2',
                              label: 'City,State Zip',
                              maxLength: 500,
                              defaultValue: '',
                            },
                            {
                              name: 'type',
                              label: 'Type',
                              type: FormItemType.select,
                              defaultValue: '',
                              options: [
                                { text: "", id: '' },
                                {
                                  id: 'Refined',
                                  text: 'Refined'
                                }
                              ],
                              maxLength: 20
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'jdebranchId',
                              label: 'JDE Branch ID',
                              defaultValue: '',
                              maxLength: 12
                            },
                            {
                              name: 'stagingMiles',
                              label: 'Staging Miles'
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'residentCountry',
                              label: 'Resident Country',
                              type: FormItemType.select,
                              defaultValue: '',
                              options: [
                                { text: "", id: "" },
                                {
                                  id: 'USA',
                                  text: 'USA'
                                },
                                {
                                  id: 'QTR',
                                  text: 'Qatar'
                                },
                                {
                                  id: 'ALG',
                                  text: 'Algeria'
                                },
                              ]
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'legacyOrganization',
                              label: 'Legacy Organization',
                              type: FormItemType.select,
                              defaultValue: '',
                              options: [
                                { text: "", id: null },
                                {
                                  id: 'Praxair',
                                  text: 'Praxair'
                                },
                                {
                                  id: 'Linde APAC',
                                  text: 'Linde APAC'
                                },
                                {
                                  id: 'Linde EMEA',
                                  text: 'Linde EMEA'
                                },
                              ]
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'Assigned Liquid Customer ID',
                              name: 'assignedLiquidCustomerId',
                              type: FormItemType.select,
                              options: async () => {
                                const customers = await GetLiquidCustomers(null);
                                return [{ text: "", id: null }, ...customers.map(c => ({ id: c.customerId, text: c.customerName }))]
                              },
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'depressureVolume',
                              label: 'Depressure Volume'
                            },
                            {
                              name: 'gasReceiverWaterVolume',
                              label: 'Gas Receiver Water Volume',
                              number: true
                            },

                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'latitude',
                              label: 'Latitude',
                              number: true,
                            },
                            {
                              name: 'longitude',
                              label: 'Longitude',
                              number: true,
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              name: 'residualFormula',
                              label: 'Residual Formula'
                            },
                            {
                              name: 'netProductFormula',
                              label: 'Net Product Formula'
                            },
                            {
                              name: 'grossProductFormula',
                              label: 'Gross Product Formula'
                            },
                          ]
                        },
                      ]
                    },
                    {
                      type: FormItemType.fieldset,
                      label: 'Location Options',
                      items: [
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              items: [
                                {
                                  name: 'isRepairShop',
                                  label: 'Repair Shop',
                                  width: 300,
                                  inline: true,
                                  type: FormItemType.checkbox,
                                  fitToContent: true
                                },
                                {
                                  label: 'Trans Fill',
                                  width: 300,
                                  name: 'isTransfill',
                                  inline: true,
                                  type: FormItemType.checkbox,
                                  fitToContent: true
                                },
                                {
                                  label: 'Crude Location',
                                  width: 300,
                                  name: 'isCrudeLocation',
                                  inline: true,
                                  type: FormItemType.checkbox,
                                  fitToContent: true
                                },
                                {
                                  name: 'isTreatAsDomesticForPlanning',
                                  label: 'Treat as Domestic For Planning Tool',
                                  type: FormItemType.checkbox,
                                  number: false, required: true, defaultValue: false,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },

                                {
                                  name: 'isStagingFacility',
                                  label: 'Staging Facility',
                                  type: FormItemType.checkbox,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },
                                {
                                  name: 'isVirtualPlant',
                                  label: 'Virtual Plant',
                                  type: FormItemType.checkbox,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },
                                {
                                  label: 'Liquid Location',
                                  width: 300,
                                  name: 'isLiquidLocation',
                                  inline: true,
                                  type: FormItemType.checkbox,
                                  fitToContent: true
                                },
                                {
                                  label: 'Gas Location',
                                  width: 300,
                                  name: 'isGasLocation',
                                  inline: true,
                                  type: FormItemType.checkbox,
                                  fitToContent: true
                                },
                                {
                                  name: 'usePriorResCreditOnShipInvoice',
                                  label: 'Use Prior Resid Credit on Shp Inv',
                                  type: FormItemType.checkbox,
                                  defaultValue: false,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },

                                {
                                  name: 'isRentalBookingInsert',
                                  label: 'Rental Shipment Insert',
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                  type: FormItemType.checkbox,
                                  defaultValue: false
                                },
                                {
                                  name: 'bypassBilling',
                                  label: 'Bypass Billing',
                                  defaultValue: false,
                                  type: FormItemType.checkbox,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },
                                {
                                  name: 'isNotificationsEnabled',
                                  label: 'Enable Notifications',
                                  type: FormItemType.checkbox,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },
                                {
                                  name: 'isPassThroughMaintenanceBilling',
                                  label: 'Pass Through Maintenance Location',
                                  type: FormItemType.checkbox,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },


                                {
                                  name: 'weightUnits',
                                  label: 'Weight Units',
                                  number: true,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },
                                {
                                  name: 'pressureUnits',
                                  label: 'Pressure Units',
                                  number: true,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },
                                {
                                  name: 'invoiceUnits',
                                  label: 'Invoice Units',
                                  number: true,
                                  width: 300,
                                  inline: true,
                                  fitToContent: true,
                                },
                              ]
                            },
                          ]
                        },
                      ]
                    }
                  ]
                },

              ]
            },
            {
              label: 'Liquid Purity',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Liquid Purity',
                  items: liquidPurity
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: locationsPermissions,
                }
              ]
            },
            {
              label: 'Gas Purity',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Gas Purity',
                  items: gasPurity
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: locationsPermissions,
                }
              ]
            },
            {
              label: 'Gas Receivers',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Gas Receivers',
                  items: [
                    gasReceiversEditTable
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: locationsPermissions,
                }
              ]
            },
            {
              label: 'Nitrogen Tanks',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Nitrogen Tanks',
                  items: [
                    nitrogenTanksEditTable
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: locationsPermissions,
                }
              ]
            },
            {
              label: 'Site Analyzers',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Site Analyzers',
                  items: [
                    analyzersEditTable
                  ],
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: locationsPermissions,
                }
              ]
            },
            {
              label: 'Fill Slots',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Fill Slots',
                  items: [
                    fillSlotsEditTable
                  ],
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: locationsPermissions,
                }
              ]
            },
            {
              label: 'Audit Logs',
              unMountOnExit: true,
              items: [
                {
                  type: FormItemType.auditLogs,
                  name: 'Locations',
                }
              ]
            },
          ]
        }
      ]
    }


  ]

} as FormView;

export const LocationEditFormView = createConnectedView({ form: locationsEdit as any, dataSource: locationsDataSource as any });

