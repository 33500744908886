import { range } from 'lodash';
import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import {
  Button, ButtonGroup, Form, Tooltip,
} from 'react-bootstrap';
import { BsArrowDownUp, BsSortDown, BsSortUp } from 'react-icons/bs';
import {
  useFilters, useGlobalFilter, usePagination, useRowSelect, useSortBy, useTable,
} from 'react-table';

// eslint-disable-next-line object-curly-newline
const RowSelectTable = forwardRef(({
  columns,
  data,
  cellProps,
  rowProps,
  initialState,
  triggerExternalRenderUpdate,
  onCheckboxSelect,
  onRowSelect,
  noCheck,
  singleSelectMode,
  onDataFiltered,
  formItemRenderer,
  tableName,
}, ref) => {
  const startState = { pageIndex: 0, pageSize: 25, ...initialState };
  const instance = useTable(
    {
      columns,
      data,
      initialState: startState,
      autoResetFilters: false,
      autoResetGlobalFilter: false,
      stateReducer: singleSelectMode === true ? (newState, action) => {
        if (action.type === 'toggleRowSelected') {
          newState.selectedRowIds = {
            [action.id]: true,
          };
        }
        return newState;
      } : undefined,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect,
    (hooks) => {
      if (!noCheck) {
        hooks.visibleColumns.push((selectColumns) => [
          {
            id: 'selection',
            Header: !singleSelectMode ? ({ toggleRowSelected, isAllPageRowsSelected, page }) => {
              const modifiedOnChange = (event) => {
                page.forEach((row) => {
                // check each row if it is not disabled
                  if (!row.original.disabled) {
                    toggleRowSelected(row.id, event.currentTarget.checked);
                  }
                });
              };
              // Count number of selectable and selected rows in the current page
              // to determine the state of select all checkbox
              let selectableRowsInCurrentPage = 0;
              let selectedRowsInCurrentPage = 0;
              page.forEach((row) => {
                if (row.isSelected) { selectedRowsInCurrentPage += 1; }
                if (!row.original.disabled) { selectableRowsInCurrentPage += 1; }
              });
              // If there are no selectable rows in the current page
              // select all checkbox will be disabled -> see page 2
              const disabled = selectableRowsInCurrentPage === 0;
              const checked = (isAllPageRowsSelected
                || selectableRowsInCurrentPage === selectedRowsInCurrentPage)
              && !disabled;
              return (
                <div>
                  <IndeterminateCheckbox
                    onChange={modifiedOnChange}
                    checked={checked}
                    disabled={disabled}
                  />
                </div>
              );
            } : () => <div />,

            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox
                  disabled={row.original.disabled}
                  {...row.getToggleRowSelectedProps()}
                />
              </div>
            ),
          },
          ...selectColumns,
        ]);
      }
    },

  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page

    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    state: { pageIndex, pageSize, filters },
  } = instance;

  const [checked, setChecked] = useState(false);

  const renderDisabledTooltip = (info) => (
    <Tooltip id="button-tooltip">
      {info}
    </Tooltip>
  );

  const IndeterminateCheckbox = React.forwardRef(
    ({ indeterminate, disabled, ...rest }, ref1) => {
      const defaultRef = React.useRef();
      const resolvedRef = ref1 || defaultRef;

      React.useEffect(() => {
        resolvedRef.current.indeterminate = indeterminate;
      }, [resolvedRef, indeterminate]);
      if (disabled) {
        return (
          <div style={{ marginRight: 3 }}>
            <input type="checkbox" ref={resolvedRef} checked={checked} disabled {...rest} />
          </div>
        );
      }
      return (
        <input style={{ marginRight: 3 }} type="checkbox" ref={resolvedRef} checked={checked} {...rest} />
      );
    },
  );

  useEffect(() => {
    if (onDataFiltered) onDataFiltered(rows);
  }, [rows]);

  useImperativeHandle(ref, () => instance);

  useImperativeHandle(ref, () => ({
    ...instance,
    getSelectedRows: () => selectedFlatRows,
  }));

  if (onRowSelect) {
    useEffect(() => {
      onRowSelect(selectedFlatRows);
    }, [selectedFlatRows]);
  }

  const getPageRange = (currentPage, numPages) => {
    if (numPages < 5) {
      return range(1, numPages + 1);
    }
    if (currentPage <= 3) {
      return range(1, 6);
    }
    if (currentPage + 3 > numPages) {
      return range(numPages - 4, numPages + 1);
    }
    return range(currentPage - 2, currentPage + 3);
  };

  useEffect(() => {
    if (triggerExternalRenderUpdate) {
      triggerExternalRenderUpdate(rows.map((row) => row.original));
    }
  }, [triggerExternalRenderUpdate, rows]);

  return (
    // apply the table props
    <div className="d-flex flex-column overflow-auto flex-grow-1 justify-content-between">
      <div className="flex-grow-1 overflow-auto">
        <table size="sm" striped hover {...getTableProps()}>
          <thead>
            {// Loop over the header rows
            headerGroups.map((headerGroup) => (
              // Apply the header row props
              <tr {...headerGroup.getHeaderGroupProps()}>
                {// Loop over the headers in each row
                  headerGroup.headers.map((column) => (
                    // Apply the header cell props
                    <th {...column.getHeaderProps({
                      style: {
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        width: column.width,
                      },
                      ...column.getSortByToggleProps(),
                    })}
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          {// Render the header
                            column.render('Header')
                          }
                        </div>
                        <div>
                          {column.canSort && (
                            <span>
                              {
                                /* eslint-disable */
                                column.isSorted
                                  ? column.isSortedDesc
                                    ? <BsSortDown />
                                    : <BsSortUp />
                                  : <BsArrowDownUp color="gray" />}
                            </span>
                          )}
                        </div>
                      </div>
                    </th>
                  ))
                }
              </tr>
            ))
          }
        </thead>
        {/* Apply the table body props */}
        <tbody {...getTableBodyProps()}>
          {// Loop over the table rows
            page.map((row, i) => {
              // Prepare the row for display
              prepareRow(row);
              return (
                // Apply the row props
                <tr data-cy="table-row" {...row.getRowProps()} >
                  {// Loop over the rows cells
                    row.cells.map((cell) => (
                      <td {...cell.getCellProps([
                        {
                          className: cell.column.className,
                          style: {...cell.column.style, verticalAlign: cell.column.id == 'selection' ? undefined: 'top' },
                        },
                      ])}
                      >
                        {// Render the cell contents
                          formItemRenderer && cell.column.formItemProps ? formItemRenderer({
                            ...cell.column.formItemProps,
                            name: `${tableName}[${i}][${cell.column.formItemProps.name}]`,
                          }) : cell.render('Cell')
                        }
                      </td>
                    ))
                  }
                </tr>
              );
            })
          }
        </tbody>
      </table>
      </div>
      {data.length > 25 &&
        <div className="w-100 bg-white bottom-0 pb-2 pt-2 pr-4 pl-4">
          <div className="pagination d-flex align-items-center justify-content-between">

            <Form.Group className="pr-2">
              <Form.Select
                size="sm"
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[25, 50, 75, 100, data.length].map((size, i) => (
                  <option key={size} value={size}>
                    Show
                    {' '}
                    {i === 4 ? 'ALL' : size}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>

            <ButtonGroup size="sm">
              <Button
                variant="outline-primary"
                onClick={() => gotoPage(0)}
                disabled={!canPreviousPage}
              >
                {'<<'}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => previousPage()}
                disabled={!canPreviousPage}
              >
                {'<'}
              </Button>
              {getPageRange(pageIndex + 1, pageOptions.length).map((pg) => (
                <Button
                  key={`button-pg-${pg}`}
                  variant="outline-primary"
                  onClick={() => gotoPage(pg - 1)}
                  active={pageIndex + 1 === pg}
                >
                  {pg}
                </Button>
              ))}
              <Button
                variant="outline-primary"
                onClick={() => nextPage()}
                disabled={!canNextPage}
              >
                {'>'}
              </Button>
              <Button
                variant="outline-primary"
                onClick={() => gotoPage(pageCount - 1)}
                disabled={!canNextPage}
              >
                {'>>'}
              </Button>
            </ButtonGroup>
            <div>
              Page
              {' '}
              <strong>
                {pageIndex + 1}
                {' '}
                of
                {' '}
                {pageOptions.length}
              </strong>
              {' '}
            </div>
          </div>
        </div>
      }
    </div>
  );
});

export default RowSelectTable;
