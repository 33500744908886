import { createFormView, FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import {usaStates} from '../../../data/states';
import { unassignMaintenanceRequestFromWorkOrder } from "../../../services/requests";
import { unique } from '../../../services/form-validation';

export const vendorCreate: FormView = {
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Info',
      items: [
        {
          label: 'Vendor Name',
          name: 'vendorName',
          unique: true,
          required: true
        },
        {
          type: FormItemType.row,
          items: [
            {
              label: 'Category',
              name: 'category',
              type: FormItemType.typeahead,
              freeform: true,
              options: (state) => unique(state.vendors.data, v => v.category),
            },
            {
              label: 'Scope',
              name: 'scope',
              type: FormItemType.typeahead,
              freeform: true,
              options: (state) => unique(state.vendors.data, v => v.scope),
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              label: 'VCode',
              name: 'vCode'
            },
            {
              label: 'Date Listed',
              name: 'dateListed',
              type: FormItemType.date
            },
          ]
        },
        {
          label: 'Comments',
          name: 'comments',
          type: FormItemType.textarea
        },
      ]
    },
    {
      type: FormItemType.fieldset,
      label: 'Contact',
      items: [
        {
          label: 'Phone',
          name: 'phoneNumber'
        },
        {
          label: 'Fax',
          name: 'faxNumber'
        },
        {
          label: 'Attn',
          name: 'attn'
        },
        {
          label: 'Email',
          name: 'email'
        },
      ]
    },
    {
      type: FormItemType.fieldset,
      label: 'Address',
      items: [
        {
          type: FormItemType.typeahead,
          label: 'Location',
          name: 'locationId',
          options: (state) => (state.legacy?.dropdownValues?.locations || []).map(l => ({id: l.LocationID, label: l.LocationName}))
        },
        {
          label: 'Address 1',
          name: 'address1'
        },
        {
          label: 'Address 2',
          name: 'address2'
        },
        {
          label: 'City',
          name: 'city'
        },
        {
          type: FormItemType.row,
          items: [
            {
              label: 'State',
              name: 'state',
              type: FormItemType.select,
              options: [{id: null, text: ''}, ...Object.keys(usaStates).map(abbrev => ({id: abbrev, text: abbrev}))]
            },
            {
              label: 'Zip',
              name: 'zip'
            },
            {
              label: 'Country',
              name: 'country',
              type: FormItemType.typeahead,
              freeform: true,
              options: (state) => unique(state.vendors.data, v => v.country),
            }
          ]
        },
      ]
    }
  ]
};

