import React, { useMemo } from 'react';
import {
  Button,
  Col, Container, Form, OverlayTrigger, Popover,
  Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatWorkOrderStatus, splitStringOnUppercase } from '../../../services/format';
import { selectIsMaintenanceManager } from '../../../store/slices/auth-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import { selectCurrentWorkOrder, selectCurrentWorkOrderId, updateWorkOrderStatus } from '../../../store/slices/work-orders-slice';
import Card from '../../UI/molecules/Card/card';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';

function WorkOrderStatus(props) {
  const { assetTypes, workOrderStatuses } = useSelector(selectMaintenanceRequestDropdownValues);
  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);

  const currentWorkOrder = useSelector(selectCurrentWorkOrder);
  const currentWorkOrderId = useSelector(selectCurrentWorkOrderId);

  const currentWorkOrderStatusId = currentWorkOrder?.workOrderStatusId;

  const statusName = splitStringOnUppercase(workOrderStatuses.find(
    (s) => s.id === (currentWorkOrderStatusId || 0).toString(),
  )?.name ?? '');

  const dispatch = useDispatch();
  const { confirm } = useConfirm();

  const dispatchUpdate = async (workOrderStatusId) => {
    const isConfirmed = await confirm(`Are you sure you want to update the Work Order status to ${formatWorkOrderStatus(workOrderStatusId)}?`);
    if (isConfirmed) {
      dispatch(updateWorkOrderStatus({
        workOrderId: currentWorkOrderId,
        workOrderStatusId,
      }));
    }
  };

  const markClosedButton = useMemo(() => {
    const button = (
      <Button
        size="sm"
        variant="danger"
        className="w-100"
        disabled={!isMaintenanceManager}
        onClick={() => {
          if (!props.preventClose) {
            dispatchUpdate(5);
          } else {
            props.setActiveTab('Checklist');
          }
        }}
      >
        Mark as Closed
      </Button>
    );
    if (isMaintenanceManager) {
      return button;
    }
    return (
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={(
          <Popover id="shield-tooltip">
            <Popover.Header as="div">
              <small>
                Only
                {' '}
                <b>Maintenance Manager</b>
                {' '}
                users can close work orders.
              </small>
            </Popover.Header>
          </Popover>
        )}
      >
        <span className="d-inline-block">
          {button}
        </span>
      </OverlayTrigger>
    );
  }, [isMaintenanceManager, dispatchUpdate, props.preventClose]);

  const statusButtons = useMemo(() => {
    switch (currentWorkOrderStatusId) {
      case 1:
        return (
          <>
            <Button
              size="sm"
              variant="success"
              className="mb-1 w-100"
              onClick={() => {
                dispatchUpdate(2);
              }}
            >
              Mark as Scheduled
            </Button>
            <Button
              size="sm"
              variant="danger"
              className="w-100"
              onClick={() => {
                dispatchUpdate(6);
              }}
            >
              Cancel
            </Button>
          </>
        );
      case 2:
        return (
          <Button
            size="sm"
            className="w-100"
            onClick={() => {
              dispatchUpdate(3);
            }}
          >
            Mark as In Progress
          </Button>
        );
      case 3:
        return (
          <Button
            size="sm"
            className="w-100"
            onClick={() => {
              dispatchUpdate(4);
            }}
          >
            Mark as Completed
          </Button>
        );
      case 4:
        return markClosedButton;
      case 5:
        return (
          <Button
            size="sm"
            className="w-100"
            onClick={() => {
              dispatchUpdate(1);
            }}
          >
            Reopen
          </Button>
        );
      default:
        return <div />;
    }
  }, [currentWorkOrderStatusId, props.preventClose]);

  return (
    <Card header="Status" className="card-secondary card-outline">
      <Container fluid className="p-0 m-0">
        <Row>
          <Col
            sm
          >
            <Form.Group className="mb-1">
              <Form.Label>Status</Form.Label>
              <Form.Control
                size="sm"
                disabled
                value={statusName ?? ''}
              />
            </Form.Group>
          </Col>
          <Col
            className="d-flex align-items-center justify-content-center flex-column"
            sm
          >
            {statusButtons}
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default WorkOrderStatus;
