import axios from "axios";
import { handleRequestError, memoizePromiseFn } from "../../requests";

//		//BypassInvoiceFromInvoice(int billingId)

export const BypassInvoiceFromInvoice = async (billingId) => {
  const results = await axios.post('/DewarBilling/BypassInvoiceFromInvoice', {billingId}).catch(handleRequestError);
  return results.data;
}

export const getInvoiceFromOrder = async (orderId) => {
  const results = await axios.get(`/DewarBilling/GetInvoiceFromOrder/${orderId}`).catch(handleRequestError);
  return results.data;
}

export const getPastVolumes = async () => {
  const results = await axios.get('/DewarBilling/getPastVolumes').catch(handleRequestError);
  return results.data;
}

export const jdeUploadByOrderIds = async (orderIds) => {
  const results = await axios.post('/DewarBilling/UploadInvoicesToJDEByOrderId', orderIds).catch(handleRequestError);
  return results.data;
}
export const jdeUploadByBillingIDs = async (billingIds) => {
  const results = await axios.post('/DewarBilling/UploadInvoicesToJDEByBillingId', billingIds).catch(handleRequestError);
  return results.data;
}
//bypassInvoiceFromOrder
export const bypassInvoiceFromOrder = async (orderId) => {
  const results = await axios.post('/DewarBilling/bypassInvoiceFromOrder', {orderId}).catch(handleRequestError);
  return results.data;
}

export const InsertBilling = async (billing) => {
  const results = await axios.post('/DewarBilling/InsertBilling', billing).catch(handleRequestError);
  return results.data;
}


export const getDewarPricing = async (cst) => {
  const results = await axios.get(`/DewarBilling/getDewarPricing/${cst}`).catch(handleRequestError);
  return results.data;
}

//getDewarInvoices
export const getDewarInvoices = async (queryDeg) => {
  const results = await axios.post(`/DewarBilling/GetBillingList`, queryDeg).catch(handleRequestError);
  return results.data;
}

export const getPendingManualUpload = async () => {
  const results = await axios.get(`/DewarBilling/GetPendingManualUpload`).catch(handleRequestError);
  return results.data;
}

export const GetDewarProductTypeList = async () => {
  const results = await axios.get('/DewarBilling/GetDewarProductTypeList').catch(handleRequestError);
  return results.data;
}
export const UpdateDewarProductType = async (productType) => {
  const results = await axios.put('/DewarBilling/UpdateDewarProductType', productType).catch(handleRequestError);
  return results.data;
}
export const InsertDewarProductType = async (productType) => {
  const results = await axios.post('/DewarBilling/InsertDewarProductType', productType).catch(handleRequestError);
  return results.data;
}

//////
export const deleteInvoice = async (invoiceId) => {
  const results = await axios.delete(`/DewarBilling/DeleteInvoice/${invoiceId}`).catch(handleRequestError);
  return results.data;
};
// /PreAuthorizeCreditCard
export const PreAuthorizeCreditCard = async (billingId) => {
  const results = await axios.get(`/DewarBilling/PreAuthorizeCreditCard/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const getBillingHeader = async (billingId) => {
  const results = await axios.get(`/DewarBilling/getBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const reverseBilling = async (reverse) => {
  const results = await axios.post(`/DewarBilling/reverseBilling`, reverse).catch(handleRequestError);
  return results.data;
};

export const uploadInvoicesJDEDTO = async (invoice) => {
  const results = await axios.post(`/DewarBilling/UploadInvoicesToJDE`, invoice).catch(handleRequestError);
  return results.data;
};

export const createDewarDamagesInvoice = async (invoice) => {
  const results = await axios.post(`/MiscBilling/createDewarDamagesInvoice`, invoice).catch(handleRequestError);
  return results.data;
};
export const getDewarDamageReport = async (customerId) => {
  const results = await axios.get(`/DewarBilling/getDewarDamageReport/${customerId}`).catch(handleRequestError);
  return results.data;
};
export const getDewarDamageReportHeaders = async (type) => {
  const results = await axios.get('/DewarBilling/GetDewarDamageReportHeaders').catch(handleRequestError);
  return results.data;
};
export const SendReport = async (report) => {
  const results = await axios.post('/DewarBilling/SendReport', report).catch(handleRequestError);
  return results.data;
};
export const downloadReport = async (report) => {
  const results = await axios.post('/DewarBilling/DownloadReport', report, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', report.filename);
  document.body.appendChild(link);
  link.click();
};
export const GetJDEErrors = async (billing) => {
  const results = await axios.get('/DewarBilling/GetJDEErrors').catch(handleRequestError);
  return results.data;
}
export const GetUnbilledInvoices = async (billing) => {
  const results = await axios.get('/DewarBilling/GetUnbilledInvoices').catch(handleRequestError);
  return results.data;
}

export const UpdateBilling = async (billing) => {
  const results = await axios.put('/DewarBilling/UpdateBilling', billing).catch(handleRequestError);
  return results.data;
}