import { useMsal } from '@azure/msal-react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { signOut } from '../../../../store/slices/auth-slice';

/**
 * Renders a button which, when selected, will redirect the page to the logout prompt
 */
export function SignOutButton() {
  const { instance } = useMsal();
  const dispatch = useDispatch();

  const handleLogout = (i) => {
    i.logoutRedirect().then(() => {
      dispatch(signOut());
    }).catch((e) => {
      console.error(e);
    });
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a className="nav-link" onClick={() => handleLogout(instance)}>
      <i className="bi nav-icon bi-box-arrow-left" style={{ marginRight: 4 }} />
      {' '}
      <p className="text-nowrap">
        Sign out
      </p>
    </a>
  );
}
