import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import { getCurrentAssetLocations } from '../../services/requests';

const initialState = {
  assetLocations: {},
  status: 'idle',
  error: null,
};

export const fetchAssetLocations = createAsyncThunk('assetLocation/fetchAssetLocations', async () => {
  const response = await getCurrentAssetLocations();
  return response;
});

export const assetLocations = createSlice({
  name: 'assetLocation',
  initialState,
  reducers: {
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAssetLocations.pending, (state, action) => {
        state.status = 'loading';
      })
      .addCase(fetchAssetLocations.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.assetLocations = action.payload;
        state.error = null;
      })
      .addCase(fetchAssetLocations.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.error.message;
      });
  },
});

export const selectChassisLocationObjects = createSelector(
  [((state) => state.assetLocation?.assetLocations?.currentChassisLocations)],
  (currentChassisLocations) => (currentChassisLocations ?? []).reduce(
    (current, location) => ({ ...current, [location.ContainerID]: location }),
    {},
  ),
);

export const selectLiquidLocationObjects = createSelector(
  [((state) => state.assetLocation?.assetLocations?.currentLiquidLocations)],
  (currentChassisLocations) => (currentChassisLocations ?? []).reduce(
    (current, location) => ({ ...current, [location.ContainerID]: location }),
    {},
  ),
);

export const selectGasLocationObjects = createSelector(
  [((state) => state.assetLocation?.assetLocations?.currentGasLocations)],
  (currentChassisLocations) => (currentChassisLocations ?? []).reduce(
    (current, location) => ({ ...current, [location.ContainerID]: location }),
    {},
  ),
);

export default assetLocations.reducer;
