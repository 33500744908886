import './serial-number-popover.scss';

import React from 'react';
import { Badge } from 'react-bootstrap';
import Popover from 'react-bootstrap/Popover';

function SerialNumberPopover(props) {
  const listItems = props.unitNumbers.map((sr) => (
    <div
      key={`unit-${sr}`}
    >
      <Badge key={sr}>{sr}</Badge>
    </div>
  ));
  return (
    <Popover
      id="popover-basic"
      style={{
        top: '0%',
        left: '100%',
        zIndex: 2000,
        minWidth: '200px',
        maxHeight: '100%',
        border: '0',
        marginLeft: '0.5em',
      }}
    >
      <Popover.Header as="h3">{props.header}</Popover.Header>
      <Popover.Body className="d-flex flex-column" style={{ maxHeight: '400px' }}>
        <div className="h-100 overflow-auto">
          {listItems}
        </div>
      </Popover.Body>
    </Popover>
  );
}
export default SerialNumberPopover;
