import './auth-loading.scss';

import React from 'react';

import Balloons from '../../atoms/Balloons/balloons';

function AuthLoading(props) {
  return (
    <div className="auth-loading-container">
      <Balloons />
      <h4 className="text-light my-2">Loading Authentication</h4>
    </div>
  );
}

export default AuthLoading;
