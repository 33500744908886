import React from 'react';
import { Container, Modal, Spinner } from 'react-bootstrap';

import CreatePartForm from './create-part-form';

function CreatePartModal({
  show, onHide, partCreateIsLoading,
}) {
  const onModalHide = () => {
    onHide();
  };

  return (
    <Modal
      show={show}
      onHide={() => onModalHide()}
      size="lg"
      centered
      scrollable
    >
      <Modal.Header>
        <Modal.Title>
          Add New Part
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spinner animation="border" variant="primary" className={`create-spinner${partCreateIsLoading ? ' visible' : ' invisible'}`} />
        <Container fluid className={` ${partCreateIsLoading ? ' creation-loading' : ''}`}>
          <CreatePartForm onHide={onHide} />
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default CreatePartModal;
