export const SHOW_CONFIRM = 'SHOW_CONFIRM';
export const HIDE_CONFIRM = 'HIDE_CONFIRM';

export const confirmInitialState = {
  show: false,
  text: '',
};

export const confirmReducer = (state, action) => {
  switch (action.type) {
    case SHOW_CONFIRM:
      return {
        show: true,
        text: action.payload.text,
        alertOnly: action.payload.alertOnly,
      };
    case HIDE_CONFIRM:
      return confirmInitialState;
    default:
      return confirmInitialState;
  }
};
