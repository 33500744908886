import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  createPackagedProductAttachmentRequest,
  createPackagedProductRequest, deletePackagedProductAttachment,
  getPackagedProduct, getPackagedProductBySerialNumber,
  getPackagedProducts, updatePackagedProductRequest,
} from '../../services/requests';

const initialState = {
  packagedProducts: {},
  packagedProductsFetch: {
    status: 'idle',
    error: null,
  },
  packagedProductsFetchSingle: {
    status: 'idle',
    error: null,
  },
  currentPackagedProduct: {
    packagedProductId: null,
    status: 'idle',
    error: null,
  },
  packagedProductCreate: {
    status: 'idle',
    error: null,
  },
  packagedProductUpdate: {
    status: 'idle',
    error: null,
  },
  packagedProductAttachmentCreate: {
    status: 'idle',
    error: null,
  },
  packagedProductAttachmentRemove: {
    status: 'idle',
    error: null,
  },

};

export const fetchPackagedProducts = createAsyncThunk('packagedProduct/fetchPackagedProducts', async () => {
  const response = await getPackagedProducts();
  return response;
});

export const fetchPackagedProduct = createAsyncThunk('packagedProduct/fetchPackagedProduct', async (serialNumber) => {
  const response = await getPackagedProduct(serialNumber);
  return response;
});
export const fetchPackagedProductBySerialNumber = createAsyncThunk('packagedProduct/fetchPackagedProductBySerialNumber', async (serialNumber) => {
  const response = await getPackagedProductBySerialNumber(serialNumber);
  return response;
});
export const createPackagedProduct = createAsyncThunk('packagedProduct/createPackagedProduct', async (user) => {
  const response = await createPackagedProductRequest(user);
  return response;
});

export const updatePackagedProduct = createAsyncThunk('packagedProduct/updatePackagedProduct', async (user, thunkAPI) => {
  const response = await updatePackagedProductRequest(user);
  return response;
});

export const createPackagedProductAttachment = createAsyncThunk('attachment/createPackagedProductAttachment', async (attachment, thunkAPI) => {
  const response = await createPackagedProductAttachmentRequest(attachment);
  return response;
});

export const removePackagedProductAttachment = createAsyncThunk('attachment/deletePackagedProductAttachment', async (attachmentId) => {
  const response = await deletePackagedProductAttachment(attachmentId);
  return response;
});

export const packagedProductSlice = createSlice({
  name: 'packagedProduct',
  initialState,
  reducers: {
    setPackagedProducts: (state, action) => {
      state.packagedProducts = action.payload.reduce(
        (current, p) => ({ ...current, [p.id]: p }),
        {},
      );
    },
    setCurrentPackagedProductId: (state, action) => {
      state.currentPackagedProduct.packagedProductId = action.payload;
      // state.currentRole.roleData = state.roles.find(role => role.roleId === action.payload);
    },
    setPackagedProductCreateStatus: (state, action) => {
      state.packagedProductCreate.status = action.payload;
    },
    setPackagedProductUpdateStatus: (state, action) => {
      state.packagedProductUpdate.status = action.payload;
    },
    setCreateAttachmentStatus: (state, action) => {
      state.packagedProductAttachmentCreate.status = action.payload;
    },
    setRemoveAttachmentStatus: (state, action) => {
      state.packagedProductAttachmentRemove.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPackagedProducts.pending, (state, action) => {
        state.packagedProductsFetch.status = 'loading';
      })
      .addCase(fetchPackagedProducts.fulfilled, (state, action) => {
        state.packagedProductsFetch.status = 'succeeded';
        // state.packagedProducts = state.packagedProducts.concat(action.payload)
        state.packagedProducts = action.payload.reduce((current, p) => ({
          ...current,
          [p.id]: p,
        }), {});
      })
      .addCase(fetchPackagedProducts.rejected, (state, action) => {
        state.packagedProductsFetch.status = 'failed';
        state.packagedProductsFetch.error = action.error.message;
      });

    builder
      .addCase(fetchPackagedProduct.pending, (state, action) => {
        state.packagedProductsFetchSingle.status = 'loading';
      })
      .addCase(fetchPackagedProduct.fulfilled, (state, action) => {
        state.packagedProductsFetchSingle.status = 'succeeded';
        state.packagedProducts = {
          ...state.packagedProducts,
          [action.payload.assetDetails.id]: {
            ...action.payload.assetDetails,
            attachmentList: action.payload.attachmentList,
          },
        };
      })
      .addCase(fetchPackagedProduct.rejected, (state, action) => {
        state.packagedProductsFetchSingle.status = 'failed';
        state.packagedProductsFetchSingle.error = action.error.message;
      });

    builder
      .addCase(createPackagedProductAttachment.pending, (state, action) => {
        state.packagedProductAttachmentCreate.status = 'loading';
      })
      .addCase(createPackagedProductAttachment.fulfilled, (state, action) => {
        state.packagedProductAttachmentCreate.status = 'succeeded';
        const newAttachmentList = JSON.parse(JSON.stringify([...state.packagedProducts[state.currentPackagedProduct.packagedProductId].attachmentList]));
        const exists = newAttachmentList.filter((x) => x.attachmentId === action.payload.attachmentId);
        if (exists.length === 0) newAttachmentList.push(action.payload);
        state.packagedProducts = {
          ...state.packagedProducts,
          [state.currentPackagedProduct.packagedProductId]: {
            ...state.packagedProducts[state.currentPackagedProduct.packagedProductId],
            attachmentList:
              [
                ...newAttachmentList,
              ],
          },
        };
      })
      .addCase(createPackagedProductAttachment.rejected, (state, action) => {
        state.packagedProductAttachmentCreate.status = 'failed';
        state.packagedProductAttachmentCreate.error = action.error.message;
      });

    builder
      .addCase(removePackagedProductAttachment.pending, (state, action) => {
        state.packagedProductAttachmentRemove.status = 'loading';
      })
      .addCase(removePackagedProductAttachment.fulfilled, (state, action) => {
        state.packagedProductAttachmentRemove.status = 'succeeded';
        state.packagedProducts = {
          ...state.packagedProducts,
          [state.currentPackagedProduct.packagedProductId]: {
            ...state.packagedProducts[state.currentPackagedProduct.packagedProductId],
            attachmentList:
              state.packagedProducts[state.currentPackagedProduct.packagedProductId]
                .attachmentList
                .filter((attachment) => attachment.attachmentId !== action.meta.arg),
          },
        };
      })
      .addCase(removePackagedProductAttachment.rejected, (state, action) => {
        state.packagedProductAttachmentRemove.status = 'failed';
        state.packagedProductAttachmentRemove.error = action.error.message;
      });

    builder
      .addCase(createPackagedProduct.pending, (state, action) => {
        state.packagedProductCreate.status = 'loading';
      })
      .addCase(createPackagedProduct.fulfilled, (state, action) => {
        state.packagedProductCreate.status = 'succeeded';
        state.packagedProducts = { ...state.packagedProducts, [action.payload.id]: action.payload };
        state.currentPackagedProduct.packagedProductId = action.payload.id;
        state.packagedProductCreate.error = null;
      })
      .addCase(createPackagedProduct.rejected, (state, action) => {
        state.packagedProductCreate.status = 'failed';
        state.packagedProductCreate.error = action.error.message;
      });

    builder
      .addCase(updatePackagedProduct.pending, (state, action) => {
        state.packagedProductUpdate.status = 'loading';
      })
      .addCase(updatePackagedProduct.fulfilled, (state, action) => {
        state.packagedProductUpdate.status = 'succeeded';
        state.packagedProducts = {
          ...state.packagedProducts,
          [action.payload.id]: {
            ...state.packagedProducts[action.payload.id],
            ...action.payload,
          },
        };
        state.packagedProductUpdate.error = null;
      })
      .addCase(updatePackagedProduct.rejected, (state, action) => {
        state.packagedProductUpdate.status = 'failed';
        state.packagedProductUpdate.error = action.error.message;
      });
  },
});

export const {
  setPackagedProducts, setCurrentPackagedProductId,
  setPackagedProductCreateStatus, setPackagedProductUpdateStatus,
  setCreateAttachmentStatus, setRemoveAttachmentStatus,
} = packagedProductSlice.actions;

export const selectPackagedProducts = (state) => state.packagedProduct.packagedProducts;
export const selectCurrentPackagedProductId = (state) => (
  state.packagedProduct.currentPackagedProduct.packagedProductId
);

export const selectCurrentPackagedProduct = createSelector([
  selectPackagedProducts,
  selectCurrentPackagedProductId], (packagedProducts, packagedProductId) => {
  if (packagedProductId == null) {
    return {};
  }
  return packagedProducts[packagedProductId];
});

export const selectPackagedProductStatus = (state) => state.packagedProduct.status;

export default packagedProductSlice.reducer;
