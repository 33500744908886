import './date-input.scss';

import Inputmask from 'inputmask';
import React, { useEffect, useRef } from 'react';
import { BsCalendar, BsXLg } from 'react-icons/bs';
import { useSelector } from 'react-redux';

import DatePicker from '../../../../overridden-components/custom-date-picker/DatePicker';
import { getDateFormat } from '../../../../services/user-preferences';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';

function StyledDatePicker(props) {
  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const dateFormat = getDateFormat(datePreference);
  let calendarType;
  if (dateFormat === 'MM/dd/yyyy') {
    calendarType = 'US';
  }

  const dateRef = useRef(null);

  const datePickerClasses = `linde-date-picker form-control form-control-sm ${props?.className ?? ''}`;

  useEffect(() => {
    if (dateRef.current !== null) {
      Inputmask().mask(dateRef.current);
    }
  }, [dateRef.current, dateFormat]);

  return (
    <DatePicker
      {...props}
      format={dateFormat}
      yearPlaceholder="yyyy"
      dayPlaceholder="dd"
      monthPlaceholder="mm"
      calendarType={calendarType}
      ref={dateRef}
      calendarClassName={props.invalid ? 'is-invalid' : ''}
      showLeadingZeros
      // eslint-disable-next-line no-nested-ternary
      className={props.invalid ? `${datePickerClasses} is-invalid` : (props.disabled ? `${datePickerClasses} disabled` : datePickerClasses)}
      calendarIcon={<BsCalendar />}
      clearIcon={props.disabled ? null : <BsXLg />}
      disableCalendar={props.disabled}
    />
  );
}

export default StyledDatePicker;
