import axios from "axios";
import { handleRequestError, memoizePromiseFn } from "../../requests";

export const InsertBilling = async (billing) => {
  const results = await axios.post('/LiquidBilling/InsertBilling', billing).catch(handleRequestError);
  return results.data;
}

export const getLiquidBillingInvoices = async (queryDefinition) => {
  const results = await axios.get(`/LiquidBilling/GetLiquidBillingList`).catch(handleRequestError);
  return results.data;
}
export const getLiquidBillingHeader = async (billingId) => {
  const results = await axios.get(`/LiquidBilling/GetLiquidBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
}

export const getRecentLiquidDeliveries = async (customerId) => {
  const results = await axios.get(`/LiquidBilling/getRecentLiquidDeliveries/${customerId}`).catch(handleRequestError);
  return results.data;
}



export const createLiquidBillingTransfer = async () => {
  return {};
}
export const getUnlinkedCreditInvoices = async () => {
  return [];
}

export const deleteInvoice = async (invoiceId) => {
  const results = await axios.delete(`/LiquidBilling/DeleteInvoice/${invoiceId}`).catch(handleRequestError);
  return results.data;
};
// /PreAuthorizeCreditCard
export const PreAuthorizeCreditCard = async (billingId) => {
  const results = await axios.get(`/LiquidBilling/PreAuthorizeCreditCard/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const getBillingHeader = async (billingId) => {
  const results = await axios.get(`/LiquidBilling/getBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const reverseBilling = async (reverse) => {
  const results = await axios.post(`/LiquidBilling/reverseBilling`, reverse).catch(handleRequestError);
  return results.data;
};

export const uploadInvoicesJDEDTO = async (invoice) => {
  const results = await axios.post(`/LiquidBilling/UploadInvoicesToJDE`, invoice).catch(handleRequestError);
  return results.data;
};

export const SendReport = async (report) => {
  const results = await axios.post('/LiquidBilling/SendReport', report).catch(handleRequestError);
  return results.data;
};
export const downloadReport = async (report) => {
  const results = await axios.post('/LiquidBilling/DownloadReport', report, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', report.filename);
  document.body.appendChild(link);
  link.click();
};
export const GetJDEErrors = async (billing) => {
  const results = await axios.get('/LiquidBilling/GetJDEErrors').catch(handleRequestError);
  return results.data;
}
export const GetUnbilledInvoices = async (billing) => {
  const results = await axios.get('/LiquidBilling/GetUnbilledInvoices').catch(handleRequestError);
  return results.data;
}

export const UpdateBilling = async (billing) => {
  const results = await axios.put('/LiquidBilling/UpdateBilling', billing).catch(handleRequestError);
  return results.data;
}