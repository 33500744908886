import React, { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setCurrentMaintenanceRequestId } from '../../../store/slices/maintenance-requests-slice';
import WorkOrderMaintenanceRequestsTable from './work-order-maintenance-requests-table';
import WorkOrderOutstandingRequestsTable from './work-order-outstanding-requests-table';

function WorkOrderMaintenanceRequests(props) {
  const maintenanceRequestsFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const [createModalShow, setCreateModalShow] = useState(false);

  const handleCreateModalClose = () => {
    setCreateModalShow(false);
  };
  const handleCreateModalShow = (assetType) => {
    setCreateModalShow(true);
  };

  const dispatch = useDispatch();

  const [editModalShow, setEditModalShow] = useState(false);

  const handleEditModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setEditModalShow(false);
  };
  const handleEditModalShow = (assetType) => {
    setEditModalShow(true);
  };

  const [addToWorkOrderModaShow, setAddToWorkOrderModaShow] = useState(false);

  const handleWorkOrderModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setAddToWorkOrderModaShow(false);
  };
  const handleWorkOrderModalShow = (assetType) => {
    setAddToWorkOrderModaShow(true);
  };

  const tableContent = useMemo(() => {
    if (props.type === 'outstanding') {
      return (
        <WorkOrderOutstandingRequestsTable
          handleCreateModalShow={handleCreateModalShow}
          handleEditModalShow={handleEditModalShow}
          handleWorkOrderModalShow={handleWorkOrderModalShow}
          handleMaintenanceRequestAttachmentsModalShow={
            props.handleMaintenanceRequestAttachmentsModalShow
          }
          currentWorkOrder={props.currentWorkOrder}
        />
      );
    }
    return (
      <WorkOrderMaintenanceRequestsTable
        handleCreateModalShow={handleCreateModalShow}
        handleEditModalShow={handleEditModalShow}
        handleWorkOrderModalShow={handleWorkOrderModalShow}
        handleMaintenanceRequestAttachmentsModalShow={
          props.handleMaintenanceRequestAttachmentsModalShow
        }
      />
    );
  }, [props.currentWorkOrder]);

  return (
    <div className="h-100 d-flex">
      {tableContent}
    </div>
  );
}

export default WorkOrderMaintenanceRequests;
