import { FormItem, FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";

export const liquidPurity = [
  {
    type: FormItemType.row,
    items: [
      { name: 'Neon Max', label: 'Neon Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.neonMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Carbon Dioxide Max', label: 'Carbon Dioxide Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.carbonDioxideMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Carbon Monoxide Max', label: 'Carbon Monoxide Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.carbonMonoxideMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Methane Max', label: 'Methane Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.methaneMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'THC Max', label: 'THC Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.thcmax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Oxygen Max', label: 'Oxygen Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.oxygenMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Nitrogen Max', label: 'Nitrogen Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.nitrogenMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },
  {
    type: FormItemType.row,
    items: [
      { name: 'Moisture Max', label: 'Moisture Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.moistureMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },

  {
    type: FormItemType.row,
    items: [
      { name: 'Hydrogen Max', label: 'Hydrogen Max', type: FormItemType.label, width: 180 },
      { name: 'liquidPurity.hydrogenMax', defaultValue: 0.1, number: true, fitToContent: true },
    ]
  },


] as FormItem[];
