import { sortArray } from "../../../../services/array-helpers";
import { months } from "../../../../services/date-helpers";
import { GetGasCustomers } from "../../../../services/requests";
import { FormItemType } from "../../../UI/view/component-map";
import { FormView } from "../../../UI/view/form-view";

export const createEmptyInvoice = {
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Create Empty Invoice',
      items: [
        {
          type: FormItemType.select,
          name: 'customerId',
          label: 'Customer',
          required: true,
          options: async () => {
            const customers = sortArray({data: (await GetGasCustomers(null)), key: 'customerName', sortingOrder: 'asc'});
            return [{ text: "", id: null }, ...customers.map(c => ({ id: c.customerId, text: c.customerName }))]
          }
        },
        {
          type: FormItemType.select,
          name: 'invoiceYear',
          label: 'Year',
          required: true,
          options: async () => {
            const currYear = new Date().getFullYear() + 4;
            return [{ text: "", id: null }, ...Array.from({ length: 10 }, (_, i) => currYear - i).map(year => ({ id: year, text: year }))]
          }
        },
        {
          type: FormItemType.select,
          name: 'invoiceMonth',
          required: true,
          label: 'Month',
          options: async () => {
            return [{ text: "", id: null }, ...Object.entries(months).map(arr => ({ id: arr[0], text: arr[1] }))]
          }
        },
      ]
    }
  ]
} as FormView;