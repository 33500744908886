export const chassisStyles = [
  'Module',
  'Jumbo',
  'Super Jumbo',
  'Small',
  'MultiTuber',
  'Composite',
];

export const chassisTypes = [
  'Module',
  'Gooseneck',
  'Flatbed',
  'GooseneckRG',
  'Other',
];

export const chassisLength = [
  '20',
  '40',
  '43',
  'Other',
];

export const chassisResidentRegion = [
  'APAC',
  'EMEA',
  'Americas',
];

export const chassisManufacturers = [
  'Armor Chassis',
  'Capacity',
  'Chassis King',
  'CHEET',
  'Cheetah',
  'Cheta',
  'Chetaah',
  'Cryofab',
  'Flexday (Flexi-van)',
  'HPA Monon',
  'Kwik',
  'Linde',
  'Ottawa',
  'Ottowa Old',
  'Pratt',
  'STRI',
  'STRICK',
  'Theurer',
  'UNKN',
];

export const getChassisOwnerCustomer = (chassisCustomers, value) => {
  const notNull = !(value == null);
  if (notNull && chassisCustomers[value.toString()]?.CustomerName) {
    return chassisCustomers[value.toString()]?.CustomerName;
  }
  if (notNull) {
    return `Unknown ID: ${value}`;
  }
  return null;
};
