import React from 'react';

import ErrorTemplate from './error-template';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.log(error, errorInfo);
  }

  render() {
    const content = (
      <>
        <p>
          Oops! looks like Something went wrong.
          {' '}
          <a href="/">Refresh</a>
          {' '}
          the page and try again!
        </p>
        <p>
          If this problem persists please contact an IT Administrator
          for help.
        </p>
        <p>
          {this.state.error && this.state.error.toString()}
        </p>
      </>
    );

    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <ErrorTemplate
          errorCode={500}
          pageTitle="'! Application Error'"
          title="Application Error"
          content={content}
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
