import React, { useEffect, useMemo } from 'react';
import {
  Button, Col, Container, Dropdown, DropdownButton, Form, Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';

import { convertDateToUTCDay, formatAssetType } from '../../../services/format';
import {
  selectAllActiveLocations, selectGasCustomers, selectLiquidCustomers,
} from '../../../store/slices/legacy-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import { updateWorkOrder } from '../../../store/slices/work-orders-slice';
import Card from '../../UI/molecules/Card/card';
import DateInput from '../../UI/molecules/DateInput/date-input';
import {
  Typeahead,
} from '../../UI/molecules/Typeahead';

const defaultWorkOrderFormValues = {
  workOrderId: null,
  approvedBy: null,
  assetSerialNumber: null,
  assetTypeId: null,
  comments: null,
  // overrideOwner: null,
  // customerId: null,
  locationId: null,
  estimatedStartDate: null,
  estimatedEndDate: null,
  estimatedDuration: null, // should this calculate the end date? or be calucated start/end
  completedDate: null,
};

function WorkOrderForm({ currentWorkOrder, workOrderStatusIsLoading }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    watch,
    trigger,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultWorkOrderFormValues,
  });

  const dispatch = useDispatch();
  const { assetTypes, workOrderStatuses } = useSelector(selectMaintenanceRequestDropdownValues);
  const onSubmit = (workOrder) => {
    Object.keys(workOrder).forEach((key) => {
      if (key.toLowerCase().includes('date') && workOrder[key]) {
        workOrder[key] = convertDateToUTCDay(workOrder[key]);
      }
    });
    dispatch(updateWorkOrder({ ...workOrder }));
  };

  const locations = useSelector(selectAllActiveLocations);

  // Populate form with default values
  useEffect(() => {
    if (currentWorkOrder) {
      Object.keys(defaultWorkOrderFormValues).forEach((key) => {
        if (key.toLowerCase().includes('date') && currentWorkOrder[key]) {
          setValue(key, new Date(currentWorkOrder[key]));
        } else {
          setValue(key, currentWorkOrder[key]);
        }
      });
    }
  }, [currentWorkOrder]);

  const gasCustomers = useSelector(selectGasCustomers);
  const liquidCustomers = useSelector(selectLiquidCustomers);
  const customers = useMemo(() => {
    return {
      1: gasCustomers ?? [],
      2: liquidCustomers ?? [],
      3: liquidCustomers ?? [],
      4: liquidCustomers ?? [],
    };
  }, [liquidCustomers, gasCustomers]);

  // let currentCustomer = (customers[watch('assetTypeId')] ?? []).find((c) => c.Cu
  // stomerID === (currentWorkOrder?.customerId ?? 0).toString())?.CustomerName ?? '';

  // useEffect(() => {
  //   if (currentWorkOrder?.customerId == null) {
  //     currentCustomer = '';
  //   }
  // }, [currentWorkOrder?.customerId]);

  // // Set customerId to null if overrideOwner is false
  // useEffect(() => {
  //   const subscription = watch((value, { name, type }) => {
  //     if (name === 'overrideOwner' && value.overrideOwner === false) {
  //       setValue('customerId', null, { shouldDirty: true });
  //     }
  //   });
  //   return () => subscription.unsubscribe();
  // }, [watch]);

  return (
    <>
      <Form className="overflow-auto pt-3 flex-grow-1">
        <Spinner animation="border" variant="primary" className={`create-spinner${workOrderStatusIsLoading ? ' visible' : ' invisible'}`} />
        <Container fluid className={` ${workOrderStatusIsLoading ? ' creation-loading' : ''}`}>
          <Row>
            <Col>
              <Card header="Info" className="card-secondary card-outline">
                <Container fluid className="p-0 m-0">
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Work Order Id</Form.Label>
                        <Form.Control
                          size="sm"
                          type="number"
                          min={0}
                          disabled
                          {...register('workOrderId', { required: true })}
                          isInvalid={!!errors.workOrderId}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Approved By</Form.Label>
                        <Form.Control
                          size="sm"
                          disabled
                          {...register('approvedBy')}
                          isInvalid={!!errors.approvedBy}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Asset Unit Number</Form.Label>
                        <Form.Control
                          size="sm"
                          disabled
                          {...register('assetSerialNumber', { required: true })}
                          isInvalid={!!errors.assetSerialNumber}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Asset Type</Form.Label>
                        <Form.Select
                          size="sm"
                          {...register('assetTypeId', { required: true })}
                          disabled
                          isInvalid={!!errors.assetTypeId}
                        >
                          {assetTypes.map((type, index) => (
                            <option value={type.id} key={`assetType-${index}`}>{formatAssetType(type.name)}</option>
                          ))}
                        </Form.Select>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Location</Form.Label>
                        <Controller
                          control={control}
                          name="locationId"
                          defaultValue={null}
                          rules={{ required: true }}
                          render={({
                            field: { onChange, value, ref },
                            fieldState: {
                              isTouched, isDirty, error,
                            },
                            formState,
                          }) => (
                            <Typeahead
                              ref={ref}
                              value={value}
                              onChange={(e) => {
                                onChange(e[0]?.LocationID);
                              }}
                              size="sm"
                              id="locationId"
                              labelKey="LocationName"
                              options={locations}
                              isValid={!!error}
                              disabled={currentWorkOrder?.locationId}
                              placeholder={locations.find(
                                (wo) => wo.LocationID === currentWorkOrder?.locationId,
                              )?.LocationName}
                            />
                          )}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Completed Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="completedDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Estimated Duration</Form.Label>
                        <Form.Control
                          size="sm"
                          type="number"
                          min={0}
                          {...register('estimatedDuration')}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Estimated Start Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="estimatedStartDate"
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group className="mb-1">
                        <Form.Label>Estimated End Date</Form.Label>
                        <DateInput
                          control={control}
                          formControlName="estimatedEndDate"
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Form.Group className="mb-1">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      size="sm"
                      as="textarea"
                      rows={3}
                      {...register('comments')}
                      style={{ resize: 'none' }}
                    />
                  </Form.Group>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>

      </Form>

      <div className="d-flex flex-row position-sticky bottom-0 p-2 pr-1 bg-body border-top">
        <DropdownButton title="Reports" variant="light">
          <Dropdown.Item style={{ textAlign: 'left' }} target="__blank" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fHe3_MaintenanceWorkOrder&rs:Command=Render&WOId=${currentWorkOrder?.workOrderId}`}>Work Order Report</Dropdown.Item>
          <Dropdown.Item style={{ textAlign: 'left' }} target="__blank" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fHe3_MaintenanceTimeCardWorkOrderReport&rs:Command=Render&WorkOrderID=${currentWorkOrder?.workOrderId}`}>Time Card Report</Dropdown.Item>
          <Dropdown.Item style={{ textAlign: 'left' }} target="__blank" href={`http://${process.env.REACT_APP_RPT}/ReportServer/Pages/ReportViewer.aspx?%2fBulk+Helium+Reports%2fHe3_MaintenanceMaterialIssue&rs:Command=Render&WOId=${currentWorkOrder?.workOrderId}`}>Material Issue</Dropdown.Item>
        </DropdownButton>
        <Button
          type="submit"
          disabled={workOrderStatusIsLoading}
          onClick={handleSubmit(onSubmit)}
          style={{ marginLeft: 'auto' }}
        >
          Update
        </Button>
      </div>
    </>
  );
}

export default WorkOrderForm;
