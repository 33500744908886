import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setChassisCreateStatus } from '../../../store/slices/chassis-slice';
import CreateChassisForm from './create-chassis-form';

function CreateChassis(props) {
  const chassisCreateStatus = useSelector((state) => state.chassis.chassisCreate.status);
  const chassisCreateError = useSelector((state) => state.chassis.chassisCreate.error);

  const currentChassisId = useSelector((state) => state.chassis.currentChassis.chassisId);

  const chassisCreateSuccessNotify = () => toast.success('Successfully created chassis');
  const chassisCreateFailedNotify = (error) => toast.error(`Failed to create Chassis\n${error}`);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    if (chassisCreateStatus === 'succeeded') {
      chassisCreateSuccessNotify();
      dispatch(setChassisCreateStatus('idle'));
      navigate(`/assets/chassis/${currentChassisId}`);
    }
    if (chassisCreateStatus === 'failed') {
      chassisCreateFailedNotify(chassisCreateError);
      dispatch(setChassisCreateStatus('idle'));
    }
  }, [chassisCreateStatus]);

  // const currentChassis = useSelector(selectCurrentChassis);

  const chassisStatusIsLoading = chassisCreateStatus === 'loading';

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          Add New Chassis
        </Modal.Title>
      </Modal.Header>
      <CreateChassisForm
        onHide={props.onHide}
        chassisStatusIsLoading={chassisStatusIsLoading}
      />
    </>
  );
}

export default CreateChassis;
