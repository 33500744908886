import { createListView, FilterType, ListView, ListViewColumn, ListViewColumnType } from '../../UI/view/list-view';
import { dewarCustomerStore } from '../../../store/store';
import {usaStates} from '../../../data/states';
import { FormItemType } from "../../UI/view/component-map";

import { refreshDewarCustomers } from '../../../services/requests';
import { customerPermissions } from './customer-data-source';

export const dewarCustomerList: ListView = {
  permissions: customerPermissions,
  toolbar: [
    {
      type: FormItemType.button,
      label: 'Refresh Customers...',
      handler: async ({ listViewState }) => {
        if (confirm("Are you sure you want to refresh dewar customers?") == true) {
          const success = await refreshDewarCustomers();
          alert("Dewar Customers Sucessfully Refreshed!")
          location.reload();
        } 
      }
    }
  ],
  filters: [
    {
      type: FilterType.combo,
      label: 'State',
      fieldName: 'state',
      options: () => [...Object.keys(usaStates)]
    },
    {
      type: FilterType.multiselect,
      fieldName: 'accountType',
      filterValue: {'CBT': true, 'DNU': false, 'CST': true},
      options: () => ['CBT', 'DNU', 'CST']
    },
    {
      type: FilterType.simple,
      label: 'International',
      fieldName: 'isInternational',
      filterValue: false
    },
  ],
  columns: [
    {
      Header: 'Customer #',
      accessor: 'site',
      type: ListViewColumnType.linkEdit,
      linkPath: ({value, row}) => `/customers/edit/dewars/${value}`
    },
    {
      Header: 'Name',
      accessor: 'accountName',
      defaultSort: true,
    },
    {
      Header: 'Address',
      accessor: 'address1',
      serverExpression: `address1 & " " & address2`,
      Cell: ({row}) => row.original.address1 + ' ' + row.original.address2
    },
    {
      Header: 'City',
      accessor: 'city',
    },
    {
      Header: 'State',
      accessor: 'state',
    },
    {
      Header: 'CBT',
      accessor: 'billToNumber',
    },
    {
      Header: 'CBT Name',
      accessor: 'billToName',
    },
    {
      Header: 'Type',
      accessor: 'accountType',
    },
  ] as ListViewColumn[],
  breadcrumbs: [
    {
      name: 'Customers',
      icon: 'people',
      to: '/customers',
    },
  ],
};

export const DewarCustomerList = createListView(dewarCustomerList, dewarCustomerStore as any);

export const DewarCustomerSelectList = createListView({
  ...dewarCustomerList,
  noGlobalFilterPadding: true,
  toolbar: undefined,
  selectionMode: 'single',
  columns: [
    {
      type: ListViewColumnType.radio,
      accessor: 'checked',
      excludeGlobalFilter: true,
    },
    ...dewarCustomerList.columns
  ]
}, dewarCustomerStore as any);