import {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Card, Col, Container, Modal, Row, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatCurrency, formatDate } from '../../../services/format';
import { getUndoShipmentPreview, undoShipment } from '../../../services/requests';
import { selectIsAdmin, selectIsMaintenanceManager, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  fetchPurchaseOrderPartsPurchased,
  fetchPurchaseOrderSingle, selectShipmentsByCurrentPurchaseOrder, setCurrentPurchaseOrderId,
} from '../../../store/slices/purchase-orders-slice';
import {
  fetchPurchaseRequestsByPurchaseOrder,
} from '../../../store/slices/purchase-request-slice';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

function PurchaseOrderShipments(props) {
  const [undoPending, setUndoPending] = useState(false);
  const [undoPreview, setUndoPreview] = useState(null);
  const [showUndoPreview, setShowUndoPreview] = useState(false);
  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);
  const isAdmin = useSelector(selectIsAdmin);
  const hasUndo = undoPreview?.length;

  const onUndoPreview = async (shipmentId) => {
    setShowUndoPreview(true);
    const preview = await getUndoShipmentPreview(shipmentId);
    setUndoPreview(preview);
  };
  const dispatch = useDispatch();
  const shipments = useSelector(selectShipmentsByCurrentPurchaseOrder);

  const { currentPurchaseOrder } = props;

  const doUndoShipment = async () => {
    setUndoPending(true);
    setShowUndoPreview(false);
    const { shipmentId } = undoPreview[0];
    try {
      await undoShipment(shipmentId);
    } catch {
      // eslint-disable-next-line no-alert
      alert('there was an error undoing this shipment. No change was performed.');
    }
    setUndoPending(false);
    dispatch(fetchPurchaseOrderSingle(currentPurchaseOrder?.purchaseOrderId));
    dispatch(fetchPurchaseOrderPartsPurchased(currentPurchaseOrder?.purchaseOrderId));
    dispatch(fetchPurchaseRequestsByPurchaseOrder(currentPurchaseOrder?.purchaseOrderId));
    setUndoPreview(null);
  };

  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);

  const shipmentsFetchStatus = useSelector(
    (state) => state.purchaseOrder.shipmentsByPurchaseOrderFetch.status,
  );
  const shipmentsErrorStatus = useSelector(
    (state) => state.purchaseOrder.shipmentsByPurchaseOrderFetch.error,
  );

  const requestsAreLoading = [
    shipmentsFetchStatus,
  ].includes('loading');

  useEffect(() => {
    dispatch(setCurrentPurchaseOrderId(currentPurchaseOrder?.purchaseOrderId));
  }, [currentPurchaseOrder?.purchaseOrderId, dispatch]);

  useEffect(() => {
    dispatch(setCurrentPurchaseOrderId(currentPurchaseOrder?.purchaseOrderId));
  }, []);

  const partDeliveriesColumns = useMemo(() => [
    {
      Header: 'Part Delivered Id',
      accessor: 'partId',
      Cell: ({ value }) => (
        <NavLink to={`/maintenance/parts-inventory/${value}`}>
          {value}
        </NavLink>
      ),
    },
    {
      Header: 'Part Name',
      accessor: 'part.partName',
    },
    {
      Header: 'Cost',
      id: 'newCost',
      accessor: ({ newCost }) => formatCurrency(newCost),
    },
    {
      Header: 'HsCode',
      accessor: 'hsCode',
    },
    {
      Header: 'Quantity Accepted',
      accessor: 'quantityAccepted',
    },
    {
      Header: 'Quantity Rejected',
      accessor: 'quantityRejected',
    },

  ]);

  const partDeliveriesInstance = useRef(null);

  const calculateTotalPartCost = (shipmentData) => {
    let totalPartCost = 0;
    shipmentData.partDeliveries.forEach((delivery) => {
      totalPartCost += delivery.newCost * delivery.quantityAccepted;
    });
    return totalPartCost;
  };

  const disabledUndo = useMemo(() => {
    return !(isAdmin || isMaintenanceManager);
  }, [isAdmin, isMaintenanceManager]);

  return (
    <>
      <Spinner animation="border" variant="primary" className={`create-spinner${props.purchaseOrderStatusIsLoading ? ' visible' : ' invisible'}`} />
      <Container>
        {shipments?.length > 0 && shipments.map((data, index) => (
          <Row key={index}>
            <Card className="card-secondary card-outline">
              <Card.Header>
                <Container>
                  <Row>
                    <Col>
                      <b>
                        MRN:
                      </b>
                      {' '}
                      {data.mrn}
                    </Col>
                    <Col>
                      <b>
                        MRN ID:
                      </b>
                      {' '}
                      {data.shipmentId}
                    </Col>
                    <Col>
                      <b>
                        Bill of Entry:
                      </b>
                      {' '}
                      {data.boe}

                    </Col>
                    <Col>
                      <b>
                        MRN Cost:
                      </b>
                      {' '}
                      {formatCurrency(data.shipmentCost)}
                    </Col>
                    <Col>
                      <b>
                        Total Parts Cost:
                      </b>
                      {' '}
                      {formatCurrency(calculateTotalPartCost(data))}
                    </Col>
                    <Col>
                      <b>
                        Total Cost:
                      </b>
                      {' '}
                      {formatCurrency(data.shipmentCost + calculateTotalPartCost(data))}
                    </Col>
                    <Col>
                      <b>
                        ShipmentDate:
                      </b>
                      {' '}
                      {formatDate(data.shipmentDate, datePreference)}
                    </Col>
                  </Row>
                </Container>
              </Card.Header>
              <Card.Body style={{ paddingBottom: 0 }}>
                <PaginatedSelectTable
                  columns={partDeliveriesColumns}
                  data={data?.partDeliveries}
                  ref={partDeliveriesInstance}
                  rowProps={() => { }}
                />
              </Card.Body>
              <Card.Footer style={{ paddingTop: 0 }}>
                <Button disabled={disabledUndo || undoPending} onClick={() => onUndoPreview(data?.shipmentId)}>Undo...</Button>
              </Card.Footer>
            </Card>
          </Row>
        ))}
      </Container>
      {(requestsAreLoading || props.purchaseOrderStatusIsLoading || undoPending) && <LoadingContent />}
      {showUndoPreview && (
        <Modal
          show={showUndoPreview}
          onHide={() => {
            setShowUndoPreview(false);
            setUndoPreview(null);
          }}
          size="xl"
          centered
          scrollable
        >
          <Modal.Header>
            <Modal.Title>
              Undo MRN
              {' '}
              {undoPreview ? undoPreview[0].shipmentId : null}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Container fluid>
              <Spinner animation="border" variant="primary" className={`create-spinner${!undoPreview ? ' visible' : ' invisible'}`} />
              {
                undoPreview && (
                  <b className="text-warning">Please review the below adjustments to make sure they are correct.</b>
                )
              }
              {undoPreview && (
                <PaginatedSelectTable
                  columns={[
                    {
                      Header: 'MRN',
                      accessor: 'shipmentId',
                    },
                    {
                      Header: 'Part Name',
                      accessor: 'partName',
                    },
                    {
                      Header: 'Location',
                      accessor: 'location',
                    },
                    {
                      Header: 'Current Quantity',
                      accessor: 'currentQuantity',
                    },
                    {
                      Header: 'New Quantity',
                      accessor: 'quantity',
                    },
                    {
                      Header: 'Current Cost',
                      accessor: 'lastCost',
                    },
                    {
                      Header: 'New Cost',
                      accessor: 'newCost',
                    },
                  ]}
                  data={undoPreview}
                  rowProps={() => { }}
                />
              )}
            </Container>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              setShowUndoPreview(false);
              setUndoPreview(null);
            }}
            >
              Cancel
            </Button>
            <Button onClick={doUndoShipment}>Confirm</Button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default PurchaseOrderShipments;
