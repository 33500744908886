import { createConnectedListView, ListView, ListViewColumnType } from '../../UI/view/list-view';
import { getLiquidContainerHistory } from '../../../services/requests';
import { DataSource } from '../../UI/view/data-source';

export const liquidHistoryListView = {
  columns: [
    {
      Header: 'Trk #',
      accessor: 'trackingNumberID',
    },
    {
      Header: 'Location',
      accessor: 'locationID'
    },
    {
      Header: 'Arrival',
      accessor: 'arriveDate',
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Fill',
      accessor: 'fillDate',
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Ship',
      accessor: 'shipDate',
      defaultSort: true,
      defaultSortDirection: 'desc',
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Fill Customer',
      accessor: 'fillCustomerName'
    },
  ],
} as ListView;

export const liquidHistoryDataSource = {
  request: getLiquidContainerHistory,
  requestArgs: (state) => {
    return state.liquidContainer.currentLiquidContainer?.liquidContainerId;
  },
  name: 'liquidHistory',
  pk: 'trackingNumberID'
} as DataSource

export const LiquidHistoryListViewComponent = createConnectedListView(liquidHistoryListView, liquidHistoryDataSource);