/* eslint-disable max-len */
import React, {
  useCallback, useEffect,
  useMemo, useRef, useState,
} from 'react';
import {
  Button, Card,
  Col, Container, OverlayTrigger, Row, Tooltip,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import {
  BsFillPencilFill, BsPersonFill, BsTools, BsXLg,
} from 'react-icons/bs';
import { GiVacuumCleaner } from 'react-icons/gi';
import { MdAttachFile } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  createMaintenanceRequestLineItem,
  createWorkOrderLineItem,
  setBulkUpdateAndCompleteMaintenanceRequestLineItemsStatus,
  setMaintenanceRequestLineItemCreateStatus,
  setMaintenanceRequestLineItemDeleteStatus,
  setMaintenanceRequestLineItemsBulkUpdateStatus,
  setMaintenanceRequestLineItemStatusUpdateStatus,
  setMaintenanceRequestLineItemUndoStatus,
} from '../../../store/slices/maintenance-request-line-items-slice';
import {
  selectCurrentWorkOrderMaintenanceRequests,
  selectMaintenanceRequestTypes,
  setCurrentMaintenanceRequestId, setUnassignMaintenanceRequestStatus, unassignMaintenanceRequest,
} from '../../../store/slices/maintenance-requests-slice';
import {
  fetchWorkOrder, selectCurrentWorkOrder, selectCurrentWorkOrderId,
  setCurrentWorkOrderId,
} from '../../../store/slices/work-orders-slice';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import ExpandablePaginatedSelectTable from '../../UI/organisms/Table/expandable-paginated-select-table';
import MaintenanceRequestLineItemList from '../MaintenanceLineItem/maintenance-request-line-items-list';
import CreateRequestForm from './create-request-form';
import RequestDetailsForm from './request-details-form';

function WorkOrderMaintenanceRequestsTable(props) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [workOrderLineItemListOpen, setWorkOrderLineItemListOpen] = useState(false);
  const [createModalShow, setCreateModalShow] = useState(false);

  const currentMaintenanceRequests = useSelector(selectCurrentWorkOrderMaintenanceRequests);
  const currentWorkOrderId = useSelector(selectCurrentWorkOrderId);

  const currentWorkOrder = useSelector(selectCurrentWorkOrder);

  const dispatch = useDispatch();

  const handleCreateModalShow = () => {
    setCreateModalShow(true);
  };

  const handleCreateModalClose = () => {
    setCreateModalShow(false);
  };

  const maintenanceRequestsFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const partsFetchStatus = useSelector((state) => state.parts.partsFetch.status);

  const loading = [
    partsFetchStatus,
    maintenanceRequestsFetchStatus,
  ].includes('loading');

  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );

  const miscMaintenanceRequest = useMemo(() => {
    return currentMaintenanceRequests.find(
      (mr) => maintenanceRequestTypes[mr?.maintenanceRequestTypeId]?.isMiscellanious,
    );
  }, [currentMaintenanceRequests, maintenanceRequestsFetchStatus]);

  const maintenanceRequestsData = useMemo(() => {
    return currentMaintenanceRequests;
  }, [currentMaintenanceRequests, maintenanceRequestsFetchStatus]);

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const lineItemCreateStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemCreate.status,
  );

  const lineItemDeleteStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemDelete.status,
  );

  const lineItemBulkUpdateStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemsBulkUpdate.status,
  );
  const lineItemBulkUpdateAndCompleteStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.status,
  );

  const maintenanceRequestUnassignStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestUnassign.status,
  );

  const lineItemStatusUpdateStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemStatusUpdate.status,
  );

  const lineItemStatusUpdateError = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemStatusUpdate.error,
  );

  const lineItemStatusUpdateCompleteError = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceReqmaintenanceRequestLineItemsBulkUpdateAndComplete.error,
  );

  const lineItemUndoStatus = useSelector(
    (state) => state.maintenanceRequestLineItem.maintenanceRequestLineItemUndo.status,
  );

  const [editModalShow, setEditModalShow] = useState(false);

  const handleEditModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setEditModalShow(false);
  };
  const handleEditModalShow = (maintenanceRequestId) => {
    dispatch(setCurrentMaintenanceRequestId(maintenanceRequestId));
    setEditModalShow(true);
  };

  const lineItemCreateNotify = () => toast.success('Created Line Item');
  const lineItemDeleteNotify = () => toast.success('Deleted Line Item');
  const lineItemBulkUpdateNotify = () => toast.success('Updated Line Items');
  const lineItemStatusUpdateNotify = () => toast.success('Completed Line Item');
  const lineItemUndoNotify = () => toast.success('Undo Successful');
  const lineItemStatusUpdateFailedNotify = (error) => toast.error(`Failed to complete line item\n${error}`);
  const unassignNotify = () => toast.success('Removed Maintenance Request from Work Order');
  const lineItemBulkUpdateAndCompleteNotify = () => toast.success('Completed Line Items');

  useEffect(() => {
    if (lineItemCreateStatus === 'succeeded') {
      lineItemCreateNotify();
      dispatch(setMaintenanceRequestLineItemCreateStatus('idle'));
    }
    if (lineItemDeleteStatus === 'succeeded') {
      lineItemDeleteNotify();
      dispatch(setMaintenanceRequestLineItemDeleteStatus('idle'));
    }
    if (lineItemBulkUpdateStatus === 'succeeded') {
      lineItemBulkUpdateNotify();
      dispatch(setMaintenanceRequestLineItemsBulkUpdateStatus('idle'));
    }
    if (maintenanceRequestUnassignStatus === 'succeeded') {
      unassignNotify();
      dispatch(setUnassignMaintenanceRequestStatus('idle'));
    }
    if (lineItemStatusUpdateStatus === 'succeeded') {
      lineItemStatusUpdateNotify();
      dispatch(setMaintenanceRequestLineItemStatusUpdateStatus('idle'));
    }
    if (lineItemStatusUpdateStatus === 'failed') {
      lineItemStatusUpdateFailedNotify(lineItemStatusUpdateError);
      dispatch(setMaintenanceRequestLineItemStatusUpdateStatus('idle'));
    }
    if (lineItemUndoStatus === 'succeeded') {
      lineItemUndoNotify();
      dispatch(setMaintenanceRequestLineItemUndoStatus('idle'));
    }
    if (lineItemBulkUpdateAndCompleteStatus === 'succeeded') {
      lineItemBulkUpdateAndCompleteNotify();
      dispatch(setBulkUpdateAndCompleteMaintenanceRequestLineItemsStatus('idle'));
    }
    if (lineItemBulkUpdateAndCompleteStatus === 'failed') {
      lineItemStatusUpdateFailedNotify(lineItemStatusUpdateCompleteError);
      dispatch(setBulkUpdateAndCompleteMaintenanceRequestLineItemsStatus('idle'));
    }
  }, [
    lineItemCreateStatus,
    lineItemDeleteStatus,
    lineItemBulkUpdateStatus,
    maintenanceRequestUnassignStatus,
    lineItemStatusUpdateStatus,
    lineItemUndoStatus,
    lineItemBulkUpdateAndCompleteStatus,
  ]);

  const createLineItem = (row, typeId) => {
    dispatch(createMaintenanceRequestLineItem({
      maintenanceRequestId: row.original.maintenanceRequestId,
      lineItem: {
        maintenanceRequestId: row.original.maintenanceRequestId,
        maintenanceRequestLineItemTypeId: typeId,
      },
    }));
    if (!row.isExpanded) {
      row.toggleRowExpanded();
    }
  };

  const addLineItemToWorkOrder = (typeId) => {
    dispatch(createWorkOrderLineItem({
      workOrderId: currentWorkOrderId,
      lineItem: {
        maintenanceRequestLineItemTypeId: typeId,
      },
    }));
    setWorkOrderLineItemListOpen(true);
  };

  const renderAddLineItemTooltip = (info) => (
    <Tooltip id="button-tooltip">
      {info}
    </Tooltip>
  );

  const attachmentsModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    props.handleMaintenanceRequestAttachmentsModalShow();
  };

  const onUnassign = (maintenanceRequestId) => {
    dispatch(unassignMaintenanceRequest({ maintenanceRequestId })).unwrap().then(async (result) => {
      // update work order
      const workOrder = await dispatch(fetchWorkOrder(currentWorkOrderId)).unwrap();
      dispatch(setCurrentWorkOrderId(currentWorkOrderId));
    }).catch((error) => {
      console.log(`Error : ${error.message}`);
    });
  };

  const workOrderIsClosed = currentWorkOrder?.workOrderStatusId === 5;

  const containsConsumed = (row) => {
    const reqId = row.original.maintenanceRequestId;
    const myReq = currentMaintenanceRequests.find((req) => req.maintenanceRequestId === reqId);
    const lineItems = myReq?.maintenanceRequestLineItems || [];
    const hasConsumed = lineItems.find((item) => item.isActive && item.isCompleted);
    return hasConsumed?.maintenanceRequestLineItemId;
  };

  const maintenanceRequestsColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'expander',
        accessor: 'serialNumber',
        Cell: ({ value, row }) => (
          <div className="d-flex">
            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{containsConsumed(row) ? 'You must first undo consumed line items' : 'Remove from Work Order'}</Tooltip>}>
              <span className="d-inline-block">
                <Button
                  disabled={containsConsumed(row) || workOrderIsClosed}
                  size="xsm"
                  title="Remove"
                  variant="danger"
                  onClick={() => onUnassign(row.original.maintenanceRequestId)}
                  className="mr-1"
                >
                  <BsXLg />
                </Button>
              </span>
            </OverlayTrigger>
            <Button
              size="xsm"
              title="Edit"
              onClick={() => handleEditModalShow(row.original.maintenanceRequestId)}
              className="mr-1"
            >
              <BsFillPencilFill />
            </Button>
            <Button
              size="xsm"
              title="Add Attachment"
              onClick={() => attachmentsModalShow(row.original.maintenanceRequestId)}
              className="mr-1"
            >
              <MdAttachFile />
            </Button>
            <div>
              <span {...row.getToggleRowExpandedProps()}>
                <i className={`bi-chevron-right ${row.isExpanded ? 'expanded-chevron' : ''}`} />
              </span>
            </div>
            &nbsp;
            <OverlayTrigger
              placement="top"
              overlay={renderAddLineItemTooltip('Add New Part Line Item')}
            >
              <Button
                size="xsm"
                variant="success"
                title="Add New Part Line Item"
                disabled={workOrderIsClosed}
                onClick={() => createLineItem(row, 1)}
              >
                <BsTools />
              </Button>
            </OverlayTrigger>
            &nbsp;
            <OverlayTrigger
              placement="top"
              overlay={renderAddLineItemTooltip('Add New Labor Line Item')}
            >
              <Button
                size="xsm"
                variant="success"
                title="Add New Labor Line Item"
                disabled={workOrderIsClosed}
                onClick={() => createLineItem(row, 2)}
              >
                <BsPersonFill />
              </Button>
            </OverlayTrigger>
            &nbsp;
            <OverlayTrigger
              placement="top"
              overlay={renderAddLineItemTooltip('Add New Vacuum Line Item')}
            >
              <Button
                size="xsm"
                variant="success"
                title="Add New Vacuum Line Item"
                disabled={workOrderIsClosed}
                onClick={() => createLineItem(row, 3)}
              >
                <GiVacuumCleaner />
              </Button>
            </OverlayTrigger>
          </div>
        ),
      },
      // {
      //   Header: 'Status',
      //   accessor: 'maintenanceRequestStatus',
      //   Cell: ({ value }) => (value == null ? null : splitStringOnUppercase(value)),
      // },
      {
        Header: ({ value = 'Complaint Date' }) => <span title={value}>Date</span>,
        accessor: 'complaintDate',
        Cell: ({ value }) => (value == null ? null : formatDate(value, datePreference)),
      },
      {
        Header: ({ value = 'Request Type Name' }) => <span title={value}>Type</span>,
        accessor: 'maintenanceRequestType',
      },
      {
        Header: ({ value = 'Estimated Effort' }) => <span title={value}>Effort</span>,
        accessor: 'estimatedEffort',
      },
      {
        Header: ({ value = 'Labor Hours' }) => <span title={value}>Labor</span>,
        accessor: 'maintenanceRequestLineItems',
        id: 'laborHours',
        Cell: ({ value }) => (value === null ? null : value.filter(
          (li) => li.isActive === true && li.maintenanceRequestLineItemTypeId === 2,
        ).map(({ quantity }) => [quantity]).flat(1).reduce((acc, val) => {
          return acc + val;
        }, 0)),
      },
      {
        Header: ({ value = 'Vacuum Hours' }) => <span title={value}>Vacuum</span>,
        accessor: 'maintenanceRequestLineItems',
        id: 'vacuumHours',
        Cell: ({ value }) => (value === null ? null : value.filter(
          (li) => li.isActive === true && li.maintenanceRequestLineItemTypeId === 3,
        ).map(({ duration }) => [duration]).flat(1).reduce((acc, val) => {
          return acc + val;
        }, 0)),
      },
      {
        Header: ({ value = 'Due Date' }) => <span title={value}>Date</span>,
        accessor: 'plannedDueDate',
        Cell: ({ value }) => (value == null ? null : formatDate(value, datePreference)),
      },
      {
        Header: ({ value = 'Description' }) => <span title={value}>Desc</span>,
        accessor: 'details',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [workOrderIsClosed, currentMaintenanceRequests],
  );

  const renderRowSubComponent = useCallback(({ row }) => {
    return (
      <MaintenanceRequestLineItemList
        maintenanceRequestId={row.original.maintenanceRequestId}
        handleWorkOrderModalShow={props.handleWorkOrderModalShow}
        key={row.original.maintenanceRequestId}
      />
    );
  }, []);

  const maintenanceRequestsTableInstance = useRef(null);

  const maintenanceRequestsContent = (

    <Container fluid className="d-flex flex-column h-100 py-2 overflow-auto work-order-line-items-section">
      <Row className="flex-grow-1 overflow-auto">
        <Col className="h-100">
          <Card className="card-secondary card-outline flex-grow-1 p-0 m-0 overflow-auto h-100">
            <Card.Header>
              <div className="d-flex justify-content-between m-0">
                <h3 className="card-title">Requests</h3>
                <Button disabled={currentWorkOrder?.workOrderStatusId === 5} size="sm" variant="primary ml-5 sposition-sticky float-end bottom-0 my-0" onClick={handleCreateModalShow}> Add Request </Button>
              </div>
            </Card.Header>
            <Card.Body className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
              <div className="h-100">
                <div className="d-flex flex-column flex-grow-1 h-100">
                  <ExpandablePaginatedSelectTable
                    columns={maintenanceRequestsColumns}
                    data={maintenanceRequestsData}
                    ref={maintenanceRequestsTableInstance}
                    renderRowSubComponent={renderRowSubComponent}
                  />
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

  return (
    <>
      <div className={`d-flex flexbox flex-column flex-grow-1 h-100${loading ? ' creation-loading' : ''}`}>
        {maintenanceRequestsContent}
      </div>
      {loading && <LoadingContent />}
      <RequestDetailsForm
        show={editModalShow}
        onHide={handleEditModalClose}
        onWorkOrder
      />
      <CreateRequestForm
        show={createModalShow}
        onHide={handleCreateModalClose}
        createModalAssetType={currentWorkOrder?.assetTypeId}
        assetSerialNumber={currentWorkOrder?.assetSerialNumber}
        currentWorkOrderId={currentWorkOrder?.workOrderId}
      />
    </>
  );
}

export default WorkOrderMaintenanceRequestsTable;
