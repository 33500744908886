import React, { useMemo } from 'react';
import { Container } from 'react-bootstrap';

import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import PartsListTabs from './parts-list-tabs';

function PartsList() {
  usePageTitle('Parts List');

  const breadcrumbs = useMemo(() => [
    {
      name: 'Parts Inventory',
      icon: 'tools',
      to: 'maintenance/parts-inventory',
    },
  ], []);

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column mb-2">
      <PageHeader header="Parts Inventory" breadcrumbs={breadcrumbs} />
      <PartsListTabs />
    </Container>
  );
}

export default PartsList;
