/* eslint-disable spaced-comment */
/* eslint-disable no-return-assign */
import Polyline from '@arcgis/core/geometry/Polyline';
import * as geodesicUtils from '@arcgis/core/geometry/support/geodesicUtils';
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import React, { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { formatDate, formatNumberPrecision } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import Recenter from './recenter';
import { intersects } from "@arcgis/core/geometry/geometryEngine";

export function LocationMap(props) {
  const {assetType, readings} = props;
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);
  const mapDiv = useRef(null);
  const { summary: summaryData, breadcrumbs: breadcrumbsRaw } = readings;

  const uniqBy = (a, key) => {
    const seen = {};
    return a.filter((item) => {
      const k = key(item);
      // eslint-disable-next-line no-return-assign, no-prototype-builtins
      return seen.hasOwnProperty(k) ? false : (seen[k] = true);
    });
  };
  const uniqueSeq = (a, key) => {
    let seen = null;
    return a.filter((item) => {
      const k = key(item);
      const newPosition = seen !== k;
      seen = k;
      return newPosition;
    });
  };

  const queryPopupInfo = async (breadcrumb, isSummary) => {


    const rows = 
    (
      `
       <tr>
        <td>N2 Level</td>
        <td>${breadcrumb.n2Level}</td>
       </tr>
       <tr>
        <td>N2 Pressure</td>
        <td>${breadcrumb.n2Pressure}</td>
       </tr>
       <tr>
        <td>He Level</td>
        <td>${breadcrumb.heLevel}</td>
       </tr>
       <tr>
        <td>He Pressure</td>
        <td>${breadcrumb.hePressure}</td>
        </tr>
        <tr>
          <td>Battery Level</td>
          <td>${breadcrumb.batteryLevel}</td>
        </tr>
        <tr>
          <td>Ambient Temperature</td>
          <td>${breadcrumb.ambientTemperature}</td>
        </tr>
       `
    );
    const output = `
      <table>
        <tbody>
          ${rows}
        </tbody>
      </table>
    `;
    return output;
  }

  const getDate = (record) => {
    console.log(record);
    return record?.LatitudeTS ?? record?.ReadingDate ?? record?.readingDate ?? record?.readingTime ?? record?.dt;
  }

  const breadcrumbs = uniqueSeq((breadcrumbsRaw || [])
    .filter((x) => x.CoordinatesPair !== '')
    .map((x) => ({
      lon: x.Longitude,
      lat: x.Latitude,
      dt: formatDate(x.ReadingTime),
      ...x
    })), (x) => JSON.stringify({ lat: x.lat, lon: x.lon }));

  useEffect(() => {
    let view = null;
    if (mapDiv.current && breadcrumbs?.length) {
      const map = new ArcGISMap({
        basemap: 'gray-vector',
      });

      const myPath = breadcrumbsRaw.filter((x) => x.CoordinatesPair).map((x) => JSON.parse(x.CoordinatesPair));
      myPath.unshift([summaryData.Longitude, summaryData.Latitude]);
      const densifiedPolyline = geodesicUtils.geodesicDensify(new Polyline({
        paths: myPath,
      }), 1000);
      view = new MapView({
        map,
        container: mapDiv.current,
        zoom: 3,
        center: Number(summaryData.Longitude) + Number(summaryData.Latitude) !== 0 ? [summaryData.Longitude, summaryData.Latitude] : undefined,
        graphics: [
          // eslint-disable-next-line indent
          ...breadcrumbs.reverse().map((breadcrumb) => ({
            geometry: {
              longitude: Number(breadcrumb.lon),
              latitude: Number(breadcrumb.lat),
              type: 'point',
            },
            symbol: {
              type: 'simple-marker',
              style: 'circle',
              color: [0, 85, 145],
              size: '7px',
              outline:
              {
                width: 0,
              },
            },
            popupTemplate: {
              title: `${getDate(breadcrumb) ? (formatDate(getDate(breadcrumb), datePreference)) : ''} [${formatNumberPrecision(breadcrumb.lat, 3)}, ${formatNumberPrecision(breadcrumb.lon, 3)}]`,
              content: assetType == 'liquid' ? (async () => await queryPopupInfo(breadcrumb, false)) : '<div></div>',
            },
          })),
          {
            geometry: densifiedPolyline,
            symbol: {
              type: 'simple-line',
              color: [0, 85, 145],
              width: 2,
            },
          },
          {
            geometry: {
              type: 'point',
              longitude: summaryData.Longitude,
              latitude: summaryData.Latitude,
            },
            symbol: {
              type: 'simple-marker',
              color: [0, 85, 145],
              outline:
              {
                color: [255, 255, 255],
                width: 2,
              },
            },
            popupTemplate: {
              title: `Current Location as of ${getDate(summaryData) ? (formatDate(getDate(summaryData), datePreference)) : ''} [${formatNumberPrecision(summaryData.Latitude, 3)}, ${formatNumberPrecision(summaryData.Longitude, 3)}]`,
              content: assetType == 'liquid' ? (async () => await queryPopupInfo(summaryData, true)) : '<div></div>',
            },
          },
        ],
      });     
    }
  }, [breadcrumbs]);

  return <div style={{ width: '100%', height: '100%' }} className="mapDiv" ref={mapDiv} />;
}
