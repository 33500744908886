/* eslint-disable max-len */
import React, {
  useCallback,
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button,
  Collapse,
  InputGroup,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BsPencilFill, BsPlusLg, BsXLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import { formatCurrency, formatDate } from '../../../services/format';
import { getDisplayNameByOID } from '../../../services/table-helpers';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectMaintenanceRequestDropdownObjects } from '../../../store/slices/maintenance-requests-slice';
import { selectCurrentPartId } from '../../../store/slices/parts-slice';
import { deletePurchaseRequest, fetchPurchaseRequestsByPart, selectPurchaseRequestsByPart } from '../../../store/slices/purchase-request-slice';
import { selectUsersByOid } from '../../../store/slices/users-slice';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import PurchaseRequestForm from './purchase-request-form';
import { purchaseRequestPriorities } from './purchase-requests-values';

function PartPurchaseRequests(props) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [editPurchaseRequest, setEditPurchaseRequest] = useState(null);

  const tableInstance = useRef(null);
  const usersByOid = useSelector(selectUsersByOid);
  const data = useSelector(selectPurchaseRequestsByPart);
  const purchaseRequestsByPartFetchStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsByPartFetch.status);
  const purchaseRequestsByPartFetchError = useSelector((state) => state.purchaseRequests.purchaseRequestsByPartFetch.error);
  const purchaseRequestsDeleteStatus = useSelector((state) => state.purchaseRequests.purchaseRequestsDelete.status);
  const purchaseRequestsDeleteError = useSelector((state) => state.purchaseRequests.purchaseRequestsDelete.error);

  const { datePreference } = useSelector(selectLoggedInUserPreferences);
  const { purchaseRequestStatuses } = useSelector(selectMaintenanceRequestDropdownObjects);
  const currentPartId = useSelector(selectCurrentPartId);

  const { confirm } = useConfirm();

  const handleModalShow = useCallback((purchaseRequest) => {
    setEditPurchaseRequest(purchaseRequest);
    setModalShow(true);
  }, [setEditPurchaseRequest, setModalShow]);

  const handleModalClose = useCallback(() => {
    setEditPurchaseRequest(null);
    setModalShow(false);
  }, [setEditPurchaseRequest, setModalShow]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (currentPartId) {
      dispatch(fetchPurchaseRequestsByPart(currentPartId));
    }
  }, [currentPartId]);

  // REquest status listener
  useEffect(() => {
    if (purchaseRequestsDeleteStatus === 'succeeded') {
      toast.success('Purchase Request Deleted');
    } else if (purchaseRequestsDeleteStatus === 'failed') {
      toast.error(`Failed to Delete Puchase Request\n${purchaseRequestsDeleteError}`);
    }
  }, [purchaseRequestsDeleteStatus]);

  const getDisplayName = useCallback((OID) => {
    return getDisplayNameByOID(OID, usersByOid);
  }, [usersByOid]);

  const columns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => (
          <div className="d-flex">
            <Button
              size="xsm"
              title="Edit"
              onClick={() => {
                handleModalShow(cell.row.original);
              }}
            >
              <BsPencilFill />
            </Button>
            &nbsp;
            <Button
              size="xsm"
              variant={cell.row.original.isActive ? 'danger' : 'success'}
              title={cell.row.original.isActive ? 'Deactivate' : 'Activate'}
              onClick={async () => {
                const isConfirmed = await confirm('Are you sure you want to delete this Purchase Request?');
                if (isConfirmed) {
                  dispatch(deletePurchaseRequest(cell.row.original.purchaseRequestId));
                }
              }}
            >
              {cell.row.original.isActive ? <BsXLg /> : <BsPlusLg />}
            </Button>
          </div>
        ),
      },
      {
        Header: 'ID',
        accessor: 'purchaseRequestId',
        disableSortBy: true,
      },
      {
        Header: 'Part Id',
        accessor: 'partId',
      },
      {
        Header: 'Last Cost',
        id: 'lastCost',
        accessor: ({ lastCost }) => formatCurrency(lastCost),
      },
      {
        Header: 'Status',
        accessor: 'purchaseRequestStatusId',
        Cell: ({ value }) => (value == null ? null : purchaseRequestStatuses[value]?.name),
      },
      {
        Header: 'Quantity Requested',
        accessor: 'quantityRequested',
      },
      {
        Header: 'Requested Date',
        accessor: 'created',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
      {
        Header: 'Requested By',
        id: 'createdBy',
        accessor: ({ createdBy: value }) => (getDisplayName(value)),
      },
      {
        Header: 'Priority',
        accessor: 'priority',
        Cell: ({ value }) => (value == null ? null : purchaseRequestPriorities[value]),
      },
      {
        Header: 'Comment',
        accessor: 'comment',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [purchaseRequestStatuses],
  );

  const memoData = useMemo(() => [...data], [data]);

  const requestsAreLoading = [
    purchaseRequestsByPartFetchStatus,
  ].includes('loading');

  return (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          {/* <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button> */}
          <InputGroup.Text><i className="bi bi-search" /></InputGroup.Text>
          <TableSearchFilter tableInstance={tableInstance} />
           &nbsp;
          <Button variant="primary" size="sm" onClick={() => handleModalShow(null)}>Create Purchase Request</Button>
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              Filters
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={columns}
        data={memoData}
        ref={tableInstance}
        rowProps={() => ({})}
      />
      {purchaseRequestsByPartFetchStatus === 'failed' && <ErrorContent errorMessage={purchaseRequestsByPartFetchError} />}
      {requestsAreLoading && <LoadingContent />}
      <PurchaseRequestForm
        show={modalShow}
        onHide={handleModalClose}
        partId={currentPartId}
        purchaseRequest={editPurchaseRequest}
      />
    </>
  );
}

export default PartPurchaseRequests;
