import { GetVendorList, GetWorldRareGasReadings, GetWorldReadings, UpdateVendor } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";
import { CreateVendor } from "../../../services/requests";

export const worldMapDataSource = {
  name: 'worldmap',
  pk: 'containerId',
  request: GetWorldReadings,
} as DataSource

export const worldMapRareGasDataSource = {
  name: 'worldmaprare',
  pk: 'containerId',
  request: GetWorldRareGasReadings,
} as DataSource