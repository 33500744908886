import React, {
  useEffect, useMemo, useRef, useState,
} from 'react';
import {
  Button, Col, Form, InputGroup,
  Modal, Row,
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { BsXLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';

import {
  selectCurrentUserLocation,
  selectCurrentUserLocationId,
  selectCurrentUserLocationStatus,
  setCurrentUserLocationId,
  setCurrentUserLocationStatus,
  updateCurrentUserLocation,
} from '../../../store/slices/roles-slice';
import { fetchUsers, selectCurrentUserLocationUsers, selectUsersNotInLocation } from '../../../store/slices/users-slice';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { Typeahead } from '../../UI/molecules/Typeahead';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

function LocationAddModal({ show, onHide }) {
  const typeaheadRef = useRef(null);

  const currentUserLocationStatus = useSelector(selectCurrentUserLocationStatus);
  const currentUserLocationId = useSelector(selectCurrentUserLocationId);
  const currentUserLocation = useSelector(selectCurrentUserLocation);
  const usersNotInUserLocation = useSelector(selectUsersNotInLocation);

  const dispatch = useDispatch();

  const [userToAdd, setUserToAdd] = useState(null);

  const onModalHide = () => {
    onHide();
    setUserToAdd(null);
    dispatch(setCurrentUserLocationStatus('idle'));
    dispatch(setCurrentUserLocationId(null));
  };

  const onUserAdd = (userId) => {
    dispatch(updateCurrentUserLocation({ userId, action: 'add' }));
  };

  const onUserRemove = (userId) => {
    dispatch(updateCurrentUserLocation({ userId, action: 'remove' }));
    setUserToAdd(null);
  };

  const currentUsersColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => (
          <Button
            size="xsm"
            variant="danger"
            onClick={() => {
              onUserRemove(cell.row.original.userId);
            }}
          >
            <BsXLg />
          </Button>
        ),
      },
      {
        Header: 'Name',
        accessor: 'displayName',
      },
    ],
    [],
  );

  const currentUsersTableInstance = useRef(null);

  const currentUserLocationUsers = useSelector(selectCurrentUserLocationUsers);

  const locationUsersData = useMemo(() => {
    if (currentUserLocationUsers) {
      return currentUserLocationUsers;
    }

    return [];
  }, [currentUserLocationUsers]);

  const currentUsersTable = (
    <PaginatedSelectTable
      columns={currentUsersColumns}
      data={locationUsersData}
      ref={currentUsersTableInstance}
      rowProps={(row) => ({})}
    />
  );

  const locationUpdateSuccessNotify = () => toast.success('Updated Location Users');
  const locationUpdateFailureNotify = () => toast.error('Failued to update Location Users');

  useEffect(() => {
    if (currentUserLocationStatus === 'succeeded') {
      locationUpdateSuccessNotify();
      dispatch(setCurrentUserLocationStatus('idle'));
      setUserToAdd(null);
      if (typeaheadRef?.current) {
        typeaheadRef.current.clear();
      }
    }
    if (currentUserLocationStatus === 'failed') {
      locationUpdateFailureNotify();
      dispatch(setCurrentUserLocationStatus('idle'));
      setUserToAdd(null);
    }
  }, [currentUserLocationStatus]);

  useEffect(() => {
    if (show === true) dispatch(fetchUsers());
  }, [show]);
  const statusIsLoading = currentUserLocationStatus === 'loading';

  return (
    <Modal
      show={show}
      dialogClassName="rbac-add-modal"
      onHide={() => onModalHide()}
      size="lg"
      centered
      scrollable
      backdrop={statusIsLoading ? 'static' : true}
    >
      <Modal.Header closeButton={!statusIsLoading}>
        <Modal.Title id="contained-modal-title-vcenter">
          Location
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="d-flex flex-column">
        {statusIsLoading ? <LoadingContent /> : ''}
        <Row className={`${statusIsLoading ? ' creation-loading' : ''}`}>
          <Col className="role-input-section">

            <Form>

              <Form.Group controlId="adInfo">
                <Row className="mb-3">
                  <Col>
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      size="sm"
                      readOnly
                      placeholder="name"
                      value={currentUserLocation.LocationName ?? ''}
                    />
                  </Col>
                  <Col>
                    <Form.Label>Resident Country</Form.Label>
                    <Form.Control
                      size="sm"
                      readOnly
                      placeholder="name"
                      value={currentUserLocation.ResidentCountry ?? ''}
                    />
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </Col>
        </Row>

        <Form.Group>
          <Form.Label>Users In Location</Form.Label>
          <Row className="pb-3">
            <Col>
              <InputGroup>
                <Typeahead
                  id="add-user-to-role-typeahead"
                  labelKey="displayName"
                  size="sm"
                  className="flex-grow-1"
                  onChange={(e) => {
                    setUserToAdd(e[0]);
                  }}
                  ref={typeaheadRef}
                  options={usersNotInUserLocation}
                  placeholder="Add user to location..."
                />
                    &nbsp;
                <Button
                  size="sm"
                  onClick={() => onUserAdd(userToAdd.userId)}
                  disabled={!userToAdd?.userId}
                >
                  Add User
                </Button>
              </InputGroup>
            </Col>
          </Row>

        </Form.Group>
        <div className="d-flex flex-column flex-grow-1 overflow-auto">
          {currentUsersTable}
        </div>

      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          onClick={onHide}
          variant="secondary"
          className="mt-3 position-sticky float-end bottom-0"
          disabled={statusIsLoading}
        >
          Close

        </Button>
        {currentUserLocationId === null
        && (
        <Button
          type="submit"
          className="mt-3 position-sticky float-end bottom-0"
        >
          Submit

        </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
}

export default LocationAddModal;
