import React from 'react';

function InfoListItem(props) {
  return (
    <div className="">
      {props.name}
      :
      <span className="float-right text-right">{props.value}</span>
    </div>
  );
}

export default InfoListItem;
