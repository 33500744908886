import React, { useCallback, useEffect, useMemo, useRef } from "react";
import { Modal, Spinner, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { DataSource, Repository } from "./data-source";
import { createFormView, FormView } from "./form-view";
import { useForm } from 'react-hook-form';
import { BsMenuButtonWideFill } from "react-icons/bs";
import toast from 'react-hot-toast';
import { getFormFieldCollection } from '../../../services/form-helpers';

export interface ModalViewProps {
  show,
  onModalHide,
  title,
  form: FormView,
  repository: Repository,
  submitAction?: any,
  editMode?: boolean,
}
export const ModalView = (props: ModalViewProps) => {
  const { show, onModalHide, title, form, repository, submitAction, editMode } = props;
  const { modalSize } = form;
  const { dataSource, fetchData, actions: { setCreateStatus, setUpdateStatus } } = repository;
  const { pk } = dataSource;


  const createStatus = useSelector((state: any) => state[dataSource.name].createAction.status);
  const updateStatus = useSelector((state: any) => state[dataSource.name].updateAction.status);
  const data = useSelector(state => state[dataSource.name].data);
  const currentEntityId = useSelector(state => state[dataSource.name].current.data ? state[dataSource.name].current.data[pk] : null);
  const currentEntity = useMemo(() => {
    if (!currentEntityId || !data) return null;
    return data.find(d => d[dataSource.pk] === currentEntityId)
  }, [currentEntityId, data]);
  const dataStatus = useSelector((state: any) => {
    const base = state[dataSource.name];
    const status = base.status;
    return status;
  }) as string;
  const dispatch = useDispatch();

  const defaults = useMemo(() => getFormFieldCollection(form.items, 'defaultValue'), [form.items]);

  function removeEmptyFields(data) {
    Object.keys(data).forEach(key => {
      if (data[key] === '' && !(defaults && defaults[key] == '')) {
        data[key] = null;
      }
    });
  }

  const onSubmit = (formData) => {
    removeEmptyFields(formData);
    dispatch(submitAction(formData));
  };

  const {
    register, control, watch, handleSubmit, setValue, reset, formState, unregister, getValues
  } = useForm({ mode: 'onSubmit', defaultValues: editMode ? { ...defaults, ...currentEntity } : { ...defaults } });

  const formStateVars = useMemo(() => {
    return { register, control, watch, formState, setValue, unregister, getValues }
  }, [register, control, watch, formState])

  const formComponent = useMemo(() => {
    return form && register ? createFormView({ ...form, formState: formStateVars }, repository) : null;
  }, [register, formState]);

  const isLoading = createStatus === 'loading' || updateStatus === 'loading';

  return (
    <Modal
      show={show}
      dialogClassName="rbac-add-modal"
      onHide={() => onModalHide()}
      size={modalSize || "lg"}
      centered
      scrollable
      backdrop={isLoading ? 'static' : true}
    >
      {title && <Modal.Header closeButton={!isLoading}>
        <Modal.Title id="contained-modal-title-vcenter">
          {title}
        </Modal.Title>
      </Modal.Header>}
      <Modal.Body style={{ paddingLeft: 6, paddingRight: 6 }}>
        {isLoading ? <Spinner animation="border" variant="primary" className="create-spinner" /> : ''}
        <Container fluid style={{ paddingLeft: 0, paddingRight: 0 }} className={`${isLoading ? ' creation-loading' : ''}`}>
          {formComponent ? formComponent() : null}
        </Container>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button
          className="mt-3 position-sticky float-end bottom-0"
          onClick={() => onModalHide()}
          variant="secondary"
          disabled={isLoading}
        >
          Close

        </Button>
        <Button
          className="mt-3 position-sticky float-end bottom-0"
          type="submit"
          onClick={handleSubmit(onSubmit)}
          disabled={
            isLoading
          }

        >
          Submit
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
