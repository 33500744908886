import { getLiquidShipments, saveDemandForecasting } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";

export const liqShipmentsDataSource = {
  name: 'liquidCustomerShipments',
  pk: 'pk',
  entityRequest: getLiquidShipments,
  entityRequestArgs: (formState) => {
    const {year, type, asOfDate, compare, snapshot} = formState.getValues();
    return `${year}_${snapshot}`  ;
  },
  updateRequest: saveDemandForecasting
} as DataSource;

export const forecastingPermissions = {
  read: [1, 10, 11, 12],
  write: [1, 11, 12],
  admin: [1, 12]
};