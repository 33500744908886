export const liquidContainerManufacturers = [
  'Air Liquide', 'Cryenco', 'Gardner', 'Linde', 'Union Carbide', 'Taiyo', 'Cryo AB',
];

export const liquidContainerTypes = [
  '15', '64', '64S', '87', '91', '91S', '151', '175',
];

export const liquidContainerRegulatoryDocuments = [
  'UN Portable', 'DOT',
];

export const liquidContainerSpecialPermits = [
  'SP-6765', 'SP-9832', 'SP-10146', 'SP-10480', 'SP-8556', 'Unportable', 'Not Required',
];

export const liquidContainerShieldTypes = [
  'Aluminum', 'Copper',
];

export const liquidContainerStyleTypes = [
  3400,
  11000,
  15000,
];

export const liquidContainerRehabCycleYears = [
  5,
  7.5,
  10,
];

export const liquidContainerMawps = [
  0,
  92,
  175,
  127,
  58,
  64,
  87,
  150,
  50,
  170,
  61,
  25,
  62,
  123,
  54,
  91,
  20,
  68,
  151,
];

export const getProducerCountry = (manufacturer) => {
  if (manufacturer === 'Gardner') {
    return 'USA';
  } if (manufacturer === 'Cryo AB') {
    return 'Sweden';
  } if (manufacturer === 'Linde') {
    return 'Germany';
  }
  return null;
};

export const getLiquidOwnerCustomer = (liquidCustomers, value) => {
  const notNull = !(value == null);
  if (notNull && liquidCustomers[value.toString()]?.CustomerName) {
    return liquidCustomers[value.toString()]?.CustomerName;
  }
  if (notNull) {
    return `Unknown ID: ${value}`;
  }
  return null;
};

export const getLiquidOwnerMawp = (value) => {
  return value == null ? null : liquidContainerMawps[value];
};
