import React from 'react';
import { ListGroup, Placeholder } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectGasCustomerObjects, selectLiquidCustomerObjects } from '../../../store/slices/legacy-slice';
import { selectCurrentWorkOrderMaintenanceRequests } from '../../../store/slices/maintenance-requests-slice';
import { selectCurrentWorkOrder, selectCurrentWorkOrderAsset } from '../../../store/slices/work-orders-slice';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';

function WorkOrderInfo({ workOrderStatusIsLoading }) {
  const currentWorkOrder = useSelector(selectCurrentWorkOrder);
  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const liquidCustomers = useSelector(selectLiquidCustomerObjects);
  const gasCustomers = useSelector(selectGasCustomerObjects);

  const currentAsset = useSelector(selectCurrentWorkOrderAsset);

  let customers = {};
  let assetTypeName = '';
  let assetLinkPrefix = '';
  if (currentWorkOrder) {
    if (currentWorkOrder?.assetTypeId === 1) {
      assetTypeName = 'Gas Container';
      assetLinkPrefix = '/assets/gas-container/';
      customers = gasCustomers;
    }
    if (currentWorkOrder?.assetTypeId === 2) {
      assetTypeName = 'Liquid Container';
      assetLinkPrefix = '/assets/liquid-container/';
      customers = liquidCustomers;
    }
    if (currentWorkOrder?.assetTypeId === 4) {
      assetTypeName = 'Chassis';
      assetLinkPrefix = '/assets/chassis/';
      customers = liquidCustomers;
    }
  }

  const maintRequests = useSelector(selectCurrentWorkOrderMaintenanceRequests);
  const lineItemsObj = maintRequests.map(
    (
      { maintenanceRequestLineItems },
    ) => (maintenanceRequestLineItems !== null ? [maintenanceRequestLineItems] : []),
  );

  const lineItemsArr = lineItemsObj.length > 0 ? [].concat(...lineItemsObj.flat(1)) : [];

  const laborHours = lineItemsArr.filter(
    (li) => li.isActive === true && li.maintenanceRequestLineItemTypeId === 2,
  ).map(({ quantity }) => [quantity]).flat(1).reduce((acc, val) => {
    return acc + val;
  }, 0);

  const vacuumHours = lineItemsArr.filter(
    (li) => li.isActive === true && li.maintenanceRequestLineItemTypeId === 3,
  ).map(({ duration }) => [duration]).flat(1).reduce((acc, val) => {
    return acc + val;
  }, 0);

  const placeholder = <Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>;

  const workOrderIsUnavailable = (workOrderStatusIsLoading || (!currentWorkOrder));

  /* eslint-disable */
  const type = workOrderIsUnavailable
    ? placeholder
    : assetTypeName;
  const serialNumber = workOrderIsUnavailable
    ? placeholder
    : (currentWorkOrder?.assetSerialNumber || '');
  const assetOwner = workOrderIsUnavailable
    ? placeholder
    : (customers[currentAsset?.ownerCustomer]?.CustomerName ?? 'N/A');
  const createdOn = workOrderIsUnavailable
    ? placeholder
    : formatDate(currentWorkOrder.created, datePreference);
  const modifiedOn = workOrderIsUnavailable
    ? placeholder
    : formatDate(currentWorkOrder.modified, datePreference);

  return (
    <Callout>
      <h4>
        {currentWorkOrder ? `Work Order: ${currentWorkOrder.workOrderId}`
          : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}
      </h4>
      <ListGroup variant="unbordered">
        <ListGroup.Item><InfoListItem name="Asset Type" value={(type)} /></ListGroup.Item>
        <ListGroup.Item>
          <InfoListItem
            name="Asset Unit Number"
            value={(<NavLink to={`${assetLinkPrefix}${serialNumber}`}>{serialNumber}</NavLink>)}
          />
        </ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Owner" value={(assetOwner)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Added On" value={(createdOn)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Last Modified On" value={(modifiedOn)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Total Labor Hours" value={(laborHours)} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Total Vacuum Hours" value={(vacuumHours)} /></ListGroup.Item>
      </ListGroup>
    </Callout>
  );
}

export default WorkOrderInfo;