import moment from "moment";
import { addDaysToDate, formatCurrency, formatDate } from "../../../../services/format";
import { LogicalConnector } from "../../../UI/view/data-source";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../../UI/view/list-view";
import { createConnectedView } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { BillingList, billingListView as billingListViewFactor, billingView } from "../billing-invoice-list";
import { months } from "../../../../services/date-helpers";
import { createEmptyInvoice } from "./liquidrental-create-empty-invoice";
import { GetLiquidRentalBillingList, InsertEmptyBilling, UpdateBilling, getBillingHeader, uploadInvoicesJDEDTO } from "../../../../services/requests/billing/liquid-rental-billing-requests";

export const liquidRentalBillingDataSource = {
  name: 'liquidRB',
  request: GetLiquidRentalBillingList,
  entityRequest: getBillingHeader,
  updateRequest: UpdateBilling,
  createRequest: InsertEmptyBilling,
  serverSideEvents: true,
  pk: 'billingId'
}

const billingListView = billingListViewFactor({uploadEndpoint: uploadInvoicesJDEDTO, collectionName: 'liquidRentalBillingList'});

export const liquidRentalBillingList = {
  addButtonText: 'Create Invoice',
  create: createEmptyInvoice,
  secondarySort: billingListView.secondarySort,
  toolbar: billingListView.toolbar,
  filters: billingListView.filters,
  defaultPageSize: 500,
  maxPageSize: 500,
  permissions: billingPermissions,
  columns: [
    billingListView.columns.find(c => c.accessor == 'checked'),
    billingListView.columns.find(c => c.id == 'trafficStatus'),
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/billing/liquidrentalinvoiceedit/' + row.original.billingId,
      serverExpression: 'LiquidCustomer.CustomerName',
    },
/*     {
      Header: 'Invoice Id',
      accessor: 'billingId'
    }, */
    {
      Header: 'Date Invoiced',
      accessor: 'dateInvoiced',
      defaultSortDirection: 'desc',
      type: ListViewColumnType.date,
      excludeGlobalFilter: true,
    },
    {
      Header: 'Date Processed',
      accessor: 'dateJdeprocessed',
      type: ListViewColumnType.date,
      excludeGlobalFilter: true,
    },
    {
      Header: 'CST',
      accessor: 'liquidCustomerID'
    },
    {
      Header: 'Year',
      accessor: 'invoiceYear'
    },
    {
      Header: 'Month',
      id: 'invoiceMonth',
      serverExpression: 'invoiceMonth',
      accessor: ({invoiceMonth}) => months[invoiceMonth]
    },
    {
      Header: '# Liquids',
      accessor: 'count',
      serverExpression: 'LiquidRentalBillingDetails.Count()',
    },
    {
      Header: 'Dollars ($)',
      accessor: 'itemValue',
      Cell: ({ row: { original: { itemValue } } }) => formatCurrency(itemValue),
      serverExpression: 'LiquidRentalBillingDetails.Sum(Value)',
    },
    {
      Header: 'JDE Batch Number',
      accessor: 'jdebatchNumber',
    },
    {
      Header: 'JDE Errors',
      accessor: 'uploadIssues',
      hideOverflow: true,
      maxWidth: 200,
    },
/*     {
      Header: 'Order Number',
      accessor: 'orderId',
      hidden: true
    }, */
    {
      Header: 'Billing Id',
      accessor: 'billingId',
      hidden: true
    },
    {
      Header: 'priority',
      accessor: 'priority',
      hidden: true,
      defaultSort: true,
      excludeGlobalFilter: true,
      defaultSortDirection: 'desc',
      serverExpression: `iif(holdDate > ${'"' + formatDate(new Date()) + '"'}, 2, iif(Status == 0 && Jdebypassed == false, 4, iif(Status == 9 && Jdebypassed == false, 3, 1)))`
    },
    {
      Header: 'status',
      accessor: 'status',
      hidden: true,
      excludeGlobalFilter: true,
    },
    {
      Header: 'customerId',
      accessor: 'customerId',
      hidden: true,
    },
    {
      Header: 'holdDate',
      accessor: 'holdDate',
      hidden: true,
    },
    {
      Header: 'hasErrors',
      accessor: 'hasErrors',
      hidden: true,
      excludeGlobalFilter: true,
    },
    {
      Header: 'unbilled',
      accessor: 'unbilled',
      hidden: true,
      excludeGlobalFilter: true,
    },
  ]
} as ListView;
export const BillingLiquidRentalListView = createConnectedListView(liquidRentalBillingList, liquidRentalBillingDataSource);