import moment from "moment";
import { validateIntervalOverlaps } from "../../../services/form-helpers";
import { FormItemType } from "../../UI/view/component-map";

import { ListViewColumn, ListViewColumnType } from "../../UI/view/list-view";

export const liquidCustomerPricingEditColumns = [
  {
    type: ListViewColumnType.delete,
    accessor: 'delete',
  },
  {
    accessor: 'pricingType',
    Header: 'Pricing Type',
    fixedWidth: 200,
    formItemProps: {
      name: 'pricingType',
      defaultValue: 'RentalFeePerDay',
      minWidth: 100,
      required: true,
      type: FormItemType.select,
      onChange: ({ getValues, editTableProps, evt, setValue }) => {
        const val = evt.target.value;
        if (val == 'CooldownTier1') {
          setValue(`liquidCustomerPricing[${editTableProps.rowIdentity}][metaInformation.threshold]`, 20);
          setValue(`liquidCustomerPricing[${editTableProps.rowIdentity}][metaInformation.maximum]`, 39);
        } else if (val == 'CooldownTier2') {
          setValue(`liquidCustomerPricing[${editTableProps.rowIdentity}][metaInformation.threshold]`, 39);
          setValue(`liquidCustomerPricing[${editTableProps.rowIdentity}][metaInformation.maximum]`, 79);
        } else if (val == 'CooldownTier3') {
          setValue(`liquidCustomerPricing[${editTableProps.rowIdentity}][metaInformation.threshold]`, 79);
          setValue(`liquidCustomerPricing[${editTableProps.rowIdentity}][metaInformation.maximum]`, null);
        }
      },
      options: [
        { text: '$Rent/Day', id: 'RentalFeePerDay' },
        { text: '$Rent/Month', id: 'RentalFeePerMonth' },
        { text: 'Helium Price', id: 'LiquidHeliumPrice' },
        { text: 'N2 Price', id: 'NitrogenPrice' },
        { text: 'Flat N2 Price', id: 'FlatNitrogenPrice' },
        { text: 'Inland Freight', id: 'TransportationCharge' },
        { text: 'Depress Charge', id: 'DepressurizationCharge' },
        { text: 'Analytics Charge', id: 'AnalyticalCharge' },
        { text: 'Service Charge', id: 'ServiceCharge' },
        { text: 'Cooldown Tier1', id: 'CooldownTier1' },
        { text: 'Cooldown Tier2', id: 'CooldownTier2' },
        { text: 'Cooldown Tier3', id: 'CooldownTier3' },
        { text: 'Cooldown Charge', id: 'CooldownCharge' },
        { text: 'Residual Price', id: 'ResidualPrice' },
        { text: 'Residual Price High', id: 'ResidualPriceHigh' },
        { text: 'Residual Price Low', id: 'ResidualPriceLow' },
        { text: 'Export Insp.', id: 'ExportInspection' },
        { text: 'Free Rent Days', id: 'FreeRentDays' },
        { text: 'Chassis Rental Fee Per Day', id: 'ChassisRentalFeePerDay' },
        { text: 'Chassis Rental Fee Per Month', id: 'ChassisRentalFeePerMonth' },
        { text: 'Export Fee Price', id: 'ExportFeePrice' },
        { text: 'Customs Fee Price', id: 'CustomsFeePrice' },
        { text: 'Forwarding Fee Price', id: 'ForwardingFeePrice' },
        { text: 'Tolling Service Charge', id: 'tollingServiceCharge' },
        { text: 'Ocean Freight', id: 'oceanFreight' },
      ]
    }
  },
  {
    accessor: 'value',
    Header: 'Value',
    formItemProps: {
      name: 'value',
      number: true,
      required: true,
      defaultValue: null,
      minWidth: 100
    }
  },
  {
    accessor: 'effectiveDate',
    Header: 'Effective Date',
    formItemProps: {
      fitToContent: true,
      name: 'effectiveDate',
      minWidth: 100,
      type: FormItemType.date,
      required: true,
      defaultValue: new Date(),
      validation: {
        overlap: {
          message: 'pricing record date ranges must not overlap',
          handler: (val: string, getValues, existing, row) => {
            const formValues = getValues();
            const { liquidCustomerPricing } = formValues;
            if (!liquidCustomerPricing[row]) return true;
            const { effectiveDate, expirationDate, pricingType } = liquidCustomerPricing[row];
            const overlap = validateIntervalOverlaps(
              liquidCustomerPricing
                .filter((p, idx) => idx != row)
                .filter((p, idx) => p.pricingType == pricingType)
                .map(p => ({ START: p.effectiveDate, END: p.expirationDate })),
              effectiveDate,
              expirationDate)
            return !overlap;
          }
        },
        indirection: {
          message: 'effective date must occur before expiration date',
          handler: (val: string, getValues, existing, row) => {
            const formValues = getValues();
            const { liquidCustomerPricing } = formValues;
            if (!liquidCustomerPricing[row]) return true;
            const { effectiveDate, expirationDate } = liquidCustomerPricing[row];
            if (effectiveDate && expirationDate)
              return moment(expirationDate).isAfter(effectiveDate)
            return true;
          }
        }
      }
    }
  },
  {
    accessor: 'expirationDate',
    Header: 'Expiration Date',
    formItemProps: {
      fitToContent: true,
      name: 'expirationDate',
      type: FormItemType.date,
      minWidth: 100,
      required: true,
      defaultValue: moment().add(1, 'y').toDate(),
      validation: {
        overlap: {
          message: 'pricing record date ranges must not overlap',
          handler: (val: string, getValues, existing, row) => {
            const formValues = getValues();
            const { liquidCustomerPricing } = formValues;
            if (!liquidCustomerPricing[row]) return true;
            const { effectiveDate, expirationDate, pricingType } = liquidCustomerPricing[row];
            const overlap = validateIntervalOverlaps(
              liquidCustomerPricing.filter((p, idx) => idx != row)
                .filter(p => p.pricingType == pricingType)
                .map(p => ({ START: p.effectiveDate, END: p.expirationDate })),
              effectiveDate,
              expirationDate)
            return !overlap;
          }
        },
        indirection: {
          message: 'effective date must occur before expiration date',
          handler: (val: string, getValues, existing, row) => {
            const formValues = getValues();
            const { liquidCustomerPricing } = formValues;
            if (!liquidCustomerPricing[row]) return true;
            const { effectiveDate, expirationDate } = liquidCustomerPricing[row];
            if (effectiveDate && expirationDate)
              return moment(expirationDate).isAfter(effectiveDate)
            return true;
          }
        }
      }
    }
  },
  {
    accessor: 'metaInformation.threshold',
    Header: 'Threshold',
    fixedWidth: 100,
    formItemProps: {
      name: 'metaInformation.threshold',
      number: true,
      required: true,
      defaultValue: null,
      title: 'Threshold',
      hideIf: ({ editTableProps }) => !['ResidualPriceHigh', 'ResidualPriceLow', 'CooldownTier1', 'CooldownTier2', 'CooldownTier3'].includes(editTableProps.rowEntity.pricingType),
      onChange: (val: string, getValues, setValue, editTableProps) => {
        const formValues = getValues();
        const { liquidCustomerPricing } = formValues;
        if (!liquidCustomerPricing[editTableProps.rowIdentity]) return true;
        const { effectiveDate, expirationDate, pricingType, metaInformation } = liquidCustomerPricing[editTableProps.rowIdentity];
        const {partitions, partition} = editTableProps;
        if (pricingType == 'CooldownTier2') {
          const tier1 = liquidCustomerPricing?.find(p => p.pricingType == 'CooldownTier1' && partition.filter(p));
          if(tier1){
            const idxOfTier1 = liquidCustomerPricing.indexOf(tier1);
            setValue(`liquidCustomerPricing[${idxOfTier1}][metaInformation.maximum]`, val);
          }

        } else if (pricingType == 'CooldownTier3') {
          const tier2 = liquidCustomerPricing?.find(p => p.pricingType == 'CooldownTier2' && partition.filter(p));
          if(tier2){
            const idxOfTier2 = liquidCustomerPricing.indexOf(tier2);
            setValue(`liquidCustomerPricing[${idxOfTier2}][metaInformation.maximum]`, val);
          }
        }
      }
    }
  },
  {
    accessor: 'metaInformation.maximum',
    Header: 'Max',
    fixedWidth: 100,
    minWidth: 100,
    formItemProps: {
      name: 'metaInformation.maximum',
      number: true,
      required: true,
      /*       defaultValue: 80, */
      title: 'Maximum',
      hideIf: ({ editTableProps }) => !['CooldownTier1', 'CooldownTier2'].includes(editTableProps.rowEntity.pricingType),
      onChange: (val: string, getValues, setValue, editTableProps) => {

        const formValues = getValues();
        const { liquidCustomerPricing } = formValues;
        const {partitions, partition} = editTableProps;
        if (!liquidCustomerPricing[editTableProps.rowIdentity]) return true;
        const { effectiveDate, expirationDate, pricingType, threshold, maximum } = liquidCustomerPricing[editTableProps.rowIdentity];
        if (pricingType == 'CooldownTier1') {
          const tier2 = liquidCustomerPricing?.find(p => p.pricingType == 'CooldownTier2' && partition.filter(p));
          if(tier2){
            const idxOfTier2 = liquidCustomerPricing.indexOf(tier2);
            setValue(`liquidCustomerPricing[${idxOfTier2}][metaInformation.threshold]`, val);
          }
        } else if (pricingType == 'CooldownTier2') {
          const tier3 = liquidCustomerPricing?.find(p => p.pricingType == 'CooldownTier3' && partition.filter(p));       
          if(tier3){
            const idxOfTier3 = liquidCustomerPricing.indexOf(tier3);
            setValue(`liquidCustomerPricing[${idxOfTier3}][metaInformation.threshold]`, val);
          }  
        }
      }
    }
  },
  {
    accessor: 'metaInformation.liquidCustomerPricingId',
    hidden: true,
    formItemProps: {
      name: 'metaInformation.liquidCustomerPricingId',
    }
  },

  //hidden
  /*    {
      accessor: 'metaInformation.cooldownTier3Temp',
      Header: '',
      formItemProps: {
        name: 'metaInformation.cooldownTier3Temp',
        number: true,
        required: true,
        defaultValue: null,
        title: 'Temp Range 3',
        hideIf: ({editTableProps, watch}) => editTableProps.rowEntity.pricingType != 'CooldownTier3' && editTableProps.rowEntity.pricingType != 'CooldownTier2'
      }
    }, */
  {
    accessor: 'id',
    hidden: true,
    formItemProps: {
      name: 'id',
    }
  },
  {
    accessor: 'itemType',
    hidden: true,
    formItemProps: {
      name: 'itemType',
      defaultValue: 'LiquidCustomerPricing'
    }
  },
] as ListViewColumn[];