import React, { useCallback, useEffect, useMemo } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import {
  fetchGasContainer, selectCurrentGasContainer, selectGasContainers,
  setCreateAttachmentStatus, setCurrentGasContainerId, setGasContainerUpdateStatus,
  setRemoveAttachmentStatus,
} from '../../../store/slices/gas-containers-slice';
import { fetchAssetMaintenanceRequests, setFetchMaintenanceRequestStatus } from '../../../store/slices/maintenance-requests-slice';
import { setFetchWorkOrderStatus } from '../../../store/slices/work-orders-slice';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import AssetDetailsTabs from '../../UI/organisms/AssetDetailsTabs/asset-details-tabs';
import AssetFileAttachment from '../../UI/organisms/FileAttachment/asset-file-attachment';
import EntityAuditLogs from '../AuditLog/entity-audit-logs';
import AssetMaintenanceRequests from '../MaintenanceDashboard/asset-maintenance-requests';
import AssetWorkOrders from '../WorkOrders/asset-work-orders';
import GasContainerForm from './gas-container-form';
import GasContainerInfo from './gas-container-info';
import { GasHistoryListViewComponent } from './gas-history-list';
import { SensorInfo } from './gas-sensor-info-tab';

function GasContainerDetails(props) {
  const { gasContainerId } = useParams();

  const gasContainersFetchSingleStatus = useSelector(
    (state) => state.gasContainer.gasContainersFetchSingle.status,
  );

  const gasContainerUpdateStatus = useSelector(
    (state) => state.gasContainer.gasContainerUpdate.status,
  );

  const gasContainerAttachmentCreateStatus = useSelector(
    (state) => state.gasContainer.gasContainerAttachmentCreate.status,
  );

  const gasContainerAttachmentRemoveStatus = useSelector(
    (state) => state.gasContainer.gasContainerAttachmentRemove.status,
  );

  const gasContainerUpdateNotify = () => toast.success('Successfully Updated gas container');
  const gasContainerAttachmentCreateNotify = () => toast.success('Successfully Uploaded attachment');
  const gasContainerAttachmentRemoveNotify = () => toast.success('Successfully Deleted attachment');

  const currentGasContainer = useSelector(selectCurrentGasContainer);
  const gasContainers = useSelector(selectGasContainers);

  const breadcrumbs = useMemo(() => [
    {
      name: 'Assets',
      icon: 'boxes',
      to: '/assets',

    },
    {
      name: gasContainerId,
      icon: 'box',
      to: '',
    },
  ], [gasContainerId]);

  const dispatch = useDispatch();

  const loadData = useCallback(async () => {
    if (gasContainersFetchSingleStatus === 'idle' || gasContainersFetchSingleStatus === 'failed') {
      dispatch(fetchGasContainer(gasContainerId));
    }

    dispatch(setCurrentGasContainerId(gasContainerId));
  }, [gasContainersFetchSingleStatus, gasContainerId, dispatch, currentGasContainer]);

  const loadMaintenanceRequestData = useCallback(async () => {
    await dispatch(fetchAssetMaintenanceRequests({
      assetType: 1,
      assetSerialNumber: gasContainerId,
    })).unwrap();
    dispatch(setFetchMaintenanceRequestStatus('idle'));
  }, [gasContainerId]);

  useEffect(() => {
    if (gasContainerUpdateStatus === 'succeeded') {
      gasContainerUpdateNotify();
      dispatch(setGasContainerUpdateStatus('idle'));
      loadMaintenanceRequestData();
    }
    if (gasContainerAttachmentCreateStatus === 'succeeded') {
      gasContainerAttachmentCreateNotify();
      dispatch(setCreateAttachmentStatus('idle'));
    }
    if (gasContainerAttachmentRemoveStatus === 'succeeded') {
      gasContainerAttachmentRemoveNotify();
      dispatch(setRemoveAttachmentStatus('idle'));
    }
  }, [gasContainerUpdateStatus,
    gasContainerAttachmentCreateStatus,
    gasContainerAttachmentRemoveStatus,
    loadMaintenanceRequestData]);

  useEffect(() => {
    loadData();
  }, [currentGasContainer]);

  useEffect(() => {
    dispatch(setFetchMaintenanceRequestStatus('idle'));
    dispatch(setFetchWorkOrderStatus('idle'));
    dispatch(fetchGasContainer(gasContainerId));
  }, []);

  const list = useMemo(
    () => currentGasContainer?.attachmentList ?? [],
    [currentGasContainer],
  );

  const gasContainerStatusIsLoading = [
    gasContainersFetchSingleStatus,
    gasContainerUpdateStatus,
  ].includes('loading');

  return (
    <Container
      fluid
      className="h-100 d-flex flex-column"
    >
      <PageHeader
        header="Gas"
        breadcrumbs={breadcrumbs}
      />
      <Row className="h-100 flex-grow-1 overflow-auto">
        <Col lg={3} className="h-100 fluid flex-column overflow-auto">
          <GasContainerInfo
            currentGasContainer={currentGasContainer}
            gasContainerStatusIsLoading={gasContainerStatusIsLoading}
          />
          <AssetFileAttachment moduleId={gasContainerId} moduleName="GasContainers" attachmentList={list} />
        </Col>
        <Col className="h-100 d-flex fluid">
          <AssetDetailsTabs
            generalInfoContents={(
              <GasContainerForm
                currentGasContainer={currentGasContainer}
                gasContainerStatusIsLoading={gasContainerStatusIsLoading}
              />
            )}
            workOrderContent={(
              <AssetWorkOrders
                assetType="Gas Container"
                assetTypeId={1}
                assetSerialNumber={gasContainerId}
              />
            )}
            maintenanceRequestContent={(
              <AssetMaintenanceRequests
                assetTypeId={1}
                assetSerialNumber={gasContainerId}
                asset={currentGasContainer}
              />
            )}
            auditLogContent={(
              <EntityAuditLogs
                entityId={1}
                tablePK={gasContainerId}
              />
            )}
            sensorContent={(
              <SensorInfo />
            )}
            historyContent={<GasHistoryListViewComponent />}
          />
        </Col>
      </Row>
    </Container>
  );
}

export default GasContainerDetails;
