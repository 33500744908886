/* eslint-disable spaced-comment */
/* eslint-disable no-return-assign */
import ArcGISMap from '@arcgis/core/Map';
import MapView from '@arcgis/core/views/MapView';
import Home from '@arcgis/core/widgets/Home';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import { Repository } from '../../view/data-source';
import { Col, Row } from 'react-bootstrap';
import { WorlMapListComponentStore, assetRoute } from '../../../pages/WorldMap/worldmap-list';
import { ColorLegend } from './color-legend';
import { getContainerSummaryData, getGasAndChassisReadings } from '../../../../services/requests';


export interface WorldMapFilter {
  type: string,
  color: string,
  display: string,
  legendColor: string
}
export interface WorldMapProps {
  filters: WorldMapFilter[],
  listComponent: (props) => JSX.Element,
  storeComponent: Repository,
  detailsLink?: (breadcrumb: string) => string,
  filterField?: string
}

export function WorldMap(props: WorldMapProps) {
  const { filters, listComponent: ListComponent, storeComponent, detailsLink, filterField } = props;
  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);
  const [mapObject, setMapObject] = useState({ map: null, view: null });
  const { fetchData: fetchWorldCoordinates, dataSource: repo } = storeComponent;
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchWorldCoordinates());
  }, []);
  const data = useSelector((state: any) => state[repo.name].data);
  const status = useSelector((state: any) => state[repo.name].status);
  const loaded = status === 'succeeded';

  const [filteredData, setFilteredData] = useState(data);
  useEffect(() => {
    setFilteredData(data);
  }, [data])

  const mapDiv = useRef(null);

  const display = (type) => {
    const f = filters?.find(f => f.type == type)
    return f?.display ?? type;
  }
  const pointColor = (type) => {
    const f = filters?.find(f => f.type == type)
    return f?.color ?? 'red';
  }

  const queryPopupInfo = async (breadcrumb) => {
    const assetType = breadcrumb.assetType;
    const summaryData = await getContainerSummaryData(assetType, breadcrumb.containerId);
    var root = location.protocol + '//' + location.host;
    const linkUrl = detailsLink? `${root}${detailsLink(breadcrumb)}` : `${root}/assets/${assetRoute(assetType)}/${breadcrumb.containerId}#sensors`;
    const onClick = () => {
      console.log(breadcrumb);
    }
    const rows = summaryData.map(rec =>
    (
      `
       <tr>
         <td>${rec.readingType}</td>
         <td>${rec.readingValue}</td>
       </tr>
       `
    )).join('');
    const output = `
      <table>
        <tbody>
          ${rows}
        </tbody>
      </table>
      <a style="color: rgb(0, 85, 145) !important" href="${linkUrl}" >Details</a>
    `;
    return output;
  }

  const renderGeometry = () => {
    return [
      // eslint-disable-next-line indent
      ...filteredData.map((breadcrumb) => ({
        geometry: {
          longitude: Number(breadcrumb.longitude),
          latitude: Number(breadcrumb.latitude),
          type: 'point',
        },
        attributes: {
          containerId: breadcrumb.containerId,
          assetType: breadcrumb.assetType
        },
        symbol: {
          type: 'simple-marker',
          color: pointColor(filterField ? breadcrumb[filterField] : breadcrumb.assetType),
          outline:
          {
            color: [255, 255, 255],
            width: 2,
          },
        },
        popupTemplate: {
          title: `${breadcrumb.readingTime != null ? (formatDate(breadcrumb.readingTime, datePreference)) : ''} ${breadcrumb.containerId} [${display(filterField ? breadcrumb[filterField] : breadcrumb.assetType)}]`,
          content: async () => await queryPopupInfo(breadcrumb),
        },
      })),

    ];
  }
  useEffect(() => {
    if (mapDiv.current && loaded) {
      let map = new ArcGISMap({
        basemap: 'gray-vector',
      });

      let view = new MapView({
        map,
        container: mapDiv.current,
        zoom: 2,
        center: [6, 36],
        graphics: renderGeometry()
      });


      setMapObject({ map, view });

    }
  }, [JSON.stringify(filteredData)]);

  const onFilterChanged = (newData) => {
    setFilteredData(newData.map(d => d.original));
  }
  return (
    <>
      <div className="container-fluid">
        <Row>
          <Col>
            <div style={{ width: '100%', height: '50vh' }} className="mapDiv" ref={mapDiv} />
          </Col>
        </Row>
        <Row>
          <Col>
            <ListComponent onRowSelect={(selection) => {
              if (selection?.length) {
                const p = selection[0];
                mapObject.view?.goTo({ center: [p.original.longitude, p.original.latitude], zoom: 3 }, { animate: true });
                var graphic = mapObject.view.graphics?._items.find(g => g.attributes.containerId == p.original.containerId);
                // Get the popup template for the point
                var popupTemplate = graphic?.popupTemplate;
                if (popupTemplate) {
                  // Open the popup for the point
                  mapObject.view?.popup.open({
                    location: graphic.geometry,
                    title: popupTemplate.title,
                    content: popupTemplate.content,
                    features: [graphic],
                    updateLocationEnabled: true
                  });
                }

              }
            }} onFilterChanged={onFilterChanged} toolbarWidget={!(filters?.length > 1) ? undefined : <ColorLegend filters={filters} />} />
          </Col>
        </Row>
      </div>
    </>
  );



}
