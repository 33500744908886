import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { fetchEntityAuditLogs } from '../../../store/slices/audit-slice';
import EntityAuditLogsTable from './entity-audit-logs-table';

function EntityAuditLogs(props) {
  const auditLogsFetchStatus = useSelector(
    (state) => state.audit.entityLogsFetch.status,
  );

  const dispatch = useDispatch();

  // Should update name of props and make less specifict to assetTypes
  let tableName = null;
  const tableSchema = props.tableSchema || null;
  const tablePK = props.tablePK || null;
  if (props.entityId === 1) {
    tableName = 'GasContainers';
  } else if (props.entityId === 2) {
    tableName = 'LiquidContainers';
  } else if (props.entityId === 3) {
    tableName = 'DewarContainers';
  } else if (props.entityId === 4) {
    tableName = 'LiquidChassis';
  } else if (props.entityId === 5) {
    tableName = 'WorkOrders';
  } else if (props.entityId === 6) {
    tableName = 'MaintenanceRequests';
  } else if (props.entityId === 8) {
    tableName = 'PurchaseRequests';
  } else if (props.entityId === 9) {
    tableName = 'PurchaseOrders';
  } else if (props.entityId === 10) {
    tableName = 'Parts';
  } else if (props.entityId === 'DewarCustomers') {
    tableName = 'DewarCustomers';
  } else if (props.entityId === 'LiquidCustomers') {
    tableName = 'LiquidCustomers';
  } else if (props.entityId === 'GasCustomers') {
    tableName = 'GasCustomers';
  } else if (props.entityId === 'LiquidCustomerPricing') {
    tableName = 'LiquidCustomerPricing';
  } else if (props.entityId === 'MaintenanceRequestLineItems') {
    tableName = 'MaintenanceRequestLineItems';
  } else if (props.entityId === 'Locations') {
    tableName = 'Locations';
  } else if (props.entityId === 'LocationLiquidPurityRequirements') {
    tableName = 'LocationLiquidPurityRequirements';
  } else if (props.entityId === 'LocationGasPurityRequirements') {
    tableName = 'LocationGasPurityRequirements';
  } else if (props.entityId === 'GasReceivers') {
    tableName = 'GasReceivers';
  } else if (props.entityId === 'LocationAnalyzers') {
    tableName = 'LocationAnalyzers';
  } else if (props.entityId === 'NitrogenTanks') {
    tableName = 'NitrogenTanks';
  } else if (props.entityId === 'FillSlots') {
    tableName = 'FillSlots';
  } else if (props.entityId === 'RTU') {
    tableName = 'RTU';
  } else if (typeof props.entityId === 'string') {
    tableName = props.entityId;
  }

  useEffect(() => {
    dispatch(fetchEntityAuditLogs({ tableName, tablePK, tableSchema }));
  }, [tableName, tablePK, tableSchema]);

  const auditLogsAreLoading = auditLogsFetchStatus === 'loading';

  return (
    <div className="h-100 d-flex">
      <Spinner animation="border" variant="primary" className={`create-spinner${auditLogsAreLoading ? ' visible' : ' invisible'}`} />
      <EntityAuditLogsTable />
    </div>
  );
}

export default EntityAuditLogs;
