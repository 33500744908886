import { sum } from "../../../../services/array-helpers";
import { formatCurrency, formatDate } from "../../../../services/format";
import { FormItemType } from "../../../UI/view/component-map";
import { FormView } from "../../../UI/view/form-view";
import { ListView, ListViewColumnType, createConnectedListView, createListView } from "../../../UI/view/list-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { DewarDamagesItemList } from "../billing-dewar-damages";
import { dewarrentalordersDS } from "./dewarrental-unbilled-list";


export const dewarRentalsCreate = {
  editMode: true,
  pageHeader: 'Dewar Rentals Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Dewar Rentals Billing',
      icon: 'currency-dollar',
      to: '/billing/dewar',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          type: FormItemType.infoPane,
          header: ({ dewarrentalbill }) => dewarrentalbill?.current?.data?.customerName,
          infoListItems: [

            {
              label: 'Customer',
              name: 'customerName'
            },
            {
              label: 'CST',
              name: 'dewarCustomerId'
            },
            {
              label: 'CBT',
              name: 'cbtName'
            },
            {
              label: 'Billing Period',
              name: 'billingPeriod',
              value: ({ invoiceMonth, invoiceYear }) => `${invoiceMonth}/${invoiceYear}`
            },
            {
              label: 'Free Rent Days Per Trip',
              name: 'freeDays'
            }

          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Dewar Rentals',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Rental Billing',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          name: 'ponumber',
                          label: 'PO Number',
                          minWidth: 200,
                          fitToContent: true,
                          defaultValue: ''
                        },
                      ]
                    },
                    {
                      name: 'invoiceNotes',
                      label: 'Invoice Notes',
                      type: FormItemType.textarea,
                      defaultValue: ''
                    },
                    {
                      type: FormItemType.customField,
                      getComponent: () => DewarBillingList,
                      name: 'dewarRentalBillingDetails',
                      label: 'Unbilled Containers in Billing Period'
                    },
                    {
                      name: 'billingType',
                      defaultValue: 'DEWARRENTAL',
                      hideIf: () => true
                    },
                    {
                      name: 'productType',
                      defaultValue: 'Dewar',
                      hideIf: () => true
                    },
                    {
                      name: 'customerId',
                      hideIf: () => true
                    },
                    {
                      name: 'dewarCustomerId',
                      computedValue: ( getValues ) => getValues('customerId'),
                      hideIf: () => true
                    },
                    {
                      name: 'roNumber',
                      defaultValue: '',
                      hideIf: () => true
                    },
                    {
                      name: 'invoiceYear',
                      computedValue: (getValues) => getValues('billingYear'),
                      hideIf: () => true
                    },
                    {
                      name: 'invoiceMonth',
                      computedValue: (getValues) => getValues('billingMonth'),
                      hideIf: () => true
                    },
                    {
                      name: 'freeDays',
                      computedValue: (getValues) => getValues('freeRentDays'),
                      hideIf: () => true
                    },
                    {
                      name: 'bypassInvoice',
                      defaultValue: false,
                      hideIf: () => true
                    },
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.button,
                  label: 'Bypass Invoice',
                  variant: 'secondary',
                  handler: async ({ methods, handleSubmit, onSubmit, redirect, confirm }) => {
                    const { setValue } = methods;
                    setValue('bypassInvoice', true);
                    let isError = false;
                    let result = null;
                    try {
                      result = await handleSubmit(onSubmit)();
                    } catch {
                      isError = true;
                    }
                    if (isError) {
                      await confirm('There was an error bypassing this invoice.', true);
                    } else {
                      //redirect to created invoice. We need the invoice id!\
                      await confirm('Invoice Bypassed.', true);
                      redirect('/billing/dewar');
                    }
                  }
                },
                {
                  type: FormItemType.submitButton,
                  label: 'Create Invoice',
                  variant: 'secondary',
                  onAfterSubmit: ({ redirect }) => {
                    alert('Invoice Successfully Created!')
                    redirect('/billing/dewar');
                  }
                }
              ]
            }
          ]
        }
      ]
    }
  ]
} as FormView;

export const [DewarRentalInvoice, DewarRentalInvoiceRepo] = createConnectedView2({ form: dewarRentalsCreate, dataSource: dewarrentalordersDS });



export const DewarBillingList = createListView({
  hideGlobalFilter: true,
  autoCheckAll: true,
  preventFetch: true,
  columns: [
    {
      accessor: 'checked',
      type: ListViewColumnType.checkbox,

    },
    {
      Header: 'Dewar S/N',
      accessor: 'serialNumber',
      defaultSort: true
    },
    {
      Header: 'Product',
      accessor: 'productNumber'
    },

    {
      Header: 'Order #',
      accessor: 'orderId'
    },
    {
      Header: 'Rent Start Date',
      type: ListViewColumnType.date,
      accessor: 'startDate'
    },
    {
      Header: 'Rent Stop Date',
      type: ListViewColumnType.date,
      accessor: 'endDate'
    },
    {
      Header: 'Days Billable',
      accessor: 'daysBilled'
    },
    {
      Header: '$/Day',
      accessor: 'rentalFeePerDay'
    },
    {
      Header: 'Total $',
      accessor: 'value'
    },

  ]

} as ListView, DewarRentalInvoiceRepo as any, state => state.dewarrentalbill.current?.data?.billingDetails)