
import { getUnlinkedCreditInvoices } from "../../../../services/requests/billing/liquid-billing-requests";
import { DataSource } from "../../../UI/view/data-source";
import { ListView, ListViewColumnType, createConnectedListView } from "../../../UI/view/list-view";

export const unlinkedCreditInvoicesDataSource = {
  request: getUnlinkedCreditInvoices,
  name: 'unlinkedCreditInvoices',
  pk: 'billingId',
} as DataSource;

export const unlinkedCreditInvoices = {
  columns: [
    {
      accessor: 'billingId',
      Header: 'Billing Id',
      defaultSort: true
    },
    {
      accessor: 'jdeVolume',
      Header: 'JDE Volume',
    },
    {
      accessor: 'dateInvoiced',
      Header: 'Date Invoiced',
      type: ListViewColumnType.date
    },
    {
      accessor: 'customerName',
      Header: 'Customer Name',
    },
  ]
} as ListView;

export const UnlinkedCreditInvoices = createConnectedListView(unlinkedCreditInvoices, unlinkedCreditInvoicesDataSource)