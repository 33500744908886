import './custom-spinner.scss';

import React from 'react';
import { Spinner } from 'react-bootstrap';
// import EmptyTabPlaceHolder from "../../atoms/EmptyTabPlaceHolder/empty-tab-placeholder";

function LoadingContent(props) {
  return (
  // <EmptyTabPlaceHolder>
    <Spinner animation="border" variant="primary" className="custom-spinner" />
  // </EmptyTabPlaceHolder>
  );
}

export default LoadingContent;
