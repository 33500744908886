import './date-input.scss';

import React from 'react';
import {
  Col, Form,
  Row,
} from 'react-bootstrap';

import StyledDatePicker from './styled-date-picker';

function RangePicker(props) {
  const { onRangeChanged, defaultValue } = props;
  const [dropdownValue, setDropdownValue] = React.useState(3);

  const getDay = (months) => {
    const d = new Date();
    d.setMonth(d.getMonth() - months);
    return d;
  };
  const [startDate, setStartDate] = React.useState(defaultValue ? defaultValue.start : getDay(3));
  const [endDate, setEndDate] = React.useState(defaultValue ? defaultValue.end : getDay(0));

  const getStartEndDate = (days) => {
    if (days >= 1) {
      return {
        start: getDay(days),
        end: getDay(0),
      };
    }
    return null;
  };

  const setDateRange = (days) => {
    if (days >= 1) {
      const { start, end } = getStartEndDate(days);
      setStartDate(start);
      setEndDate(end);
    }
  };

  return (
    <Row>
      <Col xs="auto">
        <StyledDatePicker
          onChange={(e) => { setStartDate(e); setDropdownValue(''); onRangeChanged({ start: e, end: endDate }); }}
          value={startDate}
          maxDate={new Date()}
        />
      </Col>
      <Form.Label xs="auto" column className="text-muted">To</Form.Label>
      <Col xs="auto">
        <StyledDatePicker
          onChange={(e) => { setEndDate(e); setDropdownValue(''); onRangeChanged({ start: startDate, end: e }); }}
          value={endDate}
          maxDate={new Date()}
        />
      </Col>
      <Col xs="auto">
        <Form.Select value={dropdownValue} size="sm" onChange={(e) => { setDropdownValue(e.target.value); setDateRange(e.target.value); onRangeChanged(getStartEndDate(e.target.value)); }}>
          <option value="" key="customer-null">-- Days --</option>
          <option value={1}>Last 30 Days</option>
          <option value={3}>Last 3 Months</option>
          <option value={6}>Last 6 Months</option>
          <option value={12}>Last 12 Months</option>
        </Form.Select>
      </Col>
    </Row>
  );
}

export default RangePicker;
