/* eslint-disable max-len */
import { createAsyncThunk, createSelector, createSlice } from '@reduxjs/toolkit';

import {
  assignPurchaseRequestToPurchaseOrder,
  createBulkPurchaseRequests, deletePurchaseRequestRequest, getPurchaseRequests,
  getPurchaseRequestsByPart, getPurchaseRequestsByPurchaseOrder,
  postPurchaseRequest, putPurchaseRequest, putPurchaseRequestStatus,
} from '../../services/requests';
import { selectCurrentPurchaseOrder } from './purchase-orders-slice';

const initialState = {
  purchaseRequests: {},
  purchaseRequestsByPart: {},
  purchaseRequestsByPurchaseOrder: {},
  currentPurchaseRequest: {
    purchaseRequestId: null,
  },
  purchaseRequestsFetch: {
    status: 'idle',
    error: null,
  },
  purchaseRequestsByPartFetch: {
    status: 'idle',
    error: null,
  },
  purchaseRequestsByPurchaseOrderFetch: {
    status: 'idle',
    error: null,
  },
  purchaseRequestsCreate: {
    status: 'idle',
    error: null,
  },
  purchaseRequestsUpdate: {
    status: 'idle',
    error: null,
  },
  purchaseRequestsDelete: {
    status: 'idle',
    error: null,
  },
  purchaseRequestAssign: {
    status: 'idle',
    error: null,
  },
};

export const fetchPurchaseRequests = createAsyncThunk('purchaseRequests/fetchPurchaseRequests', async (partId) => {
  const response = await getPurchaseRequests();
  return response;
});

export const fetchPurchaseRequestsByPart = createAsyncThunk('purchaseRequests/fetchPurchaseRequestsByPart', async (partId) => {
  const response = await getPurchaseRequestsByPart(partId);
  return response;
});

export const fetchPurchaseRequestsByPurchaseOrder = createAsyncThunk('purchaseRequests/fetchPurchaseRequestsByPurchaseOrder', async (purchaseOrderId) => {
  const response = await getPurchaseRequestsByPurchaseOrder(purchaseOrderId);
  return response;
});

export const createPurchaseRequests = createAsyncThunk('purchaseRequests/createPurchaseRequests', async (purchaseRequests) => {
  const response = await createBulkPurchaseRequests(purchaseRequests);
  return response;
});

export const createPurchaseRequest = createAsyncThunk('purchaseRequests/createPurchaseRequest', async (purchaseRequest) => {
  const response = await postPurchaseRequest(purchaseRequest);
  return response;
});

export const updatePurchaseRequest = createAsyncThunk('purchaseRequests/updatePurchaseRequest', async (purchaseRequest) => {
  const response = await putPurchaseRequest(purchaseRequest);
  return response;
});

export const deletePurchaseRequest = createAsyncThunk('purchaseRequests/deletePurchaseRequest', async (purchaseRequestId) => {
  const response = await deletePurchaseRequestRequest(purchaseRequestId);
  return response;
});

export const updatePurchaseRequestStatus = createAsyncThunk('purchaseRequests/updatePurchaseRequestStatus', async ({ purchaseRequestId, purchaseRequestStatusId }) => {
  const response = await putPurchaseRequestStatus(purchaseRequestId, purchaseRequestStatusId);
  return response;
});

export const assignPurchaseRequest = createAsyncThunk('purchaseRequests/assignPurchaserequest', async (data) => {
  const response = await assignPurchaseRequestToPurchaseOrder(data);
  return response;
});

export const purchaseRequestsSlice = createSlice({
  name: 'purchaseRequest',
  initialState,
  reducers: {
    setFetchPurchaseRequestsStatus: (state, action) => {
      state.purchaseRequestsFetch.status = action.payload;
    },
    setCreatePurchaseRequestsStatus: (state, action) => {
      state.purchaseRequestsCreate.status = action.payload;
    },
    setUpdatePurchaseRequestStatus: (state, action) => {
      state.purchaseRequestsUpdate.status = action.payload;
    },
    setAssignPurchaseRequestStatus: (state, action) => {
      state.purchaseRequestAssign.status = action.payload;
    },
    setCurrentPurchaseRequestId: (state, action) => {
      state.currentPurchaseRequest.purchaseRequestId = action.payload;
    },
    setpurchaseRequestsByPurchaseOrderFetch: (state, action) => {
      state.purchaseRequestsByPurchaseOrderFetch.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(createPurchaseRequests.pending, (state, action) => {
        state.purchaseRequestsCreate.status = 'loading';
      })
      .addCase(createPurchaseRequests.fulfilled, (state, action) => {
        state.purchaseRequestsCreate.status = 'succeeded';
        state.purchaseRequests = action.payload;
        state.purchaseRequestsByPart = {
          ...state.purchaseRequestsByPart,
          ...action.payload,
        };
        state.purchaseRequestsCreate.error = null;
      })
      .addCase(createPurchaseRequests.rejected, (state, action) => {
        state.purchaseRequestsCreate.status = 'failed';
        state.purchaseRequestsCreate.error = action.error.message;
      });

    builder
      .addCase(assignPurchaseRequest.pending, (state, action) => {
        state.purchaseRequestAssign.status = 'loading';
      })
      .addCase(assignPurchaseRequest.fulfilled, (state, action) => {
        state.purchaseRequestAssign.status = 'succeeded';
        state.purchaseRequests = {
          ...state.purchaseRequests,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequestsByPart = {
          ...state.purchaseRequestsByPart,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequestsByPurchaseOrder = {
          ...state.purchaseRequestsByPurchaseOrder,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequestsCreate.error = null;
      })
      .addCase(assignPurchaseRequest.rejected, (state, action) => {
        state.purchaseRequestAssign.status = 'failed';
        state.purchaseRequestAssign.error = action.error.message;
      });

    builder
      .addCase(createPurchaseRequest.pending, (state, action) => {
        state.purchaseRequestsCreate.status = 'loading';
      })
      .addCase(createPurchaseRequest.fulfilled, (state, action) => {
        state.purchaseRequestsCreate.status = 'succeeded';
        state.purchaseRequestsByPart = {
          ...state.purchaseRequestsByPart,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequests = {
          ...state.purchaseRequests,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequestsCreate.error = null;
      })
      .addCase(createPurchaseRequest.rejected, (state, action) => {
        state.purchaseRequestsCreate.status = 'failed';
        state.purchaseRequestsCreate.error = action.error.message;
      });

    builder
      .addCase(updatePurchaseRequest.pending, (state, action) => {
        state.purchaseRequestsUpdate.status = 'loading';
      })
      .addCase(updatePurchaseRequest.fulfilled, (state, action) => {
        state.purchaseRequestsUpdate.status = 'succeeded';
        state.purchaseRequestsByPart = {
          ...state.purchaseRequestsByPart,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequestsByPurchaseOrder = {
          ...state.purchaseRequestsByPurchaseOrder,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequests = {
          ...state.purchaseRequests,
          [action.payload.purchaseRequestId]: action.payload,
        };
        state.purchaseRequestsUpdate.error = null;
      })
      .addCase(updatePurchaseRequest.rejected, (state, action) => {
        state.purchaseRequestsUpdate.status = 'failed';
        state.purchaseRequestsUpdate.error = action.error.message;
      });

    builder
      .addCase(fetchPurchaseRequestsByPart.pending, (state, action) => {
        state.purchaseRequestsByPartFetch.status = 'loading';
      })
      .addCase(fetchPurchaseRequestsByPart.fulfilled, (state, action) => {
        state.purchaseRequestsByPartFetch.status = 'succeeded';
        const purchaseRequestsReturned = action.payload.reduce((current, purchaseRequest) => (
          { ...current, [purchaseRequest.purchaseRequestId]: purchaseRequest }), {});
        state.purchaseRequestsByPart = {
          ...purchaseRequestsReturned,
        };
        state.purchaseRequests = {
          ...state.purchaseRequests,
          ...purchaseRequestsReturned,
        };
      })
      .addCase(fetchPurchaseRequestsByPart.rejected, (state, action) => {
        state.purchaseRequestsByPartFetch.status = 'failed';
        state.purchaseRequestsByPartFetch.error = action.error.message;
      });

    builder
      .addCase(fetchPurchaseRequestsByPurchaseOrder.pending, (state, action) => {
        state.purchaseRequestsByPurchaseOrderFetch.status = 'loading';
      })
      .addCase(fetchPurchaseRequestsByPurchaseOrder.fulfilled, (state, action) => {
        state.purchaseRequestsByPurchaseOrderFetch.status = 'succeeded';
        const purchaseRequestsReturned = action.payload.reduce((current, purchaseRequest) => (
          { ...current, [purchaseRequest.purchaseRequestId]: purchaseRequest }), {});
        state.purchaseRequestsByPurchaseOrder = {
          ...purchaseRequestsReturned,
        };
        state.purchaseRequests = {
          ...state.purchaseRequests,
          ...purchaseRequestsReturned,
        };
      })
      .addCase(fetchPurchaseRequestsByPurchaseOrder.rejected, (state, action) => {
        state.purchaseRequestsByPurchaseOrderFetch.status = 'failed';
        state.purchaseRequestsByPurchaseOrderFetch.error = action.error.message;
      });

    builder
      .addCase(fetchPurchaseRequests.pending, (state, action) => {
        state.purchaseRequestsFetch.status = 'loading';
      })
      .addCase(fetchPurchaseRequests.fulfilled, (state, action) => {
        state.purchaseRequestsFetch.status = 'succeeded';
        const purchaseRequestsReturned = action.payload.reduce((current, purchaseRequest) => (
          { ...current, [purchaseRequest.purchaseRequestId]: purchaseRequest }), {});
        state.purchaseRequests = {
          ...purchaseRequestsReturned,
        };
      })
      .addCase(fetchPurchaseRequests.rejected, (state, action) => {
        state.purchaseRequestsFetch.status = 'failed';
        state.purchaseRequestsFetch.error = action.error.message;
      });

    builder
      .addCase(deletePurchaseRequest.pending, (state, action) => {
        state.purchaseRequestsDelete.status = 'loading';
      })
      .addCase(deletePurchaseRequest.fulfilled, (state, action) => {
        state.purchaseRequestsDelete.status = 'succeeded';
        state.purchaseRequestsByPart[action.payload.purchaseRequestId] = action.payload;
        state.purchaseRequestsByPurchaseOrder[action.payload.purchaseRequestId] = action.payload;
        state.purchaseRequests[action.payload.purchaseRequestId] = action.payload;
        state.purchaseRequestsDelete.error = null;
      })
      .addCase(deletePurchaseRequest.rejected, (state, action) => {
        state.purchaseRequestsDelete.status = 'failed';
        state.purchaseRequestsByPartFetch.error = action.error.message;
      });

    builder
      .addCase(updatePurchaseRequestStatus.fulfilled, (state, action) => {
        state.purchaseRequestsByPart[action.payload.purchaseRequestId] = action.payload;
        state.purchaseRequestsByPurchaseOrder[action.payload.purchaseRequestId] = action.payload;
        state.purchaseRequests[action.payload.purchaseRequestId] = action.payload;
      });
  },
});

export const {
  setFetchPurchaseRequestsStatus,
  setCreatePurchaseRequestsStatus,
  setUpdatePurchaseRequestStatus,
  setAssignPurchaseRequestStatus,
  setCurrentPurchaseRequestId,
  setpurchaseRequestsByPurchaseOrderFetch,
} = purchaseRequestsSlice.actions;

// eslint-disable-next-line max-len
export const selectCurrentPurchaseRequestId = (state) => state.purchaseRequests.currentPurchaseRequest?.purchaseRequestId;
export const selectPurchaseRequestsByPartObjects = (state) => state.purchaseRequests.purchaseRequestsByPart;
export const selectPurchaseRequestsObjects = (state) => state.purchaseRequests.purchaseRequests;
export const selectPurchaseRequestsByPurchaseOrderObjects = (state) => state.purchaseRequests.purchaseRequestsByPurchaseOrder;

export const selectPurchaseRequestsByPart = createSelector(
  [selectPurchaseRequestsByPartObjects],
  (purchaseRequests) => Object.values(purchaseRequests).filter((pr) => pr.isActive === true),
);

export const selectPurchaseRequestsByPurchaseOrder = createSelector(
  [selectPurchaseRequestsByPurchaseOrderObjects],
  (purchaseRequests) => {
    return Object.values(purchaseRequests).filter((pr) => pr.isActive === true);
  },
);

export const selectPurchaseRequestsByCurrentPurchaseOrder = createSelector(
  [selectPurchaseRequestsByPurchaseOrder, selectCurrentPurchaseOrder],
  (purchaseRequests, purchaseOrder) => {
    return Object.values(purchaseRequests).filter((pr) => pr.isActive === true
    && pr.purchaseOrderId === purchaseOrder?.purchaseOrderId);
  },
);

export const selectOutstandingPurchaseRequests = createSelector(
  [selectCurrentPurchaseOrder, selectPurchaseRequestsByCurrentPurchaseOrder, selectPurchaseRequestsObjects],
  (purchaseOrder, purchaseRequestsPO, purchaseRequestsPlain) => {
    if (purchaseOrder?.purchaseOrderId) {
      return Object.values(purchaseRequestsPlain)
        .filter((pr) => pr.isActive === true
        && pr.purchaseOrderId !== purchaseOrder.purchaseOrderId
        && !purchaseRequestsPO.some((npr) => npr.purchaseRequestId === pr.purchaseRequestId));
    }
    return [];
  },
);

export const selectOutstandingPurchaseRequestsByLocation = createSelector(
  [selectCurrentPurchaseOrder, selectOutstandingPurchaseRequests],
  (purchaseOrder, purchaseRequests) => {
    if (purchaseOrder) {
      const locationId = purchaseOrder.isStockTransferOrder ? purchaseOrder.sourceLocationId : purchaseOrder.locationId;
      return Object.values(purchaseRequests)
        .filter((pr) => pr.part.locationId === locationId);
    }
    return [];
  },
);

export const selectCreatePurchaseRequstsStatus = (
  state,
) => state.purchaseRequests.purchaseRequestsCreate.status;

export const selectCurrentPurchaseRequest = createSelector(
  [
    selectPurchaseRequestsObjects,
    selectCurrentPurchaseRequestId,
  ],
  (purchaseRequests, curr) => {
    const currentPurchaseRequest = Object.values(purchaseRequests).find((pr) => pr.purchaseRequestId === curr);
    return { ...currentPurchaseRequest };
  },

);

export default purchaseRequestsSlice.reducer;
