import { sortArray } from "../../../../services/array-helpers";
import { formatDate } from "../../../../services/format";
import { GetLiquidCustomers } from "../../../../services/requests";
import { FormItemType } from "../../../UI/view/component-map";
import { Repository } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createView } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { liquidBulkLineItems } from "./liquid-bulk-line-items";
import { LiquidBillingInvoiceDataSource } from "./liquid-invoice-list";
import { RecentDeliveriesList } from "./liquid-recent-deliveries";


export const liquidInvoiceEdit = {
  editMode: true,
  pageHeader: 'Liquid Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Liquid Billing',
      icon: 'currency-dollar',
      to: '/billing/liquidproduct',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          type: FormItemType.infoPane,
          header: ({ liquidbilling }) => liquidbilling?.current?.data?.customerName,
          infoListItems: [
            {
              label: 'Customer Id',
              name: 'customerId'
            },
            {
              label: 'Customer',
              name: 'customerName'
            },
            {
              label: 'Invoice Date',
              name: 'invoiceDate',
              value: ({ invoiceDate }) => formatDate(invoiceDate)
            },

          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Liquid Billing',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Billing Information',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          type: FormItemType.select,
                          label: 'Customer',
                          name: 'customerId',
                          minWidth: 200,
                          options: async () => {
                            const customers = sortArray({ data: (await GetLiquidCustomers(null)), key: 'customerName', sortingOrder: 'asc' });
                            return [{ text: "", id: null }, ...customers.map(c => ({ id: c.customerId, text: c.customerName }))]
                          }
                        },
                        {
                          name: 'soNumber',
                          label: 'SO #',
                          minWidth: 200,
                        },
                        {
                          name: 'customerPort',
                          label: 'Customer Port',
                          minWidth: 200,
                        },
                        {
                          name: 'containerIdstarted',
                          label: 'Container',
                          disabled: () => true,
                          minWidth: 200,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          name: 'dateStarted',
                          label: 'Date',
                          type: FormItemType.date,
                          minWidth: 200,
                        },
                        {
                          name: 'sailDate',
                          label: 'Sailing Date',
                          type: FormItemType.date,
                          minWidth: 200,
                        },
                        {
                          label: 'Override Gross Weight (lbs)',
                          name: 'overrideGrossWeight',
                          number: true,
                          minWidth: 200,
                        },
                        {
                          label: 'Override Net Product Weight (lbs)',
                          name: 'overrideNetProductWeight',
                          number: true,
                          minWidth: 200,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Override OrderID',
                          name: 'overrideOrderId',
                          minWidth: 200,
                        },
                        {
                          label: 'Override Invoice Date',
                          name: 'overrideInvoiceDate',
                          type: FormItemType.date,
                          minWidth: 200,
                        },
                        {
                          label: 'PO #',
                          name: 'ponumber',
                          minWidth: 200,
                        },
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [

                        {
                          label: 'RO #',
                          name: 'ronumber',
                          minWidth: 200,
                        },
                        {
                          label: 'Vessel',
                          name: 'vessel',
                          minWidth: 200,
                        },
                        {
                          label: 'Override Terms of Sale',
                          name: 'invoiceTermsOverride',
                          minWidth: 200,
                        },
                        {
                          label: 'Routed Transaction',
                          name: 'routedTransaction',
                          type: FormItemType.checkboxField,
                          minWidth: 200,
                        },
                      ]
                    },

                    {
                      type: FormItemType.row,
                      items: [

                        {
                          label: 'Related Party',
                          name: 'relatedParty',
                          type: FormItemType.checkboxField,
                          minWidth: 200,
                        },
                        {
                          label: 'Hold Bill',
                          name: 'isHold',
                          type: FormItemType.checkboxField,
                          minWidth: 200,
                        }
                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Container',
                          name: 'containerIdstarted',
                          type: FormItemType.labelField
                        },
                        {
                          label: 'Billing Notes',
                          name: 'billingComments',
                          type: FormItemType.labelField
                        },
                        {
                          label: 'Order Type',
                          name: 'orderTypeName',
                          type: FormItemType.labelField
                        },

                      ]
                    },
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          label: 'Tracking #',
                          name: 'trackingNumberId',
                          type: FormItemType.labelField,
                          link: ({ watch }) => `https://vts.linde.grp/Helium/VTS/Liquid/Inventory.aspx?trkno=${watch('trackingNumberId')}`,
                        },
                        {
                          label: 'Booking Id',
                          name: 'bookingId',
                          type: FormItemType.labelField,
                          link: ({ watch }) => `https://vts.linde.grp/Helium/VTS/Logistics/Bookings_Edit.aspx?bookingid==${watch('bookingId')}`,
                        },
                        {
                          label: 'Booking Agent',
                          name: 'bookingAgent',
                          type: FormItemType.labelField,
                        },
                        {
                          label: 'Default Terms',
                          name: 'invoiceTermsOfSale',
                          type: FormItemType.labelField,
                        }
                      ]
                    },
                    {
                      name: 'customerComments',
                      label: 'Customer Comments',
                      type: FormItemType.textarea,
                    },
                    {
                      name: 'deliveryComments',
                      label: 'Delivery Comments',
                      type: FormItemType.textarea,
                    },
                    {
                      name: 'isStagingInvoice',
                      hideIf: () => true
                    }

                  ]
                },

              ]
            },
            {
              label: 'Bulk Line Items',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Bulk Line Items',
                  items: liquidBulkLineItems
                }
              ]
            },
            {
              label: 'Recent Deliveries',
              items: [
                {
                  type: FormItemType.customComponent,
                  component: RecentDeliveriesList
                }
              ]
            }
          ]
        }
      ]
    }
  ]
} as FormView;


export const LiquidInvoiceEdit = createView({ form: liquidInvoiceEdit, repository: LiquidBillingInvoiceDataSource as Repository });