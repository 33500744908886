
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { billingPermissions } from "./billing-datasource";
import { InsertBillingItemType, UpdateBillingItemType, getBillingItemTypes } from "../../../services/requests/billing/misc-billing-requests";

export const billingItemDataSource = {
  name: 'billingItemTypes',
  request: getBillingItemTypes,
  updateRequest: UpdateBillingItemType,
  createRequest: InsertBillingItemType,
  pk: 'itemKey'
}
export const billingItemEdit = {
  editMode: true,
  permissions: billingPermissions,
  items: [
    {
      type: FormItemType.fieldset,
      items: [
        {
          name: 'itemKey',
          label: 'Item Key',
          defaultSort: true,
          required: true,
          unique: true
        },
        {
          name: 'description',
          label: 'Description',
          required: true,
        },
        {
          name: 'billingType',
          label: 'Billing Type',
          required: true,
          defaultValue: 'MISC',
          type: FormItemType.select,
          options: [
            { id: 'MISC', text: 'Misc' }
          ]
        },
      ]
    }
  ]
} as FormView;

export const billingItemCreate = {
  ...billingItemEdit,
  editMode: false
}

export const billingItemTypeList = {
  activeFlag: 'active',
  filters: [
    {
      type: FilterType.simple,
      fieldName: 'active',
      label: 'Active',
      filterValue: true
    }
  ],
  permissions: billingPermissions,
  create: billingItemCreate,
  update: billingItemEdit,
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      accessor: 'itemKey',
      Header: 'Item Key',
      defaultSort: true,
    },
    {
      accessor: 'description',
      Header: 'Description'
    },
    {
      accessor: 'billingType',
      Header: 'Billing Type'
    },
    {
      accessor: 'active',
      Header: 'active',
      hidden: true
    },
  ]
} as ListView;


export const BillingItemList = createConnectedListView(billingItemTypeList, billingItemDataSource);


