import React from 'react';
import { ListGroup } from 'react-bootstrap';
import { useSelector } from 'react-redux';

import { splitStringOnUppercase } from '../../../services/format';
import { selectAllActiveLocationObjects } from '../../../store/slices/legacy-slice';
import { selectMaintenanceRequestDropdownObjects } from '../../../store/slices/maintenance-requests-slice';
import Callout from '../../UI/atoms/Callout/callout';
import InfoListItem from '../../UI/atoms/InfoListItem/info-list-item';

function PartInfo({
  partStatusIsLoading, currentPart,
}) {
  const activeLocations = useSelector(selectAllActiveLocationObjects);
  const maintenanceDropdownValues = useSelector(selectMaintenanceRequestDropdownObjects);
  /* eslint-disable */
  return (
    <Callout className={`${partStatusIsLoading ? ' creation-loading' : ''}`}>
      {<h4>{currentPart ? currentPart.partId : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}</h4>}
      <ListGroup variant="unbordered">
        <ListGroup.Item><InfoListItem name="Part Id" value={currentPart.partId} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Part Name" value={currentPart.partName} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Asset Type" value={currentPart.assetTypeId && currentPart.assetTypeId in maintenanceDropdownValues.assetTypes ? splitStringOnUppercase(maintenanceDropdownValues.assetTypes[currentPart.assetTypeId].name) : 'N/A'} /></ListGroup.Item>
        <ListGroup.Item>
          <InfoListItem
            name="Location"
            value={
              currentPart.locationId && currentPart.locationId in activeLocations ?
                activeLocations[currentPart.locationId].LocationName :
                'N/A'} />
        </ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Quantity on Hand" value={currentPart.quantityOnHand} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Vendor Name" value={currentPart.vendorName} /></ListGroup.Item>
        {/* <ListGroup.Item><InfoListItem name="Minimum Quantity" value={currentPart.minQuantity} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Maximum Quantity" value={currentPart.maxQuantity} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Reorder Level" value={currentPart.reorderLevel} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Part Description" value={currentPart.partDescription} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Is Non-Stock Item" value={currentPart.isNonStockItem ? 'True' : 'False'} /></ListGroup.Item>
        <ListGroup.Item><InfoListItem name="Purchase Price" value='Placeholder Price' /></ListGroup.Item> */}
      </ListGroup>
    </Callout>
  );
}

export default PartInfo;
