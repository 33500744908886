import { FilterType, ListView, ListViewColumnType, createConnectedListView } from "../../UI/view/list-view";
import { groupingsCreate, groupingsEdit } from "./groupings-create";
import { groupingsDataSource } from "./groupings-ds";

export const groupingsList = {
  update: groupingsEdit,
  create: groupingsCreate,
  filters: [
    {
      type: FilterType.combo,
      label: 'Segment',
      fieldName: 'segment',
    },
  ],
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      defaultSort: true
    },
    {
      Header: 'Segment',
      accessor: 'segment'
    },
    {
      Header: 'Grouping',
      accessor: 'grouping'
    },
    {
      Header: 'Sales Manager',
      accessor: 'salesManager'
    },
  ]
} as ListView

export const GroupingsListView = createConnectedListView(groupingsList, groupingsDataSource);

/*
,[CustomerName]
,[Segment]
,[Grouping]
,[SalesManager]
*/