import { FormItemType } from "../../../UI/view/component-map";
import { Repository } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { gasBillingDataSource } from "./gas-billing-datasource";
import { PendingDeliveryList } from "./gas-billing-pending-delivery";
import { GasInvoiceList, GasInvoiceListRepo } from "./gas-invoice-list";

export const gasBillingMain = {
  editMode: false,
  pageHeader: 'Gas Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Gas Billing',
      icon: 'currency-dollar',
      to: '/billing/gasproduct',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Pending Deliveries',
          items: [
            {
              type: FormItemType.customComponent,
              component: PendingDeliveryList
            }
          ]
        },
        {
          label: 'Invoices',
          items: [
            {
              type: FormItemType.customField,
              name: 'gasBillingList',
              component: GasInvoiceList
            }
          ]
        },

      ]
    }
  ]
} as FormView;

export const [GasBillingMain, GasBillingMainRepo] = createConnectedView2({ form: gasBillingMain, existingRepo: GasInvoiceListRepo as Repository});