import React from "react"
import { Button } from "react-bootstrap"
import { useNavigate } from "react-router-dom";
import { usePermissions } from "../../../hooks/usePermissions";

export const SubmitButton = ({ editMode, entity, entityLoading, field, isAdmin, writeAccess, handleSubmit, onSubmit, watch, confirm }) => {
  const redirect = useNavigate();
  const [localWrite] = usePermissions(field.permissions);
  return (
    <Button
      type="submit"
      disabled={!localWrite || (editMode && !entity) || entityLoading || (field.adminOnly && !isAdmin) || !writeAccess || (field.disabled ? field.disabled({ watch }, entity) : false)}
      onClick={async () => {
        await handleSubmit(onSubmit)();
        if (field.onAfterSubmit) {
          field.onAfterSubmit({redirect, confirm});
        }
      }}
      style={{ marginLeft: 'auto' }}
    >
      {field.label || 'Update'}
    </Button>
  )
}