import React from 'react';
import {
  Breadcrumb,
  Col, Container, Row,
} from 'react-bootstrap';
import { Link } from 'react-router-dom';

function PageHeader(props) {
  const { formState: { touchedFields, dirtyFields } } = props;
  const formChanged = Object.keys(dirtyFields).length > 0 || Object.keys(touchedFields).length > 0;
  return (
    <Container fluid className="mt-1 mb-1 p-0">
      <Row>
        <Col className="d-flex align-items-center">
          <h3 className="m-0">{props.header}</h3>
          {formChanged && <i className="bi bi-pencil" style={{marginLeft: 8}} />}
        </Col>
        <Col sm="auto">
          <Breadcrumb
            className="d-flex align-items-center"
            listProps={{ style: { display: 'flex', alignItems: 'center', margin: 0 } }}
          >
            <Breadcrumb.Item key="breadbrumb-home" linkAs={Link} linkProps={{ to: '/' }}>
              {' '}
              <i className="nav-icon bi bi-house-door" />
              {' '}
              Home
            </Breadcrumb.Item>
            {props.breadcrumbs.map((breadcrumb, i, arr) => {
              if (i + 1 === arr.length) {
                return (
                  <Breadcrumb.Item key={`breadbrumb-${i}`} active={!breadcrumb.to} linkAs={breadcrumb.to && Link} linkProps={breadcrumb.to && { to: breadcrumb.to }}>
                    <i className={`nav-icon bi bi-${breadcrumb.icon}`} />
                    {' '}
                    {breadcrumb.name}
                  </Breadcrumb.Item>
                );
              }
              return (
                <Breadcrumb.Item key={`breadbrumb-${i}`} linkAs={Link} linkProps={{ to: breadcrumb.to }}>
                  <i className={`nav-icon bi bi-${breadcrumb.icon}`} />
                  {' '}
                  {breadcrumb.name}
                </Breadcrumb.Item>
              );
            })}
          </Breadcrumb>
        </Col>
      </Row>
    </Container>
  );
}

export default PageHeader;
