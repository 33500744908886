import { dewarCustomerStore } from "../../../store/store";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createView } from "../../UI/view/view";
import { PricingTableView } from "./packaged-product-pricing-table";
import { customerPermissions } from "./customer-data-source";
import PackagedProductHistory from "./customer-packaged-history-list";

export const customerDewarEdit = {
  editMode: true,
  pageHeader: 'Packaged Customer',
  permissions: customerPermissions,
  breadcrumbs: [
    {
      name: 'Customers',
      icon: 'people',
      to: '/customers#Packaged Customers',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              infoListItems: [
                {
                  label: 'Primary Plant',
                  name: 'primaryPlant',
                },
                {
                  label: 'CBT',
                  name: 'billToNumber',
                },
                {
                  label: 'CBT Name',
                  name: 'billToName',
                },
                {
                  label: 'PO Info',
                  name: 'poInfoField',
                },
                {
                  label: 'Billing Message',
                  name: 'billingMessage',
                },
                {
                  label: 'Credit Manager',
                  name: 'creditManager',
                },
                {
                  label: 'Terms',
                  name: 'terms',
                },
                {
                  label: 'Blanket PO',
                  name: 'blanketPO',
                },
                {
                  label: 'Credit Rep',
                  name: 'creditRep',
                },
                {
                  label: 'Address 1',
                  name: 'address1',
                  disabled: () => true
                },
                {
                  label: 'Address 2',
                  name: 'address2',
                  disabled: () => true
                },
                {
                  label: 'Address 3',
                  name: 'address3',
                  disabled: () => true
                },
                {
                  label: 'Address 4',
                  name: 'address4',
                  disabled: () => true
                },
                {
                  label: 'City',
                  name: 'city',
                  disabled: () => true
                },
                {
                  label: 'State',
                  name: 'state',
                  disabled: () => true
                },
                {
                  label: 'Zip',
                  name: 'zip',
                  disabled: () => true
                },
                {
                  label: 'County',
                  name: 'county',
                  disabled: () => true
                },
                {
                  label: 'Country',
                  name: 'country',
                  disabled: () => true
                },
              ],
              header: (state: any) => {
                const entity = state.dewarcustomers?.current?.data ?? {};
                return `${entity.accountName} (${entity.site})`
              },
            },
            {
              type: FormItemType.fileAttachments,
              entityTypeId: 10,
              entityName: 'Dewar Customers',
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'General Info',
              items: [
                {
                  type: FormItemType.row,
                  items: [
                    {
                      type: FormItemType.fieldset,
                      label: 'Info',
                      items: [
                        {
                          label: 'Name',
                          name: 'accountName',
                          disabled: () => true
                        },
                        {
                          label: 'Delivery Instructions',
                          name: 'deliveryInstructions',
                          type: FormItemType.textarea
                        },
                        {
                          label: 'Order Notes',
                          name: 'orderNotes',
                          type: FormItemType.textarea
                        },
                        {
                          label: 'Logistics Only Comments',
                          name: 'logisitcsOnlyNotes',
                          type: FormItemType.textarea
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'Contact Name',
                              name: 'contactName',
                            },
                            {
                              label: 'Contact Phone',
                              name: 'contactPhone',
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'Contact Fax',
                              name: 'contactFax',
                            },
                            {
                              label: 'Contact Email',
                              name: 'contactEmail',
                            },
                          ]
                        }


                      ],
                    },
                    {
                      type: FormItemType.fieldset,
                      hide: ({watch}) => {
                        return !watch('isInternational');
                      },
                      label: 'International Fields',
                      items: [
                        {
                          label: 'International Name',
                          name: 'customerName',
                        },
                        {
                          label: 'Shipping Address 1',
                          name: 'shippingAddress1',
                        },
                        {
                          label: 'Shipping Address 2',
                          name: 'shippingAddress2',
                        },
                        {
                          label: 'Shipping Address 3',
                          name: 'shippingAddress3',
                        },
                        {
                          label: 'Shipping Address 4',
                          name: 'shippingAddress4',
                        },
                        {
                          label: 'Billing Address 1',
                          name: 'billingAddress1',
                        },
                        {
                          label: 'Billing Address 2',
                          name: 'billingAddress2',
                        },
                        {
                          label: 'Billing Address 3',
                          name: 'billingAddress3',
                        },
                        {
                          label: 'Billing Address 4',
                          name: 'billingAddress4',
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'ECCN',
                              name: 'eccn',
                            },
                            {
                              label: 'License Number',
                              name: 'licenseNumber',
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'Sales Terms',
                              name: 'salesTerms',
                            },
                            {
                              label: 'Freight Terms',
                              name: 'freightTerms',
                            },
                          ]
                        },
                        {
                          type: FormItemType.row,
                          items: [
                            {
                              label: 'Payment Terms',
                              name: 'paymentTerms',
                            },
                            {
                              label: 'Related Party',
                              name: 'relatedParty',
                              type: FormItemType.checkboxField
                            },
                          ]
                        }


                      ]
                    },
                  ]
                }
              ],
              footerItems: [
                {
                  type: FormItemType.submitButton,
                  permissions: customerPermissions,
                }
              ]
            },
            {
              label: 'Pricing',
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Pricing',
                  items: [
                    {
                      type: FormItemType.customComponent,
                      component: PricingTableView
                    }
                  ]
                }
              ]
            },
            {
              label: 'Audit Logs',
              unMountOnExit: true,
              items: [
                {
                  type: FormItemType.auditLogs,
                  name: 'DewarCustomers',
                }
              ]
            },
            {
              label: 'History',
              items: [
                {
                  type: FormItemType.customComponent,
                  component: PackagedProductHistory,
                }
              ]
            }
          ]
        },
      ]
    }
  ]

} as FormView;

export const CustomerDewarEdit = createView({ form: customerDewarEdit as any, repository: dewarCustomerStore as any });