import React, { useMemo, useReducer } from 'react';

import ConfirmContext from './confirm-context';
import { confirmInitialState, confirmReducer } from './confirm-reducer';

function ConfirmContextProvider({ children }) {
  const [confirmState, dispatch] = useReducer(confirmReducer, confirmInitialState);

  const confirmStateMemo = useMemo(() => [confirmState, dispatch], [confirmState]);
  return (
    <ConfirmContext.Provider value={confirmStateMemo}>
      {children}
    </ConfirmContext.Provider>
  );
}

export default ConfirmContextProvider;
