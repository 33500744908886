import { months } from "../../../../services/date-helpers";
import { formatCurrency, formatDate } from "../../../../services/format";
import { jdeUploadByBillingIDs, uploadInvoicesJDEDTO } from "../../../../services/requests/billing/dewar-billing-requests";
import { FormItemType } from "../../../UI/view/component-map";
import { ListView, ListViewColumnType, createConnectedListView, createConnectedListView2 } from "../../../UI/view/list-view";
import { billingPermissions } from "../billing-datasource";
import { billingListView } from "../billing-invoice-list";
import { dewarInvoiceDataSource } from "./dewar-datasource";

const dewarBillingListView = billingListView({ uploadEndpoint: jdeUploadByBillingIDs, collectionName: 'dewarInvoices' });

export const dewarBillingList = {
  secondarySort: dewarBillingListView.secondarySort,
  toolbar: [...dewarBillingListView.toolbar,
    {
      type: FormItemType.button,
      label: 'Upload Selected to JDE (Recheck Warnings)',
      permissions: {
        read: [1, 14, 15, 18],
        write: [1, 15],
        admin: [1, 15],
      },
      handler: async ({ getValues, confirm }) => {
        const vals = getValues();
        const billingList = vals['dewarInvoices'];
        if (!billingList?.length) {
          await confirm('No invoices are selected. Please select invoices from the list below then press upload.', true)
        } else {
          if (await confirm("Are you sure you want to upload the selected invoices to JDE?") == true) {
            let isError = false;
            try {
              await jdeUploadByBillingIDs({ billingIds: billingList.map(b => b.billingId), recheckWarnings: true });
            } catch (e) {
              isError = true;
              await confirm('There was an error uploading to JDE:' + e, true)
            }
            if (!isError) {
              await confirm('JDE Upload process is completed.', true);
              location.reload();
            }
          }
        }
      }
    }
  ],
  filters: dewarBillingListView.filters,
  defaultPageSize: 500,
  maxPageSize: 500,
  permissions: billingPermissions,
  columns: [
    dewarBillingListView.columns.find(c => c.accessor == 'checked'),
    dewarBillingListView.columns.find(c => c.id == 'trafficStatus'),
    {
      Header: 'Customer Name',
      accessor: 'customerName',
      type: ListViewColumnType.linkEdit,
      linkPath: ({ val, row }) => '/billing/dewarproductedit/' + row.original.billingId,
      serverExpression: 'DewarCustomer.AccountName',
    },
    {
      Header: 'Date Invoiced',
      accessor: 'dateInvoiced',
      defaultSortDirection: 'desc',
      type: ListViewColumnType.date,
      excludeGlobalFilter: true,
    },
    {
      Header: 'Date Processed',
      accessor: 'dateJdeprocessed',
      type: ListViewColumnType.date,
      excludeGlobalFilter: true,
    },
    {
      Header: 'CST',
      accessor: 'dewarCustomerID'
    },

  /*Specialty Columns here */
    {
      Header: 'JDE Batch Number',
      accessor: 'jdebatchNumber',
    },
    {
      Header: 'JDE Errors',
      accessor: 'uploadIssues',
      hideOverflow: true,
      maxWidth: 200,
    },
    {
      Header: 'Billing Id',
      accessor: 'billingId',
      hidden: true
    },
    {
      Header: 'priority',
      accessor: 'priority',
      hidden: true,
      defaultSort: true,
      excludeGlobalFilter: true,
      defaultSortDirection: 'desc',
      serverExpression: `iif(holdDate > ${'"' + formatDate(new Date()) + '"'}, 2, iif(Status == 0 && Jdebypassed == false, 4, iif(Status == 9 && Jdebypassed == false, 3, 1)))`
    },
    {
      Header: 'status',
      accessor: 'status',
      hidden: true,
      excludeGlobalFilter: true,
    },
    {
      Header: 'customerId',
      accessor: 'customerId',
      hidden: true,
    },
    {
      Header: 'holdDate',
      accessor: 'holdDate',
      hidden: true,
    },
    {
      Header: 'hasErrors',
      accessor: 'hasErrors',
      hidden: true,
      excludeGlobalFilter: true,
    },
    {
      Header: 'unbilled',
      accessor: 'unbilled',
      hidden: true,
      excludeGlobalFilter: true,
    },
  ]
} as ListView;
export const [BillingDewarListView, BillingDewarListViewRepo] = createConnectedListView2(dewarBillingList, dewarInvoiceDataSource);