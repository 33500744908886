import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createConnectedView } from "../../UI/view/view";
import { locationsDataSource, locationsPermissions } from "./locations-data-source";

export const locationsCreate = {
  pageHeader: 'Location Create',
  editMode: false,
  permissions: locationsPermissions,
  breadcrumbs: [
    {
      name: 'Locations',
      icon: 'building',
      to: '/locations',
    },
  ],
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Details',
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              name: 'locationId',
              label: 'Location Id',
              required: true,
              unique: true,
              maxLength: 4
            },
            {
              name: 'locationName',
              label: 'Location Name',
              required: true,
              unique: true,
              maxLength: 50
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'addressName',
              label: 'Address Name Line',
              maxLength: 255,
              defaultValue: ''
            },
            {
              name: 'addressLine1',
              label: 'Address',
              maxLength: 500,
              defaultValue: ''
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'addressLine2',
              label: 'City,State Zip',
              maxLength: 500,
              defaultValue: ''
            },
            {
              name: 'type',
              label: 'Type',
              type: FormItemType.select,
              options: [
                { text: "", id: null },
                {
                  id: 'Refined',
                  text: 'Refined'
                }
              ],
              maxLength: 20
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'jdeBranchId',
              label: 'JDE Branch ID',
              maxLength: 12
            },
            {
              name: 'stagingMiles',
              label: 'Staging Miles',
              number: true
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.row,
              items: [
                {
                  name: 'isRentalBookingInsert',
                  label: 'Rental Shipment Insert',
                  type: FormItemType.checkboxField,
                  defaultValue: false
                },
              ]
            },
            {
              name: 'residentCountry',
              label: 'Resident Country',
              type: FormItemType.select,
              required: true,
              defaultValue: 'USA',
              options: [
                {
                  id: 'USA',
                  text: 'USA'
                },
                {
                  id: 'QTR',
                  text: 'Qatar'
                },
                {
                  id: 'ALG',
                  text: 'Algeria'
                },
              ]
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'bypassBilling',
              label: 'Bypass Billing',
              defaultValue: false,
              type: FormItemType.checkboxField
            },
            {
              name: 'isNotificationsEnabled',
              label: 'Enable Notifications',
              type: FormItemType.checkboxField
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'IsPassThroughMaintenanceBilling',
              label: 'Pass Through Maintenance Location',
              type: FormItemType.checkboxField
            },
            {
              name: 'isTreatAsDomesticForPlanning',
              label: 'Treat as Domestic For Planning Tool',
              type: FormItemType.checkboxField
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'isTreatAsDomesticForPlanning',
              label: 'Treat as Domestic For Planning Tool',
              type: FormItemType.checkboxField
            },
            {
              name: 'isVirtualPlant',
              label: 'Virtual Plant',
              type: FormItemType.checkboxField
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              name: 'isStagingFacility',
              label: 'Staging Facility',
              type: FormItemType.checkboxField
            },
            {
              name: 'usePriorResCreditOnShipInvoice',
              label: 'Use Prior Residual Credit on Shipping Invoices',
              type: FormItemType.checkboxField,
              defaultValue: false,
            },
          ]
        },
        {
          name: 'legacyOrganization',
          label: 'Legacy Organization',
          type: FormItemType.select,
          defaultValue: 'Praxair',
          options: [
            {
              id: 'Praxair',
              text: 'Praxair'
            },
            {
              id: 'Linde APAC',
              text: 'Linde APAC'
            },
            {
              id: 'Linde EMEA',
              text: 'Linde EMEA'
            },
          ]
        },
        {
          name: 'active',
          field: 'active',
          defaultValue: true,
          hide: () => true
        }
      ]
    }




    /*     Use Prior Residual Credit on Shipping Invoices */


  ]

} as FormView;

export const LocationCreateFormView = createConnectedView({ form: locationsCreate as any, dataSource: locationsDataSource as any });


/* <asp:ListItem value="USA">USA</asp:ListItem>
<asp:ListItem value="QTR">Qatar</asp:ListItem>
<asp:ListItem value="ALG">Algeria</asp:ListItem>  */

//Resident Country


/* JdebranchId */

/* LocationID	
LocationName	
Address Name Line	
Address	
City,State Zip	
Type	
--Select--
JDE Branch ID	
Staging Miles	
Rental Shipment Insert	
Resident Country	
USA
Bypass Billing	
Enable Notifications	
Pass Through Maintenance Location	
Treat as Domestic For Planning Tool	
Virtual Plant	
Staging Facility	
Use Prior Residual Credit on Shipping Invoices	
Legacy Organization */