import { createAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  createWorkOrderRequest, getWorkOrder,
  getWorkOrders, getWorkOrdersForAsset, putWorkOrderStatus,
  updateWorkOrderRequest,
} from '../../services/requests';
import { selectChassis } from './chassis-slice';
import { selectGasContainers } from './gas-containers-slice';
import { selectLiquidContainers } from './liquid-containers-slice';

const createMaintenanceRequestWithWorkOrderFulfilled = createAction('maintenanceRequest/createMaintenanceRequestWithWorkOrder/fulfilled');
const createWorkOrderWithMaintenanceRequestFulfilled = createAction('maintenanceRequest/createWorkOrderAssignMaintenanceRequest/fulfilled');

const initialState = {
  workOrders: {},
  workOrdersFetch: {
    status: 'idle',
    error: null,
  },
  workOrderFetchSingle: {
    status: 'idle',
    error: null,
  },
  workOrderCreate: {
    status: 'idle',
    error: null,
  },
  workOrderUpdate: {
    status: 'idle',
    error: null,
  },
  currentWorkOrder: {
    workOrderId: null,
  },

};

export const fetchWorkOrder = createAsyncThunk('workOrder/fetchWorkOrder', async (workOrderId) => {
  const response = await getWorkOrder(workOrderId);
  return response;
});

export const fetchWorkOrders = createAsyncThunk('workOrder/fetchWorkOrders', async () => {
  const response = await getWorkOrders();
  return response;
});

export const fetchAssetWorkOrders = createAsyncThunk('workOrder/fetchAssetWorkOrders', async ({ assetType, assetSerialNumber }) => {
  const response = await getWorkOrdersForAsset({ assetType, assetSerialNumber });
  return response;
});

export const createWorkOrder = createAsyncThunk('workOrder/createWorkOrder', async (data) => {
  const response = await createWorkOrderRequest(data);
  return response;
});

export const updateWorkOrder = createAsyncThunk('workOrder/updateWorkOrder', async (data) => {
  const response = await updateWorkOrderRequest(data);
  return response;
});

export const updateWorkOrderStatus = createAsyncThunk('workOrder/updateWorkOrderStatus', async ({ workOrderId, workOrderStatusId }) => {
  const response = await putWorkOrderStatus(workOrderId, workOrderStatusId);
  return response;
});

export const workOrdersSlice = createSlice({
  name: 'workOrder',
  initialState,
  reducers: {
    setCurrentWorkOrderId: (state, action) => {
      state.currentWorkOrder.workOrderId = action.payload;
    },
    setFetchWorkOrderStatus: (state, action) => {
      state.workOrdersFetch.status = action.payload;
    },
    setUpdateWorkOrderStatus: (state, action) => {
      state.workOrderUpdate.status = action.payload;
    },
    setCreateWorkOrderStatus: (state, action) => {
      state.workOrderCreate.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchWorkOrders.pending, (state, action) => {
        state.workOrdersFetch.status = 'loading';
      })
      .addCase(fetchWorkOrders.fulfilled, (state, action) => {
        state.workOrdersFetch.status = 'succeeded';
        state.workOrders = action.payload.reduce((current, w) => ({
          ...current,
          [w.workOrderId]: w,
        }), {});
        state.workOrdersFetch.error = null;
      })
      .addCase(fetchWorkOrders.rejected, (state, action) => {
        state.workOrdersFetch.status = 'failed';
        state.workOrdersFetch.error = action.error.message;
      });

    builder
      .addCase(fetchAssetWorkOrders.pending, (state, action) => {
        state.workOrdersFetch.status = 'loading';
      })
      .addCase(fetchAssetWorkOrders.fulfilled, (state, action) => {
        state.workOrdersFetch.status = 'succeeded';
        state.workOrders = {
          ...state.workOrders,
          ...action.payload.reduce((current, w) => ({
            ...current,
            [w.workOrderId]: w,
          }), {}),
        };
        state.workOrdersFetch.error = null;
      })
      .addCase(fetchAssetWorkOrders.rejected, (state, action) => {
        state.workOrdersFetch.status = 'failed';
        state.workOrdersFetch.error = action.error.message;
      });

    builder
      .addCase(fetchWorkOrder.pending, (state, action) => {
        state.workOrderFetchSingle.status = 'loading';
      })
      .addCase(fetchWorkOrder.fulfilled, (state, action) => {
        state.workOrderFetchSingle.status = 'succeeded';
        state.workOrders = {
          ...state.workOrders,
          [action.payload.workOrderId]: action.payload,
        };
        state.workOrderFetchSingle.error = null;
      })
      .addCase(fetchWorkOrder.rejected, (state, action) => {
        state.workOrderFetchSingle.status = 'failed';
        state.workOrderFetchSingle.error = action.error.message;
      });

    builder
      .addCase(createWorkOrder.pending, (state, action) => {
        state.workOrderCreate.status = 'loading';
      })
      .addCase(createWorkOrder.fulfilled, (state, action) => {
        state.workOrderCreate.status = 'succeeded';
        state.workOrders = {
          ...state.workOrders,
          [action.payload.workOrderId]: action.payload,
        };
        state.currentWorkOrder.workOrderId = action.payload.workOrderId;
        state.workOrderCreate.error = null;
      })
      .addCase(createWorkOrder.rejected, (state, action) => {
        state.workOrderCreate.status = 'failed';
        state.workOrderCreate.error = action.error.message;
      });

    builder
      .addCase(updateWorkOrder.pending, (state, action) => {
        state.workOrderUpdate.status = 'loading';
      })
      .addCase(updateWorkOrder.fulfilled, (state, action) => {
        state.workOrderUpdate.status = 'succeeded';
        state.workOrders = {
          ...state.workOrders,
          [action.payload.workOrderId]: action.payload,
        };
        state.workOrderUpdate.error = null;
      })
      .addCase(updateWorkOrder.rejected, (state, action) => {
        state.workOrderUpdate.status = 'failed';
        state.workOrderUpdate.error = action.error.message;
      });

    builder
      .addCase(updateWorkOrderStatus.pending, (state, action) => {
        state.workOrderUpdate.status = 'loading';
      })
      .addCase(updateWorkOrderStatus.fulfilled, (state, action) => {
        state.workOrderUpdate.status = 'succeeded';
        state.workOrders = {
          ...state.workOrders,
          [action.payload.workOrderId]: action.payload,
        };
        state.workOrderUpdate.error = null;
      })
      .addCase(updateWorkOrderStatus.rejected, (state, action) => {
        state.workOrderUpdate.status = 'failed';
        state.workOrderUpdate.error = action.error.message;
      });

    builder
      .addCase(createMaintenanceRequestWithWorkOrderFulfilled, (state, action) => {
        state.workOrders = {
          ...state.workOrders,
          [action.payload.workOrder.workOrderId]: action.payload.workOrder,
        };
      });
    builder
      .addCase(createWorkOrderWithMaintenanceRequestFulfilled, (state, action) => {
        state.workOrders = {
          ...state.workOrders,
          [action.payload.workOrder.workOrderId]: action.payload.workOrder,
        };
      });
  },
});

export const {
  setCurrentWorkOrderId,
  setUpdateWorkOrderStatus,
  setCreateWorkOrderStatus,
  setFetchWorkOrderStatus,
} = workOrdersSlice.actions;

export const selectWorkOrders = (state) => state.workOrder.workOrders;
export const selectCurrentWorkOrderId = (state) => (
  state.workOrder.currentWorkOrder.workOrderId
);

export const selectCurrentWorkOrder = createSelector([
  selectWorkOrders,
  selectCurrentWorkOrderId,
], (workOrders, currentWorkOrderId) => {
  if (currentWorkOrderId == null) {
    return {};
  }
  return workOrders[currentWorkOrderId];
});

export const selectCurrentWorkOrderAsset = createSelector([
  selectCurrentWorkOrder,
  selectLiquidContainers,
  selectGasContainers,
  selectChassis,
], (
  currentWorkOrder,
  liquidContainers,
  gasContainers,
  chassis,
) => {
  if (currentWorkOrder) {
    if (currentWorkOrder.assetTypeId === 1) {
      return gasContainers[currentWorkOrder.assetSerialNumber];
    } if (currentWorkOrder.assetTypeId === 2) {
      return liquidContainers[currentWorkOrder.assetSerialNumber];
    } if (currentWorkOrder.assetTypeId === 4) {
      return chassis[currentWorkOrder.assetSerialNumber];
    }
  }
  return {};
});

export const selectLiquidContainerWorkOrders = createSelector(
  [selectWorkOrders, selectLiquidContainers],
  (workOrders, liquidConatiners) => Object.keys(workOrders).reduce((current, w) => {
    if (workOrders[w].assetTypeId === 2) {
      return {
        ...current,
        [w]: { ...workOrders[w], assetDetails: liquidConatiners[w.assetSerialNumber] },
      };
    }
    return current;
  }, {}),
);

export const selectGasContainerWorkOrders = createSelector(
  [selectWorkOrders, selectGasContainers],
  (workOrders, gasContainers) => Object.keys(workOrders).reduce((current, w) => {
    if (workOrders[w].assetTypeId === 1) {
      return {
        ...current,
        [w]: { ...workOrders[w], assetDetails: gasContainers[w.assetSerialNumber] },
      };
    }
    return current;
  }, {}),
);

export const selectChassisWorkOrders = createSelector(
  [selectWorkOrders, selectChassis],
  (workOrders, chassis) => Object.keys(workOrders).reduce((current, w) => {
    if (workOrders[w].assetTypeId === 4) {
      return {
        ...current,
        [w]: { ...workOrders[w], assetDetails: chassis[w.assetSerialNumber] },
      };
    }
    return current;
  }, {}),
);

export const selectGasContainerWorkOrdersByAssetId = createSelector(
  [selectWorkOrders],
  (workOrders) => Object.keys(workOrders).reduce((current, requestId) => {
    const currentWorkOrder = workOrders[requestId];
    if (currentWorkOrder.assetTypeId === 1) {
      if (currentWorkOrder.assetSerialNumber in current) {
        return {
          ...current,
          [currentWorkOrder.assetSerialNumber]: [
            ...current[currentWorkOrder.assetSerialNumber],
            currentWorkOrder,
          ],
        };
      }
      return {
        ...current,
        [currentWorkOrder.assetSerialNumber]: [
          currentWorkOrder,
        ],
      };
    }
    return current;
  }, {}),
);

export const selectLiquidContainerWorkOrdersByAssetId = createSelector(
  [selectWorkOrders],
  (workOrders) => Object.keys(workOrders).reduce((current, requestId) => {
    const currentWorkOrder = workOrders[requestId];
    if (currentWorkOrder.assetTypeId === 2) {
      if (currentWorkOrder.assetSerialNumber in current) {
        return {
          ...current,
          [currentWorkOrder.assetSerialNumber]: [
            ...current[currentWorkOrder.assetSerialNumber],
            currentWorkOrder,
          ],
        };
      }
      return {
        ...current,
        [currentWorkOrder.assetSerialNumber]: [
          currentWorkOrder,
        ],
      };
    }
    return current;
  }, {}),
);

export const selectChassisWorkOrdersByAssetId = createSelector(
  [selectWorkOrders],
  (workOrders) => Object.keys(workOrders).reduce((current, requestId) => {
    const currentWorkOrder = workOrders[requestId];
    if (currentWorkOrder.assetTypeId === 4) {
      if (currentWorkOrder.assetSerialNumber in current) {
        return {
          ...current,
          [currentWorkOrder.assetSerialNumber]: [
            ...current[currentWorkOrder.assetSerialNumber],
            currentWorkOrder,
          ],
        };
      }
      return {
        ...current,
        [currentWorkOrder.assetSerialNumber]: [
          currentWorkOrder,
        ],
      };
    }
    return current;
  }, {}),
);

export const selectWorkOrdersForAsset = createSelector(
  [
    selectLiquidContainerWorkOrdersByAssetId,
    selectGasContainerWorkOrdersByAssetId,
    selectChassisWorkOrdersByAssetId,
    (state, assetSerialNumber, assetTypeId) => assetSerialNumber,
    (state, assetSerialNumber, assetTypeId) => assetTypeId,
  ],
  (
    liquidContainerWorkOrders,
    gasContainerWorkOrders,
    chassisWorkOrders,
    assetSerialNumber,
    assetTypeId,
  ) => {
    let workOrders = [];
    if (assetTypeId === 1) {
      workOrders = gasContainerWorkOrders[assetSerialNumber] ?? [];
    }
    if (assetTypeId === 2) {
      workOrders = liquidContainerWorkOrders[assetSerialNumber] ?? [];
    }
    if (assetTypeId === 4) {
      workOrders = chassisWorkOrders[assetSerialNumber] ?? [];
    }
    return workOrders;
  },
);

export default workOrdersSlice.reducer;
