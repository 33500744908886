import { GetBillingScheduleList, InsertBillingSchedule, UpdateBillingSchedule } from "../../../services/requests/billing/misc-billing-requests";
import { DataSource } from "../../UI/view/data-source";
import { ListView, ListViewColumnType, createConnectedListView, createConnectedListView2 } from "../../UI/view/list-view";
import { billingScheduleEdit, scheduleOptions } from "./billing-schedule-create";

import { miscBillingScheduleDS } from "./billing-schedule-ds";



export const billingScheduleList = {
  activeFlag: 'isActive',
  create: billingScheduleEdit(true),
  update: {...billingScheduleEdit(false), editMode: true},
/*   create: miscBillingTemplate, */
/*   create: BillingScheduleCreateForm,
  update: BillingScheduleCreateForm, */
  columns: [
     {
      accessor: 'editdelete',
      type: ListViewColumnType.editdelete,
  
    },
    {
      accessor: 'name',
      Header: 'Name',
      defaultSort: true
    },
    {
      accessor: 'customerName',
      Header: 'Customer',
      defaultSort: true
    },
    {
      id: 'schedule',
      Header: 'Schedule',
      accessor: ({schedule}) => scheduleOptions.find(o => o.id === schedule)?.text,
    },

  ],
} as ListView;

export const MiscBillingScheduleList = createConnectedListView(billingScheduleList, miscBillingScheduleDS);

