import 'chartjs-adapter-date-fns';
import {
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  TimeScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { Line } from 'react-chartjs-2';
import { useSelector } from 'react-redux';
import { formatDate } from '../../../../services/format';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import { FilterDefinition, FilterType } from '../list-view';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import debounce from 'lodash.debounce';
import { exportData } from '../../../../services/table-helpers';
import { getUniqueItemsByProperties } from '../../../../services/array-helpers';

const myColors = ['#005591', '#6c757d', '#00a0e1', '#002f5a', 'purple'];

export interface ChartFilter {
  name: any,
  number: any,
  label: any,
  defaultValue: any
}
export interface ChartSeries {
  name: any,
  type: any,
  x: any,
  y: any
}
export interface ChartProps {
  dataSource: any,
  dataSourceArgs: any,
  filters: FilterDefinition[],
  series: ChartSeries[],
  getValues?: any,
  entity?: any,
  metrics?: any[],
  enableExport?: boolean,
  customExport?: any
}

export function Chart(props: ChartProps) {
  const { dataSource, dataSourceArgs, filters, series, getValues, entity, metrics, enableExport, customExport } = props;
  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);
  const [isLoading, setIsLoading] = useState(true);
  const [rawData, setRawData] = useState([]);
  const [dataSets, setDataSets] = useState([]);

  const handleFilterChange = (value, filterId) => {
    const filter = filters?.find(f => f.fieldName === filterId);
    switch (filter?.type) {
      case FilterType.simple:
      case FilterType.radio:
        dispatchFilters({ type: filterId });
        return;
      case FilterType.combo:
      case FilterType.text:
        dispatchFilters({ type: filterId, value });
      default:
        return;
    }
  };
  const initFilterState = React.useMemo(() => filters?.reduce((obj, current, idx) => {
    switch (current.type) {
      case FilterType.combo:
        obj[current.fieldName] = current.filterValue;
        break;
      case FilterType.multiselect:
        obj[current.fieldName] = current.filterValue;
        break;
      default:
        obj[current.fieldName] = current.filterValue ?? true;
    }
    return obj;
  }, {}), [filters]);
  function filtersReducer(state, action) {
    const fieldName = action.type;
    const setVal = action.value ?? !state[action.type];
    const filter = filters?.find(f => f.fieldName === fieldName);
    switch (typeof filter?.type == 'undefined' ? FilterType.simple : filter?.type) {
      case FilterType.simple:
      case FilterType.radio:
        return { ...state, [fieldName]: setVal };
      case FilterType.multiselect:
      case FilterType.combo:
        return { ...state, [fieldName]: action.value };
      default:
        return { ...state, [fieldName]: action.value };
    }
  };
  const [filtersState, dispatchFilters] = useReducer(filtersReducer, { ...initFilterState });

  const formatLabelDate = (dateString) => {
    const dt = new Date(dateString);
    const datePortion = formatDate(dt, datePreference);
    const hours = dt.getHours().toString().length === 1 ? `0${dt.getHours().toString()}` : dt.getHours().toString();
    const minutes = dt.getMinutes().toString().length === 1 ? `0${dt.getMinutes().toString()}` : dt.getMinutes().toString();
    return `${datePortion}`;
  };

  useEffect(() => {
    if (entity) {
      loadData();
    }
  }, [entity, filtersState])

  const getTimestamp = (dateString) => {
    const dt = new Date(dateString);
    return dt.getTime();
  };
  const dp = (yval, xval) => {
    return {
      x: xval,
      y: yval,
    };
  };
  const loadData = async () => {
    setIsLoading(true);
    const dataArgs = dataSourceArgs(entity, filtersState);
    if (dataArgs) {
      const data = await dataSource(...dataArgs)

      setRawData(data);

      let ds = series.map((s, idx) => {
        const datum = getUniqueItemsByProperties(data, [s.x]).map(d => ({ x: getTimestamp(d[s.x]), y: d[s.y] })).sort((a, b) => a.x - b.x)
        const label = s.name;
        const borderColor = myColors[idx];
        const radius = 0;
        const spanGaps = true;
        return {
          label,
          data: datum,
          borderColor,
          backgroundColor: borderColor,
          radius,
          spanGaps
        }
      });
      setDataSets(ds);
    }
    setIsLoading(false);
  }

  const options = {
    animation: false,
    parsing: false,
    interaction: {
      mode: 'nearest',
      axis: 'x',
      intersect: false,
    },
    scales:
    {
      y: {
        ticks:
        {
          beginAtZero: true,
        },
      },
      x: {
        type: 'time',
        ticks: {
          autoSkip: true,
          maxTicksLimit: 10,
          callback: (value) => {
            // eslint-disable-next-line key-spacing
            return formatLabelDate(value);
          },
        },
      },
    },
    tooltips: {
      mode: 'x-axis',
    },
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: false,
      },
      decimation: {
        enabled: true,
        algorithm: 'min-max',
        samples: 200,
      },
    },
  };

  ChartJS.register(
    CategoryScale,
    TimeScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Decimation,
  );

  const renderFilter = (filter: FilterDefinition, key: number) => {
    switch (filter.type) {
      case FilterType.combo:
        return (
          <Row className="uiRow">
            <Col className="col-md-auto">
              <Form.Label>{filter.label}</Form.Label>
            </Col>
            <Col >
            <Form.Control
              as="select"
              size="sm"
              value={filtersState[filter.fieldName]}
              onChange={(e) => handleFilterChange(e.target.value, filter.fieldName)}
            >

              {filter.options(null).map((opt, idx) => <option key={idx} value={opt.value}>{opt.label}</option>)}
            </Form.Control>
            </Col>
          </Row>

        );
      case FilterType.simple:
      case FilterType.radio:
        return (
          <Form.Check
            style={{ marginLeft: 8 }}
            key={key}
            type="switch"
            className="mb-1"
            inline
            label={filter.label}
            checked={filtersState[filter.fieldName]}
            onChange={(e) => handleFilterChange(e, filter.fieldName)}
          />
        );
      case FilterType.text:
        return (
          <Row className="uiRow">
            <Col className="col-md-auto">
              <Form.Label>{filter.label}</Form.Label>
            </Col>
            <Col >
              <Form.Control
                size="sm"
                style={{ width: 80 }}
                value={filtersState[filter.fieldName]}
                onChange={(e) => {
                  handleFilterChange(e.target.value, filter.fieldName)
                }}
                type={filter.number ? 'number' : undefined}
                max={filter.max}
              />
            </Col>
          </Row>
        );
      default:
        throw new Error('invalid case');
    }
  }
  const renderMetric = useCallback((metric: any, key: number) => {
    const val = metric.value(rawData);
    if (!val) return ''
    return (
      <span style={{ marginTop: 3 }}>{metric.label(filtersState)}: <span style={{ fontWeight: 'bold' }}>{metric.value(rawData)}</span> {metric.units}</span>
    )
  }, [rawData]);


  const onExport = () => {
    if (customExport) {
      customExport(rawData);
    } else {
      exportData('data', rawData, []);
    }
  }

  return (
    <>
      <div className="card-tools">
        {isLoading && <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner visible`}
        />}
        {!isLoading && dataSets?.length && <Line
          // @ts-ignore
          options={options}
          data={{
            datasets: dataSets,
          }}
        />}
      </div>
      {!isLoading && (filters?.length || metrics?.length) &&
        <div>
          <div className="p-3 d-flex flex-wrap wrap">
            {
              filters?.map((f, idx) => renderFilter(f, idx))
            }
            {
              metrics?.map((m, idx) => renderMetric(m, idx))
            }
            {enableExport && <Button style={{ marginLeft: 6 }} size="sm" onClick={onExport}><i className="bi bi-download" /></Button>}
          </div>
        </div>
      }
    </>
  );
}
