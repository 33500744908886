
import { createLiquidBillingTransfer } from "../../../../services/requests/billing/liquid-billing-requests";
import { FormItemType } from "../../../UI/view/component-map";
import { DataSource } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";

export const createTransferDataSource = {
  name: 'createTransfer',
  createRequest: createLiquidBillingTransfer,
  request: async () => [],
  pk: 'billingId',
} as DataSource;

export const createTransfer = {
  editMode: false,
  items: [
    {
      type: FormItemType.boundingBox,
      height: 300,
      items: [
        {
          type: FormItemType.fieldset,
          label: 'Create Transfer',
          items: [
            {
              type: FormItemType.select,
              required: true,
              label: 'HDC',
              name: 'hdc',
              defaultValue: null,
              fitToContent: true,
              width: 200,
              options: [
                { id: null, text: '' },
                { id: '98501487', text: 'East Chicago' },
                { id: '98527316', text: 'Fife' },
                { id: '98501468', text: 'Keasbey' },
                { id: '98501475', text: 'North Royalton' },
                { id: '98509217', text: 'Ontario' },
                { id: '98501465', text: 'Shrewsbury' },
                { id: '98501515', text: 'Taft' },
                { id: '98505447', text: 'Tampa' },
                { id: '98501513', text: 'Waxahachie' },
              ]
            },
            {
              label: 'Volume',
              number: true,
              name: 'volume',
              required: true
            }
          ]
        }
      ]
    },

    {
      type: FormItemType.footer,
      items: [
        {
          type: FormItemType.button,
          label: 'Close',
          variant: 'secondary',
          size: '',
          handler: ({ modalContext }) => {
            modalContext.setModalClose()
          }
        },
        {
          type: FormItemType.button,
          label: 'Submit',
          variant: 'primary',
          size: '',
          style: { marginLeft: 'auto' },
          handler: async ({ handleSubmit, onSubmit }) => {
            try {
              await handleSubmit(onSubmit)();
            } catch {

            }
          }
        },
      ]
    }



  ]
} as FormView;

export const [CreateTransfer, CreateTransferDataSource] = createConnectedView2({ form: createTransfer, dataSource: createTransferDataSource });