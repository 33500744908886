import React, { ReactElement, useCallback, useEffect, useMemo, useState } from "react";
import { Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap"
import DateInput from "../../molecules/DateInput/date-input";
import { useValidation } from "../../../hooks/useValidation";
import { BsInfoCircle } from "react-icons/bs";
import { SelectOption } from "../form-view";
import { FormItemType } from "../component-map";
import { Controller } from "react-hook-form";
import { isAsync } from "../../../../services/table-helpers";
import { useSelector } from "react-redux";
import { getError } from "../../../../services/form-helpers";


export const SelectField = ({ watch, field, control, fieldErrors, validation, getValues, data, rowIdentity, isUnique, required, register, errors, formState, editTableProps }) => {
  const isOptionsAsync = typeof field.options === 'function' && isAsync(field.options);
  const useSelectorOptions = typeof field.options === 'function' && !isAsync(field.options);
  const optionSelector = useSelector(useSelectorOptions ? field.options : () => null);
  const [result, setResult] = useState([] as any[]);
  const [customValidation, customValidatorNames] = useValidation({ field, validation, getValues, data, rowIdentity, isUnique, errors });
  const hide = field.hideIf ? field.hideIf({ editTableProps, rowIdentity, watch, getValues }) : false;
  useEffect(() => {
    let active = true
    load()
    return () => { active = false }

    async function load() {
      if (isOptionsAsync) {
        const res = await (field.options as ((data: any, getValues?: any, editTableProps?: any) => Promise<any[]>))(data, getValues, editTableProps);
        if (!active) { return }
        setResult(res)
      }
    }
  }, [data, field.options]);
  const renderTooltip = (props) => {
    return (
      <Tooltip id="button-tooltip" {...props} >
        {field.tooltip}
      </Tooltip>
    )
  }

  return hide ? null : (
    <Controller
      rules={{ required: field.required }}
      control={control}
      name={field.name}
      defaultValue={null}
      render={({
        field: {
          onChange, value,
        },
      }) => {
        return (
          <Form.Group className={editTableProps ? undefined : "mb-1"} title={field.tooltip} style={field.style}>
            {field.label && <Form.Label>{field.label}</Form.Label>}
            {field.label && field.tooltip && (
              <><Form.Label>{field.label}</Form.Label>
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 0, hide: 0 }}
                  overlay={renderTooltip}
                >
                  <Button variant="link" style={{ backgroundColor: 'transparent', color: 'unset', padding: 0, paddingLeft: 6 }}><BsInfoCircle style={{ marginTop: -6 }} size={15} /></Button>
                </OverlayTrigger></>
            )
            }
            <Form.Select
              disabled={field.disabled && field.disabled(watch, rowIdentity)}
              value={value ?? ''}
              style={field.minWidth ? { minWidth: field.minWidth } : undefined}
              onChange={(e) => {
                onChange(e)
                if (editTableProps?.refreshRow) {
                  editTableProps.refreshRow();
                }
                if (field.onChange) {
                  field.onChange({ getValues, editTableProps, evt: e, setValue: formState.setValue }, {})
                }
              }} size="sm"
         /*      {...register(field.name, {
                required: required && 'Required'
              })} */
              isInvalid={fieldErrors}>
              {!isOptionsAsync && useSelectorOptions && (optionSelector as SelectOption[])
                .map((option, index) => (
                  <option value={option.id} key={`option-${index}`}>{option.text}</option>
                ))}
              {!isOptionsAsync && !useSelectorOptions && (field.options as SelectOption[])
                .map((option, index) => (
                  <option selected={true} value={option.id} key={`option-${index}`}>{option.text}</option>
                ))}
              {isOptionsAsync && result.map((option, index) => (
                <option value={option.id} key={`option-${index}`}>{option.text}</option>
              ))}
            </Form.Select>
            {
              fieldErrors && <Form.Control.Feedback type="invalid">
                {'this field is required'}
              </Form.Control.Feedback>
            }
          </Form.Group>
        );
      }}
    />

  );
}