import { getLocations, insertLocation, updateLocation } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";

export const locationsDataSource = {
  request: getLocations,
  updateRequest: updateLocation,
  createRequest: insertLocation,
  name: 'locations',
  pk: 'locationId',
} as DataSource


export const locationsPermissions = {
  read: [1, 17],
  write: [1, 17],
  admin: [1, 17],
};