import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { fetchAssetMaintenanceRequests, setCurrentMaintenanceRequestId, setFetchMaintenanceRequestStatus } from '../../../store/slices/maintenance-requests-slice';
import AddRequestToWorkOrderModal from './add-request-to-work-order';
import AssetMaintenanceRequestsTable from './asset-maintenance-requests-table';
import CreateRequestForm from './create-request-form';
import MaintenanceRequestAttachments from './maintenance-request-attachments';
import RequestDetailsForm from './request-details-form';

function AssetMaintenanceRequests(props) {
  const maintenanceRequestsFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const dispatch = useDispatch();

  const [createModalShow, setCreateModalShow] = useState(false);

  const handleCreateModalClose = () => {
    setCreateModalShow(false);
  };
  const handleCreateModalShow = (assetType) => {
    setCreateModalShow(true);
  };

  const [editModalShow, setEditModalShow] = useState(false);

  const handleEditModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setEditModalShow(false);
  };
  const handleEditModalShow = (assetType) => {
    setEditModalShow(true);
  };

  const [addToWorkOrderModaShow, setAddToWorkOrderModaShow] = useState(false);

  const handleWorkOrderModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setAddToWorkOrderModaShow(false);
  };
  const handleWorkOrderModalShow = (assetType) => {
    setAddToWorkOrderModaShow(true);
  };

  const [
    maintenanceRequestAttachmentsModalShow,
    setMaintenanceRequestAttachmentsModalShow,
  ] = useState(false);

  const handleMaintenanceRequestAttachmentsModalClose = () => {
    dispatch(setCurrentMaintenanceRequestId(null));
    setMaintenanceRequestAttachmentsModalShow(false);
  };
  const handleMaintenanceRequestAttachmentsModalShow = (assetType) => {
    setMaintenanceRequestAttachmentsModalShow(true);
  };

  const loadData = useCallback(async () => {
    await dispatch(fetchAssetMaintenanceRequests({
      assetType: props.assetTypeId,
      assetSerialNumber: props.assetSerialNumber,
    })).unwrap();
    dispatch(setFetchMaintenanceRequestStatus('idle'));
  }, [maintenanceRequestsFetchStatus, props.assetTypeId, props.assetSerialNumber]);

  useEffect(() => {
    loadData();
  }, [props.assetTypeId, props.assetSerialNumber]);

  // re fetch maintenance requests when asset is updated
  // TODO: need to update endpoint to fetch for single asset
  // useEffect(() => {
  //   dispatch(fetchMaintenanceRequests());
  // }, [props.asset]);

  const tableContent = useMemo(() => {
    return (
      <AssetMaintenanceRequestsTable
        assetTypeId={props.assetTypeId}
        assetSerialNumber={props.assetSerialNumber}
        handleCreateModalShow={handleCreateModalShow}
        handleEditModalShow={handleEditModalShow}
        handleWorkOrderModalShow={handleWorkOrderModalShow}
        handleMaintenanceRequestAttachmentsModalShow={handleMaintenanceRequestAttachmentsModalShow}
      />
    );
  }, []);

  return (
    <>
      <div className="h-100 d-flex">
        {tableContent}
      </div>
      <CreateRequestForm
        show={createModalShow}
        onHide={handleCreateModalClose}
        assetSerialNumber={props.assetSerialNumber}
        createModalAssetType={props.assetTypeId}
        asset={props.asset}
      />
      <RequestDetailsForm
        show={editModalShow}
        onHide={handleEditModalClose}
      />
      <AddRequestToWorkOrderModal
        assetTypeId={props.assetTypeId}
        assetSerialNumber={props.assetSerialNumber}
        show={addToWorkOrderModaShow}
        onHide={handleWorkOrderModalClose}
      />
      <MaintenanceRequestAttachments
        show={maintenanceRequestAttachmentsModalShow}
        onHide={handleMaintenanceRequestAttachmentsModalClose}
      />
    </>
  );
}

export default AssetMaintenanceRequests;
