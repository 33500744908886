import React from "react"
import { Badge } from "react-bootstrap";
import { WorldMapFilter } from "./world-map";
import { filter } from "lodash";

export const ColorLegend = (props) => {
  const { filters } = props
  /*   <Badge bg="primary">Liquid</Badge>
    <Badge bg="success">Gas</Badge>
    <Badge bg="danger">Chassis</Badge> */


  return (
    <div>
      {
        filters.map(f => (<Badge bg={f.legendColor}>{f.display}</Badge>))
      }
    </div>
  );
}