import React, { useMemo, useRef, useState } from 'react';
import {
  Button, Collapse,
  Form, InputGroup,
} from 'react-bootstrap';
import { BsFillPencilFill, BsPaperclip, BsPlusLg } from 'react-icons/bs';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';

import { splitStringOnUppercase } from '../../../services/format';
import { getStatusLight } from '../../../services/maintenance-helpers';
import { filterDatesByRange, getDateRangeFilterValue, sortByBasic } from '../../../services/table-helpers';
import { selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  selectChassisMaintenanceRequestsByAssetId,
  selectGasMaintenanceRequestsByAssetId,
  selectLiquidMaintenanceRequestsByAssetId,
  selectMaintenanceRequestDropdownObjects,
  selectMaintenanceRequestTypes,
  setCurrentMaintenanceRequestId,
} from '../../../store/slices/maintenance-requests-slice';
import { RedIcon, YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import StyledDatePicker from '../../UI/molecules/DateInput/styled-date-picker';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import { NextDueDate } from '../../UI/molecules/NextDueDate/next-due-date';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';

function AssetMaintenanceRequestsTable(props) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  let maintenanceRequests = null;
  if (props.assetTypeId === 2) {
    maintenanceRequests = useSelector(selectLiquidMaintenanceRequestsByAssetId);
  } else if (props.assetTypeId === 1) {
    maintenanceRequests = useSelector(selectGasMaintenanceRequestsByAssetId);
  } else if (props.assetTypeId === 4) {
    maintenanceRequests = useSelector(selectChassisMaintenanceRequestsByAssetId);
  }

  const dispatch = useDispatch();

  const maintenanceRequestsFetchStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestsFetch.status,
  );

  const { maintenanceRequestStatuses } = useSelector(selectMaintenanceRequestDropdownObjects);

  const maintenanceRequestsData = useMemo(() => {
    if (maintenanceRequests && props.assetSerialNumber in maintenanceRequests) {
      const requestsList = maintenanceRequests[props.assetSerialNumber];
      return requestsList.filter(
        (requests) => requests.workOrderStatus !== 'Completed' && requests.workOrderStatus !== 'Closed',
      ).sort(
        (a, b) => sortByBasic(new Date(a.modified), new Date(b.modified)),
      ).reverse();
    }
    return [];
  }, [maintenanceRequests, maintenanceRequestsFetchStatus, maintenanceRequestStatuses]);

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const editModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    props.handleEditModalShow();
  };

  const workOrderModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    props.handleWorkOrderModalShow();
  };

  const attachmentsModalShow = (requestId) => {
    dispatch(setCurrentMaintenanceRequestId(requestId));
    props.handleMaintenanceRequestAttachmentsModalShow();
  };

  const maintenanceRequestTypes = useSelector(
    selectMaintenanceRequestTypes,
  );

  const renderStatusLight = (status) => {
    if (status === 'Red') {
      return <RedIcon />;
    }
    if (status === 'Yellow') {
      return <YelloIcon />;
    }
    return '';
  };

  const maintenanceRequestsColumns = useMemo(
    () => [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ row }) => (
          <div className="d-flex">
            <Button
              size="xsm"
              title="Edit"
              onClick={() => editModalShow(row.original.maintenanceRequestId)}
              className="mr-1"
            >
              <BsFillPencilFill />
            </Button>
            {row.original.maintenanceRequestStatusId === 2 && (
              <Button
                size="xsm"
                title="Add to Work Order"
                onClick={() => workOrderModalShow(row.original.maintenanceRequestId)}
                className="mr-1"
              >
                <BsPlusLg />
              </Button>
            )}
            <Button
              size="xsm"
              title="Attach File"
              onClick={() => attachmentsModalShow(row.original.maintenanceRequestId)}
            >
              <BsPaperclip />
            </Button>
          </div>
        ),
      },
      {
        Header: 'ID',
        accessor: 'maintenanceRequestId',
      },
      {
        Header: '',
        accessor: 'serialNumber',
        id: 'maintenanceStatus',
        filter: 'exactText',
        disableSortBy: true,
        Cell: ({ value, row }) => {
          return renderStatusLight(getStatusLight(row.original, maintenanceRequestTypes));
        },
      },
      {
        Header: 'Status',
        accessor: 'maintenanceRequestStatusId',
        Cell: ({ value, row }) => (
          <>
            {value in maintenanceRequestStatuses
              ? splitStringOnUppercase(maintenanceRequestStatuses[value].name)
              : '?'}
            {value === 4 && (
              <>
                &nbsp;(
                <NavLink to={`/maintenance/work-orders/${row.original.workOrderId}`}>
                  {row.original.workOrderId}
                </NavLink>
                )
              </>
            )}
          </>
        )
        ,
      },
      {
        Header: 'Request Type Name',
        accessor: 'maintenanceRequestType',
      },
      {
        Header: 'Estimated Effort​',
        accessor: 'estimatedEffort',
      },
      {
        Header: 'Due Date',
        accessor: 'plannedDueDate',
        filter: filterDatesByRange,
        Cell: ({ row }) => {
          const isOverridden = !!row.original?.overriddenDueDate;
          const dueDate = isOverridden
            ? row.original?.overriddenDueDate : row.original?.plannedDueDate;
          return (
            <NextDueDate
              dueDate={dueDate}
              isOverridden={isOverridden}
              datePreference={datePreference}
            />
          );
        },
      },
      {
        Header: 'Description',
        accessor: 'details',
        className: 'overflow-hide',
        Cell: ({ value }) => <span title={value}>{value}</span>,
      },
    ],
    [maintenanceRequestStatuses, maintenanceRequestTypes],
  );

  const tableInstance = useRef(null);

  const handleSelectFilterChange = (event, filterId) => {
    tableInstance.current.setFilter(filterId, event.target.value);
  };

  const handleRangeDateFilterChange = (timeObj, dateChangeId) => {
    const filterValue = getDateRangeFilterValue(
      timeObj,
      dateChangeId,
      setStartDateFilter,
      setEndDateFilter,
      startDateFilter,
      endDateFilter,
    );

    tableInstance.current.setFilter('plannedDueDate', filterValue);
  };

  const maintenanceRequestsContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={tableInstance} />
          &nbsp;
          <Button variant="primary" size="sm" onClick={props.handleCreateModalShow}>Add Maintenance Request</Button>
        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'maintenanceRequestStatusId'); }}>
                  <option value="" key="maintenanceRequestStatusId-null">-- Status --</option>
                  {Object.keys(maintenanceRequestStatuses).map((obj, index) => (
                    <option value={maintenanceRequestStatuses[obj].id} key={`maintenanceRequestStatusId-${index}`}>{maintenanceRequestStatuses[obj].name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'startDate'); }}
                  value={startDateFilter}
                />
                <Form.Text className="text-muted">Start Date</Form.Text>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <StyledDatePicker
                  onChange={(e) => { handleRangeDateFilterChange(e, 'endDate'); }}
                  value={endDateFilter}
                />
                <Form.Text className="text-muted">End Date</Form.Text>
              </Form.Group>
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={maintenanceRequestsColumns}
        data={maintenanceRequestsData}
        ref={tableInstance}
        rowProps={() => ({})}
      />
    </>
  );

  return (
    <>
      <div className={`d-flex flexbox flex-column overflow-auto flex-grow-1 h-100${maintenanceRequestsFetchStatus === 'loading' ? ' creation-loading' : ''}`}>
        {maintenanceRequestsContent}
      </div>
      {maintenanceRequestsFetchStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default AssetMaintenanceRequestsTable;
