import { getC2CChecklist, upsertC2CChecklist } from "../../../services/requests";
import { FormItemType } from "../../UI/view/component-map";
import { DataSource } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";
import { createConnectedView, createConnectedView2, createView } from "../../UI/view/view";

const checklistFooter = {
  type: FormItemType.footer,
  items: [
    {
      type: FormItemType.button,
      label: 'Save Draft',
      variant: 'secondary',
      size: '',
      style: {marginLeft: 'auto'},
      disabled: ({watch}) => watch('isCompleted'),
      handler: async ({ handleSubmit, onSubmit, setValue }) => {
        setValue('saveAsDraft', true);
        try {
          await handleSubmit(onSubmit)();
        } catch {

        }
      }
    },
    {
      type: FormItemType.button,
      label: 'Complete Checklist',
      variant: 'primary',
      size: '',
      style: {marginLeft: 4},
      handler: async ({ handleSubmit, onSubmit, setValue }) => {
        setValue('saveAsDraft', false);
        try {
          await handleSubmit(onSubmit)();
        } catch {

        }
      }
    },
    {
      name: 'isCompleted',
      hideIf: () => true
    }
  ]
};

export const checkRow = (checkField, commentField, label) => (
  {
    type: FormItemType.row,
    style: { marginLeft: -5 },
    items: [
      {
        type: FormItemType.checkboxClassic,
        style: { paddingLeft: 0, paddingRight: 6 },
        name: checkField,
        variant: 'checkbox',
        fitToContent: true,
      },
      {
        type: FormItemType.label,
        label: label,
      },
      {
        type: FormItemType.text,
        name: commentField,
        className: 'x-small-input',
        width: 220,
        validation: {
          overlap: {
            message: 'provide a comment if leaving unchecked',
            handler: (val: string, getValues, existing, row) => {
              const formValues = getValues();
              return !!formValues[checkField] || !!formValues[commentField] || !!formValues.saveAsDraft;
            }
          },
        }
      }
    ]
  }
);
export const containerChecklistForm = {
  editMode: true,
  items: [
    {
      type: FormItemType.container,
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.fieldset,
              label: 'Right Side',
              items: [
                checkRow('crsisoCornerFrame', 'crsisoCornerFrameComment', 'ISO Corner & Frame'),
                checkRow('crsDataPanel', 'crsDataPanelComment', 'Data Panel'),
                checkRow('crsDoorHinges', 'crsDoorHingesComment', 'Door / Hinges / Latches / Panels'),
                checkRow('crscscPlate', 'crscscPlateComment', 'CSC Plate / Date Decal'),
                checkRow('crsPlacard', 'crsPlacardComment', 'Placard / Hazcom'),
                checkRow('crsHeliumRefrigerated', 'crsHeliumRefrigeratedComment', 'Helium Refrigerated & Thermally Insulated Decal'),
                checkRow('crsN2Vent', 'crsN2VentComment', 'N2 Vent'),
                checkRow('crsSpecialPermitNumber', 'crsSpecialPermitNumberComment', 'Special Logo'),
                checkRow('crsBrandLogo', 'crsBrandLogoComment', 'Brand Logo'),
                checkRow('crsdotInspection', 'crsdotInspectionComment', 'DOT Inspection'),
                checkRow('crsPaint', 'crsPaintComment', 'Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'crsComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
            {
              type: FormItemType.fieldset,
              label: 'Front',
              items: [
                checkRow('cfpDate', 'cfpDateComment', 'P-Date (MC338 Only)'),
                checkRow('cfN2FlowControl', 'cfN2FlowControlComment', 'N2 Flow Control'),
                checkRow('cfPlacard', 'cfPlacardComment', 'Placard / Hazcom'),
                checkRow('cfiso', 'cfisoComment', 'ISO Frame & Corners'),
                checkRow('cfContainerNumber', 'cfContainerNumberComment', 'Container #'),
                checkRow('cfP3Gauge', 'cfP3GaugeComment', 'P3 Gauge / Lens'),
                checkRow('cfHelium', 'cfHeliumComment', 'Helium Refrigerated & Thermally Insulated Decal'),
                checkRow('cfUpArrows', 'cfUpArrowsComment', '"Up" Arrows Decal'),
                checkRow('cfBrandLogo', 'cfBrandLogoComment', 'Brand Logo'),
                checkRow('cfPaint', 'cfPaintComment', 'Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'cfComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.fieldset,
              label: 'Left Side',
              items: [
                checkRow('cliso', 'clisoComment', 'ISO Corner & Frame'),
                checkRow('clDataPanel', 'clDataPanelComment', 'Data PAnel'),
                checkRow('clDoor', 'clDoorComment', 'Door / Hinges / Latches / Panels'),
                checkRow('clcsc', 'clcscComment', 'CSC Plate / Date Decal'),
                checkRow('clPlacard', 'clPlacardComment', 'Placard / Hazcom'),
                checkRow('clHelium', 'clHeliumComment', 'Helium Refrigerated & Thermally Insulated Decal'),
                checkRow('clheVent', 'clheVentComment', 'He Vent'),
                checkRow('clSpecialPermit', 'clSpecialPermitComment', 'Special Permit Number (DOT ISO Onnly)'),
                checkRow('clBrandLogo', 'clBrandLogoComment', 'Brand Logo'),
                checkRow('cldotInspection', 'cldotInspectionComment', 'DOT Inspection'),
                checkRow('clPaint', 'clPaintComment', 'Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'clComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
            {
              type: FormItemType.fieldset,
              label: 'Rear',
              items: [
                checkRow('crPlacard', 'crPlacardComment', 'Placard'),
                checkRow('crDoor', 'crDoorComment', 'Door / Gauge Glass'),
                checkRow('cridPlates', 'cridPlatesComment', 'ID Plates (CSC / DOT Inspection Date Verified)'),
                checkRow('crUpArrowsDecal', 'crUpArrowsDecalComment', '"Up" Arrows Decal'),
                checkRow('crContainerNumber', 'crContainerNumberComment', 'Container #'),
                checkRow('crHelium', 'crHeliumComment', 'Helium Refrigerated & Thermally Insulated Decal'),
                checkRow('crBillboard', 'crBillboardComment', 'Billboard'),
                checkRow('crDoors', 'crDoorsComment', 'Doors / Latches / Hinges'),
                checkRow('criso', 'crisoComment', 'ISO Frame'),
                checkRow('crInsideDoghouse', 'crInsideDoghouseComment', 'Inside Doghouse'),
                checkRow('crBrandLogo', 'crBrandLogoComment', 'Brand Logo'),
                checkRow('crdotInspection', 'crdotInspectionComment', 'DOT Inspection'),
                checkRow('crPaint', 'crPaintComment', 'Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'crComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
          ]
        },
        checklistFooter

      ]
    }
  ]

} as FormView;

export const containerChecklistFormDataSource = {
  name: 'containerC2C',
  pk: 'workOrderId',
  updateRequest: upsertC2CChecklist,
  entityRequest: getC2CChecklist
  /*   entityRequest: getC2crequest */
} as DataSource;



export const chassisChecklistForm = {
  editMode: true,
  items: [
    {
      type: FormItemType.container,
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.fieldset,
              label: 'Right Side',
              items: [
                checkRow('srsTires','srsTiresComment','Tires / Wheels / Lug Nuts / Lug Ind'),
                checkRow('srsWheel','srsWheelComment','Wheel Hubcap & Oil Level'),
                checkRow('srsHubometer','srsHubometerComment','Hubometer'),
                checkRow('srsLights','srsLightsComment','Lights, Reflectors, DOT Tape'),
                checkRow('srsChassisNumber','srsChassisNumberComment','Chassis #'),
                checkRow('srsiso','srsisoComment','ISO Locking Pins'),
                checkRow('srsRegistration','srsRegistrationComment','Registration Box'),
                checkRow('srsLandingGear','srsLandingGearComment','Landing Gear / Handle / Holder'),
                checkRow('srsPaint','srsPaintComment','Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'srsComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
            {
              type: FormItemType.fieldset,
              label: 'Front',
              items: [
                checkRow('sfAirFittings','sfAirFittingsComment','Air Fittings / Gladhands / 7-way Box'),
                checkRow('sfChassis','sfChassisComment','Chassis #'),
                checkRow('sfiso','sfisoComment','ISO Locking Pins'),
                checkRow('sfBoister','sfBoisterComment','Boister'),
                checkRow('sfKingPin','sfKingPinComment','King Pin / Plate'),
                checkRow('sfLights','sfLightsComment','Lights, Reflectors, DOT Tape'),
                checkRow('sfPaint','sfPaintComment','Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'sfComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
          ]
        },
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.fieldset,
              label: 'Left Side',
              items: [
                checkRow('slTires','slTiresComment','Tires / Wheels / Lug Nuts / Lug Ind'),
                checkRow('slWheel','slWheelComment','Wheel Hubcap & Oil Level'),
                checkRow('slHubometer','slHubometerComment','Hubometer'),
                checkRow('sldot','sldotComment','DOT Decal'),
                checkRow('slLights','slLightsComment','Lights, Reflectors, DOT Tape'),
                checkRow('slChassis','slChassisComment','Chassis #'),
                checkRow('sliso','slisoComment','ISO Locking Pins'),
                checkRow('slLandingGear','slLandingGearComment','Landing Gear'),
                checkRow('slChassisLock','slChassisLockComment','Chassis-Lock'),
                checkRow('slabs','slabsComment','ABS Light'),
                checkRow('slPaint','slPaintComment','Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'slComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
            {
              type: FormItemType.fieldset,
              label: 'Rear',
              items: [
                checkRow('srDecking','srDeckingComment','Decking'),
                checkRow('srMudflaps','srMudflapsComment','Mudflaps'),
                checkRow('srChassisNumber','srChassisNumberComment','Chassis #'),
                checkRow('srLights','srLightsComment','Lights, Reflectors, DOT Tape'),
                checkRow('srSuspension','srSuspensionComment','Suspension / Axles'),
                checkRow('srBrakes','srBrakesComment','Brakes / Brake Pots (Rusted?) '),
                checkRow('srrss','srrssComment','RSS / ABS'),
                checkRow('srAirTanks','srAirTanksComment','Air Tanks, Lines, Wheel Sensors'),
                checkRow('srPaint','srPaintComment','Paint, Decals, Other'),
                {
                  type: FormItemType.textarea,
                  name: 'srComment',
                  label: 'General Condition, Notes, Other'
                }
              ]
            },
          ]
        },
        checklistFooter

      ]
    }
  ]

} as FormView;

/*        










          
          
          */


export const [ContainerChecklistForm, ContainerChecklistRepo] = createConnectedView2({ form: containerChecklistForm, dataSource: containerChecklistFormDataSource });

export const ChassisChecklistForm = createView({ form: chassisChecklistForm, repository: ContainerChecklistRepo as any});