import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { createSelector } from 'reselect';

import {
  addUserRole,
  createExternalUserRequest,
  createUserRequest,
  deactivateUserRequest,
  getUser,
  getUsers,
  removeUserRole,
  updateUserRequest,
} from '../../services/requests';
import { sortByBasic } from '../../services/table-helpers';
import {
  selectCurrentRoleData, selectCurrentUserLocation, selectRoles,
} from './roles-slice';

const initialState = {
  users: {},
  currentUser: {
    userId: null,
    status: 'idle',
    error: null,
  },
  usersFetch: {
    status: 'idle',
    error: null,
  },
  userCreate: {
    status: 'idle',
    error: null,
  },
  externalUserCreate: {
    status: 'idle',
    error: null,
  },
  userUpdate: {
    status: 'idle',
    error: null,
  },

};

export const fetchUsers = createAsyncThunk('user/fetchUsers', async () => {
  const response = await getUsers();
  return response;
});

export const createUser = createAsyncThunk('user/createUser', async (user) => {
  const response = await createUserRequest(user);
  return response;
});
export const createExternalUser = createAsyncThunk('user/createExternalUser', async (user) => {
  const response = await createExternalUserRequest(user);
  return response;
});

export const getCurrentUser = createAsyncThunk('user/getCurrentuser', async (userId) => {
  const response = await getUser(userId);
  return response;
});

export const updateUser = createAsyncThunk('user/updateUser', async ({ userId, user }) => {
  const response = await updateUserRequest(userId, user);
  return response;
});

export const deactivateUser = createAsyncThunk('user/deactivateUser', async (userId) => {
  const response = await deactivateUserRequest(userId);
  return response;
});

export const updateCurrentUserRole = createAsyncThunk('user/updateCurrentUserRole', async ({ roleId, action }, { getState }) => {
  const state = getState();
  let response;
  if (action === 'add') {
    response = await addUserRole(state.user.currentUser.userId, roleId);
  } else {
    response = await removeUserRole(state.user.currentUser.userId, roleId);
  }
  return response;
});

export const usersSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUsers: (state, action) => {
      state.users = action.payload;
    },
    setUsersFetchStatus: (state, action) => {
      state.usersFetch.status = action.payload;
    },
    setUserUpdateStatus: (state, action) => {
      state.userUpdate.status = action.payload;
    },
    setUserCreateStatus: (state, action) => {
      state.userCreate.status = action.payload;
    },
    setExternalUserCreateStatus: (state, action) => {
      state.externalUserCreate.status = action.payload;
    },
    setCurrentUserId: (state, action) => {
      state.currentUser.userId = action.payload;
      // state.currentRole.roleData = state.roles.find(role => role.roleId === action.payload);
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchUsers.pending, (state, action) => {
        state.usersFetch.status = 'loading';
      })
      .addCase(fetchUsers.fulfilled, (state, action) => {
        state.usersFetch.status = 'succeeded';
        state.users = action.payload.reduce((current, user) => ({
          ...current,
          [user.userId]: user,
        }), {});
        state.usersFetch.error = null;
      })
      .addCase(fetchUsers.rejected, (state, action) => {
        state.usersFetch.status = 'failed';
        state.usersFetch.error = action.error.message;
      });

    builder
      .addCase(createUser.pending, (state, action) => {
        state.userCreate.status = 'loading';
      })
      .addCase(createUser.fulfilled, (state, action) => {
        state.userCreate.status = 'succeeded';
        state.users[action.payload.userId] = action.payload;
        state.userCreate.error = null;
      })
      .addCase(createUser.rejected, (state, action) => {
        state.userCreate.status = 'failed';
        state.userCreate.error = action.error.message;
      });

    builder
      .addCase(createExternalUser.pending, (state, action) => {
        state.externalUserCreate.status = 'loading';
      })
      .addCase(createExternalUser.fulfilled, (state, action) => {
        state.externalUserCreate.status = 'succeeded';
        state.users[action.payload.userId] = action.payload;
        state.externalUserCreate.error = null;
      })
      .addCase(createExternalUser.rejected, (state, action) => {
        state.externalUserCreate.status = 'failed';
        state.externalUserCreate.error = action.error.message;
      });

    builder
      .addCase(getCurrentUser.pending, (state, action) => {
        state.currentUser.status = 'loading';
      })
      .addCase(getCurrentUser.fulfilled, (state, action) => {
        state.currentUser.status = 'succeeded';
        state.user.users[action.payload.userId] = action.payload;
        state.currentUser.error = null;
      })
      .addCase(getCurrentUser.rejected, (state, action) => {
        state.currentUser.status = 'failed';
        state.currentUser.error = action.error.message;
      });

    builder
      .addCase(updateCurrentUserRole.pending, (state, action) => {
        state.currentUser.status = 'loading';
      })
      .addCase(updateCurrentUserRole.fulfilled, (state, action) => {
        state.currentUser.status = 'succeeded';
        state.users[action.payload.userId] = action.payload;
        state.currentUser.error = null;
      })
      .addCase(updateCurrentUserRole.rejected, (state, action) => {
        state.currentUser.status = 'failed';
        state.currentUser.error = action.error.message;
      });

    builder
      .addCase(deactivateUser.pending, (state, action) => {
        state.usersFetch.status = 'loading';
      })
      .addCase(deactivateUser.fulfilled, (state, action) => {
        state.usersFetch.status = 'succeeded';
        state.users[action.payload.userId] = action.payload;
        state.usersFetch.error = null;
      })
      .addCase(deactivateUser.rejected, (state, action) => {
        state.usersFetch.status = 'failed';
        state.usersFetch.status = action.error.message;
      });

    builder
      .addCase(updateUser.pending, (state, action) => {
        state.userUpdate.status = 'loading';
      })
      .addCase(updateUser.fulfilled, (state, action) => {
        state.userUpdate.status = 'succeeded';
        state.users[action.payload.userId] = action.payload;
        state.userUpdate.error = null;
      })
      .addCase(updateUser.rejected, (state, action) => {
        state.userUpdate.status = 'failed';
        state.userUpdate.status = action.error.message;
      });
  },
});

export const {
  setUsers, setCurrentUserId, setExternalUserCreateStatus,
  setUserUpdateStatus, setUsersFetchStatus,
  setUserCreateStatus,
} = usersSlice.actions;

export const selectUsers = (state) => state.user.users;
export const selectUsersStatus = (state) => state.user.usersFetch.status;
export const selectUserUpdateStatus = (state) => state.user.userUpdate.status;

export const selectCreateUserStatus = (state) => state.user.userCreate.status;
export const selectCreateExternalUserStatus = (state) => state.user.externalUserCreate.status;
export const selectCurrentUserId = (state) => state.user.currentUser.userId;
export const selectCurrentUser = (state) => state.user.currentUser;

export const selectUsersActive = createSelector(
  [selectUsers],
  (users) => {
    return Object.values(users).filter((u) => u.isActive === true);
  },
);
export const selectUsersAdIdSet = createSelector(
  [selectUsers],
  (users) => {
    return new Set(Object.values(users).map((u) => u.activeDirectoryId));
  },
);

export const selectCurrentUserData = createSelector(
  [selectUsers, selectCurrentUserId],
  (user, currentUserId) => {
    if (currentUserId == null) {
      return {};
    }
    return user[currentUserId];
  },
);

export const selectUsersByOid = createSelector(
  [selectUsers],
  (users) => {
    return Object.values(users ?? []).reduce((current, u) => ({
      ...current,
      [u.activeDirectoryId]: u,
    }), {});
  },
);

export const selectUserRolesNotInUser = createSelector(
  [selectCurrentUserData, selectRoles],
  (user, roles) => {
    const currentRoleIds = (user?.roles ?? []).map((r) => r.roleId);
    return [...Object.values(roles).filter((r) => !currentRoleIds.includes(r.roleId))];
  },
);

export const selectUsersNotInRole = createSelector(
  [selectCurrentRoleData, selectUsersActive],
  (role, users) => {
    const currentUserIds = (role?.users ?? []).filter((u) => u.isActive).map((u) => u.userId);
    const usersNotInRole = [
      ...users.filter((u) => !currentUserIds.includes(u.userId)),
    ];
    usersNotInRole.sort((a, b) => sortByBasic(a.displayName, b.displayName));
    return usersNotInRole;
  },
);

export const selectUsersNotInLocation = createSelector(
  [selectCurrentUserLocation, selectUsersActive],
  (currentUserLocation, users) => {
    return users.filter(
      (u) => !(currentUserLocation.userIds ?? []).includes(u.userId),
    );
  },
);

export const selectCurrentUserLocationUsers = createSelector(
  [selectCurrentUserLocation, selectUsers],
  (currentUserLocation, users) => {
    return (currentUserLocation.userIds ?? []).map((uid) => users[uid]);
  },
);

export default usersSlice.reducer;
