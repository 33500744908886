import axios from "axios";
import { handleRequestError, memoizePromiseFn } from "../../requests";

export const InsertBilling = async (billing) => {
  const results = await axios.post('/DewarRentalBilling/InsertBilling', billing).catch(handleRequestError);
  return results.data;
}


export const GetDewarRentalBillingList = async (queryDefinition) => {
  const results = await axios.post('/DewarRentalBilling/GetDewarRentalBillingList', queryDefinition).catch(handleRequestError);
  return results.data;
}

export const createDewarRentalInvoice = async (invoice) => {
  const results = await axios.post(`/DewarRentalBilling/createDewarRentalInvoice`, invoice).catch(handleRequestError);
  return results.data;
};

export const getDewarRental = async (dewarRental) => {
  const results = await axios.get(`/DewarRentalBilling/getDewarRental/${dewarRental}`).catch(handleRequestError);
  return results.data;
};

export const getDewarRentalsUnbilled = async (def) => {
  const results = await axios.get(`/DewarRentalBilling/GetCurrentDewarRentals`).catch(handleRequestError);
  return results.data;
};

export const insertAllPending = async (pending) => {
  const results = await axios.post(`/DewarRentalBilling/InvoiceAllUnbilled`, pending).catch(handleRequestError);
  return results.data;
};
export const bypassAll = async (pending) => {
  const results = await axios.post(`/DewarRentalBilling/BypassAllUnbilled`, pending).catch(handleRequestError);
  return results.data;
};

export const deleteInvoice = async (invoiceId) => {
  const results = await axios.delete(`/DewarRentalBilling/DeleteInvoice/${invoiceId}`).catch(handleRequestError);
  return results.data;
};
// /PreAuthorizeCreditCard
export const PreAuthorizeCreditCard = async (billingId) => {
  const results = await axios.get(`/DewarRentalBilling/PreAuthorizeCreditCard/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const getBillingHeader = async (billingId) => {
  const results = await axios.get(`/DewarRentalBilling/getBillingHeader/${billingId}`).catch(handleRequestError);
  return results.data;
};

export const reverseBilling = async (reverse) => {
  const results = await axios.post(`/DewarRentalBilling/reverseBilling`, reverse).catch(handleRequestError);
  return results.data;
};

export const uploadInvoicesJDEDTO = async (invoice) => {
  const results = await axios.post(`/DewarRentalBilling/UploadInvoicesToJDE`, invoice).catch(handleRequestError);
  return results.data;
};


export const SendReport = async (report) => {
  const results = await axios.post('/DewarRentalBilling/SendReport', report).catch(handleRequestError);
  return results.data;
};
export const downloadReport = async (report) => {
  const results = await axios.post('/DewarRentalBilling/DownloadReport', report, { responseType: 'blob' }).catch(handleRequestError);

  const url = window.URL.createObjectURL(new Blob([results.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', report.filename);
  document.body.appendChild(link);
  link.click();
};
export const GetJDEErrors = async (billing) => {
  const results = await axios.get('/DewarRentalBilling/GetJDEErrors').catch(handleRequestError);
  return results.data;
}
export const GetUnbilledInvoices = async (billing) => {
  const results = await axios.get('/DewarRentalBilling/GetUnbilledInvoices').catch(handleRequestError);
  return results.data;
}

export const UpdateBilling = async (billing) => {
  const results = await axios.put('/DewarRentalBilling/UpdateBilling', billing).catch(handleRequestError);
  return results.data;
}