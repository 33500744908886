import React, { useMemo } from "react";
import { Nav, Badge } from "react-bootstrap";
import { flatten, getError } from "../../../../services/form-helpers";
import { useSelector } from "react-redux";

export const TabHeaderButton = ({formView, tab }) => {
  const { formState } = formView;
  const { formState: { errors } } = formState;
  const badgeCounter = useSelector(state => tab?.badgeCounter ? tab?.badgeCounter(state) : null);
  const hasErrors = useMemo(() => {
    const allFields = flatten(tab.items).filter(x => x.name);
    return allFields.filter(f => getError(errors, f.name))
  }, [errors, formState])

  return (
    <Nav.Item>
      {hasErrors?.length > 0 && <Badge bg="danger" style={{position: 'absolute', zIndex: 999}}>!</Badge>}
     
      <Nav.Link eventKey={tab.label}><span>{tab.label}</span>{badgeCounter && badgeCounter !== 0 ? <Badge bg="danger" style={{position: 'absolute', zIndex: 999, top: 5}}>{badgeCounter}</Badge> : <></>}</Nav.Link>
    </Nav.Item>
  )
}