import { Column, Row } from "@silevis/reactgrid";
import { sum } from "../../../../services/array-helpers";

export const getColumns = ({ cells }, availableWidth?: number): Column[] => {
  if (!availableWidth) {
    return cells.map(c => ({
      columnId: c.name,
      width: c.width || 150,
      resizable: true
    }));
  }
  const taken = sum(cells.map(c => c.width));
  const freeWidth = (availableWidth - 3) - taken;

  const flexWidth = (w) => {
    const ratio = w / taken;
    return ratio * (availableWidth - 3);
  }
  return cells.map(c => {
    const flex = flexWidth(c.width);
    return ({
      columnId: c.name,
      width: (flex > c.width ? flex : c.width) || 150,
      resizable: true
    })
  });


}


export const headerRow = ({ cells }, { entityRequestArgs }, formState): Row => ({
  rowId: "header",
  cells: cells.map(c => ({
    type: 'header',
    text: typeof c.header == 'function' ? c.header(entityRequestArgs(formState)) : c.header,
  }))
});

export const isReadOnly = (readOnly, record, formState) => {
  if (typeof readOnly == "function") {
    return readOnly(record, formState)
  }
  return readOnly;
}

export const getRows = (records: any[], { cells, selector, totals, disabledEdit }, formState, dataSource, editedCells?: any): any[] => {
  const isLocked = formState.getValues().isLocked;
  const adaptData = (d) => {
    const adapted = d.map((record, idx) => ({
      rowId: idx,
      original: record,
      /*       height: 20, */
      cells: cells.map((c, colIdx) => ({
        type: c.cellType,
        text: c.cellType == 'text' ? record[c.name] : undefined,
        value: c.cellType == 'number' ? (c.compute ? c.compute(record, formState) : record[c.name]) : undefined,
        style: (editedCells && editedCells[idx] && editedCells[idx][c.name]) ? { background: '#ffede2' } : (c.compute ? { background: 'rgb(217,225,242)' } : isReadOnly(c.readOnly, record, formState) ? { background: 'whitesmoke' } : undefined),
        nonEditable: c.compute || isReadOnly(c.readOnly, record, formState) || disabledEdit || isLocked,
        name: c.name
        /*    format: c.cellType == 'number' ? numberFormat : undefined, */
      }))
    }));
    return adapted;
  }

  const selectorVals = adaptData(selector ? selector(records) : records);

  let list = [
    headerRow({ cells }, dataSource, formState),
    ...selectorVals
  ];

  if (totals) {
    const total = (totalList, colName) => {
      const s = sum(totalList, colName);
      return s;
    }
    const currData = records;

    totals.forEach((t, idx) => {
      const vals = t.selector ? adaptData(t.selector(currData)) : selectorVals;

      const flatList = vals.map((current) => {
        const newObj = current.cells.reduce((obj, cell) => {
          obj[cell.name] = cell.value;
          return obj;
        }, {})
        return newObj;
      }, [])

      list.push({
        rowId: records?.length + idx,
        cells: cells.map((c, colIdx) => ({
          type: c.cellType,
          text: colIdx == 0 ? t.header : c.cellType == 'text' ? '' : undefined,
          value: c.cellType == 'number' ? total(flatList, c.name) : undefined,
          style: { background: 'whitesmoke', fontWeight: t.bold ? 'bold' : undefined },
          nonEditable: true,
          colspan: colIdx == 0 ? t.colspan : undefined,
        }))
      })
    })
  }

  return list;
}