export const gasContainerStyles = [
  'Module',
  'Jumbo',
  'Super Jumbo',
  'Small',
  'Multi Tuber',
  'Composite',
];

export const gasContainerBusiness = [
  'Rare Gases',
  'Helium',
  'Diving Gases',
];

export const gasContainerTubeTypes = [
  'DOT',
  'DOT Special Permit',
  'UN',
];

export const getGasOwnerCustomer = (gasCustomers, value) => {
  const notNull = !(value == null);
  if (notNull && gasCustomers[value.toString()]?.CustomerName) {
    return gasCustomers[value.toString()]?.CustomerName;
  }
  if (notNull) {
    return `Unknown ID: ${value}`;
  }
  return null;
};
