import { formatDate } from "../../../../services/format";
import { SendReport , GetDewarRentalBillingList, PreAuthorizeCreditCard, UpdateBilling, deleteInvoice, downloadReport, getBillingHeader, reverseBilling } from "../../../../services/requests/billing/dewar-rental-billing-requests";

import { FormItemType } from "../../../UI/view/component-map";
import { DataSource } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { ListViewColumnType } from "../../../UI/view/list-view";
import { createConnectedView } from "../../../UI/view/view";
import { billingPermissions } from "../billing-datasource";
import { dewarRentalReportLink, footerItems } from "../billing-invoice-edit";

export const dewarrentalinvoicesDS = {
  request: GetDewarRentalBillingList,
  pk: 'billingId',
  name: 'dewarrentalinvoice',
  updateRequest: UpdateBilling,
  entityRequest: getBillingHeader,
  /*   serverSideEvents: true */
} as DataSource;

export const dewarRentalsEdit = {
  editMode: true,
  pageHeader: 'Dewar Rentals Billing',
  permissions: billingPermissions,
  breadcrumbs: [
    {
      name: 'Dewar Rentals Billing',
      icon: 'currency-dollar',
      to: '/billing/dewar#Invoices',
    },
  ],
  items: [
    {
      type: FormItemType.masterDetailLayout,
      items: [
        {
          items: [
            {
              type: FormItemType.infoPane,
              header: ({ dewarrentalinvoice }) => dewarrentalinvoice?.current?.data?.customerName,
              infoListItems: [
    
                {
                  label: 'Customer',
                  name: 'customerName'
                },
                {
                  label: 'CST',
                  name: 'customerId'
                },
                {
                  label: 'CBT',
                  name: 'cbtName'
                },
                {
                  label: 'Invoice Number',
                  name: 'billingId'
                },
                {
                  label: 'JDE Batch Number',
                  name: 'jdebatchNumber'
                },
                {
                  label: 'Date Invoiced',
                  name: 'dateInvoiced',
                  value: ({ dateInvoiced }) => formatDate(dateInvoiced)
                },
                {
                  label: 'Billing Period',
                  name: 'billingPeriod',
                  value: ({ invoiceMonth, invoiceYear }) => `${invoiceMonth}/${invoiceYear}`
                },
    
              ]
            },
            {
              type: FormItemType.fileAttachments,
              entityTypeId: 13,
              entityName: 'Billing Invoice',
            },
          ]
        },
        {
          type: FormItemType.tab,
          items: [
            {
              label: 'Dewar Rentals',
              footerItems: footerItems('Dewar_Rental_Billing_Invoice', dewarRentalReportLink,  PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, SendReport),
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Rental Billing',
                  items: [
                    {
                      type: FormItemType.row,
                      items: [
                        {
                          name: 'ponumber',
                          label: 'PO Number',
                          minWidth: 200,
                          fitToContent: true,
                          defaultValue: ''
                        },
                      ]
                    },
                    {
                      name: 'invoiceComments',
                      label: 'Invoice Notes',
                      type: FormItemType.textarea,
                      defaultValue: ''
                    },
                    {
                      label: 'Bypass Invoice',
                      name: 'jdebypassed',
                      type: FormItemType.checkboxField,
                      width: 140,
                      fitToContent: true,
                      hideIf: ({ watch }) => watch('jdebatchNumber', dewarRentalReportLink,  PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, SendReport)
                    },
                  ]
                }
              ],
            },
            {
              label: 'Invoice Contents', 
              footerItems: footerItems('Dewar_Rental_Billing_Invoice', dewarRentalReportLink, PreAuthorizeCreditCard, deleteInvoice, downloadReport, reverseBilling, SendReport),
              items: [
                {
                  type: FormItemType.fieldset,
                  label: 'Invoice Contents',
                  footerItems: [
                    {
                      type: FormItemType.submitButton,
                      label: 'Update Invoice',
                      variant: 'secondary',
                    }
                  ],
                  items: [
                    {
                      type: FormItemType.editTable,
                      name: 'dewarRentalBillingDetails',
                      variant: 'tabular',
                      pk: 'id',
                      hideAddButton: true,
                      columns: [
                        {
                          type: ListViewColumnType.delete,
                          id: 'deleteme'
                        },
                        {
                          Header: 'Dewar S/N',
                          accessor: 'serialNumber',
                          formItemProps: {
                            name: 'serialNumber',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Product',
                          accessor: 'product',
                          formItemProps: {
                            name: 'product',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },

                        {
                          Header: 'Order #',
                          accessor: 'orderId',
                          formItemProps: {
                            name: 'orderId',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Rent Start Date',
                          accessor: 'startDate',
                          formItemProps: {
                            name: 'startDate',
                            disabled: () => true,
                            type: FormItemType.date,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Rent Stop Date',
                          accessor: 'endDate',
                          formItemProps: {
                            name: 'endDate',
                            disabled: () => true,
                            type: FormItemType.date,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Days Billable',
                          accessor: 'daysBilled',
                          formItemProps: {
                            name: 'daysBilled',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: '$/Day',
                          accessor: 'rentalFeePerDay',
                          formItemProps: {
                            name: 'rentalFeePerDay',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                        {
                          Header: 'Total',
                          accessor: 'value',
                          formItemProps: {
                            name: 'value',
                            disabled: () => true,
                            className: 'readonly'
                          }
                        },
                      ]

                    },
                  ]
                }

              ]
            }
          ]
        }
      ]
    }
  ]
} as FormView;


export const DewarRentalEditInvoice = createConnectedView({ form: dewarRentalsEdit, dataSource: dewarrentalinvoicesDS })


