import React, { useEffect, useMemo, useRef } from 'react';
import {
  Accordion, Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatDate } from '../../../../services/format';
import {
  fetchAssetOrderHistoryLogs, selectPackagedProductOrderHistoryLogs,
  selectPackagedProductTransactionLogs,
} from '../../../../store/slices/asset-order-history-slice';
import { selectLoggedInUserPreferences } from '../../../../store/slices/auth-slice';
import PaginatedSelectTable from '../Table/paginated-select-table';

function PackagedProductHistory({ assetType, serialNumber }) {
  const assetOrderHistoryLogsFetchStatus = useSelector(
    (state) => state.assetOrderHistory.assetOrderHistoryLogsFetch.status,
  );

  const packagedProductOrderHistoryLogs = useSelector(
    selectPackagedProductOrderHistoryLogs,
  );

  const packagedProductTransactionLogs = useSelector(
    selectPackagedProductTransactionLogs,
  );

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchAssetOrderHistoryLogs({ assetType, serialNumber }));
  }, []);

  const orderHistoryIsLoading = assetOrderHistoryLogsFetchStatus === 'loading';

  //   <th>Order</th>
  // <th>Qty</th>
  // <th>Delivery Date</th>
  // <th>Return Date</th>
  // <th>Stop Rent Date</th>
  // <th>Freight</th>
  // <th>Unit Price ($)</th>

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const orderHistoryColumns = useMemo(() => [
    {
      Header: 'Order',
      accessor: 'orderNumber',
    },
    {
      Header: 'Qty',
      accessor: 'quantityDelivered',
    },
    {
      Header: 'Delivery Date',
      accessor: 'deliveryDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'Return Date',
      accessor: 'returnDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'Stop Rent Date',
      accessor: 'stopRentDate',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'Freight',
      accessor: 'freight',
    }, {
      Header: 'Unit Price ($)',
      accessor: 'unitPrice',
    },

  ], [orderHistoryIsLoading]);

  const orderHistortyTableInstance = useRef(null);

  const orderHistoryData = useMemo(() => {
    if (packagedProductOrderHistoryLogs) {
      return packagedProductOrderHistoryLogs;
    }
    return [];
  }, [packagedProductOrderHistoryLogs]);

  const transactionColumns = useMemo(() => [
    {
      Header: 'Date',
      accessor: 'scanDateTime',
      Cell: ({ value }) => formatDate(value, datePreference),
    },
    {
      Header: 'Action',
      accessor: 'scanTypeName',
    },
    {
      Header: 'Lat',
      accessor: 'latitude',
    },
    {
      Header: 'Long',
      accessor: 'longitude',
    },
    {
      Header: 'Order #',
      accessor: 'orderNumber',
    },
    {
      Header: 'Location',
      accessor: 'plantLocationId',
    }, {
      Header: 'Quantity',
      accessor: 'quantities',
    },

  ], [orderHistoryIsLoading]);

  const transactionTableInstance = useRef(null);

  const transactionData = useMemo(() => {
    if (packagedProductTransactionLogs) {
      return packagedProductTransactionLogs;
    }
    return [];
  }, [packagedProductTransactionLogs]);

  return (
    <div className="h-100 d-flex">
      <Spinner animation="border" variant="primary" className={`create-spinner${orderHistoryIsLoading ? ' visible' : ' invisible'}`} />
      <Accordion defaultActiveKey="0" style={{ width: '100%' }}>
        <Accordion.Item eventKey="0">
          <Accordion.Header
            style={{
              paddingLeft: 20, paddingRight: 20, paddingBottom: 16, paddingTop: 16,
            }}
          >
            Order History
          </Accordion.Header>
          <Accordion.Body>
            <div className={`d-flex flexbox flex-column overflow-auto flex-grow-1 h-100${orderHistoryIsLoading ? ' creation-loading' : ''}`}>
              <PaginatedSelectTable
                columns={orderHistoryColumns}
                data={orderHistoryData}
                ref={orderHistortyTableInstance}
                rowProps={() => ({})}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header
            style={{
              paddingLeft: 20, paddingRight: 20, paddingBottom: 16, paddingTop: 16,
            }}
          >
            Transaction History
          </Accordion.Header>
          <Accordion.Body>
            <div className={`d-flex flexbox flex-column overflow-auto flex-grow-1 h-100${orderHistoryIsLoading ? ' creation-loading' : ''}`}>
              <PaginatedSelectTable
                columns={transactionColumns}
                data={transactionData}
                ref={transactionTableInstance}
                rowProps={() => ({})}
              />
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
}

export default PackagedProductHistory;
