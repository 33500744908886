import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import {
  createAttachmentRequest,
  deleteAttachment,
  getAssetAttachment,
  getAttachment,
  getAttachmentsBulk,
  getEntityAttachments,
  getFileAttachmentDropdownValues,
} from '../../services/requests';

const initialState = {
  assetAttachmentFetch: {
    status: 'idle',
    error: null,
  },
  dropdownValues: {},
  dropdownValuesFetch: {
    status: 'idle',
    error: null,
  },
  entityAttachments: {},
  entityAttachmentsFetch: {
    status: 'idle',
    error: null,
  },
  attachmentUpload: {
    status: 'idle',
    error: null,
  },
  attachmentDownload: {
    status: 'idle',
    error: null,
  },
  attachmentDelete: {
    status: 'idle',
    error: null,
  },
  attachmentBulkDownload: {
    status: 'idle',
    error: null,
  },
};

export const fetchAssetAttachment = createAsyncThunk(
  'attachment/fetchAssetAttachment',
  async (obj) => {
    const response = await getAssetAttachment(obj.attachmentId, obj.fileName);
    return response;
  },
);

export const fetchFileAttachmentDropdownValues = createAsyncThunk(
  'attachment/fetchFileAttachmentDropdownValues',
  async (obj) => {
    const response = await getFileAttachmentDropdownValues();
    return response;
  },
);

export const createAttachment = createAsyncThunk(
  'attachment/createAttachment',
  async (attachment) => {
    const response = await createAttachmentRequest(attachment);
    return response;
  },
);

export const fetchEntityAttachments = createAsyncThunk(
  'attachment/fetchEntityAttachments',
  async (obj) => {
    const response = await getEntityAttachments(obj.entityTypeId, obj.entityPK);
    return response;
  },
);

export const deleteAttachmentAction = createAsyncThunk(
  'attachment/deleteAttachmentAction',
  async (obj) => {
    const response = await deleteAttachment(obj.attachmentId);
    return response;
  },
);

export const fetchAttachment = createAsyncThunk('attachment/fetchAttachment', async (obj) => {
  const response = await getAttachment(obj.attachmentId, obj.fileName);
  return response;
});

export const fetchAttachmentBulk = createAsyncThunk(
  'attachment/fetchAttachmentBulk',
  async (obj) => {
    const response = await getAttachmentsBulk(obj.entityTypeId, obj.entityPK, obj.archiveName);
    return response;
  },
);

export const fileAttachmentSlice = createSlice({
  name: 'attachment',
  initialState,
  reducers: {
    setAttachmentUploadStatus: (state, action) => {
      state.attachmentUpload.status = action.payload;
    },
    setAttachmentDeleteStatus: (state, action) => {
      state.attachmentDelete.status = action.payload;
    },
    setEntityAttachmentsFetchStatus: (state, action) => {
      state.entityAttachmentsFetch.status = action.payload;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(fetchAssetAttachment.pending, (state, action) => {
        state.assetAttachmentFetch.status = 'loading';
      })
      .addCase(fetchAssetAttachment.fulfilled, (state, action) => {
        state.assetAttachmentFetch.status = 'succeeded';
      })
      .addCase(fetchAssetAttachment.rejected, (state, action) => {
        state.assetAttachmentFetch.status = 'failed';
      });

    builder
      .addCase(fetchFileAttachmentDropdownValues.pending, (state, action) => {
        state.dropdownValuesFetch.status = 'loading';
      })
      .addCase(fetchFileAttachmentDropdownValues.fulfilled, (state, action) => {
        state.dropdownValuesFetch.status = 'succeeded';
        const generalType = action.payload.attachmentTypes.find((at) => at.name === 'General');
        state.dropdownValues = {
          ...action.payload.entityTypes.reduce((current, et) => {
            return {
              ...current,
              [et.name]: {
                details: et,
                types: [
                  generalType,
                  ...action.payload.attachmentTypes.filter(
                    (at) => at.entityTypeId && at.entityTypeId.toString() === et.id,
                  ),
                ],
              },
            };
          }, {}),
        };
      })
      .addCase(fetchFileAttachmentDropdownValues.rejected, (state, action) => {
        state.dropdownValuesFetch.status = 'failed';
      });

    builder
      .addCase(createAttachment.pending, (state, action) => {
        state.attachmentUpload.status = 'loading';
      })
      .addCase(createAttachment.fulfilled, (state, action) => {
        state.attachmentUpload.status = 'succeeded';
        state.entityAttachments[action.payload.attachmentId] = action.payload;
      })
      .addCase(createAttachment.rejected, (state, action) => {
        state.attachmentUpload.status = 'failed';
      });

    builder
      .addCase(fetchAttachment.pending, (state, action) => {
        state.attachmentDownload.status = 'loading';
      })
      .addCase(fetchAttachment.fulfilled, (state, action) => {
        state.attachmentDownload.status = 'succeeded';
      })
      .addCase(fetchAttachment.rejected, (state, action) => {
        state.attachmentDownload.status = 'failed';
      });

    builder
      .addCase(fetchAttachmentBulk.pending, (state, action) => {
        state.attachmentBulkDownload.status = 'loading';
      })
      .addCase(fetchAttachmentBulk.fulfilled, (state, action) => {
        state.attachmentBulkDownload.status = 'succeeded';
      })
      .addCase(fetchAttachmentBulk.rejected, (state, action) => {
        state.attachmentBulkDownload.status = 'failed';
      });

    builder
      .addCase(fetchEntityAttachments.pending, (state, action) => {
        state.entityAttachmentsFetch.status = 'loading';
      })
      .addCase(fetchEntityAttachments.fulfilled, (state, action) => {
        state.entityAttachmentsFetch.status = 'succeeded';
        state.entityAttachments = action.payload.reduce(
          (current, a) => ({ ...current, [a.attachmentId]: a }),
          {},
        );
      })
      .addCase(fetchEntityAttachments.rejected, (state, action) => {
        state.entityAttachmentsFetch.status = 'failed';
      });

    builder
      .addCase(deleteAttachmentAction.pending, (state, action) => {
        state.attachmentDelete.status = 'loading';
      })
      .addCase(deleteAttachmentAction.fulfilled, (state, action) => {
        state.attachmentDelete.status = 'succeeded';
        delete state.entityAttachments[action.meta.arg.attachmentId];
      })
      .addCase(deleteAttachmentAction.rejected, (state, action) => {
        state.attachmentDelete.status = 'failed';
      });
  },
});

export const {
  setAttachmentUploadStatus,
  setAttachmentDeleteStatus,
  setEntityAttachmentsFetchStatus,
} = fileAttachmentSlice.actions;

export const selectAttachmentDropdownValues = (state) => state.attachment.dropdownValues;

export const selectEntityAttachmentsFetchStatus = (state) => state.attachment
  .entityAttachmentsFetch.status;

export const selectAttachmentDownloadStatus = (state) => state.attachment.attachmentDownload.status;

export const selectAttachmentBulkDownloadStatus = (state) => state.attachment
  .attachmentBulkDownload.status;

export const selectAttachmentDeleteStatus = (state) => state.attachment.attachmentDelete.status;

export const selectAttachmentUploadStatus = (state) => state.attachment.attachmentUpload.status;

export const selectEntityAttachments = (state) => state.attachment.entityAttachments;

export default fileAttachmentSlice.reducer;
