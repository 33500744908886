import { america } from "./shipments-america";

export const emeaBase = {
  ...america, selector: (data) => data.filter(d => d.segment == 'EMEA' && !d.isOriginal), totals: [
    {
      header: 'AFRICA',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'EMEA' && d.grouping == 'AFRICA' && !d.isOriginal)
    },
    {
      header: 'REN',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'EMEA' && d.grouping == 'REN' && !d.isOriginal)
    },
    {
      header: 'REW',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'EMEA' && d.grouping == 'REW' && !d.isOriginal)
    },
    {
      header: 'RME',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'EMEA' && d.grouping == 'RME' && !d.isOriginal)
    },
    {
      header: 'RUK',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'EMEA' && d.grouping == 'RUK' && !d.isOriginal)
    },
    {
      header: 'Adjustment (+-)',
      colspan: 4,
      selector: (data) => {
        const og = data.filter(d => d.segment == 'EMEA' && d.isOriginal);
        const current = data.filter(d => d.segment == 'EMEA' && !d.isOriginal);
        return current.map(curr => {
          const ogRecord = og.find(a => a.shipmentId == curr.shipmentId);
          return {
            ...curr,
            isOriginal: false,
            janCurrentYear: (curr.janCurrentYear ?? 0) - (ogRecord?.janCurrentYear ?? 0),
            febCurrentYear: (curr.febCurrentYear ?? 0) - (ogRecord?.febCurrentYear ?? 0),
            marCurrentYear: (curr.marCurrentYear ?? 0) - (ogRecord?.marCurrentYear ?? 0),
            aprCurrentYear: (curr.aprCurrentYear ?? 0) - (ogRecord?.aprCurrentYear ?? 0),
            mayCurrentYear: (curr.mayCurrentYear ?? 0) - (ogRecord?.mayCurrentYear ?? 0),
            junCurrentYear: (curr.junCurrentYear ?? 0) - (ogRecord?.junCurrentYear ?? 0),
            julyCurrentYear: (curr.julyCurrentYear ?? 0) - (ogRecord?.julyCurrentYear ?? 0),
            augCurrentYear: (curr.augCurrentYear ?? 0) - (ogRecord?.augCurrentYear ?? 0),
            septCurrentYear: (curr.septCurrentYear ?? 0) - (ogRecord?.septCurrentYear ?? 0),
            octCurrentYear: (curr.octCurrentYear ?? 0) - (ogRecord?.octCurrentYear ?? 0),
            novCurrentYear: (curr.novCurrentYear ?? 0) - (ogRecord?.novCurrentYear ?? 0),
            decCurrentYear: (curr.decCurrentYear ?? 0) - (ogRecord?.decCurrentYear ?? 0)
          }
        })
      }
    },
    {
      header: 'EMEA Total',
      colspan: 4,
      selector: (data) => data.filter(d => d.segment == 'EMEA' && !d.isOriginal),
      bold: true
    }
  ],
};


/* export const emeaAdjustment = { ...emeaBase, selector: (data) => data.filter(d => d.segment == 'EMEA')}; */

export const emeaTotal = { ...america, disabledEdit: true, selector: (data) => {
  const base = data.filter(d => d.segment == 'EMEA');
  const total = base.map(bRecord => {
    return {
      ...bRecord,
      janCurrentYear: (bRecord.janCurrentYear ?? 0),
      febCurrentYear: (bRecord.febCurrentYear ?? 0),
      marCurrentYear: (bRecord.marCurrentYear ?? 0),
      aprCurrentYear: (bRecord.aprCurrentYear ?? 0),
      mayCurrentYear: (bRecord.mayCurrentYear ?? 0),
      junCurrentYear: (bRecord.junCurrentYear ?? 0),
      julyCurrentYear: (bRecord.julyCurrentYear ?? 0),
      augCurrentYear: (bRecord.augCurrentYear ?? 0),
      septCurrentYear: (bRecord.septCurrentYear ?? 0),
      octCurrentYear: (bRecord.octCurrentYear ?? 0),
      novCurrentYear: (bRecord.novCurrentYear ?? 0),
      decCurrentYear: (bRecord.decCurrentYear ?? 0)
    }
  });
  return total;
} };