import { createConnectedListView, ListView, ListViewColumnType } from '../../UI/view/list-view';
import { getCustomerLiquidContainerHistory } from '../../../services/requests';
import { DataSource } from '../../UI/view/data-source';

export const liquidHistoryListView = {
  columns: [
    {
      Header: 'ContainerID',
      accessor: 'containerID',
      type: ListViewColumnType.link,
      linkPath: ({ val, row }) => '/assets/liquid-container/' + row.original.containerID
    },
    {
      Header: 'Trk #',
      accessor: 'trackingNumberID',
      type: ListViewColumnType.link,
      linkPath:  ({ val, row }) => `https://vts.linde.grp/Helium/VTS/Liquid/Shipping.aspx?gtrkno=${row.original.trackingNumberID}`,
    },
    {
      Header: 'Location',
      accessor: 'locationID'
    },
    {
      Header: 'Arrival',
      accessor: 'arriveDate',
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Fill',
      accessor: 'fillDate',
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Ship',
      accessor: 'shipDate',
      defaultSort: true,
      defaultSortDirection: 'desc',
      type: ListViewColumnType.datetime
    },
  ],
} as ListView;

export const liquidHistoryDataSource = {
  request: getCustomerLiquidContainerHistory,
  requestArgs: (state) => {
    return state.liquidcustomers.current.data.customerId;
  },
  name: 'liquidHistory',
  pk: 'trackingNumberID'
} as DataSource

export const CustomerLiquidHistoryListViewComponent = createConnectedListView(liquidHistoryListView, liquidHistoryDataSource);