import React from "react";
import Callout from '../../../UI/atoms/Callout/callout';
import { ListGroup, Placeholder } from "react-bootstrap";
import InfoListItem from '../../../UI/atoms/InfoListItem/info-list-item';
import { useSelector } from "react-redux";
import { Scrollbars } from 'react-custom-scrollbars-2';

export interface InfoItem {
  label: string
  value: string
}
export interface InfoField {
  label: string
  name: string
  value?: (entity: any) => any
}
export interface EntityInfoPaneProps {
  isLoading?: boolean,
  infoListSelector?: (state: any) => InfoItem[],
  infoListItems?: InfoField[],
  header?: (state: any) => string,
  subHeader?: (state: any) => string,
  entity?: any,
  flexHeight?: boolean
}
export const EntityInfoPane = (props: any) => {

  const { entityLoading: isLoading, field: { infoListItems, infoListSelector, flexHeight, subHeader, header }, entity, getValues, watch } = props;
  const infoListItemsEval = useSelector(infoListSelector ? infoListSelector : (state) => []);
  const headerDisplay = useSelector(header ? header : (state) => '') as any;
  const subHeaderDisplay = useSelector(subHeader ? subHeader : (state) => '') as any;

  const values = infoListItems && entity ? infoListItems.map(field => ({
    label: field.label,
    value: field.value ? field.value(entity, watch) : entity[field.name]
  })) : null;


  return (
    <Callout className={`${isLoading ? ' creation-loading' : ''}`}>
      <>
        {header && <h4>{!isLoading ? headerDisplay : (<Placeholder animation="glow"><Placeholder xs={6} /></Placeholder>)}</h4>}
        {subHeader && <p><i>{!isLoading ? subHeaderDisplay : (<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)}</i></p>}
      </>
      {!isLoading
        ? (
          <Scrollbars
            autoHide
            autoHeight
            autoHeightMin={0}
            autoHeightMax={flexHeight ? 1000 : 300}
            style={{ width: '100%' }}
          >
            <ListGroup variant="unbordered">
              {(values || infoListItemsEval).map(item => (<ListGroup.Item><InfoListItem name={item.label} value={item.value !== null ? item.value : 'N/A'} /></ListGroup.Item>))}
            </ListGroup>
          </Scrollbars>
        )
        : (
          <ListGroup variant="flush">
            <ListGroup.Item><InfoListItem value={(<Placeholder animation="glow"><Placeholder xs={12} /></Placeholder>)} /></ListGroup.Item>
          </ListGroup>
        )}
    </Callout>
  )
}