// import { createSelector } from 'reselect';
import { InteractionRequiredAuthError } from '@azure/msal-browser';
import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import { createSelector } from 'reselect';

import { protectedResources } from '../../authConfig';
import {
  permissionEntities,
  permissionTypes,
} from '../../services/access-control';
import { selectAllLocationObjectsWithUsers, selectRoles } from './roles-slice';
import { selectUsers } from './users-slice';

// define initial state
const initialState = {
  account: null,
  token: false,
  userProfile: {
    userId: null,
    userIdNotFound: false,
  },
};

// create slice
export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setAccount: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.account = action.payload;
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    signOut: (state) => {
      state.token = null;
      state.account = null;
    },
    setLoggedInUserId: (state, action) => {
      state.userProfile.userId = action.payload;
      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        'app-user-id': action.payload,
      };
    },
    setUserIdNotFound: (state, action) => {
      state.userProfile.userIdNotFound = action.payload;
    },
  },
  // extraReducers(builder) {
  //   builder
  //     .addCase(getLoggedInUser.pending, (state, action) => {
  //       state.userProfile.status = "loading";
  //     })
  //     .addCase(getLoggedInUser.fulfilled, (state, action) => {
  //       state.userProfile.status = "succeeded";
  //       state.userProfile.profileInfo = action.payload;
  //       state.userProfile.error = null;
  //     })
  //     .addCase(getLoggedInUser.rejected, (state, action) => {
  //       state.userProfile.status = "failed";
  //       state.userProfile.error = action.error.message;
  //     });
  // },
});

// Action creators are generated for each case reducer function
export const {
  setAccount, setToken, signOut, setLoggedInUserId, setUserIdNotFound,
} = authSlice.actions;

export const setTokenAsync = (instance) => async (dispatch, getState) => {
  const { account } = getState().auth;
  const tokenRequest = {
    account,
    scopes: protectedResources.appServiceApi.scopes,
  };

  const response = await instance
    .acquireTokenSilent(tokenRequest)
    .catch(async (e) => {
      // Catch interaction_required errors and call interactive method to resolve
      if (e instanceof InteractionRequiredAuthError) {
        await instance.acquireTokenRedirect(tokenRequest);
      }
      throw e;
    });

  axios.defaults.headers.common = {
    Authorization: `Bearer ${response.accessToken}`,
  };

  dispatch(setToken(true));
};

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectAccount = (state) => state.auth.account;
export const selectToken = (state) => state.auth.token;
export const selectCurrentUserProfileId = (state) => state.auth?.userProfile?.userId;

export const selectCurrentUserId = (state) => state.auth.account?.localAccountId;

export const selectLoggedInUserInfo = createSelector(
  [selectCurrentUserId, selectUsers],
  (userId, users) => {
    if (!userId || Object.keys(users).length === 0) {
      return {};
    }
    return Object.values(users).find((user) => user.activeDirectoryId === userId) ?? {};
  },
);

export const selectLoggedInUserLocations = createSelector(
  [selectAllLocationObjectsWithUsers, selectLoggedInUserInfo],
  (locations, userInfo) => {
    const userLocations = Object.values(locations).filter(
      (location) => location.userIds && location.userIds.includes(userInfo.userId),
    );

    return userLocations.sort().reverse();
  },
);

export const selectAllRepairLocations = createSelector(
  [selectLoggedInUserLocations],
  (locations) => (locations ?? []).filter((location) => location.IsRepairShop === 'True'),
);

export const selectAllActiveUserRepairLocationObjects = createSelector(
  [selectAllRepairLocations],
  (locations) => locations.reduce(
    (current, l) => ({ ...current, [l.LocationID]: l }),
    {},
  ),
);

export const selectLoggedInUserActiveLocation = createSelector(
  [selectAllLocationObjectsWithUsers, selectLoggedInUserInfo],
  (locations, userInfo) => {
    return locations[userInfo.locationPreference] ?? {};
  },
);

export const selectIsAdmin = createSelector(
  [selectRoles, selectCurrentUserProfileId],
  (roles, userId) => {
    const adminRole = Object.values(roles).find((role) => role.name === 'Application Admin');
    const found = adminRole?.users?.find((user) => {
      return user?.userId === userId;
    });

    return !!found;
  },
);

export const selectIsDev = createSelector(
  [selectRoles, selectCurrentUserProfileId],
  (roles, userId) => {
    const adminRole = Object.values(roles).find((role) => role.name === 'Developer');
    const found = adminRole?.users?.find((user) => {
      return user?.userId === userId;
    });

    return !!found;
  },
);

export const selectIsAssetAdmin = createSelector(
  [selectRoles, selectCurrentUserProfileId],
  (roles, userId) => {
    const adminRole = Object.values(roles).find((role) => role.name === 'Application Admin');
    const maintManagerRole = Object.values(roles).find((role) => role.name === 'Maintenance Manager');
    const found = adminRole?.users?.find((user) => {
      return user?.userId === userId;
    }) || maintManagerRole?.users?.find((user) => {
      return user?.userId === userId;
    });

    return !!found;
  },
);

export const selectIsMaintenanceManager = createSelector(
  [selectRoles, selectCurrentUserProfileId],
  (roles, userId) => {
    const maintManagerRole = Object.values(roles).find((role) => role.name === 'Maintenance Manager');
    const found = maintManagerRole?.users?.find((user) => {
      return user?.userId === userId;
    });

    return !!found;
  },
);

export const selectCurentUserRoles = createSelector(
  [selectRoles, selectCurrentUserProfileId],
  (roles, userId) => {
    console.log('roles', roles);
    console.log('selectCurrentUserProfileId', userId);
    const rolesList = Object.values(roles);
    const currRoles = [];
    rolesList.forEach((role) => {
      const inRole = role.users?.find((user) => {
        return user?.userId === userId;
      });
      if (inRole) {
        currRoles.push(role);
      }
    });
    return currRoles;
  },
);

export const selectUserAccountAndProfileIsLoaded = createSelector(
  [selectAccount, selectUsers],
  (userId, users) => {
    if (!userId || Object.keys(users).length === 0) {
      return {};
    }
    return userId in users ? users[userId] : {};
  },
);

export const selectLoggedInUserPreferences = createSelector(
  [selectLoggedInUserInfo],
  (userInfo) => ({
    datePreference: userInfo?.datePreference ?? 0,
    measurementPreference: userInfo?.measurementPreference ?? 0,
    numberPreference: userInfo?.numberPreference ?? 0,
  }),
);

export const selectCurrentUserRoleIds = createSelector(
  [selectCurrentUserId, selectUsers],
  (userId, users) => {
    if (!userId || Object.keys(users).length === 0) {
      return [];
    }
    return users[userId].roles.map((r) => r.roleId);
  },
);

export const selectCurrentUserPermissions = createSelector(
  [selectCurrentUserRoleIds, selectRoles],
  (roleIds, roles) => {
    if (Object.keys(roles).length === 0 || roles.length === 0) {
      return [];
    }
    return roleIds
      .map((roleId) => roles[roleId])
      .map((r) => r.permissions)
      .flat()
      .map((p) => ({
        ...p,
        entity: permissionEntities[p.entity],
        type: permissionTypes[p.type],
      }));
  },
);

// export const getLoggedInUser = createAsyncThunk(
//   "auth/userProfile",
//   async (_, { getState }) => {
//     const state = getState();

//     const response = await getUserByAdId(state.auth.account.localAccountId);
//     return response;
//   }
// );

export default authSlice.reducer;
