import { getDewarPricing } from "../../../services/requests";
import { DataSource } from "../../UI/view/data-source";

export const packagedProductPricingDataSource = {
  request: getDewarPricing,
  requestArgs: (state) => {
    const site = state.dewarcustomers.current.data.site;
    return site;
  },
  pk: 'itemCode',
  name: 'dewarpricing'
} as DataSource;