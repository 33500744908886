import { ListView, createConnectedListView } from "../../UI/view/list-view";
import { packagedProductPricingDataSource } from "./packaged-product-pricing-ds";

export const pricingTable = {
  hideGlobalFilter: true,
  columns: [
    {
      Header: 'Item',
      accessor: 'itemCode',
      defaultSort: true,
    },
    {
      Header: 'Our Truck',
      accessor: 'ourTruckPrice'
    },
    {
      Header: 'Customer Pickup',
      accessor: 'customerPickupPrice'
    },
    {
      Header: 'Common Carrier',
      accessor: 'commonCarrierPrice'
    },
    {
      Header: 'RU Fee',
      accessor: 'ruFee'
    },
    {
      Header: 'Rent/Day',
      accessor: 'rentFee'
    },
/*     {
      Header: 'Item',
      accessor: 'itemCode'
    },
    {
      Header: 'Item',
      accessor: 'itemCode'
    }, */
  ]
} as ListView

export const PricingTableView = createConnectedListView(pricingTable, packagedProductPricingDataSource);