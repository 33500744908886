import { formatCurrency } from "../../../../services/format";
import { FormItemType } from "../../../UI/view/component-map";
import { Repository } from "../../../UI/view/data-source";
import { FormView } from "../../../UI/view/form-view";
import { ListView, createConnectedListView, createListView } from "../../../UI/view/list-view";
import { createConnectedView, createConnectedView2 } from "../../../UI/view/view";
import { dewarPricingDataSource } from "./dewar-datasource";


export const dewarPricing = {
  editMode: false,
  items: [
    {
      type: FormItemType.fieldset,
      label: 'Advanced Dewar Pricing',
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              type: FormItemType.label,
              label: 'Enter CST',
              fitToContent: true,
            },
            {
              name: 'cst',
              width: 200
            },
            {
              type: FormItemType.button,
              label: 'Display All Pricing',
              handler: ({ methods}) => {
                methods.loadEntity();
              }
            }
          ]
        },
        {
          type: FormItemType.customField,
          name: 'dewarPricing',
          label: 'Pricing',
          getComponent: () => DewarPricingTable
        }
      ]
    }
  ]
} as FormView;

export const [DewarPricingForm, DewarPricingFormDS]  = createConnectedView2({ form: dewarPricing, dataSource: dewarPricingDataSource });


/* "customerId": "92398176",
"cbt": null,
"csTaccountName": null,
"itemCode": "HE6.0MR-T",
"ruFee": 0.0,
"rentFee": 0.0,
"ourTruckPrice": 0.0,
"commonCarrierPrice": 0.0,
"customerPickupPrice": 0.0,
"rentDates": " - ",
"ruDates": " - ",
"ourTruckDates": " - ",
"commonCarrierDates": " - ",
"customerPickupDates": " - " */
export const dewarPricingTable = {
  hideGlobalFilter: true,
  columns: [
    {
      accessor: 'itemCode',
      Header: 'Item',
      defaultSort: true,
    },
    {
      accessor: 'ourTruckPrice',
      Header: 'Our Truck',
      tooltip: ({ ourTruckDates }) => ourTruckDates,
      Cell: ({ row: { original: { ourTruckPrice } } }) => formatCurrency(ourTruckPrice),
    },
    {
      accessor: 'customerPickupPrice',
      Header: 'Customer Pickup',
      tooltip: ({ customerPickupDates }) => customerPickupDates,
      Cell: ({ row: { original: { customerPickupPrice } } }) => formatCurrency(customerPickupPrice),
    },
    {
      accessor: 'commonCarrierPrice',
      Header: 'Common Carrier',
      tooltip: ({ commonCarrierDates }) => commonCarrierDates,
      Cell: ({ row: { original: { commonCarrierPrice } } }) => formatCurrency(commonCarrierPrice),
    },
    {
      accessor: 'ruFee',
      Header: 'RU Fee',
      tooltip: ({ ruDates }) => ruDates,
      Cell: ({ row: { original: { ruFee } } }) => formatCurrency(ruFee),
    },
    {
      accessor: 'rentFee',
      Header: 'Rent/Day',
      tooltip: ({rentDates}) => {
        return rentDates;
      },
      Cell: ({ row: { original: { rentFee } } }) => formatCurrency(rentFee),
    },
    {
      accessor: 'rentDates',
      hidden: true
    },
    {
      accessor: 'ruDates',
      hidden: true
    },
    {
      accessor: 'ourTruckDates',
      hidden: true
    },
    {
      accessor: 'commonCarrierDates',
      hidden: true
    },
    {
      accessor: 'customerPickupDates',
      hidden: true
    },
  ]
} as ListView;

export const DewarPricingTable = createListView(dewarPricingTable, DewarPricingFormDS as Repository, state => state.dewarpricing?.current?.data?.dewarPricing || []);
