import { CreateSnapshot, exportDemandForecasting } from "../../../services/requests";
import { getColumns, getRows } from "../../UI/view/FormItems/SpreadsheetUtility";
import { FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { createConnectedView } from "../../UI/view/view";
import { GroupingsListView } from "./groupings-list";
import { america, americaBase, americaTotal } from "./shipments-america";
import { apacBase, apacTotal } from "./shipments-apac";
import { forecastingPermissions, liqShipmentsDataSource } from "./shipments-datasource";
import { emeaBase, emeaTotal } from "./shipments-emea";
import {formatDateTime} from "../../../services/format";

export const toggleLock = async (formState) => {
  const isLocked = formState.getValues().isLocked;
  formState.setValue('isLocked', !isLocked);
  formState.methods.handleSubmit(formState.methods.onSubmit)();
}

export const exportSpreadsheet = async (formState) => {
  const shipments = formState.getValues().liquidShipments;
  const americaBaseDataRows = getRows(shipments, americaBase as any, formState, liqShipmentsDataSource);
  const emeaBaseDataRows = getRows(shipments, emeaBase as any, formState, liqShipmentsDataSource);
  const apacBaseDataRows = getRows(shipments, apacBase as any, formState, liqShipmentsDataSource);
  const columns = getColumns({cells: america.cells});

  const exportTab = (label, baseSet) => ({
    label: label,
    rows: [
      {
        cells: baseSet[0].cells.map(c => ({ text: c.text, type: 'text', color: 'white', backgroundColor: 'black', width: Math.floor(columns.find(x => x.columnId == c.name)?.width * .15) }))
      },
      {
        cells: [
          {text: 'Forecasted Needs', backgroundColor: 'yellow', color: 'red', type: 'text'},
          ...Array(20).fill('X').map(x => ({text: '', type: 'text', backgroundColor: 'yellow'}))
        ]
      },
      ...baseSet.filter((row, idx) => idx != 0).map(row => ({cells: row.cells.map(c => ({
        text: c.type == 'number' ? undefined : c.text, 
        value: c.type == 'number' ? c.value : undefined, 
        type: c.type,
      }))})),
    ]
  });

  const exported = await exportDemandForecasting({
    fileName: 'GlobalDemandForecasting.xlsx',
    tabs: [
      exportTab('Americas Need (Loads)', americaBaseDataRows),
      exportTab('APAC Need (Loads)', apacBaseDataRows),
      exportTab('EMEA Need (Loads)', emeaBaseDataRows),
    ]
  }, 'GlobalDemandForecasting.xlsx');
  return exported;
}

export const ShipmnentsSheet = {
  editMode: true,
  permissions: forecastingPermissions,
  /*  editMode: true, */
  pageHeader: 'He Project Management Needs Forecasting',
  breadcrumbs: [
    {
      name: 'Needs Forecasting',
      icon: 'people',
      to: '/globaldemand',
    },
  ],
  items: [
    {
      type: FormItemType.tab,
      items: [
        {
          label: 'Americas Need (Loads)',
          disableScroll: true,
          footerItems: [
            {
              type: FormItemType.submitButton
            }
          ],
          items: [
            {
              type: FormItemType.row,
              items: [
                { type: FormItemType.select, style: { marginLeft: 6 }, defaultValue: (new Date().getFullYear()).toString(), name: 'year', options: [{ text: '2023', id: '2023' }, { text: '2024', id: '2024' }, { text: '2025', id: '2025' }], fitToContent: true },
/*                 { type: FormItemType.select, defaultValue: 'base', name: 'type', options: [{ text: 'Base Needs', id: 'base' }, { text: 'Incremental Demand', id: 'incremental' }, { text: 'Total', id: 'total' }], fitToContent: true }, */
                {
                  type: FormItemType.button, label: 'Export', handler: exportSpreadsheet, fitToContent: true 
                },
                {
                  type: FormItemType.button, hideIf: ({formState}) => formState.getValues().isLocked, label: 'Lock', handler: toggleLock, fitToContent: true 
                },
                {
                  type: FormItemType.button, hideIf: ({formState}) => !formState.getValues().isLocked, label: 'Unlock', handler: toggleLock, fitToContent: true 
                },
                {
                  name: 'snapshot',
                  type: FormItemType.select,
                  defaultValue: null,
                  fitToContent: true,
                  options: (state: any) => {
                    return [{id: null, text: 'Snapshot'}, ...(state.liquidCustomerShipments?.current?.data?.snapshots || []).map(l => ({id: l.snapshotId, text: formatDateTime(l.timestamp)}))]
                  }
                },
                {
                  type: FormItemType.button,
                  label: 'Create Snapshot',
                  handler: async (formState) => {
                    await CreateSnapshot(formState.getValues().year);
                    formState.methods.loadEntity();
                    //call create snapshot endpoint
                    //call loadEntity which should now be contained within formState
                  },
                  hideIf: ({watch}) => watch('snapshot'),
                },
              ]
            },
            {
              type: FormItemType.fieldset,
              allowScroll: true,
              allowGrow: true,
              label: 'Forecasted Needs',
              items: [
                americaBase
              ]
            },
          ]
        },
        {
          label: 'APAC Need (Loads)',
          disableScroll: true,
          footerItems: [
            {
              type: FormItemType.submitButton
            }
          ],
          items: [
            {
              type: FormItemType.row,
              items: [
                { type: FormItemType.select, style: { marginLeft: 6 }, defaultValue: (new Date().getFullYear()).toString(), name: 'year', options: [{ text: '2023', id: '2023' }, { text: '2024', id: '2024' }, { text: '2025', id: '2025' }], fitToContent: true },
/*                 { type: FormItemType.select, defaultValue: 'base', name: 'type', options: [{ text: 'Base Needs', id: 'base' }, { text: 'Incremental Demand', id: 'incremental' }, { text: 'Total', id: 'total' }], fitToContent: true }, */
                {
                  type: FormItemType.button, label: 'Export', handler: exportSpreadsheet, fitToContent: true 
                },
                {
                  type: FormItemType.button, hideIf: ({formState}) => formState.getValues().isLocked, label: 'Lock', handler: toggleLock, fitToContent: true 
                },
                {
                  type: FormItemType.button, hideIf: ({formState}) => !formState.getValues().isLocked, label: 'Unlock', handler: toggleLock, fitToContent: true 
                },
                {
                  name: 'snapshot',
                  type: FormItemType.select,
                  defaultValue: null,
                  fitToContent: true,
                  options: (state: any) => {
                    return [{id: null, text: 'Snapshot'}, ...(state.liquidCustomerShipments?.current?.data?.snapshots || []).map(l => ({id: l.snapshotId, text: formatDateTime(l.timestamp)}))]
                  }
                },
                {
                  type: FormItemType.button,
                  label: 'Create Snapshot',
                  handler: async (formState) => {
                    await CreateSnapshot(formState.getValues().year);
                    formState.methods.loadEntity();
                  },
                  hideIf: ({watch}) => watch('snapshot'),
                },
              ]
            },
            {
              type: FormItemType.fieldset,
              allowScroll: true,
              allowGrow: true,
/*               hide: ({ watch }) => {
                return watch('type') != 'base';
              }, */
              label: 'Forecasted Needs',
              items: [
                apacBase
              ]
            },
/*             {
              type: FormItemType.fieldset,
              allowScroll: true,
              allowGrow: true,
              hide: ({ watch }) => {
                return watch('type') != 'total';
              },
              label: 'Total',
              items: [
                apacTotal
              ]
            }, */

          ]
        },
        {
          label: 'EMEA Need (Loads)',
          disableScroll: true,
          footerItems: [
            {
              type: FormItemType.submitButton
            }
          ],
          items: [
            {
              type: FormItemType.row,
              items: [
                { type: FormItemType.select, style: { marginLeft: 6 }, defaultValue: (new Date().getFullYear()).toString(), name: 'year', options: [{ text: '2023', id: '2023' }, { text: '2024', id: '2024' }, { text: '2025', id: '2025' }], fitToContent: true },
/*                 { type: FormItemType.select, defaultValue: 'base', name: 'type', options: [{ text: 'Base Needs', id: 'base' }, { text: 'Incremental Demand', id: 'incremental' }, { text: 'Total', id: 'total' }], fitToContent: true }, */
                {
                  type: FormItemType.button, label: 'Export', handler: exportSpreadsheet, fitToContent: true 
                },
                {
                  type: FormItemType.button, hideIf: ({formState}) => formState.getValues().isLocked, label: 'Lock', handler: toggleLock, fitToContent: true 
                },
                {
                  type: FormItemType.button, hideIf: ({formState}) => !formState.getValues().isLocked, label: 'Unlock', handler: toggleLock, fitToContent: true 
                },
                {
                  name: 'snapshot',
                  type: FormItemType.select,
                  defaultValue: null,
                  fitToContent: true,
                  options: (state: any) => {
                    return [{id: null, text: 'Snapshot'}, ...(state.liquidCustomerShipments?.current?.data?.snapshots || []).map(l => ({id: l.snapshotId, text: formatDateTime(l.timestamp)}))]
                  }
                },
                {
                  type: FormItemType.button,
                  label: 'Create Snapshot',
                  handler: async (formState) => {
                    await CreateSnapshot(formState.getValues().year);
                    formState.methods.loadEntity();
                  },
                  hideIf: ({watch}) => watch('snapshot'),
                },
              ]
            },
            {
              type: FormItemType.fieldset,
              allowScroll: true,
              allowGrow: true,
/*               hide: ({ watch }) => {
                return watch('type') != 'base';
              }, */
              label: 'Forecasted Needs',
              items: [
                emeaBase
              ]
            },
/*             {
              type: FormItemType.fieldset,
              allowScroll: true,
              allowGrow: true,
              hide: ({ watch }) => {
                return watch('type') != 'total';
              },
              label: 'Total',
              items: [
                emeaTotal
              ]
            }, */

          ]
        },
        {
          label: 'Groupings',
          items: [
            {
              type: FormItemType.customComponent,
              component: GroupingsListView
            }
          ]
        }
      ]
    }


  ]
} as any;


export const LiquidCustomerShipments = createConnectedView({ form: ShipmnentsSheet, dataSource: liqShipmentsDataSource });

