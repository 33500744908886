import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Modal, Spinner, Container, Button } from "react-bootstrap";
import { useDispatch, useSelector, useStore } from "react-redux";
import { DataSource, Repository, createReduxStore } from "./data-source";
import { createFormView, FormView } from "./form-view";
import { useForm } from 'react-hook-form';
import { BsMenuButtonWideFill } from "react-icons/bs";
import toast from 'react-hot-toast';
import { useNavigate, useParams } from "react-router-dom";
import { getFormFieldCollection, merge, mergeForReset, transformModelForEditTablePartitions } from '../../../services/form-helpers'
import { reduxReducer, setReduxReducer } from "../../../store/store";
import ReactRouterPrompt from "react-router-prompt";
import PageHeader from "./FormItems/PageHeader";
import { selectCurentUserRoles } from "../../../store/slices/auth-slice";

export interface CreateViewProps {
  form: FormView,
  repository?: Repository,
}
export interface CreateConnectedViewProps {
  form: FormView,
  dataSource?: DataSource,
  existingRepo?: Repository
}
export const createConnectedView = (props: CreateConnectedViewProps) => {
  const { dataSource } = props;
  const repository = createReduxStore(dataSource);
  reduxReducer[dataSource.name] = repository.reducer;
  setReduxReducer(reduxReducer);
  const myProps = { ...props, repository: repository };
  return (additionalProps) => {
    return <FrameWorkView {...myProps} {...additionalProps} repository={myProps.repository} />
  }
}
export const createConnectedView2 = (props: CreateConnectedViewProps) => {
  const { dataSource, existingRepo } = props;
  let repository = existingRepo;
  if (!existingRepo) {
    repository = createReduxStore(dataSource);
    reduxReducer[dataSource.name] = repository.reducer;
    setReduxReducer(reduxReducer);
  }

  const myProps = { ...props, repository: repository };
  return [(additionalProps) => {
    return <FrameWorkView {...myProps} {...additionalProps} repository={repository} />
  }, repository];
}
export const createView = (props: CreateViewProps) => {
  return (additionalProps) => {
    return <FrameWorkView {...props} {...additionalProps} />
  }
}
export const FrameWorkView = (props) => {
  const { form, repository, modalContext, entityId: overrideEntityId } = props;
  const [entityLoading, setEntityLoading] = useState(false);
  const { editMode, permissions } = form;
  const { dataSource, fetchData, actions: { setCurrent, setUpdateStatus, setCreateStatus }, updateAction, createAction } = repository;

  const redirect = useNavigate();
  const currRoles = useSelector(selectCurentUserRoles) as any;
  const additionaSelector = useSelector((state: any) => {
    return dataSource.additionalSelector ? dataSource.additionalSelector(state) : null
  });
  const readAccess = useMemo(() => {
    if (!permissions || !permissions.read || !currRoles || currRoles.length == 0) return true;
    const readRoles = [...currRoles, {roleId: 2}].find(r => permissions.read.includes(r.roleId));
    return readRoles;
  }, [currRoles, permissions]);
  useEffect(() => {
    if (!readAccess) {
      redirect('unauthorized');
    }
  }, [readAccess])

  const fetchStatus = useSelector((state: any) => state[dataSource.name].status);
  const createStatus = useSelector((state: any) => state[dataSource.name].createAction.status);
  const updateStatus = useSelector((state: any) => state[dataSource.name].updateAction.status);
  const statusIsLoading = createStatus === 'loading' || updateStatus === 'loading' || fetchStatus === 'loading' || entityLoading;
  const data = useSelector(state => state[dataSource.name].data);
  const currentEntity = useSelector(state => state[dataSource.name].current);

  let {entityId}= useParams();
  if(!entityId) entityId = overrideEntityId;

  const createSuccessNotify = () => toast.success('Successfully Saved', { id: 'savesuccess' });
  const createFailureNotify = () => toast.error(`Failed to Save`, { id: 'savefailure' });

  useEffect(() => {
    if (createStatus === 'succeeded') {
      if (!editMode) {
        if (modalContext) {
          modalContext.setModalClose(true);
        }
        createSuccessNotify()
        dispatch(setCreateStatus('idle'));
      } else {
        dispatch(setCreateStatus('idle'));
      }
    }
    if (updateStatus === 'succeeded') {
      if (editMode) {
        if (modalContext) {
          modalContext.setModalClose(true);
        }
        createSuccessNotify()
        dispatch(setUpdateStatus('idle'));
      } else {
        dispatch(setUpdateStatus('idle'));
      }
    }
    if (updateStatus === 'failed') {
      if (editMode) {
        createFailureNotify()
        dispatch(setUpdateStatus('idle'));
      } else {
        dispatch(setUpdateStatus('idle'));
      }
    }
  }, [updateStatus, createStatus]);

  useEffect(() => {
    doReload();
  }, [data, entityId]);

  const doReload = async () => {
    if (form.editMode && entityId && !dataSource.entityRequest) {
      const toBeFound = data.find(record => record[dataSource.pk] == entityId);
      if (toBeFound) {
        dispatch(setCurrent(toBeFound))
      } else if(fetchStatus != 'loading') {
        //make call to repopulate item
        if (dataSource.serverSideEvents) {
          const res = await dispatch(fetchData({
            pKs: [entityId],
            primaryKeyColumn: dataSource.pk
          })).unwrap();
          const myGuy = res?.result?.length ? res.result[0] : null
          if (myGuy) {
            dispatch(setCurrent(myGuy))
          }
        } else {
          dispatch(fetchData());
        }
      }

    }
  }

  const dispatch = useDispatch();

  const defaults = useMemo(() => getFormFieldCollection(form.items, 'defaultValue'), [form.items]);

  function removeEmptyFields(formData) {
    Object.keys(formData).forEach(key => {
      if (formData[key] === '' && !(defaults && defaults[key] == '')) {
        formData[key] = null;
      }
    });
  }

  const onSubmit = async (formData) => {
    removeEmptyFields(formData);
    if (editMode === false) {
      const result = await dispatch(createAction(formData)).unwrap();
      return result;
    } else {
      const result = await dispatch(updateAction(formData)).unwrap();
      return result;
    }

  };

  const {
    register, control, watch, handleSubmit, setValue, formState, reset, getValues, unregister
  } = useForm({ mode: 'onSubmit', defaultValues: editMode ? merge(defaults, { ...currentEntity?.data }) : { ...defaults } });


  const loadEntity = async () => {
    setEntityLoading(true);
    const e = await dataSource.entityRequest(dataSource.entityRequestArgs ? dataSource.entityRequestArgs({ getValues, additionaSelector: additionaSelector }, entityId) : entityId);
    dispatch(setCurrent(e));
    setEntityLoading(false);
  }

  const formStateVars = useMemo(() => {
    return { register, control, watch, formState, setValue, handleSubmit, onSubmit, getValues, loadEntity, unregister, modalContext }
  }, [register, control, watch, formState, register])

  const entityRequestArgsVals = useMemo(() => {
    if (dataSource.entityRequestArgs) {
      return dataSource.entityRequestArgs({ getValues, additionaSelector: additionaSelector  }, entityId);
    }
  }, [dataSource.entityRequestArgs, formStateVars]);

  useEffect(() => {
    if (form.editMode && ((entityId && dataSource.entityRequest && !dataSource.entityRequestArgs) || (dataSource.entityRequestArgs && dataSource.entityRequest && entityRequestArgsVals))) {
      loadEntity();
    }
  }, [entityId, entityRequestArgsVals]);



  useEffect(() => {
    const editTableTransform = transformModelForEditTablePartitions({ ...currentEntity?.data }, form);
    const merged = mergeForReset(defaults, { ...currentEntity?.data });
    // const currVals = getValues();
    reset(merged);
  }, [currentEntity?.data]);

  const formComponent = useMemo(() => {
    return form && register ? createFormView({ ...form, formState: formStateVars }, repository) : null;
  }, [register, formState]);

  useEffect(() => {
    const isDirty = formState?.isDirty;
  }, [formState])

  return (
    <>
      <Container fluid className={`h-100 m-0 p-0 d-flex flex-column mb-2 uiFrameworkL ${statusIsLoading ? ' creation-loading' : ''}`} >
        {form.pageHeader && <PageHeader header={form.pageHeader} breadcrumbs={form.breadcrumbs} formState={formState} />}
        {editMode && <Spinner animation="border" variant="primary" className={`create-spinner${statusIsLoading ? ' visible' : ' invisible'}`} />}
        {formComponent()}
      </Container>
      <ReactRouterPrompt when={({ currentLocation, nextLocation, historyAction }) => {
        if (currentLocation?.pathname == nextLocation?.pathname) return false;
        return Object.keys(formState?.dirtyFields).length > 0 || Object.keys(formState?.touchedFields).length > 0
      }}>
        {({ isActive, onConfirm, onCancel }) => (
          <Modal show={isActive}>
            <Modal.Header>
              <Modal.Title>
                Unsaved Changes
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex flex-column">
              This page has unsaved changes. Are you sure you want to leave this page?
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={onCancel}>Cancel</Button>
              <Button onClick={onConfirm}>Yes</Button>
            </Modal.Footer>
          </Modal>
        )}
      </ReactRouterPrompt>
    </>
  )
}
