import React from 'react';
import { Modal } from 'react-bootstrap';

function CustomModal(props) {
  return (
    <Modal
      {...props}
      size="lg"
        // aria-labelledby="contained-modal-title-vcenter"
      centered
      scrollable
    >
      {props.children}
    </Modal>
  );
}

export default CustomModal;
