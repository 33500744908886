import { createConnectedListView, ListView, ListViewColumnType } from '../../UI/view/list-view';
import { getGasHistory } from '../../../services/requests';
import { DataSource } from '../../UI/view/data-source';

export const gasHistoryListView = {
  columns: [
    {
      Header: 'Trk #',
      accessor: 'trackingNumberID',
    },
    {
      Header: 'Location',
      accessor: 'locationID'
    },
    {
      Header: 'Arrival',
      accessor: 'arriveDate',
      type: ListViewColumnType.datetime

    },
    {
      Header: 'Fill',
      accessor: 'fillDate',
      type: ListViewColumnType.datetime
    },
    {
      Header: 'Ship',
      accessor: 'shipDate',
      type: ListViewColumnType.datetime,
      defaultSort: true,
      defaultSortDirection: 'desc'
    },
    {
      Header: 'Fill Customer',
      accessor: 'fillCustomerName'
    },
  ],
} as ListView

export const gasHistoryDataSource = {
  request: getGasHistory,
  requestArgs: (state) => {
    return state.gasContainer.currentGasContainer?.gasContainerId;
  },
  name: 'gasHistory',
  pk: 'trackingNumberID'
} as DataSource

export const GasHistoryListViewComponent = createConnectedListView(gasHistoryListView, gasHistoryDataSource);