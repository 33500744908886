import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Button, Card, Form, Spinner } from "react-bootstrap";
import Dropzone, { useDropzone } from 'react-dropzone';
import PaginatedSelectTable from '../../../UI/organisms/Table/paginated-select-table';
import { BsFillFileEarmarkArrowDownFill, BsFillTrashFill } from "react-icons/bs";
import { useDispatch, useSelector } from 'react-redux';
import { formatDate } from "../../../../services/format";
import { selectIsAdmin, selectLoggedInUserPreferences, selectCurentUserRoles } from '../../../../store/slices/auth-slice';
import toast from 'react-hot-toast';
import useConfirm from '../../../UI/organisms/ConfirmDialog/use-confirm';
import { attachmentsStore } from "../../../../store/store";
import { FormItem } from "../form-view";

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

export interface EntityAttachmentsProps {
  field?: any;
  entityPk?: any; 
  adminOnly?: any;
  append?: any;
  readOnly?: any;
}
export const EntityAttachments = ({ field, entityPk, adminOnly, append, readOnly }: EntityAttachmentsProps) => {

  const { permissions, entityName, entityTypeId } = field;
  const { additionalActions, dataSource } = attachmentsStore;
  const [dataLoaded, setDataLoaded] = useState(false);
  let isAdminRole = useSelector(selectIsAdmin);
  const isAdmin = isAdminRole;
  const currRoles = useSelector(selectCurentUserRoles);
  const writeAccess = useMemo(() => {
    if (!permissions || !permissions.write || !currRoles) return true;
    const readRoles = currRoles.find(r => permissions.write.includes(r.roleId));
    return readRoles;
  }, [currRoles, permissions]);

  const restricted = (adminOnly && !isAdmin) || !writeAccess;
  const dispatch = useDispatch();
  const [moduleKey, setModuleKey] = useState('General');
  const {
    isFocused,
  } = useDropzone();
  const {
    datePreference,
  } = useSelector(selectLoggedInUserPreferences);

  const attachmentDropdownValues = useSelector((state: any) => state.attachments.fetchFileAttachmentDropdownValues.data);
  /*   const append */
  const attachmentListUnfiltered = useSelector((state: any) =>
    state.attachments.fetchEntityAttachments.data);

  const attachmentList = attachmentListUnfiltered?.filter(x => (x.entityTypeId == entityTypeId && x.entityPK == entityPk)
    || (append?.find(a => a.entityPK == x.entityPK && a.entityTypeId == x.entityTypeId)))
  //const entityAttachments = useSelector((state: any) => state.attachments.fetchEntityAttachments.data);

  const dropdownValuesFetchStatus = useSelector((state: any) => state.attachments.fetchFileAttachmentDropdownValues.status);
  const entityAttachmentsFetchStatus = useSelector((state: any) => state.attachments.fetchEntityAttachments.status);
  const entityAttachmentDeleteStatus = useSelector((state: any) => state.attachments.deleteAttachmentAction.status);
  const entityAttachmentDownloadStatus = useSelector((state: any) => state.attachments.fetchAttachment.status);
  const entityAttachmentUploadStatus = useSelector((state: any) => state.attachments.createAttachment.status);
  const entityAttachmentBulkDownloadStatus = useSelector((state: any) => state.attachments.fetchAttachmentBulk.status);

  const { fetchFileAttachmentDropdownValues, fetchEntityAttachments, setFetchEntityAttachmentsStatus, deleteAttachmentAction, fetchAttachment, createAttachment, setCreateAttachmentStatus, setDeleteAttachmentActionStatus } = additionalActions;

  const entityAttachmentDetails = attachmentDropdownValues?.attachmentTypes.filter(x => x.entityTypeId == entityTypeId || x.entityTypeId === null) ?? [];

  useEffect(() => {
    dispatch(fetchFileAttachmentDropdownValues());
  }, [])
  const attachmentsLoading = [
    entityAttachmentsFetchStatus,
    entityAttachmentDeleteStatus,
    entityAttachmentDownloadStatus,
    entityAttachmentUploadStatus,
    entityAttachmentBulkDownloadStatus,
  ].includes('loading');

  const onDrop = ([fileAttachment]) => {
    const form = new FormData();
    const file = new Blob([fileAttachment]);

    const attachmentTypeId = entityAttachmentDetails.find((t) => t.name === moduleKey).id;
    form.append('file', file, fileAttachment.name);
    // @ts-ignore
    form.append('attachmentTypeId', parseInt(attachmentTypeId, 10));
    // @ts-ignore
    form.append('entityTypeId', entityTypeId);
    form.append('entityPK', entityPk);

    dispatch(createAttachment(form));
  };


  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
  }), [
    isFocused,
  ]);

  const { confirm } = useConfirm();

  const fileColumns = useMemo(() => (
    [
      {
        Header: '',
        id: 'actionColumn',
        Cell: ({ cell }) => (
          <div className="d-flex">
            {!restricted && <Button
              // @ts-ignore
              size="xsm"
              variant="secondary"
              onClick={async () => {
                const isConfirmed = await confirm('Are you sure you want to delete this attachment?');
                if (isConfirmed) {
                  dispatch(deleteAttachmentAction({
                    attachmentId: cell.row.original.attachmentId,
                  }));
                }
              }}
            >
              <BsFillTrashFill />
            </Button>}
            &nbsp;
            <Button
              // @ts-ignore
              size="xsm"
              variant="secondary"
              onClick={() => {
                dispatch(fetchAttachment({
                  attachmentId: cell.row.original.attachmentId,
                  fileName: cell.row.original.attachmentName,
                }));
              }}
            >
              <BsFillFileEarmarkArrowDownFill />
            </Button>
          </div>
        ),
      },
      {
        Header: 'Type',
        accessor: 'attachmentTypeId',
        Cell: ({ value }) => entityAttachmentDetails.find(
          (t) => parseInt(t.id, 10) === value,
        ).name,
      },
      {
        Header: 'File',
        accessor: 'attachmentName',
      },
      {
        Header: 'Uploaded',
        accessor: 'created',
        Cell: ({ value }) => formatDate(value, datePreference),
      },
    ]
  ), [attachmentList, entityAttachmentDetails]);

  const tableData = useMemo(
    () => (attachmentList && dataLoaded ? Object.values(attachmentList) : []),
    [attachmentList, dataLoaded],
  );

  const attachmentUploadNotify = () => toast.success('Successfully uploaded attachment');
  const attachmentDeleteNotify = () => toast.success('Successfully Deleted attachment');

  useEffect(() => {

    if (entityAttachmentUploadStatus === 'succeeded') {
      attachmentUploadNotify();
      dispatch(setCreateAttachmentStatus('idle'));
      loadData(true);
    }
  }, [entityAttachmentUploadStatus]);

  useEffect(() => {
    if (entityAttachmentDeleteStatus === 'succeeded') {
      attachmentDeleteNotify();
      dispatch(setDeleteAttachmentActionStatus('idle'));
      loadData(false);
    }
  }, [entityAttachmentDeleteStatus]);

  const attachmentsTableInstance = useRef(null);

  const dependeciesLoading = [
    dropdownValuesFetchStatus,
  ].includes('loading');

  const handleSelect = (e) => {
    setModuleKey(e.target.value);
  };

  const fileTypes = entityAttachmentDetails;

  const loadData = useCallback(async (appendData) => {
    if (
      (entityAttachmentsFetchStatus === 'idle'
        || entityAttachmentsFetchStatus === 'failed') && entityPk
    ) {
      dispatch(fetchEntityAttachments({ entityTypeId, entityPK: entityPk, appendData }));
    }
  }, [entityAttachmentsFetchStatus, entityTypeId, entityPk, dispatch]);

  useEffect(() => {
    if (append) {
      append?.forEach(additional => {
        dispatch(fetchEntityAttachments({ entityTypeId: additional.entityTypeId, entityPK: additional.entityPK }));
      });
    }
  }, [append]);

  useEffect(() => {
    if (dropdownValuesFetchStatus === 'succeeded') {
      loadData(false);
    }
  }, [entityPk, dropdownValuesFetchStatus]);

  useEffect(() => {
    if (entityAttachmentsFetchStatus === 'succeeded' || entityAttachmentsFetchStatus === 'failed') {
      setDataLoaded(true);
      dispatch(setFetchEntityAttachmentsStatus('idle'));
    }
  }, [entityAttachmentsFetchStatus]);


  return (
    <Card className="mt-2 card-primary card-outline flex-grow-1 d-flex flex-column overflow-auto">
      <Spinner
        animation="border"
        variant="primary"
        className={`create-spinner${attachmentsLoading ? ' visible' : ' invisible'}`}
      />
      <Card.Header>
        <h3 className="card-title">{entityName ? entityName : 'Attachments'}</h3>
      </Card.Header>
      <Card.Body className={`d-flex flex-column overflow-auto ${attachmentsLoading ? ' creation-loading' : ''}`}>
        {!readOnly && !restricted && <Dropzone onDrop={(accepted: any) => { onDrop(accepted); }}>
          {({ getRootProps, getInputProps }) => (
            <section>
              <div {...getRootProps({ style: style as any })}>
                <input {...getInputProps()} />
                <p>Click or Drag & Drop files here...</p>
              </div>
            </section>
          )}
        </Dropzone>}
        <div className="d-flex justify-content-between">
          <Form.Select
            className="mb-2 mt-2 btn-block w-100"
            // @ts-ignore
            variant="outline-secondary"
            title={moduleKey}
            onChange={handleSelect}
            size="sm"
          >
            {(fileTypes ?? []).map((fileType, index) => (
              <option
                value={fileType.name}
                key={`fileType-${index}`}
              >
                {fileType.name}
              </option>
            ))}
          </Form.Select>
        </div>
        <div className="d-flex flex-column flex-grow-1 overflow-auto ">
          {dependeciesLoading ? null : (
            <PaginatedSelectTable
              // @ts-ignore
              columns={fileColumns}
              data={tableData}
              ref={attachmentsTableInstance}
              rowProps={() => ({})}
            />
          )}
        </div>
      </Card.Body>
    </Card>

  );
}