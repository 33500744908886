import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import {
  Button,
  Collapse,
  Form,
  InputGroup,
  Spinner,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';

import { formatDate } from '../../../services/format';
import {
  maintenanceStatusValues,
} from '../../../services/maintenance-helpers';
import {
  assetSort,
  exportData,
  sortByBasic,
  sortByBoolean,
  sortByDateField,
} from '../../../services/table-helpers';
import { convertToPreferred, getPreferredUnit } from '../../../services/user-preferences';
import { selectIsAssetAdmin, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import {
  fetchChassis,
  fetchMaintenanceStatusForChassis, selectChassis, selectChassisMaintenanceStatuses,
} from '../../../store/slices/chassis-slice';
import { selectChassisCustomers, selectLiquidCustomerObjects } from '../../../store/slices/legacy-slice';
import { useTablePageRef } from '../../hooks/useStateRef';
import ActiveIcon from '../../UI/atoms/ActiveIcon/active-icon';
import { YelloIcon } from '../../UI/atoms/CircleFillIcon/circle-fill-icon';
import InactiveIcon from '../../UI/atoms/InactiveIcon/inactive-Icon';
import RedFlagAlert from '../../UI/atoms/RedFlagAlert/red-flag-alert';
import TableSearchFilter from '../../UI/atoms/TableSearchFilter/table-search-filter';
import ErrorContent from '../../UI/molecules/ErrorContent/error-content';
import LoadingContent from '../../UI/molecules/LoadingContent/loading-content';
import PaginatedSelectTable from '../../UI/organisms/Table/paginated-select-table';
import {
  chassisLength, chassisResidentRegion, chassisTypes, getChassisOwnerCustomer,
} from './chassis-values';

function ChassisTable(props) {
  const chassis = useSelector(selectChassis);
  const chassisStatus = useSelector((state) => state.chassis.chassisFetch.status);
  const chassisError = useSelector((state) => state.chassis.chassisFetch.error);

  const chassisMaintenanceStatuses = useSelector(
    selectChassisMaintenanceStatuses,
  );

  const chassisMaintenanceFetchStatus = useSelector(
    (state) => state.chassis.chassisMaintenanceStatusFetch.status,
  );

  const {
    datePreference,
    measurementPreference,
  } = useSelector(selectLoggedInUserPreferences);
  const chassisCustomersArr = useSelector(selectChassisCustomers);
  const chassisCustomers = useSelector(selectLiquidCustomerObjects);

  // Configure Table References
  const [currentPage, chassisTable, chassisTableInstance] = useTablePageRef();
  const [filterOpen, setFilterOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mappedPage = currentPage?.map((r) => r.original.serialNumber);

  useEffect(() => {
    if (chassis) {
      dispatch(fetchMaintenanceStatusForChassis(
        { assetTypeId: 4, assetIds: Object.keys(chassis) },
      ));
    }
  }, [JSON.stringify(chassis)]);

  const handleFilterChange = (event, filterId) => {
    chassisTable.setFilter(filterId, event.target.checked);
  };

  const handleSelectFilterChange = (event, filterId) => {
    chassisTable.setFilter(filterId, event.target.value);
  };

  const chassisColumns = useMemo(
    () => [
      {
        Header: 'Unit Number',
        accessor: 'serialNumber',
        minWidth: 150,
        width: 150,
        maxWidth: 150,
        Cell: ({ value }) => (<NavLink to={`/assets/chassis/${value}`}>{value}</NavLink>),
      },
      {
        Header: '',
        id: 'maintenanceStatus',
        filter: 'exactText',
        sortType: (a, b) => {
          const aStatus = chassisMaintenanceStatuses[a.values.serialNumber]?.maintenanceStatus ?? '';
          const bStatus = chassisMaintenanceStatuses[b.values.serialNumber]?.maintenanceStatus ?? '';

          const aValue = maintenanceStatusValues[aStatus];
          const bValue = maintenanceStatusValues[bStatus];
          if (chassisMaintenanceFetchStatus === 'loading') {
            return 0;
          }
          return sortByBasic(aValue, bValue);
        },
        accessor: (row) => {
          const status = chassisMaintenanceStatuses[row.serialNumber]?.maintenanceStatus ?? '';
          return status;
        },
        Cell: ({ value, row }) => {
          const status = value;
          if (status === 'Red') {
            return <RedFlagAlert redReasons={chassisMaintenanceStatuses[row.values.serialNumber]?.redReason} wos={chassisMaintenanceStatuses[row.values.serialNumber]?.wo} value={row.values.serialNumber} />;
          }
          if (status === 'Yellow') {
            return <YelloIcon />;
          }
          if (chassisMaintenanceFetchStatus === 'loading') {
            return (
              <div className="table-spinner d-inline-block">
                <Spinner animation="border" variant="primary" className="visible h-100 w-100" />
              </div>
            );
          }
          return '';
        },
      },
      {
        Header: 'Owner',
        id: 'ownerCustomer',
        filter: 'exactText',
        accessor: (row) => getChassisOwnerCustomer(chassisCustomers, row.ownerCustomer),
      },
      {
        Header: 'Region',
        id: 'residentRegion',
        accessor: (row) => (row.residentRegion == null
          ? null : chassisResidentRegion[row.residentRegion.residentRegion]),
      },
      {
        Header: 'Length',
        id: 'length',
        filter: 'exactText',
        accessor: (row) => (row.length == null ? null : chassisLength[row.length]),
      },
      {
        Header: 'Type',
        id: 'type',
        filter: 'exactText',
        accessor: (row) => (row.type == null ? null : chassisTypes[row.type]),
      },
      {
        Header: 'King Pin Insp',
        id: 'kingPinInspectionDate',
        sortType: sortByDateField,
        accessor: (row) => formatDate(row.kingPinInspectionDate, datePreference),
      },
      {
        Header: 'A Insp',
        id: 'aInspectionDate',
        sortType: sortByDateField,
        accessor: (row) => formatDate(row.aInspectionDate, datePreference),
      },
      {
        Header: 'DOT Insp',
        id: 'bInspectionDate',
        sortType: sortByDateField,
        accessor: (row) => formatDate(row.bInspectionDate, datePreference),
      },
      {
        Header: `Weight (${getPreferredUnit('lbs', measurementPreference)})`,
        id: 'weight',
        accessor: (row) => (row.weight ? `${convertToPreferred('lbs', measurementPreference, row.weight)}` : null),
        disableGlobalFilter: true,
      },
      {
        Header: 'Active',
        accessor: 'isActive',
        Cell: ({ value }) => (value ? <ActiveIcon /> : <InactiveIcon />),
      },
      {
        Header: 'Skikda Authorized',
        accessor: 'isSkikdaAuthorized',
        sortType: sortByBoolean,
        Cell: ({ value }) => (value ? 'Yes' : 'No'),
      },
    ],
    [chassisMaintenanceStatuses, chassisCustomers, chassisMaintenanceFetchStatus],
  );

  const chassisData = useMemo(() => {
    if (chassis) {
      return Object.values(chassis).sort(assetSort);
    }

    return [];
  }, [chassis]);

  const loadData = useCallback(async () => {
    if (chassisStatus === 'idle' || chassisStatus === 'failed') {
      dispatch(fetchChassis());
    }
  }, [chassisStatus, dispatch]);

  useEffect(() => {
    loadData();
  }, []);

  const handleRowClick = (chassisId) => {
    // Leveraging two methods for passing data to a new page for examples:
    // 1. State / useLocation
    // 2. uri path / useParam
    navigate(`/assets/chassis/${chassisId}`);
  };

  const onExport = () => {
    exportData('chassis', chassisData, chassisColumns);
  };
  const isAssetAdmin = useSelector(selectIsAssetAdmin);

  const chassisContent = (
    <>
      <div className="card-tools">
        <InputGroup size="sm">
          <Button
            variant={filterOpen ? 'primary' : 'outline-primary'}
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <i className={filterOpen ? 'bi bi-funnel-fill' : 'bi bi-funnel'} />
          </Button>
          <TableSearchFilter tableInstance={chassisTable} hookInstance />
          <Button variant="primary" size="sm" onClick={onExport}><i className="bi bi-download" /></Button>
          {isAssetAdmin
            && (
              <>
                &nbsp;
                <Button variant="primary" size="sm" onClick={props.handleModalShow}>Add Chassis</Button>
              </>
            )}

        </InputGroup>
        <Collapse in={filterOpen}>
          <div>
            <div className="p-3 d-flex flex-wrap wrap">
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'ownerCustomer'); }}>
                  <option value="" key="customer-null">-- Owner --</option>
                  {chassisCustomersArr.map((customer, index) => (
                    <option key={`customer-${index}`}>{customer.CustomerName}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'length'); }}>
                  <option value="" key="length-null">-- Length --</option>
                  {chassisLength.map((length, index) => (
                    <option key={`length-${index}`}>{length}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="me-3 mb-1">
                <Form.Select size="sm" onChange={(e) => { handleSelectFilterChange(e, 'type'); }}>
                  <option value="">-- Type --</option>
                  {chassisTypes.map((type, i) => (
                    <option key={type}>{type}</option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Check
                className="mb-1"
                type="switch"
                inline
                id="active-switch"
                label="Active"
                defaultChecked
                onChange={(e) => handleFilterChange(e, 'isActive')}
              />
              <Form.Check
                type="switch"
                className="ms-1 mb-1"
                inline
                id="skikda-switch"
                label="Skikda"
                onChange={(e) => handleFilterChange(e, 'isSkikdaAuthorized')}
              />
              {/* IoT - this filter is for a field that we are not currently leveraging */}
              {/* <Form.Check
                className="ms-1 mb-1"
                type="switch"
                inline
                id="telemetryUnitId-select"
                label="Remote Readout Conn. (IoT)"
                onChange={(e) => handleFilterChange(e, 'telemetryUnitId')}
              /> */}
            </div>
          </div>
        </Collapse>
      </div>
      <PaginatedSelectTable
        columns={chassisColumns}
        data={chassisData}
        ref={chassisTableInstance}
        initialState={{ filters: [{ id: 'isActive', value: true }] }}
        rowProps={() => ({})}
      />
    </>
  );

  return (
    <>
      <div className={`d-flex flex-column flex-grow-1 h-100${chassisStatus === 'loading' ? ' creation-loading' : ''}`}>
        {chassisContent}
        {chassisStatus === 'failed' && <ErrorContent errorMessage={chassisError} />}
      </div>
      {chassisStatus === 'loading' && <LoadingContent />}
    </>
  );
}

export default ChassisTable;
