import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setPackagedProductCreateStatus } from '../../../store/slices/packaged-products-slice';
// import './admin.scss';
import CreatePackagedProductForm from './create-packaged-product-form';

function CreatePackagedProduct(props) {
  const packagedProductCreateStatus = useSelector(
    (state) => state.packagedProduct.packagedProductCreate.status,
  );
  const packagedProductCreateError = useSelector(
    (state) => state.packagedProduct.packagedProductCreate.error,
  );

  const currentPackagedProductId = useSelector(
    (state) => state.packagedProduct.currentPackagedProduct.packagedProductId,
  );

  const packagedProductCreateSuccessNotify = () => toast.success('Successfully created Packaged Product');
  const packagedProductCreateFailedNotify = (error) => toast.error(`Failed to create Packaged Product\n${error}`);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (packagedProductCreateStatus === 'succeeded') {
      packagedProductCreateSuccessNotify();
      dispatch(setPackagedProductCreateStatus('idle'));
      navigate(`/assets/packaged-product/${currentPackagedProductId}`);
    }
    if (packagedProductCreateStatus === 'failed') {
      packagedProductCreateFailedNotify(packagedProductCreateError);
      dispatch(setPackagedProductCreateStatus('idle'));
    }
  }, [packagedProductCreateStatus]);

  // const currentPackagedProduct = useSelector(selectCurrentPackagedProduct);

  const packagedProductStatusIsLoading = packagedProductCreateStatus === 'loading';
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          Add New Packaged Product
        </Modal.Title>
      </Modal.Header>
      <CreatePackagedProductForm
        onHide={props.onHide}
        packagedProductStatusIsLoading={packagedProductStatusIsLoading}
      />
    </>

  );
}

export default CreatePackagedProduct;
