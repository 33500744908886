import React, { useMemo, useState } from 'react';
import {
  Card, Container, Nav, TabContainer, TabContent, TabPane,
} from 'react-bootstrap';

import { usePageTitle } from '../../hooks/usePageTitle';
import PageHeader from '../../UI/molecules/PageHeader/page-header';
import { LocationUserRoleList } from './location-user-role';
import LocationRolesTable from './locations-roles-table';
import RolesTable from './roles-table';
// import './admin.scss';

function Roles(props) {
  const [doctitle, setDocTitle] = usePageTitle('Roles');

  const breadcrumbs = useMemo(() => [
    {
      name: 'Roles',
      icon: 'person-badge',
      to: '/admin/roles',
    },
  ], []);

  const [activeTab, setActiveTab] = useState('Roles');

  return (
    <Container fluid className="h-100 m-0 p-0 d-flex flex-column">
      <PageHeader header="Roles" breadcrumbs={breadcrumbs} />
      <Card className="card-tabs card-primary flex-grow-1 overflow-auto">
        <TabContainer defaultActiveKey="Roles" onSelect={(tab) => setActiveTab(tab)}>
          <div className="card-header p-0 pt-1">
            <Nav variant="tabs">
              <Nav.Item>
                <Nav.Link eventKey="Roles">Roles</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="Locations">Locations</Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link eventKey="LocationUserRoles">Location Roles</Nav.Link>
              </Nav.Item>
            </Nav>
          </div>
          <div className="card-body p-0 overflow-auto g-0 w-100 mh-100 flex-grow-1">
            <TabContent className="h-100">
              <TabPane eventKey="Roles" className="h-100">
                <RolesTable />
              </TabPane>
              <TabPane eventKey="Locations" className="h-100" mountOnEnter unmountOnExit>
                <LocationRolesTable />
              </TabPane>
              <TabPane eventKey="LocationUserRoles" className="h-100">
                <LocationUserRoleList />
              </TabPane>
            </TabContent>

          </div>

        </TabContainer>
      </Card>

      {/* <Roles />
            <Permissions /> */}
    </Container>
  );
}

export default Roles;
