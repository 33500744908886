import React, { useEffect, useMemo } from 'react';
import {
  Button,
  Col, Container, Form, Row,
} from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';

import { formatPurchaseOrderStatus, splitStringOnUppercase } from '../../../services/format';
import { selectLoggedInUserInfo } from '../../../store/slices/auth-slice';
import { selectMaintenanceRequestDropdownValues } from '../../../store/slices/maintenance-requests-slice';
import {
  selectCurrentPurchaseOrder, selectCurrentPurchaseOrderId, selectShipmentsByCurrentPurchaseOrder, updatePurchaseOrderStatus,
} from '../../../store/slices/purchase-orders-slice';
import { selectPurchaseRequestsByCurrentPurchaseOrder } from '../../../store/slices/purchase-request-slice';
import Card from '../../UI/molecules/Card/card';
import useConfirm from '../../UI/organisms/ConfirmDialog/use-confirm';
import { LocationUserRoleRepo } from '../Roles/location-user-role';

function PurchaseOrderStatus(props) {
  const { assetTypes, purchaseOrderStatuses } = useSelector(selectMaintenanceRequestDropdownValues);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(LocationUserRoleRepo.fetchData());
  }, []);

  const locationUserRoles = useSelector((state) => state.locationUserRoleDS.data);
  const currentUser = useSelector(selectLoggedInUserInfo);
  const requests = useSelector(selectPurchaseRequestsByCurrentPurchaseOrder);
  const currentPurchaseOrder = useSelector(selectCurrentPurchaseOrder);

  const totalCost = useMemo(() => {
    return requests.filter((r) => r.purchaseRequestStatusId !== 2).reduce((acc, request) => acc + ((request.newCost ?? 0) * (request.quantityRequested ?? 0)), 0);
  }, [requests]);

  const siteManagerApprovalRequired = useMemo(() => {
    const roleId = 21;
    if (currentPurchaseOrder?.siteManagerApprovedBy) return false;
    const locationId = currentPurchaseOrder?.locationId;
    return locationUserRoles?.find((lur) => lur.locationId === locationId && lur.roleId === roleId && lur.isActive) && totalCost > 13500;
  }, [totalCost, locationUserRoles, currentPurchaseOrder]);

  const isSiteManager = useMemo(() => {
    const locationId = currentPurchaseOrder?.locationId;
    const roleId = 21;
    const userId = currentUser?.userId;
    return locationUserRoles?.find((lur) => lur.locationId === locationId && lur.roleId === roleId && lur.userId === userId && lur.isActive);
  }, [currentUser, currentPurchaseOrder, locationUserRoles]);

  const opsManagerApprovalRequired = useMemo(() => {
    const roleId = 22;
    if (currentPurchaseOrder?.opsManagerApprovedBy) return false;
    const locationId = currentPurchaseOrder?.locationId;
    return locationUserRoles?.find((lur) => lur.locationId === locationId && lur.roleId === roleId && lur.isActive);
  }, [totalCost, locationUserRoles, currentPurchaseOrder]);

  const isOpsManager = useMemo(() => {
    const locationId = currentPurchaseOrder?.locationId;
    const roleId = 22;
    const userId = currentUser?.userId;
    return locationUserRoles?.find((lur) => lur.locationId === locationId && lur.roleId === roleId && lur.userId === userId && lur.isActive);
  }, [currentUser, currentPurchaseOrder, locationUserRoles]);

  const siteApproveDisabled = siteManagerApprovalRequired && !isSiteManager;
  const opsApproveDisabled = opsManagerApprovalRequired && !isOpsManager;

  const approveDisabled = useMemo(() => {
    if (siteManagerApprovalRequired && isSiteManager) {
      return false;
    }
    if (opsManagerApprovalRequired && isOpsManager) {
      return false;
    }
    if (!siteApproveDisabled && !opsApproveDisabled) {
      return false;
    }
    return true;
  }, [siteApproveDisabled, opsApproveDisabled, siteManagerApprovalRequired, opsManagerApprovalRequired]);

  const currentPurchaseOrderId = useSelector(selectCurrentPurchaseOrderId);
  const shipments = useSelector(selectShipmentsByCurrentPurchaseOrder);

  const currentPurchaseOrderStatusId = currentPurchaseOrder?.purchaseOrderStatusId;

  const statusName = splitStringOnUppercase(purchaseOrderStatuses.find(
    (s) => s.id === (currentPurchaseOrderStatusId || 0).toString(),
  )?.name ?? '');

  const { confirm } = useConfirm();

  const dispatchUpdate = async (purchaseOrderStatusId) => {
    const isConfirmed = await confirm(`Are you sure you want to update this purchase order's status to ${formatPurchaseOrderStatus(purchaseOrderStatusId)}?`);
    if (isConfirmed) {
      dispatch(updatePurchaseOrderStatus({
        purchaseOrderId: currentPurchaseOrderId,
        purchaseOrderStatusId,
      }));
    }
  };

  const statusButtons = useMemo(() => {
    switch (currentPurchaseOrderStatusId) {
      case 1:
        return (
          <>
            <Button
              size="sm"
              variant="danger"
              className="mb-1 w-50 mr-1"
              onClick={() => {
                dispatchUpdate(5);
              }}
            >
              Reject
            </Button>
            <Button
              size="sm"
              variant="success"
              className="mb-1 w-50"
              disabled={approveDisabled}
              onClick={() => {
                dispatchUpdate(2);
              }}
            >
              {(opsManagerApprovalRequired || siteManagerApprovalRequired) ? `Awaiting Manager Approvals: ${opsManagerApprovalRequired ? 'Operations Manager' : ''}${opsManagerApprovalRequired && siteManagerApprovalRequired ? ', ' : ''} ${siteManagerApprovalRequired ? 'Site Manager' : ''}` : 'Approve'}
            </Button>
          </>
        );
      case 2:
        return (
          <Button
            size="sm"
            className="mb-1 w-100 mr-1"
            disabled={!requests?.length}
            onClick={() => {
              dispatchUpdate(3);
            }}
          >
            Mark as Submitted/Shipped
          </Button>
        );
      case 3:
        return (
          <div>
            <Button
              size="sm"
              className="mb-1 w-100 mr-1"
              onClick={() => {
                dispatchUpdate(4);
              }}
            >
              Mark as Received
            </Button>
            {currentPurchaseOrder.isStockTransferOrder && Object.values(shipments)?.length === 0 && (
              <Button
                variant="danger"
                size="sm"
                className="mb-1 w-100 mr-1"
                onClick={() => {
                  dispatchUpdate(2);
                }}
              >
                Revert to Approved
              </Button>
            )}
          </div>
        );
      case 4:
        return (
          <Button
            size="sm"
            variant="danger"
            className="mb-1 w-100 mr-1"
            onClick={() => {
              dispatchUpdate(6);
            }}
          >
            Mark as Closed
          </Button>
        );
      case 5:
        return (
          <Button
            size="sm"
            className="mb-1 w-50 mr-1"
            onClick={() => {
              dispatchUpdate(1);
            }}
          >
            Reopen
          </Button>
        );
      default:
        return <div />;
    }
  }, [currentPurchaseOrderStatusId, dispatchUpdate, totalCost]);

  return (
    <Card header="Status" className="card-secondary card-outline">
      <Container fluid className="p-0 m-0">
        <Row>
          <Col
            sm={12}
            lg={6}
          >
            <Form.Group className="mb-1">
              <Form.Control
                size="sm"
                disabled
                value={statusName ?? ''}
              />
            </Form.Group>
          </Col>
          <Col
            className="d-flex align-items-end"
            sm={12}
            lg={6}
          >
            {statusButtons}
          </Col>
        </Row>
      </Container>
    </Card>
  );
}

export default PurchaseOrderStatus;
