import { createFormView, FormView } from "../../UI/view/form-view";
import { FormItemType } from "../../UI/view/component-map";
import { usaStates } from '../../../data/states';
import { unassignMaintenanceRequestFromWorkOrder } from "../../../services/requests";
import { unique } from '../../../services/form-validation';
import { UserSearchTypeahead } from '../../UI/atoms/Fields/UserSearchTypeahead';

export const userCreate: FormView = {
  items: [
    {
      type: FormItemType.fieldset,
      label: 'User',
      items: [
        {
          type: FormItemType.checkbox,
          label: 'Non AAD User?',
          name: 'isExternalUser',
        },
        {
          type: FormItemType.customField,
          label: 'User Search',
          name: 'user search',
          hide: ({ watch }) => !!watch('isExternalUser'),
          component: UserSearchTypeahead,
          onChange: ({ newValue: selectedUser, setValue, editMode }) => {
            setValue('activeDirectoryDisplayName', selectedUser.displayName);
            setValue('activeDirectoryId', selectedUser.id);
            setValue('activeDirectoryPrincipalName', selectedUser.userPrincipalName);
            setValue('activeDirectoryMail', selectedUser.mail);
            setValue('displayName', selectedUser.displayName);
            if (!editMode) {
              const firstLast = getPrepopulatedFirstLast(selectedUser.displayName);
              setValue('firstName', firstLast[0]);
              setValue('lastName', firstLast[1]);
            }
          }
        },
      ]
    },
    {
      type: FormItemType.fieldset,
      label: 'AD User Info',
      hide: ({ watch }) => !!watch('isExternalUser'),
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              label: 'AD Display Name',
              name: 'activeDirectoryDisplayName',
              required: ({ watch }) => !watch('isExternalUser'),
            },
            {
              label: 'AD Email',
              name: 'activeDirectoryMail',
              required: ({ watch }) => !watch('isExternalUser'),
              disabled: ({ watch }) => {
                return !watch('isExternalUser')
              }
            }
          ]
        },
      ]
    },
    {
      type: FormItemType.fieldset,
      label: 'External User Info',
      hide: ({ watch }) => !watch('isExternalUser'),
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              label: 'External Display Name',
              name: 'activeDirectoryDisplayName',
              required: ({ watch }) => !!watch('isExternalUser'),
            },
            {
              label: 'Email',
              name: 'activeDirectoryMail',
              required: ({ watch }) => !!watch('isExternalUser'),
            }
          ]
        },
      ]
    },
    {
      type: FormItemType.fieldset,
      label: 'Helium3 User Info',
      items: [
        {
          type: FormItemType.row,
          items: [
            {
              label: 'First Name',
              name: 'firstName',
              required: true,
            },
            {
              label: 'Last Name',
              name: 'lastName',
              required: true,
            },
            {
              label: 'Display Name',
              name: 'displayName',
              required: true,
            }
          ]
        },
      ]
    }

    //Helium3 User Info
  ]
};

const getPrepopulatedFirstLast = (displayName) => {
  try {
    const name1 = displayName.substring(0, displayName.indexOf(' '));
    const name2 = displayName.substring(displayName.indexOf(' ') + 1);

    if (name1.includes(',')) {
      return [name2, name1.replace(',', '')];
    }
    return [name1, name2];
  } catch (e) {
    return ['', ''];
  }
};

