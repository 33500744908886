import React, { useEffect, useMemo, useState } from 'react';
import {
  Button, Col, Container, Form, Modal,
  Row, Spinner,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { convertDateToUTCDay, formatAssetType } from '../../../services/format';
import { selectIsMaintenanceManager, selectLoggedInUserActiveLocation, selectLoggedInUserPreferences } from '../../../store/slices/auth-slice';
import { selectChassis } from '../../../store/slices/chassis-slice';
import { selectGasContainers } from '../../../store/slices/gas-containers-slice';
import { selectAllActiveLocationObjects } from '../../../store/slices/legacy-slice';
import { selectLiquidContainers } from '../../../store/slices/liquid-containers-slice';
import {
  createBulkMaintenanceRequest,
  createMaintenanceRequest,
  createMaintenanceRequestWithWorkOrder,
  selectMaintenanceRequestDropdownValues,
  // selectMaintenanceRequestTypes,
  selectReleventMaintenanceRequestTypes,
  setCreateMaintenanceRequestStatus,
} from '../../../store/slices/maintenance-requests-slice';
import {
  fetchWorkOrder, setCurrentWorkOrderId,
} from '../../../store/slices/work-orders-slice';
import DateInput from '../../UI/molecules/DateInput/date-input';
import CustomModal from '../../UI/molecules/Modal/modal';
import SerialNumberPopover from '../../UI/molecules/SerialNumberPopover/serial-number-popover';
import { Typeahead } from '../../UI/molecules/Typeahead';
import { containerStatus } from './maintenance-request-values';

function CreateRequestForm(props) {
  const defaultRequestFormValues = {
    complaintOrigin: null,
    complaintDate: null,
    assetTypeId: null,
    assetSerialNumber: null,
    estimatedEffort: null,
    maintenanceRequestStatusId: null,
    maintenanceRequestTypeId: null,
    details: null,
    plannedDueDate: null,
    returnToFacility: null,
    containerStatus: null,
    createWorkOrder: false,
    workOrderLocationId: null,
    workOrderId: null,
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const maintenanceRequestTypes = useSelector(selectReleventMaintenanceRequestTypes);
  // const maintenanceRequestTypes = useSelector(selectMaintenanceRequestTypes);

  const maintenanceRequestCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestCreate.status,
  );
  const maintenanceRequestCreateError = useSelector(
    (state) => state.maintenanceRequest.maintenanceRequestCreate.error,
  );

  const { assetTypes, complaintOrigins } = useSelector(selectMaintenanceRequestDropdownValues);

  const maintenanceRequestCreateSuccessNotify = () => toast.success('Successfully created Maintenance Request(s)');
  const maintenanceRequestCreateFailedNotify = (error) => toast.error(`Failed to create Maintenance Request\n${error}`);

  const gasContainers = useSelector(selectGasContainers);
  const liquidContainers = useSelector(selectLiquidContainers);
  const chassis = useSelector(selectChassis);

  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);

  const bulkMaintenanceRequestCreateStatus = useSelector(
    (state) => state.maintenanceRequest.maintenanceBulkRequestCreate.status,
  );

  const gasContainerIds = useMemo(() => {
    if (gasContainers) {
      return Object.keys(gasContainers);
    }
    return [];
  }, [gasContainers]);

  const liquidContainerIds = useMemo(() => {
    if (liquidContainers) {
      return Object.keys(liquidContainers);
    }
    return [];
  }, [liquidContainers]);

  const chassisIds = useMemo(() => {
    if (chassis) {
      return Object.keys(chassis);
    }
    return [];
  }, [chassis]);

  const moveGenericTypeToFront = (x, y) => {
    const genericTypes = [9997, 9998, 9999];
    // eslint-disable-next-line no-nested-ternary, max-len
    return genericTypes.includes(x.maintenanceRequestTypeId) ? -1 : genericTypes.includes(y.maintenanceRequestTypeId) ? 1 : 0;
  };

  const unplannedChassisRequestTypes = useMemo(() => {
    if (maintenanceRequestTypes) {
      return Object.values(maintenanceRequestTypes).filter(
        (type) => !type.isPlanned && type.assetTypeId === 4,
      ).sort((a, b) => a.name.trim().localeCompare(b.name.trim())).sort(moveGenericTypeToFront)
        .map((type) => type.name);
    }
    return [];
  }, [maintenanceRequestTypes]);

  const unplannedLiquidRequestTypes = useMemo(() => {
    if (maintenanceRequestTypes) {
      return Object.values(maintenanceRequestTypes).filter(
        (type) => !type.isPlanned && type.assetTypeId === 2,
      ).sort((a, b) => a.name.trim().localeCompare(b.name.trim())).sort(moveGenericTypeToFront)
        .map((type) => type.name);
    }
    return [];
  }, [maintenanceRequestTypes]);

  const unplannedGasRequestTypes = useMemo(() => {
    if (maintenanceRequestTypes) {
      return Object.values(maintenanceRequestTypes).filter(
        (type) => !type.isPlanned && type.assetTypeId === 1,
      ).sort((a, b) => a.name.trim().localeCompare(b.name.trim())).sort(moveGenericTypeToFront)
        .map((type) => type.name);
    }
    return [];
  }, [maintenanceRequestTypes]);

  const locations = useSelector(selectAllActiveLocationObjects);

  const locationsList = useMemo(() => {
    return Object.values(locations ?? {});
  }, [locations]);

  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    control,
    watch,
    reset,
    trigger,
    setError,
    clearErrors,
    formState: { errors },
    getFieldState,
  } = useForm({
    defaultValues: defaultRequestFormValues,
  });

  const [assetIds, setSelectedAssetIds] = useState([]);

  const [selectedAssetTypeId, setSelectedAssetTypeId] = useState(null);

  const [unplannedMaintenanceTypes, setUnplannedMaintenanceTypes] = useState([]);

  const [unitNumbers, setUnitNumbers] = useState([]);

  const currentUserActiveLocation = useSelector(selectLoggedInUserActiveLocation);

  const initialWorkOrderLocation = currentUserActiveLocation?.LocationName;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {
      if (name === 'assetTypeId') {
        const todaysDate = new Date();
        setValue('complaintDate', todaysDate);
        if (value.assetTypeId === 1) {
          setSelectedAssetIds(gasContainerIds);
          setSelectedAssetTypeId(1);
          setUnplannedMaintenanceTypes(unplannedGasRequestTypes);
          setValue('assetType', 'GasContainer');
          setValue('assetSerialNumber', null);
        } else if (value.assetTypeId === 2) {
          setSelectedAssetIds(liquidContainerIds);
          setSelectedAssetTypeId(2);
          setUnplannedMaintenanceTypes(unplannedLiquidRequestTypes);
          setValue('assetType', 'LiquidContainer');
          setValue('assetSerialNumber', null);
        } else if (value.assetTypeId === 4) {
          setSelectedAssetIds(chassisIds);
          setSelectedAssetTypeId(4);
          setUnplannedMaintenanceTypes(unplannedChassisRequestTypes);
          setValue('assetType', 'Chassis');
          setValue('assetSerialNumber', null);
        }
      }
      if (name === 'createWorkOrder' && value.createWorkOrder === true) {
        const initialLocationId = locationsList?.find(
          (l) => l?.LocationName === initialWorkOrderLocation,
        )?.LocationID;
        setValue('workOrderLocationId', initialLocationId);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch,
    gasContainerIds, liquidContainerIds, chassisIds, initialWorkOrderLocation, locationsList]);

  useEffect(() => {
    setValue('assetTypeId', props.createModalAssetType);
    setSelectedAssetTypeId(parseInt(props.createModalAssetType, 10));
  }, [props.createModalAssetType, props.show]);

  useEffect(() => {
    if (props.assetSerialNumber) {
      setValue('assetSerialNumber', props.assetSerialNumber);
    }
  }, [props.assetSerialNumber, props.show]);

  useEffect(() => {
    if (props.currentWorkOrderId) {
      setValue('workOrderId', props.currentWorkOrderId);
    }
  }, [props.currentWorkOrderId, props.show]);

  useEffect(() => {
    if (props.selectedRequests) {
      setUnitNumbers(props.selectedRequests.map((sr) => (sr.values.serialNumber)));
    }
  }, [props.selectedRequests]);

  const onClose = () => {
    reset();
    setSelectedAssetIds([]);
    props.onHide();
  };

  const { datePreference } = useSelector(selectLoggedInUserPreferences);

  const onSubmit = (maintenanceRequest) => {
    console.log(maintenanceRequest);
    Object.keys(maintenanceRequest).forEach((key) => {
      if (key.toLowerCase().includes('date') && maintenanceRequest[key]) {
        maintenanceRequest[key] = convertDateToUTCDay(maintenanceRequest[key]);
      }
    });
    if (maintenanceRequest.maintenanceRequestTypeId == null) {
      switch (maintenanceRequest.assetTypeId) {
        case 1:
          maintenanceRequest.maintenanceRequestTypeId = 9997;
          break;
        case 2:
          maintenanceRequest.maintenanceRequestTypeId = 9998;
          break;
        case 4:
          maintenanceRequest.maintenanceRequestTypeId = 9999;
          break;
        default:
          console.error(`Invalid AssetType ID: ${maintenanceRequest.assetTypeId}`);
      }
    }
    if (maintenanceRequest.createWorkOrder) {
      dispatch(createMaintenanceRequestWithWorkOrder({
        ...maintenanceRequest,
        maintenanceRequestStatusId: 2, // APPROVED (will be finalized to OnWorkOrder)
      })).unwrap().then((result) => {
        navigate(`/maintenance/work-orders/${result.workOrder.workOrderId}`);
      }).catch((error) => {
        console.log(`Error creating maintenance request on work order: ${error.message}`);
      });
    } else if (props.selectedRequests) {
      dispatch(createBulkMaintenanceRequest({
        ...maintenanceRequest,
        maintenanceRequestStatusId: 1,
        assetSerialNumbers: unitNumbers,
      }));
    } else {
      dispatch(createMaintenanceRequest({
        ...maintenanceRequest,
        maintenanceRequestStatusId: 1,
      })).unwrap().then(async (result) => {
        // update work order
        const workOrder = await dispatch(fetchWorkOrder(result.workOrderId)).unwrap();
        dispatch(setCurrentWorkOrderId(result.workOrderId));
      }).catch((error) => {
        console.log(`Error creating maintenance request on work order: ${error.message}`);
      });
    }
  };

  useEffect(() => {
    if (maintenanceRequestCreateStatus === 'succeeded') {
      maintenanceRequestCreateSuccessNotify();
      dispatch(setCreateMaintenanceRequestStatus('idle'));
      onClose();
    }
    if (maintenanceRequestCreateStatus === 'failed') {
      maintenanceRequestCreateFailedNotify(maintenanceRequestCreateError);
      dispatch(setCreateMaintenanceRequestStatus('idle'));
      onClose();
    }
  }, [maintenanceRequestCreateStatus]);

  useEffect(() => {
    if (bulkMaintenanceRequestCreateStatus === 'succeeded') {
      onClose();
    }
    if (bulkMaintenanceRequestCreateStatus === 'failed') {
      onClose();
    }
  }, [bulkMaintenanceRequestCreateStatus]);

  const createMaintenanceRequestStatusIsLoading = [
    maintenanceRequestCreateStatus,
    bulkMaintenanceRequestCreateStatus,
    // gasContainerStatus,
    // liquidContainerStatus,
    // chassisStatus,
  ].includes('loading');

  return (
    <CustomModal
      show={props.show}
      onHide={onClose}
      contentClassName="poistion-relative overflow-visible"
    >
      <Modal.Header>
        <Modal.Title>
          Create New Maintenance Request
          {props.selectedRequests && props.selectedRequests.length > 1 ? 's' : ''}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Spinner
          animation="border"
          variant="primary"
          className={`create-spinner${createMaintenanceRequestStatusIsLoading ? ' visible' : ' invisible'}`}
        />
        <Form className={`${createMaintenanceRequestStatusIsLoading ? ' creation-loading' : ''}`}>
          <Container className="p-0 m-0">
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Asset Type</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('assetTypeId', { required: true })}
                    isInvalid={!!errors.assetTypeId}
                    disabled
                  >
                    {assetTypes.filter((type) => type.Name !== 'PackagedProduct').map((type, i) => (
                      <option value={type.id} key={`asset-type-${i}`}>{formatAssetType(type.name)}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              {!props.selectedRequests && (
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Unit Number</Form.Label>
                  <Controller
                    control={control}
                    name="assetSerialNumber"
                    defaultValue={null}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        value={value}
                        onChange={(e) => {
                          onChange(e[0]);
                        }}
                        size="sm"
                        id="asset-serial-number"
                        options={assetIds}
                        isValid={!!value}
                        disabled={!!props.assetSerialNumber}
                        placeholder={props.assetSerialNumber}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              )}
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Complaint Origin</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('complaintOrigin')}
                  >
                    <option value={null} key="complaint-origin-0" />
                    {complaintOrigins.map((type, i) => (
                      <option value={type.name} key={`complaint-origin-${i}`}>{type.name}</option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Complaint Date</Form.Label>
                  <DateInput
                    control={control}
                    formControlName="complaintDate"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Request Type</Form.Label>
                  <Controller
                    control={control}
                    name="maintenanceRequestTypeId"
                    defaultValue={null}
                    // rules={{ required: true }}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        value={value}
                        autoSort={false}
                        onChange={(e) => {
                          const id = Object.values(
                            maintenanceRequestTypes,
                          ).find((m) => (m.name === e[0]
                            && m.assetTypeId === selectedAssetTypeId
                          ))?.maintenanceRequestTypeId;
                          onChange(id);
                        }}
                        size="sm"
                        id="mainteanance-request"
                        options={unplannedMaintenanceTypes}
                        isInvalid={!!error}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Details</Form.Label>
                  <Form.Control
                    size="sm"
                    as="textarea"
                    rows={3}
                    {...register('details')}
                    style={{ resize: 'none' }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-1">
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Estimated Effort (Man Hrs.)</Form.Label>
                  <Form.Control size="sm" {...register('estimatedEffort')} />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Container Status</Form.Label>
                  <Form.Select
                    size="sm"
                    {...register('containerStatus', { required: 'Please select an owner.' })}
                    isInvalid={!!errors.containerStatus}
                  >
                    {containerStatus.map((status, index) => (
                      <option
                        value={index}
                        key={`customer-${index}`}
                      >
                        {status}
                      </option>
                    ))}
                  </Form.Select>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Return to Facility</Form.Label>
                  <Controller
                    control={control}
                    name="returnToFacility"
                    defaultValue={null}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        value={value}
                        onChange={(e) => {
                          onChange(locationsList?.find(
                            (l) => l?.LocationName === e[0],
                          )?.LocationID);
                        }}
                        size="sm"
                        id="returnToFacility"
                        options={locationsList.map((l) => l.LocationName)}
                        isInvalid={!!error}
                        flip
                      />
                    )}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-1">
                  <Form.Label>Planned Due Date</Form.Label>
                  <DateInput
                    control={control}
                    formControlName="plannedDueDate"
                  />
                </Form.Group>
              </Col>
            </Row>
            {(!props.selectedRequests && props.asset) && (
            <Row className={isMaintenanceManager ? 'visible' : 'invisible'}>
              <Col>
                <div style={{ minHeight: 60 }} className="d-flex align-items-center">
                  <Form.Check
                    {...register('createWorkOrder')}
                    type="switch"
                    inline
                    id="createWorkOrderSwitch"
                    label="Create with Work Order"
                    disabled={!isMaintenanceManager}
                  />
                </div>
              </Col>
              <Col className={watch('createWorkOrder', false) ? 'visible' : 'invisible'}>
                <Form.Group className="mb-1">
                  <Form.Label>Work Order Location</Form.Label>
                  <Controller
                    control={control}
                    name="workOrderLocationId"
                    defaultValue={null}
                    rules={{ required: watch('createWorkOrder', false) }}
                    render={({
                      field: {
                        onChange, value, ref,
                      },
                      fieldState: {
                        invalid, isTouched, isDirty, error,
                      },
                      formState,
                    }) => (
                      <Typeahead
                        ref={ref}
                        dropup
                        positionFixed
                        value={value}
                        onChange={(e) => {
                          onChange(locationsList?.find(
                            (l) => l?.LocationName === e[0],
                          )?.LocationID);
                        }}
                        size="sm"
                        id="workOrderLocationId"
                        options={locationsList.map((l) => l.LocationName)}
                        defaultSelected={initialWorkOrderLocation ? [initialWorkOrderLocation] : ['']}
                        isInvalid={!!error}
                      />
                    )}
                  />
                </Form.Group>
              </Col>
            </Row>
            )}
          </Container>
        </Form>
      </Modal.Body>
      <Modal.Footer className="d-flex justify-content-between">
        <Button onClick={props.onHide} variant="secondary">Close</Button>
        <Button type="submit" className="mt-3 position-sticky float-end bottom-0" onClick={handleSubmit(onSubmit)}>Submit</Button>
      </Modal.Footer>
      {props.selectedRequests?.length > 0 && props.show ? (
        <SerialNumberPopover
          header="Selected Assets"
          unitNumbers={unitNumbers}
        />
      ) : null}
    </CustomModal>
  );
}

export default CreateRequestForm;
