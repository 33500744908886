import { getDropdownValues, getRoles, getUsers } from "../../../services/requests";
import { getLocationUserRoles, insertLocationUserRoles, updateLocationUserRoles } from "../../../services/requests/adminRequests";
import { FormItemType } from "../../UI/view/component-map";
import { DataSource } from "../../UI/view/data-source";
import { FormView } from "../../UI/view/form-view";
import { ListView, ListViewColumnType, createConnectedListView, createConnectedListView2 } from "../../UI/view/list-view";

export const locationUserRoleDS = {
  name: 'locationUserRoleDS',
  request: getLocationUserRoles,
  updateRequest: updateLocationUserRoles,
  createRequest: insertLocationUserRoles,
  pk: 'locationUserRoleId'
} as DataSource;

export const locationUserRoleCreate = {
  items: [
    {
      label: 'Location',
      name: 'locationId',
      type: FormItemType.select,
      options: async () => {
        var locations = await getDropdownValues();
        return locations.locations.map((location: any) => ({ text: location.LocationName, id: location.LocationID }));
      }
    },
    {
      label: 'User',
      name: 'userId',
      type: FormItemType.select,
      options: async () => {
        var users = await getUsers();
        return users.map((user: any) => ({ text: user.displayName, id: user.userId }));
      }
    },
    {
      label: 'Role',
      name: 'roleId', type: FormItemType.select,
      options: async () => {
        var roles = await getRoles();
        return roles.map((role: any) => ({ text: role.name, id: role.roleId }));
      }
    },
    {
      label: 'IsActive',
      name: 'isActive',
      hideIf: () => true,
      defaultValue: true
    }

  ]
} as FormView;

export const locationUserRoleEdit = {
  ...locationUserRoleCreate,
  editMode: true
}
export const locationUserRoleList = {
  create: locationUserRoleCreate,
  update: locationUserRoleEdit,
  columns: [
    {
      type: ListViewColumnType.editdelete,
    },
    {
      Header: 'Location',
      accessor: 'locationId',
      defaultSort: true
    },
    {
      Header: 'User',
      accessor: 'user.displayName',
    },
    {
      Header: 'Role',
      accessor: 'role.name',
    }
  ]
} as ListView;

export const [LocationUserRoleList, LocationUserRoleRepo]  = createConnectedListView2(locationUserRoleList, locationUserRoleDS);

